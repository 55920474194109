import './CopyrightContent.less';

import * as React from 'react';

export const CopyrightContent = function render() {
  const current_year = new Date().getFullYear();
  const previous_year = current_year - 1;
  const copyrightNotice = `Copyright © ${current_year} ${process.env.REACT_APP_PRODUCT_NAME} Encoder. All rights reserved.`
  const copyrightText = `CPT © ${previous_year} American Medical Association. All rights reserved. Fee schedules,
  relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of
  CPT, and the AMA is not recommending their use. The AMA does not directly or indirectly practice medicine or
  dispense medical services. The AMA assumes no liability for data contained or not contained herein. CPT® is a
  registered trademark of the American Medical Association. The responsibility for the content of any "National
  Correct Coding Policy" included in this product is with the Centers for Medicare and Medicaid Services and no
  endorsement by the AMA is intended or should be implied. The AMA disclaims responsibility for any consequences or
  liability attributable to or related to any use, nonuse or interpretation of information contained in this product.
  CPT Assistant © ${previous_year} American Medical Association. All rights reserved. Official UB-04 Data
  Specifications Manual, "AHA Coding Clinic for ICD-9-CM", "AHA Coding Clinic for ICD-10-CM/PCS" and "AHA Coding
  Clinic for HCPCS" © ${current_year} American Hospital Association ("AHA"), Chicago, Illinois. Reproduced with
  permission. No portion of this publication may be copied without the express, written consent of the AHA. "AHA
  ICD-10-CM / PCS Coding Handbook ${current_year}" © 2011-${previous_year} Health Forum, LLC ("HF"), Chicago, Illinois.
  Reproduced with permission. No portion of this publication may be copied without the express, written consent of
  Health Forum. IBM® Micromedex® and IBM® DrugPoints® © Copyright IBM Corporation 1994-${current_year}. Reports are
  produced, in part, by using 3M software created, owned and licensed by 3M Company.  All copyrights in and to the 3M™ Software
  are owned by 3M Company or its affiliates.  All rights reserved. Dorland’s illustrated Medical Dictionary © ${current_year}
  Elsevier Inc. All rights reserved. No part of this publication may be reproduced or transmitted in any form
  or by any means, electronic or mechanical, including photocopying, recording, or any information storage
  and retrieval system, without permission in writing from the publisher. Details on how to seek permission,
  further information about the Publisher’s permissions policies and our arrangements with organizations such
  as the Copyright Clearance Center and the Copyright Licensing Agency, can be found at our website: www.elsevier.com/permissions.
  Coders' Desk Reference for Procedures © ${previous_year} Optum360, LLC. "Interventional Radiology Coder"
  and "Interventional Radiology Charts" © ${current_year} MedLearn Media. All Rights Reserved.
  Current Dental Terminology CDT © ${current_year} American Dental Association. All rights reserved. © ${current_year} Merck
  Sharp & Dohme Corp., a subsidiary of Merck & Co., Inc., Kenilworth, NJ, USA`;
  return <p className="copyright-content"> <span>{copyrightNotice}</span> {copyrightText}</p>;
}
