import * as actionTypes from '../constants';
import { ChoiceListsState, IdDescriptionBase } from '../models/patientEncounter';
import { choiceListsAPI } from '../services/choiceLists';
import { CHOICE_LIST_STATUS, getFooterFieldsCachedKey, getModifiersCachedKey } from '../models/cachedChoiceList';
import { parseServiceErrors } from '../services/encounter/serviceErrors';
import { addErrors } from './errors';

// loadingKey - indicate key to trigger loading for different choice lists
export const getFacilitiesChoiceListAction = (clearPreviousListOnError = true, loadingKey?: string) => (dispatch) => {
  dispatch(getChoiceListStart(loadingKey));
  choiceListsAPI.getFacilities()
    .then((facilities) => {
      dispatch(getFacilitiesCompleted(facilities));
      dispatch(getChoiceListEnd(loadingKey));
    })
    .catch((reason: Error) => {
      if (clearPreviousListOnError) {
        dispatch(getFacilitiesError(reason.message));
      }

      const errors = parseServiceErrors(reason, 'Cannot Load Facilities List');
      dispatch(addErrors(errors));
      dispatch(getChoiceListEnd(loadingKey));
    });
};

// facilitites updating by timer
export const updateFacilitiesAction = () => (dispatch) => {
  choiceListsAPI.getFacilities()
    .then((facilities) => {
      dispatch(getFacilitiesCompleted(facilities));
    })
    .catch(() => {
      // ignore errors during updating. Keep previous state
      console.log('Cannot update facilities');
    });
};

export const getChoiceListStart = (loadingKey) => ({
  payload: loadingKey,
  type: actionTypes.GET_CHOICE_LIST_START,
});

export const getChoiceListEnd = (loadingKey) => ({
  payload: loadingKey,
  type: actionTypes.GET_CHOICE_LIST_END,
});

export const getFacilitiesCompleted = (facilities: IdDescriptionBase[]) => ({
  payload: facilities,
  type: actionTypes.GET_FACILITIES_COMPLETED,
});

export const getFacilitiesError = (errorMsg: string) => ({
  error: errorMsg,
  type: actionTypes.GET_FACILITIES_FAIL,
});

export const getChoiceListsError = (errorMsg: string) => ({
  error: errorMsg,
  type: actionTypes.GET_CHOICELISTS_FAIL,
});

export const getSingleChoiceListError = (name: string) => ({
  payload: name,
  type: actionTypes.GET_CHOICELIST_FAIL,
});

export const getSingleChoiceListCompleted = (loadedLists) => ({
  payload: loadedLists,
  type: actionTypes.GET_CHOICELIST_SUCCESS,
});

export const getCachedChoiceListCompleted = (key: string, data) => ({
  payload: { key, data },
  type: actionTypes.CACHED_CHOICELIST_LOADED,
});

export const getCachedChoiceListStartLoading = (key: string) => ({
  payload: { key, status: CHOICE_LIST_STATUS.LOADING },
  type: actionTypes.CACHED_CHOICELIST_CHANGE_STATUS,
});

export const getCachedChoiceListError = (key: string) => ({
  payload: { key, status: CHOICE_LIST_STATUS.ERROR },
  type: actionTypes.CACHED_CHOICELIST_CHANGE_STATUS,
});

export const loadModifiersList = (approvedModifiersOnly: boolean, date?: string) => (dispatch) => {
  const key = getModifiersCachedKey(approvedModifiersOnly, date);
  dispatch(getCachedChoiceListStartLoading(key));
  choiceListsAPI.loadModifiersList(approvedModifiersOnly, date)
    .then((modifiers) => {
      dispatch(getCachedChoiceListCompleted(key, modifiers));
    })
    .catch((reason: Error) => {
      const errors = parseServiceErrors(reason, 'Cannot Load Modifiers');
      dispatch(addErrors(errors));
      dispatch(getCachedChoiceListError(key));      
    });
};

export const loadFooterFieldsList = (grouperType: string, pricerType: string, choiceLists: ChoiceListsState) => (dispatch) => {
  const key = getFooterFieldsCachedKey(grouperType, pricerType);
  
  // use already loaded list
  const cachedList = choiceLists.cachedLists[key];
  if (cachedList && cachedList.status === CHOICE_LIST_STATUS.READY) {
    return;
  }

  dispatch(getCachedChoiceListStartLoading(key));
  choiceListsAPI.loadFooterFieldsList(grouperType, pricerType)
    .then((fields) => {
      dispatch(getCachedChoiceListCompleted(key, fields));
    })
    .catch((reason: Error) => {
      const errors = parseServiceErrors(reason, 'Cannot Load Footer Fields');
      dispatch(addErrors(errors));
      dispatch(getCachedChoiceListError(key));      
    });
};
