import * as actionTypes from '../constants';
import { ErrorDetails } from '../models';

export const addErrorsAction = (errors: ErrorDetails[]) => (dispatch) => {
  dispatch(addErrors(errors));
};

export const clearErrorsAction = (index?: number, type?: string) => (dispatch) => {
  dispatch(clearErrors(index, type));
};

export const addErrors = (errors: ErrorDetails[]) => ({
  payload: errors,
  type: actionTypes.UI_ADD_ERRORS,
});

const clearErrors = (index?: number, type?: string) => ({
  payload: { index, type },
  type: actionTypes.UI_CLEAR_ERRORS,
});

export const showUnhandledErrorAction = (error?: ErrorDetails | null) => (dispatch) => {
  dispatch(showUnhandledError(error));
};

const showUnhandledError = (error?: ErrorDetails | null) => ({
  payload: error,
  type: actionTypes.UI_ADD_ERRORS,
});
