import './Card.less';

import * as React from 'react';
import { CardBody as KCardBody } from '@progress/kendo-react-layout';
// FIXME: once this export is fixed
import { CardBodyProps as KCardBodyProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/CardBodyProps';

export const CardBody = function render(props: KCardBodyProps) {
  const { className, ...otherProps } = props;
  return <KCardBody
    className={`tc-card-body ${className}`}
    {...otherProps}
  />;
}
