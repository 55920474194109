import './NetworkConnect.less';

import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';

import * as React from 'react';

interface NetworkConnectState {
  connected: boolean;
}

export default class NetworkConnect extends React.Component<{}, NetworkConnectState> {
  constructor(props: NetworkConnect) {
    super(props);
    this.onTimerTick = this.onTimerTick.bind(this);

    this.state = {
      connected: true
    };
  }

  private timerID: string | null = null;

  componentDidMount() {
    this.timerID = window.subscribeWorkerTimer(this.onTimerTick, 1);
  }

  componentWillUnmount() {
    if (this.timerID) {
      window.unsubscribeWorkerTimer(this.timerID);
    }
  }

  render() {
    if (this.state.connected) {
      return null;
    }

    return (
      <NotificationGroup
        style={{
          right: 0,
          bottom: 0,
          alignItems: 'flex-start',
          flexWrap: 'wrap-reverse',
          zIndex: 9000,
        }}
      >
        <Fade enter exit>
          <Notification
            type={{ style: 'error', icon: true }}
            closable={false}
            onClose={() => this.setState({ connected: false })}
          >
            <div className='no-network-connection'>
              <span>No Network Connection</span>
            </div>
          </Notification>
        </Fade>
      </NotificationGroup>
    )
  }

  private onTimerTick() {
    const online = window.navigator.onLine;

    if (online !== this.state.connected) {
      this.setState({
        connected: online
      })
    }
  }
}
