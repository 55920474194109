import './Page.less';

import * as React from 'react';

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'row' | 'column'
}

export function Page(props: PageProps) {
  const { type, className, ...otherProps} = props;
  const classes = ['page', className, type].join(' ');
  return <div id={props.id} className={classes} {...otherProps}>{props.children}</div>;
}

Page.defaultProps = {
  type: 'column',
}

export default Page;
