import './Tooltip.less';

import { Tooltip as KTooltip, TooltipProps as KTooltipProps } from '@progress/kendo-react-tooltip';
import { TooltipPosition as KTooltipPosition } from '@progress/kendo-react-tooltip/dist/npm/utils';

import * as React from 'react';

export const Tooltip = React.forwardRef((props: KTooltipProps, _ref) => {
  const { className, ...otherProps } = props;
  return <KTooltip
    ref={_ref}
    className={`tc-tooltip ${className}`}
    {...otherProps}
  >{props.children}</KTooltip>;
});

export { KTooltipPosition as TooltipPosition };
export interface TooltipProps extends KTooltipProps { }

export default Tooltip;
