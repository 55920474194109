import './MultiSelect.less';

import * as React from 'react';
import { MultiSelect as KMultiSelect, MultiSelectProps as KMultiSelectProps } from '@progress/kendo-react-dropdowns';

export const MultiSelect = React.forwardRef((props: KMultiSelectProps, _ref) => {
  const { className, popupSettings,...otherProps } = props;
  const popClass = `${popupSettings?.className} tc-multiselect-popup`;
  const popSettings = { ...popupSettings, ...{ className: popClass } };
  return <KMultiSelect
    ref={_ref}
    className={`tc-multiselect ${className}`}
    popupSettings={popSettings}
    {...otherProps}
  />;
})

