
import * as React from 'react';

import './EmptyBody.less';
import { Icon, IconType } from '../Icon/Icon';

interface EmptyBodyProps {
  icon?: IconType;
  message: string | string[];
}

export const EmptyBody = function render(props: EmptyBodyProps) {
  return (
    <div className="empty-body">
      <p className="icon"><Icon type={props.icon} /></p>
      <div className="body-with-scroll">
        <p className="msg">
          {
            Array.isArray(props.message) ?
              props.message.map((val, index) => 
                <span key={index}>{val}</span>
              ) : props.message
          }
        </p>
      </div>
    </div>
  );
}

export default EmptyBody;
