import './Tabstrip.less';

import * as React from 'react';
import { TabStrip as KTabStrip, TabStripProps as KTabStripProps } from '@progress/kendo-react-layout';

export interface TabStripProps extends React.PropsWithChildren<KTabStripProps> {}

export const Tabstrip = function render(props: TabStripProps) {
  const { className, keepTabsMounted, animation, ...otherProps } = props;
  return <KTabStrip
    className={`tc-tabstrip ${className}`}
    keepTabsMounted={true}
    animation={false}
    {...otherProps}
  />;
}
