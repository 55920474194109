/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLogic } from 'redux-logic';
import * as actionTypes from '../constants';
import { LogicLifecycleParams } from '../reduxStore';

import {
  CodeBooksModalCodePosted, codeBooksModalCodePostedAction, exitAllEditingAction
} from '../components/TEEModals/codeBooksModalActions';

import { ADMIT_DX_ID } from '../models/codeTypes';
import { gridsHaveIncorrectFields } from '../reducers/Helpers/encounterCodeGrid';

export const codePostedLogic = createLogic({
  type: [actionTypes.CODEBOOKS_MODAL_TRY_CODE_POSTED],
  cancelType: [actionTypes.OPEN_ENCOUNTER_BY_ID_BEGIN],
  latest: true, // take latest only

  // processOptions: {
  //   dispatchReturn: true,
  //   successType: usersFetchFulfilled,
  //   failType: usersFetchRejected
  // },

  debounce: 100, /* ms */

  process({ action, getState }: LogicLifecycleParams, dispatch: any, done: any) {
    const sender: CodeBooksModalCodePosted = action.payload;
    if (!sender.dataItem) {
      done();
    }

    if (sender.dataItem.id === ADMIT_DX_ID) {
      // only for the admit DX
      const code = sender.codes && sender.codes.length > 0 ? sender.codes[0].code : '';

      if (code === sender.dataItem.initialCode) {
        done();
      }
    }

    // ignore posted code if we have validation errors and keep focus on the wrong field
    if (gridsHaveIncorrectFields(getState().encounter.inProgress, getState().choiceLists.providers)) {
      done();
    }

    // clear all currently edited fields to correctly focus code after posting (we receive code from the another window)
    dispatch(exitAllEditingAction());

    // apply changes to the grid
    dispatch(codeBooksModalCodePostedAction(action.payload));

    done();
  }
});

// multiple logic groups can be exported here
export default [
  codePostedLogic,
];
