import { get, post } from '../../utils/net';
import { LicensePackage } from '../../models/license';

const baseURL = process.env.REACT_APP_API_PATH;

const getAvailableLicensePackages = (): Promise<LicensePackage[]> => {
  const url = `${baseURL}/api/user/AvailableLicensePackages`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return result.body as LicensePackage[];
  });
};

export const licenseAPI = {
  getAvailableLicensePackages: getAvailableLicensePackages,
};
