import './Toggle.less';

import { Switch, SwitchProps } from '@progress/kendo-react-inputs';
import * as React from 'react';

export const Toggle = function render(props: SwitchProps) {
  const { className, ...otherProps } = props;
  return <Switch
    className={`tc-toggle ${className}`}
    {...otherProps}
  />;
}
