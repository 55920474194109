import './PageContent.less';

import * as React from 'react';

interface PageContentProps extends React.HTMLAttributes<HTMLDivElement> {
  layout?: 'row' | 'column'; // | 'static';
  scrollable?: boolean;
}

export function PageContent(props: PageContentProps) {
  const { layout, className, scrollable, ...otherProps} = props;
  const scrollClass = scrollable ? 'scrollable' : null;
  const classes = ['page-content', className, layout, scrollClass].join(' ');
  return <section className={classes} {...otherProps}>{props.children}</section>;
}

PageContent.defaultProps = {
  layout: 'row',
  scrollable: true,
}

export default PageContent;
