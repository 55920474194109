import './Combobox.less';

import * as React from 'react';
import { ComboBox as KComboBox, ComboBoxProps as KComboBoxProps } from '@progress/kendo-react-dropdowns';

export const Combobox = React.forwardRef((props: KComboBoxProps, _ref) => {
  const { className, ...otherProps } = props;
  return <KComboBox
    ref={_ref}
    className={`tc-combobox ${className}`}
    {...otherProps}
  />;
})
