
import * as React from 'react';
import { GridCodeType } from '../../../../models';

import { BaseCodeRow } from '../../../../models/codeGrid';
import { ValuesGridType } from '../../../../models/valuesGrid';
import { affectsRisk, getAffectsDrgText, getSOIROMTooltip, getUsualFlagTooltip, isSOIROMFlag } from '../../utils/flags';

// prefixes to identify tooltip column
export const TOOLTIP_CODECELL = '##_CodeCell_##_';
export const TOOLTIP_HCCCELL = '##_HCCCell_##_';
export const TOOLTIP_HCCCELL24 = '##_HCCCell24_##_';
export const TOOLTIP_HCCCELL28 = '##_HCCCell28_##_';
export const TOOLTIP_GFCELL = '##_GFCell_##_';
export const TOOLTIP_TEXTCELL = '##_TextCell_##_';
export const TOOLTIP_SICELL = '##_SICell_##_';
export const TOOLTIP_INVALIDCELL = '##InvalidCell_##_';

export const combineTooltip = (type: string, fieldOrText: string, id?: string): string => {
  return id ? `${type}${fieldOrText}##${id}` : `${type}${fieldOrText}`;
};

export const getTooltipType = (title?: string): string => {
  if (!title) {
    return '';
  }

  if (title.substr(0, TOOLTIP_CODECELL.length) === TOOLTIP_CODECELL) {
    return TOOLTIP_CODECELL;
  }

  if (title.substr(0, TOOLTIP_HCCCELL.length) === TOOLTIP_HCCCELL) {
    return TOOLTIP_HCCCELL;
  }
  if (title.substr(0, TOOLTIP_HCCCELL24.length) === TOOLTIP_HCCCELL24) {
    return TOOLTIP_HCCCELL24;
  }
  if (title.substr(0, TOOLTIP_HCCCELL28.length) === TOOLTIP_HCCCELL28) {
    return TOOLTIP_HCCCELL28;
  }

  if (title.substr(0, TOOLTIP_GFCELL.length) === TOOLTIP_GFCELL) {
    return TOOLTIP_GFCELL;
  }

  if (title.substr(0, TOOLTIP_TEXTCELL.length) === TOOLTIP_TEXTCELL) {
    return TOOLTIP_TEXTCELL;
  }

  if (title.substr(0, TOOLTIP_SICELL.length) === TOOLTIP_SICELL) {
    return TOOLTIP_SICELL;
  }

  if (title.substring(0, TOOLTIP_INVALIDCELL.length) === TOOLTIP_INVALIDCELL) {
    return TOOLTIP_INVALIDCELL;
  }

  return '';
};

export const getTooltipItemInfo = (title?: string): string => {
  if (!title) {
    return '';
  }

  const type = getTooltipType(title);

  if (!type) {
    return '';
  }

  const info = title.substr(type.length, title.length);

  return info;
};

export const getTooltipItem = (id: string, gridData: BaseCodeRow[]) => {
  for (let ind = 0, len = gridData.length; ind < len; ind++) {
    if (gridData[ind].id === id) {
      return gridData[ind];
    }
  }

  return null;
};

export const needShowTooltipForCodeCell = (dataItem, fieldName = 'Code') => {
  if (!dataItem || !dataItem.Validations) {
    return false;
  }

  /*
    if (typeof dataItem.valid === 'boolean' && !dataItem.valid) {
        return true;
    }

    if (dataItem.edits || dataItem.mneEdits) {
        return true;
    }
    */

  for (let ind = 0, len = dataItem.Validations.length; ind < len; ind++) {
    if (dataItem.Validations[ind].Field === fieldName) {
      return true;
    }
  }

  return false;
};

export const isCriticalLevelError = (errorLevel?: string | null) => {
  return errorLevel === 'Critical' || errorLevel === 'Medium' || errorLevel === 'Low';
}

export enum TOOLTIP_ICON_TYPE {
  ERROR = 'Error',
  NOICON = 'No Icon',
  WARNING = 'Warning'
}

export const getTooltipIconTypeForCodeCell = (dataItem, fieldName = 'Code') => {
  if (!dataItem.Validations) {
    return TOOLTIP_ICON_TYPE.NOICON;
  }

  let hasWarnings = false;
  for (let ind = 0, len = dataItem.Validations.length; ind < len; ind++) {
    if (dataItem.Validations[ind].Field === fieldName) {
      if (isCriticalLevelError(dataItem.Validations[ind].Level)) {
        return TOOLTIP_ICON_TYPE.ERROR;
      }

      hasWarnings = true;
    }
  }

  return hasWarnings ? TOOLTIP_ICON_TYPE.WARNING : TOOLTIP_ICON_TYPE.NOICON;
};

export const getTooltipContentForCodeGridCell = (gridData: BaseCodeRow[], tooltip?: string) => {
  if (!tooltip) {
    return null;
  }

  const type = getTooltipType(tooltip);
  const info = getTooltipItemInfo(tooltip);
  if (!type || !info) {
    return tooltip;
  }

  // use raw text here
  if (type === TOOLTIP_TEXTCELL || type === TOOLTIP_INVALIDCELL) {
    return info;
  }

  // use multiline text
  if (type === TOOLTIP_SICELL) {
    const separator = String.fromCharCode(10);
    const parts = info.split(separator);
    return (
      <span>
        {parts.map(part => (<div key={part}>{part}</div>))}
      </span>
    )
  }

  const pos = info.indexOf('##');
  if (pos <= 0 || pos === info.length - 1) {
    return null;
  }

  const field = info.substr(0, pos);
  const id = info.substr(pos + 2);

  const dataItem = getTooltipItem(id, gridData);
  if (!dataItem) {
    return null;
  }

  switch (type) {
    case TOOLTIP_CODECELL:
      return getTooltipContentCodeCell(dataItem, field);
    case TOOLTIP_HCCCELL:
      return getTooltipContentHCCCell(dataItem, "");
    case TOOLTIP_HCCCELL24:
      return getTooltipContentHCCCell(dataItem, "24");
    case TOOLTIP_HCCCELL28:
      return getTooltipContentHCCCell(dataItem, "28");
    case TOOLTIP_GFCELL:
      return getTooltipContentGFCell(dataItem, gridData[0].id === id);
    default:
  }

  return null;
};

export const getTooltipContentCodeCell = (dataItem, fieldName = 'Code') => {
  if (!dataItem.Validations) {
    return null;
  }

  const editsContent: JSX.Element[] = [];
  const warningEditsContent: JSX.Element[] = [];

  let hasCritical = false;
  let ind;
  const len = dataItem.Validations.length;
  for (ind = 0; ind < len; ind++) {
    const edit = dataItem.Validations[ind];
    if (edit && edit.Description && edit.Field === fieldName) {
      if (isCriticalLevelError(edit.Level)) {
        hasCritical = true;
        editsContent.push((
          // eslint-disable-next-line react/no-danger
          <span style={{ display: 'block' }} key={ind} dangerouslySetInnerHTML={{ __html: edit.Description }} />
        ));
      } else if (!hasCritical) {
        warningEditsContent.push((
          // eslint-disable-next-line react/no-danger
          <span style={{ display: 'block' }} key={ind} dangerouslySetInnerHTML={{ __html: edit.Description }} />
        ));
      }
    }
  }

  if (hasCritical) {
    return editsContent;
  }

  if (warningEditsContent.length > 0) {
    return warningEditsContent;
  }

  return null;
};

const getTooltipContentHCCCell = (dataItem, version) => {
  let { hccs } = dataItem;
  switch (version) {
    case "24":
      hccs = dataItem.hccs24;
      break;
    case "28":
      hccs = dataItem.hccs28;
      break;
    default:
  }

  if (!hccs || hccs.length === 0) {
    return (
      <span><i>Hierarchical Condition Category (HCC)</i></span>
    );
  }

  const hccContent: JSX.Element[] = [];

  hccContent.push((
    <span key="title"><i>Hierarchical Condition Category (HCC)</i></span>
  ));

  for (let ind = 0; ind < hccs.length; ind++) {
    const hccItem = hccs[ind];
    if (hccItem.Value && hccItem.Description) {
      hccContent.push((
        <span style={{ display: 'block' }} key={ind}>
          {hccItem.Value.toString()} - {hccItem.Description.toString()}
        </span>
      ));
    }
  }

  return hccContent;
};

// check if all array values are {first} or {second} or not affecting risk ROM/SOI
// checkForExistence = true: first or second value should be in the array
const checkArrayForFlagValuesExceptSOIROM = (flags: string[], first: string, second: string, checkForExistence = true) => {
  let presents = false;
  for (let ind = 0, len = flags.length; ind < len; ind++) {
    const flag = flags[ind];
    if (flag === first || flag === second) {
      presents = true;
    }

    if (flag !== first && flag !== second) {
      if (!isSOIROMFlag(flag) || affectsRisk(flag)) {
        return false
      }
    }
  }

  return checkForExistence ? presents : true;
}

const getTooltipContentGFCell = (dataItem, isFirstCodeRow: boolean) => {
  const { grouperFlag, grouperFlagAffectsDRG, gridCodeType } = dataItem;
  if (!grouperFlag) {
    return <div/>;
  }

  const flags = grouperFlag.split(', ');
  const spans: JSX.Element[] = [];
  let hasADInFlagsArray = false;
  for (let ind = 0, len = flags.length; ind < len; ind++) {
    const flag = flags[ind];
    if (isSOIROMFlag(flag)) {
      const span = <span key={ind}>{spans.length ? <br/> : ''}{getSOIROMTooltip(flag)}</span>;
      spans.push(span);
    } else if (flag === "AD") {
      hasADInFlagsArray = true;
    } else {
      const tooltip = getUsualFlagTooltip(flag);
      if (tooltip) {
        const span = <span key={ind}>{spans.length ? <br/> : ''}{tooltip}</span>;
        spans.push(span);
      }
    }    
  }

  // Handle AD flag. This logic is different for Diagnoses and InProcedures grids
  if (gridCodeType === GridCodeType.DIAGNOSES) {
    // we have "AD" and this is not pdx row
    if (grouperFlagAffectsDRG && !isFirstCodeRow) {
      // we have single AD in the flags without any other flag (except r?/s?)
      if (checkArrayForFlagValuesExceptSOIROM(flags, "AD", "AD", false)) {
        // AD: Affects DRG, but is not a CC or MC
        const span = <span key='AD'>{spans.length ? <br/> : ''}AD: Affects DRG</span>;
        spans.push(span);
      } else {  // AD in combination with CC/MC/CF/H. Only main flag is shown, AD is used only for tooltip
        const span = <span key='AD'>{spans.length ? <br/> : ''}AD: Affects DRG</span>;
        spans.push(span);
      }
    } else {  // no AD or pdx row
      // add this only for only MC/CC/r?/s? like in the desktop logic (see GUI-2564) 
      // never show "Does not affect DRG" for primary Dx row (see GUI-2738)
      if (!isFirstCodeRow && !grouperFlagAffectsDRG && checkArrayForFlagValuesExceptSOIROM(flags, "CC", "MC")) {
        const span = <span key='AD'>{spans.length ? <br/> : ''}Does not affect DRG</span>;
        spans.push(span);
      }
    }
  }

  if (gridCodeType === GridCodeType.INPROCEDURES) {
    if (grouperFlagAffectsDRG) {  // has AD: single or not single
      const span = <span key='AD'>{spans.length ? <br/> : ''}AD: Affects DRG</span>;
      spans.push(span);
    } else {
      // add this only for only OR/NOR/r?/s? like in the desktop logic (see GUI-2564)
      if (!grouperFlagAffectsDRG && checkArrayForFlagValuesExceptSOIROM(flags, "OR", "NOR")) {
        const span = <span key='AD'>{spans.length ? <br/> : ''}Does not affect DRG</span>;
        spans.push(span);
      }
    }
  }
  
  return <div>{spans}</div>;
};
