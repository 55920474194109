import { FacilityServiceFieldSetting, FieldSettings, FieldSetting } from "../models/facilitySettings";

export const parseSettings = (fieldSettings: FacilityServiceFieldSetting[]) => {
  const result: FieldSettings = {};

  for (let ind = 0, len = fieldSettings.length; ind < len; ind++) {
    const setting = fieldSettings[ind];
    const readOnly = !!setting.State.find(value => value === "Readonly");
    const hidden = !!setting.State.find(value => value === "Hidden");
    const required = !!setting.State.find(value => value === "Required");

    result[setting.Field] = {
      readOnly,
      hidden,
      required
    }
  }

  return result;
}

export const isReadOnlyField = (settings: FieldSettings, field: string) => {
  const fieldSetting = settings[field];
  if (fieldSetting && fieldSetting.readOnly) {
    return true;
  }

  return false;
}

export const isHiddenField = (settings: FieldSettings, field: string) => {
  const fieldSetting = settings[field];
  if (fieldSetting && fieldSetting.hidden) {
    return true;
  }

  return false;
}

export const isRequiredField = (settings: FieldSettings, field: string) => {
  const fieldSetting = settings[field];
  if (fieldSetting && fieldSetting.required) {
    return true;
  }

  return false;
}

export const getColumnFieldSetting = (settings: FieldSettings, codeGridColumnsId: string, id: string, opGrouperType?: string): FieldSetting => {
  let setting: FieldSetting = {
    hidden: false,
    readOnly: false,
    required: false
  }

  switch(codeGridColumnsId) {
    case 'diagnosesPdxOn':
    case 'diagnosesPdxOff': {
      switch(id) {
        case 'colPresentOnAdmission':
          setting = settings['Diagnoses.PresentOnAdmission'] || setting;
          break;
        case 'colDescription':
          setting = settings['Diagnoses.Description'] || setting;
          break;
        case 'colHcc24':
          setting = settings['HCCsV24'] || setting;
          break;
        case 'colHcc28':
          setting = settings['HCCsV28'] || setting;
          break;
        default:
      }

      break;
    }

    case 'diagPsych': {
      switch(id) {
        case 'colPresentOnAdmission':
          setting = settings['Diagnoses.PresentOnAdmission'] || setting;
          break;
        case 'colDescription':
          setting = settings['Diagnoses.Description'] || setting;
          break;
        case 'colHcc24':
          setting = settings['HCCsV24'] || setting;
          break;
        case 'colHcc28':
          setting = settings['HCCsV28'] || setting;
          break;
        case 'colComorbidity':
          setting = settings['Diagnoses.Comorbidity'] || setting;
        default:
      }

      break;
    }

    case 'visitReasons': {
      switch(id) {
        case 'colDescription':
          setting = settings['VisitReasons.Description'] || setting;
          break;
        case 'colHcc24':
          setting = settings['HCCsV24'] || setting;
          break;
        case 'colHcc28':
          setting = settings['HCCsV28'] || setting;
          break;
        default:
      }

      break;
    }

    case 'inProcedures': {
      switch(id) {
        case 'colDescription':
          setting = settings['InProcedures.Description'] || setting;
          break;
        case 'colDate':
          setting = settings['InProcedures.ProcedureDate'] || setting;
          break;
        case 'colEpisode':
          setting = settings['InProcedures.Episode'] || setting;
          break;
        case 'colProvider':
          setting = settings['InProcedures.Provider'] || setting;
          break;
        default:
      }

      break;
    }

    case 'outProcedures': {
      switch(id) {
        case 'colDate':
          setting = settings['OutProcedures.ProcedureDate'] || setting;
          break;
        case 'colEpisode':
          setting = settings['OutProcedures.Episode'] || setting;
          break;
        case 'colProvider':
          setting = settings['OutProcedures.Provider'] || setting;
          break;
        case 'colDescription':
          setting = settings['OutProcedures.Description'] || setting;
          break;
        case 'colModifiers':
          setting = settings['OutProcedures.Modifiers'] || setting;
          break;
        case 'colCharges':
          setting = settings['OutProcedures.Charges'] || setting;
          break;
        case 'colNCCharges':
          setting = settings['OutProcedures.NonCoveredCharges'] || setting;
          break;
        case 'colRevenueCode':
          setting = settings['OutProcedures.RevenueCode'] || setting;
          break;
        case 'colUnits':
          setting = settings['OutProcedures.UnitsOfService'] || setting;
          break;

        case 'colApc':
          setting = settings['OutProcedures.APC'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcDescription':
          setting = settings['OutProcedures.APCDescription'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcWeight':
          setting = settings['OutProcedures.APCWeight'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcStatusIndicator':
          setting = settings['OutProcedures.APCStatusIndicator'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcCaFlag':
          setting = settings['OutProcedures.APCCompAdjFlag'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcPercent':
          setting = settings['OutProcedures.APCPercentage'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcMedicareAmount':
          setting = settings['OutProcedures.APCMedicareAmount'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcAdjustedCoin':
          setting = settings['OutProcedures.APCAdjustedCoin'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcOutlierAmount':
          setting = settings['OutProcedures.APCOutlierAmt'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;
        case 'colApcPayment':
          setting = settings['OutProcedures.APCPayment'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'OCEAPC' ? setting.hidden : true;
          break;

        case 'colEAPG':
          setting = settings['OutProcedures.EAPG'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'EAPG' ? setting.hidden : true;
          break;
        case 'colEAPGCategory':
          setting = settings['OutProcedures.EAPGCategory'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'EAPG' ? setting.hidden : true;
          break;
        case 'colEAPGType':
          setting = settings['OutProcedures.EAPGType'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'EAPG' ? setting.hidden : true;
          break;
        case 'colEAPGPayment':
          setting = settings['OutProcedures.EAPGPayment'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'EAPG' ? setting.hidden : true;
          break;
        case 'colEAPGDescription':
          setting = settings['OutProcedures.EAPGDescription'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'EAPG' ? setting.hidden : true;
          break;
        case 'colEAPGWeight':
          setting = settings['OutProcedures.EAPGWeight'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'EAPG' ? setting.hidden : true;
          break;
        case 'colEAPGPercentage':
          setting = settings['OutProcedures.EAPGPercentage'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'EAPG' ? setting.hidden : true;
          break;

        case 'colASCPayment':
          setting = settings['OutProcedures.ASCPayment'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'ASC' ? setting.hidden : true;
          break;
        case 'colASCWeight':
          setting = settings['OutProcedures.ASCWeight'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'ASC' ? setting.hidden : true;
          break;
        case 'colASCPercentage':
          setting = settings['OutProcedures.ASCPercentage'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'ASC' ? setting.hidden : true;
          break;
        case 'colASCMedicareAmount':
          setting = settings['OutProcedures.ASCMedicareAmount'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'ASC' ? setting.hidden : true;
          break;
        case 'colASCAdjustedCoin':
          setting = settings['OutProcedures.ASCAdjustedCoin'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'ASC' ? setting.hidden : true;
          break;
        case 'colASCPaymentIndicator':
          setting = settings['OutProcedures.ASCPaymentIndicator'] || setting;
          setting = { ...setting };
          setting.hidden = opGrouperType === 'ASC' ? setting.hidden : true;
          break;

        default:
      }

      break;
    }

    default:
  }
  return setting;
}
