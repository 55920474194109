import * as actionTypes from '../constants';
import { CodeMetadata } from '../models/codeFormats';
import { convertGetToPromise } from '../services/tee';

export const getCodeMetadata = (dispatch, codeMetadataInitialized: boolean) => {
  // already loaded
  if (codeMetadataInitialized) {
    return Promise.resolve()
      .then(() => {
        return true;
      })
  }

  dispatch(getCodeMetadataStart());

  return convertGetToPromise(window.TC.Net.url.code_getMetadata(), { extended: true }, window.TC.Net.get)
    .then((response) => {
      dispatch(getCodeMetadataCompleted(response));

      return true;
    })
    .catch(() => {
      dispatch(getCodeMetadataError('Metadata fetch error'));
      throw new Error('Metadata fetch error');
    });
};

const getCodeMetadataStart = () => ({
  type: actionTypes.GET_CODE_METADATA_BEGIN,
});

export const getCodeMetadataCompleted = (codesMetadata: CodeMetadata[]) => ({
  payload: codesMetadata,
  type: actionTypes.GET_CODE_METADATA_COMPLETED,
});

export const getCodeMetadataError = (error: string) => ({
  payload: error,
  type: actionTypes.GET_CODE_METADATA_FAIL,
});
