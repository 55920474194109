import * as actionTypes from '../constants';
import { userAPI } from '../services/users';

// different preparations to allow leave application
export const clearApplicationAction = () => (dispatch) => {
  dispatch(clearCurrentEncounterDirtyFlag());
  dispatch(clearSettingsDirtyChanges());
};

const clearCurrentEncounterDirtyFlag = () => ({
  payload: '',
  type: actionTypes.ENCOUNTER_CLEAR_DIRTY_CHANGES_FLAG,
});

const clearSettingsDirtyChanges = () => ({
  payload: '',
  type: actionTypes.SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES,
});

// logout current user from all tabs
export const logoutUserAction = (accountIdentifier: string, unsavedChanges?: boolean, fromIdleTimeout?: boolean) => (dispatch) => {
  // audit logout first
  return userAPI
    .auditLogout()
    .then(() => {
      // real logout, it is broadcasted to all tabs
      dispatch(logoutUser(accountIdentifier, unsavedChanges, fromIdleTimeout));
      return true;
    })
    .catch((error) => {
      console.log("Error during logout", error);

      // error should not prevent us from logout
      // real logout, it is broadcasted to all tabs
      dispatch(logoutUser(accountIdentifier, unsavedChanges, fromIdleTimeout));
      return false;
    });
};

const logoutUser = (accountIdentifier: string, unsavedChanges?: boolean, fromIdleTimeout?: boolean) => ({
  payload: { accountIdentifier, unsavedChanges, fromIdleTimeout },
  type: actionTypes.LOGOUT_CURRENT_USER,
});
