import './AppBar.less';

import * as React from 'react';
import { AppBar as KAppBar, AppBarProps as KAppBarProps } from '@progress/kendo-react-layout';

export const AppBar = function render(props: KAppBarProps) {
  const { className, ...otherProps } = props;
  return <KAppBar
    className={`tc-appbar ${className}`}
    {...otherProps}
  />;
}
