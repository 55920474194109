import * as actionTypes from '../../../constants';
import { choiceListsAPI } from '../../../services/choiceLists';
import { ChoiceListsState, IdDescriptionBase } from '../../../models/patientEncounter';
import { getChoiceListEnd, getChoiceListStart, getSingleChoiceListCompleted, getSingleChoiceListError } from '../../../actions/choiceLists';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';
import { addErrors } from '../../../actions/errors';

export const buildNewEncounterChoiceListsAction = (
  choiceLists: ChoiceListsState,
  facility: string) => (dispatch) => {
  // clear current list
  dispatch(getSingleChoiceListCompleted([{
    name: 'newEncounterEncounterTypes',
    values: []
  }]));
  
  // set loading flag
  dispatch(getChoiceListStart('loadingNewEncounterEncounterTypes'));

  // get new list
  choiceListsAPI.getChoiceListData(facility, 'EncounterTypes', '')
    .then((encounterTypes) => {
      dispatch(getSingleChoiceListCompleted([{
        name: 'newEncounterEncounterTypes',
        values: encounterTypes
      }]));
      dispatch(getChoiceListEnd('loadingNewEncounterEncounterTypes'));
    })
    .catch((reason: Error) => {
      const errors = parseServiceErrors(reason, 'Cannot Load Encounter Types');
      dispatch(addErrors(errors));
      dispatch(getSingleChoiceListError('newEncounterEncounterTypes'));
      dispatch(getChoiceListEnd('loadingNewEncounterEncounterTypes'));
    });
};
