import './FacilityConfigurationMenu.less';

import * as React from 'react';
import * as History from 'history';
import { RouterState } from 'connected-react-router';
import get from 'lodash/get';
import { match } from 'react-router-dom';
import TruLink from '../TruLink/TruLink';
import { FacilitiesState } from '../../scenes/Facility/models/facilitiesState';
import SidebarNav from '../SidebarNav/SidebarNav';

export interface FacilityConfigurationMenuProps {
  match: match<any>;
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  facilities: FacilitiesState;
  router: RouterState;
}

export class FacilityConfigurationMenu extends React.PureComponent<FacilityConfigurationMenuProps> {
  render() {
    const facilityViewId = get(this.props, 'match.params.id');
    let tertiaryActive = this.props.match.url.replace(/settings\/facility\/.*?\//i, '');
    if (tertiaryActive) { tertiaryActive = tertiaryActive.toLowerCase(); }
    const currentFacility = this.props.facilities.facilities.find(f => f.ViewId === facilityViewId);

    return (
      <SidebarNav heading={currentFacility?.Id || 'Unknown'}>
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to={`/settings/facility/${facilityViewId}/general`}
          isActive={tertiaryActive === '/general'}
        >
          General
        </TruLink>
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to={`/settings/facility/${facilityViewId}/inpatient`}
          isActive={tertiaryActive === '/inpatient'}
        >
          Inpatient
        </TruLink>
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to={`/settings/facility/${facilityViewId}/outpatient`}
          isActive={tertiaryActive === '/outpatient'}
        >
          Outpatient
        </TruLink>
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to={`/settings/facility/${facilityViewId}/choicelists`}
          isActive={tertiaryActive === '/choicelists'}
        >
          Choice Lists
        </TruLink>
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to={`/settings/facility/${facilityViewId}/financialclasses`}
          isActive={tertiaryActive === '/financialclasses'}
        >
          Financial Classes
        </TruLink>
        { /*
                <TruLink
                  hasChangesCallback={this.props.hasChangesCallback}
                  to={`/settings/facility/${facilityViewId}/preferences`}
                  isActive={tertiaryActive === '/fields'}
                >
                */}
        <a href="#" className="k-state-disabled">
          Field Settings
        </a>
        {/* </TruLink> */}
        { /* TODO: This should only be enabled if the user is a system admin!
                <TruLink
                  hasChangesCallback={this.props.hasChangesCallback}
                  to={`/settings/facility/${facilityViewId}/preferences`}
                  isActive={tertiaryActive === '/users'}
                >
                */}
        {/* Not implementing this functionality soon
        <a href="#" className="k-state-disabled">
          Users
        </a> */}
        {/* </TruLink> */}
      </SidebarNav>
    );
  }
}
