import './Notification.less';

import * as React from 'react';
import { Notification as KNotification, NotificationProps as KNotificationProps } from '@progress/kendo-react-notification';

export const Notification = function render(props: KNotificationProps) {
  const { className, ...otherProps } = props;
  return <KNotification
    className={`tc-notification ${className}`}
    {...otherProps}
  />;
}
