import { connect } from 'react-redux';
import { State } from '../../reducers';
import { ErrorPage } from './ErrorPage';

const mapStateToProps = (state: State) => ({
  error: state.ui.unhandledError,
  globalState: state,
});

export const ErrorPageContainer = connect(
  mapStateToProps,
)(ErrorPage);
