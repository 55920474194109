import * as moment from 'moment';
import * as actionTypes from '../../../constants';
import {
  DRGAnalyzeResultEntity,
  PDxAnalyzeResultEntity
} from '../../../models/groupingEntity';
import { EncounterEntity } from '../../../models';
import { pdxAPI } from '../../../services/grouping/pdx';
import { addErrors } from '../../../actions/errors';

export const pdxToggleAction = (encounter: EncounterEntity, isShowing: boolean) => (dispatch) => {
  if (isShowing && !encounter.ipGroupingType) {
    dispatch(addErrors([
      {
        description: 'Cannot start PDX analyzing without grouping',
        error: 'Cannot start PDX analyzing without grouping',
        time: moment.now(),
      }
    ]));

    return;
  }

  dispatch(pdxAnalyzeToggle(isShowing));

  if (!isShowing) {
    return;
  }

  dispatch(pdxAnalyzeBegin());

  pdxAPI
    .analyze(window.TC.Net, encounter)
    .then((res) => {
      dispatch(pdxAnalyzeCompleted(res));
    })
    .catch((reason) => {
      dispatch(pdxAnalyzeError(reason));
      dispatch(addErrors([
        {
          description: 'Cannot start PDX analyzing',
          error: 'Cannot start PDX analyzing',
          time: moment.now(),
        }
      ]));
    });
};

export const pdxAnalyzeToggle = (isShowing: boolean) => ({
  type: actionTypes.PDX_ANALYZE_TOGGLE,
  payload: isShowing
});

export const pdxAnalyzeBegin = () => ({
  type: actionTypes.PDX_ANALYZE_BEGIN,
});

export const pdxAnalyzeCompleted = (pdx: PDxAnalyzeResultEntity) => ({
  type: actionTypes.PDX_ANALYZE_COMPLETED,
  payload: pdx
});

export const pdxAnalyzeError = (error: Error) => ({
  payload: error,
  type: actionTypes.PDX_ANALYZE_ERROR,
});

export const drgAnalyzeBegin = () => ({
  type: actionTypes.DRG_ANALYZE_BEGIN,
});

export const drgAnalyzeCompleted = (drg: DRGAnalyzeResultEntity[]) => ({
  type: actionTypes.DRG_ANALYZE_COMPLETED,
  payload: drg
});

export const drgAnalyzeError = (error: Error) => ({
  payload: error,
  type: actionTypes.DRG_ANALYZE_ERROR,
});

// clear fields
export const drgAnalyzeNoDataError = () => ({
  payload: '',
  type: actionTypes.DRG_ANALYZE_ERROR,
});