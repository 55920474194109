export const UNHANDLED_LOGIC_ERROR = 'UNHANDLED_LOGIC_ERROR';
export type UNHANDLED_LOGIC_ERROR = typeof UNHANDLED_LOGIC_ERROR;

export const CCA_UPDATE_ADVICE_QUERY = 'CCA_UPDATE_ADVICE_QUERY';
export type CCA_UPDATE_ADVICE_QUERY = typeof CCA_UPDATE_ADVICE_QUERY;

export const CCA_SEARCH_ADVICE_BEGIN = 'CCA_SEARCH_ADVICE_BEGIN';
export type CCA_SEARCH_ADVICE_BEGIN = typeof CCA_SEARCH_ADVICE_BEGIN;

export const CCA_SEARCH_ADVICE_COMPLETED = 'CCA_SEARCH_ADVICE_COMPLETED';
export type CCA_SEARCH_ADVICE_COMPLETED = typeof CCA_SEARCH_ADVICE_COMPLETED;

export const CCA_SEARCH_ADVICE_ERROR = 'CCA_SEARCH_ADVICE_ERROR';
export type CCA_SEARCH_ADVICE_ERROR = typeof CCA_SEARCH_ADVICE_ERROR;

export const CCA_FETCH_ADVICE_BY_ID_BEGIN = 'CCA_FETCH_ADVICE_BY_ID_BEGIN';
export type CCA_FETCH_ADVICE_BY_ID_BEGIN = typeof CCA_FETCH_ADVICE_BY_ID_BEGIN;

export const CCA_FETCH_ADVICE_BY_ID_COMPLETED = 'CCA_FETCH_ADVICE_BY_ID_COMPLETED';
export type CCA_FETCH_ADVICE_BY_ID_COMPLETED = typeof CCA_FETCH_ADVICE_BY_ID_COMPLETED;

export const CCA_FETCH_ADVICE_BY_ID_ERROR = 'CCA_FETCH_ADVICE_BY_ID_ERROR';
export type CCA_FETCH_ADVICE_BY_ID_ERROR = typeof CCA_FETCH_ADVICE_BY_ID_ERROR;

export const CCA_FETCH_ADVICE_CODELIST_COMPLETED = 'CCA_FETCH_ADVICE_CODELIST_COMPLETED';
export type CCA_FETCH_ADVICE_CODELIST_COMPLETED = typeof CCA_FETCH_ADVICE_CODELIST_COMPLETED;

export const CCA_UPDATE_ADVICE_FIELD = 'CCA_UPDATE_ADVICE_FIELD';
export type CCA_UPDATE_ADVICE_FIELD = typeof CCA_UPDATE_ADVICE_FIELD;

export const CCA_SAVE_ADVICE_VALIDATION_COMPLETE = 'CCA_SAVE_ADVICE_VALIDATION_COMPLETE';
export type CCA_SAVE_ADVICE_VALIDATION_COMPLETE = typeof CCA_SAVE_ADVICE_VALIDATION_COMPLETE;

export const CCA_SAVE_ADVICE_BEGIN = 'CCA_SAVE_ADVICE_BEGIN';
export type CCA_SAVE_ADVICE_BEGIN = typeof CCA_SAVE_ADVICE_BEGIN;

export const CCA_SAVE_ADVICE_COMPLETE = 'CCA_SAVE_ADVICE_COMPLETE';
export type CCA_SAVE_ADVICE_COMPLETE = typeof CCA_SAVE_ADVICE_COMPLETE;

export const CCA_SAVE_ADVICE_ERROR = 'CCA_SAVE_ADVICE_ERROR';
export type CCA_SAVE_ADVICE_ERROR = typeof CCA_SAVE_ADVICE_ERROR;

export const CCA_EDITING_ADVICE_BEGIN = 'CCA_EDITING_ADVICE_BEGIN';
export type CCA_EDITING_ADVICE_BEGIN = typeof CCA_EDITING_ADVICE_BEGIN;

export const CCA_EDITING_ADVICE_END = 'CCA_EDITING_ADVICE_END';
export type CCA_EDITING_ADVICE_END = typeof CCA_EDITING_ADVICE_END;

export const CCA_EDITING_ADVICE_CANCEL = 'CCA_EDITING_ADVICE_CANCEL';
export type CCA_EDITING_ADVICE_CANCEL = typeof CCA_EDITING_ADVICE_CANCEL;

export const CCA_DELETE_ADVICE_BEGIN = 'CCA_DELETE_ADVICE_BEGIN';
export type CCA_DELETE_ADVICE_BEGIN = typeof CCA_DELETE_ADVICE_BEGIN;

export const CCA_DELETE_ADVICE_COMPLETED = 'CCA_DELETE_ADVICE_COMPLETE';
export type CCA_DELETE_ADVICE_COMPLETED = typeof CCA_DELETE_ADVICE_COMPLETED;

export const CCA_DELETE_ADVICE_ERROR = 'CCA_DELETE_ADVICE_ERROR';
export type CCA_DELETE_ADVICE_ERROR = typeof CCA_DELETE_ADVICE_ERROR;

export const CCA_ADD_ADVICE_CODES = 'CCA_ADD_ADVICE_CODES';
export type CCA_ADD_ADVICE_CODES = typeof CCA_ADD_ADVICE_CODES;

export const CCA_DELETE_ADVICE_CODE_RANGE = 'CCA_DELETE_ADVICE_CODE_RANGE';
export type CCA_DELETE_ADVICE_CODE_RANGE = typeof CCA_DELETE_ADVICE_CODE_RANGE;

export const CCA_EXCLUDE_ADVICE_CODE_WITHIN_RANGE = 'CCA_EXCLUDE_ADVICE_CODE_WITHIN_RANGE';
export type CCA_EXCLUDE_ADVICE_CODE_WITHIN_RANGE = typeof CCA_EXCLUDE_ADVICE_CODE_WITHIN_RANGE;

export const CCA_UNDO_EXCLUDE_ADVICE_CODE_WITHIN_RANGE = 'CCA_UNDO_EXCLUDE_ADVICE_CODE_WITHIN_RANGE';
export type CCA_UNDO_EXCLUDE_ADVICE_CODE_WITHIN_RANGE = typeof CCA_UNDO_EXCLUDE_ADVICE_CODE_WITHIN_RANGE;

export const CCA_CREATE_ADVICE = 'CCA_CREATE_ADVICE';
export type CCA_CREATE_ADVICE = typeof CCA_CREATE_ADVICE;

export const CCA_UNDO_DELETE_ADVICE_CODE_RANGE = 'CCA_UNDO_DELETE_ADVICE_CODE_RANGE';
export type CCA_UNDO_DELETE_ADVICE_CODE_RANGE = typeof CCA_UNDO_DELETE_ADVICE_CODE_RANGE;

export const CCA_CONFIRM_DISCARD_DIRTY_CHANGES = 'CCA_CONFIRM_DISCARD_DIRTY_CHANGES';
export type CCA_CONFIRM_DISCARD_DIRTY_CHANGES = typeof CCA_CONFIRM_DISCARD_DIRTY_CHANGES;

export const CCA_PROMPT_DISCARD_DIRTY_CHANGES = 'CCA_PROMPT_DISCARD_DIRTY_CHANGES';
export type CCA_PROMPT_DISCARD_DIRTY_CHANGES = typeof CCA_PROMPT_DISCARD_DIRTY_CHANGES;

export const CCA_CODE_CHOOSER_CHANGE_COMPLETED = 'CCA_CODE_CHOOSER_CHANGE_COMPLETED';
export type CCA_CODE_CHOOSER_CHANGE_COMPLETED = typeof CCA_CODE_CHOOSER_CHANGE_COMPLETED;

export const SYSTEM_ANNOUNCEMENTS_COMPLETE = 'SYSTEM_ANNOUNCEMENTS_COMPLETE';
export type SYSTEM_ANNOUNCEMENTS_COMPLETE = typeof SYSTEM_ANNOUNCEMENTS_COMPLETE;

export const SYSTEM_ANNOUNCEMENT_DISMISSED = 'SYSTEM_ANNOUNCEMENT_DISMISSED';
export type SYSTEM_ANNOUNCEMENT_DISMISSED = typeof SYSTEM_ANNOUNCEMENT_DISMISSED;

export const SYSTEM_ANNOUNCEMENT_ERROR = 'SYSTEM_ANNOUNCEMENT_ERROR';
export type SYSTEM_ANNOUNCEMENT_ERROR = typeof SYSTEM_ANNOUNCEMENT_ERROR;

export const INITIALIZE_BEGIN = 'INITIALIZE_BEGIN';
export type INITIALIZE_BEGIN = typeof INITIALIZE_BEGIN;

export const INITIALIZE_COMPLETED = 'INITIALIZE_COMPLETED';
export type INITIALIZE_COMPLETED = typeof INITIALIZE_COMPLETED;

export const INITIALIZE_FAIL = 'INITIALIZE_FAIL';
export type INITIALIZE_FAIL = typeof INITIALIZE_FAIL;

export const CODEBOOKS_LIST_BEGIN = 'CODEBOOKS_LIST_BEGIN';
export type CODEBOOKS_LIST_BEGIN = typeof CODEBOOKS_LIST_BEGIN;

export const CODEBOOKS_LIST_FAIL = 'CODEBOOKS_LIST_FAIL';
export type CODEBOOKS_LIST_FAIL = typeof CODEBOOKS_LIST_FAIL;

export const CODEBOOKS_LIST_COMPLETED = 'CODEBOOKS_LIST_COMPLETED';
export type CODEBOOKS_LIST_COMPLETED = typeof CODEBOOKS_LIST_COMPLETED;

export const EXPAND_CODE_RANGE_START = 'EXPAND_CODE_RANGE_START';
export type EXPAND_CODE_RANGE_START = typeof EXPAND_CODE_RANGE_START;

export const EXPAND_CODE_RANGE_COMPLETED = 'EXPAND_CODE_RANGE_COMPLETED';
export type EXPAND_CODE_RANGE_COMPLETED = typeof EXPAND_CODE_RANGE_COMPLETED;

export const EXPAND_CODE_RANGE_ERROR = 'EXPAND_CODE_RANGE_ERROR';
export type EXPAND_CODE_RANGE_ERROR = typeof EXPAND_CODE_RANGE_ERROR;

export const OPEN_ENCOUNTER_BY_ID_BEGIN = 'OPEN_ENCOUNTER_BY_ID_BEGIN';
export type OPEN_ENCOUNTER_BY_ID_BEGIN = typeof OPEN_ENCOUNTER_BY_ID_BEGIN;

export const OPEN_ENCOUNTER_BY_ID_COMPLETED = 'OPEN_ENCOUNTER_BY_ID_COMPLETED';
export type OPEN_ENCOUNTER_BY_ID_COMPLETED = typeof OPEN_ENCOUNTER_BY_ID_COMPLETED;

export const OPEN_ENCOUNTER_BY_ID_ERROR = 'OPEN_ENCOUNTER_BY_ID_ERROR';
export type OPEN_ENCOUNTER_BY_ID_ERROR = typeof OPEN_ENCOUNTER_BY_ID_ERROR;

export const SAVE_ENCOUNTER_BEGIN = 'SAVE_ENCOUNTER_BEGIN';
export type SAVE_ENCOUNTER_BEGIN = typeof SAVE_ENCOUNTER_BEGIN;

export const SAVE_ENCOUNTER_COMPLETED = 'SAVE_ENCOUNTER_COMPLETED';
export type SAVE_ENCOUNTER_COMPLETED = typeof SAVE_ENCOUNTER_COMPLETED;

export const SAVE_ENCOUNTER_ERROR = 'SAVE_ENCOUNTER_ERROR';
export type SAVE_ENCOUNTER_ERROR = typeof SAVE_ENCOUNTER_ERROR;

export const SAVE_ENCOUNTER_CHANGES_BEGIN = 'SAVE_ENCOUNTER_CHANGES_BEGIN';
export type SAVE_ENCOUNTER_CHANGES_BEGIN = typeof SAVE_ENCOUNTER_CHANGES_BEGIN;

export const SAVE_ENCOUNTER_CHANGES_COMPLETED = 'SAVE_ENCOUNTER_CHANGES_COMPLETED';
export type SAVE_ENCOUNTER_CHANGES_COMPLETED = typeof SAVE_ENCOUNTER_CHANGES_COMPLETED;

export const SAVE_ENCOUNTER_CHANGES_ERROR = 'SAVE_ENCOUNTER_CHANGES_ERROR';
export type SAVE_ENCOUNTER_CHANGES_ERROR = typeof SAVE_ENCOUNTER_CHANGES_ERROR;

export const SAVE_ENCOUNTER_CHANGES_UPDATE_ERROR = 'SAVE_ENCOUNTER_CHANGES_UPDATE_ERROR';
export type SAVE_ENCOUNTER_CHANGES_UPDATE_ERROR = typeof SAVE_ENCOUNTER_CHANGES_UPDATE_ERROR;

export const PROCESS_ENCOUNTER_BEGIN = 'PROCESS_ENCOUNTER_BEGIN';
export type PROCESS_ENCOUNTER_BEGIN = typeof PROCESS_ENCOUNTER_BEGIN;

export const PROCESS_ENCOUNTER_COMPLETED = 'PROCESS_ENCOUNTER_COMPLETED';
export type PROCESS_ENCOUNTER_COMPLETED = typeof PROCESS_ENCOUNTER_COMPLETED;

export const PROCESS_ENCOUNTER_ERROR = 'PROCESS_ENCOUNTER_ERROR';
export type PROCESS_ENCOUNTER_ERROR = typeof PROCESS_ENCOUNTER_ERROR;

export const CLOSE_ENCOUNTER_BEGIN = 'CLOSE_ENCOUNTER_BEGIN';
export type CLOSE_ENCOUNTER_BEGIN = typeof CLOSE_ENCOUNTER_BEGIN;

export const CLOSE_ENCOUNTER_COMPLETED = 'CLOSE_ENCOUNTER_COMPLETED';
export type CLOSE_ENCOUNTER_COMPLETED = typeof CLOSE_ENCOUNTER_COMPLETED;

export const CLOSE_ENCOUNTER_ERROR = 'CLOSE_ENCOUNTER_ERROR';
export type CLOSE_ENCOUNTER_ERROR = typeof CLOSE_ENCOUNTER_ERROR;

export const HEARTBEAT_ENCOUNTER_BEGIN = 'HEARTBEAT_ENCOUNTER_BEGIN';
export type HEARTBEAT_ENCOUNTER_BEGIN = typeof HEARTBEAT_ENCOUNTER_BEGIN;

export const HEARTBEAT_ENCOUNTER_COMPLETED = 'HEARTBEAT_ENCOUNTER_COMPLETED';
export type HEARTBEAT_ENCOUNTER_COMPLETED = typeof HEARTBEAT_ENCOUNTER_COMPLETED;

export const HEARTBEAT_ENCOUNTER_ERROR = 'HEARTBEAT_ENCOUNTER_ERROR';
export type HEARTBEAT_ENCOUNTER_ERROR = typeof HEARTBEAT_ENCOUNTER_ERROR;

export const GET_ENCOUNTER_BEGIN = 'GET_ENCOUNTER_BEGIN';
export type GET_ENCOUNTER_BEGIN = typeof GET_ENCOUNTER_BEGIN;

export const GET_ENCOUNTER_COMPLETED = 'GET_ENCOUNTER_COMPLETED';
export type GET_ENCOUNTER_COMPLETED = typeof GET_ENCOUNTER_COMPLETED;

export const GET_ENCOUNTER_ERROR = 'GET_ENCOUNTER_ERROR';
export type GET_ENCOUNTER_ERROR = typeof GET_ENCOUNTER_ERROR;

export const SNAPSHOT_ENCOUNTER_BEGIN = 'SNAPSHOT_ENCOUNTER_BEGIN';
export type SNAPSHOT_ENCOUNTER_BEGIN = typeof SNAPSHOT_ENCOUNTER_BEGIN;

export const SNAPSHOT_ENCOUNTER_COMPLETED = 'SNAPSHOT_ENCOUNTER_COMPLETED';
export type SNAPSHOT_ENCOUNTER_COMPLETED = typeof SNAPSHOT_ENCOUNTER_COMPLETED;

export const SNAPSHOT_ENCOUNTER_ERROR = 'SNAPSHOT_ENCOUNTER_ERROR';
export type SNAPSHOT_ENCOUNTER_ERROR = typeof SNAPSHOT_ENCOUNTER_ERROR;

export const CREATE_ENCOUNTER_BEGIN = 'CREATE_ENCOUNTER_BEGIN';
export type CREATE_ENCOUNTER_BEGIN = typeof CREATE_ENCOUNTER_BEGIN;

export const CREATE_ENCOUNTER_COMPLETED = 'CREATE_ENCOUNTER_COMPLETED';
export type CREATE_ENCOUNTER_COMPLETED = typeof CREATE_ENCOUNTER_COMPLETED;

export const CREATE_ENCOUNTER_ERROR = 'CREATE_ENCOUNTER_ERROR';
export type CREATE_ENCOUNTER_ERROR = typeof CREATE_ENCOUNTER_ERROR;

export const PDX_ANALYZE_TOGGLE = 'PDX_ANALYZE_TOGGLE';
export type PDX_ANALYZE_TOGGLE = typeof PDX_ANALYZE_TOGGLE;

export const PDX_ANALYZE_BEGIN = 'PDX_ANALYZE_BEGIN';
export type PDX_ANALYZE_BEGIN = typeof PDX_ANALYZE_BEGIN;

export const PDX_ANALYZE_COMPLETED = 'PDX_ANALYZE_COMPLETED';
export type PDX_ANALYZE_COMPLETED = typeof PDX_ANALYZE_COMPLETED;

export const PDX_ANALYZE_ERROR = 'PDX_ANALYZE_ERROR';
export type PDX_ANALYZE_ERROR = typeof PDX_ANALYZE_ERROR;

export const DRG_ANALYZE_BEGIN = 'DRG_ANALYZE_BEGIN';
export type DRG_ANALYZE_BEGIN = typeof DRG_ANALYZE_BEGIN;

export const DRG_ANALYZE_COMPLETED = 'DRG_ANALYZE_COMPLETED';
export type DRG_ANALYZE_COMPLETED = typeof DRG_ANALYZE_COMPLETED;

export const DRG_ANALYZE_ERROR = 'DRG_ANALYZE_ERROR';
export type DRG_ANALYZE_ERROR = typeof DRG_ANALYZE_ERROR;

export const CODE_GRID_CHANGE = 'CODE_GRID_CHANGE';
export type CODE_GRID_CHANGE = typeof CODE_GRID_CHANGE;

export const CODE_GRID_CLEAR_AUTOCHANGE = 'CODE_GRID_CLEAR_AUTOCHANGE';
export type CODE_GRID_CLEAR_AUTOCHANGE = typeof CODE_GRID_CLEAR_AUTOCHANGE;

export const CODE_GRID_BLUR_CODE_FIELD = 'CODE_GRID_BLUR_CODE_FIELD';
export type CODE_GRID_BLUR_CODE_FIELD = typeof CODE_GRID_BLUR_CODE_FIELD;

export const CODE_GRID_TRY_BLUR_CODE_FIELD = 'CODE_GRID_TRY_BLUR_CODE_FIELD';
export type CODE_GRID_TRY_BLUR_CODE_FIELD = typeof CODE_GRID_TRY_BLUR_CODE_FIELD;

export const CODE_GRID_BLUR_FIELD = 'CODE_GRID_BLUR_FIELD';
export type CODE_GRID_BLUR_FIELD = typeof CODE_GRID_BLUR_FIELD;

export const CODE_GRID_TRY_KEYPRESS = 'CODE_GRID_TRY_KEYPRESS';
export type CODE_GRID_TRY_KEYPRESS = typeof CODE_GRID_TRY_KEYPRESS;

export const CODE_GRID_KEYPRESS = 'CODE_GRID_KEYPRESS';
export type CODE_GRID_KEYPRESS = typeof CODE_GRID_KEYPRESS;

export const CODE_GRID_CODE_VALIDATION_BEGIN = 'CODE_GRID_CODE_VALIDATION_BEGIN';
export type CODE_GRID_CODE_VALIDATION_BEGIN = typeof CODE_GRID_CODE_VALIDATION_BEGIN;

export const CODE_GRID_CODE_VALIDATION_COMPLETE = 'CODE_GRID_CODE_VALIDATION_COMPLETE';
export type CODE_GRID_CODE_VALIDATION_COMPLETE = typeof CODE_GRID_CODE_VALIDATION_COMPLETE;

export const CODE_GRID_REMOVE = 'CODE_GRID_REMOVE';
export type CODE_GRID_REMOVE = typeof CODE_GRID_REMOVE;

export const CODE_GRID_MOVE_TO_PDX = 'CODE_GRID_MOVE_TO_PDX';
export type CODE_GRID_MOVE_TO_PDX = typeof CODE_GRID_MOVE_TO_PDX;

export const CODE_GRID_DRAG_DROP_ROW = 'CODE_GRID_DRAG_DROP_ROW';
export type CODE_GRID_DRAG_DROP_ROW = typeof CODE_GRID_DRAG_DROP_ROW;

export const CODE_GRID_TRY_ENTER_EDIT = 'CODE_GRID_TRY_ENTER_EDIT';
export type CODE_GRID_TRY_ENTER_EDIT = typeof CODE_GRID_TRY_ENTER_EDIT;

export const CODE_GRID_ENTER_EDIT = 'CODE_GRID_ENTER_EDIT';
export type CODE_GRID_ENTER_EDIT = typeof CODE_GRID_ENTER_EDIT;

export const CODE_GRID_CANCEL_EDIT = 'CODE_GRID_CANCEL_EDIT';
export type CODE_GRID_CANCEL_EDIT = typeof CODE_GRID_CANCEL_EDIT;

export const CODE_GRID_TRY_EXIT_EDIT = 'CODE_GRID_TRY_EXIT_EDIT';
export type CODE_GRID_TRY_EXIT_EDIT = typeof CODE_GRID_TRY_EXIT_EDIT;

export const CODE_GRID_EXIT_EDIT = 'CODE_GRID_EXIT_EDIT';
export type CODE_GRID_EXIT_EDIT = typeof CODE_GRID_EXIT_EDIT;

export const CODE_GRID_EXIT_ALL_EDIT = 'CODE_GRID_EXIT_ALL_EDIT';
export type CODE_GRID_EXIT_ALL_EDIT = typeof CODE_GRID_EXIT_ALL_EDIT;

export const RESEARCH_PANE_INTERACTION = 'RESEARCH_PANE_INTERACTION';
export type RESEARCH_PANE_INTERACTION = typeof RESEARCH_PANE_INTERACTION;

export const RESEARCH_EDIT_LOCATE_EVENT = 'RESEARCH_EDIT_LOCATE_EVENT';
export type RESEARCH_EDIT_LOCATE_EVENT = typeof RESEARCH_EDIT_LOCATE_EVENT;

export const CODEBOOKS_MODAL_SHOW = 'CODEBOOKS_MODAL_SHOW';
export type CODEBOOKS_MODAL_SHOW = typeof CODEBOOKS_MODAL_SHOW;

export const CODEBOOKS_MODAL_HIDE = 'CODEBOOKS_MODAL_HIDE';
export type CODEBOOKS_MODAL_HIDE = typeof CODEBOOKS_MODAL_HIDE;

export const CODEBOOKS_MODAL_SET_REFS = 'CODEBOOKS_MODAL_SET_REFS';
export type CODEBOOKS_MODAL_SET_REFS = typeof CODEBOOKS_MODAL_SET_REFS;

export const CODEBOOKS_MODAL_TRY_CODE_POSTED = 'CODEBOOKS_MODAL_TRY_CODE_POSTED';
export type CODEBOOKS_MODAL_TRY_CODE_POSTED = typeof CODEBOOKS_MODAL_TRY_CODE_POSTED;

export const CODEBOOKS_MODAL_CODE_POSTED = 'CODEBOOKS_MODAL_CODE_POSTED';
export type CODEBOOKS_MODAL_CODE_POSTED = typeof CODEBOOKS_MODAL_CODE_POSTED;

export const CODEBOOKS_MODAL_CANCELLED = 'CODEBOOKS_MODAL_CANCELLED';
export type CODEBOOKS_MODAL_CANCELLED = typeof CODEBOOKS_MODAL_CANCELLED;

export const REFERENCES_MODAL_SHOW = 'REFERENCES_MODAL_SHOW';
export type REFERENCES_MODAL_SHOW = typeof REFERENCES_MODAL_SHOW;

export const REFERENCES_MODAL_HIDE = 'REFERENCES_MODAL_HIDE';
export type REFERENCES_MODAL_HIDE = typeof REFERENCES_MODAL_HIDE;

export const REFERENCES_MODAL_SET_REFS = 'REFERENCES_MODAL_SET_REFS';
export type REFERENCES_MODAL_SET_REFS = typeof REFERENCES_MODAL_SET_REFS;

export const GET_ARTICLE_START = 'GET_ARTICLE_START';
export type GET_ARTICLE_START = typeof GET_ARTICLE_START;

export const GET_ARTICLE_COMPLETED = 'GET_ARTICLE_COMPLETED';
export type GET_ARTICLE_COMPLETED = typeof GET_ARTICLE_COMPLETED;

export const GET_ARTICLE_ERROR = 'GET_ARTICLE_ERROR';
export type GET_ARTICLE_ERROR = typeof GET_ARTICLE_ERROR;

export const SELECT_ARTICLE = 'SELECT_ARTICLE';
export type SELECT_ARTICLE = typeof SELECT_ARTICLE;

export const SELECT_POLICY_ARTICLE = 'SELECT_POLICY_ARTICLE';
export type SELECT_POLICY_ARTICLE = typeof SELECT_POLICY_ARTICLE;

export const GET_POLICY_ARTICLE_START = 'GET_POLICY_ARTICLE_START';
export type GET_POLICY_ARTICLE_START = typeof GET_POLICY_ARTICLE_START;

export const GET_POLICY_ARTICLE_COMPLETED = 'GET_POLICY_ARTICLE_COMPLETED';
export type GET_POLICY_ARTICLE_COMPLETED = typeof GET_POLICY_ARTICLE_COMPLETED;

export const GET_POLICY_ARTICLE_ERROR = 'GET_POLICY_ARTICLE_ERROR';
export type GET_POLICY_ARTICLE_ERROR = typeof GET_POLICY_ARTICLE_ERROR;

export const UPDATE_ENCOUNTER_FIELD = 'UPDATE_ENCOUNTER_FIELD';
export type UPDATE_ENCOUNTER_FIELD = typeof UPDATE_ENCOUNTER_FIELD;

export const UPDATE_ENCOUNTER_FIELDS = 'UPDATE_ENCOUNTER_FIELDS';
export type UPDATE_ENCOUNTER_FIELDS = typeof UPDATE_ENCOUNTER_FIELDS;

export const UPDATE_ENCOUNTER_CHOICE_LISTS = 'UPDATE_ENCOUNTER_CHOICE_LISTS';
export type UPDATE_ENCOUNTER_CHOICE_LISTS = typeof UPDATE_ENCOUNTER_CHOICE_LISTS;

export const UPDATE_ENCODER_USER_FIELD = 'UPDATE_ENCODER_USER_FIELD';
export type UPDATE_ENCODER_USER_FIELD = typeof UPDATE_ENCODER_USER_FIELD;

export const UPDATE_ENCOUNTER_FACILITY = 'UPDATE_ENCOUNTER_FACILITY';
export type UPDATE_ENCOUNTER_FACILITY= typeof UPDATE_ENCODER_USER_FIELD;

export const UPDATE_ENCOUNTER_FACILITY_FAIL = 'UPDATE_ENCOUNTER_FACILITY_FAIL';
export type UPDATE_ENCOUNTER_FACILITY_FAIL = typeof UPDATE_ENCOUNTER_FACILITY_FAIL;

export const UPDATE_FACILITY_ROLES = 'UPDATE_FACILITY_ROLES';
export type UPDATE_FACILITY_ROLES = typeof UPDATE_FACILITY_ROLES;

export const SET_ALL_FACILITY_ROLES = 'SET_ALL_FACILITY_ROLES';
export type SET_ALL_FACILITY_ROLES = typeof SET_ALL_FACILITY_ROLES;

export const ADD_NEW_USER = 'ADD_NEW_USER';
export type ADD_NEW_USER = typeof ADD_NEW_USER;

export const ENCOUNTER_TEMPLATES_SET = 'ENCOUNTER_TEMPLATES_SET';
export type ENCOUNTER_TEMPLATES_SET = typeof ENCOUNTER_TEMPLATES_SET;

export const ENCOUNTER_TEMPLATES_ADD = 'ENCOUNTER_TEMPLATES_ADD';
export type ENCOUNTER_TEMPLATES_ADD = typeof ENCOUNTER_TEMPLATES_ADD;

export const ENCOUNTER_TEMPLATES_EDIT_STARTED = 'ENCOUNTER_TEMPLATES_EDIT_STARTED';
export type ENCOUNTER_TEMPLATES_EDIT_STARTED = typeof ENCOUNTER_TEMPLATES_EDIT_STARTED;

export const ENCOUNTER_TEMPLATES_EDIT_CHANGE_FIELD = 'ENCOUNTER_TEMPLATES_EDIT_CHANGE_FIELD';
export type ENCOUNTER_TEMPLATES_EDIT_CHANGE_FIELD = typeof ENCOUNTER_TEMPLATES_EDIT_CHANGE_FIELD;

export const ENCOUNTER_TEMPLATES_EDIT_CANCELLED = 'ENCOUNTER_TEMPLATES_EDIT_CANCELLED';
export type ENCOUNTER_TEMPLATES_EDIT_CANCELLED = typeof ENCOUNTER_TEMPLATES_EDIT_CANCELLED;

export const CREATE_ENCOUNTER_FROM_TEMPLATE = 'CREATE_ENCOUNTER_FROM_TEMPLATE';
export type CREATE_ENCOUNTER_FROM_TEMPLATE = typeof CREATE_ENCOUNTER_FROM_TEMPLATE;

export const ENCOUNTER_TEMPLATES_LIST_BEGIN = 'ENCOUNTER_TEMPLATES_LIST_BEGIN';
export type ENCOUNTER_TEMPLATES_LIST_BEGIN = typeof ENCOUNTER_TEMPLATES_LIST_BEGIN;

export const ENCOUNTER_TEMPLATES_LIST_COMPLETED = 'ENCOUNTER_TEMPLATES_LIST_COMPLETED';
export type ENCOUNTER_TEMPLATES_LIST_COMPLETED = typeof ENCOUNTER_TEMPLATES_LIST_COMPLETED;

export const ENCOUNTER_TEMPLATES_LIST_ERROR = 'ENCOUNTER_TEMPLATES_LIST_ERROR';
export type ENCOUNTER_TEMPLATES_LIST_ERROR = typeof ENCOUNTER_TEMPLATES_LIST_ERROR;

export const ENCOUNTER_TEMPLATES_UPDATE_BEGIN = 'ENCOUNTER_TEMPLATES_UPDATE_BEGIN';
export type ENCOUNTER_TEMPLATES_UPDATE_BEGIN = typeof ENCOUNTER_TEMPLATES_UPDATE_BEGIN;

export const ENCOUNTER_TEMPLATES_UPDATE_COMPLETED = 'ENCOUNTER_TEMPLATES_UPDATE_COMPLETED';
export type ENCOUNTER_TEMPLATES_UPDATE_COMPLETED = typeof ENCOUNTER_TEMPLATES_UPDATE_COMPLETED;

export const ENCOUNTER_TEMPLATES_UPDATE_ERROR = 'ENCOUNTER_TEMPLATES_UPDATE_ERROR';
export type ENCOUNTER_TEMPLATES_UPDATE_ERROR = typeof ENCOUNTER_TEMPLATES_UPDATE_ERROR;

export const ENCOUNTER_TEMPLATES_DELETE_BEGIN = 'ENCOUNTER_TEMPLATES_DELETE_BEGIN';
export type ENCOUNTER_TEMPLATES_DELETE_BEGIN = typeof ENCOUNTER_TEMPLATES_DELETE_BEGIN;

export const ENCOUNTER_TEMPLATES_DELETE_COMPLETED = 'ENCOUNTER_TEMPLATES_DELETE_COMPLETED';
export type ENCOUNTER_TEMPLATES_DELETE_COMPLETED = typeof ENCOUNTER_TEMPLATES_DELETE_COMPLETED;

export const ENCOUNTER_TEMPLATES_DELETE_ERROR = 'ENCOUNTER_TEMPLATES_DELETE_ERROR';
export type ENCOUNTER_TEMPLATES_DELETE_ERROR = typeof ENCOUNTER_TEMPLATES_DELETE_ERROR;

export const ENCOUNTER_TEMPLATES_CREATE_BEGIN = 'ENCOUNTER_TEMPLATES_CREATE_BEGIN';
export type ENCOUNTER_TEMPLATES_CREATE_BEGIN = typeof ENCOUNTER_TEMPLATES_CREATE_BEGIN;

export const ENCOUNTER_TEMPLATES_CREATE_COMPLETED = 'ENCOUNTER_TEMPLATES_CREATE_COMPLETED';
export type ENCOUNTER_TEMPLATES_CREATE_COMPLETED = typeof ENCOUNTER_TEMPLATES_CREATE_COMPLETED;

export const ENCOUNTER_TEMPLATES_CREATE_ERROR = 'ENCOUNTER_TEMPLATES_CREATE_ERROR';
export type ENCOUNTER_TEMPLATES_CREATE_ERROR = typeof ENCOUNTER_TEMPLATES_CREATE_ERROR;

export const AVAILABLE_LICENSE_PACKAGES_BEGIN = 'AVAILABLE_LICENSE_PACKAGES_BEGIN';
export type AVAILABLE_LICENSE_PACKAGES_BEGIN = typeof AVAILABLE_LICENSE_PACKAGES_BEGIN;

export const AVAILABLE_LICENSE_PACKAGES_COMPLETED = 'AVAILABLE_LICENSE_PACKAGES_COMPLETED';
export type AVAILABLE_LICENSE_PACKAGES_COMPLETED = typeof AVAILABLE_LICENSE_PACKAGES_COMPLETED;

export const AVAILABLE_LICENSE_PACKAGES_ERROR = 'AVAILABLE_LICENSE_PACKAGES_ERROR';
export type AVAILABLE_LICENSE_PACKAGES_ERROR = typeof AVAILABLE_LICENSE_PACKAGES_ERROR;

export const CREATE_NEW_ENCOUNTER = 'CREATE_NEW_ENCOUNTER';
export type CREATE_NEW_ENCOUNTER = typeof CREATE_NEW_ENCOUNTER;

export const GET_ENCODER_USER_LIST_REQUEST_BEGIN = 'GET_ENCODER_USER_LIST_REQUEST_BEGIN';
export type GET_ENCODER_USER_LIST_REQUEST_BEGIN = typeof GET_ENCODER_USER_LIST_REQUEST_BEGIN;

export const GET_ENCODER_USER_LIST_COMPLETED = 'GET_ENCODER_USER_LIST_COMPLETED';
export type GET_ENCODER_USER_LIST_COMPLETED = typeof GET_ENCODER_USER_LIST_COMPLETED;

export const GET_ENCODER_USER_LIST_ERROR = 'GET_ENCODER_USER_LIST_ERROR';
export type GET_ENCODER_USER_LIST_ERROR = typeof GET_ENCODER_USER_LIST_ERROR;

export const REMOVE_ENCODER_USER_REQUEST_BEGIN = 'REMOVE_ENCODER_USER_REQUEST_BEGIN';
export type REMOVE_ENCODER_USER_REQUEST_BEGIN = typeof REMOVE_ENCODER_USER_REQUEST_BEGIN;

export const REMOVE_ENCODER_USER_COMPLETED = 'REMOVE_ENCODER_USER_COMPLETED';
export type REMOVE_ENCODER_USER_COMPLETED = typeof REMOVE_ENCODER_USER_COMPLETED;

export const REMOVE_ENCODER_USER_ERROR = 'REMOVE_ENCODER_USER_ERROR';
export type REMOVE_ENCODER_USER_ERROR = typeof REMOVE_ENCODER_USER_ERROR;

export const SAVE_ENCODER_USER_REQUEST_BEGIN = 'SAVE_ENCODER_USER_REQUEST_BEGIN';
export type SAVE_ENCODER_USER_REQUEST_BEGIN = typeof SAVE_ENCODER_USER_REQUEST_BEGIN;

export const SAVE_ENCODER_USER_COMPLETED = 'SAVE_ENCODER_USER_COMPLETED';
export type SAVE_ENCODER_USER_COMPLETED = typeof SAVE_ENCODER_USER_COMPLETED;

export const SAVE_ENCODER_USER_ERROR = 'SAVE_ENCODER_USER_ERROR';
export type SAVE_ENCODER_USER_ERROR = typeof SAVE_ENCODER_USER_ERROR;

export const GET_ENCODER_USER_REQUEST_BEGIN = 'GET_ENCODER_USER_REQUEST_BEGIN';
export type GET_ENCODER_USER_REQUEST_BEGIN = typeof GET_ENCODER_USER_REQUEST_BEGIN;

export const GET_ENCODER_USER_COMPLETED = 'GET_ENCODER_USER_COMPLETED';
export type GET_ENCODER_USER_COMPLETED = typeof GET_ENCODER_USER_COMPLETED;

export const GET_ENCODER_USER_ERROR = 'GET_ENCODER_USER_ERROR';
export type GET_ENCODER_USER_ERROR = typeof GET_ENCODER_USER_ERROR;

export const SEARCH_ENCOUNTER_FILTER_CLEAR = 'SEARCH_ENCOUNTER_FILTER_CLEAR';
export type SEARCH_ENCOUNTER_FILTER_CLEAR = typeof SEARCH_ENCOUNTER_FILTER_CLEAR;

export const SEARCH_ENCOUNTER_FILTER_CHANGE_FIELD = 'SEARCH_ENCOUNTER_FILTER_CHANGE_FIELD';
export type SEARCH_ENCOUNTER_FILTER_CHANGE_FIELD = typeof SEARCH_ENCOUNTER_FILTER_CHANGE_FIELD;

export const SEARCH_ENCOUNTER_FILTER_SEARCH_BEGIN = 'SEARCH_ENCOUNTER_FILTER_SEARCH_BEGIN';
export type SEARCH_ENCOUNTER_FILTER_SEARCH_BEGIN = typeof SEARCH_ENCOUNTER_FILTER_SEARCH_BEGIN;

export const SEARCH_ENCOUNTER_FILTER_SEARCH_COMPLETED = 'SEARCH_ENCOUNTER_FILTER_SEARCH_COMPLETED';
export type SEARCH_ENCOUNTER_FILTER_SEARCH_COMPLETED = typeof SEARCH_ENCOUNTER_FILTER_SEARCH_COMPLETED;

export const SEARCH_ENCOUNTER_FILTER_SEARCH_ERROR = 'SEARCH_ENCOUNTER_FILTER_SEARCH_ERROR';
export type SEARCH_ENCOUNTER_FILTER_SEARCH_ERROR = typeof SEARCH_ENCOUNTER_FILTER_SEARCH_ERROR;

export const SEARCH_ENCOUNTER_FILTER_SEARCH_ABORTED = 'SEARCH_ENCOUNTER_FILTER_SEARCH_ABORTED';
export type SEARCH_ENCOUNTER_FILTER_SEARCH_ABORTED = typeof SEARCH_ENCOUNTER_FILTER_SEARCH_ABORTED;

export const SEARCH_ENCOUNTER_FILTER_SELECT_ROW = 'SEARCH_ENCOUNTER_FILTER_SELECT_ROW';
export type SEARCH_ENCOUNTER_FILTER_SELECT_ROW = typeof SEARCH_ENCOUNTER_FILTER_SELECT_ROW;

export const NAVIGATE_FROM_ADMIT_DX_DESCRIPTION = 'NAVIGATE_FROM_ADMIT_DX_DESCRIPTION';
export type NAVIGATE_FROM_ADMIT_DX_DESCRIPTION = typeof NAVIGATE_FROM_ADMIT_DX_DESCRIPTION;

export const ADMIT_DX_DESCRIPTION_BLUR = 'ADMIT_DX_DESCRIPTION_BLUR';
export type ADMIT_DX_DESCRIPTION_BLUR = typeof ADMIT_DX_DESCRIPTION_BLUR;

export const ENCOUNTER_PROMPT_DISCARD_DIRTY_CHANGES = 'ENCOUNTER_PROMPT_DISCARD_DIRTY_CHANGES';
export type ENCOUNTER_PROMPT_DISCARD_DIRTY_CHANGES = typeof ENCOUNTER_PROMPT_DISCARD_DIRTY_CHANGES;

export const ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_UI = 'ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_UI';
export type ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_UI = typeof ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_UI;

export const ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_ENC = 'ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_ENC';
export type ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_ENC = typeof ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_ENC;

export const ENCOUNTER_CLEAR_DIRTY_CHANGES_FLAG = 'ENCOUNTER_CLEAR_DIRTY_CHANGES_FLAG';
export type ENCOUNTER_CLEAR_DIRTY_CHANGES_FLAG = typeof ENCOUNTER_CLEAR_DIRTY_CHANGES_FLAG;

export const CHOICE_LISTS_PROMPT_DISCARD_DIRTY_CHANGES = 'CHOICE_LISTS_PROMPT_DISCARD_DIRTY_CHANGES';
export type CHOICE_LISTS_PROMPT_DISCARD_DIRTY_CHANGES = typeof CHOICE_LISTS_PROMPT_DISCARD_DIRTY_CHANGES;

export const SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES_UI = 'SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES_UI';
export type SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES_UI = typeof SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES_UI;

export const SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES = 'SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES';
export type SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES = typeof SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES;

export const UI_ADD_ERRORS = 'UI_ADD_ERRORS';
export type UI_ADD_ERRORS = typeof UI_ADD_ERRORS;

export const UI_CLEAR_ERRORS = 'UI_CLEAR_ERRORS';
export type UI_CLEAR_ERRORS = typeof UI_CLEAR_ERRORS;

export const UI_INIT_COLUMNS_SETTINGS = 'UI_INIT_COLUMNS_SETTINGS';
export type UI_INIT_COLUMNS_SETTINGS = typeof UI_INIT_COLUMNS_SETTINGS;

export const UI_CHANGE_GRID_SETTINGS = 'UI_CHANGE_GRID_SETTINGS';
export type UI_CHANGE_GRID_SETTINGS = typeof UI_CHANGE_GRID_SETTINGS;

export const UI_REORDER_SHOW_WARNING = 'UI_REORDER_SHOW_WARNING';
export type UI_REORDER_SHOW_WARNING = typeof UI_REORDER_SHOW_WARNING;

export const UI_RESIZE_SHOW_WARNING = 'UI_RESIZE_SHOW_WARNING';
export type UI_RESIZE_SHOW_WARNING = typeof UI_RESIZE_SHOW_WARNING;

export const GET_USER_PREFERENCE = 'GET_USER_PREFERENCE';
export type GET_USER_PREFERENCE = typeof GET_USER_PREFERENCE;

export const SET_USER_PREFERENCE_BEGIN = 'SET_USER_PREFERENCE_BEGIN';
export type SET_USER_PREFERENCE_BEGIN = typeof SET_USER_PREFERENCE_BEGIN;

export const SET_USER_PREFERENCE_COMPLETED = 'SET_USER_PREFERENCE_COMPLETED';
export type SET_USER_PREFERENCE_COMPLETED = typeof SET_USER_PREFERENCE_COMPLETED;

export const SET_USER_PREFERENCE_ERROR = 'SET_USER_PREFERENCE_ERROR';
export type SET_USER_PREFERENCE_ERROR = typeof SET_USER_PREFERENCE_ERROR;

export const DELETE_USER_PREFERENCE = 'DELETE_USER_PREFERENCE';
export type DELETE_USER_PREFERENCE = typeof DELETE_USER_PREFERENCE;

export const GET_USER_PREFERENCES_BEGIN = 'GET_USER_PREFERENCES_BEGIN';
export type GET_USER_PREFERENCES_BEGIN = typeof GET_USER_PREFERENCES_BEGIN;

export const GET_USER_PREFERENCES_COMPLETED = 'GET_USER_PREFERENCES_COMPLETED';
export type GET_USER_PREFERENCES_COMPLETED = typeof GET_USER_PREFERENCES_COMPLETED;

export const GET_USER_PREFERENCES_ERROR = 'GET_USER_PREFERENCES_ERROR';
export type GET_USER_PREFERENCES_ERROR = typeof GET_USER_PREFERENCES_ERROR;

export const GET_CURRENT_USER_BEGIN = 'GET_CURRENT_USER_BEGIN';
export type GET_CURRENT_USER_BEGIN = typeof GET_CURRENT_USER_BEGIN;

export const GET_CURRENT_USER_COMPLETED = 'GET_CURRENT_USER_COMPLETED';
export type GET_CURRENT_USER_COMPLETED = typeof GET_CURRENT_USER_COMPLETED;

export const GET_CURRENT_USER_ORGANIZATION_COMPLETED = 'GET_CURRENT_USER_ORGANIZATION_COMPLETED';
export type GET_CURRENT_USER_ORGANIZATION_COMPLETED = typeof GET_CURRENT_USER_ORGANIZATION_COMPLETED;

export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';
export type GET_CURRENT_USER_ERROR = typeof GET_CURRENT_USER_ERROR;

export const ENCOUNTER_CHANGE_GROUPING = 'ENCOUNTER_CHANGE_GROUPING';
export type ENCOUNTER_CHANGE_GROUPING = typeof ENCOUNTER_CHANGE_GROUPING;

export const GET_CODE_METADATA_BEGIN = 'GET_CODE_METADATA_BEGIN';
export type GET_CODE_METADATA_BEGIN = typeof GET_CODE_METADATA_BEGIN;

export const GET_CODE_METADATA_COMPLETED = 'GET_CODE_METADATA_COMPLETED';
export type GET_CODE_METADATA_COMPLETED = typeof GET_CODE_METADATA_COMPLETED;

export const GET_CODE_METADATA_FAIL = 'GET_CODE_METADATA_FAIL';
export type GET_CODE_METADATA_FAIL = typeof GET_CODE_METADATA_FAIL;

export const GET_CHOICE_LIST_START = 'GET_CHOICE_LIST_START';
export type GET_CHOICE_LIST_START = typeof GET_CHOICE_LIST_START;

export const GET_CHOICE_LIST_END = 'GET_CHOICE_LIST_END';
export type GET_CHOICE_LIST_END = typeof GET_CHOICE_LIST_END;

export const GET_FACILITIES_COMPLETED = 'GET_FACILITIES_COMPLETED';
export type GET_FACILITIES_COMPLETED = typeof GET_FACILITIES_COMPLETED;

export const GET_FACILITIES_FAIL = 'GET_FACILITIES_FAIL';
export type GET_FACILITIES_FAIL = typeof GET_FACILITIES_FAIL;

export const SET_FACILITY_SETTINGS_BEGIN = 'SET_FACILITY_SETTINGS_BEGIN';
export type SET_FACILITY_SETTINGS_BEGIN = typeof SET_FACILITY_SETTINGS_BEGIN;

export const SET_FACILITY_SETTINGS = 'SET_FACILITY_SETTINGS';
export type SET_FACILITY_SETTINGS = typeof SET_FACILITY_SETTINGS;

export const SET_FACILITY_PREFERENCES = 'SET_FACILITY_PREFERENCES';
export type SET_FACILITY_PREFERENCES = typeof SET_FACILITY_PREFERENCES;

export const GET_FACILITY_SETTINGS_FAIL = 'GET_FACILITY_SETTINGS_FAIL';
export type GET_FACILITY_SETTINGS_FAIL = typeof GET_FACILITY_SETTINGS_FAIL;

export const SET_CURRENT_ENCOUNTER_FACILITIES = 'SET_CURRENT_ENCOUNTER_FACILITIES';
export type SET_CURRENT_ENCOUNTER_FACILITIES = typeof SET_CURRENT_ENCOUNTER_FACILITIES;

export const MARK_ENCOUNTER_OUTDATED = 'MARK_ENCOUNTER_OUTDATED';
export type MARK_ENCOUNTER_OUTDATED = typeof MARK_ENCOUNTER_OUTDATED;

export const VALUES_GRID_ENTER_EDIT = 'VALUES_GRID_ENTER_EDIT';
export type VALUES_GRID_ENTER_EDIT = typeof VALUES_GRID_ENTER_EDIT;

export const VALUES_GRID_CHANGE = 'VALUES_GRID_CHANGE';
export type VALUES_GRID_CHANGE = typeof VALUES_GRID_CHANGE;

export const VALUES_GRID_KEYPRESS = 'VALUES_GRID_KEYPRESS';
export type VALUES_GRID_KEYPRESS = typeof VALUES_GRID_KEYPRESS;

export const VALUES_GRID_EXIT_EDIT = 'VALUES_GRID_EXIT_EDIT';
export type VALUES_GRID_EXIT_EDIT = typeof VALUES_GRID_EXIT_EDIT;

export const VALUES_GRID_DRAG_DROP_ROW = 'VALUES_GRID_DRAG_DROP_ROW';
export type VALUES_GRID_DRAG_DROP_ROW = typeof VALUES_GRID_DRAG_DROP_ROW;

export const VALUES_GRID_NAVIGATE = 'VALUES_GRID_NAVIGATE';
export type VALUES_GRID_NAVIGATE = typeof VALUES_GRID_NAVIGATE;

export const CHANGE_SHOW_ONLY_APPROVED_MODIFIERS = 'CHANGE_SHOW_ONLY_APPROVED_MODIFIERS';
export type CHANGE_SHOW_ONLY_APPROVED_MODIFIERS = typeof CHANGE_SHOW_ONLY_APPROVED_MODIFIERS;

export const GET_CHOICELISTS_FAIL = 'GET_CHOICELISTS_FAIL';
export type GET_CHOICELISTS_FAIL = typeof GET_CHOICELISTS_FAIL;

export const GET_CHOICELIST_SUCCESS = 'GET_CHOICELIST_SUCCESS';
export type GET_CHOICELIST_SUCCESS = typeof GET_CHOICELIST_SUCCESS;

export const GET_CHOICELIST_FAIL = 'GET_CHOICELIST_FAIL';
export type GET_CHOICELIST_FAIL = typeof GET_CHOICELIST_FAIL;

export const SET_CHOICELISTS_LOADING = 'SET_CHOICELISTS_LOADING';
export type SET_CHOICELISTS_LOADING = typeof SET_CHOICELISTS_LOADING;

export const CACHED_CHOICELIST_LOADED = 'CACHED_CHOICELIST_LOADED';
export type CACHED_CHOICELIST_LOADED = typeof CACHED_CHOICELIST_LOADED;

export const CACHED_CHOICELIST_CHANGE_STATUS = 'CACHED_CHOICELIST_CHANGE_STATUS';
export type CACHED_CHOICELIST_CHANGE_STATUS = typeof CACHED_CHOICELIST_CHANGE_STATUS;

export const SET_NEED_SAVE_ENCOUNTER = 'SET_NEED_SAVE_ENCOUNTER';
export type SET_NEED_SAVE_ENCOUNTER = typeof SET_NEED_SAVE_ENCOUNTER;

export const SET_SHOW_PREVENT_SAVE = 'SET_SHOW_PREVENT_SAVE';
export type SET_SHOW_PREVENT_SAVE = typeof SET_SHOW_PREVENT_SAVE;

export const SET_NEED_RESEQUENCE_ENCOUNTER_CODES = 'SET_NEED_RESEQUENCE_ENCOUNTER_CODES';
export type SET_NEED_RESEQUENCE_ENCOUNTER_CODES = typeof SET_NEED_RESEQUENCE_ENCOUNTER_CODES;

export const SET_CODE_GRID_DRAGGING = 'SET_CODE_GRID_DRAGGING';
export type SET_CODE_GRID_DRAGGING = typeof SET_CODE_GRID_DRAGGING;

export const RESEQUENCE_ENCOUNTER_CODES = 'RESEQUENCE_ENCOUNTER_CODES';
export type RESEQUENCE_ENCOUNTER_CODES = typeof RESEQUENCE_ENCOUNTER_CODES;

export const APPLY_FACILITY_PREFERENCES = 'APPLY_FACILITY_PREFERENCES';
export type APPLY_FACILITY_PREFERENCES = typeof APPLY_FACILITY_PREFERENCES;

export const ENCOUNTER_GRIDS_FOCUS_FIRST_FIELD = 'ENCOUNTER_GRIDS_FOCUS_FIRST_FIELD';
export type ENCOUNTER_GRIDS_FOCUS_FIRST_FIELD = typeof ENCOUNTER_GRIDS_FOCUS_FIRST_FIELD;

export const CLEAR_GRID = 'CLEAR_GRID';
export type CLEAR_GRID = typeof CLEAR_GRID;

export const SET_ALL_POA_TO_VALUE = 'SET_ALL_POA_TO_VALUE';
export type SET_ALL_POA_TO_VALUE = typeof SET_ALL_POA_TO_VALUE;

export const UPDATE_EXEMPT_POA_VALUES = 'UPDATE_EXEMPT_POA_VALUES';
export type UPDATE_EXEMPT_POA_VALUES = typeof UPDATE_EXEMPT_POA_VALUES;

export const INSERT_ROW_BEFORE = 'INSERT_ROW_BEFORE';
export type INSERT_ROW_BEFORE = typeof INSERT_ROW_BEFORE;

export const INSERT_ROW_AFTER = 'INSERT_ROW_AFTER';
export type INSERT_ROW_AFTER = typeof INSERT_ROW_AFTER;

export const CLEAR_EMPTY_ROWS_IN_GRID = 'CLEAR_EMPTY_ROWS_IN_GRID';
export type CLEAR_EMPTY_ROWS_IN_GRID = typeof CLEAR_EMPTY_ROWS_IN_GRID;

export const FC_SELECT_FACILITY = 'FC_SELECT_FACILITY';
export type FC_SELECT_FACILITY = typeof FC_SELECT_FACILITY;

export const FC_GET_FINANCIAL_CLASSES_BEGIN = 'FC_GET_FINANCIAL_CLASSES_BEGIN';
export type FC_GET_FINANCIAL_CLASSES_BEGIN = typeof FC_GET_FINANCIAL_CLASSES_BEGIN;

export const FC_GET_FINANCIAL_CLASSES_COMPLETED = 'FC_GET_FINANCIAL_CLASSES_COMPLETED';
export type FC_GET_FINANCIAL_CLASSES_COMPLETED = typeof FC_GET_FINANCIAL_CLASSES_COMPLETED;

export const FC_GET_FINANCIAL_CLASSES_FAIL = 'FC_GET_FINANCIAL_CLASSES_FAIL';
export type FC_GET_FINANCIAL_CLASSES_FAIL = typeof FC_GET_FINANCIAL_CLASSES_FAIL;

export const FC_GET_FINANCIAL_CLASSES_PAGE_BEGIN = 'FC_GET_FINANCIAL_CLASSES_PAGE_BEGIN';
export type FC_GET_FINANCIAL_CLASSES_PAGE_BEGIN = typeof FC_GET_FINANCIAL_CLASSES_PAGE_BEGIN;

export const FC_GET_FINANCIAL_CLASSES_PAGE_COMPLETED = 'FC_GET_FINANCIAL_CLASSES_PAGE_COMPLETED';
export type FC_GET_FINANCIAL_CLASSES_PAGE_COMPLETED = typeof FC_GET_FINANCIAL_CLASSES_PAGE_COMPLETED;

export const FC_GET_FINANCIAL_CLASSES_PAGE_FAIL = 'FC_GET_FINANCIAL_CLASSES_PAGE_FAIL';
export type FC_GET_FINANCIAL_CLASSES_PAGE_FAIL = typeof FC_GET_FINANCIAL_CLASSES_PAGE_FAIL;

export const FC_TOGGLE_HIDE_INACTIVE_FCS = 'FC_TOGGLE_HIDE_INACTIVE_FCS';
export type FC_TOGGLE_HIDE_INACTIVE_FCS = typeof FC_TOGGLE_HIDE_INACTIVE_FCS;

export const FC_FINANCIAL_CLASS_ADD = 'FC_FINANCIAL_CLASS_ADD';
export type FC_FINANCIAL_CLASS_ADD = typeof FC_FINANCIAL_CLASS_ADD;

export const FC_FINANCIAL_CLASS_DELETE = 'FC_FINANCIAL_CLASS_DELETE';
export type FC_FINANCIAL_CLASS_DELETE = typeof FC_FINANCIAL_CLASS_DELETE;

export const FC_FINANCIAL_CLASS_CHANGE = 'FC_FINANCIAL_CLASS_CHANGE';
export type FC_FINANCIAL_CLASS_CHANGE = typeof FC_FINANCIAL_CLASS_CHANGE;

export const FC_SHOW_SETTINGS = 'FC_SHOW_SETTINGS';
export type FC_SHOW_SETTINGS = typeof FC_SHOW_SETTINGS;

export const FC_GET_SETTINGS_BEGIN = 'FC_GET_SETTINGS_BEGIN';
export type FC_GET_SETTINGS_BEGIN = typeof FC_GET_SETTINGS_BEGIN;

export const FC_GET_SETTINGS_COMPLETED = 'FC_GET_SETTINGS_COMPLETED';
export type FC_GET_SETTINGS_COMPLETED = typeof FC_GET_SETTINGS_COMPLETED;

export const FC_GET_SETTINGS_FAIL = 'FC_GET_SETTINGS_FAIL';
export type FC_GET_SETTINGS_FAIL = typeof FC_GET_SETTINGS_FAIL;

export const FC_GET_SETTINGS_PAGE_BEGIN = 'FC_GET_SETTINGS_PAGE_BEGIN';
export type FC_GET_SETTINGS_PAGE_BEGIN = typeof FC_GET_SETTINGS_PAGE_BEGIN;

export const FC_GET_SETTINGS_PAGE_COMPLETED = 'FC_GET_SETTINGS_PAGE_COMPLETED';
export type FC_GET_SETTINGS_PAGE_COMPLETED = typeof FC_GET_SETTINGS_PAGE_COMPLETED;

export const FC_GET_SETTINGS_PAGE_FAIL = 'FC_GET_SETTINGS_PAGE_FAIL';
export type FC_GET_SETTINGS_PAGE_FAIL = typeof FC_GET_SETTINGS_PAGE_FAIL;

export const FC_IP_CARD_ADD = 'FC_IP_CARD_ADD';
export type FC_IP_CARD_ADD = typeof FC_IP_CARD_ADD;

export const FC_IP_CARD_CHANGE = 'FC_IP_CARD_CHANGE';
export type FC_IP_CARD_CHANGE = typeof FC_IP_CARD_CHANGE;

export const FC_IP_CARD_DELETE = 'FC_IP_CARD_DELETE';
export type FC_IP_CARD_DELETE = typeof FC_IP_CARD_DELETE;

export const FC_OP_CARD_ADD = 'FC_OP_CARD_ADD';
export type FC_OP_CARD_ADD = typeof FC_OP_CARD_ADD;

export const FC_OP_CARD_CHANGE = 'FC_OP_CARD_CHANGE';
export type FC_OP_CARD_CHANGE = typeof FC_OP_CARD_CHANGE;

export const FC_OP_CARD_DELETE = 'FC_OP_CARD_DELETE';
export type FC_OP_CARD_DELETE = typeof FC_OP_CARD_DELETE;

export const FC_SELECT_COMPARISON = 'FC_SELECT_COMPARISON';
export type FC_SELECT_COMPARISON = typeof FC_SELECT_COMPARISON;

export const FC_SHOW_SETTING = 'FC_SHOW_SETTING';
export type FC_SHOW_SETTING = typeof FC_SHOW_SETTING;

export const FC_GET_GROUPER_CARDS_BEGIN = 'FC_GET_GROUPER_CARDS_BEGIN';
export type FC_GET_GROUPER_CARDS_BEGIN = typeof FC_GET_GROUPER_CARDS_BEGIN;

export const FC_GET_GROUPER_CARDS_COMPLETED = 'FC_GET_GROUPER_CARDS_COMPLETED';
export type FC_GET_GROUPER_CARDS_COMPLETED = typeof FC_GET_GROUPER_CARDS_COMPLETED;

export const FC_GET_GROUPER_CARDS_FAIL = 'FC_GET_GROUPER_CARDS_FAIL';
export type FC_GET_GROUPER_CARDS_FAIL = typeof FC_GET_GROUPER_CARDS_FAIL;

export const FC_GROUPER_CARD_ADD = 'FC_GROUPER_CARD_ADD';
export type FC_GROUPER_CARD_ADD = typeof FC_GROUPER_CARD_ADD;

export const FC_GROUPER_CARD_CHANGE = 'FC_GROUPER_CARD_CHANGE';
export type FC_GROUPER_CARD_CHANGE = typeof FC_GROUPER_CARD_CHANGE;

export const FC_GROUPER_CARD_DELETE = 'FC_GROUPER_CARD_DELETE';
export type FC_GROUPER_CARD_DELETE = typeof FC_GROUPER_CARD_DELETE;

export const FC_START_ADD_FINANCIAL_CLASS = 'FC_START_ADD_FINANCIAL_CLASS';
export type FC_START_ADD_FINANCIAL_CLASS = typeof FC_START_ADD_FINANCIAL_CLASS;

export const FC_START_ADD_SETTING_CARD = 'FC_START_ADD_SETTING_CARD';
export type FC_START_ADD_SETTING_CARD = typeof FC_START_ADD_SETTING_CARD;

export const FC_START_ADD_GROUPER_CARD = 'FC_START_ADD_GROUPER_CARD';
export type FC_START_ADD_GROUPER_CARD = typeof FC_START_ADD_GROUPER_CARD;

export const FC_SHOW_PRICER_CARD = 'FC_SHOW_PRICER_CARD';
export type FC_SHOW_PRICER_CARD = typeof FC_SHOW_PRICER_CARD;

export const FC_GET_PRICER_CARD_BEGIN = 'FC_GET_PRICER_CARD_BEGIN';
export type FC_GET_PRICER_CARD_BEGIN = typeof FC_GET_PRICER_CARD_BEGIN;

export const FC_GET_PRICER_CARD_FAIL = 'FC_GET_PRICER_CARD_FAIL';
export type FC_GET_PRICER_CARD_FAIL = typeof FC_GET_PRICER_CARD_FAIL;

export const FC_PRICER_CARD_VALUE_CHANGE = 'FC_PRICER_CARD_VALUE_CHANGE';
export type FC_PRICER_CARD_VALUE_CHANGE = typeof FC_PRICER_CARD_VALUE_CHANGE;

export const FC_PRICER_CARD_VALUE_ENTER_EDIT = 'FC_PRICER_CARD_VALUE_ENTER_EDIT';
export type FC_PRICER_CARD_VALUE_ENTER_EDIT = typeof FC_PRICER_CARD_VALUE_ENTER_EDIT;

export const FC_PRICER_CARD_VALUE_KEYDOWN = 'FC_PRICER_CARD_VALUE_KEYDOWN';
export type FC_PRICER_CARD_VALUE_KEYDOWN = typeof FC_PRICER_CARD_VALUE_KEYDOWN;

export const FC_CONCURRENCY_INTERACTION_BEGIN = 'FC_CONCURRENCY_INTERACTION_BEGIN';
export type FC_CONCURRENCY_INTERACTION_BEGIN = typeof FC_CONCURRENCY_INTERACTION_BEGIN;

export const FC_CONCURRENCY_INTERACTION_COMPLETED = 'FC_CONCURRENCY_INTERACTION_COMPLETED';
export type FC_CONCURRENCY_INTERACTION_COMPLETED = typeof FC_CONCURRENCY_INTERACTION_COMPLETED;

export const FC_SET_ERROR_ITEM = 'FC_SET_ERROR_ITEM';
export type FC_SET_ERROR_ITEM = typeof FC_SET_ERROR_ITEM;

export const FC_SET_GROUPER_OPTIONS = 'FC_SET_GROUPER_OPTIONS';
export type FC_SET_GROUPER_OPTIONS = typeof FC_SET_GROUPER_OPTIONS;

export const FC_SET_GROUPER_HAS_FILE = 'FC_SET_GROUPER_HAS_FILE';
export type FC_SET_GROUPER_HAS_FILE = typeof FC_SET_GROUPER_HAS_FILE;

export const FC_SET_DRG_WEIGHTS_UPLOAD_MESSAGES = 'FC_SET_DRG_WEIGHTS_UPLOAD_MESSAGES';
export type FC_SET_DRG_WEIGHTS_UPLOAD_MESSAGES = typeof FC_SET_DRG_WEIGHTS_UPLOAD_MESSAGES;

export const FC_GET_DRG_WEIGHTS_BEGIN = 'FC_GET_DRG_WEIGHTS_BEGIN';
export type FC_GET_DRG_WEIGHTS_BEGIN = typeof FC_GET_DRG_WEIGHTS_BEGIN;

export const FC_GET_DRG_WEIGHTS_END = 'FC_GET_DRG_WEIGHTS_END';
export type FC_GET_DRG_WEIGHTS_END = typeof FC_GET_DRG_WEIGHTS_END;

export const FC_GET_DRG_WEIGHTS_ERROR = 'FC_GET_DRG_WEIGHTS_ERROR';
export type FC_GET_DRG_WEIGHTS_ERROR = typeof FC_GET_DRG_WEIGHTS_ERROR;

export const REVENUE_CODES_GET_BEGIN = 'REVENUE_CODES_GET_BEGIN';
export type REVENUE_CODES_GET_BEGIN = typeof REVENUE_CODES_GET_BEGIN;

export const REVENUE_CODES_GET_COMPLETED = 'REVENUE_CODES_GET_COMPLETED';
export type REVENUE_CODES_GET_COMPLETED = typeof REVENUE_CODES_GET_COMPLETED;

export const REVENUE_CODES_GET_ERROR = 'REVENUE_CODES_GET_ERROR';
export type REVENUE_CODES_GET_ERROR = typeof REVENUE_CODES_GET_ERROR;

export const STATUS_INDICATORS_GET_BEGIN = 'STATUS_INDICATORS_GET_BEGIN';
export type STATUS_INDICATORS_GET_BEGIN = typeof STATUS_INDICATORS_GET_BEGIN;

export const STATUS_INDICATORS_GET_COMPLETED = 'STATUS_INDICATORS_GET_COMPLETED';
export type STATUS_INDICATORS_GET_COMPLETED = typeof STATUS_INDICATORS_GET_COMPLETED;

export const STATUS_INDICATORS_GET_ERROR = 'STATUS_INDICATORS_GET_ERROR';
export type STATUS_INDICATORS_GET_ERROR = typeof STATUS_INDICATORS_GET_ERROR;

export const FACILITY_GET_FACILITIES_BEGIN = 'FACILITY_GET_FACILITIES_BEGIN';
export type FACILITY_GET_FACILITIES_BEGIN = typeof FACILITY_GET_FACILITIES_BEGIN;

export const FACILITY_GET_FACILITIES_COMPLETED = 'FACILITY_GET_FACILITIES_COMPLETED';
export type FACILITY_GET_FACILITIES_COMPLETED = typeof FACILITY_GET_FACILITIES_COMPLETED;

export const FACILITY_GET_FACILITIES_FAIL = 'FACILITY_GET_FACILITIES_FAIL';
export type FACILITY_GET_FACILITIES_FAIL = typeof FACILITY_GET_FACILITIES_FAIL;

export const FACILITY_CHANGE = 'FACILITY_CHANGE';
export type FACILITY_CHANGE = typeof FACILITY_CHANGE;

export const FACILITY_CONFIRM_CHANGE = 'FACILITY_CONFIRM_CHANGE';
export type FACILITY_CONFIRM_CHANGE = typeof FACILITY_CONFIRM_CHANGE;

export const FACILITY_DISCARD_CHANGE = 'FACILITY_DISCARD_CHANGE';
export type FACILITY_DISCARD_CHANGE = typeof FACILITY_DISCARD_CHANGE;

export const FACILITY_REMOVE = 'FACILITY_REMOVE';
export type FACILITY_REMOVE = typeof FACILITY_REMOVE;

export const FACILITY_CREATE = 'FACILITY_CREATE';
export type FACILITY_CREATE = typeof FACILITY_CREATE;

export const FACILITY_CONFIRM_CREATE = 'FACILITY_CONFIRM_CREATE';
export type FACILITY_CONFIRM_CREATE = typeof FACILITY_CONFIRM_CREATE;

export const FACILITY_DISCARD_CREATE = 'FACILITY_DISCARD_CREATE';
export type FACILITY_DISCARD_CREATE = typeof FACILITY_DISCARD_CREATE;

export const FACILITY_DISCARD_ALL_CHANGES = 'FACILITY_DISCARD_ALL_CHANGES';
export type FACILITY_DISCARD_ALL_CHANGES = typeof FACILITY_DISCARD_ALL_CHANGES;

export const FACILITY_SET_FACILITY_TYPES_DROPDOWN = 'FACILITY_SET_FACILITY_TYPES_DROPDOWN';
export type FACILITY_SET_FACILITY_TYPES_DROPDOWN = typeof FACILITY_SET_FACILITY_TYPES_DROPDOWN;

export const FACILITY_SET_MAC_DROPDOWN = 'FACILITY_SET_MAC_DROPDOWN';
export type FACILITY_SET_MAC_DROPDOWN = typeof FACILITY_SET_MAC_DROPDOWN;

export const FACILITY_SET_ERROR = 'FACILITY_SET_ERROR';
export type FACILITY_SET_ERROR = typeof FACILITY_SET_ERROR;

export const FACILITY_SHOW_ADD_FORM = 'FACILITY_SHOW_ADD_FORM';
export type FACILITY_SHOW_ADD_FORM = typeof FACILITY_SHOW_ADD_FORM;

export const FACILITY_SET_SAVING = 'FACILITY_SET_SAVING';
export type FACILITY_SET_SAVING = typeof FACILITY_SET_SAVING;

export const FACILITY_TOGGLE_HIDE_INACTIVE_ITEMS = 'FACILITY_TOGGLE_HIDE_INACTIVE_ITEMS';
export type FACILITY_TOGGLE_HIDE_INACTIVE_ITEMS = typeof FACILITY_TOGGLE_HIDE_INACTIVE_ITEMS;

export const PREFERENCES_SET_REVENUE_CODES = 'PREFERENCES_SET_REVENUE_CODES';
export type PREFERENCES_SET_REVENUE_CODES = typeof PREFERENCES_SET_REVENUE_CODES;

export const PREFERENCES_SELECT_FACILITY = 'PREFERENCES_SELECT_FACILITY';
export type PREFERENCES_SELECT_FACILITY = typeof PREFERENCES_SELECT_FACILITY;

export const PREFERENCES_SELECT_PREFERENCES_TYPE = 'PREFERENCES_SELECT_PREFERENCES_TYPE';
export type PREFERENCES_SELECT_PREFERENCES_TYPE = typeof PREFERENCES_SELECT_PREFERENCES_TYPE;

export const PREFERENCES_CHANGE_PREFERENCE = 'PREFERENCES_CHANGE_PREFERENCE';
export type PREFERENCES_CHANGE_PREFERENCE = typeof PREFERENCES_CHANGE_PREFERENCE;

export const PREFERENCES_LOADING = 'PREFERENCES_LOADING';
export type PREFERENCES_LOADING = typeof PREFERENCES_LOADING;

export const PREFERENCES_CHANGE_EDITED_FACILITY = 'PREFERENCES_CHANGE_EDITED_FACILITY';
export type PREFERENCES_CHANGE_EDITED_FACILITY = typeof PREFERENCES_CHANGE_EDITED_FACILITY;

export const PREFERENCES_EDITED_FACILITY_UPDATED = 'PREFERENCES_EDITED_FACILITY_UPDATED';
export type PREFERENCES_EDITED_FACILITY_UPDATED = typeof PREFERENCES_EDITED_FACILITY_UPDATED;

export const PREFERENCES_CHANGE_EDITED_FACILITY_ERROR_TEXT = 'PREFERENCES_CHANGE_EDITED_FACILITY_ERROR_TEXT';
export type PREFERENCES_CHANGE_EDITED_FACILITY_ERROR_TEXT = typeof PREFERENCES_CHANGE_EDITED_FACILITY_ERROR_TEXT;

export const PREFERENCES_START_EDITED_FACILITY_VALIDATION = 'PREFERENCES_START_EDITED_FACILITY_VALIDATION';
export type PREFERENCES_START_EDITED_FACILITY_VALIDATION = typeof PREFERENCES_START_EDITED_FACILITY_VALIDATION;

export const CHOICE_LISTS_SETTINGS_LOADING = 'CHOICE_LISTS_SETTINGS_LOADING';
export type CHOICE_LISTS_SETTINGS_LOADING = typeof CHOICE_LISTS_SETTINGS_LOADING;

export const CHOICE_LISTS_SETTINGS_LIST_LOADED = 'CHOICE_LISTS_SETTINGS_LIST_LOADED';
export type CHOICE_LISTS_SETTINGS_LIST_LOADED = typeof CHOICE_LISTS_SETTINGS_LIST_LOADED;

export const CHOICE_LISTS_SETTINGS_SET_ERROR = 'CHOICE_LISTS_SETTINGS_SET_ERROR';
export type CHOICE_LISTS_SETTINGS_SET_ERROR = typeof CHOICE_LISTS_SETTINGS_SET_ERROR;

export const CHOICE_LISTS_SETTINGS_CHANGE_ITEM = 'CHOICE_LISTS_SETTINGS_CHANGE_ITEM';
export type CHOICE_LISTS_SETTINGS_CHANGE_ITEM = typeof CHOICE_LISTS_SETTINGS_CHANGE_ITEM;

export const CHOICE_LISTS_SETTINGS_CONFIRM_CHANGE_ITEM = 'CHOICE_LISTS_SETTINGS_CONFIRM_CHANGE_ITEM';
export type CHOICE_LISTS_SETTINGS_CONFIRM_CHANGE_ITEM = typeof CHOICE_LISTS_SETTINGS_CONFIRM_CHANGE_ITEM;

export const CHOICE_LISTS_SETTINGS_DISCARD_CHANGE_ITEM = 'CHOICE_LISTS_SETTINGS_DISCARD_CHANGE_ITEM';
export type CHOICE_LISTS_SETTINGS_DISCARD_CHANGE_ITEM = typeof CHOICE_LISTS_SETTINGS_DISCARD_CHANGE_ITEM;

export const CHOICE_LISTS_SETTINGS_REMOVE_ITEM = 'CHOICE_LISTS_SETTINGS_REMOVE_ITEM';
export type CHOICE_LISTS_SETTINGS_REMOVE_ITEM = typeof CHOICE_LISTS_SETTINGS_REMOVE_ITEM;

export const CHOICE_LISTS_SETTINGS_CREATE_ITEM = 'CHOICE_LISTS_SETTINGS_CREATE_ITEM';
export type CHOICE_LISTS_SETTINGS_CREATE_ITEM = typeof CHOICE_LISTS_SETTINGS_CREATE_ITEM;

export const CHOICE_LISTS_SETTINGS_CONFIRM_CREATE_ITEM = 'CHOICE_LISTS_SETTINGS_CONFIRM_CREATE_ITEM';
export type CHOICE_LISTS_SETTINGS_CONFIRM_CREATE_ITEM = typeof CHOICE_LISTS_SETTINGS_CONFIRM_CREATE_ITEM;

export const CHOICE_LISTS_SETTINGS_DISCARD_CREATE_ITEM = 'CHOICE_LISTS_SETTINGS_DISCARD_CREATE_ITEM';
export type CHOICE_LISTS_SETTINGS_DISCARD_CREATE_ITEM = typeof CHOICE_LISTS_SETTINGS_DISCARD_CREATE_ITEM;

export const CHOICE_LISTS_SETTINGS_DISCARD_ALL_CHANGES = 'CHOICE_LISTS_SETTINGS_DISCARD_ALL_CHANGES';
export type CHOICE_LISTS_SETTINGS_DISCARD_ALL_CHANGES = typeof CHOICE_LISTS_SETTINGS_DISCARD_ALL_CHANGES;

export const CHOICE_LISTS_SETTINGS_TOGGLE_HIDE_INACTIVE_ITEMS = 'CHOICE_LISTS_SETTINGS_TOGGLE_HIDE_INACTIVE_ITEMS';
export type CHOICE_LISTS_SETTINGS_TOGGLE_HIDE_INACTIVE_ITEMS = typeof CHOICE_LISTS_SETTINGS_TOGGLE_HIDE_INACTIVE_ITEMS;

export const USER_ACTIVITY_UPDATE_LAST_ACTIVITY = 'USER_ACTIVITY_UPDATE_LAST_ACTIVITY';
export type USER_ACTIVITY_UPDATE_LAST_ACTIVITY = typeof USER_ACTIVITY_UPDATE_LAST_ACTIVITY;

export const USER_ACTIVITY_UPDATE_TIMEOUT_DIALOG = 'USER_ACTIVITY_UPDATE_TIMEOUT_DIALOG';
export type USER_ACTIVITY_UPDATE_TIMEOUT_DIALOG = typeof USER_ACTIVITY_UPDATE_TIMEOUT_DIALOG;

export const GET_USER_BEGIN = 'GET_USER_BEGIN';
export type GET_USER_BEGIN = typeof GET_USER_BEGIN;

export const GET_USER_COMPLETED = 'GET_USER_COMPLETED';
export type GET_USER_COMPLETED = typeof GET_USER_COMPLETED;

export const GET_USER_ERROR = 'GET_USER_ERROR';
export type GET_USER_ERROR = typeof GET_USER_ERROR;

export const SAVE_CURRENT_USER_BEGIN = 'SAVE_CURRENT_USER_BEGIN';
export type SAVE_CURRENT_USER_BEGIN = typeof SAVE_CURRENT_USER_BEGIN;

export const SAVE_CURRENT_USER_COMPLETED = 'SAVE_CURRENT_USER_COMPLETED';
export type SAVE_CURRENT_USER_COMPLETED = typeof SAVE_CURRENT_USER_COMPLETED;

export const SAVE_CURRENT_USER_ERROR = 'SAVE_CURRENT_USER_ERROR';
export type SAVE_CURRENT_USER_ERROR = typeof SAVE_CURRENT_USER_ERROR;

export const UPDATE_CURRENT_USER_FIELD = 'UPDATE_CURRENT_USER_FIELD';
export type UPDATE_CURRENT_USER_FIELD = typeof UPDATE_CURRENT_USER_FIELD;

export const INVITE_NEW_USER_BEGIN = 'INVITE_NEW_USER_BEGIN';
export type INVITE_NEW_USER_BEGIN = typeof INVITE_NEW_USER_BEGIN;

export const INVITE_NEW_USER_COMPLETED = 'INVITE_NEW_USER_COMPLETED';
export type INVITE_NEW_USER_COMPLETED = typeof INVITE_NEW_USER_COMPLETED;

export const INVITE_MULTIPLE_NEW_USERS_BEGIN = 'INVITE_MULTIPLE_NEW_USERS_BEGIN';
export type INVITE_MULTIPLE_NEW_USERS_BEGIN = typeof INVITE_MULTIPLE_NEW_USERS_BEGIN;

export const INVITE_MULTIPLE_NEW_USERS_PROCESSING = 'INVITE_MULTIPLE_NEW_USERS_PROCESSING';
export type INVITE_MULTIPLE_NEW_USERS_PROCESSING = typeof INVITE_MULTIPLE_NEW_USERS_PROCESSING;

export const INVITE_MULTIPLE_NEW_USERS_COMPLETED = 'INVITE_MULTIPLE_NEW_USERS_COMPLETED';
export type INVITE_MULTIPLE_NEW_USERS_COMPLETED = typeof INVITE_MULTIPLE_NEW_USERS_COMPLETED;

export const REINVITE_USER_BEGIN = 'REINVITE_USER_BEGIN';
export type REINVITE_USER_BEGIN = typeof REINVITE_USER_BEGIN;

export const REINVITE_USER_COMPLETED = 'REINVITE_USER_COMPLETED';
export type REINVITE_USER_COMPLETED = typeof REINVITE_USER_COMPLETED;

export const INVITE_NEW_USER_ERROR = 'INVITE_NEW_USER_ERROR';
export type INVITE_NEW_USER_ERROR = typeof INVITE_NEW_USER_ERROR;

export const CLEAR_INVITE_NEW_USER_ERROR = 'CLEAR_INVITE_NEW_USER_ERROR';
export type CLEAR_INVITE_NEW_USER_ERROR = typeof CLEAR_INVITE_NEW_USER_ERROR;

export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';
export type LOGOUT_CURRENT_USER = typeof LOGOUT_CURRENT_USER;

export const NET_ACTIVITY_STARTED = 'NET_ACTIVITY_STARTED';
export type NET_ACTIVITY_STARTED = typeof NET_ACTIVITY_STARTED;

export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export type SET_USERS_LOADING = typeof SET_USERS_LOADING;

export const GET_SELECTED_USER_BEGIN = 'GET_SELECTED_USER_BEGIN';
export type GET_SELECTED_USER_BEGIN = typeof GET_SELECTED_USER_BEGIN;

export const NET_ACTIVITY_UPDATED = 'NET_ACTIVITY_UPDATED';
export type NET_ACTIVITY_UPDATED = typeof NET_ACTIVITY_UPDATED;

export const NET_ACTIVITY_RESTARTED = 'NET_ACTIVITY_RESTARTED';
export type NET_ACTIVITY_RESTARTED = typeof NET_ACTIVITY_RESTARTED;

export const B2C_UPDATE_TOKEN_INFO = 'B2C_UPDATE_TOKEN_INFO';
export type B2C_UPDATE_TOKEN_INFO = typeof B2C_UPDATE_TOKEN_INFO;

export const GET_SELECTED_USER_COMPLETED = 'GET_SELECTED_USER_COMPLETED';
export type GET_SELECTED_USER_COMPLETED = typeof GET_SELECTED_USER_COMPLETED;

export const GET_SELECTED_USER_ERROR = 'GET_SELECTED_USER_ERROR';
export type GET_SELECTED_USER_ERROR = typeof GET_SELECTED_USER_ERROR;

export const UPDATE_SELECTED_ENCODER_USER_FIELD = 'UPDATE_SELECTED_ENCODER_USER_FIELD';
export type UPDATE_SELECTED_ENCODER_USER_FIELD = typeof UPDATE_SELECTED_ENCODER_USER_FIELD;

export const SAVE_SELECTED_ENCODER_USER_BEGIN = 'SAVE_SELECTED_ENCODER_USER_BEGIN';
export type SAVE_SELECTED_ENCODER_USER_BEGIN = typeof SAVE_SELECTED_ENCODER_USER_BEGIN;

export const SAVE_SELECTED_ENCODER_USER_COMPLETED = 'SAVE_SELECTED_ENCODER_USER_COMPLETED';
export type SAVE_SELECTED_ENCODER_USER_COMPLETED = typeof SAVE_SELECTED_ENCODER_USER_COMPLETED;

export const SAVE_SELECTED_ENCODER_USER_ERROR = 'SAVE_SELECTED_ENCODER_USER_ERROR';
export type SAVE_SELECTED_ENCODER_USER_ERROR = typeof SAVE_SELECTED_ENCODER_USER_ERROR;

export const GET_SELECTED_USER_ROLES_BEGIN = 'GET_SELECTED_USER_ROLES_BEGIN';
export type GET_SELECTED_USER_ROLES_BEGIN = typeof GET_SELECTED_USER_ROLES_BEGIN;

export const GET_SELECTED_USER_ROLES_COMPLETED = 'GET_SELECTED_USER_ROLES_COMPLETED';
export type GET_SELECTED_USER_ROLES_COMPLETED = typeof GET_SELECTED_USER_ROLES_COMPLETED;

export const GET_SELECTED_USER_ROLES_ERROR = 'GET_SELECTED_USER_ROLES_ERROR';
export type GET_SELECTED_USER_ROLES_ERROR = typeof GET_SELECTED_USER_ROLES_ERROR;

export const SAVE_SELECTED_USER_ROLES_BEGIN = 'SAVE_SELECTED_USER_ROLES_BEGIN';
export type SAVE_SELECTED_USER_ROLES_BEGIN = typeof SAVE_SELECTED_USER_ROLES_BEGIN;

export const SAVE_SELECTED_USER_ROLES_COMPLETED = 'SAVE_SELECTED_USER_ROLES_COMPLETED';
export type SAVE_SELECTED_USER_ROLES_COMPLETED = typeof SAVE_SELECTED_USER_ROLES_COMPLETED;

export const SAVE_SELECTED_USER_ROLES_ERROR = 'SAVE_SELECTED_USER_ROLES_ERROR';
export type SAVE_SELECTED_USER_ROLES_ERROR = typeof SAVE_SELECTED_USER_ROLES_ERROR;

export const CREATE_NEW_USER_BEGIN = 'CREATE_NEW_USER_BEGIN';
export type CREATE_NEW_USER_BEGIN = typeof CREATE_NEW_USER_BEGIN;

export const CREATE_NEW_USER_COMPLETED = 'CREATE_NEW_USER_COMPLETED';
export type CREATE_NEW_USER_COMPLETED = typeof CREATE_NEW_USER_COMPLETED;

export const CREATE_NEW_USER_ERROR = 'CREATE_NEW_USER_ERROR';
export type CREATE_NEW_USER_ERROR = typeof CREATE_NEW_USER_ERROR;

export const CANCEL_CREATE_NEW_USER_COMPLETED = 'CANCEL_CREATE_NEW_USER_COMPLETED';
export type CANCEL_CREATE_NEW_USER_COMPLETED = typeof CANCEL_CREATE_NEW_USER_COMPLETED;

export const PATIENT_ENCOUNTER_CHANGE_TAB = 'PATIENT_ENCOUNTER_CHANGE_TAB';
export type PATIENT_ENCOUNTER_CHANGE_TAB = typeof PATIENT_ENCOUNTER_CHANGE_TAB;

export const DELETE_USER_INVITE_COMPLETED = 'DELETE_USER_INVITE_COMPLETED';
export type DELETE_USER_INVITE_COMPLETED = typeof DELETE_USER_INVITE_COMPLETED;

export const DELETE_USER_INVITE_ERROR = 'DELETE_USER_INVITE_ERROR';
export type DELETE_USER_INVITE_ERROR = typeof DELETE_USER_INVITE_ERROR;

export const SYSTEM_PREFERENCES_GET_PREFERENCES_BEGIN = 'SYSTEM_PREFERENCES_GET_PREFERENCES_BEGIN';
export type SYSTEM_PREFERENCES_GET_PREFERENCES_BEGIN = typeof SYSTEM_PREFERENCES_GET_PREFERENCES_BEGIN;

export const SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_COMPLETED = 'SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_COMPLETED';
export type SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_COMPLETED = typeof SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_COMPLETED;

export const SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_FAIL = 'SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_FAIL';
export type SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_FAIL = typeof SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_FAIL;

export const SYSTEM_PREFERENCES_SET_INTERFACE_PREFERENCES = 'SYSTEM_PREFERENCES_SET_INTERFACE_PREFERENCES';
export type SYSTEM_PREFERENCES_SET_INTERFACE_PREFERENCES = typeof SYSTEM_PREFERENCES_SET_INTERFACE_PREFERENCES;

export const SYSTEM_PREFERENCES_SET_INTERFACE_TYPE_SPECIFIC_PREFERENCES = 'SYSTEM_PREFERENCES_SET_INTERFACE_TYPE_SPECIFIC_PREFERENCES';
export type SYSTEM_PREFERENCES_SET_INTERFACE_TYPE_SPECIFIC_PREFERENCES = typeof SYSTEM_PREFERENCES_SET_INTERFACE_TYPE_SPECIFIC_PREFERENCES;

export const SYSTEM_PREFERENCES_SET_SAVING = 'SYSTEM_PREFERENCES_SET_SAVING';
export type SYSTEM_PREFERENCES_SET_SAVING = typeof SYSTEM_PREFERENCES_SET_SAVING;

export const SYSTEM_PREFERENCES_SET_ERROR = 'SYSTEM_PREFERENCES_SET_ERROR';
export type SYSTEM_PREFERENCES_SET_ERROR = typeof SYSTEM_PREFERENCES_SET_ERROR;

export const GET_RECOVERED_ENCOUNTERS_BEGIN = 'GET_RECOVERED_ENCOUNTERS_BEGIN';
export type GET_RECOVERED_ENCOUNTERS_BEGIN = typeof GET_RECOVERED_ENCOUNTERS_BEGIN;

export const GET_RECOVERED_ENCOUNTERS_COMPLETED = 'GET_RECOVERED_ENCOUNTERS_COMPLETED';
export type GET_RECOVERED_ENCOUNTERS_COMPLETED = typeof GET_RECOVERED_ENCOUNTERS_COMPLETED;

export const GET_RECOVERED_ENCOUNTERS_ERROR = 'GET_RECOVERED_ENCOUNTERS_ERROR';
export type GET_RECOVERED_ENCOUNTERS_ERROR = typeof GET_RECOVERED_ENCOUNTERS_ERROR;

export const REMOVE_ENCOUNTER_FROM_RECOVERY_BEGIN = 'REMOVE_ENCOUNTER_FROM_RECOVERY_BEGIN';
export type REMOVE_ENCOUNTER_FROM_RECOVERY_BEGIN = typeof REMOVE_ENCOUNTER_FROM_RECOVERY_BEGIN;

export const REMOVE_ENCOUNTER_FROM_RECOVERY_COMPLETED = 'REMOVE_ENCOUNTER_FROM_RECOVERY_COMPLETED';
export type REMOVE_ENCOUNTER_FROM_RECOVERY_COMPLETED = typeof REMOVE_ENCOUNTER_FROM_RECOVERY_COMPLETED;

export const REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR = 'REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR';
export type REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR = typeof REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR;

export const CLEAR_REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR = 'CLEAR_REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR';
export type CLEAR_REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR = typeof CLEAR_REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR;

export const REGISTERED_INTERFACES_GET_INTERFACES_BEGIN = 'REGISTERED_INTERFACES_GET_INTERFACES_BEGIN';
export type REGISTERED_INTERFACES_GET_INTERFACES_BEGIN = typeof REGISTERED_INTERFACES_GET_INTERFACES_BEGIN;

export const REGISTERED_INTERFACES_GET_INTERFACES_COMPLETED = 'REGISTERED_INTERFACES_GET_INTERFACES_COMPLETED';
export type REGISTERED_INTERFACES_GET_INTERFACES_COMPLETED = typeof REGISTERED_INTERFACES_GET_INTERFACES_COMPLETED;

export const REGISTERED_INTERFACES_GET_INTERFACES_FAIL = 'REGISTERED_INTERFACES_GET_INTERFACES_FAIL';
export type REGISTERED_INTERFACES_GET_INTERFACES_FAIL = typeof REGISTERED_INTERFACES_GET_INTERFACES_FAIL;

export const REGISTERED_INTERFACES_SET_SAVING = 'REGISTERED_INTERFACES_SET_SAVING';
export type REGISTERED_INTERFACES_SET_SAVING = typeof REGISTERED_INTERFACES_SET_SAVING;

export const REGISTERED_INTERFACES_REGISTER_INTERFACE = 'REGISTERED_INTERFACES_REGISTER_INTERFACE';
export type REGISTERED_INTERFACES_REGISTER_INTERFACE = typeof REGISTERED_INTERFACES_REGISTER_INTERFACE;

export const REGISTERED_INTERFACES_SET_ERRORS = 'REGISTERED_INTERFACES_SET_ERRORS';
export type REGISTERED_INTERFACES_SET_ERRORS = typeof REGISTERED_INTERFACES_SET_ERRORS;

export const REGISTERED_INTERFACES_DELETE_INTERFACE = 'REGISTERED_INTERFACES_DELETE_INTERFACE';
export type REGISTERED_INTERFACES_DELETE_INTERFACE = typeof REGISTERED_INTERFACES_DELETE_INTERFACE;

export const REGISTERED_INTERFACES_SHOW_REGISTER_FORM = 'REGISTERED_INTERFACES_SHOW_REGISTER_FORM';
export type REGISTERED_INTERFACES_SHOW_REGISTER_FORM = typeof REGISTERED_INTERFACES_SHOW_REGISTER_FORM;

export const REGISTERED_INTERFACES_CLEAR_SECRET_KEYS = 'REGISTERED_INTERFACES_CLEAR_SECRET_KEYS';
export type REGISTERED_INTERFACES_CLEAR_SECRET_KEYS = typeof REGISTERED_INTERFACES_CLEAR_SECRET_KEYS;

export const GET_GENERAL_PREFERENCE_BEGIN = 'GET_GENERAL_PREFERENCE_BEGIN';
export type GET_GENERAL_PREFERENCE_BEGIN = typeof GET_GENERAL_PREFERENCE_BEGIN;

export const GET_GENERAL_PREFERENCE_COMPLETED = 'GET_GENERAL_PREFERENCE_COMPLETED';
export type GET_GENERAL_PREFERENCE_COMPLETED = typeof GET_GENERAL_PREFERENCE_COMPLETED;

export const GET_GENERAL_PREFERENCE_ERROR = 'GET_GENERAL_PREFERENCE_ERROR';
export type GET_GENERAL_PREFERENCE_ERROR = typeof GET_GENERAL_PREFERENCE_ERROR;

export const SET_GENERAL_PREFERENCE_BEGIN = 'SET_GENERAL_PREFERENCE_BEGIN';
export type SET_GENERAL_PREFERENCE_BEGIN = typeof SET_GENERAL_PREFERENCE_BEGIN;

export const SET_GENERAL_PREFERENCE_COMPLETED = 'SET_GENERAL_PREFERENCE_COMPLETED';
export type SET_GENERAL_PREFERENCE_COMPLETED = typeof SET_GENERAL_PREFERENCE_COMPLETED;

export const SET_GENERAL_PREFERENCE_ERROR = 'SET_GENERAL_PREFERENCE_ERROR';
export type SET_GENERAL_PREFERENCE_ERROR = typeof SET_GENERAL_PREFERENCE_ERROR;

export const GET_ORGANIZATION_SECURITY_OPTIONS_BEGIN = 'GET_ORGANIZATION_SECURITY_OPTIONS_BEGIN';
export type GET_ORGANIZATION_SECURITY_OPTIONS_BEGIN = typeof GET_ORGANIZATION_SECURITY_OPTIONS_BEGIN;

export const GET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED = 'GET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED';
export type GET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED = typeof GET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED;

export const GET_ORGANIZATION_SECURITY_OPTIONS_ERROR = 'GET_ORGANIZATION_SECURITY_OPTIONS_ERROR';
export type GET_ORGANIZATION_SECURITY_OPTIONS_ERROR = typeof GET_ORGANIZATION_SECURITY_OPTIONS_ERROR;

export const SET_ORGANIZATION_SECURITY_OPTIONS_BEGIN = 'SET_ORGANIZATION_SECURITY_OPTIONS_BEGIN';
export type SET_ORGANIZATION_SECURITY_OPTIONS_BEGIN = typeof SET_ORGANIZATION_SECURITY_OPTIONS_BEGIN;

export const SET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED = 'SET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED';
export type SET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED = typeof SET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED;

export const SET_ORGANIZATION_SECURITY_OPTIONS_ERROR = 'SET_ORGANIZATION_SECURITY_OPTIONS_ERROR';
export type SET_ORGANIZATION_SECURITY_OPTIONS_ERROR = typeof SET_ORGANIZATION_SECURITY_OPTIONS_ERROR;

export const SET_ORGANIZATION_SECURITY_OPTIONS_LOADING = 'SET_ORGANIZATION_SECURITY_OPTIONS_LOADING';
export type SET_ORGANIZATION_SECURITY_OPTIONS_LOADING = typeof SET_ORGANIZATION_SECURITY_OPTIONS_LOADING;

export const GET_ORGANIZATION_INFO_BEGIN = 'GET_ORGANIZATION_INFO_BEGIN';
export type GET_ORGANIZATION_INFO_BEGIN = typeof GET_ORGANIZATION_INFO_BEGIN;

export const GET_ORGANIZATION_INFO_COMPLETED = 'GET_ORGANIZATION_INFO_COMPLETED';
export type GET_ORGANIZATION_INFO_COMPLETED = typeof GET_ORGANIZATION_INFO_COMPLETED;

export const GET_ORGANIZATION_INFO_ERROR = 'GET_ORGANIZATION_INFO_ERROR';
export type GET_ORGANIZATION_INFO_ERROR = typeof GET_ORGANIZATION_INFO_ERROR;

export const SET_ORGANIZATION_INFO_BEGIN = 'SET_ORGANIZATION_INFO_BEGIN';
export type SET_ORGANIZATION_INFO_BEGIN = typeof SET_ORGANIZATION_INFO_BEGIN;

export const SET_ORGANIZATION_INFO_COMPLETED = 'SET_ORGANIZATION_INFO_COMPLETED';
export type SET_ORGANIZATION_INFO_COMPLETED = typeof SET_ORGANIZATION_INFO_COMPLETED;

export const SET_ORGANIZATION_INFO_ERROR = 'SET_ORGANIZATION_INFO_ERROR';
export type SET_ORGANIZATION_INFO_ERROR = typeof SET_ORGANIZATION_INFO_ERROR;

export const ENABLE_TEST_ORGANIZATION_BEGIN = 'ENABLE_TEST_ORGANIZATION_BEGIN';
export type ENABLE_TEST_ORGANIZATION_BEGIN = typeof ENABLE_TEST_ORGANIZATION_BEGIN;

export const ENABLE_TEST_ORGANIZATION_COMPLETED = 'ENABLE_TEST_ORGANIZATION_COMPLETED';
export type ENABLE_TEST_ORGANIZATION_COMPLETED = typeof ENABLE_TEST_ORGANIZATION_COMPLETED;

export const ENABLE_TEST_ORGANIZATION_ERROR = 'ENABLE_TEST_ORGANIZATION_ERROR';
export type ENABLE_TEST_ORGANIZATION_ERROR = typeof ENABLE_TEST_ORGANIZATION_ERROR;

export const SYNC_TEST_ORGANIZATION_SETTINGS_BEGIN = 'SYNC_TEST_ORGANIZATION_SETTINGS_BEGIN';
export type SYNC_TEST_ORGANIZATION_SETTINGS_BEGIN = typeof SYNC_TEST_ORGANIZATION_SETTINGS_BEGIN;

export const SYNC_TEST_ORGANIZATION_SETTINGS_COMPLETED = 'SYNC_TEST_ORGANIZATION_SETTINGS_COMPLETED';
export type SYNC_TEST_ORGANIZATION_SETTINGS_COMPLETED = typeof SYNC_TEST_ORGANIZATION_SETTINGS_COMPLETED;

export const SYNC_TEST_ORGANIZATION_SETTINGS_ERROR = 'SYNC_TEST_ORGANIZATION_SETTINGS_ERROR';
export type SYNC_TEST_ORGANIZATION_SETTINGS_ERROR = typeof SYNC_TEST_ORGANIZATION_SETTINGS_ERROR;

export const SYNC_TEST_ORGANIZATION_SETTINGS_STATUS = 'SYNC_TEST_ORGANIZATION_SETTINGS_STATUS';
export type SYNC_TEST_ORGANIZATION_SETTINGS_STATUS = typeof SYNC_TEST_ORGANIZATION_SETTINGS_STATUS;

export const GET_AUDIT_CATERGORY_LIST_BEGIN = 'GET_AUDIT_CATERGORY_LIST_BEGIN';
export type GET_AUDIT_CATERGORY_LIST_BEGIN = typeof GET_AUDIT_CATERGORY_LIST_BEGIN;

export const GET_AUDIT_CATERGORY_LIST_COMPLETED = 'GET_AUDIT_CATERGORY_LIST_COMPLETED';
export type GET_AUDIT_CATERGORY_LIST_COMPLETED = typeof GET_AUDIT_CATERGORY_LIST_COMPLETED;

export const GET_AUDIT_CATERGORY_LIST_ERROR = 'GET_AUDIT_CATERGORY_LIST_ERROR';
export type GET_AUDIT_CATERGORY_LIST_ERROR = typeof GET_AUDIT_CATERGORY_LIST_ERROR;

export const UPDATE_AUDIT_ITEM_CHECKED_VALUE = 'UPDATE_AUDIT_ITEM_CHECKED_VALUE';
export type UPDATE_AUDIT_ITEM_CHECKED_VALUE = typeof UPDATE_AUDIT_ITEM_CHECKED_VALUE;

export const GET_AUDIT_EXPORT_LOG_CSV_BEGIN = 'GET_AUDIT_EXPORT_LOG_CSV_BEGIN';
export type GET_AUDIT_EXPORT_LOG_CSV_BEGIN = typeof GET_AUDIT_EXPORT_LOG_CSV_BEGIN;

export const GET_AUDIT_EXPORT_LOG_CSV_COMPELETED = 'GET_AUDIT_EXPORT_LOG_CSV_COMPELETED';
export type GET_AUDIT_EXPORT_LOG_CSV_COMPELETED = typeof GET_AUDIT_EXPORT_LOG_CSV_COMPELETED;

export const GET_AUDIT_EXPORT_LOG_CSV_ERROR = 'GET_AUDIT_EXPORT_LOG_CSV_ERROR';
export type GET_AUDIT_EXPORT_LOG_CSV_ERROR = typeof GET_AUDIT_EXPORT_LOG_CSV_ERROR;

export const CLEAR_AUDIT_LOG_DATA = 'CLEAR_AUDIT_LOG_DATA';
export type CLEAR_AUDIT_LOG_DATA = typeof CLEAR_AUDIT_LOG_DATA;

export const FACILITY_CONCURRENCY_VERSION_ERROR = 'FACILITY_CONCURRENCY_VERSION_ERROR';
export type FACILITY_CONCURRENCY_VERSION_ERROR = typeof FACILITY_CONCURRENCY_VERSION_ERROR;
