import './PageHeader.less';

import * as React from 'react';

interface PageHeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
  customRender?: boolean;
}

export function PageHeader(props: PageHeaderProps) {
  const { className, customRender, ...otherProps} = props;
  const classes = ['page-header', className].join(' ');
  if (customRender) {
    return <div className={classes}>{props.children}</div>;
  }
  return <h2 className={classes} {...otherProps}>{props.children}</h2>;
}

export default PageHeader;
