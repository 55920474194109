/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLogic } from 'redux-logic';
import * as actionTypes from '../constants';
import { LogicLifecycleParams } from '../reduxStore';
import { clearApplicationAction } from '../actions/logout';
import { isChildWindow } from '../utils/url';
import { pca, getAccountIdentifier } from '../authProvider';

// logout current user. There is cross-tab operation
export const logoutCurrentUserLogic = createLogic({
  type: [actionTypes.LOGOUT_CURRENT_USER],
  latest: true, // take latest only

  process({ action, getState }: LogicLifecycleParams, dispatch: any, done: any) {
    if (!window.useAzureADAuth) {
      done();
      return;
    }

    const accountIdentifier = getAccountIdentifier();

    // log out from any tab
    if (accountIdentifier === action.payload.accountIdentifier) {
      dispatch(clearApplicationAction());

      if (isChildWindow()) {
        // close child window instead of logout
        window.close();        
      } else {
        if (!action.payload.fromIdleTimeout && window.location.pathname.startsWith("/encounter")) {
          window.location.replace('/#logoutuser');
        } else {
          // return back to the unsaved page (WEB-1825)
          pca.logoutRedirect();
        }
      }

      /*
      use this code to redirect to /home for some pages
      } else if (true || action.payload.unsavedChanges || window.location.pathname === '/') {
        // return back to the unsaved page for the idle timeout with unsaved changes
        // return back to the encounters page (WEB-1825).
        // logout from the homepage without redirection
        pca.logoutRedirect();
      } else {
        // navigate to home page for the logout or idle timeout without unsaved changes
        window.location.replace('/#logoutuser')
      }
      */
    }

    done();
  }
});

// multiple logic groups can be exported here
export default [
  logoutCurrentUserLogic,
];
