import * as moment from 'moment';
import * as actionTypes from '../constants';
import {
  PDxAnalyzeResultEntity,
  PDxAnalyzeState
} from '../models/groupingEntity';

const createEmptyPDxAnalyzeState = (isLoading?: boolean): PDxAnalyzeState => ({
  loading: isLoading || false,
});

export const pdxAnalyzeReducer = (state = createEmptyPDxAnalyzeState(), action) => {
  switch (action.type) {
    case actionTypes.OPEN_ENCOUNTER_BY_ID_COMPLETED:
    case actionTypes.CREATE_NEW_ENCOUNTER:
      return handleOpenEncounterByIdCompleted();
    case actionTypes.PDX_ANALYZE_BEGIN:
      return handlePdxAnalyzeBegin(state);
    case actionTypes.PDX_ANALYZE_ERROR:
      return handlePdxAnalyzeError(state);
    case actionTypes.PDX_ANALYZE_COMPLETED:
      return handlePdxAnalyzeCompleted(state, action.payload);
    default:
      return state;
  }
};

const handleOpenEncounterByIdCompleted = (): PDxAnalyzeState => {
  return createEmptyPDxAnalyzeState();
};

const handlePdxAnalyzeBegin = (state: PDxAnalyzeState): PDxAnalyzeState => {
  return {
    ...state,
    loadedAt: undefined,
    loading: true,
    result: undefined,
  };
};

const handlePdxAnalyzeError = (state: PDxAnalyzeState): PDxAnalyzeState => {
  // FIXME: create error info object?
  return {
    ...state,
    loadedAt: undefined,
    loading: false,
    result: undefined,
  };
};

const handlePdxAnalyzeCompleted = (state: PDxAnalyzeState, payload: PDxAnalyzeResultEntity): PDxAnalyzeState => {
  return {
    ...state,
    loadedAt: moment.now().toString(),
    loading: false,
    result: payload,
  };
};
