import * as store from 'store';
import * as actionTypes from '../constants';
import {
  AdviceSearchQueryEntity,
  EncounterType,
  FacilityTypes,
  SearchOrder,
  StorageKeys
} from '../models';
import { AdviceQueryChangePayload } from '../scenes/CodingAdvice/actions/queryChange';

const createEmptySearchQuery = (): AdviceSearchQueryEntity => ({
  codeType: tryGetStorage(StorageKeys.AdviceCodeType) || '',
  description: '',
  encounterType: tryGetStorage(StorageKeys.AdviceEncounterType) || EncounterType.ALL,
  facilityId: tryGetStorage(StorageKeys.AdviceFacilityId) || FacilityTypes.ALL,
  skip: 0,
  sortBy: SearchOrder.CODE,
  top: 0,
});

export const adviceSearchQueryReducer = (state = createEmptySearchQuery(), action) => {
  switch (action.type) {
    case actionTypes.CCA_UPDATE_ADVICE_QUERY:
      return handleUpdateAdviceQueryCompleted(state, action.payload);
    default:
      return state;
  }
};

const handleUpdateAdviceQueryCompleted = (state: AdviceSearchQueryEntity, payload: AdviceQueryChangePayload) => {
  trySetStorage(payload.fieldName, payload.value);
  return {
    ...state,
    [payload.fieldName]: payload.value
  };
};

const trySetStorage = (fieldName: string, value: string) => {
  try {
    switch (fieldName) {
      case 'encounterType':
        store.set(StorageKeys.AdviceEncounterType, value);
        break;
      case 'facilityId':
        store.set(StorageKeys.AdviceFacilityId, value);
        break;
      case 'codeType':
        store.set(StorageKeys.AdviceCodeType, value);
        break;
      default:
        break;
    }
  } catch (Error) {
    // swallow
  }
};

const tryGetStorage = (fieldName: StorageKeys) => {
  try {
    switch (fieldName) {
      case StorageKeys.AdviceEncounterType:
        return store.get(StorageKeys.AdviceEncounterType);
      case StorageKeys.AdviceFacilityId:
        return store.get(StorageKeys.AdviceFacilityId);
      case StorageKeys.AdviceCodeType:
        return store.get(StorageKeys.AdviceCodeType);
      default:
        return null;
    }
  } catch (Error) {
    return null;
  }
};
