import * as moment from 'moment';
import { Key } from '../../constants/keyboard';
import { ValuesRow, ValuesGridType, isValuesGridClassName } from "../../models/valuesGrid";
import { EncounterEntity, EncounterState } from "../../models";
import { ValuesGridChange, ValuesGridKeypress, ValuesGridDragDropRow } from "../../scenes/Encounter/actions/valuesGrid";
import { updateValueCodes } from './encounterCommon';
import { EditLocateEvent } from '../../scenes/Encounter/actions/researchPane';


const allValuesGridsAction = (_inProgress: EncounterEntity, handler: (code: ValuesRow) => ValuesRow) => {
  const inProgress = _inProgress;
  const newConditionCodes: ValuesRow[] = [];
  const newValueCodes: ValuesRow[] = [];

  for (let ind = 0, condLen = inProgress.conditionCodes.length; ind < condLen; ind++) {
    const newCode = handler(inProgress.conditionCodes[ind]);
    newConditionCodes.push(newCode);
  }

  updateValueCodes(newConditionCodes, ValuesGridType.CONDITION_CODES);

  for (let ind = 0, valueLen = inProgress.valueCodes.length; ind < valueLen; ind++) {
    const newCode = handler(inProgress.valueCodes[ind]);
    newValueCodes.push(newCode);
  }

  updateValueCodes(newValueCodes, ValuesGridType.VALUE_CODES);

  inProgress.conditionCodes = newConditionCodes;
  inProgress.valueCodes = newValueCodes;
}

const exitAllValuesGridEdit = (inProgress: EncounterEntity) => {
  allValuesGridsAction(inProgress, (code => {
    const newCode: ValuesRow = {
      ...code,
    };

    newCode.inEdit = undefined;

    return newCode;
  }));
}

export const handleValuesGridEnterEdit = (state: EncounterState, payload: ValuesGridChange): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  allValuesGridsAction(inProgress, (code => {
    const newCode: ValuesRow = {
      ...code
    };

    if (code.id === payload.dataItem.id && code.gridType === payload.gridType) {
      newCode.editField = payload.field || '';
      newCode.inEdit = true;
    } else {
      newCode.inEdit = undefined;
    }

    return newCode;
  }));

  return {
    ...state,
    inProgress,
  };
};

export const handleValuesGridChange = (state: EncounterState, payload: ValuesGridChange): EncounterState => {
  let { dirty } = state;
  const inProgress = {
    ...state.inProgress,
  };

  allValuesGridsAction(inProgress, (code => {
    const newCode: ValuesRow = {
      ...code
    };

    if (code.id === payload.dataItem.id && code.gridType === payload.gridType) {
      if (typeof payload.field !== 'undefined' && typeof payload.value !== 'undefined') {
        if (!dirty && newCode[payload.field] !== payload.value) {
          dirty = true;
        }

        newCode[payload.field] = payload.value;

        if ((payload.field === 'ConditionCode' || payload.field === 'ValueCode') && payload.value.trim() !== '') {
          newCode.empty = false;
        }

        newCode.editField = undefined;
      }
    }

    return newCode;
  }));

  return {
    ...state,
    dirty,
    inProgress,
    lastChangeTime: moment.now(),
  };
};


export const handleValuesGridExitEdit = (state: EncounterState, tabFromLastColumn: boolean): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  if (!tabFromLastColumn) {
    exitAllValuesGridEdit(inProgress);

    return {
      ...state,
      inProgress,
    };
  }

  // find currently edited item
  let editedItem: ValuesRow | null = null;
  const condCodesLen = inProgress.conditionCodes.length;
  let ind = 0;
  for (ind = 0; ind < condCodesLen; ind++) {
    if (inProgress.conditionCodes[ind].inEdit) {
      exitAllValuesGridEdit(inProgress);
      editedItem = inProgress.conditionCodes[ind];
      break;
    }
  }

  if (editedItem) {
    // navigate to the next grid
    if (ind === condCodesLen - 1) {
      if (inProgress.valueCodes.length > 0) {
        inProgress.valueCodes[0].inEdit = true;
        inProgress.valueCodes[0].editField = 'ValueCode';
      }
    } else {
      inProgress.conditionCodes[ind + 1].inEdit = true;
      inProgress.conditionCodes[ind + 1].editField = 'ConditionCode';
    }

    return {
      ...state,
      inProgress,
    };
  }

  const valueCodesLen = inProgress.valueCodes.length;
  for (ind = 0; ind < valueCodesLen; ind++) {
    if (inProgress.valueCodes[ind].inEdit) {
      exitAllValuesGridEdit(inProgress);
      editedItem = inProgress.valueCodes[ind];
      break;
    }
  }

  if (editedItem) {
    // navigate to the first control is in the Grid. I don't want to use jQuery here
    if (ind < valueCodesLen - 1) {
      inProgress.valueCodes[ind + 1].inEdit = true;
      inProgress.valueCodes[ind + 1].editField = 'ValueCode';
    }
  }

  return {
    ...state,
    inProgress,
  };
};

const findItemIndex = (valuesGrid: ValuesRow[], dataItem?: ValuesRow, dataId?: string) => {
  const id = dataItem ? dataItem.id : dataId;
  for (let ind = 0, len = valuesGrid.length; ind < len; ind++) {
    if (valuesGrid[ind].id === id) {
      return ind;
    }
  }

  return -1;
}

// special situation with navigation from the just deleted row
const getJustDeletedRowIndex = (payload?: ValuesGridKeypress) => {
  if (payload && payload.dataIndex !== undefined
    && (payload.field === 'ConditionCode' && (payload.value || '').trim() === ''
    || payload.field === 'ValueCode' && (payload.value || '').trim() === '')
    && !payload.dataItem.empty) {
    return payload.dataIndex;
  }

  return -1;
}

const valuesGridPrevRowFocus = (_valuesGrid: ValuesRow[], dataItem: ValuesRow, field: string, justDeletedRowIndex = -1) => {
  const valuesGrid = _valuesGrid;
  const ind = justDeletedRowIndex !== -1 ? justDeletedRowIndex : findItemIndex(valuesGrid, dataItem);
  if (ind === -1 || !field) {
    return;
  }

  const focusedInd = ind > 0 ? ind - 1 : 0;
  valuesGrid[focusedInd].inEdit = true;
  valuesGrid[focusedInd].editField = field;
}

const valuesGridNextRowFocus = (_valuesGrid: ValuesRow[], dataItem: ValuesRow, field: string, justDeletedRowIndex = -1) => {
  const valuesGrid = _valuesGrid;
  const ind = justDeletedRowIndex !== -1 ? justDeletedRowIndex : findItemIndex(valuesGrid, dataItem);
  if (ind === -1 || !field) {
    return;
  }

  let focusedInd = ind < valuesGrid.length - 1 ? ind + 1 : valuesGrid.length - 1;
  if (justDeletedRowIndex !== -1 && justDeletedRowIndex < valuesGrid.length - 1) {
    // stay at the deleted row
    focusedInd--;
  }
  valuesGrid[focusedInd].inEdit = true;
  valuesGrid[focusedInd].editField = field;

  // special situation: focus ValueCode instead of Amount in the empty row to make more comfort editing
  if (valuesGrid[focusedInd].empty && field === 'Amount') {
    valuesGrid[focusedInd].editField = 'ValueCode';
  }
}

const valuesGridCurrentRowFocus = (_valuesGrid: ValuesRow[], dataItem: ValuesRow, field: string) => {
  const valuesGrid = _valuesGrid;
  const ind = findItemIndex(valuesGrid, dataItem);
  if (ind === -1 || !field) {
    return;
  }

  valuesGrid[ind].inEdit = true;
  valuesGrid[ind].editField = field;
}

export const handleValuesGridKeypress = (state: EncounterState, payload: ValuesGridKeypress): EncounterState => {
  const justDeletedRowIndex = getJustDeletedRowIndex(payload);
  const codeField = payload.dataItem.gridType === ValuesGridType.CONDITION_CODES ? 'ConditionCode' : 'ValueCode';

  switch (payload.key) {
    case Key.Enter: {
      const inProgress = {
        ...state.inProgress,
      };

      exitAllValuesGridEdit(inProgress);

      const valuesGrid = payload.dataItem.gridType === ValuesGridType.CONDITION_CODES ? inProgress.conditionCodes : inProgress.valueCodes;

      // shift-Enter - navigate one row up to the same column
      if (payload.shiftKey) {
        valuesGridPrevRowFocus(valuesGrid, payload.dataItem, payload.field || codeField, justDeletedRowIndex);
      // control-Enter - navigate one row down to the code column
      } else if (payload.ctrlKey) {
        valuesGridNextRowFocus(valuesGrid, payload.dataItem, codeField, justDeletedRowIndex);
      // usual Enter - navigate one row down to the same column
      } else {
        valuesGridNextRowFocus(valuesGrid, payload.dataItem, justDeletedRowIndex !== -1 ? codeField : payload.field || codeField, justDeletedRowIndex);
      }

      return {
        ...state,
        inProgress,
      };
    }

    // shif + Tab - previous description
    case Key.Tab: {
      const inProgress = {
        ...state.inProgress,
      };

      // back
      if (payload.shiftKey) {
        exitAllValuesGridEdit(inProgress);

        // jumping from the ConditionCodes grid - it is handled directly in the ValuesGrid
        if (payload.dataItem.gridType === ValuesGridType.CONDITION_CODES && inProgress.conditionCodes.length > 0
          && (inProgress.conditionCodes[0].id === payload.dataItem.id || justDeletedRowIndex === 0)) {
          return {
            ...state,
            inProgress,
          };
        }

        // jumping from the ValueCodes grid
        if (payload.dataItem.gridType === ValuesGridType.VALUE_CODES && inProgress.valueCodes.length > 0
          && (inProgress.valueCodes[0].id === payload.dataItem.id || justDeletedRowIndex === 0) && payload.field === 'ValueCode') {
          const condLen = inProgress.conditionCodes.length;
          if (condLen > 0) {
            inProgress.conditionCodes[condLen - 1].inEdit = true;
            inProgress.conditionCodes[condLen - 1].editField = 'ConditionCode';
          }

          return {
            ...state,
            inProgress,
          };
        }

        const valuesGrid = payload.dataItem.gridType === ValuesGridType.CONDITION_CODES ? inProgress.conditionCodes : inProgress.valueCodes;

        if (payload.field === 'Amount' && payload.dataItem.gridType === ValuesGridType.VALUE_CODES) {
          valuesGridCurrentRowFocus(valuesGrid, payload.dataItem, 'ValueCode');
        } else {
          const field = payload.dataItem.gridType === ValuesGridType.CONDITION_CODES ? 'ConditionCode' : 'Amount';
          valuesGridPrevRowFocus(valuesGrid, payload.dataItem, field, justDeletedRowIndex);
        }
      // forward
      } else {
        exitAllValuesGridEdit(inProgress);

        if (payload.dataItem.gridType === ValuesGridType.CONDITION_CODES) {
          // jumping from the ConditionCodes grid to the ValuesGrid
          if (payload.dataItem.gridType === ValuesGridType.CONDITION_CODES && inProgress.conditionCodes.length > 0
            && (inProgress.conditionCodes[inProgress.conditionCodes.length - 1].id === payload.dataItem.id
              || justDeletedRowIndex >= inProgress.conditionCodes.length)) {

            if (inProgress.valueCodes.length > 0) {
              inProgress.valueCodes[0].inEdit = true;
              inProgress.valueCodes[0].editField = 'ValueCode';
            }
          }
          // usual jumping to the next row
          else {
            valuesGridNextRowFocus(inProgress.conditionCodes, payload.dataItem, 'ConditionCode', justDeletedRowIndex);
          }
        }

        if (payload.dataItem.gridType === ValuesGridType.VALUE_CODES) {
          const valuesLen = inProgress.valueCodes.length;
          if (valuesLen > 0 && inProgress.valueCodes[valuesLen - 1].id === payload.dataItem.id && payload.field === 'Amount') {
            // special handling was already performed
            return {
              ...state,
              inProgress,
            };
          }

          if (payload.field === 'ValueCode' && justDeletedRowIndex === -1) {
            valuesGridCurrentRowFocus(inProgress.valueCodes, payload.dataItem, 'Amount');
          } else {
            valuesGridNextRowFocus(inProgress.valueCodes, payload.dataItem, 'ValueCode', justDeletedRowIndex);
          }
        }
      }

      return {
        ...state,
        inProgress,
      };
    }

    case Key.Escape: {
      const inProgress = {
        ...state.inProgress,
      };

      exitAllValuesGridEdit(inProgress);

      return {
        ...state,
        inProgress,
      };
    }

    default:
      break;
  }

  return state;
};

export const handleValuesGridDragAndDropRow = (state: EncounterState, payload: ValuesGridDragDropRow): EncounterState => {
  // same or incorrect position
  if (payload.toIdx === undefined || payload.fromIdx === payload.toIdx) {
    return state;
  }

  const inProgress = {
    ...state.inProgress,
  };

  const valuesCodes = payload.gridType === ValuesGridType.CONDITION_CODES ? inProgress.conditionCodes : inProgress.valueCodes;
  const curIdx = findItemIndex(valuesCodes, undefined, payload.dataItemId);
  if (curIdx < 0) {
    return state;
  }

  exitAllValuesGridEdit(inProgress);

  const newCollection = Array.from(valuesCodes);
  const [removed] = newCollection.splice(payload.fromIdx, 1);
  newCollection.splice(payload.toIdx, 0, removed);

  if (payload.gridType === ValuesGridType.CONDITION_CODES) {
    updateValueCodes(newCollection, ValuesGridType.CONDITION_CODES);
    inProgress.conditionCodes = newCollection;
  } else {
    updateValueCodes(newCollection, ValuesGridType.VALUE_CODES);
    inProgress.valueCodes = newCollection;
  }

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleValuesGridNavigate = (state: EncounterState, payload: { gridType: ValuesGridType, toFirstRow: boolean }): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  exitAllValuesGridEdit(inProgress);

  switch (payload.gridType) {
    case ValuesGridType.CONDITION_CODES: {
      if (payload.toFirstRow) {
        if (inProgress.conditionCodes.length > 0) {
          inProgress.conditionCodes[0].inEdit = true;
          inProgress.conditionCodes[0].editField = 'ConditionCode';
        }
      } else {
        const len = inProgress.conditionCodes.length;
        if (len > 0) {
          inProgress.conditionCodes[len - 1].inEdit = true;
          inProgress.conditionCodes[len - 1].editField = 'ConditionCode';
        }
      }
      break;
    }
    case ValuesGridType.VALUE_CODES: {
      if (payload.toFirstRow) {
        if (inProgress.valueCodes.length > 0) {
          inProgress.valueCodes[0].inEdit = true;
          inProgress.valueCodes[0].editField = 'ValueCode';
        }
      } else {
        const len = inProgress.valueCodes.length;
        if (len > 0) {
          inProgress.valueCodes[len - 1].inEdit = true;
          inProgress.valueCodes[len - 1].editField = 'Amount';
        }
      }
      break;
    }
    default:
  }

  return {
    ...state,
    inProgress,
  };
}

export const handleValuesGridEditLocateEvent = (state: EncounterState, payload: EditLocateEvent) => {
  const inProgress = {
    ...state.inProgress,
  };

  const gridType = isValuesGridClassName(payload.gridCodeType) ? ValuesGridType.VALUE_CODES : ValuesGridType.CONDITION_CODES;
  if (payload.field === 'GRID') {
    return handleValuesGridNavigate(state, { gridType, toFirstRow: true });
  }

  allValuesGridsAction(inProgress, (code => {
    const newCode: ValuesRow = {
      ...code
    };

    if (code.id === payload.viewId && code.gridType === gridType) {
      let field = payload.field || '';
      if (field === 'code' || field === 'Code') {
        field = gridType === ValuesGridType.VALUE_CODES ? 'ValueCode' : 'ConditionCode';
      }
      newCode.editField = field;
      newCode.inEdit = true;
    } else {
      newCode.inEdit = undefined;
    }

    return newCode;
  }));

  return {
    ...state,
    inProgress,
  };
};