import * as actionTypes from '../constants';

import { EncounterTemplate, EncounterTemplates } from '../models/encounterTemplate';

const createEmptyEncounterTemplateState = (): EncounterTemplates => ({
  templates: [],
  editedTemplateId: '',
  editedTemplate: { id: '' },

  initialized: false,
  serviceStatus: '',
  errorText: ''
});

export const encounterTemplatesReducer = (state = createEmptyEncounterTemplateState(), action) => {
  switch (action.type) {
    case actionTypes.ENCOUNTER_TEMPLATES_LIST_COMPLETED:
      return handleEncounterTemplatesLoaded(state, action.payload);
    case actionTypes.ENCOUNTER_TEMPLATES_ADD:
      return handleEncounterTemplatesAddAndEdit(state, action.payload);
    case actionTypes.ENCOUNTER_TEMPLATES_EDIT_STARTED:
      return handleEncounterTemplatesEditStarted(state, action.payload);
    case actionTypes.ENCOUNTER_TEMPLATES_EDIT_CHANGE_FIELD:
      return handleEncounterTemplatesEditChangeField(state, action.payload);
    case actionTypes.ENCOUNTER_TEMPLATES_UPDATE_COMPLETED:
    case actionTypes.ENCOUNTER_TEMPLATES_CREATE_COMPLETED:
      return handleEncounterTemplatesEditFinished(state, action.payload);
    case actionTypes.ENCOUNTER_TEMPLATES_EDIT_CANCELLED:
      return handleEncounterTemplatesEditCancelled(state);
    case actionTypes.ENCOUNTER_TEMPLATES_DELETE_COMPLETED:
      return handleEncounterTemplatesDelete(state, action.payload);

    case actionTypes.ENCOUNTER_TEMPLATES_LIST_BEGIN:
      return handleBeginEncounterTemplatesAction(state, 'LIST');
    case actionTypes.ENCOUNTER_TEMPLATES_UPDATE_BEGIN:
      return handleBeginEncounterTemplatesAction(state, 'UPDATE');
    case actionTypes.ENCOUNTER_TEMPLATES_DELETE_BEGIN:
      return handleBeginEncounterTemplatesAction(state, 'DELETE');
    case actionTypes.ENCOUNTER_TEMPLATES_CREATE_BEGIN:
      return handleBeginEncounterTemplatesAction(state, 'CREATE');

    case actionTypes.ENCOUNTER_TEMPLATES_LIST_ERROR:
    case actionTypes.ENCOUNTER_TEMPLATES_UPDATE_ERROR:
    case actionTypes.ENCOUNTER_TEMPLATES_DELETE_ERROR:
    case actionTypes.ENCOUNTER_TEMPLATES_CREATE_ERROR:
      return handleEncounterTemplatesActionFailed(state, action.payload);

    default:
      return state;
  }
};

// templates are loaded from anywhere
const handleEncounterTemplatesLoaded = (state: EncounterTemplates, payload: EncounterTemplates): EncounterTemplates => {
  const newState = {
    ...payload,
    initialized: true,
    serviceStatus: '',
    errorText: ''
  };

  return newState;
}

// add new template and start its editing
const handleEncounterTemplatesAddAndEdit = (state: EncounterTemplates, payload: EncounterTemplate): EncounterTemplates => {
  const newTemplate = { ...payload };
  const newTemplates = [...state.templates, newTemplate];
  const newState = {
    ...state,
    templates: newTemplates,
    editedTemplateId: newTemplate.id,
    editedTemplate: { ...newTemplate }
  };

  return newState;
}

// start template editing
const handleEncounterTemplatesEditStarted = (state: EncounterTemplates, payload: EncounterTemplate): EncounterTemplates => {
  const newState = {
    ...state,
    editedTemplateId: payload.id,
    editedTemplate: { ...payload }
  };

  return newState;
}

// edited template changed
const handleEncounterTemplatesEditChangeField = (state: EncounterTemplates, payload: EncounterTemplate): EncounterTemplates => {
  const newState = {
    ...state,
    editedTemplate: { ...payload }
  };

  return newState;
}

// editing finished and new template must be applyed
const handleEncounterTemplatesEditFinished = (state: EncounterTemplates, payload: { id: string,  template: EncounterTemplate }): EncounterTemplates => {
  const newState = {
    ...state,
    templates: state.templates.map(item => item.id === payload.id ? payload.template : item),
    editedTemplateId: '',
    serviceStatus: '',
    errorText: ''
  };

  return newState;
}

// stop editing
const handleEncounterTemplatesEditCancelled = (state: EncounterTemplates): EncounterTemplates => {
  // if we add new template - remove it
  if (state.editedTemplateId === 'new_template') {
    const newState = {
      ...state,
      templates: state.templates.filter(item => item.id !== 'new_template'),
      editedTemplateId: '',
      serviceStatus: '',
      errorText: ''
    };

    return newState;
  }

  // simple stop editing
  const newState = {
    ...state,
    editedTemplateId: '',
    serviceStatus: '',
    errorText: ''
  };

  return newState;
}


// delete existing template by id
const handleEncounterTemplatesDelete = (state: EncounterTemplates, payload: string): EncounterTemplates => {
  const newState = {
    ...state,
    templates: state.templates.filter(item => item.id !== payload),
    serviceStatus: '',
    errorText: ''
  };

  return newState;
}

// start service action
const handleBeginEncounterTemplatesAction = (state: EncounterTemplates, action: string): EncounterTemplates => {
  const newState = {
    ...state,
    serviceStatus: action,
    errorText: ''
  };

  return newState;
}

// something went wrong
const handleEncounterTemplatesActionFailed = (state: EncounterTemplates, error): EncounterTemplates => {
  const newState = {
    ...state,
    errorText: error.message
  };

  return newState;
}
