import './TooltipContainer.less';

import * as React from 'react';
import { Tooltip, TooltipProps } from '..';

export class TooltipContainer extends React.PureComponent<TooltipProps> {
  render() {
    return (
      <span className="tooltip-container">
        <Tooltip
          anchorElement="pointer"
          content={this.props.content}
          className={`tc-tooltip ${this.props.className}`}
          position="auto"
          showCallout={false}
          openDelay={500}
        >
          {this.props.children}
        </Tooltip>
      </span >
    );
  }
}

export default TooltipContainer;
