import { DecodeTokenResultEntity, FacilityMap } from '.';
import { IdDescriptionBase } from './patientEncounter';
import { ClientMode } from './system';

export interface UserState {
  // new props
  isSystemAdmin: boolean;
  highestRole: UserRole;
  clientMode: ClientMode;
  facilityRoles?: {
    facilityId: string;
    role: UserRole;
  };
  privileges: UserPrivileges;

  account: EncoderUserEntity;
  avatarText: string;
  loading: boolean;
  loaded: boolean;

  preferences: UserPreferences;
  loadingPreferences: boolean;

  dirty: boolean;

  // old props (cca era)
  error: boolean;
  errorDetail: string;
  errorReason: string;
  isAdviceAdmin: boolean;
  isPartnerAdviceAdmin: boolean;
  token: string;
  tokenDecoded: DecodeTokenResultEntity | null;
  id: string | null;
  facilities: FacilityMap;
  name: string | null;
  organizationName: string | null;
  organizationId: string | null;
}
// TODO: Consider making this hierarchical, e.g.
// encounter {
//  canSearch
//  ..
// },
// settings {
//   canSystem
//   ..
// }
export interface UserPrivileges {
  canSearch: boolean;
  canCreate: boolean;
  canSystemSettings: boolean;
  // TODO: consider merging these two. for the beta/calculator mode they will not be one in the same
  // but for other environments (interface/standard) i believe they are synonymous
  canSaveInterfaced: boolean;
  canSaveNotInterfaced: boolean;
  canEdit: boolean;
}

export function getUserPrivileges(state?: UserState, encounterRole?: UserRole): UserPrivileges {
  if (!state) {
    return {
      canSearch: false,
      canSaveInterfaced: false,
      canSaveNotInterfaced: false,
      canEdit: false,
    } as UserPrivileges;
  }

  switch (state.clientMode) {
    case ClientMode.Basic:
      return {
        canSaveInterfaced: false,
        // WEB-4611
        canSaveNotInterfaced: true,
        canSearch: false,
        canSystemSettings: false,
        // per requirements, lower the standard role requirement for creating/editing since they can't save
        // canCreate: state.isSystemAdmin || state.highestRole !== UserRole.NOROLE,
        // WEB-3606 - enable
        canCreate: true,
        canEdit: true,
      };
    case ClientMode.Interface:
      return {
        canSearch: false,
        // WEB-3606 - enable
        canCreate: true,
        canSystemSettings: state.isSystemAdmin,
        canSaveInterfaced: true,
        canSaveNotInterfaced: false,
        canEdit: true,
      };
    // treat default as standard
    default:
    case ClientMode.Standard:
      let canEditOrSave = state.isSystemAdmin;
      if (!state.isSystemAdmin) {
        canEditOrSave = typeof encounterRole !== 'undefined' ? encounterRole >= UserRole.CODER : state.highestRole >= UserRole.CODER;
      }
      return {
        canSearch: state.isSystemAdmin || state.highestRole >= UserRole.MANAGER,
        canCreate: state.isSystemAdmin || state.highestRole >= UserRole.CODER,
        canSystemSettings: state.isSystemAdmin,
        canSaveInterfaced: canEditOrSave,
        canSaveNotInterfaced: canEditOrSave,
        canEdit: canEditOrSave,
      };
  }
}

export enum UserScope {
  PARTNER = 'Partner',
  PERSONAL = 'Personal',
  FACILITY = 'Facility',
  ALL = 'All',
  UNKNOWN = 'Unknown',
}

export interface EncoderUserEntity {
  clientId: string;
  customerId: string;
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  status: UserStatus;
  licensePackageId: string;
  version: string;
  isSystemAdmin: boolean;
  roles: FacilityRolesEntity[];
  invitationLink?: string;
  hasMoreChunks?: boolean;
  mailSent?: boolean;
  originalOrganizationId?: string;
}

export interface ManageEncoderUserState {
  selectedAccount: EncoderUserEntity;
  errorText: string;
  inviteErrorTexts?: string[];
  inviteSuccesses?: string[];
  loading: boolean;
  dirty: boolean;
}

export enum UserRole {
  ADMIN = 400,
  POWER = 300,
  CODER = 200,
  MANAGER = 100,
  NOROLE = 0,
}

export const UserRoleMap = {
  FacilityAdmin: UserRole.ADMIN,
  PowerCoder: UserRole.POWER,
  Coder: UserRole.CODER,
  CaseManager: UserRole.MANAGER,
  NoRole: UserRole.NOROLE,
};

export interface CurrentUserEntityRoles {
  facilityId: string;
  role: UserRole;
}

export interface CurrentUserEntity {
  isSystemAdmin: boolean;
  highestRole: UserRole;
  roles?: CurrentUserEntityRoles[];
  clientMode: ClientMode;
  organizationName: string;
}
export interface CurrentUserServiceResponse {
  IsSystemAdmin: boolean;
  HighestRole: string;
  ClientMode: string | null;
  Roles?: {
    FacilityId: string;
    Role: string;
  }[];
}

export interface FacilityRolesEntity {
  Facility: string;
  FacilityId: string;
  Role: string;
  UserId: string;
  Version: string;
  FacilityIsActive: boolean;
}

export interface EncoderUserListState {
  totalUsers: number;
  users: EncoderUserEntity[];
  errorText: string;
  loading: boolean;
}

export interface EncoderUserListResponseEntity {
  count: number;
  total_count: number;
  users: EncoderUser[];
}

export interface EncoderUserListResultEntity {
  count: number;
  total_count: number;
  users: EncoderUserEntity[];
}

export interface EncoderUser {
  ClientId: string;
  CustomerId: string;
  UserId: string;
  FirstName: string;
  LastName: string;
  UserName: string;
  Email: string;
  IsSystemAdmin: boolean;
  Status: UserStatus;
  Version: string;
  InvitationLink?: string;
  LicensePackageId?: string;
  MailSent?: boolean;
  OriginalOrganizationId?: string;
  ErrorDetails?: string;
}

export enum UserStatus {
  INVITED = 'Invited',
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
}

export enum SyncStatus {
  PENDING = 'Pending',
  CONSISTENT = 'Consistent',
}

export interface UserPreferencesEntity {
  key: string;
  value: object;
}

export interface UserPreferences {
  [key: string]: string | boolean | number | object;
  HideExternalLinkWarning: boolean;
  AnimationDisabled: boolean;
}

export const canCreateEncountersForFacility = (role?: string): boolean => {
  return role === "FacilityAdmin" || role === "PowerCoder" || role === "Coder";
}

export const getAvailableFacilities = (allFacilities: IdDescriptionBase[], currentFacilityId?: string): IdDescriptionBase[] => {
  if (!allFacilities || !allFacilities.length) {
    return [];
  }

  const available = allFacilities.filter((facility) => facility.id === currentFacilityId
    || canCreateEncountersForFacility(facility.CurrentUserRole));

  return available;
}
