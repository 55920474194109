import { deleteEndpoint, get, patch, post } from '../../utils/net';

import { EncounterEntity, EncounterSnapshotEntity, EncounterState, InterfaceModes, InterfacePushOptions, RecoveredEncounterEntity } from '../../models/encounterEntity';
import { EncounterSearchServiceResultEntity, JSONPATCH, ServiceEncounterEntity } from '../../models/encounterServiceEntity';
import { SearchEncounterFilter } from '../../models/searchEncounterFilter';

import { ConcurrencyTarget } from '../../models/concurrencyTargetEnum';
import { ChoiceListsState, IdDescriptionBase } from '../../models/patientEncounter';
import {
  createFilterString,
  getEncounterChanges,
  mapEncounterResultToEntity,
  mapSearchServiceEncounterResultToEntity,
} from './encounterMapping';
import { DRGAnalyzeResultEntity } from '../../models/groupingEntity';
import { EncounterChoiceListsUpdate } from '../../scenes/Encounter/actions/encounterFieldChange';
import { CreateEncounterState } from '../../models/createEncounter';
import { ExtraOptions } from '../tee';
import { legacyPostMessage } from '../../utils/interface';

const baseURL = process.env.REACT_APP_API_PATH;

const openEncounterById = (id: string, facilities: IdDescriptionBase[]): Promise<EncounterEntity> => {
  const url = `${baseURL}/api/CurrentEncounter/Get/${id}`;

  /* TODO: use  'X-TC-Client': user.clientId, 'X-TC-Customer': user.customerId, 'X-TC-User': user.id,
  const headers = {
    'Content-Type': 'application/json',
  };
  */
  const extraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) =>
    mapEncounterResultToEntity(result.body, facilities)
  );
};

// *** Search encounters section ***

const searchEncounters = (filter: SearchEncounterFilter): Promise<EncounterEntity[]> => {
  const filterStr = createFilterString(filter);
  const sortingStr = 'SortAsc.MedicalRecordNumber=1&SortDesc.AdmitDate=2'
  const url = `${baseURL}/api/encounters/search?${sortingStr}&${filterStr}`;

  const headers = {
    Accept: 'text/html',
  };
  const extraOptions = {
    headers,
    useExactPath: true,
    // used to cancel promise to prevent overlapping search results
    singletonKey: 'SEARCH_ENCOUNTERS'
  };

  return get(url, extraOptions).then((result) => {
    return mapSearchServiceEncounterResultsToEntity(result.body.Items);
  });
};

const mapSearchServiceEncounterResultsToEntity = (list: EncounterSearchServiceResultEntity[]): EncounterEntity[] => {
  const encounters = list.map((item) => mapSearchServiceEncounterResultToEntity(item));

  return encounters;
};

// *** Save encounter section ***

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const saveOrPullEncounter = (id: string, version?: string, isInterfaced?: boolean, InterfaceMode?: string): Promise<any> => {
  const isLegacyInterface = InterfaceMode === InterfaceModes.Legacy;
  const url = isInterfaced && isLegacyInterface
    ? `${baseURL}/api/Interface/Pull/${id}` : `${baseURL}/api/CurrentEncounter/Save/${id}`;

  const headers = {
    'If-Match': version,
  };
  const extraOptions = {
    headers,
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  const data = {};

  if (isInterfaced && isLegacyInterface) {
    return get(url, extraOptions).then((result) => result.text);
  }

  return post(url, data, extraOptions)
    .then((result) => {
      return result.body;
    });
};

// *** Save encounter changes section ***

const saveEncounterChanges = (
  encounter: EncounterState,
  choiceLists: ChoiceListsState,
  changesToSave: JSONPATCH[] | null
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const version = encounter.inProgress.concurrencyVersion || '_FORCE_DEV_';
  const { id } = encounter.inProgress;
  const url = `${baseURL}/api/CurrentEncounter/Change/${id}`;

  const headers = {
    'If-Match': version,
  };
  const extraOptions = {
    // FIXME: shouldn't this be Changeset? arent we throwing away the response?
    concurrencyTarget: ConcurrencyTarget.Encounter,
    headers,
    method: 'PATCH',
    useExactPath: true,
  };

  // we can have already calculated changes: use them instead of the calculating
  const data = changesToSave || getEncounterChanges(encounter, choiceLists);

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'qa') {
    console.log('!!!Save Changes!!!!', encounter, data);
  }

  return patch(url, data, extraOptions).then((result) => result.body);
};

// *** Create encounter section ***

const createEncounter = (
  newEncounterData: CreateEncounterState,
): Promise<EncounterEntity> => {
  const url = `${baseURL}/api/CurrentEncounter/Create`;
  const data = {
    EncounterTypeId: newEncounterData.encounterType,
    FacilityId: newEncounterData.facility,
    MedicalRecordNumber: newEncounterData.mrn,
  };
  const extraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    useExactPath: true,
  };

  return post(url, data, extraOptions).then((result) =>
    mapEncounterResultToEntity(result.body, newEncounterData.choiceLists.facilities)
  );
};

// *** Get encounter section ***

const getEncounter = (
  encounter: EncounterState,
  facilities: IdDescriptionBase[]
): Promise<EncounterEntity> => {
  const url = `${baseURL}/api/CurrentEncounter/Get/${encounter.inProgress.id}`;

  const extraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) =>
    mapEncounterResultToEntity(result.body, facilities)
  );
};

// *** Snapshot encounter section ***

const snapshotEncounter = (
  id: string
): Promise<EncounterSnapshotEntity> => {
  // TODO: remove workaround for russian "c"
  const url = `${baseURL}/api/CurrentEncounter/CaptureSnapshot/${id}`;

  const extraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => result.body);
};

// *** Close encounter section ***

const closeEncounter = (id: string, concurrencyVersion?: string): Promise<string> => {
  const version = concurrencyVersion || '_FORCE_DEV_';
  const url = `${baseURL}/api/CurrentEncounter/Close/${id}`;

  const headers = {
    'If-Match': version,
  };
  const extraOptions = {
    headers,
    useExactPath: true,
  };

  const data = {};

  return (
    post(url, data, extraOptions)
      // TODO: analyze result and parse errors
      .then(() => mapEncounterCloseResultToEntity())
  );
};

const mapEncounterCloseResultToEntity = (): string => {
  // TODO: I have empty result here
  return 'Success';
};

// *** Process encounter section ***

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processEncounter = (encounterEntity: EncounterEntity, groupingType?: string, previousKey?: string): Promise<any> => {
  const { id } = encounterEntity;
  const version = encounterEntity.concurrencyVersion || '_FORCE_DEV_';
  const groupingResultPart = groupingType ? `groupingResultId=${groupingType}` : '';
  const previousKeyPart = previousKey ? `key=${previousKey}` : '';
  const groupingPart = groupingResultPart || previousKeyPart ? `?${groupingResultPart}${groupingResultPart && previousKeyPart ? "&" : ""}${previousKeyPart}` : '';
  const url = `${baseURL}/api/CurrentEncounter/Process/${id}${groupingPart}`;

  const headers = {
    'If-Match': version,
  };

  const extraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    headers,
    useExactPath: true,
  };

  const data = {};

  console.log('Process');

  return post(url, data, extraOptions).then((result) => result.body);
};

const analyzeEncounter = (encounterEntity: EncounterEntity): Promise<DRGAnalyzeResultEntity[]> => {
  const version = encounterEntity.concurrencyVersion || '_FORCE_DEV_';
  const url = `${baseURL}/api/CurrentEncounter/DRGAnalyze/${encounterEntity.id}?groupingResultId=${encounterEntity.ipGroupingType}`;

  const headers = {
    'If-Match': version,
  };

  const extraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    headers,
    useExactPath: true,
  };

  console.log('Analyze');

  return get(url, extraOptions).then((result) => result.body);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processMNE = (encounterEntity: EncounterEntity, previousKey?: string): Promise<any> => {
  const { id } = encounterEntity;
  const version = encounterEntity.concurrencyVersion || '_FORCE_DEV_';
  const groupingPart = previousKey ? `?key=${previousKey}` : '';
  const url = `${baseURL}/api/CurrentEncounter/ProcessMNE/${id}${groupingPart}`;

  const headers = {
    'If-Match': version,
  };

  const extraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    headers,
    useExactPath: true,
  };

  const data = {};

  console.log('Process MNE');

  return post(url, data, extraOptions).then((result) => result.body);
};

// *** Heartbeat section ***
const heartbeatEncounter = (encounter: EncounterState): Promise<string> => {
  const version = encounter.inProgress.concurrencyVersion || '_FORCE_DEV_';
  const url = `${baseURL}/api/CurrentEncounter/heartbeat/${encounter.inProgress.id}`;

  const headers = {
    'If-Match': version,
  };
  const extraOptions = {
    headers,
    useExactPath: true,
  };

  return (
    get(url, extraOptions)
      // FIXME: check result status 2xx?
      .then(() => {
        return 'Success';
      })
  );
};

// map choice lists values to new facility
const mapChoiceListsToNewFacility = (facility: string, inProgress: EncounterEntity): Promise<EncounterChoiceListsUpdate> => {
  const version = inProgress.concurrencyVersion || '_FORCE_DEV_';
  const url = `${baseURL}/api/CurrentEncounter/facilityMapInfo/${inProgress.id}?facilityid=${facility}`;

  const headers = {
    'If-Match': version,
  };
  const extraOptions = {
    headers,
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

// redirect in interfaced mode
const redirectInInterfacedMode = (inProgress: EncounterEntity): Promise<boolean> => {
  const version = inProgress.concurrencyVersion || '_FORCE_DEV_';
  const url = `${baseURL}/api/interface/redirect/${inProgress.id}`;

  const headers = {
    'If-Match': version,
  };
  const extraOptions = {
    headers,
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return true;
  });
};

const interfacePush = (payload: string, options?: InterfacePushOptions): Promise<any> => {
  const url = `${baseURL}/api/Interface/Push${options && options.debug ? '?callback=http://fake.callback' : ''}`;
  const extraOptions: ExtraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    useExactPath: true,
    headers: {
      'Content-Type': 'text/plain',
    }
  };

  return post(url, payload, extraOptions).then((result) => {
    return result.body;
  });
}

// cancel in interfaced mode
const cancelInterfacedEncounter = (encounterId: string, version?: string): Promise<boolean> => {
  const url = `${baseURL}/api/interface/cancel/${encounterId}`;

  // const version = inProgress.concurrencyVersion || '_FORCE_DEV_';
  // 'If-Match': version,

  const headers = {};
  if (version) {
    headers['If-Match'] = version;
  }

  const extraOptions = {
    headers,
    useExactPath: true,
  };

  return post(url, {}, extraOptions).then((result) => {
    legacyPostMessage({ type: "canceled" });
    return result.body;
  });

  /*
  TODO: test sync sending
  return Promise.resolve()
    .then(() => {
      const request = new XMLHttpRequest();
      request.open('POST', url, false);
      request.send(null);

      return true;
    });
  */
};

// Get Recovered Encounters
const getRecoveredEncounters = (): Promise<RecoveredEncounterEntity[]> => {
  const url = `${baseURL}/api/recovery/getSessions`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

// Remove Recovered Encounters
const removeRecoveredEncounter = (encounterId: string): Promise<RecoveredEncounterEntity[]> => {
  const url = `${baseURL}/api/recovery/removeSession/${encounterId}`;

  const extraOptions = {
    useExactPath: true,
  };

  return deleteEndpoint(url, extraOptions).then((result) => {
    return result.body;
  });
};

export const encounterAPI = {
  analyzeEncounter,
  closeEncounter,
  createEncounter,
  getEncounter,
  heartbeatEncounter,
  openEncounterById,
  processEncounter,
  processMNE,
  saveOrPullEncounter,
  saveEncounterChanges,
  searchEncounters,
  snapshotEncounter,
  mapChoiceListsToNewFacility,
  redirectInInterfacedMode,
  cancelInterfacedEncounter,
  interfacePush,
  getRecoveredEncounters,
  removeRecoveredEncounter
};
