
import * as React from 'react';
import { LoadingPanel } from './LoadingPanel';

interface LoadingControllerProps {
  isLoading: boolean;
  selector?: string;
}

export class LoadingController extends React.Component<LoadingControllerProps> {
  render() {
    return this.props.isLoading && <LoadingPanel selector={this.props.selector} />;
  }
}
