import './DropDownButton.less';

import * as React from 'react';

import { DropDownButton as KDropDownButton } from '@progress/kendo-react-buttons';
import { DropDownButtonProps as KDropDownButtonProps } from '@progress/kendo-react-buttons/dist/npm/ListButton/models/ListButtonProps';

export interface DropDownButtonProps extends KDropDownButtonProps {
  title?: string;
}

export const DropDownButton = function render(props: React.PropsWithChildren<DropDownButtonProps>) {
  const { look, className, popupSettings, ...otherProps } = props;
  const flatOrDefinedLook = look || 'flat';
  const popClass = `${popupSettings?.popupClass} tc-dropdownbutton-popup`;
  const popSettings = { ...popupSettings, ...{ popupClass: popClass } };
  return (
    <span title={props.title}>
      <KDropDownButton
        className={`tc-dropdownbutton ${className}`}
        look={flatOrDefinedLook}
        popupSettings={popSettings}
        {...otherProps}>{props.children}
      </KDropDownButton>
    </span>);
}
