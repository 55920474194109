import { connect } from 'react-redux';
import { getAvailableLicensePackagesAction } from '../../actions/license';
import { State } from '../../reducers';
import { LicenseDDL } from './LicenseDDL';

const mapStateToProps = (state: State) => ({
  license: state.license,
});

const mapDispatchToProps = (dispatch) => ({
  getAvailableLicensePackages: () => dispatch(getAvailableLicensePackagesAction()),
});

export const LicenseDDLContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LicenseDDL);
