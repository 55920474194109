import * as moment from 'moment';
import { EncounterEntity } from '../../../models/encounterEntity';
import { toFormattedDate } from '../../../utils/date';
import { patternValidator } from '../../../validators/simpleValidators';

// calculate age for the date
export default function calculateAge(encounter: EncounterEntity): string {
  // use server answer if possible
  if (encounter.ValidationResult && encounter.ValidationResult.CalculatedAge && !encounter.ageInYears) {
    return encounter.ValidationResult.CalculatedAge;
  }

  const formatted = toFormattedDate(encounter.dateOfBirth);

  if (!formatted) {
    return '';
  }

  const dateOfBirth = moment(formatted);

  let calculatedDate = moment(moment.now());

  if (encounter.dischargeDate) {
    const dischargeDate = toFormattedDate(encounter.dischargeDate);
    if (dischargeDate) {
      calculatedDate = moment(dischargeDate);
    }
  }

  if (encounter.admitDate) {
    const admitDate = toFormattedDate(encounter.admitDate);
    if (admitDate) {
      calculatedDate = moment(admitDate);
    }
  }

  const differenceY = calculatedDate.diff(dateOfBirth, 'years');

  if (differenceY > 124) {
    return '';
  }

  if (differenceY >= 1) {
    return `${differenceY} y`;
  }

  const differenceM = calculatedDate.diff(dateOfBirth, 'months');

  if (differenceM >= 1) {
    return `${differenceM} m`;
  }

  const difference = calculatedDate.diff(dateOfBirth, 'days');

  if (difference <= 0) {
    return '0 d';
  }

  return `${difference} d`;
}

const ageSkipYears = new RegExp('^[0-9]{1,3}$');
const ageValuePattern = new RegExp('^[0-9]{1,3} *[ydm]{1}$');
const fullMonthPattern = new RegExp('(^31 *)d$');

// correct age field
export const correctAgeInYearsField = (ageInYears?: string): string => {
  const value = ageInYears ? ageInYears.toLowerCase().trim() : '';

  if (!value) {
    return '';
  }

  // return '0 d' instead of 0
  if (value === '0') {
    const newValue = '0 d';

    return newValue;
  }

  // add y to the empty field if it is <= 124
  if (ageSkipYears.test(value)) {
    const numbers = parseInt(value, 10);
    if (numbers > 124) {
      return value;
    }

    const newValue = `${value} y`;

    return newValue;
  }

  // convert 31d to the 1 m
  if (fullMonthPattern.test(value)) {
    const newValue = '1 m';

    return newValue;
  }

  // set exactly one space before value and y/m/d
  if (ageValuePattern.test(value)) {
    const numbers = parseInt(value, 10);
    const sign = value[value.length - 1];

    if (sign === 'd' && numbers > 31) {
      return value;
    }

    if (sign === 'y' && numbers > 124) {
      return value;
    }

    const newValue = `${numbers} ${sign}`;

    return newValue;
  }

  return value;
};

export function validateAgeInYearsField(value?: string): string {
  const ageInYears = value ? value.toLowerCase().trim() : '';

  if (!ageInYears) {
    return '';
  }

  const pattern = '((?<y>^[0-9]{1,3} *)y{1}$)|((?<m>^[0-9]{1,3} *)m{1}$)|(^[0-9]{1,3}$)|((?<d>^[0-9]{1,2} *)d{1}$)';
  const patternValidatorResult = patternValidator(ageInYears, pattern);
  if (patternValidatorResult) {
    return patternValidatorResult;
  }

  const ageInDaysPattern = new RegExp('(?<d>^[0-9]{1,2} *)d{1}$');
  const ageInYearsPattern = new RegExp('(?<y>^[0-9]{1,3} *)y{1}$');
  const ageSkipYearsPattern = new RegExp('^[0-9]{1,3}$');

  if (ageInDaysPattern.test(ageInYears)) {
    const days = parseInt(ageInYears, 10);

    if (days > 31) {
      return 'Days can\'t be more than 31 days';
    }
  }

  if (ageInYearsPattern.test(ageInYears) || ageSkipYearsPattern.test(ageInYears)) {
    const years = parseInt(ageInYears, 10);

    if (years > 124) {
      return 'Age can\'t be more than 124 years';
    }
  }

  return '';
}
