import './Notice.less';

import * as React from 'react';
import * as moment from 'moment';
import { ErrorDetails } from '../../../models';

export interface NoticeProps {
  notice: ErrorDetails;
  className?: string;
}

export class Notice extends React.PureComponent<NoticeProps> {
  render() {
    return (
      <div className={`notice-block ${this.props.className}`}>
        <div>
          <strong className="notice-caption">{this.props.notice.error}</strong>
          <p className="notice-description">{this.props.notice.description}</p>
          <strong className="notice-time">{moment(this.props.notice.time).fromNow()}</strong>
        </div>
      </div>
    );
  }
}
