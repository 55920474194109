import './TextArea.less';

// FIXME: rewrite this component

import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { Editor } from '@progress/kendo-react-editor';
import { Key } from '../../constants/keyboard';
import { Label } from '../Label/Label';

export interface TextAreaProps {
  name: string;
  title: string;
  content?: string;
  onChange?: (fieldName: string, value: string) => void;
  placeholder?: string;
  editMode?: boolean;
  height?: number;
  error?: string;
  maxLength?: number;
  width?: string;
  defaultHeight: number;
}

interface TextAreaState {
  editorHeight?: number;
  editorStyle?: { height: string };
  wrapStyle?: {};
  overlayStyle?: {};
  wrapHeight?: number;

  propsHeight?: string | number;
  propsWidth?: string | number;
}

const calculateStyles = (props: TextAreaProps) => {
  const height = typeof props.height === 'number' ? props.height : props.defaultHeight;
  const editMode = typeof props.editMode === 'boolean' ? props.editMode : false;
  // the toolbar subtracts 5px from specified editor height
  const editorHeight = height - 37;
  const editorStyle = { height: `${editorHeight}px`};
  const wrapStyle = {
    height,
    width: props.width ? props.width : '90%',
    zIndex: editMode ? 0 : 2
  };
  const overlayStyle = {
    ...wrapStyle,
    width: '100%',
  };

  return {
    editorHeight,
    editorStyle,
    overlayStyle,
    wrapStyle
  };
};

export class TextArea extends React.PureComponent<TextAreaProps, TextAreaState> {
  static defaultProps = {
    defaultHeight: 100
  };

  el: HTMLTextAreaElement;

  editor: kendo.ui.Editor;

  constructor(props: TextAreaProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (!this.editor) {
      const $editorEl = Editor.jQuery(this.el);
      this.editor = $editorEl.kendoEditor({
        change: (e: kendo.ui.EditorEvent) => {
          if (this.props.onChange) {
            this.props.onChange(this.props.name, e.sender.value());
          }
        },
        blur: () => {
          this.onBlur();
        },
        select: (e) => {
          e.sender.element.closest('.editor-wrap').addClass('tc-state-focused');
        },
        tools: [
          { name: 'bold' },
          { name: 'italic' },
          { name: 'underline' },
          { name: 'insertUnorderedList' },
          { name: 'insertOrderedList' },
          {
            exec: this.onCustomLinkClick,
            name: 'customLink',
            tooltip: 'Insert hyperlink',
          },
          { name: 'unlink' },
        ]
      }).data('kendoEditor');
    }
  }

  componentDidUpdate() {
    this.editor.value(this.props.content || '');
  }

  static getDerivedStateFromProps(nextProps: TextAreaProps, prevState: TextAreaState) {
    if (nextProps.width !== prevState.propsWidth || nextProps.height !== prevState.propsHeight) {
      return {
        ...calculateStyles(nextProps),
        propsHeight: nextProps.height,
        propsWidth: nextProps.width
      };
    }

    return null;
  }

  componentWillUnmount() {
    if (this.editor) { this.editor.destroy(); }
  }

  render() {
    const id = uuid();
    const hasError = this.props.error && this.props.error.length > 0;
    let wrapperClass = hasError
      ? 'form-group has-error inform-outer-wrap tc-textarea'
      : 'form-group inform-outer-wrap tc-textarea';
    if (!this.props.editMode) { wrapperClass += ' tc-state-disabled'; }
    const error = hasError
      ? <Label className="error-label">{this.props.error}</Label>
      : null;
    let textarea = (
      <textarea
        ref={(c) => { this.el = c as HTMLTextAreaElement; }}
        className="form-input k-textbox"
        name={this.props.name}
        value={this.props.content}
        onChange={this.onChange}
        placeholder={this.props.placeholder}
        style={this.state.editorStyle}
        maxLength={this.props.maxLength}
        onBlur={this.onBlur}
      />
    );
    if (!this.props.editMode) {
      textarea = (
        <textarea
          ref={(c) => { this.el = c as HTMLTextAreaElement; }}
          className="form-input k-textbox k-state-disabled"
          name={this.props.name}
          value={this.props.content}
          onChange={this.onChange}
          placeholder={this.props.placeholder}
          readOnly
          style={this.state.editorStyle}
          maxLength={this.props.maxLength}
        />
      );
    }
    return (
      <div className={wrapperClass}>
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: `#id-${id} .k-content { height: ${this.state.editorHeight}px !important; }`}}
        />
        <label className="form-label" htmlFor={`id-${id}`}>{this.props.title}</label>
        <div className="editor-wrap" style={this.state.wrapStyle} id={`id-${id}`}>
          <div className="editor-overlay" style={this.state.overlayStyle} />
          <div className="editor">
            {textarea}
          </div>
        </div>
        {error}
      </div>
    );
  }

  private onCustomLinkClick() {
    // we don't bind this to TextArea so it will be the jQuery event
    // and able to find kendoEditor
    const editor = $(this).data('kendoEditor');

    // Store the editor range object
    // Needed for IE
    const storedRange = editor.getRange();

    const popupHtml = `
    <div class="k-editor-dialog k-popup-edit-form k-edit-form-container" style="width:auto;">
      <div class="k-edit-label">
        <label for="k-editor-link-url">Web address</label>
      </div>
      <div class="k-edit-field">
        <input type="text" class="k-input tc-editor-link-url" id="k-editor-link-url" value="http://">
      </div>
      <div class="k-edit-label">
        <label for="k-editor-link-text">Text</label>
      </div>
      <div class="k-edit-field">
        <input
          type="text"
          class="k-input tc-editor-link-text"
          id="k-editor-link-text"
          value="${storedRange.toString()}"
        >
      </div>
      <div class="k-edit-buttons k-state-default">
        <button class="k-dialog-insert k-button k-primary">Insert</button>
        <button class="k-dialog-close k-button">Cancel</button>
      </div>
    </div>`;

    // create a modal Window from a new DOM element
    const popupWindow = $(popupHtml)
      .appendTo(document.body)
      .kendoWindow({
        activate: (d: kendo.ui.WindowEvent) => {
          d.sender.element.find('.tc-editor-link-url').select();
        },
        // remove the Window from the DOM after closing animation is finished
        deactivate: (w: kendo.ui.WindowEvent) => {
          w.sender.element.parent().unwrap();
          w.sender.destroy();
        },
        modal: true,
        open: (d: kendo.ui.WindowEvent) => {
          d.sender.element.parent().wrap('<div class="trucode" />');
        },
        resizable: false,
        title: 'Insert custom content',
        visible: false,
        width: 600,
      }).data('kendoWindow').center().open();

    const onInsert = () => {
      const customHref = popupWindow.element.find('.tc-editor-link-url').val() || '';
      let customText = popupWindow.element.find('.tc-editor-link-text').val() || '';
      if (customHref !== 'http://') {
        if (customText.toString().trim().length === 0) { customText = customHref; }
        const linkElement = `<a target="_blank" href="${customHref}">${customText}</a>`;
        editor.selectRange(storedRange);
        editor.exec('insertHtml', { value: linkElement });
      }
    };

    const close = () => {
      // detach custom event handlers to prevent memory leaks
      popupWindow.element.find('.k-edit-buttons button').off();
      popupWindow.close();
    };

    // insert the new content in the Editor when enter is pressed or
    // when insert button is clicked
    popupWindow.element.find('.tc-editor-link-url,.tc-editor-link-text').keydown(e => {
      // TODO: test when we uncomment any TextArea
      if (e.key === Key.Enter) {
        onInsert();
        close();
      }
    });
    popupWindow.element.find('.k-dialog-insert').click(onInsert);

    // close the Window when any button is clicked
    popupWindow.element.find('.k-edit-buttons button').click(close);
  }

  private onChange = () => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.name, e.target.value);
    }
  }

  private onBlur = () => (e) => {
    e.sender.closest('.editor-wrap').removeClass('tc-state-focused');
  }
}
