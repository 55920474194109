import * as actionTypes from '../constants';

import { UserActivityState, WAIT_TICKS } from '../models/userActivity';

const createEmptyUserActivityState = (): UserActivityState => ({
  lastActivity: '',
  showTimeoutDialog: false,
  shownTimeout: WAIT_TICKS,
});

export const userActivityReducer = (state = createEmptyUserActivityState(), action) => {
  switch (action.type) {
    case actionTypes.USER_ACTIVITY_UPDATE_LAST_ACTIVITY:
      return handleUpdateLastActivity(state, action.payload);
    case actionTypes.USER_ACTIVITY_UPDATE_TIMEOUT_DIALOG:
      return handleUpdateTimeoutDialog(state, action.payload);        
    default:
      return state;
  }
};

const handleUpdateLastActivity = (state: UserActivityState, lastActivity: string): UserActivityState => {
  const newState = {
    ...state,
    lastActivity
  };

  return newState;
}

const handleUpdateTimeoutDialog = (state: UserActivityState, payload: { showTimeoutDialog: boolean, shownTimeout: number, startTime?: string }): UserActivityState => {
  const newState = {
    ...state,
    showTimeoutDialog: payload.showTimeoutDialog,
    shownTimeout: payload.shownTimeout,
    // it must be setup during first timeout dialog showing
    showTimeoutDialogFrom: payload.startTime ? payload.startTime : state.showTimeoutDialogFrom,
  };

  return newState;
}


