import './SidebarNav.less';
import * as React from 'react';
import { LoadingController } from '..';

interface SidebarNavProps {
  className?: string;
  heading?: string | JSX.Element;
  loading?: boolean;
  fixedWidth?: boolean;
}

export function SidebarNav(props: React.PropsWithChildren<SidebarNavProps>) {
  const title = typeof props.heading === 'string' ? <h2>{props.heading}</h2> : <div className="custom-heading">{props.heading}</div>;
  return (
    <div className={`sidebar-nav-wrapper${props.fixedWidth ? ' fixed-width' : ''} ${props.className}`}>
      <div className="sidebar-nav">
        {title}
        {
          props.loading &&
          <LoadingController
            selector=".sidebar-nav"
            isLoading={props.loading}
          />
        }
        {
          props.children &&
          <ul>
            {React.Children.map(props.children, child => {
              return (<li>{child}</li>);
            })}
          </ul>
        }
      </div>
    </div>
  );
}

export default SidebarNav;
