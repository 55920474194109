import { CodeGridColumnsInfo, CodeGridsColumns, DEFAULT_CODEGRIDS_COLUMNS } from '../../models/columns';

const applyOnlySupportedFields = (loaded: string| null, defaultSettings: CodeGridColumnsInfo)
: CodeGridColumnsInfo => {

  if (!loaded) {
    return defaultSettings;
  }

  const settings: CodeGridColumnsInfo = JSON.parse(loaded);
  const { columnsInfo } = settings;

  Object.keys(columnsInfo).map((key) => {
    const columnInfo = columnsInfo[key];

    columnInfo.noEditHack = defaultSettings.columnsInfo[key].noEditHack;
    columnInfo.title = defaultSettings.columnsInfo[key].title;

    return key;
  });

  return settings;
};

// do we want to store settings as single object or as changed grids objects to reduce space?
export const loadColumnsInfoFromLocalStorage = (): CodeGridsColumns => {
  clearColumnsInfoStorages();
  try {
    const settings: CodeGridsColumns = {};

    let gridSettings = localStorage.getItem('TC_COLUMNS_V1_diagnosesPdxOff');
    settings.diagnosesPdxOff =
            applyOnlySupportedFields(gridSettings, DEFAULT_CODEGRIDS_COLUMNS.diagnosesPdxOff);

    gridSettings = localStorage.getItem('TC_COLUMNS_V1_diagnosesPdxOn');
    settings.diagnosesPdxOn =
            applyOnlySupportedFields(gridSettings, DEFAULT_CODEGRIDS_COLUMNS.diagnosesPdxOn);

    gridSettings = localStorage.getItem('TC_COLUMNS_V1_inProcedures');
    settings.inProcedures =
            applyOnlySupportedFields(gridSettings, DEFAULT_CODEGRIDS_COLUMNS.inProcedures);

    gridSettings = localStorage.getItem('TC_COLUMNS_V1_outProcedures');
    settings.outProcedures =
            applyOnlySupportedFields(gridSettings, DEFAULT_CODEGRIDS_COLUMNS.outProcedures);

    gridSettings = localStorage.getItem('TC_COLUMNS_V1_visitReasons');
    settings.visitReasons =
            applyOnlySupportedFields(gridSettings, DEFAULT_CODEGRIDS_COLUMNS.visitReasons);

    gridSettings = localStorage.getItem('TC_COLUMNS_V1_diagnosesPsych');
    settings.diagnosesPsych =
            applyOnlySupportedFields(gridSettings, DEFAULT_CODEGRIDS_COLUMNS.diagnosesPsych);

    return settings;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Cannot read columns settings. Use default');
    return DEFAULT_CODEGRIDS_COLUMNS;
  }
};

export const saveColumnsInfoToLocalStorage = (key: string, gridSettings: CodeGridColumnsInfo) => {
  const storageKey = `TC_COLUMNS_V1_${key}`;
  localStorage.setItem(storageKey, JSON.stringify(gridSettings));
};

// do we want to store settings as single object or as changed grids objects to reduce space?
export const loadColumnsInfoFromLocalStorageSingleObject = (): CodeGridsColumns => {
  try {
    const settings = localStorage.getItem('TC_COLUMNS_SETTINGS_V1');
    if (!settings) {
      return DEFAULT_CODEGRIDS_COLUMNS;
    }
    return JSON.parse(settings);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Cannot read columns settings. Use default');
    return DEFAULT_CODEGRIDS_COLUMNS;
  }
};

export const saveColumnsInfoToLocalStorageSingleObject = (settings: CodeGridsColumns) => {
  localStorage.setItem('TC_COLUMNS_SETTINGS_V1', JSON.stringify(settings));
};

export const loadShowResizeWarning = (): boolean => {
  const value = localStorage.getItem('TC_COLUMNS_SETTINGS_RESIZE_WARNING_V1');

  return value !== 'false';
};

export const saveShowResizeWarning = (show: boolean) => {
  localStorage.setItem('TC_COLUMNS_SETTINGS_RESIZE_WARNING_V1', show ? 'true' : 'false');
};

export const loadShowReorderWarning = (): boolean => {
  const value = localStorage.getItem('TC_COLUMNS_SETTINGS_REORDER_WARNING_V1');

  return value !== 'false';
};

export const saveShowReorderWarning = (show: boolean) => {
  localStorage.setItem('TC_COLUMNS_SETTINGS_REORDER_WARNING_V1', show ? 'true' : 'false');
};

export const clearColumnsInfoStorages = () => {
  localStorage.removeItem('TC_COLUMNS_V1_diagnosesPdxOff');
  localStorage.removeItem('TC_COLUMNS_V1_diagnosesPdxOn');
  localStorage.removeItem('TC_COLUMNS_V1_inProcedures');
  localStorage.removeItem('TC_COLUMNS_V1_outProcedures');
  localStorage.removeItem('TC_COLUMNS_V1_visitReasons');

  localStorage.removeItem('TC_COLUMNS_SETTINGS_RESIZE_WARNING_V1');
  localStorage.removeItem('TC_COLUMNS_SETTINGS_REORDER_WARNING_V1');
};
