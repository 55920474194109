import { CodeBooksModalOptions } from '../components/TEEModals/codeBooksModalActions';
import { ReferencesModalOptions } from '../components/TEEModals/referencesModalActions';
import { TEEControl } from '../types/tee';
import { CodeGridsColumns } from './columns';
import { ResequenceRequestStatus } from './resequence';

export interface ErrorDetails {
  error?: string;
  stack?: string;
  name?: string;
  description?: string;
  time?: number;
}

export interface ModalState {
  show: boolean;
  ref?: TEEControl;
  windowRef?: kendo.ui.Window;
}

export interface UI {
  confirmDiscardDirtyAdvice: boolean;
  confirmDiscardDirtyAdviceCancelCallback?: () => void;
  confirmDiscardDirtyAdviceOkCallback?: () => void;
  creatingAdvice: boolean;

  initializing: boolean;
  initialized: boolean;
  initializingError: boolean;
  initializingErrorDetail?: string;
  initializingErrorReason?: string;

  deletingAdvice: boolean;
  editingAdvice: boolean;
  loadingAdvice: boolean;
  loadingCodebooks: boolean;
  loadingEncounterTemplates: boolean;
  promptDiscardDirtyAdvice: boolean;
  savingAdvice: boolean;
  searchingAdvice: boolean;
  searchingEncounters: boolean;
  codeBooksModal: CodeBooksModalOptions;
  referencesModal: ReferencesModalOptions;
  isShowingPdxAnalysis: boolean;
  unhandledError?: ErrorDetails | null;
  errors: ErrorDetails[];
  showOnlyApprovedModifiers: boolean;

  loadingEncounter: boolean;
  changingEncounter: boolean;
  processingEncounter: boolean;
  needSaveEncounter: boolean;                             // save encounter as soon as possible
  ignoreWarningsDuringSave: boolean;                      // do not show prevent save dialog for warnings
  ignoreResequenceWarning: boolean;                       // do not show resequence warning during save
  showPreventSaveDialog: boolean;                         // prevent save dialog is shown
  needResequenceEncounterCodes: ResequenceRequestStatus,  // resequence encounter codes as soon as possible
  savingEncounter: boolean;

  isCodeGridDragging: boolean;

  codeGridsColumns: CodeGridsColumns;
  codeGridsShowReorderWarning: boolean;
  codeGridsShowResizeWarning: boolean;

  patientEncounterTab: number;                            // currently opened encounter tab

  concurrencyHeartbeatError: boolean;                        // used for opening an encounter on multiple tabs
  concurrencyUpdateError: boolean;                          // used for opening an encounter on multiple tabs
  concurrencyFacilityVersionError: boolean;
}

// DANGER: if more tabs are added then ResearchPane.tsx should be modified to account for it in edit locator logic
export const TAB_PATIENT_ENCOUNTER = 0;
export const TAB_CODING = 1;
export const TAB_SUPPLEMENTARY = 2;

export const TAB_BASE_ID = "tc-pane";
export const TAB_PATIENT_ENCOUNTER_ID = "pane-patient-encounter";
export const TAB_CODING_ID = "pane-icd-10-coding";
export const TAB_SUPPLEMENTARY_ID = "pane-supplementary";
