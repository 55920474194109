import * as actionTypes from '../../../constants';
import { GridCodeType } from '../../../models';
import { CodeGetDescriptionResponse } from '../../../models/codeGetDescription';
import { BaseCodeRow } from '../../../models/codeGrid';
import { CodeGridsColumns } from '../../../models/columns';
import { FieldSettings, FacilitySettingsState, FacilityPreferences } from '../../../models/facilitySettings';
import { IdDescriptionBase } from '../../../models/patientEncounter';

export interface CodeGridRowEvent {
  dataItem: BaseCodeRow;
  gridCodeType: GridCodeType;
}

export interface CodeGridDragDropRow {
  fromIdx: number;
  toIdx?: number;
  gridCodeType?: GridCodeType;
  dataItemId: string;
  needAutoResequenceCodes?: boolean;
}

export interface CodeGridChange extends CodeGridRowEvent {
  field?: string;
  value?: string;
  classNames?: string[];
  blurToCodebooks?: boolean;
  facilitySettings?: FacilitySettingsState;
  removeRow?: boolean;
  providers?: IdDescriptionBase[];
}

export interface CodeGridCodeBlur extends CodeGridRowEvent {
  value: string;
  blurToCodebooks?: boolean;
  facilitySettings?: FacilitySettingsState;
}

export interface CodeGridKeypress extends CodeGridRowEvent {
  key: string;
  ctrlKey?: boolean;
  shiftKey?: boolean;
  dataIndex?: number;
  field?: string;
  columnsSettings?: CodeGridsColumns;
  fieldSettings?: FieldSettings;
  isShowingPdxAnalysis?: boolean;
  facilityPreferences?: FacilityPreferences;
  inpatientEncounter?: boolean;
}

export interface CodeValidationBegin {
  sender: CodeGridCodeBlur;
}

export interface CodeValidation {
  valid: boolean;
  sender: CodeGridCodeBlur;
  result?: CodeGetDescriptionResponse;
}

export const codeGridChangeAction = (c: CodeGridChange) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_CHANGE,
  });
};

export const codeGridTryBlurCodeFieldAction = (c: CodeGridCodeBlur) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_TRY_BLUR_CODE_FIELD,
  });
};

export const codeGridBlurCodeFieldAction = (c: CodeGridCodeBlur) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_BLUR_CODE_FIELD,
  });
};

export const codeGridTryKeypressAction = (c: CodeGridKeypress) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_TRY_KEYPRESS,
  });
};

export const codeGridKeypressAction = (c: CodeGridKeypress) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_KEYPRESS,
  });
};

export const codeGridCodeValidationBeginAction = (c: CodeValidationBegin) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_CODE_VALIDATION_BEGIN,
  });
};

export const codeGridCodeValidationCompleteAction = (c: CodeValidation) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_CODE_VALIDATION_COMPLETE,
  });
};

export const codeGridEnterEditAction = (c: CodeGridChange) => ({
  payload: c,
  type: actionTypes.CODE_GRID_TRY_ENTER_EDIT,
});

export const codeGridTryExitEditAction = (dataItem: BaseCodeRow, isUsual: boolean, tabFromLastColumn: boolean) => (dispatch) => {
  // handle values updating
  dispatch({
    payload: dataItem,
    type: actionTypes.CODE_GRID_BLUR_FIELD,
  });

  // we use this event for the tab navigation
  if (!isUsual) {
    dispatch({
      payload: tabFromLastColumn,
      type: actionTypes.CODE_GRID_TRY_EXIT_EDIT,
    });
  }
};

export interface CodeGridExitEditInfo {
  tabFromLastColumn: boolean;
  columnsSettings: CodeGridsColumns;
  fieldSettings: FieldSettings;
  isShowingPdxAnalysis?: boolean;
}

export const codeGridExitEditAction = (payload: CodeGridExitEditInfo) => (dispatch) => {
  dispatch({
    payload,
    type: actionTypes.CODE_GRID_EXIT_EDIT,
  });
};

export const codeGridCancelAction = (c: CodeGridChange) => ({
  payload: c,
  type: actionTypes.CODE_GRID_CANCEL_EDIT,
});

export const codeGridRemoveAction = (c: CodeGridChange) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_REMOVE,
  });
};

export const codeGridMoveToPdxAction = (c: CodeGridRowEvent) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_MOVE_TO_PDX,
  });
};

export const codeGridDragDropRowAction = (c: CodeGridDragDropRow) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODE_GRID_DRAG_DROP_ROW,
  });
};

export const navigateFromDescriptionAction = (back: boolean, fieldSettings: FieldSettings, isShowingPdxAnalysis?: boolean) => (dispatch) => {
  dispatch({
    payload: { back, isShowingPdxAnalysis, fieldSettings },
    type: actionTypes.NAVIGATE_FROM_ADMIT_DX_DESCRIPTION,
  });
};

export const descriptionBlurAction = (value: string) => (dispatch) => {
  dispatch({
    payload: value,
    type: actionTypes.ADMIT_DX_DESCRIPTION_BLUR,
  });
};

export const setShowOnlyApprovedModifiersAction = (checked: boolean) => (dispatch) => {
  dispatch({
    payload: checked,
    type: actionTypes.CHANGE_SHOW_ONLY_APPROVED_MODIFIERS,
  });
};

export const clearGridAction = (gridCodeType: GridCodeType) => (dispatch) => {
  dispatch({
    payload: gridCodeType,
    type: actionTypes.CLEAR_GRID,
  });
};

export const setAllPOAToValueAction = (value) => (dispatch) => {
  dispatch({
    payload: value,
    type: actionTypes.SET_ALL_POA_TO_VALUE,
  });
};

export const updateExemptPOAValuesAction = () => (dispatch) => {
  dispatch({
    payload: '',
    type: actionTypes.UPDATE_EXEMPT_POA_VALUES,
  });
};

export const insertRowBeforeAction = (dataItem: BaseCodeRow) => (dispatch) => {
  dispatch({
    payload: dataItem,
    type: actionTypes.INSERT_ROW_BEFORE,
  });
};


export const insertRowAfterAction = (dataItem: BaseCodeRow) => (dispatch) => {
  dispatch({
    payload: dataItem,
    type: actionTypes.INSERT_ROW_AFTER,
  });
};

export const clearEmptyRowsInGridAction = (dataItem: BaseCodeRow) => (dispatch) => {
  dispatch({
    payload: dataItem,
    type: actionTypes.CLEAR_EMPTY_ROWS_IN_GRID,
  });
};
