/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterDescriptor, CompositeFilterDescriptor, SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { FacilityEntity } from "./models/facilities";

export const isValidFacility = (facility: FacilityEntity) => {
  if (!facility.Id) {
    return 'Invalid Facility Id';
  }

  if (!facility.CcnType) {
    return 'Invalid Facility Type';
  }

  if (!facility.CMSCertificationNumber) {
    return 'Invalid CCN';
  }

  if (!facility.Description) {
    return 'Invalid Facility Description';
  }

  return '';
}

export const isValidFacilityAllErrors = (facility: FacilityEntity) => {
  const errors: string[] = [];

  if (!facility.Id) {
    errors.push('Invalid Facility Id');
  }

  if (!facility.CcnType) {
    errors.push('Invalid Facility Type');
  }

  if (!facility.CMSCertificationNumber) {
    errors.push('Invalid CCN');
  }

  if (!facility.Description) {
    errors.push('Invalid Facility Description');
  }

  return errors;
}

// we must filter MAC by both Mac Id and Mac Name using compose filter
export const prepareMACFilter =  (filter: FilterDescriptor) => {

  const defaultFilter = {
    ... filter,
    operator: 'startswith'
  }
  const idFilter = {
    ...defaultFilter,
    field: 'Mac',
  }

  const stateFilter = {
    ...defaultFilter,
    field: 'State',
  }

  const composeFilter: CompositeFilterDescriptor = {
    logic: 'or',
    filters: [
      defaultFilter,
      idFilter,
      stateFilter,
    ]
  }

  return composeFilter;
}

// check if string consists from the digits
const IsNumberString = (value: string) => {
  return /^\d+$/.test(value);
}

// sort MAC field in special order: empty, numbers, other strings
export const specialMACSorting = (data: any[], sort: SortDescriptor[]) => {
  const numbers: any[] = [];
  const notNumbers: any[] = [];
  const emptyItems: any[] = [];

  for (let ind = 0, len = data.length; ind < len; ind++) {
    const { Mac } = data[ind];

    if (!Mac && Mac !== '0' && Mac !== 0) {
      emptyItems.push(data[ind]);
    } else if (IsNumberString(Mac)) {
      numbers.push({ ...data[ind], Mac: Number(Mac) });
    } else {
      notNumbers.push(data[ind]);
    }
  }

  const sortedNumbers = orderBy(numbers, sort).map((item) => { return { ...item, Mac: item.Mac.toString() } });
  const sortedNotNumbers = orderBy(notNumbers, sort);
  const sortedEmptyItems = orderBy(emptyItems, sort);


  const result = sort[0].dir === 'asc' ? [...sortedEmptyItems, ...sortedNumbers, ...sortedNotNumbers] : [...sortedNotNumbers, ...sortedNumbers, ...sortedEmptyItems];

  return result;
}

// use special sorting for Mac field. Use standard sorting for other fields
export const sortMACData = (data: any[], sort: SortDescriptor[]) => {
  if (sort.length && sort[0].field === 'Mac') {
    return specialMACSorting(data, sort);
  }

  return orderBy(data, sort);
}
