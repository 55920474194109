import * as actionTypes from '../constants';
import { EncounterSnapshotEntity, EncounterSnapshotState, ErrorDetails } from '../models';

const createEmptySnapshot = (): EncounterSnapshotState => ({
  snapshot: null,
  fetching: false,
  fetched: false,
});

export const snapshotReducer = (state = createEmptySnapshot(), action) => {
  switch (action.type) {
    case actionTypes.SNAPSHOT_ENCOUNTER_BEGIN:
      return handleBegin(state, action.payload);
    case actionTypes.SNAPSHOT_ENCOUNTER_COMPLETED:
      return handleCompleted(state, action.payload);
    case actionTypes.SNAPSHOT_ENCOUNTER_ERROR:
      return handleError(state, action.payload);
    case actionTypes.UPDATE_ENCOUNTER_FIELD:
    case actionTypes.UPDATE_ENCOUNTER_FIELDS:
    case actionTypes.UPDATE_ENCOUNTER_CHOICE_LISTS:
      return createEmptySnapshot();
    default:
      return state;
  }
};

const handleBegin = (state: EncounterSnapshotState, payload: string) => {
  return {
    snapshot: null,
    fetching: true,
    fetched: false,
  };
};

const handleCompleted = (state: EncounterSnapshotState, payload: EncounterSnapshotEntity) => {
  return {
    snapshot: payload,
    fetching: false,
    fetched: true
  };
};

const handleError = (state: EncounterSnapshotState, payload: ErrorDetails[]) => {
  return {
    snapshot: null,
    fetching: false,
    fetched: false,
  };
};
