import './HotKeysWrapper.less';

import * as React from 'react';

export class HotKeysWrapper extends React.PureComponent {
  render() {

    const {...hotKeys} = this.props;

    return (
      <div className="hotkey-wrapper" tabIndex={-1} {...{...hotKeys}}>
        {this.props.children}
      </div>
    )
  }
}
