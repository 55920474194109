import { CurrentUserEntity, CurrentUserEntityRoles, CurrentUserServiceResponse, UserRoleMap } from '../../models';
import { ClientMode } from '../../models/system';
import { get } from '../../utils/net';

const baseURL = `${process.env.REACT_APP_API_PATH}/api/currentUser`;

const getCurrentUser = (options: { includeRoles?: boolean }): Promise<CurrentUserEntity> => {
  const url = `${baseURL}/Info?includeRoles=${(options && options.includeRoles) || false}`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return mapCurrentUser(result.body);
  });
};

const mapCurrentUser = (payload: CurrentUserServiceResponse): CurrentUserEntity => {
  let clientMode = ClientMode.Standard;
  if (payload.ClientMode !== null && ClientMode[payload.ClientMode] !== undefined) {
    clientMode = ClientMode[payload.ClientMode];
  }
  const mapped: CurrentUserEntity = {
    isSystemAdmin: payload.IsSystemAdmin,
    highestRole: UserRoleMap[payload.HighestRole],
    clientMode: clientMode,
  };
  if (payload.Roles) {
    mapped.roles = payload.Roles.map((r) => {
      const role: CurrentUserEntityRoles = { facilityId: r.FacilityId, role: UserRoleMap[r.Role] };
      return role;
    });
  }
  return mapped;
};

export const currentUserAPI = {
  getCurrentUser,
};
