import * as actionTypes from '../constants';
import { EncounterEntity, ErrorDetails } from '../models';
import { SearchEncounterFilter, SearchEncounters } from '../models/searchEncounterFilter';

const createEmptySearchEncounterFilterState = (): SearchEncounterFilter => ({
  accountNumber: '',
  admitDateFrom: '',
  admitDateTo: '',
  dischargeDateFrom: '',
  dischargeDateTo: '',
  firstName: '',
  lastName: '',
  medicalRecordNumber: '',
  recordStatus: ''
});

const createEmptySearchEncountersState = (): SearchEncounters => ({
  encounters: [],
  errorText: '',
  filter: createEmptySearchEncounterFilterState(),
  searched: false,
  searching: false,
  selectedEncounterId: ''
});

export const searchEncountersReducer = (state = createEmptySearchEncountersState(), action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ENCOUNTER_FILTER_CLEAR:
      return handleClearSearchEncountersState();
    case actionTypes.SEARCH_ENCOUNTER_FILTER_CHANGE_FIELD:
      return handleUpdateSearchEncounterFilterField(state, action.payload);
    case actionTypes.SEARCH_ENCOUNTER_FILTER_SEARCH_BEGIN:
      return handleEncountersSearchingBegin(state);
    case actionTypes.SEARCH_ENCOUNTER_FILTER_SEARCH_COMPLETED:
      return handleEncountersSearchingCompleted(state, action.payload);
    case actionTypes.SEARCH_ENCOUNTER_FILTER_SEARCH_ABORTED:
      return handleEncountersSearchingAborted(state);
    case actionTypes.SEARCH_ENCOUNTER_FILTER_SEARCH_ERROR:
      return handleEncountersSearchingError(state, action.payload);
    case actionTypes.SEARCH_ENCOUNTER_FILTER_SELECT_ROW:
      return handleSelectFoundEncountersRow(state, action.payload);

    default:
      return state;
  }
};

const handleClearSearchEncountersState = (): SearchEncounters => {
  return createEmptySearchEncountersState();
};

const handleUpdateSearchEncounterFilterField = (state: SearchEncounters, payload: SearchEncounterFilter): SearchEncounters => {
  const newState = {
    ...state,
    filter: { ...payload }
  };

  return newState;
};

const handleEncountersSearchingBegin = (state: SearchEncounters): SearchEncounters => {
  const newState = {
    ...state,
    encounters: [],
    errorText: '',
    searched: false,
    searching: true,
    selectedEncounterId: ''
  };

  return newState;
};

const handleEncountersSearchingCompleted = (state: SearchEncounters, payload: EncounterEntity[]): SearchEncounters => {
  const newState = {
    ...state,
    encounters: payload,
    errorText: '',
    searched: true,
    searching: false,
    selectedEncounterId: ''
  };

  return newState;
};

const handleEncountersSearchingError = (state: SearchEncounters, errors: ErrorDetails[]): SearchEncounters => {
  const newState = {
    ...state,
    encounters: [],
    errorText: errors && errors.length > 0 ? errors[0].error : '',
    searched: false,
    searching: false,
    selectedEncounterId: ''
  };

  return newState;
};

const handleSelectFoundEncountersRow = (state: SearchEncounters, id): SearchEncounters => {
  const newState = {
    ...state,
    selectedEncounterId: id
  };

  return newState;
};

const handleEncountersSearchingAborted = (state: SearchEncounters): SearchEncounters => {
  const newState = {
    ...state,
  };

  return newState;
};
