import './AppWrapper.less';

import * as History from 'history';
import * as React from 'react';
import { match } from 'react-router-dom';
import { EncounterState, ErrorDetails, RecoveredEncountersState, UserState } from '../../models';
import { ChoiceListsState } from '../../models/patientEncounter';
import { NoticeList } from './components/NoticeList';
import { CreateNewEncounter } from './components/CreateNewEncounter';
import { DebugContainer } from '../Debug/DebugContainer';
import { B2CState } from '../../models/b2c';
import { Drawer } from '../Drawer/Drawer';
import NavigateExternal from '../NavigateExternal/NavigateExternal';
import { NotificationDialogContainer } from '../Dialog/NotificationDialogContainer';
import LogoutDialog from './components/LogoutDialog';
import TopNavigation from './components/TopNavigation';
import Ripple from '../Ripple/Ripple';
import { authenticationParametersChangePassword, getAccountIdentifier, pca } from '../../authProvider';
import { InitializingContainer } from '..';
import { SystemAnnouncementsState } from '../../models/systemAnnouncements';

interface AppWrapperProps {
  // from container
  errors: ErrorDetails[];
  choiceLists: ChoiceListsState;
  user: UserState
  b2c: B2CState;
  hasActiveRequest: boolean;
  clearApplication: () => void;
  logoutUser: (accountIdentifier: string, unsavedChanges?: boolean) => void;
  clearErrors: () => void;
  buildNewEncounterChoiceLists: (choiceLists: ChoiceListsState, facility: string) => void;
  saveUserPreference: (preference: string, value: any) => void;
  getFacilitiesChoiceList: () => void;
  // props
  handleCreateNewEncounter?: (choiceLists: ChoiceListsState, mrn?: string, facility?: string, encounterType?: string) => void;
  // page can provide this callback to check about not saved changes and show warning
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  // page can provide Promise to close something before logout
  clearPageBeforeLogoutPromise?: () => Promise<boolean>;
  isModal?: boolean;
  isStandalone?: boolean;
  isEncountersPage?: boolean;
  skipInit?: boolean;
  match?: match<any>;
  // manual props
  isErrorMode?: boolean;
  encounter: EncounterState;
  announcements: SystemAnnouncementsState;
  dismissAnnouncement: (id: string) => void;
  recoveredEncounters: RecoveredEncountersState;
  refreshEncounterList: () => void;
}

enum ActiveDrawerContent {
  NONE,
  DEBUG,
  ERRORS,
  CREATE,
}

enum DrawerWidth {
  STANDARD = 600,
  NARROW = 300,
  WIDE = 900,
}

interface AppWrapperState {
  showDrawer: boolean;
  activeDrawerContent: ActiveDrawerContent,
  drawerWidth: number;
  showLogoutDialog: boolean;
}

export class AppWrapper extends React.Component<AppWrapperProps, AppWrapperState> {
  constructor(props: AppWrapperProps) {
    super(props);
    this.state = {
      showDrawer: false,
      activeDrawerContent: ActiveDrawerContent.ERRORS,
      drawerWidth: DrawerWidth.NARROW,
      showLogoutDialog: false,
    };
  }

  render() {
    const isErrorMode = this.props.isErrorMode || false;
    const errorCount = this.props.errors && this.props.errors.length > 0 ? this.props.errors.length : undefined;
    return (
      // References & CodeBooks do not need the bloat provided by src\actions\initialize.ts
      // so use new skipInit
      <InitializingContainer skipInit={this.props.skipInit || this.props.isModal || this.props.isStandalone}>
        <Ripple disabled={this.props.user.preferences.AnimationDisabled}>
          <NavigateExternal saveUserPreference={this.props.saveUserPreference} hideExternalLinkWarning={this.props.user.preferences.HideExternalLinkWarning}>
            <NotificationDialogContainer />
            <LogoutDialog show={this.state.showLogoutDialog} onLogoutNoClick={this.onLogoutNoClick} onLogoutYesClick={this.onLogoutYesClick} />
            <Drawer width={this.state.drawerWidth} expanded={this.state.showDrawer} content={this.getDrawerContents} onOverlayClick={this.onCloseDrawer} />
            <div className={`app${this.props.isStandalone ? ' standalone' : ''}`} >
              <TopNavigation
                announcements={this.props.announcements}
                user={this.props.user}
                b2c={this.props.b2c}
                onChangePasswordClick={this.handleChangePassword}
                onCreateNewEncountersClick={this.onCreateNewEncountersClick}
                onLogoutClick={this.handleLogout}
                onShowErrorsClick={this.onShowErrorsClick}
                errorCount={errorCount}
                hasChangesCallback={this.props.hasChangesCallback}
                isModal={this.props.isModal}
                isStandalone={this.props.isStandalone}
                onShowDebugClick={this.onShowDebugClick}
                hasActiveRequest={this.props.hasActiveRequest}
                isErrorMode={isErrorMode}
                onDismissAnnouncement={this.props.dismissAnnouncement}
                encounterOpened={this.props.encounter.inProgress.id !== null && this.props.encounter.inProgress.id !== '0'}
                recoveredEncounters={this.props.recoveredEncounters.encounters}
                refreshEncounterList={this.props.refreshEncounterList}
                encounter={this.props.encounter}
              />
              <div className="contents-wrapper">{this.props.children}</div>
            </div>
          </NavigateExternal>
        </Ripple>
      </InitializingContainer >
    );
  }

  private getDrawerContents = () => {
    switch (this.state.activeDrawerContent) {
      case ActiveDrawerContent.CREATE:
        return {
          headingText: "New Encounter",
          id: 'new-encounter-drawer',
          content: (
            <CreateNewEncounter
              choiceLists={this.props.choiceLists}
              handleCreateNewEncounter={this.createNewEncounterClick}
              hasChangesCallback={this.props.hasChangesCallback}
              isEncountersPage={this.props.isEncountersPage}
              buildNewEncounterChoiceLists={this.props.buildNewEncounterChoiceLists}
              onCloseNewEncounter={this.onCloseNewEncounter}
              showCreateDrawer
              getFacilitiesChoiceList={this.props.getFacilitiesChoiceList}
              encounterOpened={this.props.encounter.inProgress.id !== null && this.props.encounter.inProgress.id !== '0'}
            />
          )
        }
      case ActiveDrawerContent.DEBUG:
        return {
          headingText: "Debug",
          content: <DebugContainer />
        };
      case ActiveDrawerContent.ERRORS:
        return {
          headingText: "Alerts",
          content: <NoticeList notices={this.props.errors} onClickClear={this.onClearErrorsClick} />
        }
      default:
      case ActiveDrawerContent.NONE:
        break;
    }
    return null;
  };

  private onShowErrorsClick = () => {
    this.setState({
      showDrawer: true,
      activeDrawerContent: ActiveDrawerContent.ERRORS,
      drawerWidth: DrawerWidth.STANDARD,
    });
  };

  private onShowDebugClick = () => {
    this.setState({
      showDrawer: true,
      activeDrawerContent: ActiveDrawerContent.DEBUG,
      drawerWidth: DrawerWidth.STANDARD,
    });
  };

  private onCloseDrawer = () => {
    this.setState({
      showDrawer: false,
      activeDrawerContent: ActiveDrawerContent.NONE,
      drawerWidth: DrawerWidth.NARROW,
    });
  };

  private onClearErrorsClick = () => {
    this.setState(
      {
        showDrawer: false,
      },
      () => {
        this.props.clearErrors();
      }
    );
  };

  // logout button clicked
  private handleLogout = () => {
    if (!this.props.hasChangesCallback) {
      this.performShowLogoutWarning();
      return;
    }

    this.props.hasChangesCallback('/logout', this.performShowLogoutWarning);
  };

  // show logout warning
  private performShowLogoutWarning = () => {
    this.setState({ showLogoutDialog: true });
  }

  // user press Yes on logout dialog:
  //    force active page to perform close action if it has something to be closed
  //    logout user after this
  private onLogoutYesClick = () => {
    localStorage.setItem("showCopyrightModal", "yes");

    // no need to close active page -> logout
    if (!this.props.clearPageBeforeLogoutPromise) {
      this.logoutUser();
      return;
    }

    const closePromise = this.props.clearPageBeforeLogoutPromise();

    this.setState({ showLogoutDialog: false }, () => {
      return closePromise.then(() => {
        return this.logoutUser();
      })
    });
  };

  // real logout
  private logoutUser = () => {
    this.props.clearApplication();
    this.setState({ showLogoutDialog: false });

    if (!window.useAzureADAuth) {
      return;
    }

    const accountIdentifier = getAccountIdentifier();

    // logout current user from all tabs
    this.props.logoutUser(accountIdentifier);
  };

  private onLogoutNoClick = () => {
    this.setState({ showLogoutDialog: false });
  };

  // change password button clicked
  private handleChangePassword = () => {
    if (!this.props.hasChangesCallback) {
      this.performChangePassword();
      return;
    }

    this.props.hasChangesCallback('/logout', this.performChangePassword);
  }

  // prepare to relogin to the change password page:
  //    force active page to perform close action if it has something to be closed
  //    show change password page after this
  private performChangePassword = () => {
    // no need to close active page -> change password
    if (!this.props.clearPageBeforeLogoutPromise) {
      this.changePassword();
      return;
    }

    const closePromise = this.props.clearPageBeforeLogoutPromise();

    // eslint-disable-next-line consistent-return
    return closePromise.then(() => {
      return this.changePassword();
    })
  };

  // redirect to the change password page
  private changePassword = () => {
    // encounter opened, use popup to change password
    if(this.props.encounter.inProgress.id !== null && this.props.encounter.inProgress.id !== '0') {
      return pca.loginPopup(authenticationParametersChangePassword)
        .then(() => {
          return '';
        })
    }

    // no encounter opened, redirect using current page
    return pca.loginRedirect(authenticationParametersChangePassword)
      .then(() => {
        return '';
      })

  }

  private onCreateNewEncountersClick = () => {
    this.setState({
      showDrawer: true,
      activeDrawerContent: ActiveDrawerContent.CREATE,
      drawerWidth: DrawerWidth.NARROW,
    });
  };

  private onCloseNewEncounter = () => {
    this.setState({
      showDrawer: false,
      activeDrawerContent: ActiveDrawerContent.CREATE,
    });
  };

  private createNewEncounterClick = (choiceLists: ChoiceListsState, mrn?: string, facility?: string, encounterType?: string) => {
    if (this.props.handleCreateNewEncounter) {
      this.props.handleCreateNewEncounter(choiceLists, mrn, facility, encounterType);
    }
  }

}

