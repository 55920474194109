import * as actionTypes from '../constants';
import { LicensePackage, LicenseState } from '../models/license';

const createEmptyLicenseState = (isLoading?: boolean): LicenseState => ({
  loading: typeof isLoading !== 'undefined' ? isLoading : false,
  error: '',
  packages: [],
});

export const licenseReducer = (state = createEmptyLicenseState(), action) => {
  switch (action.type) {
    case actionTypes.AVAILABLE_LICENSE_PACKAGES_BEGIN:
      return handleLicensePackageBegin();
    case actionTypes.AVAILABLE_LICENSE_PACKAGES_COMPLETED:
      return handleLicensePackageCompleted(state, action.payload);
    case actionTypes.AVAILABLE_LICENSE_PACKAGES_ERROR:
      return handleLicensePackageError(state, action.payload);
    default:
      return state;
  }
};

const handleLicensePackageBegin = (): LicenseState => {
  return createEmptyLicenseState(true);
}

const handleLicensePackageCompleted = (state: LicenseState, payload: LicensePackage[]): LicenseState => {
  return {
    ...state,
    error: '',
    loading: false,
    packages: payload,
  };
}

const handleLicensePackageError = (state: LicenseState, payload: any): LicenseState => {
  return {
    ...state,
    error: 'There was an error loading license details',
    loading: false,
    packages: [],
  }
}
