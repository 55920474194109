import './Card.less';

import * as React from 'react';
import { CardActions as KCardActions } from '@progress/kendo-react-layout';
// FIXME: once this export is fixed
import { CardActionsProps as KCardActionsProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/CardActionsProps';

export const CardActions = function render(props: KCardActionsProps) {
  const { className, ...otherProps } = props;
  return <KCardActions
    className={`tc-card-actions ${className}`}
    {...otherProps}
  />;
}
