import * as moment from 'moment';
import { addErrorsAction } from '../../../actions/errors';
import * as actionTypes from '../../../constants';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';
import { systemPreferencesAPI } from '../../../services/systemPreferences';
import { OrganizationObject, SyncSettingsStatus, TestOrganizationSyncStatus } from '../models/systemPreferences';
import { checkForConcurrencyError } from '../../../utils/checks';
import { concurrencyFacilityVersionError } from '../../FacilityPreferences/actions/preferencesActions';

export const getOrganizationInfoAction = () => (dispatch) => {
  dispatch(getOrganizationInfoActionBegin());

  return systemPreferencesAPI
    .getCurrentOrganization()
    .then((organization) => {
      // If test org exist check if test org settings sync job is in progress
      if(organization.RelatedOrganizationId !== null && !organization.IsTestOrganization) {
        dispatch(checkSyncTestOrganizationSettingsAction(true));
      }
      dispatch(getOrganizationInfoCompleted(organization));
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Error Retrieving Organization Info');
      dispatch(addErrorsAction(errors));
      dispatch(getOrganizationInfoError(reason));
    });
};

export const getOrganizationNameAction = () => (dispatch) => {
  dispatch(getOrganizationInfoActionBegin());

  return systemPreferencesAPI
    .getCurrentOrganization()
    .then((organization) => {
      dispatch(getCurrentUserOrganizationComplete(organization || ''));
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Error Retrieving Organization Name');
      dispatch(addErrorsAction(errors));
      dispatch(getOrganizationInfoError(reason));
    });
};

export const updateCurrentOrganizationNameAction = (organizationData: OrganizationObject) => (dispatch) => {
  dispatch(setOrganizationInfoBegin());

  const error = 'Cannot Update Organization Name';
  // const error = `Cannot Update Organization Name: ${ organizationData.Name}`;

  const newOrganizationData = {
    Name: organizationData.Name,
    IsSuspended: organizationData.IsSuspended,
    Type: organizationData.Type,
    UseTest3MGrouping: organizationData.UseTest3MGrouping,
    ConcurrencyVersion: organizationData.ConcurrencyVersion,
  }

  return systemPreferencesAPI
    .setCurrentOrganization(newOrganizationData)
    .then((result) => {
      dispatch(setOrganizationInfoCompleted(result));
      dispatch(updateUserOrganizationAction(result))
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, error);
      if (checkForConcurrencyError(errors)) {
        dispatch(concurrencyFacilityVersionError(errors));
        dispatch(setOrganizationInfoError(reason));
      } else {
        dispatch(addErrorsAction(errors));
        dispatch(setOrganizationInfoError(reason));
      }
    });
};

export const createTestOrganizationAction = (organizationData: OrganizationObject) => (dispatch) => {
  dispatch(createTestOrganizationBegin());

  const error = 'Failed Creating Test Organization';

  return systemPreferencesAPI
    .createTestOrganization(organizationData)
    .then((result) => {
      dispatch(createTestOrganizationCompleted(result));
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, error);
      dispatch(addErrorsAction(errors));
      dispatch(createTestOrganizationError(reason));
    });
}

export const syncTestOrganizationSettingsAction = (organizationData: OrganizationObject) => (dispatch) => {
  dispatch(syncTestOrganizationSettingsBegin());

  const error = 'Failed Syncing Test Organization Settings';

  return systemPreferencesAPI
    .syncTestOrganizationSettings(organizationData)
    .then((result) => {
      if(result.status === 204) {
        // successful call to start sync job on the server
        dispatch(syncTestOrganizationSettingsStatus(true));
        dispatch(checkSyncTestOrganizationSettingsAction());
      }
    })
    .catch((reason) => {
      // Sync job already in progress
      if(reason.status === 409) {
        dispatch(syncTestOrganizationSettingsStatus(true));
        dispatch(checkSyncTestOrganizationSettingsAction(true));
      } else {
        const errors = parseServiceErrors(reason, error);
        dispatch(syncTestOrganizationSettingsStatus(false));
        dispatch(addErrorsAction(errors));
        dispatch(syncTestOrganizationSettingsError(SyncSettingsStatus.Failed));
      }
    });
}

export const checkSyncTestOrganizationSettingsAction = (initialCheck = false) => (dispatch) => {
  const error = 'Failed Syncing Test Organization Settings';

  if(initialCheck) {
    dispatch(syncTestOrganizationSettingsBegin());
  }

  return systemPreferencesAPI
    .checkSyncTestOrganizationSettings()
    .then((result) => {
      // sync job status is done
      if(result.Status === 'Completed') {
        dispatch(syncTestOrganizationSettingsStatus(false));
        dispatch(syncTestOrganizationSettingsCompleted(!initialCheck ? result : undefined));
      }
      else if( result.Status === 'Error' || result.Error !== null){
        dispatch(syncTestOrganizationSettingsStatus(false));
        dispatch(syncTestOrganizationSettingsError(SyncSettingsStatus.Failed));
        const errors = [{ error, description: result.Error ? result.Error : 'Unknown Error', time: moment.now() }]
        dispatch(addErrorsAction(errors));
      } else {
        dispatch(syncTestOrganizationSettingsStatus(true));
      }
    })
    .catch((reason) => {
      // 404 error when checking the status on a test org that has never been sync'ed before
      if(reason.status === 404) {
        dispatch(syncTestOrganizationSettingsCompleted());
      } else {
        const errors = parseServiceErrors(reason, error);
        dispatch(addErrorsAction(errors));
        dispatch(syncTestOrganizationSettingsError(SyncSettingsStatus.Failed));
      }
    });
}

export const syncTestOrganizationSettingsBegin = () => ({
  type: actionTypes.SYNC_TEST_ORGANIZATION_SETTINGS_BEGIN,
});

export const syncTestOrganizationSettingsStatus = (inProgress: boolean) => ({
  payload: inProgress,
  type: actionTypes.SYNC_TEST_ORGANIZATION_SETTINGS_STATUS,
});

export const syncTestOrganizationSettingsCompleted = (value?: TestOrganizationSyncStatus) => ({
  payload: value,
  type: actionTypes.SYNC_TEST_ORGANIZATION_SETTINGS_COMPLETED,
});

export const syncTestOrganizationSettingsError = (error: string) => ({
  payload: error,
  type: actionTypes.SYNC_TEST_ORGANIZATION_SETTINGS_ERROR,
});

export const createTestOrganizationBegin = () => ({
  type: actionTypes.ENABLE_TEST_ORGANIZATION_BEGIN,
});

export const createTestOrganizationCompleted = (value: OrganizationObject) => ({
  payload: value,
  type: actionTypes.ENABLE_TEST_ORGANIZATION_COMPLETED,
});

export const createTestOrganizationError = (error: string) => ({
  payload: error,
  type: actionTypes.GET_ORGANIZATION_INFO_ERROR,
});

export const getOrganizationInfoActionBegin = () => ({
  type: actionTypes.GET_ORGANIZATION_INFO_BEGIN,
});

export const getOrganizationInfoCompleted = (value: OrganizationObject) => ({
  payload: value,
  type: actionTypes.GET_ORGANIZATION_INFO_COMPLETED,
});

export const getOrganizationInfoError = (error: string) => ({
  payload: error,
  type: actionTypes.GET_ORGANIZATION_INFO_ERROR,
});

const setOrganizationInfoBegin = () => ({
  payload: '',
  type: actionTypes.SET_ORGANIZATION_INFO_BEGIN,
});

const setOrganizationInfoCompleted = (value) => ({
  payload: value,
  type: actionTypes.SET_ORGANIZATION_INFO_COMPLETED,
});

const setOrganizationInfoError = (error: Error) => ({
  payload: error,
  type: actionTypes.SET_ORGANIZATION_INFO_ERROR,
});

export const getCurrentUserOrganizationComplete = (organization: OrganizationObject) => ({
  payload: organization,
  type: actionTypes.GET_CURRENT_USER_ORGANIZATION_COMPLETED,
});

const updateUserOrganizationAction = (organization: OrganizationObject) => (dispatch) => {
  dispatch(getCurrentUserOrganizationComplete(organization));
};
