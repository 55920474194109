import * as React from 'react';
import { PreloadableComponent } from 'react-lazy-with-preload';

export interface PreloadableProps {
  preloadWith?: PreloadableComponent<typeof React.Component>[];
  preloadOption?: 'onmount' | 'manual';
}

export const withPreloadable = <P extends object, S extends object>(Component: typeof React.Component) =>
  class WithPreloadable extends React.Component<P & PreloadableProps, S> {
    render() {
      return (
        <Component
          {...this.props as P}
        />
      )
    }

    componentDidMount() {
      if (this.props?.preloadOption === 'onmount') {
        this.preload();
      }
    }

    public preload() {
      this.props.preloadWith?.forEach((p) => {
        p.preload();
      });
    }
  };
