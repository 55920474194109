import * as actionTypes from '../constants';
import { SystemAnnouncement, SystemAnnouncementsState } from '../models/systemAnnouncements';

const createEmptySystemAnnouncements = (): SystemAnnouncementsState => {
  return {
    updatedTimestamp: undefined,
    items: [],
  };
};

export const systemAnnouncementsReducer = (state = createEmptySystemAnnouncements(), action) => {
  switch (action.type) {
    case actionTypes.SYSTEM_ANNOUNCEMENTS_COMPLETE:
      return handleGetAnnouncementsCompleted(state, action.payload);
    case actionTypes.SYSTEM_ANNOUNCEMENT_DISMISSED:
      return handleDismissAnnouncement(state, action.payload);
    default:
      return state;
  }
};

const handleGetAnnouncementsCompleted = (
  state: SystemAnnouncementsState,
  items: SystemAnnouncement[]
): SystemAnnouncementsState => {
  const dismissedStorage = localStorage.getItem('dismissed-announcements') || '[]';
  const dismissed: string[] = JSON.parse(dismissedStorage);
  const filteredItems = items.filter((i) => !dismissed.includes(i.Id));
  const newState: SystemAnnouncementsState = {
    ...state,
    updatedTimestamp: Date.now(),
    items: filteredItems,
  };

  return newState;
};

const handleDismissAnnouncement = (
  state: SystemAnnouncementsState,
  id: string,
): SystemAnnouncementsState => {
  const dismissedStorage = localStorage.getItem('dismissed-announcements') || '[]';
  const dismissed: string[] = JSON.parse(dismissedStorage);
  dismissed.push(id);
  localStorage.setItem('dismissed-announcements', JSON.stringify(dismissed));
  const newState: SystemAnnouncementsState = {
    ...state,
    updatedTimestamp: Date.now(),
    items: state.items.filter((item) => item.Id !== id),
  };

  return newState;
};
