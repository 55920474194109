import './UnauthorizedPage.less';

import * as React from 'react';
import { AppWrapperContainer } from '../AppWrapper/AppWrapperContainer';
import Page from '../Page/Page';
import PageHeader from '../PageHeader/PageHeader';
import PageContent from '../PageContent/PageContent';

export const UnauthorizedPage = function render() {
  return (
    <AppWrapperContainer>
      <Page id="unauthorized">
        <PageHeader>Unauthorized</PageHeader>
        <PageContent>
          <div>
            <p>You do not have permission for this area or item.</p>
            <p>Please try again or contact your administrator.</p>
          </div>
        </PageContent>
      </Page>
    </AppWrapperContainer>
  );
}

export default UnauthorizedPage;
