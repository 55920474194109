import * as moment from 'moment';
import * as actionTypes from '../constants';
import { AdviceEntity, AdviceSearchEntity, AdviceSearchResultState } from '../models';

const createEmptySearchResult = (): AdviceSearchResultState => ({
  deleted: [],
  results: {
    advice: [],
    count: 0,
    searchTotalCount: 0,
    sortBy: '',
    timestamp: moment().unix(),
  }
});

export const adviceSearchResultReducer = (state = createEmptySearchResult(), action) => {
  switch (action.type) {
    case actionTypes.CCA_SEARCH_ADVICE_COMPLETED:
      return handleSearchAdviceCompleted(state, action.payload);
    case actionTypes.CCA_DELETE_ADVICE_COMPLETED:
      return handleDeleteAdviceCompleted(state, action.payload);
    default:
      return state;
  }
};

const handleDeleteAdviceCompleted = (state: AdviceSearchResultState, payload: AdviceEntity) => {
  const deleted = state.deleted.concat([payload.id]);
  return {
    ...state,
    deleted
  };
};

const handleSearchAdviceCompleted = (state: AdviceSearchResultState, payload: AdviceSearchEntity) => {
  return {
    ...state,
    deleted: [],
    results: payload,
  };
};
