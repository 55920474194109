import { addErrorsAction } from '../../../actions/errors';
import * as actionTypes from '../../../constants';
import { IdDescriptionBase } from '../../../models/patientEncounter';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';
import { groupingUtilsAPI } from '../../../services/grouping/utils';

/*
  Load additional lists for the encounter:
    - revenue codes
    - status indicators
*/
export const getEncounterAdditionalLists = (dispatch) => {
  return Promise.resolve()
    .then(() => {
      dispatch(getRevenueCodesBegin());

      return groupingUtilsAPI.getRevenueCodes()
        .then((result) => {
          dispatch(getRevenueCodesCompleted(result));
        })
        .catch((reason) => {
          dispatch(getRevenueCodesError(reason));

          const errors = parseServiceErrors(reason, 'Cannot Load Revenue Codes');
          dispatch(addErrorsAction(errors));
        });
    })
    .then(() => {
      dispatch(getStatusIndicatorsBegin());

      return groupingUtilsAPI.getStatusIndicators()
        .then((result) => {
          dispatch(getStatusIndicatorsCompleted(result));
        })
        .catch((reason) => {
          dispatch(getStatusIndicatorsError(reason));

          const errors = parseServiceErrors(reason, 'Cannot Load Status Indicators');
          dispatch(addErrorsAction(errors));
        });      
    })
};

const getRevenueCodesBegin = () => ({
  payload: '',
  type: actionTypes.REVENUE_CODES_GET_BEGIN,
});

const getRevenueCodesCompleted = (revenueCodes: IdDescriptionBase[]) => ({
  payload: revenueCodes,
  type: actionTypes.REVENUE_CODES_GET_COMPLETED,
});

const getRevenueCodesError = (error: Error) => ({
  payload: error,
  type: actionTypes.REVENUE_CODES_GET_ERROR,
});

const getStatusIndicatorsBegin = () => ({
  payload: '',
  type: actionTypes.STATUS_INDICATORS_GET_BEGIN,
});

const getStatusIndicatorsCompleted = (statusIndicators: IdDescriptionBase[]) => ({
  payload: statusIndicators,
  type: actionTypes.STATUS_INDICATORS_GET_COMPLETED,
});

const getStatusIndicatorsError = (error: Error) => ({
  payload: error,
  type: actionTypes.STATUS_INDICATORS_GET_ERROR,
});
