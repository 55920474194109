export interface ChoiceListDataItem {
  ViewId: string;
  Version: string;
  Id?: string | null;
  Description?: string | null;
  IsActive: boolean;
  Name?: string | null;
  Service?: string | null;
  MappedToDefault?: string | null;

  // created and not saved item
  createdItemViewId?: string;
  // edited item
  inEdit?: boolean;
  // saving state for this item
  saving?: boolean;
}

export interface ChoiceListDefaultValue {
  Code: string;
  Description: string;
}

export const getDefaultChoiceLists = () => {
  return [
    { id: 'CustomFields1', title: 'Custom Field 1' },
    { id: 'CustomFields2', title: 'Custom Field 2' },
    { id: 'CustomFields3', title: 'Custom Field 3' },
    { id: 'CustomFields4', title: 'Custom Field 4' },
    { id: 'EncounterTypes', title: 'Encounter Type' },
    { id: 'SourceOfAdmissions', title: 'Point of Origin for Admission' },
    { id: 'PatientStatuses', title: 'Patient Status' },
    { id: 'Sexes', title: 'Sex' },
    { id: 'Providers', title: 'Provider' },
    { id: 'RecordStatuses', title: 'Record Status' },
    { id: 'Services', title: 'Service' },
  ]
}

export const hasDescriptionColumn = (choiceListType: string) => {
  return choiceListType !== 'Providers';
}

export const isProvidersChoiceList = (choiceListType: string) => {
  return choiceListType === 'Providers';
}

export const isCustomFieldsChoiceList = (choiceListType: string) => {
  return choiceListType.startsWith('CustomFields');
}

export const hasDefaultColumn = (choiceListType: string) => {
  return choiceListType !== 'Providers' && !choiceListType.startsWith('CustomFields');
}

export const DefaultColumnDropDownModel = {
  columns: [
    { title: 'Default', fieldName: 'Description', className: 'choice-list-default-ddl-cell-default'},
  ],
  valueColumn: 'Code',
  textColumn: 'Description'
};
