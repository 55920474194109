/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import get  from 'lodash/get';
import { get as netGet } from '../../utils/net';
import { IdDescriptionBase } from '../../models/patientEncounter';

interface CodesByType {
  diagnoses: any[];
  procedures: any[];
}

export function matchEdits(codesByType: CodesByType, edits: any[]) {
  const editsByCode: any = {};
  forEach(edits, (edit: any) => {
    let position;
    if (edit.position) {
      position = parseInt(edit.position, 10);
    }
    if (edit.content_type && codesByType[edit.content_type] && codesByType[edit.content_type][position]) {
      // FIXME: can we avoid this no-decimal procedure?
      editsByCode[codesByType[edit.content_type][position].code.replace('.', '')] =
        editsByCode[codesByType[edit.content_type][position].code.replace('.', '')] || [];
      editsByCode[codesByType[edit.content_type][position].code.replace('.', '')].push(edit);
    }
  });
  return editsByCode;
}

export function matchMneEdits(codes: any[], edits: any[]) {
  const editsByCode: any = {};
  forEach(edits, (_edit: any) => {
    const edit = _edit;
    let position;
    if (edit.position) {
      position = parseInt(edit.position, 10);
    } else if (edit.code) {
      position = findIndex(
        codes,
        (codeObj: any) => codeObj.code.replace('.', '').toUpperCase() === edit.code.replace('.', '').toUpperCase()
      );
      if (position !== -1) {
        edit.source_field = 'code';
        setMNEEditStandardProperties(edit);
      }
    }
    if (edit.content_type && codes[edit.content_type] && codes[edit.content_type][position]) {
      // FIXME: can we avoid this no-decimal procedure?
      editsByCode[codes[edit.content_type][position].code.replace('.', '')] =
        editsByCode[codes[edit.content_type][position].code.replace('.', '')] || [];
      editsByCode[codes[edit.content_type][position].code.replace('.', '')].push(edit);
    } else if (codes[position]) {
      editsByCode[codes[position].code.replace('.', '')] = editsByCode[codes[position].code.replace('.', '')] || [];
      editsByCode[codes[position].code.replace('.', '')].push(edit);
    }
  });
  return editsByCode;
}

export function matchDescriptions(descriptions: any[]) {
  const descriptionsByCode: any = {};
  forEach(descriptions, (description: any) => {
    descriptionsByCode[description.code.replace('.', '')] = {
      code: description.code,
      description: get(description, '#text', ''),
    };
  });
  return descriptionsByCode;
}

export

function setMNEEditStandardProperties(_edit: any) {
  const edit = _edit;
  const PolicyKeys = {
    'policy-type-2': 'NCD: The ICD Diagnosis codes assigned are not on the list of nationally covered indications for procedure code {0}. {1}Please review the NCD policy.',
    'policy-type-2-3': 'NCD: Possible medical necessity issue for procedure code {0}. {1}Please review the NCD policy and details.',
    'policy-type-other': 'LCD: The ICD Diagnosis codes assigned are not on the list of locally covered indications for procedure code {0}. {1}Please review the LCD policy.',
    'policy-type-other-3': 'LCD: Possible medical necessity issue for procedure code {0}. {1}Please review the LCD policy and details.',
    'policy-type-other-article': 'Article: An article is available which may provide additional information concerning medical necessity for procedure code {0}. {1}Please review the policy.',
    'policy-type-other-article-b': 'This procedure code {0} is covered by a frequency limitaton. {1}Please review the policy.',
  };

  let noteKey = 'policy-type-';
  if (edit.policy_type === 2) {
    noteKey += '2';
  } else {
    noteKey += 'other';
    if (edit.policy_type === 1 && edit.informational_article === true) {
      noteKey += '-article';
      if (edit.diagnosis_groups.length && edit.diagnosis_groups[0].status === 'B') {
        noteKey += '-b';
      }
    }
  }
  if (edit.severity_status === 3) { noteKey += '-3'; }
  const codeType = /^[0-9]/.test(edit.code.toString()) ? 'CPT4' : 'HCPCS';
  const freqText = ''; // disable frequency edits by default, avoid for now
  edit.description = PolicyKeys[noteKey]
    .replace('{1}', freqText)
    .replace(/\{0\}/g, `<a class="code" href="javascript:void(0)" data-code-type="${codeType}">${edit.code}</a>`);
  edit.id = edit.id || edit.policy_id;
}

const getRevenueCodes = (): Promise<IdDescriptionBase[]> => {
  const baseURL = process.env.REACT_APP_TEE_GRP;
  const url = `${baseURL}/groupinginfo/revenuecodes`;

  const extraOptions = {
    useExactPath: true
  };

  return netGet(url, extraOptions).then((result) => {
    const codes: IdDescriptionBase[] = result.body.map((code) => {
      return {
        id: code.Code,
        title: code.Description,
        // GUI-2192: use Code as ViewId
        ViewId: code.Code,
      };
    });

    return codes;
  });

};

const getStatusIndicators = (): Promise<IdDescriptionBase[]> => {
  const baseURL = process.env.REACT_APP_TEE_GRP;
  const url = `${baseURL}/groupinginfo/statusindicators/custom`;

  const extraOptions = {
    useExactPath: true
  };

  return netGet(url, extraOptions).then((result) => {
    const items = result.body?.Items || [];
    const statusIndicators: IdDescriptionBase[] = items.map((si) => {
      return {
        id: si.Value,
        title: si.Description,
        // GUI-2192: use Value as ViewId
        ViewId: si.Value,
      };
    });

    return statusIndicators;
  });
};

export const groupingUtilsAPI = {
  getRevenueCodes,
  getStatusIndicators,
};

