/* eslint-disable @typescript-eslint/no-explicit-any */
import './DropdownEditor.less';

import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { orderBy, SortDescriptor, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { DropdownModel } from './DropdownModel';
import { debounce } from '../../utils/debounce';
import { DropDownList, GridCellProps, ListItemProps } from '..';

export interface DropdownEditorProps extends GridCellProps {
  field: string;          // field name
  dataItem: any;          // related dataItem
  list: any;              // ddl values
  model: DropdownModel;   // columns and fields
  readOnly?: boolean;     // cannot be edited
  filterable?: boolean;   // show filter field
  sortable?: boolean;     // show sorting
  noInitialSorting?: boolean; // do not show initial sorting
  virtual?: boolean;      // is virtual list
  virtualPageSize?: number;   // virtual: page size
  virtualLoadPage?: (skip, pageSize, dataItem, sortField, sortDir, filter) => Promise<any>; // virtual: promise to load single page
  virtualParametersAreChanged?: (oldDataItem: any, newDataItem: any) => boolean;            // virtual: function to check if additional parameters are changed
  virtualErrorText?: string;  // show this text instead of list for the error
  className?: string;         // ddl className
  displayValue?: string;      // value to display as current (in virtual lists not all data can be loaded)
  returnFullRecord?: boolean; // return full record instead of the single value (some ddls can update several lists)
  prepareFilter?: (filter: FilterDescriptor) => CompositeFilterDescriptor;  // use special filter
  markEmptyValue?: boolean;   // show red border for the empty value
  useSpecialSorting?: (data: any[], sortDescriptor: SortDescriptor[]) => any[];  // allows to use own sorting algorithm for static data
  useCombinedDisplayValue?: (data: any) => string;

  // additional validation properties
  label?: string;
  required?: boolean;
  validationMessage?: string;
  valid?: boolean;
  dependentField?: string;
}

export interface DropdownEditorState {
  sort: SortDescriptor[];
  staticData: any;
  value?: string;
  filter?: FilterDescriptor;
  virtualTotal?: number;                // must recognize empty list due filtering (0) and due list not loaded (undefined)
  virtualSkip?: number;
  virtualError?: boolean;
  virtualLoadedData: any[];             // cached data
  virtualLoadedDataDataItem: any;       // dataItem used to fill cache. We must clear cache if additional parameters (for example type) are changed
  virtualReloading: boolean;            // there is full reloading: initial / sort event / filter event
  prevPropsValue?: string;              // previous props value of the dataItem to properly update it from the parent
  listOpened?: boolean;                 // indicates opened list
}

export class DropdownEditor
  extends React.Component<DropdownEditorProps, DropdownEditorState> {

  header: React.ReactNode;
  uniq: string;
  virtualLoading: boolean;

  constructor(props: DropdownEditorProps) {
    super(props);
    this.itemRender = this.itemRender.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
    this.sort = this.sort.bind(this);
    const prevPropsValue = props.dataItem[props.field];
    const value = props.list.find(item => item[props.model.valueColumn] === prevPropsValue) || null;
    this.state = {
      staticData: props.virtual ? [] : props.list,
      sort: [],
      value,
      prevPropsValue,
      virtualSkip: 0,
      virtualError: false,
      virtualLoadedData: [],
      virtualLoadedDataDataItem: {},
      virtualReloading: false
    };
    this.uniq = uuid();
  }

  static getDerivedStateFromProps(nextProps: DropdownEditorProps, prevState: DropdownEditorState) {
    let nextState;

    // value was updated in the parent component: use new value
    const nextPropsValue = nextProps.dataItem[nextProps.field];
    if (nextPropsValue !== prevState.prevPropsValue) {
      let data = nextProps.virtual ? [] : nextProps.list;
      if (nextProps.virtual && !prevState.virtualReloading) {
        data = prevState.virtualLoadedData;
      }

      const value = data.find(item => item[nextProps.model.valueColumn] === nextPropsValue) || null;
      nextState = {
        value,
        prevPropsValue: nextPropsValue,
        filter: undefined,
      }
    }

    if (nextProps.dependentField && Object.keys(prevState.virtualLoadedDataDataItem).length > 0 && nextProps.dataItem[nextProps.dependentField] !== prevState.virtualLoadedDataDataItem[nextProps.dependentField]) {
      nextState = nextState || {};
      nextState.filter = undefined;
    }

    // list was updated in the parent component. Do not compare every item - they must not be changed but lists can be dynamically loaded
    if (!nextProps.virtual && (nextProps.list !== prevState.staticData || nextProps.list.length !== prevState.staticData.length)) {
      const { filter, sort } = prevState;
      const listFilter = filter && nextProps.prepareFilter ? nextProps.prepareFilter(filter) : filter;
      let newData = listFilter ? filterBy(nextProps.list, listFilter) : nextProps.list;
      if (sort && sort.length) {
        newData = nextProps.useSpecialSorting ? nextProps.useSpecialSorting(newData, sort) : orderBy(newData, sort);
      }

      nextState = nextState || {};
      nextState.staticData = newData;
    }

    return nextState || null;
  }

  private static readonly sortDirectionMap = { 'asc': 'desc', 'desc': 'asc', '': 'desc' };

  render() {
    const header = this.renderHeader();
    const virtual = !this.props.virtual ? undefined : {
      total: this.state.virtualTotal || 0,
      pageSize: this.props.virtualPageSize || 0,
      skip: this.state.virtualSkip || 0,
    }

    let data = !this.props.virtual ? this.state.staticData
      : this.state.virtualLoadedData.slice(this.state.virtualSkip, (this.state.virtualSkip || 0) + (this.props.virtualPageSize || 10));

    // special situation to render not loaded required value that is counted as correct: emulate it in the storage for the closed list
    let shownValue: any = this.state.value;
    if (this.props.required && this.props.virtual && !this.state.value && this.props.displayValue && !this.state.listOpened) {
      shownValue = {
        [this.props.model.valueColumn]: this.props.displayValue,
        [this.props.model.textColumn]: this.props.displayValue,
        index: this.props.displayValue
      }
      data = [shownValue]
    }

    const value = this.props.dataItem[this.props.field];
    const markEmptyValue = this.props.markEmptyValue && !value;

    return (
      <div style={{ display: 'inline-block', width: '100%' }}>
        <DropDownList
          data={this.props.readOnly ? [] : data}
          header={header}
          textField={this.props.model.textColumn}
          dataItemKey={this.props.virtual ? 'index' : undefined}   // special field to control selected item for virtual list
          returnFullRecord
          value={shownValue}
          itemRender={this.itemRender}
          valueRender={this.valueRender}
          onClose={this.onClose}
          onChange={this.onChange}
          onFilterChange={this.props.filterable ? this.onFilterChange : undefined}
          className={`${this.props.className}${markEmptyValue ? ' invalid-value' : ''}`}
          popupSettings={{ width: this.props.model.popupWidth ? this.props.model.popupWidth : '375px' }}
          onOpen={this.onOpen}
          opened={this.props.readOnly ? false : undefined}
          filterable={this.props.filterable}
          virtual={virtual}
          onPageChange={this.onPageChange}
          listNoDataRender={this.props.virtual && this.state.virtualError || this.state.virtualReloading ? this.listErrorOrLoadingDataRender : undefined}
          loading={this.state.virtualReloading}
          label={this.props.label}
          required={this.props.required}
          validationMessage={this.props.validationMessage}
          valid={this.props.valid}
          filter={this.state.filter ? this.state.filter.value : ''}
        />
      </div>
    );
  }

  private renderSortIcon(field: string) {
    if (!this.props.sortable) {
      return '';
    }

    // initial sorting is active
    if (!this.props.noInitialSorting && !this.state.sort.length) {
      const firstField = this.props.model.columns[0].fieldName;

      if (field === firstField) {
        return <span className='k-icon k-i-sort-asc-sm'/>;
      }
    }

    if (this.state.sort.length && this.state.sort[0].field === field) {
      return (
        <span className={this.state.sort[0].dir === 'asc' ? 'k-icon k-i-sort-asc-sm' : 'k-icon k-i-sort-desc-sm'}/>);
    }

    return '';
  }

  private renderHeader() {
    const columns = this.props.model.columns.map((m, i) => {
      let className = `${m.className} ddl-col`;
      if (i === 0) {
        className += ' first';
      } else if (i === this.props.model.columns.length) {
        className += ' last';
      }

      return (
        <span key={m.fieldName} className={className} onClick={this.sort.bind(this, m.fieldName)}>
          {m.title}{this.renderSortIcon(m.fieldName)}
        </span>);
    }
    );
    return (
      <div key={-1} className="ddl-grid k-header">
        {columns}
      </div>);
  }

  private sort(field: string) {
    if (!this.props.sortable) {
      return;
    }

    let oldDescriptor = this.state.sort.filter((d) => d.field === field)[0];
    if (!this.state.sort.length) {
      // initial sorting is active
      const firstField = this.props.model.columns[0].fieldName;
      oldDescriptor = { field: firstField, dir: this.props.noInitialSorting ? 'desc' : 'asc' };
    }

    let dir = DropdownEditor.sortDirectionMap[oldDescriptor && oldDescriptor.dir || ''];
    // use asc after field changing
    if (!oldDescriptor || oldDescriptor.field !== field) {
      dir = 'asc';
    }

    const newDescriptor = (dir === '' ? [] : [{ field, dir }]) as SortDescriptor[];
    const { filter } = this.state;

    if (this.props.virtual) {
      if (!this.props.virtualLoadPage) {
        return;
      }

      // ask sorted data from the services
      this.setState({
        virtualLoadedData: [],  // clear cache to reload it with new sorting
        virtualSkip: 0,
        virtualTotal: undefined,
        virtualError: false,
        virtualReloading: true,
        sort: newDescriptor
      }, () => {
        // reload data and keep position (Kendo doesn't update scrolling position after virtualSkip changing)
        this.requestVirtualData(this.state.virtualSkip, this.props.virtualPageSize);
      });

      return;
    }

    const listFilter = filter && this.props.prepareFilter ? this.props.prepareFilter(filter) : filter;
    let newData = listFilter ? filterBy(this.props.list, listFilter) : this.props.list;
    newData = this.props.useSpecialSorting ? this.props.useSpecialSorting(newData, newDescriptor) : orderBy(newData, newDescriptor);

    this.setState({
      staticData: newData,
      sort: newDescriptor
    });
  }

  onFilterChange = (e) => {
    if (this.props.virtual) {
      if (!this.props.virtualLoadPage) {
        return;
      }

      // ask filtered data from the services
      this.setState({
        virtualSkip: 0,
        virtualLoadedData: [],  // clear cache to reload it with new filter
        virtualTotal: undefined,
        virtualError: false,
        virtualReloading: true,
        filter: e.filter,
      }, () => {
        // reload data
        this.requestVirtualData(0, this.props.virtualPageSize);
        this.setState({ virtualSkip: 0 });
      });

      return;
    }

    const listFilter = e.filter && this.props.prepareFilter ? this.props.prepareFilter(e.filter) : e.filter;

    let newData = filterBy(this.props.list, listFilter);
    const { sort } = this.state;
    if (sort && sort.length) {
      newData = this.props.useSpecialSorting ? this.props.useSpecialSorting(newData, sort) : orderBy(newData, sort);
    }

    this.setState({
      filter: e.filter,
      staticData: newData
    });
  }

  private onChange(_name, value) {
    if (this.props.readOnly || !value) {
      return;
    }

    // we must load value first
    if (this.props.virtual && !value.loaded) {
      return;
    }


    this.setState({ value }, () => {
      if (this.props.onChange) {
        this.props.onChange({
          dataItem: this.props.dataItem,
          field: this.props.field,
          syntheticEvent: null, // e.syntheticEvent,
          value: this.props.returnFullRecord ? value : value[this.props.model.valueColumn]
        });
      }
    });
  }

  private onClose = (/* e: DropDownListCloseEvent */) => {
    this.setState({
      listOpened: false,
    })
  }

  private onOpen = (/* e: DropDownListOpenEvent */) => {
    if (this.props.virtual) {
      const sameKeys = this.props.virtualParametersAreChanged ? !this.props.virtualParametersAreChanged(this.state.virtualLoadedDataDataItem, this.props.dataItem) : true;

      if (sameKeys) {
        this.setState({
          virtualLoadedDataDataItem: { ...this.props.dataItem },
          virtualError: false,
          listOpened: true,
        }, () => {
          this.loadPage(0, this.props.virtualPageSize);
        })

        return;
      }

      // clear cache to reload data with new additional parameters
      this.setState({
        virtualSkip: 0,
        virtualLoadedData : [],
        virtualLoadedDataDataItem: { ...this.props.dataItem },
        virtualTotal: undefined,
        virtualError: false,
        virtualReloading: true,
        listOpened: true,
      }, () => {
        this.loadPage(0, this.props.virtualPageSize);
      })
    } else {
      this.setState({
        listOpened: true,
      })
    }
  }

  private itemRender(li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) {
    // not loaded items
    if (this.props.virtual && (!itemProps.dataItem || !itemProps.dataItem.loaded)) {
      const itemChildren = <span className='k-icon k-i-loading'/>;
      return React.cloneElement(li, li.props, itemChildren);
    }

    const { filter } = this.state;
    const filterValue = filter && filter.value ? filter.value.toUpperCase() : '';
    const itemChildren = this.props.model.columns.map((m, i) => {
      let className = `${m.className} ddl-col`;
      if (i === 0) {
        className += ' first';
      } else if (i === (this.props.model.columns.length - 1)) {
        className += ' last';
      }

      // we must not wrap textes in virtual lists to have equal items height
      if (this.props.virtual) {
        className += ' ddl-no-overflow';
      }

      const original = this.props.useCombinedDisplayValue ? this.props.useCombinedDisplayValue(itemProps.dataItem)
        : itemProps.dataItem[m.fieldName];
      const text = original && filterValue ? this.highlightFilter(original, filterValue) : original;
      return (
        <span key={m.fieldName} className={className}>
          {text}
        </span>);
    });

    const liProps = {
      ...li.props,
      className: `${li.props.className || ''} provider-ddl ddl-row`,
    };

    return React.cloneElement(li, {...liProps}, itemChildren);
  }

  // create JSX.Element with spans array
  private highlightFilter = (text: string, filter: string) => {
    const filterLen = filter.length;
    const textLen = text.length;
    const lastPos = textLen - filterLen;

    let pos = 0;
    let lastPart = '';  // accumulate last non-filter part here
    let key = 1;

    const parts: JSX.Element[] = [];
    while(pos < textLen) {
      // do not check rest of the string
      const part = pos <= lastPos ? text.substr(pos, filterLen) : '';
      const partUpperCase = part.toUpperCase();
      if (partUpperCase === filter) {
        if (lastPart.length > 0) {
          // text before filter
          parts.push(<span key={key++}>{lastPart}</span>);
          lastPart = '';
        }
        parts.push(<span className="highlight-filter" key={key++}>{part}</span>);
        pos += filterLen;
      } else {
        lastPart = `${lastPart}${text[pos]}`;
        pos++;
      }
    }

    if (lastPart.length > 0) {
      parts.push(<span key={key++}>{lastPart}</span>);
    }

    return <span>{parts}</span>;
  }

  private valueRender = (element: React.ReactElement<HTMLSpanElement>, value: any) => {
    // do not show previously selected value after field clearing (Kendo behaviour). Count '' as valid for now (MAC ddl).
    const fieldValue = this.props.dataItem[this.props.field];
    if (fieldValue === null || fieldValue === undefined) {
      const children = [
        <span key={1}/>,
      ];

      return React.cloneElement(element, { ...element.props }, children);
    }

    if (!value && !this.props.displayValue) {
      return element;
    }

    const children = this.props.useCombinedDisplayValue ? [
      <span key={1}>{this.props.useCombinedDisplayValue(this.props.dataItem)}</span>
    ]
      :[
        <span key={1}>{element.props.children ? element.props.children : value || this.props.displayValue}</span>,
      ];

    return React.cloneElement(element, { ...element.props }, children);
  }

  private onPageChange = (event) => {
    const { skip, take } = event.page;

    this.loadPage(skip, take);
  }

  loadPage = (skip = 0, pageSize = 10) => {
    if (!this.props.virtualLoadPage) {
      return;
    }

    this.requestVirtualDataIfNeeded(skip, pageSize);
    this.setState({
      virtualSkip: skip
    });
  }

  requestVirtualDataIfNeeded(skip, pageSize) {
    if (this.state.virtualError) {
      return;
    }

    if (skip >= this.state.virtualLoadedData.length && this.state.virtualTotal !== 0) {
      this.requestVirtualData(skip, pageSize);
      return;
    }

    for (let i = skip; i < skip + pageSize && i < this.state.virtualLoadedData.length; i++) {
      if (!this.state.virtualLoadedData[i] || !this.state.virtualLoadedData[i].loaded) {
        // request data only if not already fetched
        this.requestVirtualData(skip, pageSize);
        return;
      }
    }
  }

  requestVirtualData = (skipParameter, pageSize) => {
    if (this.virtualLoading || !this.props.virtualLoadPage) {
      // perform only one request at a time
      return false;
    }

    this.virtualLoading = true;

    const skip = skipParameter; // Math.max(skipParameter - pageSize, 0); // request the prev page as well
    const { sort, filter, virtualLoadedData } = this.state;
    const sortField = sort && sort.length && sort[0].field;
    const sortDir = sort && sort.length && sort[0].dir;
    const listFilter = filter && this.props.prepareFilter ? this.props.prepareFilter(filter) : filter;
    const { valueColumn } = this.props.model;

    const loadFrom = skip;
    return this.props.virtualLoadPage(loadFrom, pageSize * 2, this.props.dataItem, sortField, sortDir, listFilter)
      .then((result) => {
        this.virtualLoading = false;

        let newData = virtualLoadedData;
        const textField = this.props.model.textColumn;
        // increase size and copy old data
        if (newData.length < result.total) {
          newData = new Array(result.total);
          for (let ind = 0; ind < result.total; ind++) {
            newData[ind] = { loaded: false, [textField]: '', index: ind };
          }
          for (let ind = 0; ind < virtualLoadedData.length; ind++) {
            if (virtualLoadedData[ind] && virtualLoadedData[ind].loaded) {
              newData[ind] = { ...virtualLoadedData[ind], index: `${ind} ${virtualLoadedData[ind][valueColumn]}` };
            }
          }
        }

        result.data.forEach((value, i) => {
          newData[i + loadFrom] = { ...value, loaded: true, index: `${i + loadFrom} ${value[valueColumn]}` };
        });

        // virtualSkip can be changed during loading: check if we need to load more data
        this.requestVirtualDataIfNeeded(this.state.virtualSkip, pageSize);

        const hasErrors = result.errors && result.errors.length > 0;
        this.setState({
          virtualTotal: hasErrors ? undefined : result.total,
          virtualLoadedData: hasErrors ? [] : newData,
          virtualError: result.errors && result.errors.length > 0,
          virtualReloading: false
        })

        return hasErrors;
      });
  }


  private listErrorOrLoadingDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: '1em' }}>
        {this.state.virtualReloading ? 'Loading' : this.props.virtualErrorText}
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  }
}
