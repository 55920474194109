import './NoticeList.less';

import * as React from 'react';
import { ErrorDetails } from '../../../models/ui';
import { Notice } from './Notice';

export interface NoticeListProps {
  notices: ErrorDetails[];
  onClickClear: (e: React.MouseEvent) => void;
}

export class NoticeList extends React.PureComponent<NoticeListProps> {
  render() {
    const notices = !this.props.notices || this.props.notices && this.props.notices.length === 0
      ? []
      : this.props.notices;
    const emptyNotices = (
      <div className="no-notice-block">
        <p>There are no notifications at this time.</p>
      </div>
    );
    const noticeList = notices.map((notice, idx, list) => (
      <Notice key={`${notice.time}-${notice.description}`} notice={notice} className={idx === list.length - 1 ? 'last' : ''} />
    ));
    return (
      <div className="notices-drawer">
        {/* FIXME: convert to button */}
        {notices.length > 0 &&
          <div className="k-button k-primary clear-notices-button" onClick={this.props.onClickClear}>Clear Errors</div>
        }
        <div className="notices-wrapper">
          {notices.length === 0 &&
            emptyNotices
          }
          {notices.length > 0 &&
            noticeList
          }
        </div>
      </div >
    );
  }
}
