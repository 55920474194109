import './Menu.less';

import * as React from 'react';
import { Router, match } from 'react-router-dom';
import * as History from 'history';
import { UserState } from '../../../../models';
import { history } from '../../../../reduxStore';
import TruLink from '../../../TruLink/TruLink';
import { B2CState } from '../../../../models/b2c';
import { Button, ButtonMode } from '../../..';
import { getUserName } from '../../../../authProvider';
import { IconType } from '../../../Icon/Icon';

declare global {
  interface Window {
    tc_git_hash: string;
  }
}

export interface UserMenuProps {
  logout: () => void;
  changePassword: () => void;
  user: UserState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  match: match<any>;
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  onBlur: () => void;
  onMenuOpen: (el: HTMLUListElement | null) => void;
  b2c: B2CState;
  isErrorMode?: boolean;
  encounterOpened: boolean;
}

interface UserMenuState {
  showClientMode: boolean;
}

export default class UserMenu extends React.PureComponent<UserMenuProps, UserMenuState> {
  ref: HTMLUListElement | null;
  timeout: number;

  constructor(props: UserMenuProps) {
    super(props);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.showClientMode = this.showClientMode.bind(this);
    this.state = {
      showClientMode: false,
    }
  }

  componentDidMount() {
    this.props.onMenuOpen(this.ref);
  }

  render() {
    const isErrorMode = this.props.isErrorMode || false;
    const name = this.props.user.account.firstName !== '' && this.props.user.account.lastName !== '' ? `${this.props.user.account.firstName} ${this.props.user.account.lastName}` : getUserName();
    return (
      <ul className="tc-appbar-menu right" ref={(e) => { this.ref = e; }} tabIndex={0} onFocus={this.onFocus} onBlur={this.onBlur}>
        <li className="info"><strong>{name}</strong></li>
        <li className="separator" />
        <li>
          <TruLink
            to="/userpreferences"
            hasChangesCallback={this.props.hasChangesCallback}
            isErrorMode={isErrorMode}
            encounterOpened={this.props.encounterOpened}
          >
            <Button
              mode={ButtonMode.Transparent}
              label="Personal Settings"
              className="user-external-button"
              iconType={this.props.encounterOpened ? IconType.ExternalLink : null}
              iconPosition="right" />
          </TruLink>
        </li>
        <li>
          <Button
            mode={ButtonMode.Transparent}
            onClick={this.props.changePassword}
            className="user-external-button"
            iconType={this.props.encounterOpened ? IconType.ExternalLink : null}
            iconPosition="right"
            label="Change Password" />
        </li>
        <li className="separator" />
        <li>
          <Button
            mode={ButtonMode.Transparent}
            onClick={this.onLogout}
            label="Log out" />
        </li>
        <li className="separator" />
        <li className="revision" onClick={this.showClientMode}>v.<span className="version" title={window.tc_git_hash}>{window.tc_git_hash.substr(0, 10)}</span></li>
        <li className={`client-mode show-${this.state.showClientMode}`}>{this.props.user.clientMode}</li>
      </ul>
    );
  }

  private showClientMode(e: React.MouseEvent<HTMLLIElement>) {
    if (e.ctrlKey) {
      this.setState({ showClientMode: true });
    }
  }

  private onFocus() {
    clearTimeout(this.timeout);
  }

  private onBlur() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.props.onBlur);
  }

  // hide menu before logout dialog opening
  private onLogout() {
    clearTimeout(this.timeout);
    this.props.onBlur();
    this.props.logout();
  }
}
