import * as actionTypes from '../constants';

import { SettingsState } from '../scenes/FacilityPreferences/models/settingsState';
import { PREFERENCES_TYPE_INPATIENT, PREFERENCES_TYPE_OUTPATIENT, PREFERENCES_TYPE_GENERAL } from '../scenes/FacilityPreferences/models/preferences';
import { createEmptyFacilityEntity, FacilityEntity } from '../scenes/Facility/models/facilities';
import { IdDescriptionBase } from '../models/patientEncounter';

const createEmptyPreferencesState = (): SettingsState => ({
  loading: false,
  revenueCodes: [],
  selectedFacilityViewId: '',
  selectedPreferencesType: PREFERENCES_TYPE_GENERAL,
  general: {},
  inPatient: {},
  outPatient: {},
  currentFacility: createEmptyFacilityEntity(),
  editedFacility: createEmptyFacilityEntity(),
  editedFacilityErrorTexts: [],
  editedFacilityStartValidation: false,
  loadingError: '',
});

export const preferencesReducer = (state = createEmptyPreferencesState(), action) => {
  switch (action.type) {
    case actionTypes.PREFERENCES_SET_REVENUE_CODES:
      return handleSetRevenueCodes(state, action.payload);
    case actionTypes.PREFERENCES_LOADING:
      return handleSetLoading(state, action.payload);
    case actionTypes.PREFERENCES_SELECT_FACILITY:
      return handleSelectFacility(state, action.payload);
    case actionTypes.PREFERENCES_SELECT_PREFERENCES_TYPE:
      return handleSelectPreferencesType(state, action.payload);
    case actionTypes.PREFERENCES_CHANGE_PREFERENCE:
      return handleChangePreference(state, action.payload);
    case actionTypes.PREFERENCES_CHANGE_EDITED_FACILITY:
      return handleChangeEditedFacility(state, action.payload);
    case actionTypes.PREFERENCES_CHANGE_EDITED_FACILITY_ERROR_TEXT:
      return handleChangeEditedFacilityErrorText(state, action.payload);
    case actionTypes.PREFERENCES_START_EDITED_FACILITY_VALIDATION:
      return handleChangeEditedFacilityValidation(state, action.payload);
    case actionTypes.PREFERENCES_EDITED_FACILITY_UPDATED:
      return handleEditedFacilityUpdated(state, action.payload);
    case actionTypes.SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES:
      return handleClearDirtyChanges(state);

    default:
      return state;
  }
};

const handleSetRevenueCodes = (state: SettingsState, payload: { revenueCodes: IdDescriptionBase[] }): SettingsState => {
  const newState = {
    ...state,
    revenueCodes: payload.revenueCodes,
  };

  return newState;
}

const handleSetLoading = (state: SettingsState, loading: boolean): SettingsState => {
  const newState = {
    ...state,
    loading,
  };

  return newState;
}

const handleSelectFacility = (state: SettingsState, payload: { preferences, facilityViewId: string, facility: FacilityEntity | null, error: string }): SettingsState => {
  const newState = {
    ...state,
    selectedFacilityViewId: payload.facilityViewId,
    inPatient: payload.preferences ? { ...payload.preferences.InPatientPreferences } : {},
    outPatient: payload.preferences ? { ...payload.preferences.OutPatientPreferences } : {},
    general: payload.preferences ? { ...payload.preferences.GeneralPreferences } : {},
    currentFacility: payload.facility ? { ...payload.facility } : createEmptyFacilityEntity(),
    editedFacility: payload.facility ? { ...payload.facility } : createEmptyFacilityEntity(),
    editedFacilityErrorTexts: [],
    editedFacilityStartValidation: false,
    loadingError: payload.error,
  };

  return newState;
}

const handleSelectPreferencesType = (state: SettingsState, preferencesType: number): SettingsState => {
  const newState = {
    ...state,
    selectedPreferencesType: preferencesType
  };

  return newState;
}

const handleChangePreference = (state: SettingsState, payload: { preferencesType: number, updatedPreferences }): SettingsState => {
  const newState = {
    ...state,
    inPatient: payload.preferencesType === PREFERENCES_TYPE_INPATIENT ? { ...payload.updatedPreferences }
      : { ...state.inPatient, Version: payload.updatedPreferences.Version },
    outPatient: payload.preferencesType === PREFERENCES_TYPE_OUTPATIENT ? { ...payload.updatedPreferences }
      : { ...state.outPatient, Version: payload.updatedPreferences.Version },
    general: payload.preferencesType === PREFERENCES_TYPE_GENERAL ? { ...payload.updatedPreferences }
      : { ...state.general, Version: payload.updatedPreferences.Version },

    editedFacility: { ...state.editedFacility, Version: payload.updatedPreferences.Version },
    currentFacility: { ...state.currentFacility, Version: payload.updatedPreferences.Version },
  };

  return newState;
}

const handleChangeEditedFacility = (state: SettingsState, newFacility: FacilityEntity): SettingsState => {
  return {
    ...state,
    editedFacility: newFacility,
  }
}

const handleChangeEditedFacilityErrorText = (state: SettingsState, errorTexts: string[]): SettingsState => {
  return {
    ...state,
    editedFacilityErrorTexts: errorTexts,
  }
}

const handleChangeEditedFacilityValidation = (state: SettingsState, start: boolean): SettingsState => {
  return {
    ...state,
    editedFacilityStartValidation: start,
  }
}


// update with backend response
const handleEditedFacilityUpdated = (state: SettingsState, newFacility: FacilityEntity): SettingsState => {
  const updatedVersion = newFacility.Version || '';

  return {
    ...state,
    editedFacility: newFacility,
    currentFacility: { ...newFacility },

    inPatient: { ...state.inPatient, Version: updatedVersion },
    outPatient: { ...state.outPatient, Version: updatedVersion },
    general: { ...state.general, Version: updatedVersion },
  }
}

const handleClearDirtyChanges = (state: SettingsState): SettingsState => {
  return {
    ...state,
    editedFacility: { ...state.currentFacility },
  }
}
