import { connect } from 'react-redux';
import { clearErrorsAction } from '../../actions/errors';
import { State } from '../../reducers';

import NotificationDialog from './NotificationDialog';

const mapStateToProps = (state: State) => ({
  errors: state.ui.errors,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: (index?: number) => dispatch(clearErrorsAction(index)),
});

export const NotificationDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationDialog);
