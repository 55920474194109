import './Grid.less';

import * as React from 'react';
import { GridPDFExport as KGridPDFExport, GridPDFExportProps as KGridPDFExportProps } from '@progress/kendo-react-pdf';

export class GridPDFExport extends KGridPDFExport {
  render() {
    return <KGridPDFExport {...this.props} />;
  }
}

export interface GridPDFExportProps extends React.PropsWithChildren<KGridPDFExportProps> {
  ref: React.Ref<KGridPDFExport>;
};
