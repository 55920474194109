// eslint-disable-next-line no-useless-escape
const grammPattern = new RegExp('((^[0-9]{1,} *)g$)|(^[0-9]{1,}\s*$)');
export const partialWeightPattern = new RegExp('(^[0-9]{1,} *(g)?$)|(^[0-9]{1,} *(l(b(s)?)?)? *$)|(^[0-9]{1,}( )*(l|lb|lbs) *[0-9]{1,} *(o(z)?)?$)');

// parse weight, return error or formatted string
export function parseWeight(fieldValue: string): string {
  if (!fieldValue) {
    return '';
  }

  const value = fieldValue.toLowerCase().trim();

  if (grammPattern.test(value)) {
    const gramms = parseInt(value, 10);

    if (gramms > 9999) {
      return 'Birth weight should be less than 9999 grams.';
    }

    return `${gramms}g`;
  }

  return parseLBSOzWeight(value);
}

// check if returned value is error string or correct string
export function isIncorrectWeight(value: string): boolean {
  if (value === 'Incorrect weight format'
    || value === 'Birth weight ounces should be less than 16.'
    || value === 'Birth weight should be less than 9999 grams.') {
    return true;
  }

  return false;
}

// parse lbs + oz weight, return error or formatted string
function parseLBSOzWeight(value: string): string {
  let lbsStr = '';
  let ozStr = '';
  let ind = 0;

  while (ind < value.length) {
    if (value[ind] >= '0' && value[ind] <= '9') {
      lbsStr += value[ind];
      ind++;
    } else {
      break;
    }
  }

  if (lbsStr.length === 0) {
    return 'Incorrect weight format';
  }

  while (ind < value.length && value[ind] === ' ') {
    ind++;
  }

  let noOz = false;

  if (ind === value.length - 1 && value[ind] === 'l') {
    noOz = true;
  }

  if (ind === value.length - 3 && value[ind] === 'l' && value[ind + 1] === 'b' && value[ind + 2] === 's') {
    noOz = true;
  }

  if (!noOz) {
    if (ind < value.length - 2 && value[ind] === 'l'
      && value[ind + 1] === 'b' && value[ind + 2] === 's') {
      ind += 3;
    } else if (ind < value.length && value[ind] === 'l') {
      ind ++;
    } else {
      return 'Incorrect weight format';
    }

    while (ind < value.length && value[ind] === ' ') {
      ind++;
    }

    while (ind < value.length) {
      if (value[ind] >= '0' && value[ind] <= '9') {
        ozStr += value[ind];
        ind++;
      } else {
        break;
      }
    }

    if (ozStr.length === 0) {
      return 'Incorrect weight format';
    }

    while (ind < value.length && value[ind] === ' ') {
      ind++;
    }

    if (!((ind === value.length)
      || (ind === value.length - 1 && value[ind] === 'o')
      || (ind === value.length - 2 && value[ind] === 'o' && value[ind + 1] === 'z'))) {
      return 'Incorrect weight format';
    }
  }

  const ozValue = parseInt(ozStr, 10) || 0;

  if (ozValue > 15) {
    return 'Birth weight ounces should be less than 16.';
  }

  const lbsValue = parseInt(lbsStr, 10);
  const weight = (lbsValue + ozValue / 16.0) * 0.454;

  if (weight > 9.999) {
    return 'Birth weight should be less than 9999 grams.';
  }

  const finalString = noOz ? (`${lbsStr}lbs`) : (`${lbsStr}lbs ${ozStr}oz`);

  return finalString;
}
