/* eslint-disable @typescript-eslint/no-explicit-any */
import find from 'lodash/find';
import includes from 'lodash/includes';
import { createLogic } from 'redux-logic';
import * as actionTypes from '../constants';
import { ADMIT_DX_ID } from '../models';
import { BaseCodeRow } from '../models/codeGrid';
import { gridsHaveIncorrectFields } from '../reducers/Helpers/encounterCodeGrid';
import { LogicLifecycleParams } from '../reduxStore';
import {
  codeGridBlurCodeFieldAction,
  CodeGridChange,
  CodeGridCodeBlur,
  codeGridExitEditAction,
  codeGridKeypressAction,
  CodeGridExitEditInfo,
} from '../scenes/Encounter/actions/codeGrid';
import { combineEncounterCodes } from '../utils/encounter';

export const codeBlurLogic = createLogic({
  type: [actionTypes.CODE_GRID_TRY_BLUR_CODE_FIELD],
  cancelType: [actionTypes.OPEN_ENCOUNTER_BY_ID_BEGIN, actionTypes.RESEARCH_EDIT_LOCATE_EVENT],

  process({ action }: LogicLifecycleParams, dispatch: any, done: any) {
    const sender: CodeGridCodeBlur = action.payload;

    // the same code
    if (sender.value === sender.dataItem.initialCode || (!sender.value && !sender.dataItem.initialCode)) {
      dispatch(codeGridBlurCodeFieldAction(action.payload));
      done();
      return;
    }

    // apply changes to the grid
    dispatch(codeGridBlurCodeFieldAction(action.payload));

    done();
  },
});

// Don't exit if row is invalid or lookup is occurring
// Don't allow exit to trigger if nothing is in edit
export const codeExitEditLogic = createLogic({
  type: [actionTypes.CODE_GRID_TRY_EXIT_EDIT],
  cancelType: [actionTypes.OPEN_ENCOUNTER_BY_ID_BEGIN, actionTypes.RESEARCH_EDIT_LOCATE_EVENT],

  validate({ getState, action }: LogicLifecycleParams, allow: any, reject: any) {
    const { inProgress } = getState().encounter;
    if (inProgress.admitDiagnosisCode.inEdit) {
      allow();
      return;
    }
    // gather all codes in temp array for finding
    const codes = combineEncounterCodes(inProgress);

    const item: BaseCodeRow = find(codes, (i: BaseCodeRow) => i.inEdit);
    if (!item) {
      // nothing is being edited, stop
      reject();
      return;
    }
    // skip prevention of edit exiting
    // else {
    //   // is a lookup in progress or completed?
    //   if (item.loadingValidity) {
    //     return reject();
    //   }
    //   if (typeof item.valid === 'boolean' && item.valid === false) {
    //     return reject();
    //   }
    // }
    allow(action);
  },

  process({ getState, action }: LogicLifecycleParams, dispatch: any, done: any) {
    // add columns information
    const payload: CodeGridExitEditInfo = {
      tabFromLastColumn: action.payload,
      columnsSettings: getState().ui.codeGridsColumns,
      fieldSettings: getState().facilitySettings.fieldSettings,
      isShowingPdxAnalysis: getState().ui.isShowingPdxAnalysis,
    };

    dispatch(codeGridExitEditAction(payload));
    done();
  },
});

export const codeEnterEditLogic = createLogic({
  type: [actionTypes.CODE_GRID_TRY_ENTER_EDIT],
  cancelType: [actionTypes.OPEN_ENCOUNTER_BY_ID_BEGIN, actionTypes.RESEARCH_EDIT_LOCATE_EVENT],

  validate({ action }: LogicLifecycleParams, allow: any, reject: any) {
    const item: CodeGridChange = action.payload;
    if (includes(item.classNames, 'no-edit-hack')) {
      reject();
      return;
    }
    // if empty row, first edit *must* be on the code field
    if (item.dataItem.empty && !(item.field === 'code' || item.dataItem.id === ADMIT_DX_ID)) {
      reject();
      return;
    }
    allow(action);
  },

  process({ getState, action }: LogicLifecycleParams, dispatch: any, done: any) {
    // add providers information
    const payload: CodeGridChange = {
      ...action.payload,
      providers: getState().choiceLists.providers,
    };

    dispatch({
      payload,
      type: actionTypes.CODE_GRID_ENTER_EDIT,
    });

    done();
  },
});

export const codeChangeLogic = createLogic({
  type: [actionTypes.CODE_GRID_CHANGE],

  process({ getState, action }: LogicLifecycleParams, dispatch: any, done: any) {
    const payload = action.payload;
    setTimeout(() => {
      dispatch({
        payload,
        type: actionTypes.CODE_GRID_CLEAR_AUTOCHANGE,
      });
      done();
    }, 1000);
  },
});

export const codeKeypressLogic = createLogic({
  type: [actionTypes.CODE_GRID_TRY_KEYPRESS],
  cancelType: [actionTypes.OPEN_ENCOUNTER_BY_ID_BEGIN],

  validate({ action }: LogicLifecycleParams, allow: any) {
    allow(action);
  },

  process({ getState, action }: LogicLifecycleParams, dispatch: any, done: any) {
    // add columns information
    const payload = {
      ...action.payload,
      columnsSettings: getState().ui.codeGridsColumns,
      isShowingPdxAnalysis: getState().ui.isShowingPdxAnalysis,
      fieldSettings: getState().facilitySettings.fieldSettings,
      facilityPreferences: getState().facilitySettings.preferences,
      inpatientEncounter: getState().encounter.inProgress.ValidationResult?.IsInpatient,
    }
    dispatch(codeGridKeypressAction(payload));
    done();
  },
});

export const ignoreActionInIncorrectGrid = createLogic({
  type: [
    actionTypes.CODEBOOKS_MODAL_CODE_POSTED,
    actionTypes.CODEBOOKS_MODAL_CANCELLED,
    actionTypes.CODE_GRID_BLUR_FIELD,
    actionTypes.CODE_GRID_KEYPRESS,
    actionTypes.CODE_GRID_EXIT_EDIT,
    actionTypes.CODE_GRID_CANCEL_EDIT,
    actionTypes.CODE_GRID_DRAG_DROP_ROW,
    actionTypes.INSERT_ROW_BEFORE,
    actionTypes.INSERT_ROW_AFTER,
    actionTypes.CLEAR_EMPTY_ROWS_IN_GRID,
    actionTypes.RESEARCH_EDIT_LOCATE_EVENT,
  ],
  latest: true, // take latest only

  validate({ getState, action }: LogicLifecycleParams, allow: any, reject: any) {
    // ignore grid action when we have incorrect date or provider
    const { inProgress } = getState().encounter;
    if (gridsHaveIncorrectFields(inProgress, getState().choiceLists.providers)) {
      reject();
      return;
    }

    allow(action);
  },
});

// multiple logic groups can be exported here
export default [
  codeBlurLogic,
  codeChangeLogic,
  codeExitEditLogic,
  codeEnterEditLogic,
  codeKeypressLogic,
  ignoreActionInIncorrectGrid,
];
