/* eslint-disable max-classes-per-file */
/* eslint-disable react/prefer-stateless-function */
import './Input.less';

import * as React from 'react';
import { v4 as uuid } from 'uuid';

import { Input as KInput, InputProps as KInputProps, InputChangeEvent } from '@progress/kendo-react-inputs';
import { ErrorWrap } from '../ErrorWrap/ErrorWrap';
import { ValidationErrors } from '../../models/encounterErrors';
import { getPastedValue } from '../../utils/paste';
import { Key } from '../../constants/keyboard';

export interface InputProps extends Omit<KInputProps, 'onChange' | 'pattern' | 'onBlur'> { // extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  name: string;
  inputClass?: string;
  value?: string | number;
  disabled?: boolean;
  maxLength?: number;
  label: string;
  error?: ValidationErrors; // -> error
  pattern?: RegExp;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  // special complex validation / correction
  onBeforeChange?: (value: string) => { valid: boolean, value: string };
  onChange?: (fieldName: string, value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onCorrectValueBeforeBlur?: (value: string) => string;
  onBlur?: (fieldName: string, value: string, e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  click?: (e: React.MouseEvent<HTMLElement>) => void;
  // special complex validation / correction
  onBeforePaste?: (pastedText: string) => string;
  minLength?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: (ref: HTMLInputElement | null) => void;
  title?: string;
  dataTitle?: string;
  readOnly?: boolean;
  onlyComponent?: boolean;  // do not wrap with ErrorWrap. We have usual Inputs on some pages
  blurOnEnter?: boolean;    // send blur after Enter (it is necessary for Encounters searching)
}

export interface InputState {
  value: string;
  propsValue?: string;  // for comparison with previous props
  isFocused?: boolean;
}

export class Input extends React.PureComponent<InputProps, InputState> {
  static defaultProps = {
    content: '',
    editMode: false,
    error: '',
    inputClass: '',
  };

  el: HTMLInputElement;
  valueWasPasted: boolean;

  constructor(opts: InputProps) {
    super(opts);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onWrapClick = this.onWrapClick.bind(this);
    this.onPasteInput = this.onPasteInput.bind(this);
    this.onKeyDownInput = this.onKeyDownInput.bind(this);

    this.valueWasPasted = false;
    this.state = { value: opts.value ? opts.value.toString() : '' };
  }

  onChangeInput(e: InputChangeEvent) {
    let val = e.target.value as string;

    if (this.valueWasPasted) {
      this.valueWasPasted = false;

      const value = this.props.onBeforePaste
        ? this.props.onBeforePaste(val)
        : getPastedValue(val, this.props.maxLength, this.props.pattern);

      this.setState({ value }, () => {
        if (this.props.onChange) { this.props.onChange(this.props.name, value, e.syntheticEvent as React.ChangeEvent<HTMLInputElement>); }
      });

      return;
    }

    if (val && this.props.pattern && !val.match(this.props.pattern)) {
      return;
    }
    if (this.props.maxLength && val && val.length > this.props.maxLength) {
      return;
    }
    if (this.props.onBeforeChange) {
      const { valid, value } = this.props.onBeforeChange(val || '');
      if (!valid) {
        return;
      }
      val = value;
    }
    this.setState({ value: val }, () => {
      if (this.props.onChange) { this.props.onChange(this.props.name, val, e.syntheticEvent as React.ChangeEvent<HTMLInputElement>); }
    });
  }

  onBlurInput(e: React.FocusEvent<HTMLInputElement>) {
    e.target.classList.remove('tc-state-focused');
    let { value } = e.target;
    if (this.props.onCorrectValueBeforeBlur) {
      value = this.props.onCorrectValueBeforeBlur(value);
    }

    // update value in state to corrected
    this.setState({
      value,
      isFocused: false,
    })

    if (this.props.onBlur) {
      this.props.onBlur(this.props.name, value, e);
    }
  }

  onFocusInput = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.classList.add('tc-state-focused');

    if (!this.state.isFocused) {
      this.setState({ isFocused: true })
    }

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  private onPasteInput = (/* e: React.ClipboardEvent<HTMLInputElement> */) => {
    this.valueWasPasted = true;
  }

  onKeyDownInput(e: React.KeyboardEvent<HTMLInputElement>) {
    // update value in the parent component after keyDown with Enter
    if (this.props.blurOnEnter && e.key === Key.Enter && this.props.onBlur) {
      let { value } = this.state;
      if (this.props.onCorrectValueBeforeBlur) {
        value = this.props.onCorrectValueBeforeBlur(value);
      }

      this.props.onBlur(this.props.name, value);
    }

    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  onClick(e: React.MouseEvent<HTMLAnchorElement>) {
    if (!this.props.disabled && this.props.click) { this.props.click(e); }
  }

  // e: React.MouseEvent<HTMLSpanElement>
  onWrapClick(e: React.MouseEvent<HTMLSpanElement>) {
    if (!this.props.disabled) { e.currentTarget.focus(); }
  }

  static getDerivedStateFromProps(nextProps: InputProps, prevState: InputState) {
    if (nextProps.value !== prevState.propsValue) {
      return {
        propsValue: nextProps.value,
        value: nextProps.value ? nextProps.value.toString() : ''
      };
    }

    return null;
  }

  render() {
    const id = this.props.id || `auto-${uuid()}`;
    const placeholder = this.props.placeholder || " ";
    const { error } = this.props;
    // const hasError = typeof error !== 'undefined' && error.length > 0;
    const disabled = typeof this.props.disabled !== 'undefined' && this.props.disabled === true;
    const className = !disabled
      ? `tc-input form-input ${this.props.inputClass} k-input wrapped-input-control ${this.state.value ? ' has-text' : ''}`
      : `tc-input form-input k-state-disabled ${this.props.inputClass} k-input wrapped-input-control ${this.state.value ? ' has-text' : ''}`;
    const floatingLabelInput = (
      // TODO: pass in all props using spread after plucking the props we override?
      // this would allow us to stop manually maintaining parity with kendo props as they evolve
      <KInput
        ref={this.props.inputRef}
        id={id}
        className={className}
        maxLength={this.props.maxLength}
        autoComplete="off"
        data-lpignore="true"
        disabled={disabled}
        onChange={!disabled ? this.onChangeInput : undefined}
        onBlur={!disabled ? this.onBlurInput : undefined}
        onFocus={!disabled ? this.onFocusInput : undefined}
        onKeyDown={!disabled ? this.onKeyDownInput : undefined}
        onPaste={!disabled ? this.onPasteInput : undefined}
        value={this.state.value}
        label={this.props.label}
        minLength={typeof this.props.minLength === 'number' ? this.props.minLength : undefined}
        // valid={!hasError} // adds red outline
        title={this.props.title}
        data-title={this.props.dataTitle}
        readOnly={this.props.readOnly || disabled ? true : undefined}
        placeholder={placeholder}
        required={this.props.required}
        validationMessage={this.props.validationMessage}
      />
    );

    if (this.props.onlyComponent) {
      return floatingLabelInput;
    }

    return (
      <ErrorWrap error={error}>
        {floatingLabelInput}
      </ErrorWrap>
    );
  }
}
