import * as moment from 'moment';
import { addErrorsToDataItemValidations, EncounterEntity, GridCodeType, ValidationErrors } from '../models';
import { BaseCodeRow } from '../models/codeGrid';
import { ValuesRow } from '../models/valuesGrid';
import { CodeValidation } from '../models/encounterServiceEntity';
import { FacilityPreferences } from '../models/facilitySettings';
import { findGroupingResultByViewId, findGroupingSource } from '../services/encounter/encounterMapping';

export function combineEncounterCodes(e: EncounterEntity) {
  return e.diagnoses.codes.concat(e.inProcedures.codes, e.outProcedures.codes, e.visitReasons.codes);
}

export function getCodesByGridType(type: GridCodeType, e: EncounterEntity) {
  switch (type) {
    case GridCodeType.OUTPROCEDURES:
      return e.outProcedures.codes;
    case GridCodeType.DIAGNOSES:
      return e.diagnoses.codes;
    case GridCodeType.INPROCEDURES:
      return e.inProcedures.codes;
    case GridCodeType.VISITREASONS:
      return e.visitReasons.codes;
    default:
      return [];
  }
}

export function setCodesByGridType(type: GridCodeType, e: EncounterEntity, codes: BaseCodeRow[]) {
  switch (type) {
    case GridCodeType.OUTPROCEDURES:
      e.outProcedures.codes = codes;
      break;
    case GridCodeType.DIAGNOSES:
      e.diagnoses.codes = codes;
      break;
    case GridCodeType.INPROCEDURES:
      e.inProcedures.codes = codes;
      break;
    case GridCodeType.VISITREASONS:
      e.visitReasons.codes = codes;
      break;
    default:
      break;
  }
}

export function addErrorsToGridFirstRow(gridData: ValuesRow[] | BaseCodeRow[], errors: ValidationErrors, fieldName: string, proposedLevel = 'Critical') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: any[] = [];
  for (let ind = 0, len = gridData.length; ind < len; ind++) {
    if (ind === 0) {
      const validations = gridData[0].Validations ? [...gridData[0].Validations] : [];
      addErrorsToDataItemValidations(validations, fieldName, errors, proposedLevel);

      data.push({
        ...gridData[ind],
        Validations: validations
      });
    } else {
      data.push(gridData[ind]);
    }
  }

  return data;
}

function resequenceCodeGroup(inProgress: EncounterEntity, gridCodeType: GridCodeType, order?: string[]) {
  const { codes } = inProgress[gridCodeType];
  if (!order || !order.length) {
    return codes;
  }

  const oldCodes = [...codes];
  const newCodes: BaseCodeRow[] = [];
  // place all reordered codes in the necessary order
  for (let ind = 0, len = order.length; ind < len; ind++) {
    let found = false;
    for (let cind = 0, clen = oldCodes.length; cind < clen; cind++) {
      if (oldCodes[cind].id === order[ind]) {
        newCodes.push(oldCodes[cind]);
        oldCodes.splice(cind, 1);
        found = true;
        break;
      }
    }

    if (!found) {
      console.log(`Code with ViewId=${order[ind]} not found in ${gridCodeType}`);
    }
  }

  // place all not found codes
  for (let rind = 0, rlen = oldCodes.length; rind < rlen; rind++) {
    newCodes.push(oldCodes[rind]);
  }

  return newCodes;
}

// https://trucode.atlassian.net/browse/GUI-2900?focusedCommentId=244904
// Use Primary groupings only
//  For OP there should be only OutProceduresOrder, for IP - DiagnosesOrder and InProceduresOrder
export function resequenceCodes(inProgress: EncounterEntity) {
  const primaryIPGrouping = findGroupingSource(true, inProgress);
  const primaryIPResult = primaryIPGrouping ? findGroupingResultByViewId(inProgress, primaryIPGrouping.ViewId) : null;
  const primaryOPGrouping = findGroupingSource(false, inProgress);
  const primaryOPResult = primaryOPGrouping ? findGroupingResultByViewId(inProgress, primaryOPGrouping.ViewId) : null;

  const newDiagnoses = resequenceCodeGroup(inProgress, GridCodeType.DIAGNOSES, primaryIPResult?.DiagnosesOrder);
  const newInProcedures = resequenceCodeGroup(inProgress, GridCodeType.INPROCEDURES, primaryIPResult?.InProceduresOrder);  
  const newOutProcedures = resequenceCodeGroup(inProgress, GridCodeType.OUTPROCEDURES, primaryOPResult?.OutProceduresOrder);


  const newInProgress = {
    ...inProgress,
    diagnoses: {...inProgress.diagnoses, codes: newDiagnoses},
    inProcedures: {...inProgress.inProcedures, codes: newInProcedures},
    outProcedures: {...inProgress.outProcedures, codes: newOutProcedures},
  }

  return newInProgress;
}

export function needResequenceEncounterCodes(inProgress: EncounterEntity) {
  const primaryIPGrouping = findGroupingSource(true, inProgress);
  const primaryIPResult = primaryIPGrouping ? findGroupingResultByViewId(inProgress, primaryIPGrouping.ViewId) : null;
  const primaryOPGrouping = findGroupingSource(false, inProgress);
  const primaryOPResult = primaryOPGrouping ? findGroupingResultByViewId(inProgress, primaryOPGrouping.ViewId) : null;

  if (primaryIPResult?.DiagnosesOrder && primaryIPResult?.DiagnosesOrder.length) {
    return true;
  }
  if (primaryIPResult?.InProceduresOrder && primaryIPResult?.InProceduresOrder.length) {
    return true;
  }
  if (primaryOPResult?.OutProceduresOrder && primaryOPResult?.OutProceduresOrder.length) {
    return true;
  }

  return false;
}

export function getResequenceMode(inProgress: EncounterEntity, preferences?: FacilityPreferences) {
  if (!preferences) {
    return undefined;
  }

  if (inProgress.ValidationResult?.IsInpatient) {
    if (!preferences.InpatientPreferences) {
      return undefined;
    }

    return preferences.InpatientPreferences.AutomaticallyResequence;
  }

  if (!preferences.OutpatientPreferences) {
    return undefined;
  }

  return preferences.OutpatientPreferences.AutomaticallyResequence;
}

export const compareNoCase = (first?: string, second?: string) => {
  return (first || '').toUpperCase() === (second || '').toUpperCase();
}

export const isPdxDisabled = (encounter: EncounterEntity) => {
  const Date20151001 = moment("2015-10-01");
  const encounterBaseDate = encounter.ValidationResult?.BaseDate;
  const encounterIsBefore20151001 = encounterBaseDate && moment(encounterBaseDate).isBefore(Date20151001);

  return (encounter.diagnoses.codes.length < 2
    || encounter.diagnoses.codes.length === 2 && encounter.diagnoses.codes[1].empty
    || encounterIsBefore20151001);
}

export function isEncounterClosedError(errorMessage: string | undefined) : boolean {
  if(errorMessage){
    return errorMessage.match('^Session for.*is not found$') !== null;
  }
  return false;
}

const getEncounterStorageKey = (id: string) => {
  return `TC_ENCOUNTER_LAST_ACCESS_TIME_${id}`;
}

export const updateEncounterLastAccessTime = (id: string) => {
  const key = getEncounterStorageKey(id);
  if (sessionStorage.getItem(key) === "Outdated") {
    return;
  }

  const now = moment().format('YYYY-MM-DD HH:mm:ss');
  sessionStorage.setItem(key, now);
}

const getEncounterLastAccessTime = (id: string) => {
  const key = getEncounterStorageKey(id);
  return sessionStorage.getItem(key);
}

export const isEncounterOutdated = (id: string) => {
  const last = getEncounterLastAccessTime(id);
  if (!last || last === "Outdated") {
    return true;
  } 
  const lastTime = moment(last);

  const diff = moment().diff(lastTime, "minutes");
  return diff > 1;
}

export const markEncounterOutdated = (id: string) => {
  const key = getEncounterStorageKey(id);
  sessionStorage.setItem(key, "Outdated");
}

export const clearEncounterLastAccessTime = (id: string) => {
  const key = getEncounterStorageKey(id);
  sessionStorage.setItem(key, "");
}