import './Grid.less';

import * as React from 'react';
import { GridToolbar as KGridToolbar, GridToolbarProps as KGridToolbarProps } from "@progress/kendo-react-grid";

export class GridToolbar extends React.PureComponent<KGridToolbarProps> {
  public static displayName = 'KendoReactGridToolbar';

  render() {
    return <KGridToolbar
      {...this.props}
    />;
  }
}

export interface GridToolbarProps extends KGridToolbarProps { };

