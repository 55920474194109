import * as actionTypes from '../constants';

export const updateUserActivityAction = (lastActivity: string) => (dispatch) => {
  dispatch({
    type: actionTypes.USER_ACTIVITY_UPDATE_LAST_ACTIVITY,
    payload: lastActivity
  });
};

export const updateTimeoutDialogAction = (showTimeoutDialog: boolean, shownTimeout: number, startTime?: string) => (dispatch) => {
  dispatch({
    type: actionTypes.USER_ACTIVITY_UPDATE_TIMEOUT_DIALOG,
    payload: { showTimeoutDialog, shownTimeout, startTime }
  });
};

