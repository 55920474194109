import * as actionTypes from '../constants';
import { GetPolicyArticleRequestEntity, PolicyArticleEntity, PolicyArticleState } from '../models';

const createEmptyPolicyArticle = (): PolicyArticleState => ({
  policyArticleId: '',
  policyArticleHTML: '',
  policyArticleLoading: false,

  policyArticles: {}
});

export const getPolicyArticleReducer = (state = createEmptyPolicyArticle(), action) => {
  switch (action.type) {
    case actionTypes.GET_POLICY_ARTICLE_START:
      return handleGetPolicyArticleStart(state, action.payload);
    case actionTypes.GET_POLICY_ARTICLE_COMPLETED:
      return handleGetPolicyArticleCompleted(state, action.payload);
    case actionTypes.GET_POLICY_ARTICLE_ERROR:
      return handleGetPolicyArticleError(state, action.payload);
    case actionTypes.SELECT_POLICY_ARTICLE:
      return handleSelectPolicyArticle(state, action.payload);
    default:
      return state;
  }
};

// we set this article as active during getpolicyArticle
const handleGetPolicyArticleStart = (state: PolicyArticleState, payload: GetPolicyArticleRequestEntity) => {
  return {
    ...state,
    articles: {
      ...state.policyArticles,
      [payload.id]: {
        loading: true,
        request: payload,
        response: null,
      }
    },
    policyArticleId: payload.id,
    policyArticleLoading: true
  };
};

const handleGetPolicyArticleCompleted = (state: PolicyArticleState, payload: PolicyArticleEntity) => {
  return {
    ...state,
    articles: {
      ...state.policyArticles,
      [payload.id]: {
        loading: false,
        request: state[payload.id].request,
        response: payload
      }
    },
    // can be completed any article not only active one
    policyArticleLoading: payload.id === state.policyArticleId ? false : state.policyArticleLoading,
    policyArticleHTML: payload.id === state.policyArticleId ? payload.html : state.policyArticleHTML
  };
};

const handleGetPolicyArticleError = (state: PolicyArticleState, payload: PolicyArticleEntity) => {
  return {
    ...state,
    articles: {
      ...state.policyArticles,
      [payload.id]: {
        loading: false,
        request: state[payload.id].request,
        response: payload
      }
    },
    articleLoading: payload.id === state.policyArticleId ? false : state.policyArticleLoading,
    articleHTML: payload.id === state.policyArticleId ? payload.html : state.policyArticleHTML
  };
};

const handleSelectPolicyArticle = (state: PolicyArticleState, id?: string) => {
  if (!id) {
    return {
      ...state,
      policyArticleId: '',
      policyArticleLoading: false,
      policyArticleHTML: ''
    }
  }

  const article = state.policyArticles[id];

  return {
    ...state,
    policyArticleId: id,
    policyArticleLoading: article && article.loading,
    policyArticleHTML: article && article.response && article.response.html
  };
};

