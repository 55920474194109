import './Drawer.less';

import * as React from 'react';
import { Drawer as KDrawer, DrawerProps as KDrawerProps, DrawerContent, DrawerItemProps } from '@progress/kendo-react-layout';
import { IconType } from '../Icon/Icon';
import { Button, ButtonMode } from '..';

const CustomItem = (props: DrawerItemProps) => {
  return (
    <div className="drawer-wrap" id={props.children?.id}>
      <h1>{props.children?.headingText || ''}</h1>
      <Button
        mode={ButtonMode.Transparent}
        className="drawer-button-hidden"
        id='hidden-button'
      />
      <Button
        iconType={IconType.CancelMark}
        mode={ButtonMode.Transparent}
        className="drawer-close"
        onClick={props.children?.close}
        id='drawer-close-button'
        // tabIndex={0}
      />
      <div className="contents">{props.children?.content || null}</div>
    </div>
  );
};

interface DrawerProps extends KDrawerProps {
  content: () => {
    headingText: string,
    content: JSX.Element,
    close?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    id: string
  } | null;
}

export class Drawer extends React.Component<DrawerProps> {
  drawerRef: React.RefObject<HTMLDivElement>;

  constructor(props: DrawerProps) {
    super(props);
    this.drawerRef = React.createRef();
  }

  componentDidMount() {
    this.drawerRef?.current?.querySelector('.k-drawer-items')?.removeAttribute('title');
  }

  render() {
    const content = this.props.content();
    // share the overlayclick handler for the close button
    if (content && this.props.onOverlayClick) {
      content.close = this.props.onOverlayClick;
    }
    return (
      <div className="tc-drawer-wrapper" ref={this.drawerRef}>
        <KDrawer mode="overlay" className="tc-drawer" item={CustomItem} items={[{ children: content }]} {...this.props}>
          <DrawerContent>
            {this.props.children}
          </DrawerContent>
        </KDrawer>
      </div>
    );
  }
}
