import * as actionTypes from '../constants';
import { ArticleEntity, ArticleState, GetArticleRequestEntity } from '../models';

const createEmptyArticle = (): ArticleState => ({
  articleId: '',
  articleHTML: '',
  articleLoading: false,

  articles: {}
});

export const getArticleReducer = (state = createEmptyArticle(), action) => {
  switch (action.type) {
    case actionTypes.GET_ARTICLE_START:
      return handleGetArticleStart(state, action.payload);
    case actionTypes.GET_ARTICLE_COMPLETED:
      return handleGetArticleCompleted(state, action.payload);
    case actionTypes.GET_ARTICLE_ERROR:
      return handleGetArticleError(state, action.payload);
    case actionTypes.SELECT_ARTICLE:
      return handleSelectArticle(state, action.payload);
    default:
      return state;
  }
};

// we set this article as active during getArticle
const handleGetArticleStart = (state: ArticleState, payload: GetArticleRequestEntity) => {
  return {
    ...state,
    articles: {
      ...state.articles,
      [payload.id]: {
        loading: true,
        request: payload,
        response: null,
      }
    },
    articleId: payload.id,
    articleLoading: true
  };
};

const handleGetArticleCompleted = (state: ArticleState, payload: ArticleEntity) => {
  return {
    ...state,
    articles: {
      ...state.articles,
      [payload.id]: {
        loading: false,
        request: state[payload.id].request,
        response: payload
      }
    },
    // can be completed any article not only active one
    articleLoading: payload.id === state.articleId ? false : state.articleLoading,
    articleHTML: payload.id === state.articleId ? payload.html : state.articleHTML
  };
};

const handleGetArticleError = (state: ArticleState, payload: ArticleEntity) => {
  return {
    ...state,
    articles: {
      ...state.articles,
      [payload.id]: {
        loading: false,
        request: state[payload.id].request,
        response: payload
      }
    },
    articleLoading: payload.id === state.articleId ? false : state.articleLoading,
    articleHTML: payload.id === state.articleId ? payload.html : state.articleHTML
  };
};

const handleSelectArticle = (state: ArticleState, id?: string) => {
  if (!id) {
    return {
      ...state,
      articleId: '',
      articleLoading: false,
      articleHTML: ''
    }
  }

  const article = state.articles[id];

  return {
    ...state,
    articleId: id,
    articleLoading: article && article.loading,
    articleHTML: article && article.response && article.response.html
  };
};

