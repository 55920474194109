import { connect } from 'react-redux';
import { State } from '../../reducers';
import { snapshotEncounterAction } from '../../scenes/Encounter/actions/snapshotEncounter';
import { Debug } from './Debug';

const mapStateToProps = (state: State) => ({
  b2c: state.b2c,
  lastActivity: state.userActivity.lastActivity,
  activities: state.netActivity.activities,
  id: state.encounter.inProgress.id,
  snapshot: state.encounterSnapshot,
});

const mapDispatchToProps = (dispatch) => ({
  snapshotEncounter: (id: string) => dispatch(snapshotEncounterAction(id)),
});

export const DebugContainer = connect(mapStateToProps, mapDispatchToProps)(Debug);
