import { ChoiceListItem } from "../../FinancialClass/models/financialClass";

export const PREFERENCES_TYPE_GENERAL = 0;
export const PREFERENCES_TYPE_INPATIENT = 1;
export const PREFERENCES_TYPE_OUTPATIENT = 2;


export const mapPreferencesTypetoString = (preferencesType: number) => {
  switch(preferencesType) {
    case PREFERENCES_TYPE_INPATIENT:
      return 'InPatientPreferences';
    case PREFERENCES_TYPE_OUTPATIENT:
      return 'OutPatientPreferences';
    case PREFERENCES_TYPE_GENERAL:
      return 'GeneralPreferences';
    default:
  }

  throw new Error('Unsupported Preferences Type');
}

export interface GeneralPreferences {
  Version?: string;

  SaveCopyOfInterfacedRecordsToTrucodeDatabase?: boolean;
  ShowAllCodeGridsOnASingleTabByEncounterType?: boolean;
  OpenRecordToCodingTab?: boolean;
  UseSequestrationReduction?: boolean;
}

export interface InPatientPreferences {
  Version?: string;

  EnableECodeEdits?: boolean;
  EnableECodePlaceOfOccurrenceEdits?: boolean;
  WarnOfDuplicateCmProcedureCodes?: boolean;
  AllowInvalidModifiers?: boolean;
  ResequenceAllCodes?: boolean;
  AutomaticallyResequence?: boolean;
  AllowHcpcsProcedures?: boolean;
  VerifyProviderInChoiceList?: boolean;
  PromptForNewbornWeight?: boolean;
  PrintAttestationOnForm?: boolean;
  PrintDollarAmountsOnForm?: boolean;
  ModifyAttestationWording?: boolean;
  PrintProviderSignatureLineAndDateOnForm?: boolean;
  PrintMemo?: boolean;
  ModifiedAttestationWordingText?: string | null;
  PrintElectiveFieldsOnForm?: boolean;

  AutofillPOAForExemptDiagnosisCodes?: boolean;
  ExemptFromHospitalAcquiredConditionLogic?: boolean;
  PrintMemoOnDRGChangeReport?: boolean;
  DaysAProcedureDateMayPrecedeAdmitDate?: string;
  MissingPOAErrorLevel?: string;

  // not used fields
  NumberOfDiagnosisCodesToResequence?: number;
  NumberOfProcedureCodesToResequence?: number;
  UseCptAnesthesiaCodesInEncoderSearch?: boolean;
}

export interface OutPatientPreferences {
  Version?: string;

  EnableECodeEdits?: boolean;
  EnableECodePlaceOfOccurrenceEdits?: boolean;
  WarnOfDuplicateCmProcedureCodes?: boolean;
  AllowInvalidModifiers?: boolean;
  EnableMedicalNecessityFrequencyEdits?: boolean;
  EnableMedicalNecessityArticlePolicies?: boolean;
  AutomaticallyResequence?: boolean;
  CalculateDrgs?: boolean;
  AllowIcdProcedures?: boolean;
  VerifyProviderInChoiceList?: boolean;
  PromptForNewbornWeight?: boolean;
  PrintAttestationOnForm?: boolean;
  PrintDollarAmountsOnForm?: boolean;
  ModifyAttestationWording?: boolean;
  PrintProviderSignatureLineAndDateOnForm?: boolean;
  PrintMemo?: boolean;
  ModifiedAttestationWordingText?: string | null;
  PrintElectiveFieldsOnForm?: boolean;
  DefaultBillType?: string;
  DefaultRevenueCode?: string | null;
  AutoFillProviderInChoiceList?: boolean;
  CalculateApcForAllClaimDispositions?: boolean;
  IncludeAllFeeSchedulePricing?: boolean;
  PrintMemoOnAPCChangeReport?: boolean;

  // not used fields
  UseCptAnesthesiaCodesInEncoderSearch?: boolean;
}

export interface Preferences {
  inPatient: InPatientPreferences;
  outPatient: OutPatientPreferences;
}

export const MISSING_POA_DDL: ChoiceListItem[] = [
  { id: "Ignore", text: 'Ignore'},
  { id: "Warn", text: 'Warn' },
  { id: "Error", text: 'Error' },
];

export const DEFAULT_ATTESTATION_WORDING_TEXT = 'I certify that the narrative descriptions of the principal and secondary diagnoses and major procedures performed are accurate and complete to the best of my knowledge.';