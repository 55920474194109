import * as actionTypes from '../../../constants';
import { ErrorDetails } from '../../../models';
import { EncounterSnapshotEntity } from '../../../models/encounterEntity';
import { encounterAPI } from '../../../services/encounter';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';

export const snapshotEncounterAction = (encounterId: string) => (
  dispatch
) => {
  dispatch(snapshotEncounterBegin(encounterId));

  return encounterAPI
    .snapshotEncounter(encounterId)
    .then((encounterEntity) => {
      dispatch(snapshotEncounterCompleted(encounterEntity));
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Cannot Snapshot Encounter');
      dispatch(snapshotEncounterError(errors));
    });
};

const snapshotEncounterBegin = (id: string) => ({
  payload: id,
  type: actionTypes.SNAPSHOT_ENCOUNTER_BEGIN,
});

const snapshotEncounterCompleted = (snapshot: EncounterSnapshotEntity) => ({
  payload: snapshot,
  type: actionTypes.SNAPSHOT_ENCOUNTER_COMPLETED,
});

const snapshotEncounterError = (errors: ErrorDetails[]) => ({
  payload: errors,
  type: actionTypes.SNAPSHOT_ENCOUNTER_ERROR,
});
