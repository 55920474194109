import * as moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';
import { EncounterEntity } from '../../../models/encounterEntity';
import { EncounterValidation, MISSING_DIAGNOSIS_STRING, MISSING_ADMIT_DX_DIAGNOSIS_STRING } from '../../../models/encounterErrors';

import { toFormattedDate } from '../../../utils/date';

import {
  isDateValidator,
  isRequiredFieldValidator,
  longTextValidator,
  patternValidator
} from '../../../validators/simpleValidators';

import calculateLOS from '../utils/calculateLOS';
import { isIncorrectWeight, parseWeight  } from '../utils/calculateWeight';

import { correctAgeInYearsField, validateAgeInYearsField } from '../utils/calculateAge';
import { FacilitySettingsState } from '../../../models/facilitySettings';
import { ChoiceListsState, IdDescriptionBase } from '../../../models/patientEncounter';
import { isRequiredField } from '../../../utils/fieldSettings';
import { checkFieldForCollection, findComboValueById } from '../../../utils/ddl';
import { ValuesRow } from '../../../models/valuesGrid';
import { checkDDLFieldLength, MAX_FINANCIAL_CLASS_LENGTH, MAX_PROVIDER_LENGTH } from '../../../services/encounter/encounterMapping';
import { gridsHaveIncorrectSpecificFields } from '../../../reducers/Helpers/encounterCodeGrid';

// client side validations without required checking
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function validateEncounter(encounter: EncounterEntity, facilitySettings: FacilitySettingsState, choiceLists: ChoiceListsState): EncounterValidation {
  // check coderows for critical errors
  const gridsHaveDateErrors = gridsHaveIncorrectSpecificFields(encounter, choiceLists.providers, 'date');
  const gridsHaveProviderErrors = gridsHaveIncorrectSpecificFields(encounter, choiceLists.providers, 'provider');
  let gridsError = gridsHaveDateErrors ? 'Codes have incorrect dates' : '';
  if (gridsHaveProviderErrors && !gridsHaveDateErrors) {
    gridsError = 'The value for Providers is too long for some code rows';
  }

  const criticalError = checkCriticalError(encounter, choiceLists) || gridsHaveDateErrors || gridsHaveProviderErrors;

  return {
    // check length <= 24
    accountNumber: validateAccountNumber(encounter, false),
    admitDate: validateAdmitDate(encounter, false, '', true),
    // check for pattern and special validations
    ageInYears: validateAgeInYears(encounter, false, false, ''),
    dateOfBirth: validateDateOfBirth(encounter, false, '', true),
    dischargeDate: validateDischargeDate(encounter, false, '', true),
    // check length <= 29
    firstName: validateNameField(encounter.firstName, 29, false, ''),
    // check length <= 50
    lastName: validateNameField(encounter.lastName, 50, false, ''),
    // check length <= 24
    medicalRecordNumber: validateMedicalRecordNumber(encounter, false, ''),
    // check length <= 1
    middleInitial: validateNameField(encounter.middleInitial, 1, false, ''),
    diagnoses: '',
    // check for pattern
    birthWeight: validateBirthWeight(encounter, false, ''),
    // only numbers
    overrideLOS: validateOverrideLOS(encounter, false, ''),
    patientStatus: '',
    reviewDate: validateReviewDate(encounter),
    sex: '',
    // only numbers
    totalCodingTime: validateTotalCodingTime(encounter),
    // always required
    type: isRequiredFieldValidator(encounter.type, 'Encounter type cannot be empty'),

    service: '',
    ofa: '',
    // check for the presence in the list or <= MAX_FINANCIAL_CLASS_LENGTH
    financialClass: validateFinancialClass(choiceLists.financialClasses, encounter.financialClass),
    billType: '',
    // check for the presence in the list or <= MAX_PROVIDER_LENGTH
    attendingMd: validateAttendingMd(choiceLists.providers, encounter.attendingMd),
    managedCareFlag: '',
    // nothing
    totalCharges: validateTotalCharges(encounter, false, ''),
    thirdPartyLiability: '',
    recordStatus: '',
    // always required
    facility: isRequiredFieldValidator(encounter.facility, 'Facility cannot be empty'),
    conditionCodes: '',
    valueCodes: '',
    visitReasons: '',
    admitDX: '',
    admitDXMissing: '',
    clientPreventsSave: criticalError || checkPreventSave(encounter, choiceLists),
    clientCriticalError: criticalError,
    gridsError,
  };
}

// client side validations to find all client side critical errors
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function validateEncounterForCriticalErrors(encounter: EncounterEntity, facilitySettings: FacilitySettingsState, choiceLists: ChoiceListsState, checkRanges = false): EncounterValidation {
  return {
    accountNumber: '',
    admitDate: validateAdmitDate(encounter, false, '', checkRanges),
    ageInYears: validateAgeInYears(encounter, false, false, ''),
    dateOfBirth: validateDateOfBirth(encounter, false, '', checkRanges),
    dischargeDate: validateDischargeDate(encounter, false, '', checkRanges),
    firstName: '',
    lastName: '',
    medicalRecordNumber: '',
    middleInitial: '',
    diagnoses: '',
    birthWeight: validateBirthWeight(encounter, false, ''),
    overrideLOS: '',
    patientStatus: '',
    reviewDate: validateReviewDate(encounter, checkRanges),
    sex: '',
    totalCodingTime: '',
    type: '',
    service: '',
    ofa: '',
    financialClass: validateFinancialClass(choiceLists.financialClasses, encounter.financialClass),
    billType: '',
    attendingMd: validateAttendingMd(choiceLists.providers, encounter.attendingMd),
    managedCareFlag: '',
    totalCharges: '',
    thirdPartyLiability: '',
    recordStatus: '',
    facility: '',
    conditionCodes: '',
    valueCodes: '',
    visitReasons: '',
    admitDX: '',
    admitDXMissing: '',
  };
}

// client side validations. Validate required fields on the client side
export function validateEncounterWithRequired(encounter: EncounterEntity, facilitySettings: FacilitySettingsState, choiceLists: ChoiceListsState): EncounterValidation {
  const encounterTypeValue = findComboValueById(choiceLists.encounterTypes, encounter.type);
  const requiredStringEnd = encounter.type && encounterTypeValue ? ` for encounter type "${encounter.type} - ${encounterTypeValue}"` : '';
  const isDOBRequired = isRequiredField(facilitySettings.fieldSettings, "DateOfBirth");
  // check coderows for critical errors
  const gridsHaveDateErrors = gridsHaveIncorrectSpecificFields(encounter, choiceLists.providers, 'date');
  const gridsHaveProviderErrors = gridsHaveIncorrectSpecificFields(encounter, choiceLists.providers, 'provider');
  let gridsError = gridsHaveDateErrors ? 'Codes have incorrect dates' : '';
  if (gridsHaveProviderErrors && !gridsHaveDateErrors) {
    gridsError = 'The Providers value exceeds the maximum allowed limit for some code rows';
  }
  // The Attending MD value exceeds the maximum allowed limit.';
  const criticalError = checkCriticalError(encounter, choiceLists) || gridsHaveDateErrors || gridsHaveProviderErrors;

  return {
    accountNumber: validateAccountNumber(encounter, isRequiredField(facilitySettings.fieldSettings, "AccountNumber")),
    admitDate: validateAdmitDate(encounter, isRequiredField(facilitySettings.fieldSettings, "AdmitDate"), requiredStringEnd, true),
    ageInYears: validateAgeInYears(encounter, isRequiredField(facilitySettings.fieldSettings, "PatientAge"), isDOBRequired, requiredStringEnd),
    dateOfBirth: validateDateOfBirth(encounter, isDOBRequired, requiredStringEnd, true),
    dischargeDate: validateDischargeDate(encounter, isRequiredField(facilitySettings.fieldSettings, "DischargeDate"), requiredStringEnd, true),
    firstName: validateNameField(encounter.firstName, 29, isRequiredField(facilitySettings.fieldSettings, "FirstName"), `Field "First Name" is required${requiredStringEnd}`),
    lastName: validateNameField(encounter.lastName, 50, isRequiredField(facilitySettings.fieldSettings, "LastName"), `Field "Last Name" is required${requiredStringEnd}`),
    medicalRecordNumber: validateMedicalRecordNumber(encounter, isRequiredField(facilitySettings.fieldSettings, "MedicalRecordNumber"), requiredStringEnd),
    middleInitial: validateNameField(encounter.middleInitial, 1, isRequiredField(facilitySettings.fieldSettings, "MiddleInitial"), `Field "Middle Initial" is required${requiredStringEnd}`),
    diagnoses: validatePrincipalDiagnosis(encounter),
    birthWeight: validateBirthWeight(encounter, isRequiredField(facilitySettings.fieldSettings, "BirthWeight"), requiredStringEnd),
    overrideLOS: validateOverrideLOS(encounter, isRequiredField(facilitySettings.fieldSettings, "OverrideLOS"), requiredStringEnd, true),
    patientStatus: validatePatientStatus(choiceLists.patientStatuses, encounter.patientStatus, isRequiredField(facilitySettings.fieldSettings, "PatientStatus"), requiredStringEnd),
    reviewDate: validateReviewDate(encounter, true),
    sex: isRequiredField(facilitySettings.fieldSettings, "PatientSex") ? isRequiredFieldValidator(encounter.sex,
      `Field "Patient Sex" is required${requiredStringEnd}`) : '',
    totalCodingTime: validateTotalCodingTime(encounter),
    // always required
    type: isRequiredFieldValidator(encounter.type, 'Encounter type cannot be empty'),

    service: isRequiredField(facilitySettings.fieldSettings, "Service") ? isRequiredFieldValidator(encounter.service,
      `Field "Service" is required${requiredStringEnd}`) : '',
    ofa: isRequiredField(facilitySettings.fieldSettings, "SourceOfAdmission") ? isRequiredFieldValidator(encounter.ofa,
      `Field "Point of Origin for Admission" is required${requiredStringEnd}`) : '',
    financialClass: validateFinancialClass(choiceLists.financialClasses, encounter.financialClass, isRequiredField(facilitySettings.fieldSettings, "FinancialClass"), requiredStringEnd),
    // do not validate BillType on client side
    // billType: isRequiredField(facilitySettings.fieldSettings, "BillType") ? isRequiredFieldValidator(encounter.billType,
    //  `Field "Bill Type" is required${requiredStringEnd}`) : '',
    attendingMd: validateAttendingMd(choiceLists.providers, encounter.attendingMd, isRequiredField(facilitySettings.fieldSettings, "Provider"), requiredStringEnd),
    managedCareFlag: isRequiredField(facilitySettings.fieldSettings, "PayerFlag") ? isRequiredFieldValidator(encounter.managedCareFlag,
      `Field "Managed Care Flag" is required${requiredStringEnd}`) : '',
    totalCharges: validateTotalCharges(encounter, isRequiredField(facilitySettings.fieldSettings, "TotalCharges"), requiredStringEnd),
    recordStatus: isRequiredField(facilitySettings.fieldSettings, "RecordStatus") ? isRequiredFieldValidator(encounter.recordStatus,
      `Field "Record Status" is required${requiredStringEnd}`) : '',
    // always required
    facility: isRequiredFieldValidator(encounter.facility, 'Facility cannot be empty'),

    conditionCodes: isRequiredField(facilitySettings.fieldSettings, "ConditionCodes") ?
      validateValueCodes(encounter.conditionCodes, 'Condition Codes', requiredStringEnd) : '',
    valueCodes: isRequiredField(facilitySettings.fieldSettings, "ValueCodes") ?
      validateValueCodes(encounter.valueCodes, 'Value Codes', requiredStringEnd) : '',
    visitReasons: isRequiredField(facilitySettings.fieldSettings, "VisitReasons") ?
      validateVisitReasons(encounter, requiredStringEnd) : '',
    admitDX: validateAdmitDX(encounter, isRequiredField(facilitySettings.fieldSettings, "AdmitDX"), requiredStringEnd),
    admitDXMissing: validateAdmitDXMissing(encounter),
    clientPreventsSave: criticalError || checkPreventSave(encounter, choiceLists),
    clientCriticalError: criticalError,
    gridsError,
  };
}

function validateAccountNumber(encounter: EncounterEntity, checkRequired: boolean): string {
  const value = encounter.accountNumber;

  if (checkRequired) {
    const isRequiredValidatorResult = isRequiredFieldValidator(value, 'Missing Account Number');
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  const longTextResult = longTextValidator(value, 24);
  if (longTextResult) {
    return longTextResult;
  }

  return '';
}

function validateNameField(value: string | undefined, maxLength: number, checkRequired: boolean, requiredString: string): string {
  if (checkRequired) {
    const isRequiredValidatorResult = isRequiredFieldValidator(value, requiredString);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  const longTextResult = longTextValidator(value, maxLength);
  if (longTextResult) {
    return longTextResult;
  }

  return '';
}

function validateAgeInYears(encounter: EncounterEntity, checkRequired: boolean, isDOBRequired: boolean, requiredStringEnd: string): string {
  const {ageInYears} = encounter;
  const {dateOfBirth} = encounter;

  // if DOB is required - validation is shown for the DOB
  if (checkRequired && !isDOBRequired) {
    const succeeded = !!(ageInYears || dateOfBirth);
    if (!succeeded) {
      return `Field "Patient Age" is required${requiredStringEnd}`;
    }
  }

  return validateAgeInYearsField(ageInYears);
}

function validateDateOfBirth(encounter: EncounterEntity, checkRequired: boolean, requiredStringEnd: string, checkRanges = false): string {
  const value = encounter.dateOfBirth;

  if (checkRequired) {
    const isRequiredValidatorResult = isRequiredFieldValidator(value, `Field "Date of Birth" is required${requiredStringEnd}`);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  const isDateValidatorResult = isDateValidator(value, 'Invalid date of birth', true);
  if (isDateValidatorResult) {
    return isDateValidatorResult;
  }

  const date = toFormattedDate(value);
  if (!date) {
    return '';
  }

  /*
  moved to isInvalidFormattedDate

  const dateOfBirth = moment(date);
  const differenceY = moment(moment.now()).diff(dateOfBirth, 'years');

  if (differenceY > 124) {
    return 'Age can\'t be more than 124 years';
  }
  */

  // check DOB <= current and DOB <= AD and DOB <= DD
  if (!checkRanges) {
    return '';
  }

  if (moment().isBefore(date)) {
    return 'Invalid date of birth';
  }

  const admitDate = toFormattedDate(encounter.admitDate);
  if (admitDate) {
    if (date > admitDate) {
      return 'Admit and/or discharge date precedes date of birth.';
    }
  }

  const dischargeDate = toFormattedDate(encounter.dischargeDate);
  if (dischargeDate) {
    if (date > dischargeDate) {
      return 'Admit and/or discharge date precedes date of birth.';
    }
  }

  return '';
}

function validateAdmitDate(encounter: EncounterEntity, checkRequired: boolean, requiredStringEnd: string, checkRanges = false): string {
  const value = encounter.admitDate;

  if (checkRequired) {
    const isRequiredValidatorResult = isRequiredFieldValidator(value, `Field "Admit Date" is required${requiredStringEnd}`);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  const isDateValidatorResult = isDateValidator(value, 'Incorrect Admission Date');
  if (isDateValidatorResult) {
    return isDateValidatorResult;
  }

  // check AD <= current and AD >= DOB
  if (!checkRanges) {
    return '';
  }

  const date = toFormattedDate(value);
  if (!date) {
    return '';
  }

  if (moment().isBefore(date)) {
    return 'Incorrect Admission Date';
  }

  const dateOfBirth = toFormattedDate(encounter.dateOfBirth);
  if (dateOfBirth) {
    if (date < dateOfBirth) {
      return 'Admit and/or discharge date precedes date of birth.';
    }
  }

  return '';
}

function validateDischargeDate(encounter: EncounterEntity, checkRequired: boolean, requiredStringEnd: string, checkRanges = false): string {
  const value = encounter.dischargeDate;

  if (checkRequired) {
    const isRequiredValidatorResult = isRequiredFieldValidator(value, `Field "Discharge Date" is required${requiredStringEnd}`);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  const isDateValidatorResult = isDateValidator(value, 'Incorrect Discharge Date');
  if (isDateValidatorResult) {
    return isDateValidatorResult;
  }

  const date = toFormattedDate(value);
  if (!date) {
    return '';
  }

  // check DD <= current and AD <= DD and DOB <= DD
  if (!checkRanges) {
    return '';
  }

  if (moment().isBefore(date)) {
    return 'Incorrect Discharge Date';
  }

  const dateOfBirth = toFormattedDate(encounter.dateOfBirth);
  if (dateOfBirth) {
    if (date < dateOfBirth) {
      return 'Admit and/or discharge date precedes date of birth.';
    }
  }

  const admitDate = toFormattedDate(encounter.admitDate);
  if (admitDate) {
    if (date < admitDate) {
      return 'Discharge date before admit date';
    }
  }

  return '';
}

function validateReviewDate(encounter: EncounterEntity, checkRanges = false): string {
  const value = encounter.reviewDate;

  // can be future date
  const isDateValidatorResult = isDateValidator(value);
  if (isDateValidatorResult) {
    return isDateValidatorResult;
  }

  // check RD >= AD
  if (!checkRanges) {
    return '';
  }

  const date = toFormattedDate(value);
  if (!date) {
    return '';
  }

  const admitDate = toFormattedDate(encounter.admitDate);
  if (admitDate) {
    if (date < admitDate) {
      return 'The review date must be on or after the Admit date';
    }
  } else {
    return 'Please specify an admit date prior to specifying a review date';
  }

  return '';
}

function validateMedicalRecordNumber(encounter: EncounterEntity, checkRequired: boolean, requiredStringEnd: string): string {
  const value = encounter.medicalRecordNumber;

  if (checkRequired) {
    const isRequiredValidatorResult =
          isRequiredFieldValidator(value, `Field "Medical Record Number" is required${requiredStringEnd}`);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  const longTextResult = longTextValidator(value, 24);
  if (longTextResult) {
    return longTextResult;
  }

  return '';
}

function validateBirthWeight(encounter: EncounterEntity, checkRequired: boolean, requiredStringEnd: string): string {
  const value = encounter.birthWeight;

  if (checkRequired) {
    const isRequiredValidatorResult =
          isRequiredFieldValidator(value, `Field "Birth Weight" is required${requiredStringEnd}`);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  if (!value) {
    return '';
  }

  const checkValue = parseWeight(`${value}`);

  if (isIncorrectWeight(checkValue)) {
    return checkValue;
  }

  return '';
}

function validateOverrideLOS(encounter: EncounterEntity, checkRequired: boolean, requiredStringEnd: string, checkRanges = false): string {
  const value = encounter.overrideLOS;

  if (checkRequired) {
    if (value === undefined || value as unknown as string === '') {
      return `Field "Override LOS" is required${requiredStringEnd}`;
    }
  }

  if (!value) {
    return '';
  }

  const pattern = '^[0-9]{0,5}$';
  const patternValidatorResult = patternValidator(`${value}`, pattern);
  if (patternValidatorResult) {
    return patternValidatorResult;
  }

  const los = calculateLOS(encounter);

  if (checkRanges) {
    const succeeded = !!((!value || (Number(value) <= los)));
    if (!succeeded) {
      return 'Override LOS cannot be greater than encounter LOS';
    }
  }

  return '';
}

function validateTotalCharges(encounter: EncounterEntity, checkRequired: boolean, requiredStringEnd: string): string {
  const value = encounter.totalCharges;

  if (checkRequired) {
    if (value === undefined || value as unknown as string === '') {
      return `Field "Total Charges" is required${requiredStringEnd}`;
    }
  }

  return '';
}

function validateTotalCodingTime(encounter: EncounterEntity): string {
  const value = encounter.totalCodingTime;

  if (!value) {
    return '';
  }

  const pattern = '^[0-9]{0,5}$';
  const patternValidatorResult = patternValidator(`${value}`, pattern);
  if (patternValidatorResult) {
    return patternValidatorResult;
  }

  return '';
}

function validatePrincipalDiagnosis(encounter: EncounterEntity): string {
  const {codes} = encounter.diagnoses;

  for (let ind = 0, len = codes.length; ind < len; ind++) {
    const item = codes[ind];

    if (item.code) {
      return '';
    }
  }

  return MISSING_DIAGNOSIS_STRING;
}

function validateValueCodes(codes: ValuesRow[], field: string, requiredStringEnd: string): string {
  if (codes.length === 0 || codes[0].empty) {
    return `Field ${field} is required${requiredStringEnd}`;
  }
  return '';
}

function validateVisitReasons(encounter: EncounterEntity, requiredStringEnd: string): string {
  if (encounter.visitReasons.codes.length === 0 || (encounter.visitReasons.codes.length === 1 && encounter.visitReasons.codes[0].empty)) {
    return `Field "Visit Reasons" is required${requiredStringEnd}`;
  }

  return '';
}

function validateAdmitDX(encounter: EncounterEntity, checkRequired: boolean, requiredStringEnd: string): string {
  if (checkRequired) {
    if (!encounter.admitDiagnosisCode.code) {
      return `Field "Admit DX" is required${requiredStringEnd}`;
    }
  }

  return '';
}

function validateAdmitDXMissing(encounter: EncounterEntity): string {
  if (encounter.ValidationResult && !encounter.ValidationResult?.IsInpatient && !encounter.admitDiagnosisCode.code) {
    if (encounter.visitReasons.codes.length === 0 || (encounter.visitReasons.codes.length === 1 && !encounter.visitReasons.codes[0].code)) {
      return MISSING_ADMIT_DX_DIAGNOSIS_STRING;
    }
  }

  return '';
}

function validateFinancialClass(choiceList: IdDescriptionBase[], value?: string, checkRequired?: boolean, requiredStringEnd?: string): string {
  if (checkRequired) {
    const isRequiredValidatorResult = isRequiredFieldValidator(value, `Field "Financial Class" is required${requiredStringEnd}`);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  if (value && !checkDDLFieldLength(choiceList, MAX_FINANCIAL_CLASS_LENGTH, value)) {
    return 'The Financial Class value exceeds the maximum allowed limit.';
  }

  return '';
}

function validateAttendingMd(choiceList: IdDescriptionBase[], value?: string, checkRequired?: boolean, requiredStringEnd?: string): string {
  if (checkRequired) {
    const isRequiredValidatorResult = isRequiredFieldValidator(value, `Field "Attending MD" is required${requiredStringEnd}`);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  if (value && !checkDDLFieldLength(choiceList, MAX_PROVIDER_LENGTH, value)) {
    return 'The Attending MD value exceeds the maximum allowed limit.';
  }

  return '';
}

function validatePatientStatus(choiceList: IdDescriptionBase[], value?: string, checkRequired?: boolean, requiredStringEnd?: string): string {
  if (checkRequired) {
    const isRequiredValidatorResult = isRequiredFieldValidator(value, `Field "Patient Status" is required${requiredStringEnd}`);
    if (isRequiredValidatorResult) {
      return isRequiredValidatorResult;
    }
  }

  if (value && !checkFieldForCollection(value, choiceList)) {
    return 'The Patient Status value is incorrect.';
  }

  return '';
}

export function checkDates(dateOfBirth, admitDate, dischargeDate, compareADDD = false): boolean {
  const ad = toFormattedDate(admitDate);
  const dd = toFormattedDate(dischargeDate);

  if (dateOfBirth) {
    const dob = toFormattedDate(dateOfBirth);

    if (dob && ad) {
      if (dob > ad) {
        return true;
      }
    }

    if (dob && dd) {
      if (dob > dd) {
        return true;
      }
    }
  }

  if (compareADDD && ad && dd && ad > dd) {
    return true;
  }

  return false;
}

function checkCriticalError(encounter: EncounterEntity, choiceLists: ChoiceListsState): boolean {
  // not empty and incorrect admitDate
  if (encounter.admitDate && isDateValidator(encounter.admitDate, 'Incorrect Admission Date')) {
    return true;
  }
  // not empty and incorrect dateOfBirth
  if (encounter.dateOfBirth && isDateValidator(encounter.dateOfBirth, 'Invalid date of birth', true)) {
    return true;
  }
  // not empty and incorrect dischargeDate
  if (encounter.dischargeDate && isDateValidator(encounter.dischargeDate, 'Incorrect Discharge Date')) {
    return true;
  }

  // check here ReviewDate after tab enabling

  // not empty and incorrect birthWeight
  if (encounter.birthWeight) {
    const birthWeightValue = parseWeight(encounter.birthWeight || '');
    if (isIncorrectWeight(birthWeightValue)) {
      return true;
    }
  }

  // not empty and incorrect ageInYears
  if (encounter.ageInYears) {
    const ageInYearsValue = correctAgeInYearsField(encounter.ageInYears);
    if (validateAgeInYearsField(ageInYearsValue)) {
      return true;
    }
  }

  // too long Financial Class
  if (encounter.financialClass && !checkDDLFieldLength(choiceLists.financialClasses, MAX_FINANCIAL_CLASS_LENGTH, encounter.financialClass)) {
    return true;
  }

  // too long AttendingMd
  if (encounter.attendingMd && !checkDDLFieldLength(choiceLists.providers, MAX_PROVIDER_LENGTH, encounter.attendingMd)) {
    return true;
  }

  return false;
}

function checkPreventSave(encounter: EncounterEntity, choiceLists: ChoiceListsState): boolean {
  // DOB > AD or DOB > DD or AD > DD
  if (checkDates(encounter.dateOfBirth, encounter.admitDate, encounter.dischargeDate, true)) {
    return true;
  }

  return false;
}

export function validateConditionCodesLength(valuesCodeRow: ValuesRow): ValuesRow {
  const rowWithErrors = cloneDeep(valuesCodeRow);

  if (rowWithErrors.Validations?.length) {
    return rowWithErrors;
  }

  rowWithErrors.Validations = [];
  if (valuesCodeRow.ValueCode || valuesCodeRow.Amount) {
    return valuesCodeRow;
  }

  /*
  client side validation
  if (valuesCodeRow.ConditionCode && valuesCodeRow.ConditionCode.length < 2) {
    rowWithErrors.Validations.push({
      Description: 'Condition Code must be two characters.',
      Field: 'Code',
      Level: 'Critical'
    });
  }
  */

  return rowWithErrors;
}

