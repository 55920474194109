export const getPastedValue = (pastedValue: string, maxLength?: number, pattern?: RegExp) => {
  if (!pastedValue) {
    return '';
  }

  let value = pastedValue;

  if (maxLength && pastedValue.length > maxLength) {
    value = pastedValue.substr(0, maxLength);
  }

  if (pattern && !pattern.test(value)) {
    value = '';
  }

  return value;
}