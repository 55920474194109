import './ErrorPage.less';

import * as React from 'react';
import { AppWrapperContainer } from '../AppWrapper/AppWrapperContainer';
import Page from '../Page/Page';
import PageHeader from '../PageHeader/PageHeader';
import PageContent from '../PageContent/PageContent';
import { ErrorDetails } from '../../models';
import { Button } from '..';
import { State } from '../../reducers';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';

export interface ErrorPageProps {
  // connected props
  globalState: State;
  error?: ErrorDetails;
  // pure props
  customError?: ErrorDetails;
  history: History;
  onTryAgainClick?: () => void;
}

export class ErrorPage extends React.PureComponent<ErrorPageProps> {
  constructor(props: ErrorPageProps) {
    super(props);
    this.tryAgain = this.tryAgain.bind(this);
  }

  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <AppWrapperContainer isErrorMode>
          <Page id="error">
            <PageHeader>Error</PageHeader>
            <PageContent layout="row">
              <div>
                <p>An unexpected error occurred.</p>
                <p>Please reload this page or contact your administrator.</p>
                {this.props.error &&
                  <details style={{ whiteSpace: 'pre-wrap' }}>
                    <summary>Error</summary>
                    {this.props.error.name}
                    <br />
                    {this.props.error.error}
                    {this.props.error.stack}
                  </details>
                }
                {!this.props.error && this.props.customError &&
                  <details style={{ whiteSpace: 'pre-wrap' }}>
                    <summary>Error</summary>
                    {this.props.customError.name}
                    <br />
                    {this.props.customError.error}
                    {this.props.customError.stack}
                  </details>
                }
                <p><Button primary onClick={this.tryAgain}>Reload</Button></p>
              </div>
            </PageContent>
          </Page>
        </AppWrapperContainer>
      </ConnectedRouter>
    );
  }

  tryAgain() {
    if (this.props.onTryAgainClick) {
      this.props.onTryAgainClick();
    } else {
      window.location.reload();
    }
  }
}
