import * as actionTypes from '../constants';
import { EncoderUserListResultEntity } from '../models';
import { IdDescriptionBase } from '../models/patientEncounter';
import { DEFAULT_IDLE_TIME, DEFAULT_CALCULATED_IDLE_TIME, SECOND_TIMEOUT } from '../models/userActivity';
import { InterfaceRegistration } from '../scenes/SystemPreferences/models/interfaceRegistration';
import { AuditCheckBoxValue, AuditExportLogResult, AuditExportLogStatus, AuditItemObject, GeneralPreferenceObject, getDefaultCancelledBehaviorIsSendInboundMessage, getFieldsToPreserve, getInterfaceType, OrganizationObject, OrganizationSecurityOptionsObject, SyncSettingsStatus, SystemPreferencesObject, SystemPreferencesTypeSpecificObject } from '../scenes/SystemPreferences/models/systemPreferences';
import { SystemPreferencesState } from '../scenes/SystemPreferences/models/systemPreferencesState';

const createEmptySystemPreferences = (): SystemPreferencesState => {
  return {
    interfaceTypes: [],
    interfaceType: '',

    fieldsToPreserve: [],
    defaultCancelledBehaviorSendInboundMessage: false,
    EpicCDIFacilityMatchPattern: '',
    EpicCDIFacilityReplacePattern: '',
    EpicCdiDefaultDisposition: '',
    EpicSendPoaAsE: false,
    allSystemPreferences: {
      InterfaceType: '',
      FieldsToPreserve: [],
      EpicCDIFacilityMatchPattern: '',
      EpicCDIFacilityReplacePattern: '',
      EpicCdiDefaultDisposition: '',
      EpicSendPoaAsE: false,
    },
    typeSpecificPreferences: {},

    registeredInterfaces: [],
    showRegisterInterfaceForm: false,
    registerInterfaceLoading: false,
    registerInterfaceSaving: false,
    registerInterfaceLoaded: false,
    registerInterfaceErrorTexts: [],

    loading: false,
    initialized: false,
    saving: false,
    errorTexts: [],
    loadingGeneralPreferences: false,
    loadingOrganizationSettings: false,
    loadingAuditCategoryList: false,
    loadingUserListForAudit: false,
    generalPreferences: {
      IdleTime: DEFAULT_IDLE_TIME
    },
    calculatedIdleTime: DEFAULT_CALCULATED_IDLE_TIME,
    organizationData: undefined,
    organizationName: '',
    testOrganizationName: '',
    testOrganizationEnabled: false,
    isTestOrganization: false,
    testOrganzationCreationSuccessful: false,
    syncTestOrganizationStatus: undefined,
    syncingTestOrganizationSettingsInProgress: false,

    organizationSecurityOptions: {},
    loadingOrganizationSecurityOptions: false,
    organizationSecurityOptionsErrorTexts: [],
    auditDataList: undefined,
    auditUserList: [],
    auditErrorText: undefined,
    auditExportErrorText: undefined,
    auditStartDate: '',
    auditEndDate: '',
    exportingAuditLogStatus: undefined,
    exportAuditLogData: undefined,
    auditStartDateError: '',
    auditEndDateError: '',
  };
};

export const systemPreferencesReducer = (state = createEmptySystemPreferences(), action) => {
  switch (action.type) {
    case actionTypes.SYSTEM_PREFERENCES_GET_PREFERENCES_BEGIN:
      return handleGetPreferencesStart(state);
    case actionTypes.SET_ORGANIZATION_INFO_BEGIN:
    case actionTypes.GET_ORGANIZATION_INFO_BEGIN:
      return handleGetOrganizationStart(state);
    case actionTypes.SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_COMPLETED:
      return handleGetPreferencesCompleted(state, action.payload);
    case actionTypes.SYSTEM_PREFERENCES_GET_INTERFACE_PREFERENCES_FAIL:
      return handleGetPreferencesFailed(state, action.payload);
    case actionTypes.SYSTEM_PREFERENCES_SET_INTERFACE_PREFERENCES:
      return handleSetPreferences(state, action.payload);
    case actionTypes.SYSTEM_PREFERENCES_SET_INTERFACE_TYPE_SPECIFIC_PREFERENCES:
      return handleSetTypeSpecificPreferences(state, action.payload);
    case actionTypes.SYSTEM_PREFERENCES_SET_SAVING:
      return handleSetSaving(state, action.payload);
    case actionTypes.SYSTEM_PREFERENCES_SET_ERROR:
      return handleSetError(state, action.payload);
    case actionTypes.GET_ORGANIZATION_INFO_ERROR:
    case actionTypes.SET_ORGANIZATION_INFO_ERROR:
      return handleSetOrganizationError(state, action.payload);
    case actionTypes.REGISTERED_INTERFACES_GET_INTERFACES_BEGIN:
      return handleGetRegisteredInterfacesStart(state);
    case actionTypes.REGISTERED_INTERFACES_GET_INTERFACES_COMPLETED:
      return handleGetRegisteredInterfacesCompleted(state, action.payload);
    case actionTypes.REGISTERED_INTERFACES_GET_INTERFACES_FAIL:
      return handleGetRegisteredInterfacesFailed(state, action.payload);
    case actionTypes.REGISTERED_INTERFACES_SET_SAVING:
      return handleShowRegisteredInterfacesSetSaving(state, action.payload);
    case actionTypes.REGISTERED_INTERFACES_REGISTER_INTERFACE:
      return handleShowRegisteredInterfacesAddInterface(state, action.payload);
    case actionTypes.REGISTERED_INTERFACES_DELETE_INTERFACE:
      return handleShowRegisteredInterfacesDeleteInterface(state, action.payload);
    case actionTypes.REGISTERED_INTERFACES_SET_ERRORS:
      return handleShowRegisteredInterfacesSetErrors(state, action.payload);
    case actionTypes.REGISTERED_INTERFACES_SHOW_REGISTER_FORM:
      return handleShowRegisterInterfaceForm(state, action.payload);
    case actionTypes.REGISTERED_INTERFACES_CLEAR_SECRET_KEYS:
      return handleClearSecretKeys(state);
    case actionTypes.SET_GENERAL_PREFERENCE_BEGIN:
      return handleSetGeneralPreferenceBegin(state);
    case actionTypes.SET_GENERAL_PREFERENCE_COMPLETED:
      return handleSetGeneralPreferenceCompleted(state, action.payload);
    case actionTypes.SET_GENERAL_PREFERENCE_ERROR:
    case actionTypes.GET_GENERAL_PREFERENCE_ERROR:
      return handleGeneralPreferenceError(state, action.payload);
    case actionTypes.GET_GENERAL_PREFERENCE_BEGIN:
      return handleGetGeneralPreferenceBegin(state);
    case actionTypes.GET_GENERAL_PREFERENCE_COMPLETED:
      return handleGetGeneralPreferenceCompleted(state, action.payload);
    case actionTypes.SET_ORGANIZATION_SECURITY_OPTIONS_BEGIN:
      return handleSetOrganizationSecurityOptionsBegin(state);
    case actionTypes.SET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED:
      return handleSetOrganizationSecurityOptionsCompleted(state, action.payload);
    case actionTypes.SET_ORGANIZATION_SECURITY_OPTIONS_LOADING:
      return handleSetOrganizationSecurityOptionsLoading(state, action.payload);
    case actionTypes.SET_ORGANIZATION_SECURITY_OPTIONS_ERROR:
    case actionTypes.GET_ORGANIZATION_SECURITY_OPTIONS_ERROR:
      return handleOrganizationSecurityOptionsError(state, action.payload);
    case actionTypes.GET_ORGANIZATION_SECURITY_OPTIONS_BEGIN:
      return handleGetOrganizationSecurityOptionsBegin(state);
    case actionTypes.GET_ORGANIZATION_SECURITY_OPTIONS_COMPLETED:
      return handleGetOrganizationSecurityOptionsCompleted(state, action.payload);
    case actionTypes.GET_ORGANIZATION_INFO_COMPLETED:
    case actionTypes.SET_ORGANIZATION_INFO_COMPLETED:
      return handleGetOrganizationCompleted(state, action.payload);
    case actionTypes.ENABLE_TEST_ORGANIZATION_BEGIN:
      return handleEnableOrganizationBegin(state);
    case actionTypes.SYNC_TEST_ORGANIZATION_SETTINGS_BEGIN:
      return handleSyncOrganizationBegin(state);
    case actionTypes.ENABLE_TEST_ORGANIZATION_COMPLETED:
      return handleEnableTestOrganizationCompleted(state, action.payload);
    case actionTypes.SYNC_TEST_ORGANIZATION_SETTINGS_COMPLETED:
      return handleSyncTestOrganizationSettingsCompleted(state, action.payload);
    case actionTypes.SYNC_TEST_ORGANIZATION_SETTINGS_ERROR:
      return handleSyncOrganizationSettingsError(state, action.payload);
    case actionTypes.SYNC_TEST_ORGANIZATION_SETTINGS_STATUS:
      return handleSyncOrganizationStatus(state, action.payload);
    case actionTypes.GET_AUDIT_CATERGORY_LIST_COMPLETED:
      return handleGetAuditCategoryListCompleted(state, action.payload);
    case actionTypes.GET_AUDIT_CATERGORY_LIST_ERROR:
      return handleGetAuditCategoryListError(state, action.payload);
    case actionTypes.GET_AUDIT_CATERGORY_LIST_BEGIN:
      return handleGetAuditCategoryListBegin(state);
    case actionTypes.GET_ENCODER_USER_LIST_REQUEST_BEGIN:
      return handleGetUserListForAuditBegin(state);
    case actionTypes.GET_ENCODER_USER_LIST_COMPLETED:
      return handleGetUserListForAuditCompleted(state, action.payload);
    case actionTypes.GET_ENCODER_USER_LIST_ERROR:
      return handleGetUserListForAuditError(state, action.payload);
    case actionTypes.UPDATE_AUDIT_ITEM_CHECKED_VALUE:
      return handleUpdateAuditItemCheckedValue(state, action.payload);
    case actionTypes.GET_AUDIT_EXPORT_LOG_CSV_BEGIN:
      return handleGetExportAuditLogBegin(state);
    case actionTypes.GET_AUDIT_EXPORT_LOG_CSV_COMPELETED:
      return handleGetExportAuditLogCompleted(state, action.payload);
    case actionTypes.GET_AUDIT_EXPORT_LOG_CSV_ERROR:
      return handleGetExportAuditLogError(state, action.payload);
    case actionTypes.CLEAR_AUDIT_LOG_DATA:
      return handleClearAuditLogData(state, action.payload);
    default:
      return state;
  }
};

const handleGetPreferencesStart = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loading: true,
    errorTexts: []
  };

  return newState;
}

const handleGetPreferencesFailed = (state: SystemPreferencesState, errorTexts): SystemPreferencesState => {
  const newState = {
    ...state,
    loading: false,
    errorTexts: [...errorTexts]
  };

  return newState;
}

const handleGetPreferencesCompleted = (state: SystemPreferencesState, interfaceTypes: IdDescriptionBase[]): SystemPreferencesState => {
  const newState = {
    ...state,
    initialized: true,
    loading: false,
    errorTexts: [],
    interfaceTypes: [...interfaceTypes],
  };

  return newState;
}

const handleSetPreferences = (
  state: SystemPreferencesState,
  preferences: SystemPreferencesObject,
): SystemPreferencesState => {
  const newState = {
    ...state,
    allSystemPreferences: preferences,
    interfaceType: getInterfaceType(preferences),
    fieldsToPreserve: getFieldsToPreserve(preferences),
    EpicCDIFacilityMatchPattern: preferences.EpicCDIFacilityMatchPattern,
    EpicCDIFacilityReplacePattern: preferences.EpicCDIFacilityReplacePattern,
    EpicCdiDefaultDisposition: preferences.EpicCdiDefaultDisposition,
    EpicSendPoaAsE: preferences.EpicSendPoaAsE,
  };

  return newState;
}

const handleSetTypeSpecificPreferences = (
  state: SystemPreferencesState,
  typeSpecificPreferences: SystemPreferencesTypeSpecificObject,
): SystemPreferencesState => {
  const newState = {
    ...state,
    typeSpecificPreferences,
    defaultCancelledBehaviorSendInboundMessage: getDefaultCancelledBehaviorIsSendInboundMessage(state.allSystemPreferences, typeSpecificPreferences),
    // uncomment to use common ConcurrencyVersion
    // ConcurrencyVersion: typeSpecificPreferences.ConcurrencyVersion || state.ConcurrencyVersion,
  };

  return newState;
}

const handleSetError = (state: SystemPreferencesState, errorTexts: string[]): SystemPreferencesState => {
  const newState = {
    ...state,
    errorTexts
  };

  return newState;
}

const handleSetSaving = (state: SystemPreferencesState, saving: boolean): SystemPreferencesState => {
  const newState = {
    ...state,
    saving
  };

  return newState;
}

const handleGetRegisteredInterfacesStart = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    registerInterfaceLoading: true,
    registerInterfaceLoaded: false,
  };

  return newState;
}

const handleGetRegisteredInterfacesCompleted = (state: SystemPreferencesState, interfaces: InterfaceRegistration[]): SystemPreferencesState => {
  const newState = {
    ...state,
    registerInterfaceLoading: false,
    registerInterfaceLoaded: true,
    registeredInterfaces: [...interfaces],
  };

  return newState;
}

const handleGetRegisteredInterfacesFailed = (state: SystemPreferencesState, errors: string[]): SystemPreferencesState => {
  const newState = {
    ...state,
    registerInterfaceLoading: false,
    registerInterfaceLoaded: false,
    registerInterfaceErrorTexts: [...errors],
  };

  return newState;
}

const handleShowRegisteredInterfacesSetSaving = (state: SystemPreferencesState, saving: boolean): SystemPreferencesState => {
  const newState = {
    ...state,
    registerInterfaceSaving: saving,
  };

  return newState;
}

const handleShowRegisteredInterfacesAddInterface = (state: SystemPreferencesState, newInterface: InterfaceRegistration): SystemPreferencesState => {
  const addedInterface: InterfaceRegistration = {
    ...newInterface,
    isNewItem: true,
  }

  const newState = {
    ...state,
    registeredInterfaces: [...state.registeredInterfaces, addedInterface],
  };

  return newState;
}

const handleShowRegisteredInterfacesDeleteInterface = (state: SystemPreferencesState, ViewId: string): SystemPreferencesState => {
  const newState = {
    ...state,
    registeredInterfaces: state.registeredInterfaces.filter((interf) => interf.ViewId !== ViewId),
  };

  return newState;
}
const handleShowRegisteredInterfacesSetErrors = (state: SystemPreferencesState, errors: string[]): SystemPreferencesState => {
  const newState = {
    ...state,
    registerInterfaceErrorTexts: [...errors],
  };

  return newState;
}

const handleShowRegisterInterfaceForm = (state: SystemPreferencesState, show: boolean): SystemPreferencesState => {
  const newState = {
    ...state,
    showRegisterInterfaceForm: show,
  };

  return newState;
}

const handleClearSecretKeys = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    registeredInterfaces: state.registeredInterfaces.map((interf) => {
      return {
        ...interf,
        ClientSecret: null,
        isNewItem: undefined,
      };
    }),
  };

  return newState;
}

const handleGetGeneralPreferenceBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingGeneralPreferences: true
  }

  return newState;
}

const handleGetGeneralPreferenceCompleted = (state: SystemPreferencesState, generalPreference: GeneralPreferenceObject): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingGeneralPreferences: false,
    generalPreferences: {
      IdleTime: generalPreference.IdleTime ? generalPreference.IdleTime : DEFAULT_IDLE_TIME,
      ConcurrencyVersion: generalPreference.ConcurrencyVersion,
    },
    calculatedIdleTime: generalPreference.IdleTime ? parseInt(generalPreference.IdleTime, 10) * 60 * SECOND_TIMEOUT : DEFAULT_CALCULATED_IDLE_TIME
  }
  return newState;
}

const handleSetGeneralPreferenceBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingGeneralPreferences: true
  }

  return newState;
}

const handleSetGeneralPreferenceCompleted = (state: SystemPreferencesState, newPreference: GeneralPreferenceObject): SystemPreferencesState => {

  const newState = {
    ...state,
    loadingGeneralPreferences: false,
    generalPreferences: {
      ...state.generalPreferences,
      ...newPreference,
    },
    calculatedIdleTime : parseInt(newPreference.IdleTime, 10) * 60 * SECOND_TIMEOUT
  };

  return newState;
}

const handleGeneralPreferenceError = (state: SystemPreferencesState, error: string): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingGeneralPreferences: false,
    generalPreferences: {
      IdleTime: state.generalPreferences.IdleTime ? state.generalPreferences.IdleTime : DEFAULT_IDLE_TIME
    },
    calculatedIdleTime: state.generalPreferences.IdleTime ? parseInt(state.generalPreferences.IdleTime, 10) * 60 * SECOND_TIMEOUT : DEFAULT_CALCULATED_IDLE_TIME,
    errorTexts: [...state.errorTexts, error]
  };

  return newState;
}

const handleGetOrganizationStart = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSettings: true,
    errorTexts: []
  };

  return newState;
}

const handleGetOrganizationCompleted = (state: SystemPreferencesState, organization: OrganizationObject): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSettings: false,
    organizationData: organization,
    organizationName: organization.Name,
    testOrganizationName: organization.RelatedOrganizationName,
    testOrganizationEnabled: organization.RelatedOrganizationId !== null && !organization.IsTestOrganization,
    isTestOrganization: organization.IsTestOrganization,
  }
  return newState;
}

const handleEnableOrganizationBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSettings: true,
  }
  return newState;
}

const handleSyncOrganizationBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    syncTestOrganizationStatus: SyncSettingsStatus.Working,
  }
  return newState;
}

const handleSyncOrganizationStatus = (state: SystemPreferencesState, status: boolean): SystemPreferencesState => {
  const newState = {
    ...state,
    syncingTestOrganizationSettingsInProgress: status,
  }
  return newState;
}

const handleEnableTestOrganizationCompleted = (state: SystemPreferencesState, organization: OrganizationObject): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSettings: false,
    testOrganizationEnabled: true,
    testOrganzationCreationSuccessful: true,
    testOrganizationName: organization.IsTestOrganization ? organization.Name : '',
    isTestOrganization: false,
  }
  return newState;
}

const handleSyncTestOrganizationSettingsCompleted = (state: SystemPreferencesState, organization: OrganizationObject): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSettings: false,
    syncTestOrganizationStatus: organization ? SyncSettingsStatus.Done : '',
  }
  return newState;
}

const handleSyncOrganizationSettingsError = (state: SystemPreferencesState, status: string): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSettings: false,
    syncTestOrganizationStatus: status,
  };

  return newState;
}

const handleSetOrganizationError = (state: SystemPreferencesState, errorTexts: string[]): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSettings: false,
    errorTexts
  };

  return newState;
}

const handleGetOrganizationSecurityOptionsBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSecurityOptions: true
  }

  return newState;
}

const handleGetOrganizationSecurityOptionsCompleted = (state: SystemPreferencesState, organizationSecurityOptions: OrganizationSecurityOptionsObject): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSecurityOptions: false,
    organizationSecurityOptionsErrorTexts: [],
    organizationSecurityOptions: {
      ...organizationSecurityOptions
    },
  }
  return newState;
}

const handleSetOrganizationSecurityOptionsBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSecurityOptions: true
  }

  return newState;
}

const handleSetOrganizationSecurityOptionsCompleted = (state: SystemPreferencesState, organizationSecurityOptions: OrganizationSecurityOptionsObject): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSecurityOptions: false,
    organizationSecurityOptionsErrorTexts: [],
    organizationSecurityOptions: {
      ...organizationSecurityOptions
    },
  };

  return newState;
}

const handleSetOrganizationSecurityOptionsLoading = (state: SystemPreferencesState, loading: boolean): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSecurityOptions: loading,
  };

  return newState;
}

const handleOrganizationSecurityOptionsError = (state: SystemPreferencesState, error: string): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingOrganizationSecurityOptions: false,
    organizationSecurityOptionsErrorTexts: [error]
  };

  return newState;
}

const handleGetAuditCategoryListCompleted = (state: SystemPreferencesState, auditList: AuditItemObject[]): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingAuditCategoryList: false,
    auditDataList: auditList,
  }
  return newState;
}

const handleGetAuditCategoryListBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingAuditCategoryList: true,
    auditDataList: undefined,
    exportingAuditLogStatus: undefined,
  }
  return newState;
}

const handleGetAuditCategoryListError = (state: SystemPreferencesState, error: string): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingAuditCategoryList: false,
    auditErrorText: error,
    auditDataList: undefined,
  }
  return newState;
}

const handleGetUserListForAuditBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingUserListForAudit: true,
    auditUserList: [],
  }
  return newState;
}

const handleGetUserListForAuditCompleted = (state: SystemPreferencesState, userList: EncoderUserListResultEntity): SystemPreferencesState => {
  const newState = {
    ...state,
    loadingUserListForAudit: false,
    auditUserList: userList.users,
  }
  return newState;
}

const handleGetUserListForAuditError = (state: SystemPreferencesState, errorTexts: string[]): SystemPreferencesState => {
  const newState = {
    ...state,
    errorTexts,
  }
  return newState;
}

const handleUpdateAuditItemCheckedValue = (state: SystemPreferencesState, data: AuditCheckBoxValue): SystemPreferencesState => {
  if(!state.auditDataList) {
    return state;
  }

  const groupChecked = data.Id.indexOf(':') < 0;
  const updatedAuditDataList = state.auditDataList?.map((item) => {
    if(data.Id === 'checkAll') {
      return {...item, Selected: data.Checked}
    }
    if(groupChecked) {
      if(data.Id === item.Group) {
        return {...item, Selected: data.Checked}
      }
    }
    if(data.Id === item.Id) {
      return {...item, Selected: data.Checked}
    }
    return item;
  });

  const newState = {
    ...state,
    auditDataList: updatedAuditDataList,
  }
  return newState;
}

const handleGetExportAuditLogBegin = (state: SystemPreferencesState): SystemPreferencesState => {
  const newState = {
    ...state,
    exportingAuditLogStatus: AuditExportLogStatus.Exporting,
    auditExportErrorText: undefined,
    exportAuditLogData: undefined,
  }
  return newState;
}

const handleGetExportAuditLogCompleted = (state: SystemPreferencesState, result: AuditExportLogResult): SystemPreferencesState => {
  const newState = {
    ...state,
    exportingAuditLogStatus: AuditExportLogStatus.Done,
    exportAuditLogData: result,
  }

  return newState;
}

const handleGetExportAuditLogError = (state: SystemPreferencesState, error: string): SystemPreferencesState => {
  const newState = {
    ...state,
    auditExportErrorText: error,
    exportingAuditLogStatus: AuditExportLogStatus.Failed,
    exportAuditLogData: undefined,
  }
  return newState;
}

const handleClearAuditLogData = (state: SystemPreferencesState, payload): SystemPreferencesState => {
  const newState = {
    ...state,
    exportingAuditLogStatus: undefined,
    exportAuditLogData: undefined,
  }
  return newState;
}
