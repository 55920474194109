export interface SystemPreferencesInterfaceFieldStatus {
  Name: string;
  Description: string;
  Preserve: boolean;

  // UI field to use common CheckBoxCell
  inEdit: boolean;
}

export enum DefaultCancelledBehaviorModes {
  SendEmptyMessage = "SendEmptyMessage",
  SendInboundMessage = "SendInboundMessage",
};

export enum SyncSettingsStatus {
  Working = "Processing...",
  Done = "Done",
  Failed = "Failed",
};

export enum AuditExportLogStatus {
  Exporting = "Exporting...",
  Done = "Done",
  Failed = "Failed",
};

export interface SystemPreferencesTypeSpecificObject {
  CancelledBehavior?: DefaultCancelledBehaviorModes;
  BackwardsCompatibilityMode?: boolean;
  ConcurrencyVersion?: string;
}

export interface SystemPreferencesObject {
  InterfaceType?: string | null;
  FieldsToPreserve: SystemPreferencesInterfaceFieldStatus[];
  EpicCDIFacilityMatchPattern: string;
  EpicCDIFacilityReplacePattern: string;
  EpicCdiDefaultDisposition: string;
  ConcurrencyVersion?: string;
  EpicSendPoaAsE: boolean;
  // it have other services fields that are not handled on UI side
}

export interface GeneralPreferenceObject {
  IdleTime: string;
  ConcurrencyVersion?: string;
}

export interface OrganizationObject {
  ViewId?: string;
  Name?: string;
  Type: string;
  IsTestOrganization: boolean;
  IsSuspended: boolean;
  RelatedOrganizationId: string;
  RelatedOrganizationName: string;
  UseTest3MGrouping: boolean;
  ConcurrencyVersion?: string;
}

export interface TestOrganizationSyncStatus {
  ViewId: string;
  Status: string;
  Error: string;
  QueuedAt: string;
  StartedAt: string;
  LastUpdatedAt: string;
}

export const DEFAULT_ORGANIZATION_PASSWORDS_ALLOWED_AMOUNT = 1;
export const DEFAULT_ORGANIZATION_PASSWORD_AGE_IN_DAYS = 90;
export const DEFAULT_ORGANIZATION_PASSWORD_COMPLEXITY_DESCRIPTION = "8-64 characters, containing at least 3 out of the 4 following: lowercase characters, uppercase characters, digits (0-9), and one or more of the following symbols: @ # $ % ^ < > & * - _ + = [ ] { } | \\ : ' , ? / ` ~ “ ( ) ; .";

export interface OrganizationSecurityOptionsObject {
  PasswordRegex?: string;
  DefaultPasswordRegex?: string;
  PasswordComplexityDescription?: string;
  HistoricalPasswordsAllowedAmount?: number;
  PasswordAgeInDays?: number;
  ConcurrencyVersion?: string;
}

export interface AuditItemObject {
  Id: string;
  Description: string;
  Group: string;
  Selected: boolean;
}

export interface AuditCheckBoxValue {
  Id: string;
  Checked: boolean;
}

export interface AuditExportLogResult {
  text: string;
  type: string;
}

export const getInterfaceType = (systemPreferences: SystemPreferencesObject) => {
  return systemPreferences?.InterfaceType || '';
}

export const getFieldsToPreserve = (systemPreferences: SystemPreferencesObject) => {
  if (!systemPreferences || !systemPreferences.FieldsToPreserve || !systemPreferences.FieldsToPreserve.length) {
    return [];
  }

  return systemPreferences.FieldsToPreserve.map(field => { return { ...field, inEdit: true }});
}

export const getDefaultCancelledBehaviorIsSendInboundMessage = (systemPreferences: SystemPreferencesObject, typeSpecificPreferences: SystemPreferencesTypeSpecificObject) => {
  if (!systemPreferences?.InterfaceType) {
    return false;
  }

  const defaultBehaviour = typeSpecificPreferences?.CancelledBehavior || DefaultCancelledBehaviorModes.SendEmptyMessage;

  return defaultBehaviour === DefaultCancelledBehaviorModes.SendInboundMessage;
}

export const updateInterfaceType = (systemPreferences: SystemPreferencesObject, interfaceType: string) => {
  if (!systemPreferences) {
    return systemPreferences;
  }

  return {
    ...systemPreferences,
    InterfaceType: interfaceType || null,
  }
}

export const updateInterfaceField = (systemPreferences: SystemPreferencesObject, fieldName: string, value: string) => {
  if (!systemPreferences) {
    return systemPreferences;
  }

  return {
    ...systemPreferences,
    [fieldName]: value,
  }
}

export const updateFieldToPreserve = (systemPreferences: SystemPreferencesObject, Name: string, Preserve: boolean) => {
  if (!systemPreferences || !systemPreferences.FieldsToPreserve || !systemPreferences.FieldsToPreserve.length) {
    return systemPreferences;
  }

  const FieldsToPreserve = systemPreferences.FieldsToPreserve.map(field => field.Name === Name ? { ...field, Preserve } : field);

  return {
    ...systemPreferences,
    FieldsToPreserve,
  }
}

export const updateDefaultCancelledBehaviour = (isSendInboundMessage: boolean) => {
  return isSendInboundMessage ? DefaultCancelledBehaviorModes.SendInboundMessage : DefaultCancelledBehaviorModes.SendEmptyMessage;
}
