// immediately set runtime variables/globals
import './conf';

import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from "@azure/msal-react";
import LogRocket from 'logrocket';
import { ErrorBoundaryContainer } from './components/ErrorBoundary/ErrorBoundaryContainer';
import { history, store } from './reduxStore';
import { TEEApi, TEEOptions } from './types/tee';
import { getAccountIdentifier, getUserEmail, pca, tryLogin } from './authProvider';
import { AuthErrorPageContainer } from './scenes/AuthError/pageContainer';

import { stringifyTemplate } from './tee-templates';
import icd10mapTabEmpty from './tee-templates/icd10mapTabEmpty.template.html';
import codeBooksSearchbar from './tee-templates/codeBooksSearchbar.template.html';
import drgAnalysisTab from './tee-templates/drgAnalysisTab.template.html';
import researchTab from './tee-templates/researchTab.template.html';
import modal from './tee-templates/modal.template.html';
import editsTab from './tee-templates/editsTab.template.html';
import { retry } from './utils/lazy';
import { LoadingPanel } from './components';

declare global {
  interface Window {
    TC: TEEApi;
    useAzureADAuth: boolean;
    noAuthorization: boolean;
    ready: () => void;

    startNetActivity: (url) => string;
    stopNetActivityWithError: (requestId, errorReason, responseStatus) => void;
    stopNetActivity: (requestId, responseStatus?: string | number) => void;

    startWorkerTimer: () => boolean;
    stopWorkerTimer: () => void;
    subscribeWorkerTimer: (callback, interval: number) => string;
    unsubscribeWorkerTimer: (id: string) => void;

    runtimeConfig: {
      idleTime: number;
      helpUri: string;
    };
    _lrAsyncScript: string;
  }
}


const envMap = {
  dev: 'stage',
  development: 'prod',
  prod: 'prod',
  production: 'prod',
  stage: 'stage',
  test: 'stage',
  local: 'stage',
};

const env = process.env.NODE_ENV || 'production';
const isDevelopment = env === 'development';
const isLocal = env === 'local';
const isTest = process.env.REACT_APP_QA_MODE === 'yes';
const isModalCodebooksWindow = window.location.pathname.startsWith('/codebooksmodal');
const isModalReferencesWindow = window.location.pathname.startsWith('/referencesmodal');
const isModalEncounterWindow = window.location.pathname.startsWith('/codeencounter');
const isModalWindow = isModalCodebooksWindow || isModalReferencesWindow || isModalEncounterWindow;
const search = window.location.search || '';
const skipAuthorization = search.match(/useToken=true/) !== null;
const isOutOfAuthorizationLink = window.location.pathname.startsWith('/autherror');
window.useAzureADAuth = !isLocal && !isModalWindow && !skipAuthorization && !isOutOfAuthorizationLink;
window._lrAsyncScript = window._lrAsyncScript = `${process.env.REACT_APP_LOGROCKET_PROXY_CDN}/logger.min.js`;

const createTeeConfig = (accessToken?: string | undefined) => {
  const teeConfig: TEEOptions = {
    env: envMap[process.env.NODE_ENV || 'production'],
    ajaxTimeout: 0,
    authorizationExpired: () => { console.log('auth expired'); return 'expired!'; },
    authorizationKey: accessToken,
    effects: false,
    fatalErrorCallback: () => {
      // TODO: raise this somehow, logging, telemetry, other
    },
    printTemplate: '/dist/print/print.html',
    ready: onReady,
    templates: {
      drgAnalysisTab: stringifyTemplate(drgAnalysisTab, 'drgAnalysisTab'),
      editsTab: stringifyTemplate(editsTab, 'editsTab'),
      icd10mapTabEmpty: stringifyTemplate(icd10mapTabEmpty, 'icd10mapTabEmpty'),
      searchbar: stringifyTemplate(codeBooksSearchbar, 'codeBooksSearchbar'),
      researchTab: stringifyTemplate(researchTab, 'researchTab'),
      modal: stringifyTemplate(modal, 'modal'),
    },
  };

  teeConfig.useCustomEnv = true;
  teeConfig.customEnvUri = {
    GRP: `${process.env.REACT_APP_TEE_GRP}/`,
    MNE: `${process.env.REACT_APP_TEE_MNE}/`,
    CBK: `${process.env.REACT_APP_TEE_CBK}/`,
    RSC: `${process.env.REACT_APP_TEE_RSC}/`,
    GENERIC: `${process.env.REACT_APP_TEE_GENERIC}/`,
  };

  return teeConfig;
};

const onReady = () => {
  const renderApp = () => {
    // TODO: clean this up somehow, improper rendering when i try to force an error
    // const authError: ErrorDetails = {
    //   description: 'Authorization error',
    // }

    // ReactDOM.render(
    //   <Provider store={store}>
    //     <ErrorBoundaryContainer history={history} forcedError={authError}>
    //       <CookiesProvider>
    //         <React.Suspense fallback={<div />}>
    //           <App options={{ tee: window.TC }} history={history} />
    //         </React.Suspense>
    //       </CookiesProvider>
    //     </ErrorBoundaryContainer>
    //   </Provider>,
    //   document.getElementById('app')
    // );

    // handle situations with out from authorization pages and incorrect authorization
    if (window.noAuthorization) {
      ReactDOM.render(
        <Provider store={store}>
          <ErrorBoundaryContainer history={history}>
            <CookiesProvider>
              <AuthErrorPageContainer history={history} />
            </CookiesProvider>
          </ErrorBoundaryContainer>
        </Provider>,
        document.getElementById('app')
      );

      return;
    }

    const App = React.lazy(() => retry(() => import('./App')));

    if (window.useAzureADAuth) {
      const userEmail = getUserEmail();
      LogRocket.identify(userEmail.toLowerCase());
      const userId = getAccountIdentifier();
      const previousUser = localStorage.getItem("previousUser");
      localStorage.setItem('previousUser', userId);

      // open home page for new logged in user
      if (previousUser && previousUser !== userId && window.location.pathname !== '/') {
        window.location.assign('/');
        return;
      }

      ReactDOM.render(
        <Provider store={store}>
          <ErrorBoundaryContainer history={history}>
            <CookiesProvider>
              <MsalProvider instance={pca}>
                <>
                  <React.Suspense fallback={<LoadingPanel />}>
                    <App options={{ tee: window.TC }} history={history} />
                  </React.Suspense>
                </>
              </MsalProvider>
            </CookiesProvider>
          </ErrorBoundaryContainer>
        </Provider>,
        document.getElementById('app')
      );

      return;
    }

    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundaryContainer history={history}>
          <CookiesProvider>
            <React.Suspense fallback={<LoadingPanel />}>
              <App options={{ tee: window.TC }} history={history} />
            </React.Suspense>
          </CookiesProvider>
        </ErrorBoundaryContainer>
      </Provider>,
      document.getElementById('app')
    );
  };

  renderApp();
};

ready();

// FIXME: does this work in modal windows?
function ready() {
  return tryLogin()
    .then(accessToken => {
      // load trucode.all.js only for the Authenticated state
      const id = 'tc-api';
      const ref = document.getElementsByTagName('script')[0];
      if (!document.getElementById(id)) {
        const js = document.createElement('script');
        js.id = id;
        js.async = true;
        js.src = '/trucode/trucode.all.js';
        ref.parentNode.insertBefore(js, ref);
      }

      const teeConfig = createTeeConfig(accessToken || ''); //  userToken || token
      if (!window.TC) {
        window.TC = teeConfig as TEEApi; // cast it erroneously to avoid dynamic signature errors
      } else {
        window.TC.init(teeConfig);
        onReady();
      }

      return accessToken;
    })
}

window.ready = ready;
