export enum EncounterType {
  ALL = 'All',
  INPATIENT = 'Inpatient',
  OUTPATIENT = 'Outpatient',
  SHARED = 'Shared (Inpatient/Outpatient)'
}

export enum PatientEncounterType {
  IP = 'IP',  // 'Inpatient',
  OP = 'OP',  // 'Outpatient',
  ER = 'ER',  // 'Emergency Room',
  SDS = 'SDS' // 'Same Day Surgery'
}
