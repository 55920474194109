import { UserPreferencesEntity } from '../../models';
import { get, post } from '../../utils/net';

// const baseURL = 'http://localhost:3666/api/userpreferences';
const baseURL = `${process.env.REACT_APP_API_PATH}/api/userpreferences`;


const getUserPreferences = (): Promise<UserPreferencesEntity[]> => {
  const url = baseURL;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

/*
const getPreference = (Net: TEENet, id: string, cancelAction?: NetCancel): Promise<UserPreferencesEntity> => {
  // Pass id to get actual preference
  const url = `${baseURL}/${id}`;

  const extraOptions = {
    useExactPath: true,
  };

  return get<object>(Net, url, cancelAction, extraOptions)
    .then((result) => {
      return mapUserPreferenceResultToEntity(id, result);
    })
    .catch(() => {
      return mapUserPreferenceResultToEntity(id, {});
    });
};
*/
const setPreference = (
  preference: string,
  value: any,
): Promise<UserPreferencesEntity> => {
  const url = `${baseURL}/${preference}`;

  const extraOptions = {
    useExactPath: true,
  };

  return post(url, value, extraOptions).then(() => {
    return mapUserPreference(preference, value);
    ;
  });
};

/*
const deletePreference = (Net: TEENet, id: string, cancelAction?: NetCancel): Promise<EncoderUserListResultEntity> => {

  const url = `${baseURL}/`;

  return get<UserPreferencesEntity>(Net, url, cancelAction)
    .then(mapUserPreferenceResultToEntity);
};
*/

const mapUserPreference = (key: string, value: object): UserPreferencesEntity => {
  return {
    key,
    value: value || undefined,
  };
};

export const userPreferencesAPI = {
  getUserPreferences,
  // getPreference,
  setPreference,
  // deletePreference
};
