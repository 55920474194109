import * as React from 'react';
import * as ReactDOM from 'react-dom';

import '../../styles/kendo-custom.scss';

interface LoadingPanelProps {
  selector?: string;
}

export class LoadingPanel extends React.Component<LoadingPanelProps> {
  render() {
    const loadingPanel = (
      <div className="k-loading-mask">
        <span className="k-loading-text">Loading...</span>
        <div className="k-loading-image" />
        <div className="k-loading-color" />
      </div>
    );

    // find by selector if provided, prefer grid child first & fallback to selector only
    if (this.props.selector) {
      const content = document.querySelector(`${this.props.selector} .k-grid-content, ${this.props.selector}`)
      if (content) {
        return ReactDOM.createPortal(loadingPanel, content);
      }
    }

    // NOTE: with lazy loading this will:
    // * find a .contents-wrapper,
    // * start loading the lazy module,
    // * try to insert the portal content into a DOM element that no longer exists (blank instead of loading indicator)
    // * but removing the portal and falling on the return as-is works consistently
    // fallback to page/content wrapper
    // const pageTarget = document.querySelector('.page, .contents-wrapper');
    // if (pageTarget) {
    //   return ReactDOM.createPortal(loadingPanel, pageTarget);
    // }

    // finally return as-is
    return loadingPanel;
  }
}
