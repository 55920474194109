import { orderBy } from '@progress/kendo-data-query';
import * as actionTypes from '../constants';
import { ChoiceListsState, IdDescriptionBase } from '../models/patientEncounter';
import { CHOICE_LIST_STATUS } from '../models/cachedChoiceList';

const createEmptyChoiceListsState = (): ChoiceListsState => {
  return {
    facilities: [],
    encounterTypes: [],
    patientStatuses: [],
    services: [],
    financialClasses: [],
    recordStatuses: [],
    sexes: [],
    ofa: [],
    currentEncounterFacility: '',
    currentEncounterFacilities: [],
    newEncounterEncounterTypes: [],
    providers: [],
    cachedLists: {},
    payerFlags: [],
    loading: false,
    loadingNewEncounterFacilities: false,
    loadingEncounterFacilities: false,
    loadingNewEncounterEncounterTypes: false,
    billingNotes: [],
  };
};

export const choiceListsReducer = (state = createEmptyChoiceListsState(), action) => {
  switch (action.type) {
    case actionTypes.GET_FACILITIES_COMPLETED:
      return handleSetFacilities(state, action.payload);
    case actionTypes.GET_FACILITIES_FAIL:
    case actionTypes.UPDATE_ENCOUNTER_FACILITY_FAIL:
    case actionTypes.GET_CHOICELISTS_FAIL:
      return handleGetFacilitiesFailed(state);
    case actionTypes.GET_CHOICELIST_SUCCESS:
      return handleUpdateSingleChoiceList(state, action.payload);
    case actionTypes.GET_CHOICELIST_FAIL:
      return handleGetSingleChoiceListFail(state, action.payload);
    case actionTypes.UPDATE_ENCOUNTER_FACILITY:
      return handleUpdateChoiceLists(action.payload);
    case actionTypes.SET_CURRENT_ENCOUNTER_FACILITIES:
      return handleSetCurrentEncounterFacilities(state, action.payload);
    case actionTypes.SET_CHOICELISTS_LOADING:
      return handleSetChoiceListsLoading(state, action.payload);

    case actionTypes.CACHED_CHOICELIST_CHANGE_STATUS:
      return handleCachedChoiceChangeStatus(state, action.payload);
    case actionTypes.CACHED_CHOICELIST_LOADED:
      return handleCachedChoiceListLoaded(state, action.payload);

    case actionTypes.GET_CHOICE_LIST_START:
      return handleChoiceListStartLoading(state, action.payload);
    case actionTypes.GET_CHOICE_LIST_END:
      return handleChoiceListEndLoading(state, action.payload);
    default:
      return state;
  }
};

const handleSetFacilities = (state: ChoiceListsState, facilities: IdDescriptionBase[]): ChoiceListsState => {
  // update state only if we have real changes
  if (state.facilities.length === facilities.length) {
    let areEqual = true;
    for (let ind = 0, len = facilities.length; ind < len; ind++) {
      if (state.facilities[ind].id !== facilities[ind].id
        || state.facilities[ind].title !== facilities[ind].title
        || state.facilities[ind].ViewId !== facilities[ind].ViewId
        || state.facilities[ind].CurrentUserRole !== facilities[ind].CurrentUserRole) {
        areEqual = false;
        break;
      }
    }

    if (areEqual) {
      return state;
    }
  }

  // always keep currentEncounterFacility in the currentEncounterFacilities list
  if (!facilities.find(facility => facility.ViewId === state.currentEncounterFacility)) {
    const currentEncounterFacility = state.currentEncounterFacilities.find(facility => facility.ViewId === state.currentEncounterFacility);

    if (currentEncounterFacility) {
      const newCurrentEncounterFacilities = [
        currentEncounterFacility,
        ...facilities
      ]

      const sortedCurrentEncounterFacilities = orderBy(newCurrentEncounterFacilities, [{ field: 'id', dir: 'asc' }]);

      return {
        ...state,
        facilities,
        currentEncounterFacilities: sortedCurrentEncounterFacilities,
      }
    }
  }

  return {
    ...state,
    facilities,
    currentEncounterFacilities: facilities,
  };
};

const handleGetFacilitiesFailed = (state: ChoiceListsState): ChoiceListsState => {
  return {
    ...state,
    facilities: [],
    currentEncounterFacilities: [],
    encounterTypes: [],
    patientStatuses: [],
    services: [],
    financialClasses: [],
    recordStatuses: [],
    sexes: [],
    ofa: [],
    providers: [],
    payerFlags: [],
    billingNotes: [],
  };
};

const handleUpdateChoiceLists = (payload: { state: ChoiceListsState, choiceLists: ChoiceListsState }): ChoiceListsState => {
  return {
    ...payload.state,
    encounterTypes: payload.choiceLists.encounterTypes,
    patientStatuses: payload.choiceLists.patientStatuses,
    services: payload.choiceLists.services,
    financialClasses: payload.choiceLists.financialClasses,
    recordStatuses: payload.choiceLists.recordStatuses,
    sexes: payload.choiceLists.sexes,
    ofa: payload.choiceLists.ofa,
    providers: payload.choiceLists.providers,
    payerFlags: payload.choiceLists.payerFlags,
    billingNotes: payload.choiceLists.billingNotes,
  };
};

const handleGetSingleChoiceListFail = (state: ChoiceListsState, name: string): ChoiceListsState => {
  return {
    ...state,
    loading: false,
    [name]: [],
  };
};

export const mapChoiceListsToObject = (state, loadedLists) => {
  const newState = state;

  for (let ind = 0, len = loadedLists.length; ind < len; ind++) {
    newState[loadedLists[ind].name] = [...loadedLists[ind].values];
  }
}

const handleUpdateSingleChoiceList = (state: ChoiceListsState, loadedLists): ChoiceListsState => {
  const newState = {
    ...state,
    loading: false,
  }

  mapChoiceListsToObject(newState, loadedLists);

  return newState;
};


const  handleCachedChoiceChangeStatus = (state: ChoiceListsState, payload: { key: string, status: CHOICE_LIST_STATUS }) => {
  const newState = {
    ...state,
    cachedLists: {
      ...state.cachedLists
    }
  }

  if (!newState.cachedLists[payload.key]) {
    newState.cachedLists[payload.key] = {
      status: payload.status,
      data: []
    }
  } else {
    newState.cachedLists[payload.key] = {
      ...newState.cachedLists[payload.key],
      status: payload.status,
    }
  }

  return newState;
}

const handleCachedChoiceListLoaded = (state: ChoiceListsState, payload: { key: string, data: IdDescriptionBase[] }) => {
  const newState = {
    ...state,
    cachedLists: {
      ...state.cachedLists
    }
  }

  newState.cachedLists[payload.key] = {
    status: CHOICE_LIST_STATUS.READY,
    data: payload.data
  }

  return newState;
}

const handleSetCurrentEncounterFacilities = (state: ChoiceListsState, payload: { currentEncounterFacility: string, currentEncounterFacilities: IdDescriptionBase[] }) => {
  return {
    ...state,
    loading: true,
    currentEncounterFacility: payload.currentEncounterFacility,
    currentEncounterFacilities: payload.currentEncounterFacilities,
  };
}

const handleSetChoiceListsLoading = (state: ChoiceListsState, payload: boolean): ChoiceListsState => {
  return {
    ...state,
    loading: payload,
  }
}

const handleChoiceListChangeLoading = (state: ChoiceListsState, loading: boolean, loadingKey?: string): ChoiceListsState => {
  if (!loadingKey) {
    return state;
  }

  return {
    ...state,
    [loadingKey]: loading,
  }
}

const handleChoiceListStartLoading = (state: ChoiceListsState, loadingKey?: string): ChoiceListsState => {
  return handleChoiceListChangeLoading(state, true, loadingKey);
}

const handleChoiceListEndLoading = (state: ChoiceListsState, loadingKey?: string): ChoiceListsState => {
  return handleChoiceListChangeLoading(state, false, loadingKey);
}
