import './Panel.less';

import * as React from 'react';

interface PanelProps {
  className: string;
  title: string;
  children: React.ReactNode;
}

export const Panel = (props: PanelProps) => {
  return (
    <div className={`col ${props.className}`}>
      <div className='panel-title'>
        {props.title}
      </div>
      <div className='panel-content'>
        {props.children}
      </div>
    </div>
  );
};
