import * as React from 'react';
import { Button, Dialog, DialogActionsBar } from '..';

interface ConfirmationDialogProps {
  visible: boolean;
  confirmText: string;
  title: string;
  cancelText?: string;
  dialogMessage: string;
  formattedDialogMessage: JSX.Element;
  primaryStyleForSubmit?: boolean;
  additionalDialogControls?: JSX.Element;
  onConfirmClick: () => void;
  toggleDialog: () => void;
  preventConfirm?: boolean;
  className?: string;
}

export class ConfirmationDialog extends React.PureComponent<ConfirmationDialogProps, {}> {
  constructor(props: ConfirmationDialogProps) {
    super(props);

    this.onConfirm = this.onConfirm.bind(this);
  }

  render() {
    return (
      <div className="confirmation-dialog">
        {this.props.visible && <Dialog title={this.props.title} onClose={this.props.toggleDialog} className={this.props.className}>
          {this.props.dialogMessage && <p>{this.props.dialogMessage}</p>}
          {this.props.formattedDialogMessage && <div>{this.props.formattedDialogMessage}</div>}
          {this.props.additionalDialogControls}
          <DialogActionsBar>
            <Button onClick={this.props.toggleDialog}>{this.props.cancelText}</Button>
            <Button
              onClick={this.onConfirm}
              disabled={!!this.props.preventConfirm}
              primary={this.props.primaryStyleForSubmit}
            >
              {this.props.confirmText}
            </Button>
          </DialogActionsBar>
        </Dialog>}
      </div>

    );
  }

  onConfirm() {
    this.props.onConfirmClick();
  }
}
