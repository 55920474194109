import { connect } from 'react-redux';
import { State } from '../../reducers';
import { ErrorBoundary } from './ErrorBoundary';
import { showUnhandledErrorAction } from '../../actions/errors';
import { ErrorDetails } from '../../models/ui';

const mapStateToProps = (state: State) => ({
  error: state.ui.unhandledError,
  globalState: state,
});

const mapDispatchToProps = (dispatch) => ({
  showUnhandledError: (error: ErrorDetails) => dispatch(showUnhandledErrorAction(error)),
});

export const ErrorBoundaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorBoundary);
