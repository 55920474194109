export const checkForConcurrencyError = (errors: any) => {
  let concurrencyErrorFound = false;
  const concurrencyErrorStrings = ['invalid concurency version','invalid concurrency version','ConcurrencyException','Concurrency error occurs when trying to update database.'];
  if(Array.isArray(errors)) {
    concurrencyErrorFound = errors.find(error => error.description && (concurrencyErrorStrings.find(errorString => error.description?.includes(errorString)) !== undefined)) !== undefined;
  } else if(typeof errors === 'string') {
    concurrencyErrorFound = concurrencyErrorStrings.find((errorString) => errors.includes(errorString)) !== undefined;
  }
  return concurrencyErrorFound;
}
