/* eslint-disable no-underscore-dangle */
import './DatePicker.less';

import * as moment from 'moment';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { DatePicker as KDatePicker, DatePickerChangeEvent as KDatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { Input as KInput, InputChangeEvent as KInputChangeEvent } from '@progress/kendo-react-inputs';

import { CustomDateInput } from './customDateInput';
// import { CustomCalendar } from './customCalendar';
import { reformatDateString, toFormattedDate } from '../../utils/date';
import { ErrorWrap } from '../ErrorWrap/ErrorWrap';
import { ValidationErrors } from '../../models';
import { CustomCalendar } from './customCalendar';

const FORMAT = 'M/d/yyyy';

export interface DatePickerKendoProps {
  // we can prevent calendar opening because of the validation error in other field
  checkPreventOpen?: (fieldName: string) => boolean;
  className?: string;
  defaultValue?: Date;
  disabled?: boolean;
  error?: ValidationErrors;  // -> error
  field: string;
  groupClassName?: string;
  inputClassName?: string;
  inputId?: string;
  label?: string;
  name?: string;
  noWrap?: boolean;           // for the grid cells: no need in the wrapping
  onBlur?: (fieldName: string, value?: string, e: React.FocusEvent<HTMLInputElement>) => boolean;
  onChange?: (fieldName: string, value?: string) => void;
  onFocus?: (fieldName: string, inputFocused: boolean) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, fieldName: string, value?: string) => void;
  value?: string;
  max?: Date;
}

export interface  DatePickerKendoState {
  strValue?: string;
  dateValue: Date | null;

  propsValue?: string;  // for comparison with previous props
}

export class DatePicker extends React.PureComponent<DatePickerKendoProps, DatePickerKendoState> {
  datePicker: KDatePicker | null;
  input: KInput | null;
  valueWasPasted: boolean;

  constructor(opts: DatePickerKendoProps) {
    super(opts);

    this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);
    this.onFocusDatePicker = this.onFocusDatePicker.bind(this);
    this.onBlurDatePicker = this.onBlurDatePicker.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onPasteInput = this.onPasteInput.bind(this);

    this.state = {
      dateValue: toFormattedDate(opts.value),
      strValue: opts.value
    };
  }

  componentDidMount() {
    this.valueWasPasted = false;
  }

  static getDerivedStateFromProps(nextProps: DatePickerKendoProps, prevState: DatePickerKendoState) {
    if (nextProps.value !== prevState.propsValue) {
      return {
        dateValue: toFormattedDate(nextProps.value),
        propsValue: nextProps.value,
        strValue: nextProps.value
      };
    }

    return null;
  }

  static toMMDDYYYYDate(date: string | Date | null | undefined): string {
    if (typeof date !== 'undefined' && date !== null) {
      return reformatDateString(moment(date).format('MM/DD/Y'));
    }

    return '';
  }

  onChangeDatePicker(e: KDatePickerChangeEvent) {
    try {
      this.datePicker?.element?.querySelectorAll('.k-state-focused').forEach((el) => {
        el.className = el.className.replace('k-state-focused', '');
      });
    } catch {}
    const val = e.target.value;
    const formatted = DatePicker.toMMDDYYYYDate(val);

    this.setState(
      {
        dateValue: val,
        strValue: formatted
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.props.field, formatted);
        }
      });
  }

  onChangeInput(e: KInputChangeEvent) {
    let val = e.target.value as unknown as string;

    if (this.valueWasPasted) {
      this.valueWasPasted = false;
      // skip spaces
      val = val.trim();
    }

    this.setState({
      dateValue: toFormattedDate(val),
      strValue: val
    });
  }

  onBlurInput(e) {
    const val = this.state.strValue;
    const formatted = val ? reformatDateString(val) : '';
    const finalValue = formatted || val;

    // the same date can be typed in the different format
    if (finalValue !== this.state.strValue) {
      this.setState({ strValue: finalValue });
    }

    const targetClassName = e.relatedTarget?.className || '';
    const blurToCalendar = targetClassName && targetClassName.indexOf('k-calendar') !== -1;

    /*
    // hide calendar - DatePicker does not send onIconClick and onBlur events to control its state.
    // So I use protected function
    // Do not hide it when we activate Calendar items
    if (this.datePicker && typeof this.datePicker.setShow === 'function' && !blurToCalendar) {
      this.datePicker.setShow(false);
    }
    */

    if (this.props.onChange) {
      this.props.onChange(this.props.field, finalValue);
    }

    if (this.props.onBlur && !blurToCalendar) {
      this.props.onBlur(this.props.field, finalValue, e);
    }
  }

  onFocusInput() {
    /*
    // hide calendar - DatePicker does not send onIconClick and onBlur events to control its state.
    // So I use protected function
    if (this.datePicker && typeof this.datePicker.setShow === 'function') {
      this.datePicker.setShow(false);
    }
    */

    if (this.props.onFocus) {
      this.props.onFocus(this.props.field, true);
    }
  }

  onFocusDatePicker() {
    if (this.props.onFocus) {
      this.props.onFocus(this.props.field, false);
    }

    if(this.props.field === 'dateOfBirth') {
      const datePickers = document.querySelectorAll('.k-calendar');
      for (let ind = 0, len = datePickers.length; ind < len; ind++) {
        const datePicker = datePickers && datePickers[ind];
        if (datePicker) {
          datePicker.setAttribute('data-private', '');
        }
      }
    }
  }

  onBlurDatePicker(e) {
    /*
    if (this.datePicker && typeof this.datePicker.setShow === 'function') {
      this.datePicker.setShow(false);
    }
    */

    if (this.props.onBlur) {
      // true = cannot blur. return focus back to input
      if (this.props.onBlur(this.props.field, this.state.strValue, e)) {
        if (this.input) {
          this.input.focus();
        }
      };
    }
  }

  onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (this.props.onKeyDown) {
      const val = this.state.strValue;
      const formatted = val ? reformatDateString(val) : '';
      const finalValue = formatted || val;

      this.props.onKeyDown(e, this.props.field, finalValue);
    }
  }

  render() {
    const id = `id-${uuid()}`;
    const { error, noWrap } = this.props;
    const disabled = typeof this.props.disabled !== 'undefined' && this.props.disabled === true;
    const className = !disabled
      ? `form-input ${this.props.inputClassName ? this.props.inputClassName : 'date-control-input'} k-input wrapped-input-control ${this.state.strValue ? ' has-text' : ''}`
      : `form-input k-state-disabled ${this.props.inputClassName ? this.props.inputClassName : 'date-control-input'} k-input wrapped-input-control ${this.state.strValue ? ' has-text' : ''}`;
    const wrapperClass = `${this.props.groupClassName} tc-datepicker`;

    const input = (
      <KInput
        ref={(ref) => { this.input = ref; }}
        id={this.props.inputId}
        className={className}
        value={this.state.strValue ? this.state.strValue : ''}
        onChange={disabled ? undefined : this.onChangeInput}
        onBlur={this.onBlurInput}
        onFocus={this.onFocusInput}
        onKeyDown={this.onKeyDown}
        onPaste={this.onPasteInput}
        readOnly={disabled}
        placeholder="MM/DD/YYYY"
        autoComplete="off"
        data-lpignore="true"
      />
    );

    const datePicker = (
      <KDatePicker
        ref={this.setDatePickerRef}
        id={id}
        name={this.props.name}
        className={this.props.className}
        value={this.state.dateValue}
        defaultValue={this.props.defaultValue}
        format={FORMAT}
        onChange={this.onChangeDatePicker}
        onFocus={this.onFocusDatePicker}
        onBlur={this.onBlurDatePicker}
        dateInput={CustomDateInput}
        calendar={CustomCalendar}
        tabIndex={-1}
        max={this.props.max}
      />);

    const control = disabled
      ?
      <div className={wrapperClass}>
        <div className="fullDiv">
          {input}
        </div>
      </div>
      :
      <div className={wrapperClass}>
        <div id={id} className="inputDiv">
          {input}
        </div>
        <div className="dateDiv">
          {datePicker}
        </div>
      </div>;

    return noWrap ? control :
      <ErrorWrap error={error} withLabel>
        <div className="k-textbox-container">
          {control}
          {this.props.label && <label className="k-label" htmlFor={id}>{this.props.label}</label>}
        </div>
      </ErrorWrap>;
  }

  private onPasteInput = () => {
    // want to handle text during change event. Text can be inserted as the part of the control value so I don't want to replace entire text.
    this.valueWasPasted = true;
  }

  private setDatePickerRef = (ref: KDatePicker | null) => {
    this.datePicker = ref;
    const el = ref ? (this.datePicker as any)._element : null;

    // there is no any valid way to handle onOpen event except private _element
    // I force input focusing during onMouseDown to correctly have blur event for the previously focused element
    if (this.datePicker && el) {
      el.onmousedown = this.onMouseDown;
      el.onclick = this.onClick;
    }
  }

  // focus input when we try to open calendar
  private onMouseDown = () => {
    if (!this.props.disabled && this.input) {
      this.input.focus();
    }
  }

  // do not allow to open calendar if we have client-side validation errors in other fields
  private onClick = (ev) => {
    if (this.props.disabled || (this.props.checkPreventOpen && this.props.checkPreventOpen(this.props.field))) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }
}
