import './CreateNewEncounter.less';

import * as React from 'react';
import * as History from 'history';

import { ChoiceListsState } from '../../../models/patientEncounter';
import { MultiColumnComboBox } from '../../MultiColumnComboBox/MultiColumnComboBox';

import { Input } from "../../Input/Input";
import {
  checkFieldForCollection, convertToComboboxModel} from '../../../utils/ddl';
import TruLink from '../../TruLink/TruLink';
import { getAvailableFacilities, PatientEncounterType } from '../../../models';
import { Button } from '../..';
import { IconType } from '../../Icon/Icon';
import { REGEX_ALPHANUMERIC_PATTERN } from '../../../utils/input';

export interface CreateNewEncounterProps {
  choiceLists: ChoiceListsState;
  handleCreateNewEncounter?: (choiceLists: ChoiceListsState, mrn?: string, facility?: string, encounterType?: string) => void;
  buildNewEncounterChoiceLists(choiceLists: ChoiceListsState, facility: string): void;
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  isEncountersPage?: boolean;
  onCloseNewEncounter: () => void;
  showCreateDrawer: boolean;

  getFacilitiesChoiceList: () => void;
  encounterOpened: boolean;
}

interface CreateNewEncounterState {
  mrn: string;
  facility: string;
  encounterType: PatientEncounterType | string;
}

export class CreateNewEncounter extends React.PureComponent<CreateNewEncounterProps, CreateNewEncounterState> {
  mrnInput: HTMLInputElement | null;
  closeBtn: TruLink | null;
  drawerChildren;

  constructor(opts: CreateNewEncounterProps) {
    super(opts);

    this.state = {
      mrn: '',
      facility: '',
      encounterType: ''
    };
  }

  componentDidMount() {
    if(this.mrnInput){
      this.mrnInput.focus();
    }
    window.addEventListener('keyup', this.keyPress);
    window.addEventListener('keydown', this.keyPress);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keyup', this.keyPress);
    window.removeEventListener('keydown', this.keyPress);
    this.drawerChildren = undefined;
  }

  private keyPress = (event) => {
    this.drawerChildren = document.querySelector('.k-drawer-items')?.querySelectorAll('#drawer-close-button, input:enabled, [tabindex="0"]');

    if(!this.drawerChildren) {
      return;
    }

    const {activeElement} = document;
    if(!activeElement) {
      return;
    }

    const drawerMainElementFocused = this.isDrawerElement(activeElement.id);

    switch(event.key) {
      case 'Tab':
        if(event.type === 'keydown'
          && (!drawerMainElementFocused)
          || (event.shiftKey && activeElement.id === 'hidden-button')) {
          event.preventDefault();
        }
        if(!drawerMainElementFocused) {
          if(event.shiftKey) {
            this.focusOnLastElement();
            return;
          }
          this.focusOnFirstElement();
        }
        break;
      default:
        break;
    }
  }

  private isDrawerElement = (id: string) => {
    if(!this.drawerChildren || id === 'hidden-button') {
      return false;
    }
    for(let i = 0; i < this.drawerChildren.length; i++) {
      if(this.drawerChildren[i].id === id) {
        return true;
      }
    }
    return false;
  }

  private focusOnFirstElement = () => {
    if(!this.drawerChildren) {
      return;
    }
    this.drawerChildren[0].focus();
  }

  private focusOnLastElement = () => {
    if(!this.drawerChildren) {
      return;
    }
    this.drawerChildren[this.drawerChildren.length-1].focus();
  }

  render() {
    const facilities = getAvailableFacilities(this.props.choiceLists.facilities);
    const encounterType = this.state.encounterType ?
      convertToComboboxModel(this.props.choiceLists.newEncounterEncounterTypes, this.state.encounterType) : undefined;
    const hasFacilities = facilities && facilities.length;
    const encounterFacility = hasFacilities && this.state.facility ?
      convertToComboboxModel(facilities, this.state.facility) : undefined;
    const canCreate = !!((this.state.mrn.length > 0 && encounterFacility && encounterType && hasFacilities));
    const createPath = this.props.encounterOpened ? `/create/${this.state.mrn}/${this.state.facility}/${this.state.encounterType}` : '/encounter/create';

    return (
      <div className="create-new-encounter-drawer">
        <div className="row-wrapped">
          <div className="control-wrapped" data-private>
            <Input
              inputRef={(ref) => { this.mrnInput = ref; }}
              label="MRN"
              name="medicalRecordNumber"
              value={this.state.mrn}
              maxLength={24}
              id='pe-MRN-Create'
              onCorrectValueBeforeBlur={this.onCorrectAccountNumber}
              pattern={REGEX_ALPHANUMERIC_PATTERN}
              onBlur={this.onBlur}
            />
          </div>
          <div className="clear" />
        </div>
        <div className="row-wrapped">
          <div className="control-wrapped">
            <MultiColumnComboBox
              valueField="ViewId"
              idField="id"
              titleField="title"
              data={facilities}
              hideClearButton
              onChange={this.onChangeFacility}
              field="facility"
              className="sorted combobox"
              label='Facility'
              firstColumnWidth={200}
              value={encounterFacility}
              onOpen={this.onOpenFacilitiesList}
              onFocus={this.onFocusFacilitiesDDL}
              loading={!!this.props.choiceLists.loadingNewEncounterFacilities}
            />
          </div>
          <div className="clear" />
        </div>
        <div className="row-wrapped">
          <div className="control-wrapped">
            <MultiColumnComboBox
              valueField="ViewId"
              idField="id"
              titleField="title"
              data={this.props.choiceLists.newEncounterEncounterTypes}
              hideClearButton
              onChange={this.onChangeEncounterType}
              field="type"
              className="sorted combobox"
              label='Encounter Type'
              value={encounterType}
              disabled={this.props.choiceLists.newEncounterEncounterTypes.length === 0 || this.state.facility === ''}
              loading={!!this.props.choiceLists.loadingNewEncounterEncounterTypes}
            />
          </div>
          <div className="clear" />
        </div >

        <TruLink
          to={{ pathname: createPath,  state: { newEncounter: true, mrn: this.state.mrn, facility: this.state.facility, encounterType: this.state.encounterType} }}
          clickHandler={canCreate ? this.handleCreateNewEncounter : undefined}
          preventNavigation={!canCreate}
          encounterOpened={this.props.encounterOpened}
          className="create-encounter-button"
          id="create-encounter-link"
          tabIndex={canCreate ? 0 : -1}
        >
          <Button className="k-primary" disabled={!canCreate} iconType={this.props.encounterOpened ? IconType.ExternalLink : undefined}
            iconPosition="right">Create</Button>
        </TruLink>

      </div>
    );
  }

  private handleCreateNewEncounter = () => {
    if (this.props.handleCreateNewEncounter && !!((this.state.mrn.length > 0 && this.state.facility && this.state.encounterType))) {
      if(this.props.showCreateDrawer) {
        this.props.onCloseNewEncounter();
      }
      this.props.handleCreateNewEncounter(this.props.choiceLists, this.state.mrn, this.state.facility, this.state.encounterType);
    }

    this.setState({
      mrn: '',
      facility: '',
      encounterType: ''
    })
  }

  private onChangeFacility = (field: string, id: string): void => {
    const { facilities } = this.props.choiceLists;
    const facility = checkFieldForCollection(id, facilities);

    this.setState({
      facility,
      encounterType: ''
    });

    if (facility !== '') {
      this.props.buildNewEncounterChoiceLists(this.props.choiceLists, facility);
    }
  }

  private onChangeEncounterType = (field: string, id: string): void => {
    const { newEncounterEncounterTypes } = this.props.choiceLists;
    const encounterType = checkFieldForCollection(id, newEncounterEncounterTypes);

    this.setState({
      encounterType,
    });
  }

  private onBlur = (fieldName: string, value: string): void => {
    this.setState({
      mrn: value,
    });
  };

  private onCorrectAccountNumber = (value) => {
    const newValue = value ? value.trim() : '';

    return newValue;
  }

  private onOpenFacilitiesList = () => {
    this.props.getFacilitiesChoiceList();
  }

  private onFocusFacilitiesDDL = () => {
    if (!this.props.choiceLists.loadingNewEncounterFacilities && this.props.choiceLists.facilities.length === 0) {
      this.onOpenFacilitiesList();
    }
  }
}
