import * as actionTypes from '../constants';

import { FacilitiesState } from '../scenes/Facility/models/facilitiesState';
import { FacilityEntity, FacilityTypeDropDownRow, MACDropDownRow } from '../scenes/Facility/models/facilities';

const createEmptyFacilitiesState = (): FacilitiesState => ({
  facilities: [],
  originalFacilities: [],

  facilityTypeDropDown: [],
  MACDropDown: [],

  initialized: false,
  facilitiesLoading: false,
  facilitiesSaving: false,
  errorTexts: [],
  showAddForm: false,
  hideInactiveItems: true,
});

export const facilitiesReducer = (state = createEmptyFacilitiesState(), action) => {
  switch (action.type) {
    case actionTypes.FACILITY_GET_FACILITIES_BEGIN:
      return handleGetFacilitiesStart(state);
    case actionTypes.FACILITY_GET_FACILITIES_COMPLETED:
      return handleGetFacilitiesCompleted(state, action.payload);
    case actionTypes.FACILITY_GET_FACILITIES_FAIL:
      return handleGetFacilitiesFailed(state, action.payload);
    case actionTypes.FACILITY_CHANGE:
      return handleChangeFacility(state, action.payload);
    case actionTypes.FACILITY_CONFIRM_CHANGE:
    case actionTypes.PREFERENCES_EDITED_FACILITY_UPDATED:
      return handleConfirmChangeFacility(state, action.payload);
    case actionTypes.FACILITY_DISCARD_CHANGE:
      return handleDiscardChangeFacility(state, action.payload);
    case actionTypes.FACILITY_REMOVE:
      return handleRemoveFacility(state, action.payload);
    case actionTypes.FACILITY_CREATE:
      return handleCreateFacility(state, action.payload);
    case actionTypes.FACILITY_CONFIRM_CREATE:
      return handleConfirmCreate(state, action.payload);
    case actionTypes.FACILITY_DISCARD_CREATE:
      return handleDiscardCreate(state, action.payload);
    case actionTypes.FACILITY_DISCARD_ALL_CHANGES:
      return handleDiscardAllChanges(state);
    case actionTypes.FACILITY_SET_FACILITY_TYPES_DROPDOWN:
      return handleSetFacilityTypesDropDown(state, action.payload);
    case actionTypes.FACILITY_SET_MAC_DROPDOWN:
      return handleSetMACDropDown(state, action.payload);
    case actionTypes.FACILITY_SET_ERROR:
      return handleSetError(state, action.payload);
    case actionTypes.FACILITY_SHOW_ADD_FORM:
      return handleShowAddForm(state, action.payload);
    case actionTypes.FACILITY_SET_SAVING:
      return handleSetFacilitiesSaving(state, action.payload);
    case actionTypes.FACILITY_TOGGLE_HIDE_INACTIVE_ITEMS:
      return handleToogleHideInactiveItems(state);
    case actionTypes.SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES:
      return handleClearDirtyChanges(state);
  
    default:
      return state;
  }
};

const handleGetFacilitiesStart = (state: FacilitiesState): FacilitiesState => {
  const newState = {
    ...state,
    facilitiesLoading: true,
    errorTexts: []
  };

  return newState;
}

const handleGetFacilitiesFailed = (state: FacilitiesState, error): FacilitiesState => {
  const newState = {
    ...state,
    facilitiesLoading: false,
    errorTexts: [error.message]
  };

  return newState;
}

const addMacNameToItem = (facility: FacilityEntity, MACDropDown: MACDropDownRow[]) => {
  if (facility.MacName) {
    return facility;
  }

  if (!facility.Mac) {
    return {
      ...facility,
      MacName: '',
    };
  }

  const macItem = MACDropDown.find((ddlItem) => ddlItem.Id === facility.Mac);

  // some facilities can have Mac Id, some can have single Mac value, try to cover both situations
  return {
    ...facility,
    MacName: macItem?.MacName || facility.Mac || "",
  }
}

const addMacNames = (facilities: FacilityEntity[], MACDropDown: MACDropDownRow[]) => {
  const newFacilities = facilities.map(
    (item) => addMacNameToItem(item, MACDropDown),
  );

  return newFacilities;
}

const handleGetFacilitiesCompleted = (state: FacilitiesState, payload: FacilityEntity[]): FacilitiesState => {
  const facilitiesWithMacNames = addMacNames(payload, state.MACDropDown);

  const newState = {
    ...state,
    initialized: true,
    facilitiesLoading: false,
    errorTexts: [],
    facilities: [...facilitiesWithMacNames],
    originalFacilities: [...facilitiesWithMacNames]
  };

  return newState;
}

const handleChangeFacility = (state: FacilitiesState, dataItem: FacilityEntity): FacilitiesState => {
  const newDataItem = addMacNameToItem(dataItem, state.MACDropDown);

  // it is used for some service fields, so errorTexts must be cleared separately
  const newState = {
    ...state,
    facilities: state.facilities.map(item => item.ViewId === dataItem.ViewId ? { ...newDataItem } : item)
  };

  return newState;
}

const handleRemoveFacility = (state: FacilitiesState, dataItem: FacilityEntity): FacilitiesState => {
  const newState = {
    ...state,
    facilities: state.facilities.filter(item => item.ViewId !== dataItem.ViewId),
    originalFacilities: state.originalFacilities.filter(item => item.ViewId !== dataItem.ViewId),
    errorTexts: []
  };

  return newState;
}

const handleCreateFacility = (state: FacilitiesState, dataItem: FacilityEntity): FacilitiesState => {
  const newDataItem = addMacNameToItem(dataItem, state.MACDropDown);
  
  const newState = {
    ...state,
    facilities: [newDataItem, ...state.facilities],
    errorTexts: []
  };

  return newState;
}

const handleConfirmCreate = (state: FacilitiesState, payload: { createdItemViewId: string, facility: FacilityEntity }): FacilitiesState => {
  const rawItem = { ...payload.facility, createdItemViewId: '', inEdit: undefined };
  const newItem = addMacNameToItem(rawItem, state.MACDropDown);

  // add directly in the grid
  if (!state.showAddForm) {
    const newState = {
      ...state,
      facilities: state.facilities.map(item => item.ViewId === payload.createdItemViewId ? newItem : item),
      originalFacilities: [{ ...newItem }, ...state.originalFacilities],
      errorTexts: []
    };

    return newState;
  }

  // add using form
  const newState = {
    ...state,
    facilities: [...state.facilities, { ...newItem }],
    originalFacilities: [...state.originalFacilities, { ...newItem }],
    errorTexts: [],
    showAddForm: false
  };

  return newState;
}

const handleDiscardCreate = (state: FacilitiesState, dataItem: FacilityEntity): FacilitiesState => {
  const newState = {
    ...state,
    facilities: state.facilities.filter(item => item.ViewId !== dataItem.ViewId),
    errorTexts: []
  };

  return newState;
}

const handleConfirmChangeFacility = (state: FacilitiesState, dataItem: FacilityEntity): FacilitiesState => {
  const rawItem = { ...dataItem, inEdit: undefined };
  const newItem = addMacNameToItem(rawItem, state.MACDropDown);


  const newState = {
    ...state,
    facilities: state.facilities.map(item => item.ViewId === dataItem.ViewId ? newItem: item),
    originalFacilities: state.originalFacilities.map(item => item.ViewId === dataItem.ViewId ? { ...newItem } : item),
    errorTexts: []
  };

  return newState;
}

const handleDiscardChangeFacility = (state: FacilitiesState, dataItem: FacilityEntity): FacilitiesState => {
  const original = state.originalFacilities.find(item => item.ViewId === dataItem.ViewId);
  if (!original) {
    return {
      ...state,
      facilities: state.facilities.filter(item => item.ViewId !== dataItem.ViewId),
      errorTexts: []
    }
  }

  const newState = {
    ...state,
    facilities: state.facilities.map(item => item.ViewId === original.ViewId ? { ...original } : item),
    errorTexts: []
  };

  return newState;
}

const handleDiscardAllChanges = (state: FacilitiesState): FacilitiesState => {
  const newState = {
    ...state,
    facilities: [...state.originalFacilities],
    errorTexts: []
  };

  return newState;
}

const handleSetFacilityTypesDropDown = (state: FacilitiesState, payload: FacilityTypeDropDownRow[]): FacilitiesState => {
  const newState = {
    ...state,
    facilityTypeDropDown: [...payload]
  };

  return newState;
}

const handleSetMACDropDown = (state: FacilitiesState, payload: MACDropDownRow[]): FacilitiesState => {
  const defaultRecord = {
    Mac: '',
    Id: '',
    MacName: '<Default for CCN>',
    State: ''
  }

  const newState = {
    ...state,
    MACDropDown: [defaultRecord, ...payload]
  };

  return newState;
}

const handleSetError = (state: FacilitiesState, errorTexts: string[]): FacilitiesState => {
  const newState = {
    ...state,
    errorTexts
  };

  return newState;
}

const handleShowAddForm = (state: FacilitiesState, showAddForm: boolean): FacilitiesState => {
  const newState = {
    ...state,
    showAddForm
  };

  return newState;
}

const handleSetFacilitiesSaving = (state: FacilitiesState, facilitiesSaving: boolean): FacilitiesState => {
  const newState = {
    ...state,
    facilitiesSaving
  };

  return newState;
}

const handleToogleHideInactiveItems = (state: FacilitiesState): FacilitiesState => {
  return {
    ...state,
    hideInactiveItems: !state.hideInactiveItems,
  }
}

const handleClearDirtyChanges = (state: FacilitiesState): FacilitiesState => {
  return {
    ...state,
    showAddForm: false,
  }
}
