import { get, post } from '../../utils/net';
import { FacilityEntity, FacilityTypeDropDownRow, MACDropDownRow, CCNDropDownData } from '../../scenes/Facility/models/facilities';
import { specialMACSorting } from '../../scenes/Facility/utils';

const baseURL = process.env.REACT_APP_API_PATH;
const groupingURL = process.env.REACT_APP_TEE_GRP;
const mneURL = process.env.REACT_APP_TEE_MNE;

const listFacilities = (): Promise<FacilityEntity[]> => {
  const url = `${baseURL}/api/facilities`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) =>
    mapFacilitiesResultToEntity(result.body)
  );
};

const mapFacilityResultToEntity = (facility: FacilityEntity): FacilityEntity => {
  return {
    ...facility,
    Mac: facility.Mac || ''
  }
}

const mapFacilitiesResultToEntity = (result: FacilityEntity[]): FacilityEntity[] => {
  if (!result || !result.length) {
    return [];
  }

  return result.map((facility) => {
    return mapFacilityResultToEntity(facility);
  });
}

const getFacilityTypesDropDown = (): Promise<FacilityTypeDropDownRow[]> => {
  const url = `${groupingURL}/groupinginfo/ccntypes`;
  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

const getCCNDropDown = (skip: number, pageSize: number, type: string, sortField: string, sortDir: string, filter: { value: string }): Promise<CCNDropDownData> => {
  const urlPart = `${groupingURL}/groupinginfo/cmscertificationnumbers/custom?`;
  const paginationPart = `Top=${pageSize}&Skip=${skip}`;
  const filterString = filter && filter.value;
  // const filterPart = filterString ? ` and (StartsWith(tolower(Ccn), tolower('${filterString}')) or Contains(tolower(Description), tolower('${filterString}')) or Contains(tolower(State), tolower('${filterString}')) )` : '';
  const filterPart = filterString ? `&Filter=${filterString}` : '';
  const filterAndTypePart = `&Match.Type=${type || "AcuteCare"}${filterPart}&Count=true`;

  const sortingPart = sortField && sortDir ? `&Sort${sortDir}.${sortField}=1` : '&SortAsc.Ccn=1';
  const url = `${urlPart}${paginationPart}${filterAndTypePart}${sortingPart}`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return {
      Total: result.body?.TotalCount || 0,
      CMSCertificationNumberInfos: result.body?.Items || [],
    }
  });
};

const getMACDropDown = (): Promise<MACDropDownRow[]> => {
  const url = `${mneURL}/MedicalNecessityEdits/FiscalIntermediaries`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    const unsorted = result.body;
    const sorted = specialMACSorting(unsorted, [{ field: 'Mac', dir: 'asc' }]);
    return sorted;
  });
};

const createFacility = (facility: FacilityEntity): Promise<FacilityEntity> => {
  const url = `${baseURL}/api/facilities`;

  const extraOptions = {
    useExactPath: true,
  };

  return post(url, facility, extraOptions).then((result) => {
    return mapFacilityResultToEntity(result.body);
  });
};

const updateFacility = (facility: FacilityEntity): Promise<FacilityEntity> => {
  const url = `${baseURL}/api/facilities`;

  const extraOptions = {
    useExactPath: true,
  };

  return post(url, facility, extraOptions).then((result) => {
    return mapFacilityResultToEntity(result.body);
  });
};

const getFacility = (facilityId: string): Promise<FacilityEntity> => {
  const url = `${baseURL}/api/facilities/${facilityId}`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) =>
    mapFacilityResultToEntity(result.body)
  );
};

export const facilitiesAPI = {
  listFacilities,
  getFacilityTypesDropDown,
  getCCNDropDown,
  getMACDropDown,
  createFacility,
  updateFacility,
  getFacility,
};

