import { isInvalidFormattedDate } from '../utils/date';

export function isRequiredFieldValidator(value?: string, message?: string): string {
  const errorMessage = message || 'Please fill in this mandatory field.';

  const succeeded = !!(value && value.replace(/&nbsp;/g, '').trim() !== '');

  return succeeded ? '' : errorMessage;
}

export function isDateValidator(value?: string, incorrectFutureDateMessage = '', checkMaxAge = false): string {
  if (!value) {
    return '';
  }

  const message = isInvalidFormattedDate(value, incorrectFutureDateMessage, checkMaxAge);
  
  return message;
}

export function longTextValidator(value?: string, maxLength?: number): string {
  if (!value || !maxLength) {
    return '';
  }

  const succeeded = value.length <= maxLength;

  return succeeded ? '' : 'Too long value.';
}

export function patternValidator(value: string, pattern: string): string {
  if (!value) {
    return '';
  }

  const regexp = new RegExp(pattern);

  if (!regexp.test(value)) {
    return 'Incorrect Value.';
  }

  return '';
}
