/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLogic } from 'redux-logic';
import * as actionTypes from '../constants';
import { LogicLifecycleParams } from '../reduxStore';
import { createEncounterAction } from '../scenes/Encounter/actions/createEncounter';

export const encounterNewLogic = createLogic({
  type: [actionTypes.CREATE_NEW_ENCOUNTER],
  latest: true, // take latest only

  process({ action, getState }: LogicLifecycleParams, dispatch: any, done: any) {
    dispatch(createEncounterAction(action.payload, getState().codeFormats.initialized));
    done();
  }
});

// multiple logic groups can be exported here
export default [
  encounterNewLogic,
];
