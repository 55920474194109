/* eslint-disable no-param-reassign */
import * as moment from 'moment';
import { Key } from '../../constants/keyboard';
import { EncounterState, ADMIT_DX_ID, GridCodeType, EncounterEntity, CodeType } from '../../models';
import {
  CodeGridChange,
  CodeGridCodeBlur,
  CodeGridKeypress,
  CodeGridDragDropRow,
  CodeGridExitEditInfo,
} from '../../scenes/Encounter/actions/codeGrid';
import { updateCodes, getId, cloneEncounterEntity } from './encounterCommon';
import { CodeBooksModalCodePosted, CodeBooksModalCancelled } from '../../components/TEEModals/codeBooksModalActions';
import { BaseCodeRow, CodeGroup, PresentOnAdmission } from '../../models/codeGrid';
import { PDxAnalyzeResultEntity } from '../../models/groupingEntity';
import { combineEncounterCodes, resequenceCodes } from '../../utils/encounter';
import { CodeGridsColumns } from '../../models/columns';
import { FieldSettings, FieldSetting, FacilitySettingsState, FacilityPreferences } from '../../models/facilitySettings';
import { getColumnFieldSetting, isHiddenField, isReadOnlyField } from '../../utils/fieldSettings';
import { isInvalidFormattedDate } from '../../utils/date';
import { isDateValidator } from '../../validators/simpleValidators';
import { checkDDLFieldLength, MAX_PROVIDER_LENGTH } from '../../services/encounter/encounterMapping';
import { IdDescriptionBase } from '../../models/patientEncounter';
import { EditLocateEvent } from '../../scenes/Encounter/actions/researchPane';

export const handleCodeGridChange = (state: EncounterState, payload: CodeGridChange): EncounterState => {
  // no need to update admit diagnosis code here
  if (payload.dataItem.id === ADMIT_DX_ID) {
    return state;
  }

  // update currently edited row. It will not be sent to the services until blur
  const item = findItem(state.inProgress, payload.dataItem);
  if (!item) {
    return state;
  }

  let { dirty } = state;
  const inProgress = {
    ...state.inProgress,
  };
  const newItem = {
    ...payload.dataItem,
    autoChanged: item.autoChanged || ([] as string[]),
    inEdit: item.inEdit,
    editField: item.editField,
  };

  if (typeof payload.field !== 'undefined' && typeof payload.value !== 'undefined') {
    if (!dirty && newItem[payload.field] !== payload.value) {
      dirty = true;
    }

    // autofill using Episode
    if (payload.field === 'episode' && payload.value && newItem[payload.field] !== payload.value) {
      const fillFromCode = findEpisodeCode(inProgress, newItem.gridCodeType, payload.value, newItem.id);
      if (fillFromCode) {
        if (newItem.date !== fillFromCode.date) {
          newItem.date = fillFromCode.date;
          newItem.autoChanged.push('date');
        }
        if (newItem.provider !== fillFromCode.provider) {
          newItem.provider = fillFromCode.provider;
          newItem.autoChanged.push('provider');
        }
      }
    }

    newItem[payload.field] = payload.value;
    if (payload.field === 'description') {
      // description is generated field. duplicate it in the user field
      newItem.customCodeDescription = payload.value;
    }
    newItem.valid = undefined;
    if (!(payload.field === 'code' && payload.value.trim() === '')) {
      newItem.empty = false;
    }
    // remove current edit field to prevent focus from switching incorrectly
    newItem.editField = undefined;
  }

  updateItem(inProgress, payload.dataItem.gridCodeType, payload.dataItem.id, newItem);
  updateCodes(inProgress);

  return {
    ...state,
    dirty,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleCodeGridAutoChange = (state: EncounterState, payload: CodeGridChange): EncounterState => {
  // update currently edited row. It will not be sent to the services until blur
  const item = findItem(state.inProgress, payload.dataItem);
  if (!item || !item.autoChanged || !item.autoChanged.length) {
    return state;
  }

  const { dirty } = state;
  const inProgress = {
    ...state.inProgress,
  };
  const newItem = {
    ...item,
    // remove all autoChanged.
    // TODO: if this expands to more fields than provider/date then more complicated logic will need to be written
    autoChanged: [] as string[],
  };

  updateItem(inProgress, payload.dataItem.gridCodeType, payload.dataItem.id, newItem);
  updateCodes(inProgress);

  return {
    ...state,
    dirty,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleCodeBooksModalCodePostedAdmitDX = (state: EncounterState, payload: CodeBooksModalCodePosted) => {
  if (!payload.codes || payload.codes.length === 0) {
    return state;
  }

  const isSameCode = state.inProgress.admitDiagnosisCode.initialCode === payload.codes[0].code;

  return {
    ...state,
    dirty: true,
    inProgress: {
      ...state.inProgress,
      admitDiagnosisCode: {
        ...state.inProgress.admitDiagnosisCode,
        code: payload.codes[0].code,
        description: isSameCode ? state.inProgress.admitDiagnosisCode.description : undefined,
        customCodeDescription: isSameCode ? state.inProgress.admitDiagnosisCode.customCodeDescription : undefined,
        ServiceCodeDescription: isSameCode ? state.inProgress.admitDiagnosisCode.ServiceCodeDescription : undefined,
        gridCodeType: GridCodeType.ADMIT_DX,
        editField: 'code',
        inEdit: true,
        initialCode: payload.codes[0].code,
        serviceCode: payload.codes[0].code,
        type: payload.codes[0].codeType,
        loaded: isSameCode ? state.inProgress.admitDiagnosisCode.loaded : undefined,
      },
      lastActiveCodeId: state.inProgress.admitDiagnosisCode.id,
    },
    lastChangeTime: moment.now(),
  };
};

export const handleCodeBooksModalCodePosted = (state: EncounterState, payload: CodeBooksModalCodePosted) => {
  // LOGIC can ignore posted code if we have validation errors and keep focus on the wrong field

  // update admit diagnosis code
  if (payload.dataItem && payload.dataItem.id === ADMIT_DX_ID) {
    return handleCodeBooksModalCodePostedAdmitDX(state, payload);
  }

  const len = payload.codes.length;

  if (len === 0) {
    return state;
  }

  const inProgress = {
    ...state.inProgress,
  };
  exitEditAll(inProgress);

  // for each code from the same codegroup with edited dataItem - insert them starting from this position
  // for each code for other grids - add them to the end
  // inEdit will be set for the first added code only if we have active dataItem

  let inEdit = payload.dataItem.gridCodeType ? true : undefined;
  let lastActiveCode = inProgress.lastActiveCodeId;
  let mainGroup: GridCodeType | null = null;
  const firstCode = payload.codes[0];
  const addedCodesIndexes = {};

  // we need to insert all payload.codes of the same type from the currently edited item position if possible
  if (canBePlacedToGrid(firstCode.codeType, payload.dataItem.gridCodeType)) {
    const codeGroup = inProgress[payload.dataItem.gridCodeType];
    const collection = codeGroup.codes;
    const newCollection: BaseCodeRow[] = [];
    const codesLen = collection.length;
    for (let pos = 0; pos < codesLen; pos++) {
      if (collection[pos].id === payload.dataItem.id) {
        lastActiveCode = collection[pos].id;
        const isSameCode = payload.dataItem.code === firstCode.code;
        const newItem = {
          ...payload.dataItem,
          code: firstCode.code,
          editField: payload.field,
          initialCode: firstCode.code,
          empty: false,
          inEdit: true,
          serviceCode: firstCode.code,
          type: firstCode.codeType,
          customCodeDescription: isSameCode ? payload.dataItem.customCodeDescription : undefined,
          ServiceCodeDescription: isSameCode ? payload.dataItem.ServiceCodeDescription : undefined,
          modifiers: isSameCode ? payload.dataItem.modifiers : [],
          units: isSameCode ? payload.dataItem.units : undefined,
          date: autofillProcedureDate(inProgress, payload.dataItem.gridCodeType, payload.dataItem),
          presentOnAdmission: isSameCode ? payload.dataItem.presentOnAdmission : PresentOnAdmission.EMPTY,
          // do not replace filled provider and revenue code with default for the existing codes
          provider:
            isSameCode || payload.dataItem.provider
              ? payload.dataItem.provider
              : autofillDefaultProvider(inProgress, payload.dataItem.gridCodeType, payload.facilitySettings),
          revenueCode:
            isSameCode || payload.dataItem.revenueCode
              ? payload.dataItem.revenueCode
              : autofillDefaultRevenueCode(inProgress, payload.dataItem.gridCodeType, payload.facilitySettings),
          loaded: isSameCode ? payload.dataItem.loaded : undefined,
        };

        addedCodesIndexes[0] = true;

        newCollection.push(newItem);
        inEdit = undefined;
        mainGroup = payload.dataItem.gridCodeType;

        // insert all other codes that can be added to this grid from this position
        for (let ind = 1; ind < len; ind++) {
          const code = payload.codes[ind];
          if (canBePlacedToGrid(code.codeType, payload.dataItem.gridCodeType)) {
            newCollection.push({
              code: code.code,
              empty: false,
              gridCodeType: payload.dataItem.gridCodeType,
              id: getId(),
              inEdit: undefined,
              editField: undefined,
              initialCode: code.code,
              serviceCode: code.code,
              type: code.codeType,
              date: autofillProcedureDate(inProgress, payload.dataItem.gridCodeType),
              provider: autofillDefaultProvider(inProgress, payload.dataItem.gridCodeType, payload.facilitySettings),
              revenueCode: autofillDefaultRevenueCode(
                inProgress,
                payload.dataItem.gridCodeType,
                payload.facilitySettings
              ),

              Validations: [],
            });

            addedCodesIndexes[ind] = true;
          }
        }
      }

      // skip current item to add rest of this codegroup codes
      if (collection[pos].id !== payload.dataItem.id && !collection[pos].empty) {
        newCollection.push(collection[pos]);
      }
    }

    inProgress[payload.dataItem.gridCodeType].codes = newCollection;
  }

  // place every other code to the end of its group
  function addCodesToCodeGroup(codesGroup: CodeGroup, gridCodeType: GridCodeType) {
    if (gridCodeType === mainGroup) {
      // already handled
      return codesGroup;
    }

    const newCodes: BaseCodeRow[] = [];
    for (let ind = 0, codesGroupLen = codesGroup.codes.length; ind < codesGroupLen; ind++) {
      if (!codesGroup.codes[ind].empty) {
        newCodes.push(codesGroup.codes[ind]);
      }
    }

    for (let ind = 0; ind < len; ind++) {
      const code = payload.codes[ind];
      // code added from the codebooks should be focused on (last code only)
      inEdit = ind === (len-1);
      if (!addedCodesIndexes[ind] && canBePlacedToGrid(code.codeType, gridCodeType)) {
        const newId = getId();
        newCodes.push({
          code: code.code,
          empty: false,
          gridCodeType,
          id: newId,
          inEdit,
          editField: inEdit ? payload.field : undefined,
          initialCode: code.code,
          serviceCode: code.code,
          type: code.codeType,
          date: autofillProcedureDate(inProgress, gridCodeType),
          provider: autofillDefaultProvider(inProgress, gridCodeType, payload.facilitySettings),
          revenueCode: autofillDefaultRevenueCode(inProgress, gridCodeType, payload.facilitySettings),

          Validations: [],
        });

        if (inEdit) {
          lastActiveCode = newId;
        }

        inEdit = undefined;
        addedCodesIndexes[ind] = true;
      }
    }

    return {
      ...codesGroup,
      codes: newCodes,
    };
  }

  // both diagnoses and visitReasons can hold same codes. Use gridCodeType from the dataItem if possible
  if (payload.dataItem && payload.dataItem.gridCodeType === GridCodeType.VISITREASONS) {
    inProgress.visitReasons = addCodesToCodeGroup(inProgress.visitReasons, GridCodeType.VISITREASONS);
    inProgress.diagnoses = addCodesToCodeGroup(inProgress.diagnoses, GridCodeType.DIAGNOSES);
  } else {
    inProgress.diagnoses = addCodesToCodeGroup(inProgress.diagnoses, GridCodeType.DIAGNOSES);
    inProgress.visitReasons = addCodesToCodeGroup(inProgress.visitReasons, GridCodeType.VISITREASONS);
  }

  inProgress.inProcedures = addCodesToCodeGroup(inProgress.inProcedures, GridCodeType.INPROCEDURES);
  inProgress.outProcedures = addCodesToCodeGroup(inProgress.outProcedures, GridCodeType.OUTPROCEDURES);

  // it will create empty items for each type
  updateCodes(inProgress);
  inProgress.lastActiveCodeId = lastActiveCode;

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleCodeBooksModalCancelled = (state: EncounterState, payload: CodeBooksModalCancelled) => {
  // LOGIC can ignore this action if we have validation errors and keep focus on the wrong field

  // update admit diagnosis code
  if (payload.dataItem && payload.dataItem.id === ADMIT_DX_ID) {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        admitDiagnosisCode: {
          ...state.inProgress.admitDiagnosisCode,
          editField: 'code',
          inEdit: true,
        },
        lastActiveCodeId: state.inProgress.admitDiagnosisCode.id,
      },
    };
  }

  if (!payload.dataItem) {
    return state;
  }

  const inProgress = {
    ...state.inProgress,
  };

  exitEditAll(inProgress);
  const collection = inProgress[payload.dataItem.gridCodeType].codes;

  for (let ind = 0, len = collection.length; ind < len; ind++) {
    if (collection[ind].id === payload.dataItem.id) {
      collection[ind].inEdit = true;
      inProgress.lastActiveCodeId = collection[ind].id;
      break;
    }
  }

  return {
    ...state,
    inProgress,
  };
};

export const handleCodeGridCodeBlurAdmitDX = (state: EncounterState, payload: CodeGridCodeBlur): EncounterState => {
  const isSameCode = state.inProgress.admitDiagnosisCode.initialCode === payload.value;
  return {
    ...state,
    dirty: isSameCode ? state.dirty : true,
    inProgress: {
      ...state.inProgress,
      admitDiagnosisCode: {
        ...state.inProgress.admitDiagnosisCode,
        ...payload.dataItem,
        code: payload.value,
        inEdit: undefined,
        initialCode: payload.value,
        customCodeDescription: isSameCode ? state.inProgress.admitDiagnosisCode.customCodeDescription : undefined,
        description: isSameCode ? state.inProgress.admitDiagnosisCode.description : undefined,
        ServiceCodeDescription: isSameCode ? state.inProgress.admitDiagnosisCode.ServiceCodeDescription : undefined,
        loaded: isSameCode ? state.inProgress.admitDiagnosisCode.loaded : undefined,
      },
    },
    lastChangeTime: isSameCode ? state.lastChangeTime : moment.now(),
  };
};

// clear any empty row except current row and focused row
const handleClearEmptyNotFocusedRowsInGrid = (inProgress: EncounterEntity, dataItem: BaseCodeRow): EncounterEntity => {
  const collection = inProgress[dataItem.gridCodeType].codes;

  const newCollection: BaseCodeRow[] = [];
  for (let ind = 0, len = collection.length; ind < len; ind++) {
    if (
      !(!collection[ind].empty && collection[ind].code.trim() === '') ||
      collection[ind].inEdit ||
      collection[ind].id === dataItem.id
    ) {
      newCollection.push(collection[ind]);
    }
  }

  inProgress[dataItem.gridCodeType] = {
    ...inProgress[dataItem.gridCodeType],
    codes: newCollection,
  };

  return inProgress;
};

export const handleCodeGridCodeBlur = (state: EncounterState, payload: CodeGridCodeBlur): EncounterState => {
  // update admit diagnosis code
  if (payload.dataItem && payload.dataItem.id === ADMIT_DX_ID) {
    return handleCodeGridCodeBlurAdmitDX(state, payload);
  }

  let inProgress = cloneEncounterEntity(state.inProgress);

  inProgress = handleClearEmptyNotFocusedRowsInGrid(inProgress, payload.dataItem);

  const codeGroup = inProgress[payload.dataItem.gridCodeType];
  const collection = codeGroup.codes;

  // remove this dataItem if we clear code
  if (!payload.dataItem.empty && payload.value.trim() === '' && !payload.blurToCodebooks) {
    const newCollection: BaseCodeRow[] = [];
    for (let ind = 0, len = collection.length; ind < len; ind++) {
      if (collection[ind].id !== payload.dataItem.id) {
        newCollection.push(collection[ind]);
      }
    }

    const newCodeGroup = { ...codeGroup, codes: newCollection };
    inProgress[payload.dataItem.gridCodeType] = newCodeGroup;
    updateCodes(inProgress);

    return {
      ...state,
      inProgress,
      lastChangeTime: moment.now(),
    };
  }

  // check if code is different from the service value. Do it only after blur
  if (payload.value !== payload.dataItem.serviceCode) {
    // update currently edited row
    const item = findItem(state.inProgress, payload.dataItem);
    if (!item) {
      return state;
    }

    // autofill date only for the new entered codes
    const procedureDate =
     (!payload.dataItem.initialCode || !payload.dataItem.serviceCode) && payload.dataItem.code
       ? autofillProcedureDate(inProgress, payload.dataItem.gridCodeType, payload.dataItem)
       : payload.dataItem.date;
    const provider =
      (!payload.dataItem.initialCode || !payload.dataItem.serviceCode) && payload.dataItem.code && !payload.dataItem.provider
        ? autofillDefaultProvider(inProgress, payload.dataItem.gridCodeType, payload.facilitySettings)
        : payload.dataItem.provider;
    const revenueCode =
      (!payload.dataItem.initialCode || !payload.dataItem.serviceCode) && payload.dataItem.code && !payload.dataItem.revenueCode
        ? autofillDefaultRevenueCode(inProgress, payload.dataItem.gridCodeType, payload.facilitySettings)
        : payload.dataItem.revenueCode;

    const newItem = {
      ...payload.dataItem,

      inEdit: item.inEdit,
      editField: item.editField,

      presentOnAdmission: PresentOnAdmission.EMPTY,
      serviceCode: payload.value,
      loaded: undefined, // mark changed code as not synchronized with services
      customCodeDescription: undefined,
      ServiceCodeDescription: undefined,
      modifiers: [],
      units: undefined,
      date: procedureDate,
      provider,
      revenueCode,
    };

    updateItem(inProgress, payload.dataItem.gridCodeType, payload.dataItem.id, newItem);
    updateCodes(inProgress);

    return {
      ...state,
      inProgress,
      lastChangeTime: moment.now(),
    };
  }

  // ignore equal values in the existing cells
  if (payload.value === payload.dataItem.initialCode || (!payload.value && !payload.dataItem.initialCode)) {
    return state;
  }

  // recheck all codes if we have any changes
  updateCodes(inProgress);

  return {
    ...state,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

// blur from any cell - analyze changes here
export const handleCodeGridFieldBlur = (state: EncounterState, dataItem: BaseCodeRow): EncounterState => {
  // LOGIC can ignore this action if we have validation errors and keep focus on the wrong field

  if (!dataItem || dataItem.id === ADMIT_DX_ID) {
    return state;
  }

  const item = findItem(state.inProgress, dataItem);
  if (!item) {
    return state;
  }

  // check description changes
  const customDescription = dataItem.customCodeDescription ? dataItem.customCodeDescription : '';
  let needUpdate = false;
  if (customDescription) {
    const codeDescription = dataItem.ServiceCodeDescription;
    // description was changed
    if (customDescription !== codeDescription && customDescription !== dataItem.serviceCustomDescription) {
      needUpdate = true;
    }
  } else if (dataItem.serviceCustomDescription) {
    // description was cleared
    needUpdate = true;
  }

  if (needUpdate) {
    // update changed description on the services side
    const inProgress = {
      ...state.inProgress,
    };

    const newItem = {
      ...dataItem,
      serviceCustomDescription: customDescription,
    };

    updateItem(inProgress, dataItem.gridCodeType, dataItem.id, newItem);
    updateCodes(inProgress);

    return {
      ...state,
      inProgress,
      lastChangeTime: moment.now(),
    };
  }

  return state;
};

export const handleCodeGridKeypress = (state: EncounterState, payload: CodeGridKeypress): EncounterState => {
  // LOGIC can ignore this action if we have validation errors and keep focus on the wrong field

  // ignore navigation in pdx analysis mode
  const { isShowingPdxAnalysis } = payload;
  if (isShowingPdxAnalysis) {
    // special situation: Tab-Enter from AdmitDX in pdx analysis mode
    const shiftEnterFromAdmitDx = payload.field === 'code' && payload.key === Key.Enter && payload.shiftKey;
    if (
      payload.dataItem.id === ADMIT_DX_ID &&
      (payload.key === Key.Tab || payload.key === Key.Enter) &&
      !shiftEnterFromAdmitDx
    ) {
      const inProgress = cloneEncounterEntity(state.inProgress);

      // skip AdmitDX Description for empty code
      const currentAdmitDX = (inProgress.admitDiagnosisCode.code || '').trim();
      if (
        (payload.fieldSettings && isHiddenField(payload.fieldSettings, 'AdmitDiagnosis.Description')) ||
        currentAdmitDX === ''
      ) {
        inProgress.admitDiagnosisCode = {
          ...inProgress.admitDiagnosisCode,
          editField: 'code',
          inEdit: true,
        };
        inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;
      } else {
        inProgress.admitDiagnosisCode = {
          ...inProgress.admitDiagnosisCode,
          editField: 'description',
          inEdit: true,
        };
        inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;
      }

      updateCodes(inProgress);

      return {
        ...state,
        inProgress,
      };
    }

    return state;
  }

  // not PDX Analysis mode
  switch (payload.key) {
    case Key.Enter: {
      const inProgress = cloneEncounterEntity(state.inProgress);

      // shift-Enter - navigate one row up to the same column
      if (payload.shiftKey) {
        handlePrevRowFocus(inProgress, payload.dataItem, payload.field || 'code', true, payload);
        // control-Enter - navigate one row down to the code column
      } else if (payload.ctrlKey) {
        handleNextRowFocus(inProgress, payload.dataItem, 'code', true, payload);
        // usual Enter - navigate one row down to the same column
      } else {
        handleNextRowFocus(inProgress, payload.dataItem, payload.field || 'code', true, payload);
      }

      updateCodes(inProgress);

      return {
        ...state,
        inProgress,
      };
    }

    // shif + Tab - previous field
    case Key.Tab: {
      const inProgress = cloneEncounterEntity(state.inProgress);

      const columns = getGridColumns(
        payload.dataItem.gridCodeType,
        isShowingPdxAnalysis,
        payload.columnsSettings,
        payload.fieldSettings
      );

      // back direction
      if (payload.shiftKey) {
        const previousField = getPreviousField(columns, payload.field || '');
        if (previousField) {
          handleCurrentRowFocus(inProgress, payload.dataItem, previousField);
        } else {
          const lastField = getPreviousField(columns, '');
          handlePrevRowFocus(inProgress, payload.dataItem, lastField, false, payload);
        }

        updateCodes(inProgress);

        return {
          ...state,
          inProgress,
        };
      }

      // forward direction
      if (payload.dataItem.id === ADMIT_DX_ID) {
        // skip AdmitDX Description for empty code
        const currentAdmitDX = (inProgress.admitDiagnosisCode.code || '').trim();
        if (
          (payload.fieldSettings && isHiddenField(payload.fieldSettings, 'AdmitDiagnosis.Description')) ||
          currentAdmitDX === ''
        ) {
          // focus next grid if description is hidden
          const nextGrid = nextGridType(
            GridCodeType.ADMIT_DX,
            payload.fieldSettings || {},
            isShowingPdxAnalysis,
            payload && payload.facilityPreferences,
            payload && payload.inpatientEncounter
          );
          focusFirstGridRow(inProgress, nextGrid, payload);
        } else {
          inProgress.admitDiagnosisCode = {
            ...inProgress.admitDiagnosisCode,
            editField: 'description',
            inEdit: true,
          };
          inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;
        }
      } else {
        let nextField = getNextField(columns, payload.field || '');
        // navigate to the next row / grid from the empty lines
        if (payload.field === 'code') {
          const item = findItem(inProgress, payload.dataItem);
          if (item && item.empty) {
            nextField = '';
          }
        }

        // special situation: tab after code deleting - focus first cell in the row that would replace deleted one
        const justDeletedRowIndex = getJustDeletedRowIndex(payload);

        if (nextField && justDeletedRowIndex === -1) {
          handleCurrentRowFocus(inProgress, payload.dataItem, nextField);
        } else {
          const firstField = getNextField(columns, '');
          handleNextRowFocus(
            inProgress,
            payload.dataItem,
            firstField,
            justDeletedRowIndex !== -1,
            payload
          );
        }
      }

      updateCodes(inProgress);

      return {
        ...state,
        inProgress,
      };
    }

    case Key.Escape: {
      // simple exit editing mode for not 'code' fields
      if (payload.field !== 'code') {
        const inProgress = {
          ...state.inProgress,
          lastActiveCodeId: undefined,
        };
        exitEditAll(inProgress);

        updateCodes(inProgress);

        return {
          ...state,
          inProgress,
        };
      }

      // restore initial code for the 'code' field
      if (payload.dataItem.id === ADMIT_DX_ID) {
        return {
          ...state,
          inProgress: {
            ...state.inProgress,
            admitDiagnosisCode: {
              ...state.inProgress.admitDiagnosisCode,
              code: state.inProgress.admitDiagnosisCode.initialCode,
              inEdit: undefined,
            },
            lastActiveCodeId: undefined,
          },
          lastChangeTime: moment.now(),
        };
      }

      const inProgress = {
        ...state.inProgress,
      };

      const item = findItem(state.inProgress, payload.dataItem);
      if (!item) {
        return state;
      }

      exitEditAll(inProgress);
      inProgress.lastActiveCodeId = undefined;

      const newCode = item.empty ? '' : item.initialCode;

      const newItem = {
        ...payload.dataItem,
        inEdit: undefined,
        code: newCode,
      };

      if (newCode) {
        updateItem(inProgress, payload.dataItem.gridCodeType, payload.dataItem.id, newItem);
      } else {
        // delete row
        const newCollection: BaseCodeRow[] = [];
        const { codes } = inProgress[payload.dataItem.gridCodeType];
        for (let ind = 0, len = codes.length; ind < len; ind++) {
          if (codes[ind].id !== item.id) {
            newCollection.push(codes[ind]);
          }
        }

        inProgress[payload.dataItem.gridCodeType].codes = newCollection;

        updateCodes(inProgress);

        return {
          ...state,
          inProgress,
        };
      }

      updateCodes(inProgress);

      return {
        ...state,
        inProgress,
        lastChangeTime: moment.now(),
      };
    }

    case Key.G:
      const inProgress = {
        ...state.inProgress,
      };

      exitEditAll(inProgress);
      if (payload.dataItem.id === ADMIT_DX_ID) {
        if (payload.fieldSettings) {
          const nextGrid = nextGridType(
            GridCodeType.ADMIT_DX,
            payload.fieldSettings,
            isShowingPdxAnalysis,
            payload && payload.facilityPreferences,
            payload && payload.inpatientEncounter
          );
          focusFirstGridRow(inProgress, nextGrid, payload);
        }
      } else {
        const item = findItem(state.inProgress, payload.dataItem);
        if (!item) {
          return state;
        }
        const isShowingPdxAnalysis = payload && payload.isShowingPdxAnalysis;
        const nextGrid = nextGridType(
          item.gridCodeType,
          (payload && payload.fieldSettings) || {},
          isShowingPdxAnalysis,
          payload && payload.facilityPreferences,
          payload && payload.inpatientEncounter
        );

        focusFirstGridRow(inProgress, nextGrid, payload);
      }

      return {
        ...state,
        inProgress,
      };
    default:
      break;
  }

  return state;
};

function getPreviousField(columns: string[], field: string) {
  let ind = columns.length - 1;
  while (ind >= 0) {
    if (columns[ind] === field) {
      break;
    }
    ind--;
  }

  // last field
  if (field === '') {
    ind = columns.length;
  }

  if (ind > 0) {
    return columns[ind - 1];
  }

  return '';
}

function getNextField(columns: string[], field: string) {
  const len = columns.length;
  if (len === 0) {
    return '';
  }

  let ind = 0;
  while (ind < len) {
    if (columns[ind] === field) {
      break;
    }

    ind++;
  }

  // first field
  if (field === '') {
    ind = -1;
  }

  if (ind < len - 1) {
    return columns[ind + 1];
  }

  return '';
}

function getFocusableFields(columnsSettings: CodeGridsColumns, gridKey: string, fieldSettings?: FieldSettings) {
  const defaultColumns = ['code', 'description'];
  if (!columnsSettings[gridKey]) {
    return defaultColumns;
  }

  const { columnsOrder } = columnsSettings[gridKey];
  const columns: string[] = [];
  for (let ind = 0, len = columnsOrder.length; ind < len; ind++) {
    const columnKey = columnsOrder[ind];
    const column = columnsSettings[gridKey].columnsInfo[columnKey];
    // no need to filter by OP grouping because all this columns are not focusable
    const fieldSetting: FieldSetting = fieldSettings ? getColumnFieldSetting(fieldSettings, gridKey, columnKey) : {};

    if (column && !column.noEditHack && column.focusField && !fieldSetting.hidden) {
      columns.push(column.focusField);
    }
  }

  return columns;
}

function getGridColumns(
  gridCodeType: GridCodeType,
  isShowingPdxAnalysis?: boolean,
  columnsSettings?: CodeGridsColumns,
  fieldSettings?: FieldSettings
) {
  const defaultColumns = ['code', 'description'];
  if (!columnsSettings) {
    return defaultColumns;
  }

  switch (gridCodeType) {
    case GridCodeType.DIAGNOSES:
      if (isShowingPdxAnalysis) {
        return columnsSettings.diagnosesPdxOn
          ? getFocusableFields(columnsSettings, 'diagnosesPdxOn', fieldSettings)
          : defaultColumns;
      }

      return columnsSettings.diagnosesPdxOff
        ? getFocusableFields(columnsSettings, 'diagnosesPdxOff', fieldSettings)
        : defaultColumns;
    case GridCodeType.VISITREASONS:
      return columnsSettings.visitReasons
        ? getFocusableFields(columnsSettings, 'visitReasons', fieldSettings)
        : defaultColumns;
    case GridCodeType.INPROCEDURES:
      return columnsSettings.inProcedures
        ? getFocusableFields(columnsSettings, 'inProcedures', fieldSettings)
        : defaultColumns;
    case GridCodeType.OUTPROCEDURES:
      return columnsSettings.outProcedures
        ? getFocusableFields(columnsSettings, 'outProcedures', fieldSettings)
        : defaultColumns;
    default:
  }

  return defaultColumns;
}

// special situation with navigation from the just deleted code
const getJustDeletedRowIndex = (payload?: CodeGridKeypress) => {
  if (
    payload &&
    payload.dataIndex !== undefined &&
    payload.field === 'code' &&
    (payload.dataItem.code || '').trim() === '' &&
    !payload.dataItem.empty
  ) {
    return payload.dataIndex;
  }

  return -1;
};

// moving in the current grid or to the next grid
function handleNextRowFocus(
  inProgress: EncounterEntity,
  item: BaseCodeRow,
  fieldName: string,
  currentGridOnly: boolean,
  payload?: CodeGridKeypress
) {
  if (item.id === ADMIT_DX_ID) {
    const code = inProgress.admitDiagnosisCode;
    const currentAdmitDX = (inProgress.admitDiagnosisCode.code || '').trim();

    exitEditAll(inProgress);

    if (
      (payload && payload.fieldSettings && isHiddenField(payload.fieldSettings, 'AdmitDiagnosis.Description')) ||
      currentAdmitDX === ''
    ) {
      // focus next grid if description is hidden or we have not admitDX code
      const isShowingPdxAnalysis = payload && payload.isShowingPdxAnalysis;
      const nextGrid = nextGridType(
        item.gridCodeType,
        (payload && payload.fieldSettings) || {},
        isShowingPdxAnalysis,
        payload && payload.facilityPreferences,
        payload && payload.inpatientEncounter
      );
      focusFirstGridRow(inProgress, nextGrid, payload);
    } else {
      code.inEdit = true;
      code.editField = 'description';
      inProgress.lastActiveCodeId = code.id;
    }

    return;
  }

  // special situation: tab or enter after code deleting
  const justDeletedRowIndex = getJustDeletedRowIndex(payload);

  const collection = inProgress[item.gridCodeType].codes;

  for (let ind = 0, len = collection.length; ind < len; ind++) {
    if (collection[ind].id === item.id || ind === justDeletedRowIndex) {
      exitEditAll(inProgress);

      // navigate to the next grid
      if (!currentGridOnly && ind === len - 1) {
        const isShowingPdxAnalysis = payload && payload.isShowingPdxAnalysis;
        const nextGrid = nextGridType(
          item.gridCodeType,
          (payload && payload.fieldSettings) || {},
          isShowingPdxAnalysis,
          payload && payload.facilityPreferences,
          payload && payload.inpatientEncounter
        );

        focusFirstGridRow(inProgress, nextGrid, payload);
        break;
      }

      // start editing for the next item in the same grid. Stay on the last item
      let focusedItemId = ind === len - 1 ? ind : ind + 1;
      // move to the 'code' in the last empty row
      let focusedFieldName = collection[focusedItemId].empty ? 'code' : fieldName;

      // after deletion stay at the code on the same row as deleted
      if (justDeletedRowIndex > -1) {
        focusedItemId = justDeletedRowIndex;
        focusedFieldName = 'code';
      }

      collection[focusedItemId].inEdit = true;
      collection[focusedItemId].editField = focusedFieldName;
      collection[focusedItemId].initialCode = collection[focusedItemId].code;

      inProgress.lastActiveCodeId = collection[focusedItemId].id;

      break;
    }
  }
}

export const handleEditLocateEvent = (state: EncounterState, payload: EditLocateEvent) => {
  const {inProgress} = state;

  if (payload.gridCodeType === GridCodeType.ADMIT_DX) {
    exitEditAll(inProgress);
    inProgress.admitDiagnosisCode = {
      ...inProgress.admitDiagnosisCode,
      editField: payload.field || 'code',
      inEdit: true,
    };
    inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;
    return {
      ...state,
      inProgress,
    };
  }

  const group: CodeGroup = inProgress[payload?.gridCodeType];
  if (group) {
    const rows = group.codes;
    const row = rows.find((r) => {
      return r.id === payload.viewId;
    });

    if (row) {
      exitEditAll(inProgress);

      const newItem = {
        ...row,
        editField: payload.field || '',
        inEdit: true,
        initialCode: row.code,
      };

      inProgress.lastActiveCodeId = newItem.id;

      updateItem(inProgress, payload.gridCodeType, row.id, newItem);
      updateCodes(inProgress);

      return {
        ...state,
        inProgress,
      };
    }
  }

  return state;
};

// moving in the current grid or to the previous grid
function handlePrevRowFocus(
  inProgress: EncounterEntity,
  item: BaseCodeRow,
  fieldName: string,
  currentGridOnly: boolean,
  payload?: CodeGridKeypress
) {
  const isShowingPdxAnalysis = payload && payload.isShowingPdxAnalysis;
  if (item.id === ADMIT_DX_ID) {
    if (!currentGridOnly && !isShowingPdxAnalysis) {
      const prevGrid =
        payload && payload.facilityPreferences?.InpatientPreferences?.AllowHcpcsProcedures && payload.inpatientEncounter
          ? GridCodeType.OUTPROCEDURES
          : GridCodeType.INPROCEDURES;
      focusLastGridRow(inProgress, prevGrid, payload);
    } else {
      inProgress.admitDiagnosisCode = {
        ...inProgress.admitDiagnosisCode,
        editField: 'code',
        inEdit: true,
      };
      inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;
    }

    return;
  }

  const collection = inProgress[item.gridCodeType].codes;

  // special situation: shift-tab or shift-enter after code deleting
  const justDeletedRowIndex = getJustDeletedRowIndex(payload);

  // stay on the first item
  for (let ind = 0, len = collection.length; ind < len; ind++) {
    if (collection[ind].id === item.id || ind === justDeletedRowIndex) {
      exitEditAll(inProgress);

      // navigate to the previous grid
      if (!currentGridOnly && ind === 0) {
        const prevGrid = prevGridType(
          item.gridCodeType,
          isShowingPdxAnalysis,
          payload && payload.fieldSettings,
          payload && payload.facilityPreferences,
          payload && payload.inpatientEncounter
        );

        focusLastGridRow(inProgress, prevGrid, payload);
        break;
      }

      // start editing for the previous item in the same grid. Stay on the first item
      const focusedItemId = ind === 0 ? ind : ind - 1;

      collection[focusedItemId].inEdit = true;
      collection[focusedItemId].editField = collection[focusedItemId].empty ? 'code' : fieldName;
      collection[focusedItemId].initialCode = collection[focusedItemId].code;

      inProgress.lastActiveCodeId = collection[focusedItemId].id;

      break;
    }
  }
}

// moving in the current grid
function handleCurrentRowFocus(inProgress: EncounterEntity, item: BaseCodeRow, fieldName: string) {
  if (item.id === ADMIT_DX_ID) {
    return;
  }

  const collection = inProgress[item.gridCodeType].codes;

  // stay on the first item
  for (let ind = 0, len = collection.length; ind < len; ind++) {
    if (collection[ind].id === item.id) {
      exitEditAll(inProgress);

      // start editing for the previous item in the same grid. Stay on the first item
      const focusedItemId = ind;

      collection[focusedItemId].inEdit = true;
      collection[focusedItemId].editField = collection[focusedItemId].empty ? 'code' : fieldName;
      collection[focusedItemId].initialCode = collection[focusedItemId].code;

      inProgress.lastActiveCodeId = collection[focusedItemId].id;
      break;
    }
  }
}

function focusLastGridRow(_inProgress: EncounterEntity, gridType?: GridCodeType, payload?: CodeGridKeypress) {
  if (!gridType) {
    return;
  }

  const inProgress = _inProgress;

  let editField = 'description';

  // more accurate definition
  if (payload) {
    const { isShowingPdxAnalysis } = payload;
    const columns = getGridColumns(gridType, isShowingPdxAnalysis, payload.columnsSettings, payload.fieldSettings);
    const previousField = getPreviousField(columns, '');
    if (previousField) {
      editField = previousField;
    }
  }

  if (gridType === GridCodeType.ADMIT_DX) {
    // skip AdmitDX Description for empty code
    const currentAdmitDX = (inProgress.admitDiagnosisCode.code || '').trim();

    inProgress.admitDiagnosisCode = {
      ...inProgress.admitDiagnosisCode,
      editField:
        (payload && payload.fieldSettings && isHiddenField(payload.fieldSettings, 'AdmitDiagnosis.Description')) ||
        currentAdmitDX === ''
          ? 'code'
          : 'description',
      inEdit: true,
    };

    inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;

    return;
  }

  const codeGroup = inProgress[gridType];
  if (codeGroup && codeGroup.codes.length > 0) {
    const lastCode = codeGroup.codes[codeGroup.codes.length - 1];
    lastCode.inEdit = true;
    lastCode.editField = lastCode.empty ? 'code' : editField;
    lastCode.initialCode = lastCode.code;

    inProgress.lastActiveCodeId = lastCode.id;
  }
}

export const handleFocusFistGridField = (state: EncounterState, payload): EncounterState => {
  const inProgress = cloneEncounterEntity(state.inProgress);
  exitEditAll(inProgress);

  inProgress.admitDiagnosisCode.inEdit = true;
  inProgress.admitDiagnosisCode.editField = 'code';
  inProgress.admitDiagnosisCode.initialCode = inProgress.admitDiagnosisCode.code;
  inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;

  return {
    ...state,
    inProgress,
  };
};

export const handleCodeGridRemove = (state: EncounterState, payload: CodeGridChange): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  const collection = inProgress[payload.dataItem.gridCodeType].codes;

  const newCollection: BaseCodeRow[] = [];

  let removedRowIndex = -1;
  for (let ind = 0, len = collection.length; ind < len; ind++) {
    if (collection[ind].id !== payload.dataItem.id) {
      newCollection.push(collection[ind]);
    } else {
      removedRowIndex = ind;
    }
  }

  if (removedRowIndex === -1) {
    return state;
  }

  inProgress[payload.dataItem.gridCodeType].codes = newCollection;

  const newFocusRow = inProgress[payload.dataItem.gridCodeType].codes[removedRowIndex];

  if (payload.removeRow) {
    handleCurrentRowFocus(inProgress, newFocusRow, payload.field);
  }

  updateCodes(inProgress);

  return {
    ...state,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleCodeGridMoveToPdx = (state: EncounterState, payload: CodeGridChange): EncounterState => {
  const item = findItem(state.inProgress, payload.dataItem);
  if (!item) {
    return state;
  }

  const inProgress = {
    ...state.inProgress,
  };

  const newCollection: BaseCodeRow[] = [];

  // set item as first
  newCollection.push(item);

  // add all other items
  for (let ind = 0, len = inProgress[item.gridCodeType].codes.length; ind < len; ind++) {
    if (inProgress[item.gridCodeType].codes[ind].id !== payload.dataItem.id) {
      newCollection.push(inProgress[item.gridCodeType].codes[ind]);
    }
  }

  inProgress[item.gridCodeType].codes = newCollection;
  updateCodes(inProgress);

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handlePdxAnalyzeCompleted = (state: EncounterState, payload: PDxAnalyzeResultEntity): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  const collection = inProgress.diagnoses.codes;
  for (let ind = 0, len = collection.length; ind < len; ind++) {
    const code = collection[ind];
    if (payload[code.id]) {
      code.drg = payload[code.id].drg;
      code.drgDescription = payload[code.id].drgDescription;
      code.drgWeight = payload[code.id].drgWeight;
      code.reimbursement = payload[code.id].drgReimbursement;
    }
  }

  updateCodes(inProgress);

  return {
    ...state,
    inProgress,
  };
};

export const handleCodeGridEnterEditAdmitDX = (state: EncounterState): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  exitEditAll(inProgress);

  const admitDiagnosisCode = {
    ...state.inProgress.admitDiagnosisCode,
    editField: 'code',
    inEdit: true,
    initialCode: state.inProgress.admitDiagnosisCode.code,
  };

  inProgress.admitDiagnosisCode = admitDiagnosisCode;
  inProgress.lastActiveCodeId = admitDiagnosisCode.id;

  return {
    ...state,
    inProgress,
  };
};

export const handleCodeGridEnterEdit = (state: EncounterState, payload: CodeGridChange): EncounterState => {
  // allow click again on the incorrect field
  if (payload.providers) {
    const incorrectField = isIncorrectGridItem(payload.dataItem, payload.providers);
    if (gridsHaveIncorrectFields(state.inProgress, payload.providers) && incorrectField !== payload.field) {
      return state;
    }
  }

  if (payload.dataItem.id === ADMIT_DX_ID) {
    return handleCodeGridEnterEditAdmitDX(state);
  }

  const item = findItem(state.inProgress, payload.dataItem, payload.dataItem.gridCodeType);
  if (!item) {
    return state;
  }

  const inProgress = {
    ...state.inProgress,
  };

  exitEditAll(inProgress);

  const newItem = {
    ...payload.dataItem,
    editField: payload.field || '',
    inEdit: true,
    initialCode: item.code,
  };

  inProgress.lastActiveCodeId = newItem.id;

  updateItem(inProgress, payload.gridCodeType, payload.dataItem.id, newItem);
  updateCodes(inProgress);

  return {
    ...state,
    inProgress,
  };
};

function canBePlacedToGrid(itemType: CodeType, gridType: GridCodeType) {
  switch (itemType) {
    case CodeType.ICD10CM_DX:
    case CodeType.ICD10CM_E:
      return gridType === GridCodeType.DIAGNOSES || gridType === GridCodeType.VISITREASONS;
    case CodeType.ICD10PCS_PR:
      return gridType === GridCodeType.INPROCEDURES;
    case CodeType.CPT4:
    case CodeType.HCPCS:
      return gridType === GridCodeType.OUTPROCEDURES;
    default:
  }

  throw new Error('Unsupported code type');
}

function nextGridType(
  gridType: GridCodeType,
  fieldSettings: FieldSettings,
  isShowingPdxAnalysis?: boolean,
  facilityPreferences?: FacilityPreferences,
  isInpatient?: boolean
) {
  const showIcdProcedures =
    isInpatient || (!isInpatient && facilityPreferences?.OutpatientPreferences?.AllowIcdProcedures);
  const isVisitReasonsAvailable =
    !isHiddenField(fieldSettings, 'VisitReasons') && !isReadOnlyField(fieldSettings, 'VisitReasons');
  const isAdmitDXAvailable =
    !isHiddenField(fieldSettings, 'AdmitDiagnosis') && !isReadOnlyField(fieldSettings, 'AdmitDiagnosis');

  switch (gridType) {
    case GridCodeType.ADMIT_DX:
      if (isVisitReasonsAvailable) {
        return GridCodeType.VISITREASONS;
      }
      if (!isShowingPdxAnalysis) {
        return GridCodeType.DIAGNOSES;
      }

      return GridCodeType.INPROCEDURES;
    case GridCodeType.VISITREASONS:
      return !isShowingPdxAnalysis ? GridCodeType.DIAGNOSES : GridCodeType.INPROCEDURES;
    case GridCodeType.DIAGNOSES:
      if (isInpatient) {
        return GridCodeType.INPROCEDURES;
      }
      if (showIcdProcedures) {
        return GridCodeType.INPROCEDURES;
      }
      return GridCodeType.OUTPROCEDURES;

    case GridCodeType.INPROCEDURES:
      return GridCodeType.OUTPROCEDURES;
    case GridCodeType.OUTPROCEDURES:
      if (isAdmitDXAvailable) {
        return undefined;
      }
      if (isVisitReasonsAvailable) {
        return GridCodeType.VISITREASONS;
      }
      if (!isShowingPdxAnalysis) {
        return GridCodeType.DIAGNOSES;
      }

      return GridCodeType.INPROCEDURES;
    default:
  }

  return undefined;
}

function prevGridType(
  gridType: GridCodeType,
  isShowingPdxAnalysis?: boolean,
  fieldSettings?: FieldSettings,
  facilityPreferences?: FacilityPreferences,
  isInpatient?: boolean
) {
  const showIcdProcedures =
    isInpatient || (!isInpatient && facilityPreferences?.OutpatientPreferences?.AllowIcdProcedures);
  const isVisitReasonsAvailable =
    !fieldSettings ||
    (!isHiddenField(fieldSettings, 'VisitReasons') && !isReadOnlyField(fieldSettings, 'VisitReasons'));
  const isAdmitDXAvailable =
    !fieldSettings ||
    (!isHiddenField(fieldSettings, 'AdmitDiagnosis') && !isReadOnlyField(fieldSettings, 'AdmitDiagnosis'));

  // skip diagnoses grid in PDX On Mode
  const checkedGridType =
    gridType === GridCodeType.INPROCEDURES && isShowingPdxAnalysis ? GridCodeType.DIAGNOSES : gridType;
  switch (checkedGridType) {
    case GridCodeType.VISITREASONS:
      return isAdmitDXAvailable ? GridCodeType.ADMIT_DX : GridCodeType.OUTPROCEDURES;
    case GridCodeType.DIAGNOSES: {
      if (isVisitReasonsAvailable) {
        return GridCodeType.VISITREASONS;
      }
      if (isAdmitDXAvailable) {
        return GridCodeType.ADMIT_DX;
      }

      return GridCodeType.OUTPROCEDURES;
    }
    case GridCodeType.INPROCEDURES:
      return GridCodeType.DIAGNOSES;
    case GridCodeType.OUTPROCEDURES:
      if (!showIcdProcedures) {
        return GridCodeType.DIAGNOSES;
      }
      return GridCodeType.INPROCEDURES;
    default:
  }

  return undefined;
}

function findItem(inProgress: EncounterEntity, item: BaseCodeRow | string, gridCodeType?: GridCodeType) {
  const itemId = typeof item === 'string' ? item : item.id;
  if (typeof item === 'string' && !gridCodeType) {
    const allCodes = combineEncounterCodes(inProgress);
    const len = allCodes.length;
    for (let ind = 0; ind < len; ind++) {
      if (allCodes[ind].id === itemId) {
        return allCodes[ind];
      }
    }
    return null;
  }

  // fix linter warning
  const typeForLinter = typeof item === 'string' ? gridCodeType : item.gridCodeType;
  const codeGroupId = gridCodeType || typeForLinter;
  if (!codeGroupId) {
    return null;
  }
  const { codes } = inProgress[codeGroupId];
  const len = codes.length;
  for (let ind = 0; ind < len; ind++) {
    if (codes[ind].id === itemId) {
      return codes[ind];
    }
  }

  return null;
}

// update item in the clone
function updateItem(_inProgress: EncounterEntity, gridCodeType: GridCodeType, itemId: string, newItem: BaseCodeRow) {
  const inProgress = _inProgress;
  const newCodes: BaseCodeRow[] = [];

  const { codes } = inProgress[gridCodeType];
  for (let ind = 0, len = codes.length; ind < len; ind++) {
    if (codes[ind].id === itemId) {
      newCodes.push(newItem);
    } else {
      newCodes.push(codes[ind]);
    }
  }

  inProgress[gridCodeType].codes = newCodes;
}

export const handleCodeGridTryExitEdit = (state: EncounterState, payload: CodeGridExitEditInfo): EncounterState => {
  // LOGIC can ignore this action if we have validation errors and keep focus on the wrong field

  const inProgress = {
    ...state.inProgress,
  };

  if (state.inProgress.admitDiagnosisCode.inEdit || !payload.tabFromLastColumn) {
    exitEditAll(inProgress);
    updateCodes(inProgress);

    return {
      ...state,
      inProgress,
    };
  }

  return state;
};

const focusFirstGridRow = (_inProgress: EncounterEntity, nextType?: GridCodeType, payload?: CodeGridKeypress) => {
  const inProgress = _inProgress;

  // focus Admit_DX
  if (!nextType) {
    inProgress.admitDiagnosisCode.inEdit = true;
    inProgress.admitDiagnosisCode.editField = 'code';
    inProgress.admitDiagnosisCode.initialCode = inProgress.admitDiagnosisCode.code;
    inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;

    return;
  }

  let editField = 'code';

  // more accurate definition
  if (payload) {
    const { isShowingPdxAnalysis } = payload;
    const columns = getGridColumns(nextType, isShowingPdxAnalysis, payload.columnsSettings, payload.fieldSettings);
    const firstField = getNextField(columns, '');
    if (firstField) {
      editField = firstField;
    }
  }

  // focus first item in the next grid
  const nextCodes = inProgress[nextType].codes;
  if (nextCodes.length > 0) {
    nextCodes[0].inEdit = true;
    nextCodes[0].editField = nextCodes[0].empty ? 'code' : editField;
    nextCodes[0].initialCode = nextCodes[0].code;

    inProgress.lastActiveCodeId = nextCodes[0].id;
  }
};

export const handleAdmitDXDescriptionBlur = (state: EncounterState, value: string): EncounterState => {
  return {
    ...state,
    dirty: true,
    inProgress: {
      ...state.inProgress,
      admitDiagnosisCode: {
        ...state.inProgress.admitDiagnosisCode,
        description: value,
        inEdit: undefined,
      },
    },
    lastChangeTime: moment.now(),
  };
};

export const handleNavigateFromAdmitDXDescription = (
  state: EncounterState,
  back: boolean,
  fieldSettings: FieldSettings,
  isShowingPdxAnalysis?: boolean
): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  exitEditAll(inProgress);

  // do not navigate to grid in pdx analysis mode
  if (isShowingPdxAnalysis || back) {
    inProgress.admitDiagnosisCode = {
      ...state.inProgress.admitDiagnosisCode,
      editField: 'code',
      inEdit: true,
      initialCode: state.inProgress.admitDiagnosisCode.code,
    };
    inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;
  } else {
    const isVisitReasonsAvailable =
      !isHiddenField(fieldSettings, 'VisitReasons') && !isReadOnlyField(fieldSettings, 'VisitReasons');
    const firstFocusableIPGrid = isShowingPdxAnalysis ? GridCodeType.INPROCEDURES : GridCodeType.DIAGNOSES;
    const firstCodeGridType = isVisitReasonsAvailable ? GridCodeType.VISITREASONS : firstFocusableIPGrid;

    const collection = inProgress[firstCodeGridType].codes;
    if (collection.length > 0) {
      collection[0].inEdit = true;
      collection[0].editField = 'code';
      collection[0].initialCode = collection[0].code;
      inProgress.lastActiveCodeId = collection[0].id;
    }
  }

  updateCodes(inProgress);

  return {
    ...state,
    inProgress,
  };
};

export const handleCodeGridCancelEdit = (state: EncounterState, payload: CodeGridChange): EncounterState => {
  // LOGIC can ignore this action if we have validation errors and keep focus on the wrong field

  // TODO: fix this routine
  // const originalCodeItemsClone = map(values(state.saved.codes.items), clone);

  const item = findItem(state.inProgress, payload.dataItem);
  if (!item) {
    return state;
  }
  const inProgress = {
    ...state.inProgress,
  };

  const newItem = {
    ...payload.dataItem,
    editField: payload.field || '',
    inEdit: true,
  };

  inProgress.lastActiveCodeId = newItem.id;

  updateItem(inProgress, item.gridCodeType, payload.dataItem.id, newItem);
  updateCodes(inProgress);

  return {
    ...state,
    inProgress,
  };
};

export const handleCodeGridDragAndDropRow = (state: EncounterState, payload: CodeGridDragDropRow): EncounterState => {
  // LOGIC can ignore this action if we have validation errors and keep focus on the wrong field

  // not changed position
  const ignoreDropping = payload.toIdx === undefined || payload.fromIdx === payload.toIdx;

  const item = !ignoreDropping ? findItem(state.inProgress, payload.dataItemId) : null;
  // incorrect item or same/incorrect position
  if (!item) {
    // we must execute pending auto resequence now
    if (payload.needAutoResequenceCodes) {
      const inProgress = cloneEncounterEntity(state.inProgress);
      resequenceCodes(inProgress);
      console.log('Codes are resequenced');

      return {
        ...state,
        inProgress,
        dirty: true,
        lastChangeTime: moment.now(),
      };
    }

    return state;
  }

  const inProgress = {
    ...state.inProgress,
  };

  const newCollection: BaseCodeRow[] = [];

  // get code types & those codes
  const gridCodes = inProgress[item.gridCodeType].codes;
  const curIdx = gridCodes.findIndex((row) => row.id === payload.dataItemId);
  if (curIdx === -1) {
    // eslint-disable-next-line no-console
    console.log('NOT FOUND!');
    return state;
  }

  let ind = 0;
  const len = gridCodes.length;
  while (ind <= len) {
    // we can drop after last row. Skip old item
    if (ind !== curIdx) {
      if (ind < len && payload.fromIdx < payload.toIdx) {
        newCollection.push(gridCodes[ind]);
      }

      if (ind === payload.toIdx) {
        newCollection.push(gridCodes[curIdx]);
      }

      if (ind < len && payload.fromIdx > payload.toIdx) {
        newCollection.push(gridCodes[ind]);
      }
    }

    ind++;
  }

  // const adjustedIdx = curIdx - (curIdx - payload.fromIdx);
  // const targetIdx = adjustedIdx - (payload.toIdx - payload.fromIdx);
  // inProgress.codes[targetIdx] = item;
  // for (const i = 0;) {
  //   debugger;
  // }

  // // add all other items
  // for (let ind = 0, len = inProgress.codes.length; ind < len; ind++) {
  //   if (inProgress.codes[ind].id !== payload.dataItemId) {
  //     newCollection.push(inProgress.codes[ind]);
  //   }
  // }

  inProgress[item.gridCodeType].codes = newCollection;
  updateCodes(inProgress);

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };

  // if (!destination) {
  //   return;
  // }

  // if (destination.droppableId === source.droppableId &&
  //   destinationIndex === source.index) {
  //   return;
  // }

  // let codeTypeInGrid: string[];

  // const typeFilter = source.droppableId;

  // switch (typeFilter) {
  //   case CodeType.CPT4:
  //   case CodeType.HCPCS:
  //     codeTypeInGrid = [CodeType.CPT4, CodeType.HCPCS];
  //     break;
  //   case CodeType.ICD10CM_DX:
  //   case CodeType.ICD10CM_E:
  //     codeTypeInGrid = [CodeType.ICD10CM_DX, CodeType.ICD10CM_E];
  //     break;
  //   case CodeType.ICD10PCS_PR:
  //     codeTypeInGrid = [CodeType.ICD10PCS_PR];
  //     break;
  //   case CodeType.VISIT_REASONS:
  //     codeTypeInGrid = [CodeType.VISIT_REASONS];
  //     break;
  //   default:
  //     codeTypeInGrid = ([]);
  // }

  // const codeValues = values(this.state.encounterData.inProgress.codes);

  // const rowMoved = codeValues.find((c) => c.order === source.index && includes(codeTypeInGrid, c.type));

  // result = codeValues.reduce((type, code) => {
  //   type[code.type] = type[code.type] || [];
  //   type[code.type].push(code);
  //   return type;
  // }, []);

  // // TODO: combine dx and e, sort by order

  // rowMoved.order = destinationIndex;
  // rowMoved.id = destinationIndex.toString();

  // result[typeFilter].splice(sourceIndex, 1);
  // result[typeFilter].splice(destination.index, 0, rowMoved);

  // let newOrder = 1;

  // result[typeFilter].forEach((r) => {
  //   if (r.order && codeTypeInGrid.find(() => r.type)) {
  //     r.order = newOrder;
  //     r.id = newOrder.toString();
  //     newOrder += 1;
  //   }
  // });

  // // TODO: Combine arrays that were split out from initial codes, set to codeValues for mapping in CodeGrid

  // const newinProgress = {
  //   ...this.state.encounterData.inProgress
  // };

  // newinProgress.codes = codeValues;

  // const newState = {
  //   ...this.state,
  //   newinProgress
  // };

  // this.setState({
  //   encounterData: {
  //     ...this.state.encounterData,
  //     inProgress: newinProgress,
  //     dirty: true,
  //   }
  // },
  //   () => {
  //     console.log(this.state.encounterData);
  //   });
};

export const handleClearGrid = (state: EncounterState, gridCodeType: GridCodeType): EncounterState => {
  const inProgress = {
    ...state.inProgress,
    [gridCodeType]: {
      ...state.inProgress[gridCodeType],
      codes: [],
    },
  };

  updateCodes(inProgress);

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleSetAllPOAToValue = (state: EncounterState, value: PresentOnAdmission): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  const newCollection: BaseCodeRow[] = [];

  const gridCodes = inProgress.diagnoses.codes;
  const len = gridCodes.length;
  for (let ind = 0; ind < len; ind++) {
    const code = gridCodes[ind];
    const poaValue = code.presentOnAdmission ? code.presentOnAdmission : value;
    newCollection.push({
      ...code,
      presentOnAdmission: code.empty ? undefined : poaValue,
    });
  }

  inProgress.diagnoses = {
    ...inProgress.diagnoses,
    codes: newCollection,
  };

  updateCodes(inProgress);

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleUpdateExemptPOAValues = (state: EncounterState): EncounterState => {
  const inProgress = {
    ...state.inProgress,
  };

  const newCollection: BaseCodeRow[] = [];

  const gridCodes = inProgress.diagnoses.codes;
  const len = gridCodes.length;
  for (let ind = 0; ind < len; ind++) {
    const code = gridCodes[ind];
    const poaValue = code.exemptPoa;
    let newPOA = code.presentOnAdmission;

    // === undefined -> no need to update
    // === null -> blank
    // === 1 => 1
    if (poaValue !== undefined && !code.empty) {
      newPOA = poaValue === '1' ? PresentOnAdmission.ONE : PresentOnAdmission.EMPTY;
    }

    newCollection.push({
      ...code,
      presentOnAdmission: newPOA,
    });
  }

  inProgress.diagnoses = {
    ...inProgress.diagnoses,
    codes: newCollection,
  };

  updateCodes(inProgress);

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleInsertRow = (
  state: EncounterState,
  dataItem: BaseCodeRow,
  insertBefore: boolean
): EncounterState => {
  // LOGIC can ignore this action if we have validation errors and keep focus on the wrong field

  const inProgress = {
    ...state.inProgress,
  };

  exitEditAll(inProgress);

  const collection = inProgress[dataItem.gridCodeType].codes;

  const newItem = {
    code: '',
    empty: false,
    gridCodeType: dataItem.gridCodeType,
    id: getId(),
    inEdit: true,
    editField: 'code',
    initialCode: '',
    serviceCode: '',
    type: dataItem.type,

    Validations: [],
  };

  inProgress.lastActiveCodeId = newItem.id;

  const newCollection: BaseCodeRow[] = [];
  for (let ind = 0, len = collection.length; ind < len; ind++) {
    if (collection[ind].id === dataItem.id && insertBefore) {
      newCollection.push(newItem);
    }

    // skip any other empty row
    if (!(!collection[ind].empty && collection[ind].code.trim() === '')) {
      newCollection.push(collection[ind]);
    }

    if (collection[ind].id === dataItem.id && !insertBefore) {
      newCollection.push(newItem);
    }
  }

  inProgress[dataItem.gridCodeType] = {
    ...inProgress[dataItem.gridCodeType],
    codes: newCollection,
  };

  updateCodes(inProgress);

  return {
    ...state,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleClearEmptyRowsInGrid = (state: EncounterState, dataItem: BaseCodeRow): EncounterState => {
  // LOGIC can ignore this action if we have validation errors and keep focus on the wrong field

  const inProgress = {
    ...state.inProgress,
  };

  exitEditAll(inProgress);

  const collection = inProgress[dataItem.gridCodeType].codes;

  const newCollection: BaseCodeRow[] = [];
  for (let ind = 0, len = collection.length; ind < len; ind++) {
    // skip any empty row
    if (!(!collection[ind].empty && collection[ind].code.trim() === '')) {
      newCollection.push(collection[ind]);
    }
  }

  inProgress[dataItem.gridCodeType] = {
    ...inProgress[dataItem.gridCodeType],
    codes: newCollection,
  };

  updateCodes(inProgress);

  return {
    ...state,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

export const handleCodeGridExitAllEdit = (state: EncounterState): EncounterState => {
  const inProgress = cloneEncounterEntity(state.inProgress); // { ...state.inProgress };
  exitEditAll(inProgress);
  return {
    ...state,
    inProgress,
  };
};

export const exitEditAll = (inprogress: EncounterEntity, exitAdmitDX = true) => {
  const inProgress = inprogress;
  const allCodes = combineEncounterCodes(inProgress);
  const allCodesLen = allCodes.length;
  for (let ind = 0; ind < allCodesLen; ind++) {
    allCodes[ind].inEdit = undefined;
  }
  if (inProgress.admitDiagnosisCode && exitAdmitDX) {
    inProgress.admitDiagnosisCode.inEdit = undefined;
  }
};

const autofillProcedureDate = (inProgress: EncounterEntity, gridCodeType: GridCodeType, dataItem?: BaseCodeRow) => {
  if (!(gridCodeType === GridCodeType.INPROCEDURES || gridCodeType === GridCodeType.OUTPROCEDURES) || inProgress.ValidationResult?.IsInpatient) {
    return undefined;
  }

  // Procedure Date is already filled
  if (dataItem && dataItem.date) {
    return dataItem.date;
  }

  // admitDate === dischargeDate, admitDate is not empty and admitDate is valid
  if (inProgress.admitDate && inProgress.admitDate === inProgress.dischargeDate) {
    if (!isInvalidFormattedDate(inProgress.admitDate, 'Invalid Date')) {
      return inProgress.admitDate;
    }
  }

  return undefined;
};

const findEpisodeCode = (inProgress: EncounterEntity, gridCodeType: GridCodeType, episode: string, skipId: string) => {
  const firstGrid =
    gridCodeType === GridCodeType.INPROCEDURES ? inProgress.inProcedures.codes : inProgress.outProcedures.codes;
  const otherGrid =
    gridCodeType === GridCodeType.INPROCEDURES ? inProgress.outProcedures.codes : inProgress.inProcedures.codes;

  for (let ind = 0, len = firstGrid.length; ind < len; ind++) {
    const code = firstGrid[ind];
    if (code.episode === episode && code.id !== skipId) {
      return code;
    }
  }

  for (let ind = 0, len = otherGrid.length; ind < len; ind++) {
    const code = otherGrid[ind];
    if (code.episode === episode && code.id !== skipId) {
      return code;
    }
  }

  return null;
};

const autofillDefaultProvider = (
  inProgress: EncounterEntity,
  gridCodeType: GridCodeType,
  facilitySettings?: FacilitySettingsState
) => {
  if (gridCodeType !== GridCodeType.INPROCEDURES && gridCodeType !== GridCodeType.OUTPROCEDURES) {
    return undefined;
  }

  if (inProgress.ValidationResult?.IsInpatient || !facilitySettings || !facilitySettings.preferences.OutpatientPreferences) {
    return undefined;
  }

  if (!facilitySettings.preferences.OutpatientPreferences.AutoFillProviderInChoiceList) {
    return undefined;
  }

  return inProgress.attendingMd;
};

const autofillDefaultRevenueCode = (
  inProgress: EncounterEntity,
  gridCodeType: GridCodeType,
  facilitySettings?: FacilitySettingsState
) => {
  if (gridCodeType !== GridCodeType.OUTPROCEDURES) {
    return undefined;
  }

  if (inProgress.ValidationResult?.IsInpatient || !facilitySettings || !facilitySettings.preferences.OutpatientPreferences) {
    return undefined;
  }

  return facilitySettings.preferences.OutpatientPreferences.DefaultRevenueCode;
};

export const isIncorrectGridItem = (item: BaseCodeRow, providers: IdDescriptionBase[]) => {
  if (item.empty) {
    return false;
  }

  if (isDateValidator((item.date as unknown) as string, 'Incorrect Date')) {
    return 'date';
  }

  // provider is not in the providers list and has too many characters
  if (item.provider && !checkDDLFieldLength(providers, MAX_PROVIDER_LENGTH, item.provider)) {
    return 'provider';
  }

  return false;
};

export const gridsHaveIncorrectFields = (inProgress: EncounterEntity, providers: IdDescriptionBase[]) => {
  // do not check diagnoses and visitReasons because they have not ui side validation errors

  if (inProgress.inProcedures.codes.some((item) => isIncorrectGridItem(item, providers))) {
    return true;
  }

  if (inProgress.outProcedures.codes.some((item) => isIncorrectGridItem(item, providers))) {
    return true;
  }

  return false;
};

export const gridsHaveIncorrectSpecificFields = (
  inProgress: EncounterEntity,
  providers: IdDescriptionBase[],
  fieldName: string
) => {
  if (inProgress.inProcedures.codes.some((item) => isIncorrectGridItem(item, providers) === fieldName)) {
    return true;
  }

  if (inProgress.outProcedures.codes.some((item) => isIncorrectGridItem(item, providers) === fieldName)) {
    return true;
  }

  return false;
};
