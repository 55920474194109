import { PublicClientApplication, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";

// Msal Configurations
const authoritySignIn = process.env.REACT_APP_AAD_authority_signin;
const authorityPasswordReset = process.env.REACT_APP_AAD_authority_passwordreset;
const authorityChangePassword = process.env.REACT_APP_AAD_authority_changepassword;

const config = {
  auth: {
    authority: authoritySignIn,
    clientId: process.env.REACT_APP_AAD_applicationId || '',
    redirectUri: window.location.origin,
    validateAuthority: false,
    navigateToLoginRequestUrl: true,
    knownAuthorities: [
      authoritySignIn,
      authorityPasswordReset,
      authorityChangePassword,
    ],
  },
};

// Authentication Parameters
export const authenticationParametersSignIn = {
  authority: authoritySignIn,
  scopes: [
    process.env.REACT_APP_AAD_scope || '',
  ],
}

export const authenticationParametersPasswordReset = {
  authority: authorityPasswordReset,
}

export const authenticationParametersChangePassword = {
  authority: authorityChangePassword,
}

export const accessTokenRequest = {
  scopes: [process.env.REACT_APP_AAD_scope || ''],
  // account: accounts[0]
}

const AAD_B2C_ERROR_EXPIRED_INVITATION = 'AADB2C90208';
const AAD_B2C_REQUEST_PASSWORD_RESET = 'AADB2C90118';
const AAD_B2C_TOO_MANY_RETRIES = 'AADB2C90157';
const USER_CANCELLED_OPERATION = 'AADB2C90091';
const ERROR_HASH_DOES_NOT_CONTAIN_STATE = 'Unexpected error in authentication.: Hash does not contain state.';

// auth provider
export const pca = new PublicClientApplication(config);

// indicates that authorization is not successfull or we are out from the authorization
window.noAuthorization = true;

// retreive token promise wihtout errors handling
export const acquireTokenSilent = () => {
  return pca.acquireTokenSilent(accessTokenRequest);
}

export const isInteractionRequiredError = (error) => {
  if (error instanceof InteractionRequiredAuthError) {
    return true;
  }

  if (error && error.errorCode === 'silent_sso_error') {
    return true;
  }

  if (error && error.errorCode === 'monitor_window_timeout') {
    return true;
  }

  return false;
}

// try login and return accessToken
export const tryLogin = () => {
  window.noAuthorization = true;

  // autherror page is out from the authorization sequence
  if (window.location.href.indexOf('autherror') !== -1) {
    return Promise.resolve()
      .then(() => { return ''; });
  }

  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    pca.setActiveAccount(accounts[0]);
  }

  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const { payload } = event;
      const { account } = payload;
      pca.setActiveAccount(account);
    }

    if (window.location.href.indexOf(AAD_B2C_ERROR_EXPIRED_INVITATION) > -1) {
      window.location.assign('/autherror?error=expiredinvitation');
    }

    if (event.eventType === EventType.LOGIN_FAILURE && !(window.location.href.indexOf(AAD_B2C_ERROR_EXPIRED_INVITATION) > -1 || window.location.href.indexOf(AAD_B2C_TOO_MANY_RETRIES) > -1)) {
      console.log('LOGIN_FAILURE. Try again')
      // try login again after login failure
      window.location.assign('/');
    }

    if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      console.log('ACQUIRE_TOKEN_FAILURE. Try again')

      // try acquire token again. must I left it to the package?
      return acquireTokenSilent()
        .then(tokenResponse => {
          return tokenResponse.accessToken;
        }).catch(error => {
          if (isInteractionRequiredError(error)) {
            return pca.acquireTokenRedirect(accessTokenRequest);
          }

          return error;
        });
    }

    if (event.eventType === EventType.SSO_SILENT_FAILURE) {
      console.log('SSO_SILENT_FAILURE')
      // I suppose we must not react on this event and left it to the package
    }

    if (event.eventType === EventType.LOGOUT_FAILURE) {
      console.log('LOGOUT_FAILURE. Try again')
      // try logout again
      if (pca && pca.getActiveAccount()) {
        pca.logoutRedirect();
      }
    }

    return '';
  });

  return pca.handleRedirectPromise().then(authResult => {
    // Check if user signed in
    const account = pca.getActiveAccount();

    if (!account){
      // redirect anonymous user to login page
      return pca.loginRedirect(authenticationParametersSignIn)
        .then(() => {
          return '';
        })
    }

    return acquireTokenSilent()
      .then(tokenResponse => {
        window.noAuthorization = false;
        return tokenResponse.accessToken;
      }).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return pca.acquireTokenRedirect(accessTokenRequest);
        }

        return error;
      });
  }).catch((error) => {
    let errorHandled = false;

    if (window.location.href.indexOf(AAD_B2C_ERROR_EXPIRED_INVITATION) > -1) {
      window.location.assign('/autherror?error=expiredinvitation');
      errorHandled = true;
    }
    if (window.location.href.indexOf(AAD_B2C_TOO_MANY_RETRIES) > -1) {
      window.location.assign('/autherror?error=toomanyretries');
      errorHandled = true;
    }
    if (error && error.errorMessage === ERROR_HASH_DOES_NOT_CONTAIN_STATE) {
      // try to relogin
      window.location.assign('/');
      errorHandled = true;
    }
    if (error && error.errorMessage && error.errorMessage.indexOf(AAD_B2C_REQUEST_PASSWORD_RESET) > -1) {
      errorHandled = true;
      return pca.loginRedirect(authenticationParametersPasswordReset)
        .then(() => {
          return '';
        })
    }
    if (error && error.errorMessage && error.errorMessage.indexOf(USER_CANCELLED_OPERATION) > -1) {
      errorHandled = true;
      window.location.assign('/');
    }
    if (error && error.errorMessage && error.errorMessage.indexOf('No cached authority found') > -1) {
      errorHandled = true;
      window.location.assign('/');
    }

    // I do not know what to do with remained errors - try to login again? can be infinite cycle. But without it we can have empty page
    // so I redirect to the special error page for any unhandled error
    if (!errorHandled) {
      window.location.assign(`/autherror?error=${encodeURIComponent(error?.errorMessage || "")}`);
    }

    return error;
  });
}

export const isUserAuthenticated = () => {
  if (window.noAuthorization) {
    return false;
  }

  const account = pca.getActiveAccount();
  if (!account) {
    return false;
  }

  return true;
}

export const getUserName = () => {
  if (window.noAuthorization) {
    return '';
  }

  const account = pca.getActiveAccount();
  const name = account?.idTokenClaims?.user_name;

  return name;
}

export const getAccountIdentifier = () => {
  if (window.noAuthorization) {
    return '';
  }

  const account = pca.getActiveAccount();
  const id = account?.localAccountId;

  return id || '';
}

export const getUserEmail = () => {
  if (window.noAuthorization) {
    return '';
  }

  const account = pca.getActiveAccount();
  const email = account?.idTokenClaims?.email || '';

  return email;
}
