import './Popup.less';

import * as React from 'react';
import { Popup as KPopup, PopupProps as KPopupProps } from '@progress/kendo-react-popup';

export const Popup = function render(props: KPopupProps) {
  const { className, popupClass, ...otherProps } = props;
  return <KPopup
    className={`tc-popup ${className}`}
    popupClass={`tc-popup-popup ${popupClass}`}
    {...otherProps}
  />;
}
