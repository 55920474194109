import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IdleTimer from './IdleTimer';
import { clearApplicationAction, logoutUserAction } from '../../actions/logout';
import { State } from '../../reducers';
import { updateUserActivityAction, updateTimeoutDialogAction } from '../../actions/userActivity';

const mapStateToProps = (state: State) => ({
  lastActivity: state.userActivity.lastActivity,
  showTimeoutDialog: state.userActivity.showTimeoutDialog,
  shownTimeout: state.userActivity.shownTimeout,
  showTimeoutDialogFrom: state.userActivity.showTimeoutDialogFrom,
  unsavedChanges: state.encounter.dirty,
  systemPreferences: state.systemPreferences
});

const mapDispatchToProps = (dispatch) => ({
  clearApplication: () => dispatch(clearApplicationAction()),
  logoutUser: (accountIdentifier: string, unsavedChanges?: boolean) => dispatch(logoutUserAction(accountIdentifier, unsavedChanges, true)),
  updateUserActivity: (lastActivity: string) => dispatch(updateUserActivityAction(lastActivity)),
  updateTimeoutDialog: (showTimeoutDialog: boolean, shownTimeout: number, startTime?: string) => dispatch(updateTimeoutDialogAction(showTimeoutDialog, shownTimeout, startTime)),
});

export const IdleTimerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(IdleTimer));
