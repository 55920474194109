import * as actionTypes from '../constants';
import { GroupingInfoState } from '../models/groupingEntity';
import { IdDescriptionBase } from '../models/patientEncounter';

const createEmptyGroupingInfoState = (): GroupingInfoState => ({
  revenueCodes: [],
  statusIndicators: [],
});

export const groupingInfoReducer = (state = createEmptyGroupingInfoState(), action) => {
  switch (action.type) {
    case actionTypes.REVENUE_CODES_GET_BEGIN:
      return handleGetRevenueCodesBegin(state);
    case actionTypes.REVENUE_CODES_GET_COMPLETED:
      return handleGetRevenueCodesCompleted(state, action.payload);
    case actionTypes.REVENUE_CODES_GET_ERROR:
      return handleGetRevenueCodesError(state);
    case actionTypes.STATUS_INDICATORS_GET_BEGIN:
      return handleGetStatusIndicatorsBegin(state);
    case actionTypes.STATUS_INDICATORS_GET_COMPLETED:
      return handleGetStatusIndicatorsCompleted(state, action.payload);
    case actionTypes.STATUS_INDICATORS_GET_ERROR:
      return handleGetStatusIndicatorsError(state);
    default:
      return state;
  }
};

const handleGetRevenueCodesBegin = (state: GroupingInfoState): GroupingInfoState => {
  const newState = {
    ...state,
    revenueCodes: [],
  }
  return newState;
}

const handleGetRevenueCodesCompleted = (state: GroupingInfoState, payload: IdDescriptionBase[]): GroupingInfoState => {
  const newState = {
    ...state,
    revenueCodes: payload,
  };

  return newState;
};

const handleGetRevenueCodesError = (state: GroupingInfoState): GroupingInfoState => {
  const newState = {
    ...state,
    revenueCodes: [],
  }
  return newState;
};

const handleGetStatusIndicatorsBegin = (state: GroupingInfoState): GroupingInfoState => {
  const newState = {
    ...state,
    statusIndicators: [],
  }
  return newState;
}

const handleGetStatusIndicatorsCompleted = (state: GroupingInfoState, payload: IdDescriptionBase[]): GroupingInfoState => {
  const newState = {
    ...state,
    statusIndicators: payload,
  };

  return newState;
};

const handleGetStatusIndicatorsError = (state: GroupingInfoState): GroupingInfoState => {
  const newState = {
    ...state,
    statusIndicators: [],
  }
  return newState;
};