import * as jsonpatch from 'json8-patch';
import cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';

import { ADMIT_DX_ID, CodeType, EncounterEntity, EncounterState, GridCodeType } from '../../models';
import { BaseCodeRow, CodeGroup, PresentOnAdmission } from '../../models/codeGrid';
import {
  CodeValidation,
  EncounterSaveServiceResult,
  EncounterSearchServiceResultEntity,
  JSONPATCH,
  ServiceEncounterEntity,
  ServiceEncounterView,
  ServiceResultPatientGroupingResult,
  ServiceResultValidationEdit,
  ServiceViewCode,
  ProcGroupingResult,
  ProcGroupingResultValues,
  ServiceResultValidationEditParameter,
  ServiceResultMneEdit,
  ServiceEncounterValidationResult,
  EncounterProcessResult,
  ServiceEncounterProcessMNEResult,
  ServiceResultGroupingResultResult,
  ServiceResultCodeInfo,
} from '../../models/encounterServiceEntity';
import { ChoiceListsState, IdDescriptionBase } from '../../models/patientEncounter';
import { SearchEncounterFilter } from '../../models/searchEncounterFilter';
import { correctAgeInYearsField, validateAgeInYearsField } from '../../scenes/Encounter/utils/calculateAge';
import { isIncorrectWeight, parseWeight } from '../../scenes/Encounter/utils/calculateWeight';
import { reformatDateString, toServiceDate } from '../../utils/date';
import { findComboIdByValue } from '../../utils/ddl';
import { isDateValidator } from '../../validators/simpleValidators';
import { ValuesRow, ValuesGridType } from '../../models/valuesGrid';
import { PricerType, Sex } from '../../models/groupingEntity';
import { checkDates } from '../../scenes/Encounter/validation/validateEncounter';
import { formatTotalCharges } from '../../scenes/Encounter/utils/formatTotalCharges';
import { prepareCodeFlags } from '../../scenes/Encounter/utils/flags';

export const MAX_FINANCIAL_CLASS_LENGTH = 10;
export const MAX_PROVIDER_LENGTH = 25;

export const checkDDLFieldLength = (choiceList: IdDescriptionBase[], maxLength: number, value?: string, valueFieldName = 'ViewId') => {
  if (!value) {
    return true;
  }

  if (value.length <= maxLength) {
    return true;
  }

  const upperValue = value.toUpperCase();
  // there is ViewId of the existing record - no need to check length
  const record = choiceList && choiceList.find(item => item[valueFieldName] && item[valueFieldName].toUpperCase() === upperValue);
  if (record) {
    return true;
  }

  return false;
}

// Prepare service representation of the encounter
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const mapEncounterToServiceEntity = (encounter: EncounterEntity, choiceLists: ChoiceListsState): ServiceEncounterView => {
  const sex = encounter.sex || null;
  const facility = encounter.facility ? encounter.facility : 'undefined';
  const birthWeightValue = parseWeight(encounter.birthWeight || '');
  // WEB-4456, the weight might be wrong from the start. We shouldn't be able to store the wrong weight, but should be able to overwrite it with the correct value.
  const birthWeight = birthWeightValue; // isIncorrectWeight(birthWeightValue) ? '' : birthWeightValue;
  const ageInYearsValue = correctAgeInYearsField(encounter.ageInYears);
  const ageInYears = validateAgeInYearsField(ageInYearsValue) ? '' : ageInYearsValue;
  const totalCharges = formatTotalCharges(encounter.totalCharges as unknown as string);
  const { thirdPartyLiability } = encounter;
  const billingNotes = encounter.billingNotes ? encounter.billingNotes.split(';') : [];

  const serviceEncounter: ServiceEncounterView = {
    AccountNumber: encounter.accountNumber || '',
    AdmitDate: !encounter.admitDate || isDateValidator(encounter.admitDate, 'Incorrect Date')
      ? null : encounter.admitDate,
    AdmitDiagnosis: null,
    BillType: encounter.billType || '',
    DateOfBirth: !encounter.dateOfBirth || isDateValidator(encounter.dateOfBirth, 'Incorrect Date', true) ||
      checkDates(encounter.dateOfBirth, encounter.admitDate, encounter.dischargeDate)
      ? null : encounter.dateOfBirth,
    Diagnoses: [],
    DischargeDate: !encounter.dischargeDate || isDateValidator(encounter.dischargeDate, 'Incorrect Date')
      ? null : encounter.dischargeDate,
    EncounterType: encounter.type,
    Facility: facility || '',
    FinancialClass: encounter.financialClass && checkDDLFieldLength(choiceLists.financialClasses, MAX_FINANCIAL_CLASS_LENGTH, encounter.financialClass) ? encounter.financialClass : '',
    FirstName: encounter.firstName || '',
    InProcedures: [],
    LastName: encounter.lastName || '',
    MedicalRecordNumber: encounter.medicalRecordNumber || '',
    MiddleInitial: encounter.middleInitial || '',
    BirthWeight: birthWeight,
    OutProcedures: [],
    OverrideLOS: encounter.overrideLOS ? `${encounter.overrideLOS}` : '',
    PatientAge: ageInYears,
    PatientSex: sex,
    PatientStatus: encounter.patientStatus || '',
    RecordStatus: encounter.recordStatus || '',
    Service: encounter.service || '',
    SourceOfAdmission: encounter.ofa || '',
    PayerFlag: encounter.payerFlag || '',
    TotalCharges: totalCharges ? totalCharges.toString() : null,
    ThirdPartyLiability: thirdPartyLiability ? thirdPartyLiability.toString() : null,
    Provider: encounter.attendingMd && checkDDLFieldLength(choiceLists.providers, MAX_PROVIDER_LENGTH, encounter.attendingMd) ? encounter.attendingMd : '',
    VisitReasons: [],
    ValueCodes: [],
    ConditionCodes: [],
    BillingNotes: billingNotes,
  };

  mapCodeFields(serviceEncounter, encounter, choiceLists);

  serviceEncounter.ValueCodes = mapValueConditionCodesGroup(encounter.valueCodes, true);
  serviceEncounter.ConditionCodes = mapValueConditionCodesGroup(encounter.conditionCodes, false);

  return serviceEncounter;
};

// set space instead of the equal undefined values to fix diff error
export const cleanUndefinedValues = (_first: ServiceEncounterView, _second: ServiceEncounterView) => {
  const first = _first;
  const second = _second;
  Object.keys(second).map((key) => {
    if (first[key] === undefined && second[key] === undefined) {
      first[key] = '';
      second[key] = '';
    }

    return key;
  })
};

// entity from the service parsing
export const mapSearchServiceEncounterResultToEntity =
  (encounterEntity: EncounterSearchServiceResultEntity): EncounterEntity => {
    // TODO: handle IsLocking
    const encounter: EncounterEntity = {
      accountNumber: encounterEntity.AccountNumber,
      admitDate: mapDateField(encounterEntity.AdmitDate),
      admitDateOriginal: encounterEntity.AdmitDate,
      admitDiagnosisCode: {
        gridCodeType: GridCodeType.ADMIT_DX,
        id: ADMIT_DX_ID,
        type: CodeType.ICD10CM_DX,  // fictive field

        Validations: []
      },
      dateOfBirth: mapDateField(encounterEntity.DateOfBirth),
      diagnoses: { codes: [] },
      dischargeDate: mapDateField(encounterEntity.DischargeDate),
      dischargeDateOriginal: encounterEntity.DischargeDate,
      facility: encounterEntity.Facility,
      firstName: encounterEntity.FirstName,
      id: encounterEntity.DocumentId,
      inProcedures: { codes: [] },
      lastName: encounterEntity.LastName,
      medicalRecordNumber: encounterEntity.MedicalRecordNumber,
      middleInitial: encounterEntity.MiddleInitial,
      outProcedures: { codes: [] },
      service: encounterEntity.Service,
      sex: encounterEntity.Sex,
      type: encounterEntity.EncounterType,
      visitReasons: { codes: [] },

      conditionCodes: [],
      valueCodes: [],

      ValidationResult: null,
      GroupingResults: [],
      ProcessMNEResult: null,

      recordStatus: encounterEntity.RecordStatus,
    };

    return encounter;
  };

// filter string for the encounter searching
export const createFilterString = (filter: SearchEncounterFilter): string => {
  let result = '';

  if (filter.accountNumber) {
    result = `${result}${result ? '&' : ''}From.AccountNumber=${filter.accountNumber}`;
  }

  if (filter.medicalRecordNumber) {
    result = `${result}${result ? '&' : ''}From.MedicalRecordNumber=${filter.medicalRecordNumber}`;
  }

  if (filter.lastName) {
    result = `${result}${result ? '&' : ''}From.LastName=${filter.lastName}`;
  }

  if (filter.firstName) {
    result = `${result}${result ? '&' : ''}From.FirstName=${filter.firstName}`;
  }

  if (filter.admitDateFrom) {
    const admitDateFrom = toServiceDate(filter.admitDateFrom);
    const date = admitDateFrom ? `${admitDateFrom}` : '9999-12-31';
    result = `${result}${result ? '&' : ''}From.AdmitDate=${date}`;
  }

  if (filter.admitDateTo) {
    const admitDateTo = toServiceDate(filter.admitDateTo);
    const date = admitDateTo ? `${admitDateTo}` : '1900-01-01';
    result = `${result}${result ? '&' : ''}To.AdmitDate=${date}`;
  }

  if (filter.dischargeDateFrom) {
    const dischargeDateFrom = toServiceDate(filter.dischargeDateFrom);
    const date = dischargeDateFrom ? `${dischargeDateFrom}` : '9999-12-31';
    result = `${result}${result ? '&' : ''}From.DischargeDate=${date}`;
  }

  if (filter.dischargeDateTo) {
    const dischargeDateTo = toServiceDate(filter.dischargeDateTo);
    const date = dischargeDateTo ? `${dischargeDateTo}` : '1900-01-01';
    result = `${result}${result ? '&' : ''}To.DischargeDate=${date}`;
  }

  if (filter.recordStatus) {
    result = `${result}${result ? '&' : ''}From.RecordStatus=${filter.recordStatus}`;
  }

  if (filter.dateOfBirth) {
    const dateOfBirth = toServiceDate(filter.dateOfBirth);
    const date = dateOfBirth ? `${dateOfBirth}` : '1900-01-01';
    result = `${result}${result ? '&' : ''}From.DateOfBirth=${date}&To.DateOfBirth=${date}`;
  }

  return result;
};

// format datetime
export const mapDateField = (date?: string | Date | null): string => {
  if (!date) {
    return '';
  }

  return reformatDateString(moment(date).format('MM/DD/Y'));
};

// entity from the Create/Open/Get Encounter service parsing
export const mapEncounterResultToEntity = (result: ServiceEncounterEntity, facilities: IdDescriptionBase[]): EncounterEntity => {
  const sex = result.Encounter.PatientSex || '';
  const overrideLOS = result.Encounter.OverrideLOS ? parseInt(result.Encounter.OverrideLOS, 10) : undefined;
  const admitDXServiceId = result.Encounter.AdmitDiagnosis && result.Encounter.AdmitDiagnosis.ViewId
    ? result.Encounter.AdmitDiagnosis.ViewId : '';
  const admitDXServiceCode = result.Encounter.AdmitDiagnosis && result.Encounter.AdmitDiagnosis.Code
    ? result.Encounter.AdmitDiagnosis.Code : '';
  const facilityId = findFacility(result.Encounter.Facility, facilities);

  const encounter: EncounterEntity = {
    accountNumber: result.Encounter.AccountNumber || '',
    admitDate: mapDateField(result.Encounter.AdmitDate),
    admitDiagnosisCode: {
      code: admitDXServiceCode,
      type: CodeType.ICD10CM_DX,  // would be corrected later in findAllCodeInformation
      description: result.Encounter.AdmitDiagnosis && result.Encounter.AdmitDiagnosis.CustomCodeDescription
        ? result.Encounter.AdmitDiagnosis.CustomCodeDescription : '',
      gridCodeType: GridCodeType.ADMIT_DX,
      id: ADMIT_DX_ID,
      serviceId: admitDXServiceId,

      Validations: []
    },
    ageInYears: result.Encounter.PatientAge || '',
    billType: result.Encounter.BillType || '',
    concurrencyVersion: result.ConcurrencyVersion,
    dateOfBirth: mapDateField(result.Encounter.DateOfBirth),
    diagnoses: mapServiceCodeGroupToCodeGroup(result.Encounter.Diagnoses, GridCodeType.DIAGNOSES, result.Encounter.IsInterfaced),
    dischargeDate: mapDateField(result.Encounter.DischargeDate),
    facility: facilityId,
    financialClass: result.Encounter.FinancialClass || '',
    firstName: result.Encounter.FirstName || '',
    ipGroupingType: findFirstGroupingSourceInValidationResult(true, result.ValidationResult),
    opGroupingType: findFirstGroupingSourceInValidationResult(false, result.ValidationResult),
    id: result.DocumentId,
    viewId: result.Encounter.ViewId,
    inProcedures: mapServiceCodeGroupToCodeGroup(result.Encounter.InProcedures,
      GridCodeType.INPROCEDURES),
    lastName: result.Encounter.LastName || '',
    lockedBy: result.LockedBy,
    // lengthOfStay - it is calculated in UI
    medicalRecordNumber: result.Encounter.MedicalRecordNumber || '',
    middleInitial: result.Encounter.MiddleInitial || '',
    birthWeight: result.Encounter.BirthWeight || '',
    ofa: result.Encounter.SourceOfAdmission || '',
    payerFlag: result.Encounter.PayerFlag || '',
    outProcedures: mapServiceCodeGroupToCodeGroup(result.Encounter.OutProcedures,
      GridCodeType.OUTPROCEDURES),
    overrideLOS,
    patientStatus: result.Encounter.PatientStatus || '',
    readOnly: false,
    recordStatus: result.Encounter.RecordStatus || '',
    attendingMd: result.Encounter.Provider || '',
    service: result.Encounter.Service || '',
    sex: sex as Sex,
    totalCharges: result.Encounter.TotalCharges ? parseFloat(result.Encounter.TotalCharges) : undefined,
    thirdPartyLiability: result.Encounter.ThirdPartyLiability ? parseFloat(result.Encounter.ThirdPartyLiability) : undefined,
    type: result.Encounter.EncounterType,
    visitReasons: mapServiceCodeGroupToCodeGroup(result.Encounter.VisitReasons,
      GridCodeType.VISITREASONS),

    valueCodes: mapServiceValueConditionCodesToCodes(result.Encounter.ValueCodes, true),
    conditionCodes: mapServiceValueConditionCodesToCodes(result.Encounter.ConditionCodes, false),

    // raw EncounterResult from the services
    ValidationResult: result.ValidationResult,
    GroupingResults: result.GroupingResults,
    ProcessMNEResult: result.ProcessMNEResult,
    IsInterfaced: result.Encounter.IsInterfaced,
    InterfaceMode: result.Encounter.InterfaceMode,
    InterfaceCallbackUrl: result.Encounter.InterfaceCallbackUrl,
    IsDirty: result.IsDirty !== undefined ? result.IsDirty : result.Encounter.IsDirty,

    billingNotes: result.Encounter.BillingNotes?.map((billingNote) => billingNote).join('; ') || '',
  };

  // we store missed values as undefined not null
  Object.keys(encounter).map((key) => {
    if (encounter[key] === null) {
      encounter[key] = undefined;
    }

    return key;
  })

  // calculate all codes information from result: validations, description, hcc
  findAllCodesInformation(encounter);
  findAllConditionCodesInformation(encounter);

  return encounter;
};

const mapCodeGroup = (current: CodeGroup, choiceLists: ChoiceListsState): ServiceViewCode[] => {
  const result: ServiceViewCode[] = [];

  for (let ind = 0, len = current.codes.length; ind < len; ind++) {
    const code = current.codes[ind];
    // skip empty records
    if (!code.empty && code.serviceCode) {
      result.push(mapCodeToServiceEntity(code, choiceLists));
    }
  }

  return result;
};

export const mapCodeFields = (_serviceEncounter: ServiceEncounterView, current: EncounterEntity, choiceLists: ChoiceListsState) => {
  const serviceEncounter = _serviceEncounter;
  serviceEncounter.Diagnoses = mapCodeGroup(current.diagnoses, choiceLists);
  serviceEncounter.InProcedures = mapCodeGroup(current.inProcedures, choiceLists);
  serviceEncounter.OutProcedures = mapCodeGroup(current.outProcedures, choiceLists);
  serviceEncounter.VisitReasons = mapCodeGroup(current.visitReasons, choiceLists);

  serviceEncounter.AdmitDiagnosis = {
    Code : current.admitDiagnosisCode.code ? current.admitDiagnosisCode.code : null,
    CodeDescription: null,
    CodeType : current.admitDiagnosisCode.code && current.admitDiagnosisCode.type
      ? CodeType.ICD10CM_DX : null,
    CustomCodeDescription : current.admitDiagnosisCode.code && current.admitDiagnosisCode.description
      ? current.admitDiagnosisCode.description : '',
    PresentOnAdmission : null,
    ViewId : current.admitDiagnosisCode.code ? '' : null
  };
};

const mapCodeToServiceEntity = (code: BaseCodeRow, choiceLists: ChoiceListsState): ServiceViewCode => {
  const serviceCode = {
    Code: code.serviceCode || '',
    CodeDescription: null,
    CodeType: mapToServiceCodeType(code.type),
    CustomCodeDescription: code.customCodeDescription || '',
    Episode: code.episode || '',
    Modifiers: code.modifiers ? [...code.modifiers] : null,
    Charges: code.charges || '',
    NonCoveredCharges: code.nccharges || '',
    ProcedureDate: code.date && !isDateValidator(code.date as unknown as string, 'Incorrect Date') ? code.date : '',
    PresentOnAdmission: code.presentOnAdmission ? code.presentOnAdmission : '',
    UnitsOfService: code.units || '',
    ViewId: code.id,
    RevenueCode: code.revenueCode || '',
    Provider: code.provider && checkDDLFieldLength(choiceLists.providers, MAX_PROVIDER_LENGTH, code.provider) ? code.provider : '',
  };

  return serviceCode;
};

// why format was changed to single letters?
const mapServiceCodeType = (type: string): CodeType => {
  switch (type) {
    case CodeType.CPT4:
    case "C":
      return CodeType.CPT4;
    case CodeType.HCPCS:
    case "H":
      return CodeType.HCPCS;
    case CodeType.ICD10CM_DX:
    case "X":
      return CodeType.ICD10CM_DX;
    case CodeType.ICD10CM_E:
    case "Z":
      return CodeType.ICD10CM_E;
    case CodeType.ICD10PCS_PR:
    case "Y":
      return CodeType.ICD10PCS_PR;
    default:
      return CodeType.ICD10CM_DX;
  }
};

const mapToServiceCodeType = (type: CodeType | undefined): string => {
  switch (type) {
    case CodeType.CPT4:
      return CodeType.CPT4;
    case CodeType.HCPCS:
      return CodeType.HCPCS;
    case CodeType.ICD10CM_DX:
      return CodeType.ICD10CM_DX;
    case CodeType.ICD10CM_E:
      return CodeType.ICD10CM_E;
    case CodeType.ICD10PCS_PR:
      return CodeType.ICD10PCS_PR;
    default:
      return CodeType.ICD10CM_DX;
  }
};

const mapServiceCodeToCode = (code: ServiceViewCode, gridCodeType: GridCodeType, isInterfaced: boolean): BaseCodeRow => {
  let presentOnAdmission = code.PresentOnAdmission
    ? PresentOnAdmission[code.PresentOnAdmission] : PresentOnAdmission.EMPTY;
  if (code.PresentOnAdmission === '1') {
    presentOnAdmission = PresentOnAdmission.ONE;
  }
  // interfaced encounter sent an unknown POA value (WEB-3669)
  if(isInterfaced && presentOnAdmission === undefined){
    presentOnAdmission = code.PresentOnAdmission;
  }
  const clientCode = {
    code: code.Code ? code.Code : '',
    customCodeDescription: code.CustomCodeDescription ? code.CustomCodeDescription : '',
    date: code.ProcedureDate ? mapDateField(code.ProcedureDate) : '',
    description: '',    // calculated field
    episode: code.Episode ? code.Episode : '',
    gridCodeType,
    id: code.ViewId ? code.ViewId : '',
    modifiers: code.Modifiers ? [...code.Modifiers] : undefined,
    charges: code.Charges ? code.Charges : '',
    nccharges: code.NonCoveredCharges ? code.NonCoveredCharges : '',
    units: code.UnitsOfService ? code.UnitsOfService : '',
    presentOnAdmission,
    serviceCode: code.Code ? code.Code : '',
    serviceCustomDescription: code.CustomCodeDescription ? code.CustomCodeDescription : '',
    type: mapServiceCodeType(code.CodeType ? code.CodeType : ''),
    revenueCode: code.RevenueCode ? code.RevenueCode : '',
    provider: code.Provider ? code.Provider : '',
    IsChargeDriven: code.IsChargeDriven,

    Validations: []
  };

  return clientCode;
};

const mapServiceCodeGroupToCodeGroup = (codes: ServiceViewCode[], gridCodeType: GridCodeType, isInterfaced=false): CodeGroup => {
  const result: CodeGroup = {
    codes: [],
    editedCode: undefined
  };

  for (let ind = 0, len = codes.length; ind < len; ind++) {
    result.codes.push(mapServiceCodeToCode(codes[ind], gridCodeType, isInterfaced));
  }

  return result;
};

// changes in all codes
export const compareAllCodeArrays = (result: JSONPATCH[], oldValue: ServiceEncounterView,
  newValue: ServiceEncounterView) => {
  compareCodeArrays(result, '/Diagnoses/', oldValue.Diagnoses, newValue.Diagnoses);
  compareCodeArrays(result, '/InProcedures/', oldValue.InProcedures, newValue.InProcedures);
  compareCodeArrays(result, '/OutProcedures/', oldValue.OutProcedures, newValue.OutProcedures);
  compareCodeArrays(result, '/VisitReasons/', oldValue.VisitReasons, newValue.VisitReasons);

  // admit diagnosis comparison
  // we must not have this situations with empty AdmitDiagnosis fields
  if (!oldValue.AdmitDiagnosis || !newValue.AdmitDiagnosis)   {
    return;
  }

  if (oldValue.AdmitDiagnosis.Code && !newValue.AdmitDiagnosis.Code) {
    result.push({
      op: 'remove',
      path: '/AdmitDiagnosis/Code',
    });
  }

  if (!oldValue.AdmitDiagnosis.Code && newValue.AdmitDiagnosis.Code) {
    result.push({
      op: 'replace',
      path: '/AdmitDiagnosis/Code',
      value: newValue.AdmitDiagnosis.Code
    });
  }

  if (oldValue.AdmitDiagnosis.Code && newValue.AdmitDiagnosis.Code) {
    compareCodes(result, '/AdmitDiagnosis/', oldValue.AdmitDiagnosis, newValue.AdmitDiagnosis);
  }
};

// changes between code arrays
export const compareCodeArrays = (result: JSONPATCH[],
  basePath: string, oldValues: ServiceViewCode[],
  newValues: ServiceViewCode[]) => {
  const oldLen = oldValues.length;
  const newLen = newValues.length;
  const oldOrder: string[] = [];
  const newOrder: string[] = [];
  const newOrderWithAdded: string[] = [];
  const newAdded: boolean[] = [];

  // find deleted and changed items
  const deleted: JSONPATCH[] = [];
  let deletedRowsCount = 0;
  for (let ind = 0; ind < oldLen; ind++) {
    let found = false;
    for (let newInd = 0; newInd < newLen; newInd++) {
      if (oldValues[ind].ViewId === newValues[newInd].ViewId) {
        // fill changes if necessary
        compareCodes(result, `${basePath + (ind)}/`, oldValues[ind], newValues[newInd]);

        found = true;
        break;
      }
    }

    if (!found) {
      deleted.push({
        op: 'remove',
        path: basePath + (ind - deletedRowsCount),
      });

      deletedRowsCount++;
    } else {
      oldOrder.push(oldValues[ind].ViewId || 'undefinedId');
    }
  }

  // change first, then delete, then add, then move
  for (let ind = 0, deletedLen = deleted.length; ind < deletedLen; ind++) {
    result.push(deleted[ind]);
  }

  // find added items
  // let addIndex = oldOrder.length; // to define exact adding position
  let addedItemsNumber = 0;
  for (let newInd = 0; newInd < newLen; newInd++) {
    let found = false;
    for (let ind = 0; ind < oldLen; ind++) {
      if (oldValues[ind].ViewId === newValues[newInd].ViewId) {
        found = true;
        break;
      }
    }

    if (!found) {
      const addedCode = {
        Code: newValues[newInd].Code,
        ViewId: newValues[newInd].ViewId
      }

      // some fields can be added before saving
      TEXT_SERVICE_FIELDS.forEach((field) => {
        if (newValues[newInd][field]) {
          addedCode[field] = newValues[newInd][field];
        }
      });

      if (newValues[newInd].Modifiers && newValues[newInd].Modifiers?.length) {
        // eslint-disable-next-line dot-notation
        addedCode['Modifiers'] = newValues[newInd].Modifiers;
      }

      result.push({
        op: 'add',
        path: `${basePath}-`,
        value: addedCode
      });

      // addIndex++;
    }

    if (found) {
      newOrder.push(newValues[newInd].ViewId || 'undefinedId');
    } else {
      addedItemsNumber++;
    }

    newOrderWithAdded.push(newValues[newInd].ViewId || 'undefinedId');
    newAdded.push(!found);
  }

  // find reordering. Now oldOrder - all kept ids in the exact order, newOrder - all old ids in the new order
  const len = oldOrder.length;
  if (len === newOrder.length && len > 0) {
    let done = false;
    while (!done) {
      done = true;

      // for each order in the new result - find corresponding old result if necessary, reorder and repeat
      for (let ind = 0; ind < len; ind++) {
        if (newOrder[ind] !== oldOrder[ind]) {
          // move row operation
          for (let orderInd = ind + 1; orderInd < len; orderInd++) {
            if (newOrder[ind] === oldOrder[orderInd]) {
              result.push({
                from: basePath + orderInd,
                op: 'move',
                path: basePath + ind,
              });

              // perform reordering
              for (let tempInd = orderInd; tempInd > ind; tempInd--) {
                oldOrder[tempInd] = oldOrder[tempInd - 1];
              }

              oldOrder[ind] = newOrder[ind];
            }
          }

          // compare new order arrays again
          done = false;
          break;
        }
      }
    }
  }

  // row can be inserted or reordered after adding. I suppose that [op = add] always adds an item to the end
  // we have to keep all added to the end items. All inserted items are handled as reordering
  // previous items can be around added items
  let lastInd = newOrderWithAdded.length - addedItemsNumber;
  for (let ind = 0; ind < lastInd; ind++) {
    if (newAdded[ind]) {
      // this row was added to the current empty position and moved then
      result.push({
        from: basePath + lastInd,
        op: 'move',
        path: basePath + ind
      });

      lastInd++;
    }
  }
};

const addCompareCodesOp = (result: JSONPATCH[], basePath: string, key: string, serviceKey: string,
  oldCode: ServiceViewCode,
  newCode: ServiceViewCode) => {

  const oldValue = oldCode[key]                       ;
  const newValue = newCode[key];

  if (oldValue === undefined) {
    if (newValue === undefined) {
      return;
    }

    result.push({
      op: 'add',
      path: basePath + serviceKey,
      value: newValue
    });

    return;
  }

  if (newValue === undefined) {
    result.push({
      op: 'remove',
      path: basePath + serviceKey
    });

    return;
  }

  if (oldValue !== newValue) {
    result.push({
      op: 'replace',
      path: basePath + serviceKey,
      value: newValue
    });
  }
};

const TEXT_SERVICE_FIELDS = [
  'CustomCodeDescription', 'DiagnosesFlags', 'PresentOnAdmission', 'ProcedureDate',
  'Episode', 'UnitsOfService', 'Charges', 'NonCoveredCharges', 'Amount', 'RevenueCode', 'Provider'
];

const compareCodes = (result: JSONPATCH[], basePath: string,
  oldCode: ServiceViewCode,
  newCode: ServiceViewCode) => {
  addCompareCodesOp(result, basePath, 'Code', 'Code', oldCode, newCode);

  TEXT_SERVICE_FIELDS.forEach((field) => {
    addCompareCodesOp(result, basePath, field, field, oldCode, newCode);
  });

  addCompareModifiersOp(result, basePath, 'Modifiers', 'Modifiers', oldCode, newCode);
};

export const collectChanges = (oldServiceEntity: ServiceEncounterView, newEntity: EncounterEntity, choiceLists: ChoiceListsState) => {
  const newServiceEntity = mapEncounterToServiceEntity(newEntity, choiceLists);
  return collectChangesBetweenServiceEntities(oldServiceEntity, newServiceEntity);
}

export const collectChangesBetweenServiceEntities = (oldServiceEntity: ServiceEncounterView, newServiceEntity: ServiceEncounterView) => {
  const inProgress = newServiceEntity;
  const saved = oldServiceEntity; // mapEncounterToServiceEntity(oldEntity, choiceLists);

  // diff incorrectly handles undefined -> undefined as 'remove'
  cleanUndefinedValues(saved, inProgress);

  // compare without codes first
  const savedCopy = { ...saved };
  const inProgressCopy = { ...inProgress };
  savedCopy.Diagnoses = [];
  savedCopy.InProcedures = [];
  savedCopy.OutProcedures = [];
  savedCopy.VisitReasons = [];
  savedCopy.AdmitDiagnosis = null;
  savedCopy.ValueCodes = [];
  savedCopy.ConditionCodes = [];

  inProgressCopy.Diagnoses = [];
  inProgressCopy.InProcedures = [];
  inProgressCopy.OutProcedures = [];
  inProgressCopy.VisitReasons = [];
  inProgressCopy.AdmitDiagnosis = null;
  inProgressCopy.ValueCodes = [];
  inProgressCopy.ConditionCodes = [];
  const data = jsonpatch.diff(savedCopy, inProgressCopy);

  // now compare code arrays
  compareAllCodeArrays(data, saved, inProgress);

  // compare value and condition codes
  compareCodeArrays(data, '/ValueCodes/', saved.ValueCodes, inProgress.ValueCodes);
  compareCodeArrays(data, '/ConditionCodes/', saved.ConditionCodes, inProgress.ConditionCodes);

  return data;
};

export const getEncounterChanges = (encounter: EncounterState, choiceLists: ChoiceListsState) => {
  return collectChanges(encounter.savedServiceEntity, encounter.inProgress, choiceLists);
};

// find corresponding CodeInfos item
export const findCodeResultInfo = (Result?: ServiceEncounterValidationResult | null, codeId?: string) => {
  // find corresponding Service Result fields
  if (codeId && Result && Result.CodeInfos) {
    for (let ind = 0, len = Result.CodeInfos.length; ind < len; ind++) {
      const resultCode = Result.CodeInfos[ind];
      if (codeId === resultCode.ViewId) {
        return resultCode;
      }
    }
  }

  return null;
};

// find all edits in the edit group that are referenced to the code
const findCodeEditsInEditGroup = (result: ServiceResultValidationEdit[],
  edits: ServiceResultValidationEdit[], codeViewId: string) => {
  for (let ind = 0, len = edits.length; ind < len; ind++) {
    const edit = edits[ind];
    // we need to find corresponding ReferencedViewId in the Parameters
    if (edit && edit.Parameters) {
      for (let pind = 0, plen = edit.Parameters.length; pind < plen; pind++) {
        const parameter = edit.Parameters[pind];

        // ReferencedViewId = codeViewId
        if (parameter && parameter.ReferencedViewId === codeViewId /* && !parameter.Name */) {
          result.push(edit);
          break;
        }
      }
    }
  }
};

// find all edits in the edit group that are referenced to the code asdf
const findCodeEditsInMneEditGroup = (result: ServiceResultMneEdit[],
  edits: ServiceResultMneEdit[], codeViewId: string) => {
  for (let ind = 0, len = edits.length; ind < len; ind++) {
    const edit = edits[ind];
    // ReferencedViewId = codeViewId
    if (edit.ReferencedViewId === codeViewId && edit.Policies) {
      // hoist the policy to top level before pushing
      // so that TEE controls note/render logic works like the legacy format
      for (let p = 0; p < edit.Policies.length; p++) {
        const formattedEdit = {
          ...edit,
          ...edit.Policies[p]
        };
        delete formattedEdit.Policies;
        result.push(formattedEdit);
      }
      break;
    }
  }
};

// M* and m* parameters that should be handled as modifiers
const isSpecialModifierParameter = (type) => {
  return type && (type.startsWith("M") || type.startsWith("m")) && type.length <= 2;
}

// attribute must not be empty string to not broke html template
const addAttribute = (name: string, value, emptyValue = '') => {
  if (value === '' || value && value.trim() === '') {
    if (emptyValue === '') {
      return '';
    }

    return `${name}=${emptyValue}`;
  }

  return `${name}=${value}`;
}

// prepare text with parameter name. It is raw value if prepareLinks = false or special link if prepareLinks = true
const getParameterString = (edit: ServiceResultValidationEdit, param: ServiceResultValidationEditParameter, value?: string | null, prepareLinks?: boolean) => {
  if (!value) {
    return '';
  }

  if (!prepareLinks) {
    return value;
  }

  const { CodeType: ReferencedCodeType } = param;
  const ReferencedViewId = param.ReferencedViewId === edit.EncounterViewId ? null : param.ReferencedViewId;

  if (param.Field === 'Modifiers') {
    /*
      I must find referenced code in this Edit.Parameters. Must not be empty.
      a) add modifier - { ReferencedViewId: undefined,
        Parameters: [..., { Field: 'Code', ReferencedViewId: use_it, Value: use_it_as_code }, ...,
                        { Field: 'Modifiers', ReferencedViewId: null }
        ]}
        NOTE: This logic has changed to where the ReferencedViewId is no longer set to null if it is an add.
        It will be set to the ViewId of the encounter.
      b) remove modifier - {
        Parameters: [..., { Field: 'Modifiers', ReferencedViewId: find_code_by_it }, ...]
       }
    */
    let ModifiersReferencedCode: string | null | undefined = '';
    let ModifiersReferencedCodeType = ReferencedCodeType;
    let ModifiersReferencedCodeViewId = ReferencedViewId;
    // only for Add Modifier. In remove modifier I must have ReferencedViewId
    if (!ReferencedViewId || ReferencedViewId === 'null' || ReferencedViewId === 'undefined') {
      for (let ind = 0, len = edit.Parameters.length; ind < len; ind++) {
        if (edit.Parameters[ind].Field === 'Code' && edit.Parameters[ind].ReferencedViewId) {
          // update only for the Add Modifier
          ModifiersReferencedCodeViewId = ModifiersReferencedCodeViewId || edit.Parameters[ind].ReferencedViewId;
          // update only for the Add Modifier
          ModifiersReferencedCodeType = ModifiersReferencedCodeType || edit.Parameters[ind].CodeType;
          // I have not other way to get information about this code because I must know original value (code can be changed in the encounter)
          ModifiersReferencedCode = edit.Parameters[ind].Value;

          break;
        }
      }
    }

    return `<a href='#' class="code" ${addAttribute('data-code-field', param.Field)} ${addAttribute('data-code-ref-view-id', ReferencedViewId)} ${addAttribute('data-code-code-type', ReferencedCodeType)} ${addAttribute('data-modifier-code', ModifiersReferencedCode)} ${addAttribute('data-modifier-code-type', ModifiersReferencedCodeType)} ${addAttribute('data-modifier-code-ref-view-id', ModifiersReferencedCodeViewId)} onclick="event.preventDefault();">${value}</a>`;
  }

  if (isSpecialModifierParameter(ReferencedCodeType)) {
    /*
      Special case for M* and m* parameters that should be shown as modifiers
      I must find referenced code in this Edit.Parameters. Must not be empty.
    */
    let ModifiersReferencedCode: string | null | undefined = '';
    let ModifiersReferencedCodeType: string | null | undefined = "";
    let ModifiersReferencedCodeViewId: string | null | undefined = "";
    for (let ind = 0, len = edit.Parameters.length; ind < len; ind++) {
      if (edit.Parameters[ind].Field === 'Code' && edit.Parameters[ind].ReferencedViewId) {
        // update only for the Add Modifier
        ModifiersReferencedCodeViewId = edit.Parameters[ind].ReferencedViewId;
        // update only for the Add Modifier
        ModifiersReferencedCodeType = edit.Parameters[ind].CodeType;
        // I have not other way to get information about this code because I must know original value (code can be changed in the encounter)
        ModifiersReferencedCode = edit.Parameters[ind].Value;

        break;
      }
    }

    return `<a href='#' class="code" ${addAttribute('data-code-field', 'Modifiers')} ${addAttribute('data-code-ref-view-id', ModifiersReferencedCodeViewId)} ${addAttribute('data-code-code-type', ModifiersReferencedCodeType)} ${addAttribute('data-modifier-code', ModifiersReferencedCode)} ${addAttribute('data-modifier-code-type', ModifiersReferencedCodeType)} ${addAttribute('data-modifier-code-ref-view-id', ModifiersReferencedCodeViewId)} onclick="event.preventDefault();">${value}</a>`;
  }

  return `<a href='#' class="code" ${addAttribute('data-code-field', param.Field)} ${addAttribute('data-code-ref-view-id', ReferencedViewId)} ${addAttribute('data-code-code-type', ReferencedCodeType)} onclick="event.preventDefault();">${value}</a>`;
}

// transform parameters to the string
const translateParameters = (edit: ServiceResultValidationEdit, params: ServiceResultValidationEditParameter[], paramDetails: string, prepareLinks?: boolean) => {
  // if there are more than one parameter with same name, they should be separated in text.
  // By default all elements except last must be separated by ','. Last element must be separated by "or".
  const delimeter = ', ';     // by default;
  let lastDelimiter = ' or '; // by default
  const resultParts: string[] = [];

  // it is possible to specify "and" separator for the last element directly in the macros, by using macros detail ":and". For example {ParamName:and}
  if (paramDetails && paramDetails.toLowerCase() === 'and') {
    lastDelimiter = ' and ';
  }

  const existingValues: (string | null | undefined)[] = [];
  for (let ind = 0, len = params.length; ind < len; ind++) {
    const param = params[ind];
    // skip parameters with same values: see GUI-1746 for details
    const isDuplicatedValue = existingValues.find(value => value === param.Value);
    if (!isDuplicatedValue) {
      existingValues.push(param.Value);

      let paramStr = '';
      let expanded = false;
      // additional case to expandParam:
      // It is indended to process single parameter which represent codes range and has two codes in the Value in form "XXX.X-YYY.Y"
      // in this case we have to separate this parameter in two, for XXX.X and for YYY.Y
      // this case is only for the codes which are not present in the encounter, and has known code type info
      if ((param.CodeType || param.Field === 'Modifiers') && !param.ReferencedViewId && param.Value) {
        // currently we find codes range in value where codes are separated by '-'.
        const splt = param.Value.split('-').map(s => s.trim()).filter(s => s.length > 0);
        if (splt.length > 1) {
          expanded = true;
          for (let spltind = 0, spltlen = splt.length; spltind < spltlen; spltind++) {
            paramStr = `${paramStr}${spltind > 0 ? ' - ' : ''}${getParameterString(edit, param, splt[spltind], prepareLinks)}`;
          }
        }
      }

      if (!expanded) {
        paramStr = getParameterString(edit, param, param.Value, prepareLinks);
      }

      resultParts.push(paramStr);
    }
  }

  let result = '';
  for (let resInd = 0, resLen = resultParts.length; resInd < resLen; resInd++) {
    // add parameter value and delimiter
    const currentDelimiter = resInd === resLen - 1 ? lastDelimiter : delimeter;
    const usedDelimiter = resInd > 0 ? currentDelimiter : '';
    result = `${result}${usedDelimiter}${resultParts[resInd]}`;
  }

  return result;
};

// find value for the description's parameter. {s} is stored with empty Name
const translateMacros = (macros: string, edit: ServiceResultValidationEdit, ReferencedViewId?: string, prepareLinks?: boolean) => {
  // macros can be in the form of {XXX:YYY} where XXX is macros name or parameter name, YYY - additional macroses details
  const splited = macros.split(':');
  const paramName = splited[0].trim();
  const paramDetails = splited.length > 1 ? splited[1].trim() : '';
  const params: ServiceResultValidationEditParameter[] = [];

  // first try to check if macros name means edit parameter(s).
  for (let ind = 0, len = edit.Parameters.length; ind < len; ind++) {
    const editParam = edit.Parameters[ind];

    if (!ReferencedViewId || ReferencedViewId === editParam.ReferencedViewId) {
      if ((paramName === 's' || paramName === 'S') && !editParam.Name) {
        params.push(editParam);
      }

      if (editParam.Name === paramName) {
        params.push(editParam);
      }
    }
  }

  // if there is no parameters with name XXX, check special macroses
  if (params.length === 0) {
    // {M:modifier} or {M*:modifier} - macros which allows to insert modifier in text. Not implemented now on backend side
    if (isSpecialModifierParameter(paramName) && paramDetails) {
      // modifier case "Mx:modifier" or something like this, do not used now
      params.push({
        CodeType: paramName.toUpperCase(),
        Value: paramDetails
      });
      // {T:XXX} - valid code XXX of type T. For example: {X:J18.1}
    } else if (paramName.length === 1 && paramDetails) {
      params.push({
        CodeType: paramName.toUpperCase(),
        Value: paramDetails
      });
      // {T?:XXX} or {T*:XXX} - partial code XXX of type T. For example: {X*:J18}
    } else if (paramName.length === 2 && (paramName[0] === '?' || paramName[0] === '*') && paramDetails) {
      // special case for handle {?T:XXX} or {*T:XXX}- partial code or search XXX with type T
      params.push({
        CodeType: paramName.toUpperCase(),
        Value: paramDetails
      });
    }
  }

  // return macros name if we cannot translate it
  if (params.length === 0) {
    return macros;
  }

  return translateParameters(edit, params, paramDetails, prepareLinks);
};

// find error description string
export const translateDescription = (description: string, edit: ServiceResultValidationEdit, prepareLinks?: boolean, ReferencedViewId?: string) => {
  let pos = 0;
  let result = '';
  const len = description.length;
  do {
    const start = description.indexOf('{', pos);
    if (start === - 1) {
      result += description.substr(pos);
      pos = len;
    } else {
      const end = description.indexOf('}', start + 1);
      result += description.substr(pos, start - pos);
      if (end === -1) {
        pos = len;
      } else {
        const param = description.substr(start + 1, end - start - 1);
        if (param) {
          result += translateMacros(param, edit, ReferencedViewId, prepareLinks);
        }

        pos = end + 1;
      }
    }
  } while (pos < len);

  return result;
};

// find error fieldNames
export const findFieldNames = (edit: ServiceResultValidationEdit, ReferencedViewId: string) => {
  const fields: string[] = [];

  for (let ind = 0, len = edit.Parameters.length; ind < len; ind++) {
    const editParam = edit.Parameters[ind];

    if (ReferencedViewId === editParam.ReferencedViewId && editParam.Field) {
      fields.push(editParam.Field);
    }
  };

  return fields;
}

const readNumberField = (value?: string | number | null) => {
  if (value === '0' || value === 0) {
    return '0';
  }

  if (!value) {
    return '';
  }

  return value;
}

const convertToNumber = (value?: string | number | null) => {
  if(!value) {
    return 0;
  }
  return Number(value);
}

interface CodeGroupingInfo {
  grouperFlags: string[];
  validations: CodeValidation[];
  procGroupingResult?: ProcGroupingResultValues;
  diagGroupingResult?: DiagnosisPricingResult;
}

const FIELDS_SERVICE_TO_CLIENT_MAP = {
  AccountNumber: 'accountNumber',
  AdmitDate: 'admitDate',
  AdmitDiagnosis: 'admitDX',
  PatientAge: 'ageInYears',
  BillType: 'billType',
  DateOfBirth: 'dateOfBirth',
  Diagnoses: 'diagnoses',
  DischargeDate: 'dischargeDate',
  Facility: 'facility',
  FinancialClass: 'financialClass',
  FirstName: 'firstName',
  LastName: 'lastName',
  MedicalRecordNumber: 'medicalRecordNumber',
  MiddleInitial: 'middleInitial',
  BirthWeight: 'birthWeight',
  Service: 'service',
  SourceOfAdmission: 'ofa',
  OverrideLOS: 'overrideLOS',
  PatientSex: 'sex',
  PatientStatus: 'patientStatus',
  RecordStatus: 'recordStatus',
  TotalCharges: 'totalCharges',
  ThirdPartyLiability: 'thirdPartyLiability',
  EncounterType: 'type',
  LengthOfStay: 'los',
  Provider: 'Provider',
  AttendingMd: 'attendingMd',
  ValueCodes: 'valueCodes',
  ConditionCodes: 'conditionCodes',
  VisitReasons: 'visitReasons'
};

// mapping from the service fields to the client side fields. Only these fields are validated
export const getEncounterFieldFromServiceField = (field?: string | null) => {
  if (!field) {
    return '';
  }

  if (!FIELDS_SERVICE_TO_CLIENT_MAP[field]) {
    return '';
  }

  return FIELDS_SERVICE_TO_CLIENT_MAP[field];
}

const VALIDATION_CODE_FIELDS_TO_CODE_FIELDS = {
  'Diagnoses.PresentOnAdmission': 'PresentOnAdmission',
  'InProcedures.ProcedureDate': 'ProcedureDate',
  'InProcedures.Episode': 'Episode',
  'InProcedures.Provider': 'Provider',
  'OutProcedures.RevenueCode': 'RevenueCode',
  'OutProcedures.UnitsOfService': 'UnitsOfService',
  'OutProcedures.Charges': 'Charges',
  'OutProcedures.NonCoveredCharges': 'NonCoveredCharges',
  'OutProcedures.Modifiers': 'Modifiers',
  'OutProcedures.ProcedureDate': 'ProcedureDate',
  'OutProcedures.Episode': 'Episode',
  'OutProcedures.Provider': 'Provider',
}

// mapping from the validation service fields to the client side fields. Do we need so many formats on the services size?
const getEncounterCodeFieldSpecialMapping = (field?: string | null) => {
  if (!field) {
    return '';
  }

  if (!VALIDATION_CODE_FIELDS_TO_CODE_FIELDS[field]) {
    return field;
  }

  return VALIDATION_CODE_FIELDS_TO_CODE_FIELDS[field];
}

// analyze Result and collect all code information: validations and flags
const findCodeInformation = (encounter: EncounterEntity, ipGroupingType?: string, opGroupingType?: string,
  codeViewId?: string | null): CodeGroupingInfo => {
  if (!codeViewId) {
    return {
      grouperFlags: [],
      procGroupingResult: {},
      validations: [],
      diagGroupingResult: {},
    };
  }

  const result: ServiceResultValidationEdit[] = [];
  const mneResult: ServiceResultMneEdit[] = [];
  let grouperFlags: string[] = [];
  const procGroupingResult: ProcGroupingResultValues = {};
  let diagGroupingResult: DiagnosisPricingResult = {};

  const primaryIPGroupingViewId = findFirstGroupingSource(true, encounter);
  const primaryOPGroupingViewId = findFirstGroupingSource(false, encounter);

  // we need to collect Edits from the ValidationEdits and from the FieldsEdits
  // we need to collect Edits from the Primary grouping in the Inpatient GroupingResults for the IP encounter
  // we need to collect Edits from the Primary grouping in the Outpatient GroupingResults for the OP encounter
  // in the IsInpatient mode we need to collect Flags from the current groupings
  // we try to find ProcGroupingResults in the current grouping in the Outpatient GroupingResults

  const validationEdits = encounter.ValidationResult?.ValidationEdits || [];
  findCodeEditsInEditGroup(result, validationEdits, codeViewId);

  const fieldsEdits = encounter.ValidationResult?.FieldsEdits || [];
  findCodeEditsInEditGroup(result, fieldsEdits, codeViewId);

  const mneEdits = encounter.ProcessMNEResult?.MneEdits || [];
  findCodeEditsInMneEditGroup(mneResult, mneEdits, codeViewId);

  // GroupingResults handling
  if (encounter.GroupingResults) {
    const { GroupingResults } = encounter;
    for (let igrp = 0, igrpLen = GroupingResults.length; igrp < igrpLen; igrp++) {
      const correspondingGroupingSource = findGroupingSourceByViewId(encounter, GroupingResults[igrp].ViewId);

      // Inpatient GroupingResults handling: current GroupingResults and Encounter are Inpatient
      if (correspondingGroupingSource && correspondingGroupingSource.IsInpatient) {
        const igrpResult = GroupingResults[igrp];

        // use selected grouping from the Inpatient GroupingResults to collect flags. collect flags only in the IsInpatient mode
        // only collect flags here. They would be filtered in the prepareCodeFlags function. All this is necessary to simplify rendering
        if (!ipGroupingType || ipGroupingType === igrpResult.ViewId) {
          const igrpFlags = igrpResult && igrpResult.Flags;
          if (igrpFlags) {
            for (let find = 0, flen = igrpFlags.length; find < flen; find++) {
              const flag = igrpFlags[find];
              if (flag && flag.ViewId === codeViewId) {
                grouperFlags = flag.Flags && Array.isArray(flag.Flags) ? flag.Flags : [];
              }
            }
          }

          if(GroupingResults[igrp].DiagGroupingResults) {
            if(correspondingGroupingSource && correspondingGroupingSource.PricerType === PricerType.CmsPsych) {
              const diagResult = GroupingResults[igrp].DiagGroupingResults.find(v => v.ViewId === codeViewId);

              if(diagResult) {
                diagGroupingResult= {
                  PsychComorbidityAdjustmentFactor: diagResult.Values?.PsychComorbidityAdjustmentFactor,
                  PsychComorbidityCategoryDescription: diagResult.Values?.PsychComorbidityCategoryDescription
                }
              }
            }
          }
        }

        // use Primary grouping from the Inpatient GroupingResults to collect Edits
        if (encounter.ValidationResult?.IsInpatient) {
          if (!primaryIPGroupingViewId || primaryIPGroupingViewId === igrpResult.ViewId) {
            // collect validation edits
            const igrpEdits = igrpResult && igrpResult.Edits;
            findCodeEditsInEditGroup(result, igrpEdits, codeViewId);
          }
        }
      }

      // Outpatient GroupingResults handling: both for the IP and OP encounters
      if (correspondingGroupingSource && !correspondingGroupingSource.IsInpatient) {
        const ogrpResult = GroupingResults[igrp];

        // use selected grouping from the Outpatient GroupingResults to collect procGroupingResults
        if (!opGroupingType || opGroupingType === ogrpResult.ViewId) {
          // find corresponding procGroupingResults if possible
          const ProcGroupingResults = ogrpResult && ogrpResult.ProcGroupingResults;
          if (ProcGroupingResults) {
            for (let pind = 0, plen = ProcGroupingResults.length; pind < plen; pind++) {
              const procResult = ProcGroupingResults[pind];

              if (procResult.ViewId === codeViewId) {
                copyOutpatientGrouperFields(procGroupingResult, correspondingGroupingSource.GrouperType || '', procResult);
                break;
              }
            }
          }
        }

        // use Primary grouping from the Outpatient GroupingResults to collect Edits
        if (!encounter.ValidationResult?.IsInpatient) {
          if (!primaryOPGroupingViewId || primaryOPGroupingViewId === ogrpResult.ViewId) {
            // collect validation edits
            const ogrpEdits = ogrpResult && ogrpResult.Edits;
            findCodeEditsInEditGroup(result, ogrpEdits, codeViewId);
          }
        }
      }
    }
  }

  const len = result.length;

  // translate all dependent parameters
  const validations: CodeValidation[] = [];
  for (let ind = 0; ind < len; ind++) {
    const edit = result[ind];
    if (edit.ViewId && edit.Description) {
      edit.EncounterViewId = encounter.viewId;
      const description = translateDescription(edit.Description, edit);
      const fieldNames = findFieldNames(edit, codeViewId);
      for (let find = 0, flen = fieldNames.length; find < flen; find++) {
        const fieldName = getEncounterCodeFieldSpecialMapping(fieldNames[find]);

        if (fieldName && description) {
          validations.push({
            Description: description,
            Field: fieldName,
            Level: edit.Level
          });
        }
      }
    }
  }

  const mneLen = mneResult.length;
  for (let ind = 0; ind < mneLen; ind++) {
    const mneEdit = mneResult[ind];
    // eslint-disable-next-line no-underscore-dangle
    const setNote = window.TC._util.setMneNote;
    if (mneEdit.ViewId && mneEdit.Code && window.TC) {
      // eslint-disable-next-line no-underscore-dangle
      let description = 'Error resolving MNE edit';
      if (setNote) {
        setNote(mneEdit, true);
        description = mneEdit.intro || 'Error resolving MNE edit';
      }

      validations.push({
        Description: description,
        Field: "Code",
        Level: "Warning"
      });
    }
  }

  return {
    grouperFlags,
    procGroupingResult,
    validations,
    diagGroupingResult
  };
};

// copy group related fields
const copyOutpatientGrouperFields = (result: ProcGroupingResultValues, grouperType: string, procResult: ProcGroupingResult) => {
  const procGroupingResult = result;
  switch (grouperType) {
    case 'OCEAPC':
      procGroupingResult.APC = readNumberField(procResult.Values.APC); // int
      procGroupingResult.APCPayment = convertToNumber(procResult.Values.Payment)  // decimal
      procGroupingResult.APCDescription = procResult.Values.APCDescription || '';
      procGroupingResult.APCWeight = readNumberField(procResult.Values.Weight); // decimal
      procGroupingResult.APCPercentage = readNumberField(procResult.Values.Percentage); // double
      procGroupingResult.APCMedicareAmount = convertToNumber(procResult.Values.MedicareAmount);  // decimal
      procGroupingResult.APCAdjustedCoin = convertToNumber(procResult.Values.AdjustedCoin);  // decimal
      procGroupingResult.APCStatusIndicator = procResult.Values.StatusIndicator || '';
      procGroupingResult.APCCompAdjFlag = procResult.Values.CompAdjFlag || '';
      procGroupingResult.APCOutlierAmt = convertToNumber(procResult.Values.OutlierAmt);  // decimal
      break;

    case 'EAPG':
      procGroupingResult.EAPG = procResult.Values.FinalEAPG || '';
      procGroupingResult.EAPGCategory = procResult.Values.FinalEAPGCategory || '';
      procGroupingResult.EAPGType = procResult.Values.FinalEAPGType || '';
      procGroupingResult.EAPGPayment = convertToNumber(procResult.Values.Payment);  // decimal
      procGroupingResult.EAPGDescription = procResult.Values.FinalEAPGDescription || '';
      procGroupingResult.EAPGWeight = procResult.Values.Weight;  // decimal
      procGroupingResult.EAPGPercentage = readNumberField(procResult.Values.Percentage); // double
      break;

    case 'ASC':
      procGroupingResult.ASCPayment = convertToNumber(procResult.Values.Payment);  // decimal
      procGroupingResult.ASCWeight = readNumberField(procResult.Values.Weight); // decimal
      procGroupingResult.ASCPercentage = readNumberField(procResult.Values.Percentage); // double
      procGroupingResult.ASCMedicareAmount = convertToNumber(procResult.Values.MedicareAmount); // decimal
      procGroupingResult.ASCAdjustedCoin = convertToNumber(procResult.Values.AdjustedCoin);  // decimal
      procGroupingResult.ASCPaymentIndicator = procResult.Values.PaymentIndicator || '';
      break;

    default:
  }
}

// clear calculated code fields
const clearAllCodeInformation = (_code: BaseCodeRow) => {
  const code = _code;
  // clear description
  code.ServiceCodeDescription = undefined;
  // clear HCC
  code.hccs = undefined;
  code.hccs24 = undefined;
  code.hccs28 = undefined;
  // clear GF
  code.grouperFlag = undefined;
  code.grouperFlagAffectsDRG = undefined;
  code.grouperFlagHFlag = undefined;
  // clear procedureData
  code.procedureData = undefined;
  // clear exempt POA flag
  code.exemptPoa  = undefined;
};

// collect hccs with versions
const collectHCCs = (code: BaseCodeRow, codeInfo: ServiceResultCodeInfo) => {
  const { HCCs } = codeInfo;
  if (!HCCs || HCCs.length <= 0) {
    code.hccs = undefined;
    code.hccs24 = undefined;
    code.hccs28 = undefined;

    return;
  }

  code.hccs = HCCs;
  code.hccs24 = [];
  code.hccs28 = [];
  HCCs.forEach((hcc) => {
    switch (hcc?.Version) {
      case "24":
        code.hccs24?.push(hcc);
        break;
      case "28":
        code.hccs28?.push(hcc);
        break;
      default:
    }
  })
}

// collect all information for the code: validations, hcc, description, flags
const findAllCodeInformation = (encounter: EncounterEntity, _code: BaseCodeRow, codeId: string, gridCodeType: GridCodeType, rowIndex: number) => {
  const code = _code;
  clearAllCodeInformation(code);

  const codeGroupingInfo: CodeGroupingInfo =
    findCodeInformation(encounter, encounter.ipGroupingType, encounter.opGroupingType, codeId);
  code.Validations = codeGroupingInfo.validations;
  // filter flags and keep only grid specific ones. Fill code.grouperFlag, code.grouperFlagAffectsDRG, code.grouperFlagHFlag
  prepareCodeFlags(code, codeGroupingInfo.grouperFlags, gridCodeType, rowIndex);

  code.psychComorbidityAdjustmentFactor = codeGroupingInfo.diagGroupingResult ? codeGroupingInfo.diagGroupingResult.PsychComorbidityAdjustmentFactor : '';
  code.psychComorbidityCategoryDescription = codeGroupingInfo.diagGroupingResult? codeGroupingInfo.diagGroupingResult.PsychComorbidityCategoryDescription : '';

  const codeInfo = findCodeResultInfo(encounter.ValidationResult, codeId);
  if (codeInfo) {
    // use description
    code.ServiceCodeDescription = codeInfo.CodeDescription;
    // use code type from the services side
    code.type = mapServiceCodeType(codeInfo.CodeType || '');
    // use HCC
    collectHCCs(code, codeInfo);

    // TODO: recognize between no exemptPOA, blank exemptPOA and '1' exemptPOA in the future
    code.exemptPoa = codeInfo.ExemptPOA ? null : undefined;
  }

  // map proc grouping fields
  const { procGroupingResult } = codeGroupingInfo;
  if (procGroupingResult) {
    code.procedureData = procGroupingResult;
  }

  // set loaded flag to indicate that code is syncronized with services
  code.loaded = true;
};

// collect information for all codes in the group: validations, hcc, description, flags
const findCodesInformationForGroup = (encounter: EncounterEntity, codeGroup: CodeGroup, gridCodeType: GridCodeType) => {
  for (let ind = 0, len = codeGroup.codes.length; ind < len; ind++) {
    const code = codeGroup.codes[ind];
    findAllCodeInformation(encounter, code, code.id, gridCodeType, ind);
  }
};

// collect information for all codes: validations, hcc, description, flags
export const findAllCodesInformation = (encounter: EncounterEntity) => {
  findAllCodeInformation(encounter, encounter.admitDiagnosisCode, encounter.admitDiagnosisCode.serviceId || '', GridCodeType.ADMIT_DX, 0);

  findCodesInformationForGroup(encounter, encounter.diagnoses, GridCodeType.DIAGNOSES);
  findCodesInformationForGroup(encounter, encounter.inProcedures, GridCodeType.INPROCEDURES);
  findCodesInformationForGroup(encounter, encounter.outProcedures, GridCodeType.OUTPROCEDURES);
  findCodesInformationForGroup(encounter, encounter.visitReasons, GridCodeType.VISITREASONS);
};

// collect all information for the condition code: validations
const findAllConditionCodeInformation = (encounter: EncounterEntity, _code: ValuesRow, codeId: string) => {
  const code = _code;

  const codeGroupingInfo: CodeGroupingInfo =
    findCodeInformation(encounter, encounter.ipGroupingType, encounter.opGroupingType, codeId);
  code.Validations = codeGroupingInfo.validations;
};

// collect information for all codes in the group: validations, hcc, description, flags
const findConditionCodesInformationForGroup = (encounter: EncounterEntity, codeGroup: ValuesRow[]) => {
  for (let ind = 0, len = codeGroup.length; ind < len; ind++) {
    const code = codeGroup[ind];
    findAllConditionCodeInformation(encounter, code, code.id);
  }
};

// collect information for all condition codes: validations
export const findAllConditionCodesInformation = (encounter: EncounterEntity) => {
  findConditionCodesInformationForGroup(encounter, encounter.conditionCodes);
  findConditionCodesInformationForGroup(encounter, encounter.valueCodes);
};


// Apply encounter result to the encounter entity
export const applyEncounterResult = (encounter: EncounterEntity, _result: EncounterProcessResult, checkForGroupingsReset = false) => {
  // no result (204 response) -> do not update current encounter and try process again later
  if (!_result || !_result.processResult || (_result.type === "GroupingResult" && !_result.ViewId)) {
    return encounter;
  }

  const result = _result.processResult;
  const { type } = _result;
  const concurrencyVersion = result.ConcurrencyVersion;
  // TODO: can we avoid full cloning?
  const newEncounter: EncounterEntity = cloneDeep(encounter);
  if (concurrencyVersion) {
    newEncounter.concurrencyVersion = concurrencyVersion;
  }

  delete result.ConcurrencyVersion;

  // I need to apply changes only to the single service result part
  let previousResult: ServiceEncounterValidationResult | ServiceEncounterProcessMNEResult | ServiceResultGroupingResultResult | null = null;
  let previousResultIndex = -1;
  if (type === "ValidationResult") {
    previousResult = newEncounter.ValidationResult;
  } else if (type === "ProcessMNEResult") {
    previousResult = newEncounter.ProcessMNEResult;
  } else {
    previousResultIndex = newEncounter.GroupingResults ?
      newEncounter.GroupingResults.findIndex(res => res.ViewId === _result.ViewId) : -1;

    previousResult = previousResultIndex === -1 ? null : newEncounter.GroupingResults[previousResultIndex];
  }

  const applyResult = jsonpatch.apply(previousResult, result && result.length ? result : []);

  if (applyResult && applyResult.doc) {
    if (type === "ValidationResult") {
      newEncounter.ValidationResult = applyResult.doc;
    } else if (type === "ProcessMNEResult") {
      newEncounter.ProcessMNEResult = applyResult.doc;;
    } else {
      // replace previous item
      if (previousResultIndex !== -1) {
        newEncounter.GroupingResults[previousResultIndex] = applyResult.doc;
      }

      // add new item
      if (previousResultIndex === -1) {
        if (!newEncounter.GroupingResults) {
          newEncounter.GroupingResults = [];
        }
        newEncounter.GroupingResults.push(applyResult.doc);
      }
    }

    // it is necessary to reset current groupings if encounter need in processing. It can happen only after ValudationResult applying
    const needProcessingGroupings = type === "ValidationResult" ? encounterGroupingsNeedProcess(newEncounter) : false;
    const newNeedProcessing = checkForGroupingsReset && needProcessingGroupings;

    // it is possible that now we have not last selected grouping and must use first available
    newEncounter.ipGroupingType = findFirstGroupingSource(true, newEncounter, newNeedProcessing ? undefined : newEncounter.ipGroupingType);
    newEncounter.opGroupingType = findFirstGroupingSource(false, newEncounter, newNeedProcessing ? undefined : newEncounter.opGroupingType);

    // I need to update Validations for each code
    findAllCodesInformation(newEncounter);
    findAllConditionCodesInformation(newEncounter);

    return newEncounter;
  }

  console.log("throw");
  throw new Error('Cannot apply result to the Encounter');
};

// find grouping source by ViewId
export const findGroupingSourceByViewId = (encounter: EncounterEntity, ViewId?: string | null) => {
  return encounter?.ValidationResult?.GroupingSources?.find(group => group.ViewId === ViewId);
}

// find grouping result by ViewId
export const findGroupingResultByViewId = (encounter: EncounterEntity, ViewId?: string | null) => {
  return encounter?.GroupingResults?.find(group => group.ViewId === ViewId);
}

// find grouping. Use groupId first, then Primary, then return first available group. Analyze only IP or OP groupings
export const findGroupingSourceInValidationResult = (isIPGrouping: boolean, ValidationResult?: ServiceEncounterValidationResult | null, groupId?: string) => {
  if (!ValidationResult?.GroupingSources) {
    return null;
  }

  const groupings = ValidationResult.GroupingSources.filter(grouping => grouping.IsInpatient === isIPGrouping);

  if (!groupings) {
    return null;
  }

  // find group by selected value
  let res: ServiceResultPatientGroupingResult | null | undefined = null;
  if (groupId) {
    res = groupings.find((group) => group.ViewId === groupId);
  }

  if (!res) {
    res = groupings.find((group) => group.Primary);
  }

  if (!res && groupings.length > 0) {
    const firstIndex = 0;
    res = groupings[firstIndex];
  }

  return res;
};

// find grouping with edits. Use groupId first, then Primary, then return first available group. Analyze only IP or OP groupings
export const findGroupingSource = (isIPGrouping: boolean, inProgress: EncounterEntity, groupId?: string) => {
  return findGroupingSourceInValidationResult(isIPGrouping, inProgress.ValidationResult, groupId);
};

// find grouping with edits. Use groupId first, then Primary, then return first available group. Analyze only IP or OP groupings
export const findGroupingResult = (isIPGrouping: boolean, inProgress: EncounterEntity, groupId?: string) => {
  const groupingSource = findGroupingSource(isIPGrouping, inProgress, groupId);
  if (!groupingSource) {
    return null;
  }

  return findGroupingResultByViewId(inProgress, groupingSource.ViewId);
};

// find id of the first available grouping
// if we have groupId and such grouping - use it. Otherwise find first available
export const findFirstGroupingSourceInValidationResult = (isIPGrouping: boolean, ValidationResult?: ServiceEncounterValidationResult | null, groupId?: string) => {
  const group = findGroupingSourceInValidationResult(isIPGrouping, ValidationResult, groupId);

  return group && group.ViewId ? group.ViewId : '';
};

// find id of the first available grouping
// if we have groupId and such grouping - use it. Otherwise find first available
export const findFirstGroupingSource = (isIPGrouping: boolean, inProgress: EncounterEntity, groupId?: string) => {
  const group = findGroupingSource(isIPGrouping, inProgress, groupId);

  return group && group.ViewId ? group.ViewId : '';
};

// find all possible groupings for the Encounter
export const findAllGroupingSources = (isIPGrouping: boolean, inProgress: EncounterEntity) => {
  if (!inProgress.ValidationResult) {
    return [];
  }

  const groupings = inProgress.ValidationResult.GroupingSources;

  if (!groupings) {
    return [];
  }

  const groups: IdDescriptionBase[] = [];
  for (let ind = 0, len = groupings.length; ind < len; ind++) {
    const group = groupings[ind];
    if (group.ViewId && group.IsInpatient === isIPGrouping) {
      const correspondingResult = findGroupingResultByViewId(inProgress, group.ViewId);
      // use description from the result if possible and description from the groupingsource if result was not calculated
      const description = correspondingResult?.Description || group.Description || '';

      groups.push({
        id: group.ViewId,
        title: description,
        primary: group.Primary,
      });
    }
  }

  const sortedGroups = groups.sort((a,b) => {
    const first = a.primary ? '' : a.title;
    const second = b.primary ? '' : b.title;
    return first.localeCompare(second);
  });

  return sortedGroups;
};

// use first available facility for the empty value
const findFacility = (id: string, facilities: IdDescriptionBase[]): string => {
  if (id) {
    return id;
  }

  if (facilities.length > 0) {
    return facilities[0].ViewId || '';
  }

  return '';
};

export const mapChoiceLists = (choiceListData: any, choiceLists: ChoiceListsState): ChoiceListsState => {
  const lists: ChoiceListsState = {
    facilities: choiceLists.facilities,
    currentEncounterFacilities: choiceLists.currentEncounterFacilities,
    currentEncounterFacility: choiceLists.currentEncounterFacility,
    cachedLists: choiceLists.cachedLists,
    encounterTypes: choiceListData.EncounterTypes.map((encounter) => {
      return {
        id: encounter.Id,
        title: encounter.Description,
        ViewId: encounter.ViewId,
      };
    }),
    patientStatuses: choiceListData.PatientStatuses.map((status) => {
      return {
        id: status.Id,
        title: status.Description,
        ViewId: status.ViewId,
      };
    }),
    services: choiceListData.Services.map((service) => {
      return {
        id: service.Id,
        title: service.Description,
        ViewId: service.ViewId,
      };
    }),
    financialClasses: choiceListData.FinancialClasses.map((financialClass) => {
      return {
        id: financialClass.Id,
        title: financialClass.Description,
        ViewId: financialClass.ViewId,
      };
    }),
    recordStatuses: choiceListData.RecordStatuses.map((status) => {
      return {
        id: status.Id,
        title: status.Description,
        ViewId: status.ViewId,
      };
    }),
    sexes: choiceListData.Sexes.map((sex) => {
      return {
        id: sex.Id,
        title: sex.Description,
        ViewId: sex.ViewId,
      };
    }),
    ofa: choiceListData.SourceOfAdmissions.map((source) => {
      return {
        id: source.Id,
        title: source.Description,
        ViewId: source.ViewId,
      };
    }),
    providers: choiceListData.Providers.map((provider) => {
      return {
        id: provider.Id,
        title: provider.Description,
        ViewId: provider.ViewId,
      };
    }),
    payerFlags: choiceListData.PayerFlags.map((flag) => {
      return {
        id: flag.Id,
        title: flag.Description,
        ViewId: flag.ViewId,
      };
    }),
    newEncounterEncounterTypes: []
  };

  return lists;
};

const mapValueConditionCodeToServiceEntity = (code: ValuesRow, isValueCode: boolean): ServiceViewCode => {
  const serviceCode = {
    Amount: code.Amount || '',
    Code: (isValueCode ? code.ValueCode : code.ConditionCode) || '',
    ViewId: code.id,

    CodeDescription: null,
    CustomCodeDescription: null,
    CodeType: null,
    Episode: null,
    Modifiers: null,
    Charges: null,
    NonCoveredCharges: null,
    UnitsOfService: null,
    ProcedureDate: null,
    DiagnosesFlags: null,
    PresentOnAdmission: null,
    RevenueCodes: null
  };

  return serviceCode;
};

const mapValueConditionCodesGroup = (codes: ValuesRow[], isValueCode: boolean): ServiceViewCode[] => {
  const result: ServiceViewCode[] = [];

  for (let ind = 0, len = codes.length; ind < len; ind++) {
    const code = codes[ind];
    // skip empty records
    if (!code.empty && (isValueCode && code.ValueCode || !isValueCode && code.ConditionCode)) {
      result.push(mapValueConditionCodeToServiceEntity(code, isValueCode));
    }
  }

  return result;
};

const mapServiceValueConditionCodesToCodes = (codes: ServiceViewCode[], isValueCode: boolean): ValuesRow[] => {
  const result: ValuesRow[] = [];

  for (let ind = 0, len = codes.length; ind < len; ind++) {
    result.push(mapServiceValueConditionCodeToCode(codes[ind], isValueCode));
  }

  return result;
};

const mapServiceValueConditionCodeToCode = (serviceCode: ServiceViewCode, isValueCode: boolean): ValuesRow => {
  const code: ValuesRow = isValueCode ? {
    ValueCode: serviceCode.Code ? serviceCode.Code : '',
    Amount: serviceCode.Amount ? serviceCode.Amount : '',
    gridType: ValuesGridType.VALUE_CODES,
    id: serviceCode.ViewId ? serviceCode.ViewId : ''
  } : {
    ConditionCode: serviceCode.Code ? serviceCode.Code : '',
    gridType: ValuesGridType.CONDITION_CODES,
    id: serviceCode.ViewId ? serviceCode.ViewId : ''
  };

  return code;
};

export const compareModifiersArrays = (oldModifiers: string[], newModifiers: string[]): boolean => {
  if (!oldModifiers && !newModifiers) {
    return true;
  }

  // in the old services format we can have null. It is equal to the []
  if ((!oldModifiers && newModifiers.length === 0) || (!newModifiers && oldModifiers.length === 0)) {
    return true;
  }

  if (!oldModifiers || !newModifiers) {
    return false;
  }

  if (oldModifiers.length !== newModifiers.length) {
    return false;
  }

  for (let ind = 0, len = oldModifiers.length; ind < len; ind++) {
    if (oldModifiers[ind] !== newModifiers[ind]) {
      return false;
    }
  }

  return true;
}

const isEmptyModifiersValue = (modifiers) => {
  if (modifiers === null || modifiers === undefined) {
    return true;
  }

  if (!modifiers || !modifiers.length) {
    return true;
  }

  return false;
}

const addCompareModifiersOp = (result: JSONPATCH[], basePath: string, key: string, serviceKey: string,
  oldCode: ServiceViewCode,
  newCode: ServiceViewCode) => {

  const oldValue = oldCode[key];
  const newValue = newCode[key];

  if (isEmptyModifiersValue(oldValue)) {
    if (isEmptyModifiersValue(newValue)) {
      return;
    }

    result.push({
      op: 'add',
      path: basePath + serviceKey,
      value: newValue
    });

    return;
  }

  if (isEmptyModifiersValue(newValue)) {
    result.push({
      op: 'remove',
      path: basePath + serviceKey
    });

    return;
  }

  if (!compareModifiersArrays(oldValue, newValue)) {
    result.push({
      op: 'replace',
      path: basePath + serviceKey,
      value: newValue
    });
  }
};

export const IPGroupingIsOutdated = (encounter: EncounterEntity, ipGroupingType?: string) => {
  // new processing: return false
  return false;
}

export const OPGroupingIsOutdated = (encounter: EncounterEntity, opGroupingType?: string) => {
  // new processing: return false
  return false;
}

// result:
//  key - previous key for processing,
//  "" - need processing without key,
//  null - no need in processing
export const encounterGroupingNeedProcess = (encounter: EncounterEntity, groupingId?: string): string | null => {
  if (!groupingId) {
    return null;
  }

  const newGroupingResultsKey = encounter.ValidationResult?.GroupingResultsKey;
  if (!newGroupingResultsKey) {
    return null;
  }

  const correspondingProcessResult = encounter.GroupingResults?.find(group => group.ViewId === groupingId);
  if (!correspondingProcessResult) {
    return "";
  }

  if (correspondingProcessResult.Key !== newGroupingResultsKey) {
    return correspondingProcessResult.Key || "";
  }

  return null;
}

export interface NeedInProcessingGrouping {
  groupingViewId: string;
  groupingKey: string;
}

// WEB-4920 - we should process each outdated and not processed groupingSource before save
// result - array of groupingKey and key
export const encounterGroupingsThatNeedProcess = (encounter: EncounterEntity): NeedInProcessingGrouping[] => {
  const newGroupingResultsKey = encounter.ValidationResult?.GroupingResultsKey;
  if (!newGroupingResultsKey || !encounter.ValidationResult?.GroupingSources) {
    return [];
  }

  const results: NeedInProcessingGrouping[] = [];
  for (let ind = 0, len = encounter.ValidationResult.GroupingSources.length; ind < len; ind += 1) {
    const grpSource = encounter.ValidationResult.GroupingSources[ind];
    if (grpSource?.ViewId) {
      const correspondingProcessResult = encounter.GroupingResults?.find(group => group.ViewId === grpSource.ViewId);
      // not processed grouping
      if (!correspondingProcessResult) {
        results.push({
          groupingViewId: grpSource.ViewId,
          groupingKey: "",
        });
      }

      // outdated grouping
      if (correspondingProcessResult && correspondingProcessResult.Key !== newGroupingResultsKey) {
        results.push({
          groupingViewId: grpSource.ViewId,
          groupingKey: correspondingProcessResult.Key || "",
        });
      }
    }
  }

  return results;
}

// result:
//  key - previous key for processing,
//  "" - need processing without key,
//  null - no need in processing
export const encounterNeedMNEProcess = (encounter: EncounterEntity): string | null => {
  const newMNEKey = encounter.ValidationResult?.MNEResultKey;
  if (!newMNEKey) {
    return null;
  }

  const correspondingProcessResult = encounter.ProcessMNEResult;
  if (!correspondingProcessResult) {
    return "";
  }

  if (correspondingProcessResult.Key !== newMNEKey) {
    return correspondingProcessResult.Key || "";
  }

  return null;
}

const encounterHasNeedProcessFlags = (encounter: EncounterState): boolean => {
  // needHandling: activate empty processing sequence to perform some operation after it (like autoresequence)
  if (encounter.needInitialProcess || encounter.needHandling) {
    return true;
  }

  return false;
}

export const encounterGroupingsNeedProcess = (encounterEntity: EncounterEntity): boolean => {
  if (encounterGroupingNeedProcess(encounterEntity, encounterEntity.ipGroupingType) !== null) {
    return true;
  }

  if (encounterGroupingNeedProcess(encounterEntity, encounterEntity.opGroupingType) !== null) {
    return true;
  }

  if (encounterNeedMNEProcess(encounterEntity) !== null) {
    return true;
  }

  // skip outdated groupings processing if it was not successfull last time to avoid infinite processing - removed in GUI-3081 as not used with new processing

  return false;
}

export const encounterNeedProcess = (encounter: EncounterState, checkAllGroupingsBeforeSave = false): boolean => {
  if (encounterHasNeedProcessFlags(encounter)) {
    return true;
  }

  if (checkAllGroupingsBeforeSave) {
    const results = encounterGroupingsThatNeedProcess(encounter.inProgress);
    if (results.length > 0) {
      return true;
    }
  }

  return encounterGroupingsNeedProcess(encounter.inProgress);
}
