import './DatePicker.less';

import { DateInputProps } from '@progress/kendo-react-dateinputs';
import * as React from 'react';
import { toServiceDate } from '../../utils/date';

export class CustomDateInput extends React.PureComponent<DateInputProps> {
  render() {
    return (
      <input
        className="custom_date_input"
        type="text"
        value={this.props.value ? toServiceDate(this.props.value) : ''}
        readOnly
      />
    );
  }
}
