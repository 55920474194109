import './Menu.less';

import * as React from 'react';
import { Router, match } from 'react-router-dom';
import * as History from 'history';
import { history } from '../../../../reduxStore';
import { WindowId } from '../../../../constants/tee';
import TruLink from '../../../TruLink/TruLink';
import { IconType } from '../../../Icon/Icon';
import { UserRole } from '../../../../models';
import { Button, ButtonMode } from '../../..';

export interface ToolsMenuProps {
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  onBlur: () => void;
  onMenuOpen: (el: HTMLUListElement | null) => void;
  isSystemAdmin: boolean;
  highestRole: UserRole;
  isErrorMode?: boolean;
}

export default class ToolsMenu extends React.PureComponent<ToolsMenuProps, {}> {
  ref: HTMLUListElement | null;
  timeout: number;

  constructor(props: ToolsMenuProps) {
    super(props);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    this.props.onMenuOpen(this.ref);
  }

  render() {
    const isErrorMode = this.props.isErrorMode || false;
    const canUseCodebooks = this.props.isSystemAdmin || this.props.highestRole >= UserRole.NOROLE;
    const canUseReferences = this.props.isSystemAdmin || this.props.highestRole >= UserRole.NOROLE;

    const codebooksButton = canUseCodebooks
      ? (
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to="/codebooks"
          singleInstanceId={WindowId.CodeBooks}
          isErrorMode={isErrorMode}
        >
          <Button
            mode={ButtonMode.Transparent}
            iconType={IconType.BookCodebooks}
            label="CodeBooks" />
        </TruLink>
      )
      : (
        <Button
          mode={ButtonMode.Transparent}
          iconType={IconType.BookCodebooks}
          disabled
          label="CodeBooks" />
      );

    const referencesButton = canUseReferences
      ? (
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to="/references"
          singleInstanceId={WindowId.References}
          isErrorMode={isErrorMode}
        >
          <Button
            mode={ButtonMode.Transparent}
            iconType={IconType.BookReferences}
            label="References" />
        </TruLink>
      )
      : (
        <Button
          mode={ButtonMode.Transparent}
          iconType={IconType.BookReferences}
          disabled
          label="References" />
      );

    return (
      <Router history={history}>
        <ul className="tc-appbar-menu left" ref={(e) => { this.ref = e; }} tabIndex={0} onFocus={this.onFocus} onBlur={this.onBlur}>
          <li>{codebooksButton}</li>
          <li>{referencesButton}</li>
        </ul>
      </Router>
    );
  }

  private onFocus() {
    clearTimeout(this.timeout);
  }

  private onBlur() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.props.onBlur);
  }
}
