import './ErrorWrap.less';

import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { MultilineTooltipTemplate } from '../Tooltip/MultilineTooltipTemplate';
import { IconType, Icon } from '../Icon/Icon';
import { hasValidationError, isCriticalValidationError, ValidationErrors } from '../../models/encounterErrors';
import { Tooltip } from '..';

export interface ErrorWrapProps {
  id?: string;
  error?: ValidationErrors;
  className?: string;
  withLabel?: boolean;
}

export class ErrorWrap extends React.PureComponent<ErrorWrapProps> {
  el: HTMLInputElement;
  targetElement: HTMLElement | null = null;

  setRef = (ref) => {
    this.targetElement = ref;
  }

  // all client side errors are simple strings (do we want special flag?)
  collectClientSideCriticalErrors = (error?: ValidationErrors) => {
    if (!error) {
      return undefined;
    }

    if (!Array.isArray(error)) {
      return typeof error === "string" ? error : undefined;
    }

    const errors = error.filter(item => typeof item === "string");

    return errors.length > 0 ? errors : undefined;
  }

  render() {
    const id = this.props.id || `id-${uuid()}`;
    const filter = (el: HTMLElement) => { return el.className.split(' ').indexOf('icon') !== -1; }
    const { error } = this.props;
    const hasError = typeof error !== 'undefined' && hasValidationError(error);
    let wrapperClass = hasError ? 'tc-error-wrap form-group inform-outer-wrap-new has-error' : 'tc-error-wrap form-group inform-outer-wrap-new';
    if (this.props.className) { wrapperClass += ` ${this.props.className}`; }
    if (this.props.withLabel) { wrapperClass += ' with-label' }

    const clientSideCriticalErrors = this.collectClientSideCriticalErrors(this.props.error);
    const hasClientSideErrors = !!clientSideCriticalErrors;

    return (
      <div id={id} className={wrapperClass}>
        {/* client side errors tooltip. always visible if we have client side errors. show only client side errors */}
        <Tooltip
          filter={filter}
          anchorElement="target"
          openDelay={hasClientSideErrors ? 5: 500}
          showCallout={false}
          position="auto"
          content={() => <MultilineTooltipTemplate content={hasClientSideErrors ? clientSideCriticalErrors : error} />}
          targetElement={hasClientSideErrors ? this.targetElement : undefined}
        >
          {/* usual tooltip. visible only if we have not client side errors */}
          <div className="tc-error-wrap-inner">
            <Icon type={isCriticalValidationError(error) ? IconType.Error : IconType.Warning} forceTooltip setRef={this.setRef} />
            {this.props.children}
          </div>
        </Tooltip>
      </div>
    );
  }
}
