import * as moment from 'moment';

export function toServiceDate(date: string | Date | null | undefined, strict = true) {
  if (typeof date === 'undefined' || date === null) { return undefined; }

  // avoid warnings because non strict format. TODO: simplify?
  if (strict && typeof date === 'string') {
    const formatted = reformatDateString(date, true);

    if (!formatted) {
      return undefined;
    }

    const result = moment(formatted, 'YYYY-MM-DD', true);
    const isValid = result.isValid();

    if (!isValid) {
      return undefined;
    }

    return result.format('Y-MM-DD');
  }

  return moment(date).format('Y-MM-DD');
}

// FIXME: valid-typeof error
export function toDate(date: string | Date | null | undefined) {
  if (typeof date === 'undefined' || typeof date === null || !date) { return null; }

  return moment(date).toDate();
}

// M/dd/yy or M/dd/yyyy or MMddyy or MMddyyyy formats => M/dd/yy
export function reformatDateString(value: string, toStandard = false) {
  if (!value) {
    return '';
  }

  const usualString = new RegExp('^[0-9]{4,4}/[0-9]{2,2}/[0-9]{2,2}$');
  const year2Numbers = new RegExp('^[0-9]{1,2}/[0-9]{1,2}/[0-9][0-9]$');
  const year4Numbers = new RegExp('^[0-9]{1,2}/[0-9]{1,2}/[0-9]{4,4}$');
  const monthSeparatorDay = new RegExp('^[0-9]{1,2}(\.|\\|\/)[0-9]{1,2}$');
  const fourNumbers = new RegExp('^[0-9]{4}$');
  const monthDotDayDotYear2Numbers = new RegExp('^[0-9]{1,2}[\\\\.][0-9]{1,2}[\\\\.][0-9][0-9]$');
  const monthDotDayDotYear4Numbers = new RegExp('^[0-9]{1,2}[\\\\.][0-9]{1,2}[\\\\.][0-9]{4,4}$');
  const simpleString = new RegExp('^[0-9]{6}$');
  const simpleStringYear4Numbers = new RegExp('^[0-9]{8}$');
  const numbers = new RegExp('^[0-9]{1,4}$');
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
    'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC',
    'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
    'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
  ];

  let month = '0';
  let day = '0';
  let year = '0';
  let correct = false;

  if (fourNumbers.test(value)) {
    correct = true;
    month = value.substr(0, 2);
    day = value.substr(2, 2);
    year = `${moment().toDate().getFullYear()}`;
  }

  if (usualString.test(value)) {
    correct = true;
    month = value.substr(5, 2);
    day = value.substr(8, 2);
    year = value.substr(0, 4);
  }

  if (simpleString.test(value)) {
    correct = true;
    month = value.substr(0, 2);
    day = value.substr(2, 2);
    year = value.substr(4, 2);
  }

  if (simpleStringYear4Numbers.test(value)) {
    correct = true;
    month = value.substr(0, 2);
    day = value.substr(2, 2);
    year = value.substr(4, 4);
  }

  if (year2Numbers.test(value) || year4Numbers.test(value)) {
    correct = true;
    const indexDay = value.indexOf('/');
    const indexYear = value.indexOf('/', indexDay + 1);
    month = value.substr(0, indexDay);
    day = value.substr(indexDay + 1, indexYear - indexDay - 1);
    year = value.substr(indexYear + 1, value.length - indexYear - 1);
  }

  if (monthSeparatorDay.test(value)) {
    let indexDay = value.indexOf('.');
    if (indexDay === -1) {
      indexDay = value.indexOf('\\');
    }
    if (indexDay === -1) {
      indexDay = value.indexOf('/');
    }
    if (indexDay !== -1) {
      correct = true;
      month = value.substr(0, indexDay);
      day = value.substr(indexDay + 1, value.length - indexDay - 1);
      year = `${moment().toDate().getFullYear()}`;
    }
  }

  if (monthDotDayDotYear2Numbers.test(value) || monthDotDayDotYear4Numbers.test(value)) {
    correct = true;
    let indexDay = value.indexOf('.');
    if (indexDay === -1) {
      indexDay = value.indexOf('\\');
    }
    let indexYear = value.indexOf('.', indexDay + 1);
    if (indexYear === -1) {
      indexYear = value.indexOf('\\', indexDay + 1);
    }
    month = value.substr(0, indexDay);
    day = value.substr(indexDay + 1, indexYear - indexDay - 1);
    year = value.substr(indexYear + 1, value.length - indexYear - 1);
  }

  // try month templates like DMonthname or D Monthname Year
  if (!correct) {
    const upper = value.toUpperCase();

    for (let ind = 0; ind < 24; ind++) {
      const indexMonth = upper.indexOf(months[ind]);
      if (indexMonth > 0) {
        const dayStr = upper.substr(0, indexMonth).trim();
        if (numbers.test(dayStr) && dayStr.length <= 2) {
          day = dayStr;
          month = `${ind % 12 + 1}`;

          const yearStr = upper.substr(indexMonth + months[ind].length, upper.length).trim();
          if (yearStr === '') {
            year = `${moment().toDate().getFullYear()}`;
            correct = true;
            break;
          }

          if ((yearStr.length === 2 || yearStr.length === 4) && numbers.test(yearStr)) {
            year = yearStr;
            correct = true;
            break;
          }
        }
      }
    }
  }

  if (!correct) {
    const date = moment(value, [
      moment.ISO_8601,
      moment.RFC_2822,
      "ddd, DD MMM YYYY",
      "YYYY/MM/DDTHH:mm",
      "YYYY/MM/DDTHH:mm:ss",
      "YYYY/MM/DDTHH:mm:ssZZ",
      "MM/DD/YYYYTHH:mm",
      "MM/DD/YYYYTHH:mm:ss",
      "MM/DD/YYYYTHH:mm:ssZZ",      
    ], true);

    if (date.isValid()) {
      correct = true;
      year = `${date.year()}`;
      month = `${date.month() + 1}`;
      day = `${date.date()}`;
    }
  }

  if (year.length === 2) {
    const yearNumber = Number.parseInt(year, 10);
    const currentYear = moment().toDate().getFullYear();

    year = 2000 + yearNumber > currentYear ? `19${year}` : `20${year}`;
  }

  if (!correct || month === '0' || day === '0' || month === '00' || day === '00') {
    return '';
  }

  if (!toStandard) {
    if (month.length === 2 && month[0] === '0') {
      month = month[1];
    }
    if (day.length === 2 && day[0] === '0') {
      day = day[1];
    }
  }

  if (toStandard) {
    if (month.length === 1) {
      month = `0${month}`;
    }
    if (day.length === 1) {
      day = `0${day}`;
    }

    const result = `${year}-${month}-${day}`;
    if (moment(result, 'YYYY-MM-DD', true).isValid()) {
      return result;
    }
    return '';
  }

  const result = `${month}/${day}/${year}`;
  if (month.length === 1) {
    month = `0${month}`;
  }
  if (day.length === 1) {
    day = `0${day}`;
  }
  const checked = `${month}/${day}/${year}`;
  if (moment(checked, 'MM/DD/YYYY', true).isValid()) {
    return result;
  }

  return '';
}

export function isInvalidFormattedDate(value: string | undefined, incorrectFutureDateMessage = '', checkMaxAge = false): string {
  if (!value) {
    return 'Invalid Date';
  }

  const formatted = reformatDateString(value, true);
  if (!formatted) {
    return 'Invalid Date';
  }

  const date = moment(formatted, 'YYYY-MM-DD', true);
  if (!date.isValid()) {
    return 'Invalid Date';
  }

  // check future date
  if (incorrectFutureDateMessage) {
    if (moment().isBefore(date)) {
      return incorrectFutureDateMessage;
    }
  }

  if (checkMaxAge) {
    const differenceY = moment(moment.now()).diff(date, 'years');
  
    if (differenceY > 124) {
      return 'Age can\'t be more than 124 years';
    }
  }

  return '';
}

export function toFormattedDate(value: string | undefined) {
  if (typeof value === 'undefined' || !value) { return null; }

  const formatted = reformatDateString(value, true);

  if (!formatted) {
    return null;
  }

  const result = moment(formatted, 'YYYY-MM-DD', true);
  const isValid = result.isValid();

  if (!isValid) {
    return null;
  }

  return result.toDate();
}

// MM/DD/YYYY with skipped zeros
export function toShownDate(value?: string) {
  if (!value) {
    return '';
  }

  const result = moment(value, 'YYYY-MM-DD', true);
  if (!result.isValid()) {
    return '';
  }

  return reformatDateString(result.format('MM/DD/Y'));
}

// check if date is between 2 dates. value/from/to in the shown format
export function dateIsBetween(value?: string, from?: string, to?: string) {
  // no date
  if (!value) {
    if (!from && !to) {
      return true;
    }

    return false;
  }

  // some of the dates can be corrupted
  const valueDate = toFormattedDate(value);
  if (!valueDate) {
    return false;
  }

  if (from) {
    const dateFrom = toFormattedDate(from);
    if (!dateFrom) {
      return false;
    }

    if (moment(valueDate).isBefore(dateFrom)) {
      return false;
    }
  }

  if (to) {
    const dateTo = toFormattedDate(to);
    if (!dateTo) {
      return false;
    }

    if (moment(valueDate).isAfter(dateTo)) {
      return false;
    }
  }

  return true;
}
