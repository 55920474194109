/* eslint-disable @typescript-eslint/camelcase */
import {
  EncoderUser,
  EncoderUserEntity,
  EncoderUserListResultEntity,
  UserRole,
  FacilityRolesEntity
} from '../../models';
import { FacilityEntity } from '../../scenes/Facility/models/facilities';
import { deleteEndpoint, get, post } from '../../utils/net';

const baseURL = `${process.env.REACT_APP_API_PATH}/api/User`

const getList = (): Promise<EncoderUserListResultEntity> => {
  const url = `${baseURL}/List`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return mapEncoderUserListResultToEntity(result.body);
  });


};

const getUser = (id: string): Promise<EncoderUserEntity> => {
  const url = `${baseURL}/${id}`;
  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return mapEncoderUserResultToEntity(result.body);
  });
};

const getUserRoles = (id: string, facilities: FacilityEntity[]): Promise<FacilityRolesEntity> => {
  const url = `${baseURL}/${id}/FacilityRoles`;
  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return mapFacilityStatusToRole(result.body, facilities);
  });

};

const inviteUser = (user: EncoderUserEntity, sendEmail?: boolean): Promise<EncoderUserEntity> => {
  const url = `${baseURL}/Invite?sendEmail=${sendEmail}`;
  const extraOptions = {
    useExactPath: true
  };

  return post(url, user, extraOptions).then((result) => {
    return result.body.map(mapEncoderUserResultToEntity);
  });
};


const saveUserChanges = (user: EncoderUserEntity): Promise<EncoderUserEntity> => {
  const url = `${baseURL}/${user.userId}`;

  const headers = {
    'If-Match': user.version,
  };

  const extraOptions = {
    headers,
    useExactPath: true,
  };

  return post(url, user, extraOptions).then((result) => {
    return mapEncoderUserResultToEntity(result.body);
  });
};

const saveUserRoles = (userWithRoles: EncoderUserEntity): Promise<EncoderUserEntity> => {
  const url = `${baseURL}/${userWithRoles.userId}/FacilityRoles`;

  const headers = {
    'If-Match': userWithRoles.version,
  };

  const extraOptions = {
    headers,
    useExactPath: true,
  };

  const mappedRoles = userWithRoles.roles.map((r) => {
    return mapToServiceFacilityRole(r)
  });

  return post(url, mappedRoles, extraOptions).then((result) => {
    return result.body ? mapEncoderUserResultToEntity(result.body) : userWithRoles;
  });
};

const mapToServiceFacilityRole = (role: FacilityRolesEntity):  FacilityRolesEntity => {
  const facilityRole = {
    Facility: role.Facility,
    FacilityId: role.FacilityId,
    Role: role.Role,
    UserId: role.UserId,
    Version: role.Version,
    FacilityIsActive: role.FacilityIsActive
  };

  return facilityRole;
};

const mapRoleToService = (roleName: string): string => {
  switch (roleName) {
    case UserRole.ADMIN:
      return 'FacilityAdmin';
    case UserRole.CODER:
      return 'Coder';
    case UserRole.POWER:
      return 'PowerCoder';
    case UserRole.MANAGER:
      return 'CaseManager';
    case UserRole.NOROLE:
    default:
      return 'NoRole';
  }
};

const deleteInvitedUser = (user: EncoderUserEntity): Promise<EncoderUserEntity> => {

  const url = `${baseURL}/${user.userId}`;

  const headers = {
    'If-Match': user.version,
  };

  const extraOptions = {
    headers,
    useExactPath: true,
  };

  return deleteEndpoint(url, extraOptions).then((result) => {
    return (result);
  });
};


const mapEncoderUserListResultToEntity = (list: any): EncoderUserListResultEntity => {
  const users = list.map((user) => mapEncoderUserResultToEntity(user));

  return {
    users,
    count: list.count,
    total_count: list.total_count
  };
};

const mapEncoderUserResultToEntity = (user: EncoderUser): EncoderUserEntity=> {

  return {
    id: user.id || user.UserId,
    clientId: user.ClientId,
    customerId: user.CustomerId,
    userId: user.UserId,
    firstName: user.FirstName,
    lastName: user.LastName,
    userName: user.UserName,
    email: user.Email,
    isSystemAdmin: user.IsSystemAdmin,
    status: user.Status,
    version: user.Version,
    invitationLink: user.InvitationLink,
    roles: [],
    licensePackageId: user.LicensePackageId || '',
    mailSent: user.MailSent,
    originalOrganizationId: user.OriginalOrganizationId,
    errorDetails: user.ErrorDetails,
  };
};

const mapFacilityStatusToRole = (rawRoles: FacilityRolesEntity[], facilities: FacilityEntity[]) : FacilityRolesEntity => {
  const roles = rawRoles.map((role) => {
    return {
      ...role,
      FacilityIsActive: facilities.find((f) => f.ViewId === role.FacilityId)?.IsActive
    }
  });

  return roles;
}

/*
const mapResultRolesToEntity = (role: FacilityRoles): FacilityRolesEntity => {
  return {
    FacilityId: role.FacilityId,
    FacilityName: role.facility,
    ccn: role.ccn,
    role: role.role_name,
    roleId: role.role_id
  };
};

const mapEncoderRolesToEntity = (roles: any): [] => {
  const mappedRoles = roles.map((role) => mapEntityToResponse(role));

  return {
    roles: mappedRoles
  };
};

const mapEntityToResponse = (role: FacilityRolesEntity): FacilityRoles => {
  return {
    facility_id: role.facilityId,
    display_name: role.facilityName,
    ccn: role.ccn,
    role_name: role.role,
    role_id: role.roleId
  };
}; */

const auditLogout = () => {
  const url = `${baseURL}/AuditLogout`;

  const extraOptions = {
    useExactPath: true,
  };

  return post(url, {}, extraOptions)
    .then(() => {
      return true;
    });
}

const resetPassword = (id: string) => {
  const url = `${baseURL}/ResetPassword`;

  const extraOptions = {
    useExactPath: true,
  };

  const body = {
    id,
  }

  return post(url, body, extraOptions)
    .then(() => {
      return true;
    });
}

const resetAllPasswords = () => {
  const url = `${baseURL}/ResetPassword/All`;

  const extraOptions = {
    useExactPath: true,
  };

  return post(url, {}, extraOptions)
    .then(() => {
      return true;
    });
}

export const userAPI = {
  deleteInvitedUser,
  getList,
  getUser,
  getUserRoles,
  saveUserChanges,
  saveUserRoles,
  inviteUser,
  auditLogout,
  resetPassword,
  resetAllPasswords,
};
