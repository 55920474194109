import * as moment from 'moment';
import { EncounterState, EncounterEntity, PatientEncounterType, GridCodeType } from "../../models";
import { ChoiceListsState, IdDescriptionBase, PartialChoiceLists } from "../../models/patientEncounter";
import { cloneEncounterEntity, updateCodes, createEmptyEncounterState, createEmptyEncounter, updateValueCodes, createEmptyServiceEncounter } from "./encounterCommon";
import { EncounterProcessResult, EncounterSaveServiceResult, ServiceEncounterView } from "../../models/encounterServiceEntity";
import { applyEncounterResult, collectChanges, IPGroupingIsOutdated, OPGroupingIsOutdated, mapEncounterToServiceEntity } from "../../services/encounter/encounterMapping";
import { ValuesGridType } from '../../models/valuesGrid';
import { checkFieldForCollection } from '../../utils/ddl';
import { resequenceCodes, needResequenceEncounterCodes, isEncounterClosedError } from '../../utils/encounter';
import { BaseCodeRow } from '../../models/codeGrid';
import { ErrorDetails } from '../../models/ui';

export const handleEncounterOpen = (
  state: EncounterState,
  payload: {
    encounterEntity: EncounterEntity, partialChoiceLists: PartialChoiceLists,
    choiceLists: ChoiceListsState, needAutoResequence?: boolean, openEncounterToCodingTab?: boolean,
  },
  options: { isNewEncounter: boolean, isDirtyEncounter: boolean }
): EncounterState => {
  const { encounterEntity } = payload;
  const { facilities, encounterTypes } = payload.partialChoiceLists;
  let inProgress = cloneEncounterEntity(encounterEntity);
  inProgress.lastActiveCodeId = undefined;

  // focus admitDX code if coding tab is opened initially
  if (payload.openEncounterToCodingTab && document.hasFocus()) {
    inProgress.admitDiagnosisCode.inEdit = true;
    inProgress.admitDiagnosisCode.editField = 'code';
    inProgress.admitDiagnosisCode.initialCode = inProgress.admitDiagnosisCode.code;
    inProgress.lastActiveCodeId = inProgress.admitDiagnosisCode.id;
  }

  const newState: EncounterState = {
    dirty: options.isDirtyEncounter,
    inProgress,
    saved: cloneEncounterEntity(encounterEntity),
    savedServiceEntity: mapEncounterToServiceEntity(encounterEntity, payload.choiceLists),
    needInitialProcess: false,
    // not use it according with GUI-1666: needInitialProcess: !options.isNewEncounter
  };

  // encounter type and facility cannot be empty. correct encounter type to the existing in this facility
  if (!inProgress.type) {
    inProgress.type = encounterTypes.length > 0 ? encounterTypes[0].ViewId || '' : PatientEncounterType.IP;
  }

  // use first encounter type if current encounter type is not existing in this facility
  const existingEncounterType = checkFieldForCollection(inProgress.type, encounterTypes);
  if (!existingEncounterType) {
    inProgress.type = encounterTypes.length > 0 ? encounterTypes[0].ViewId || '' : PatientEncounterType.IP;
  }

  if (!inProgress.facility) {
    inProgress.facility = facilities.length > 0 ? facilities[0].ViewId : '';
  }

  if (!options.isNewEncounter && payload.needAutoResequence && needResequenceEncounterCodes(inProgress)) {
    inProgress = resequenceCodes(inProgress);
    newState.dirty = true;
    newState.lastChangeTime = moment.now();
    console.log('Codes are resequenced');
  }

  // add empty codes for each grid to support adding
  updateCodes(inProgress);
  updateValueCodes(inProgress.conditionCodes, ValuesGridType.CONDITION_CODES);
  updateValueCodes(inProgress.valueCodes, ValuesGridType.VALUE_CODES);

  return {
    ...newState,
    inProgress,
    codesAutoResequenced: false,
    checkIfCodesNeedResequencing: true,
  };
};

// all work is on the backend side. We only update dirty flag
export const handleCurrentEncounterSaved = (state: EncounterState, newConcurrencyVersion: string): EncounterState => {
  return {
    ...state,
    inProgress: {
      ...state.inProgress,
      concurrencyVersion: newConcurrencyVersion || state.inProgress.concurrencyVersion,
    },
    dirty: false,
  };
};

export const handleEncounterStartUpdate = (state: EncounterState): EncounterState => {
  return {
    ...state,
  };
};

// UI state saved to the backend. Syncronize states. All grids must be unlocked
export const handleEncounterUpdated = (
  state: EncounterState,
  result: EncounterSaveServiceResult,
  encounterToSave: EncounterEntity,
  serviceEncounterToSave: ServiceEncounterView,
  heartbeatMode = false,
  updateTime?: number
): EncounterState => {
  // apply changes to the current encounter not to the saved
  const inProgress = applyEncounterResult(state.inProgress, { type: "ValidationResult", processResult: result }, true);
  updateCodes(inProgress);

  return {
    ...state,
    errorAttempt: 0,
    inProgress,
    lastErrorTime: undefined,
    lastUpdateTime: updateTime || moment.now(),
    saved: cloneEncounterEntity(encounterToSave),
    savedServiceEntity: serviceEncounterToSave,
    hangingProcessings: [], // need to process all if necessary
    checkIfCodesNeedResequencing: true,
  };
};

export const handleEncounterStartProcess = (state: EncounterState): EncounterState => {
  return {
    ...state,
  };
};

// Service fields were updated - update only them
export const handleEncounterProcessed = (
  state: EncounterState,
  payload: {
    results: EncounterProcessResult[],
    needAutoResequence?: boolean,
    serviceEncounterToSave: ServiceEncounterView,
    choiceLists: ChoiceListsState,
    outdatedIPGrouping: string,
    outdatedOPGrouping: string,
    outdatedGroupings: string[],
  }
): EncounterState => {
  let { inProgress } = state;
  // changes from the last state to ignore auto resequence if we have changes. it will be executed next time
  const newChanges = payload.needAutoResequence ? collectChanges(state.savedServiceEntity, inProgress, payload.choiceLists) : []; // payload.serviceEncounterToSave
  const hasNewchanges = newChanges && newChanges.length > 0;

  for (let ind = 0, len = payload.results.length; ind < len; ind++) {
    inProgress = applyEncounterResult(inProgress, payload.results[ind]);
  }

  let codesAreResequenced = false;
  if (payload.needAutoResequence && !hasNewchanges && needResequenceEncounterCodes(inProgress) && !state.codesAutoResequenced) {
    inProgress = resequenceCodes(inProgress);
    codesAreResequenced = true;
    console.log('Codes are resequenced');
  }

  // check if any of oudatedGroupings has not be updated to not force theirs processing next time
  const hangingProcessings: string[] = [];
  if (payload.outdatedIPGrouping && IPGroupingIsOutdated(inProgress, payload.outdatedIPGrouping)) {
    hangingProcessings.push(payload.outdatedIPGrouping);
  }
  if (payload.outdatedOPGrouping && OPGroupingIsOutdated(inProgress, payload.outdatedOPGrouping)) {
    hangingProcessings.push(payload.outdatedOPGrouping);
  }

  updateCodes(inProgress);

  return {
    ...state,
    errorAttempt: 0,
    needInitialProcess: false,
    needHandling: false,
    hangingProcessings,
    inProgress,
    lastErrorTime: undefined,
    dirty: codesAreResequenced ? true : state.dirty,
    lastChangeTime: codesAreResequenced ? moment.now() : state.lastChangeTime,
    codesAutoResequenced: codesAreResequenced,
    checkIfCodesNeedResequencing: codesAreResequenced,
    // do not update saved here
  };
};

// do not try to save incorrect encounter again. Unlock all codegroups
export const handleSaveEncounterChangesError = (state: EncounterState, payload: { errors: ErrorDetails[]; errorTime: number; }): EncounterState => {
  return {
    ...state,
    closedByAnotherSession: !!(payload.errors && payload.errors.length && payload.errors[0].description && isEncounterClosedError(payload.errors[0].description)),
    errorAttempt: state.errorAttempt ? state.errorAttempt + 1 : 1,
    lastErrorTime: payload.errorTime || moment.now(),

    // block processing after error - cannot use this flags now
    inProgress: {
      ...state.inProgress,
      // NeedProcess: false,
      // NeedProcessMne: false,
    }
  };
};

export const handleSaveEncounterErrorUpdate = (state: EncounterState): EncounterState => {
  // clear error information for now
  return {
    ...state,
    errorAttempt: 0,
    lastErrorTime: undefined,
  };
};

export const handleIncorrectEncounter = (): EncounterState => {
  return createEmptyEncounterState();
};

export const handleCloseEncounter = () => {
  return {
    dirty: false,
    inProgress: createEmptyEncounter(),
    saved: createEmptyEncounter(),
    savedServiceEntity: createEmptyServiceEncounter(),
  };
};

export const handleMarkEncounterOutdated = (state: EncounterState) => {
  // clear concurrency version
  return {
    ...state,
    inProgress: {
      ...state.inProgress,
      concurrencyVersion: ''
    }
  }
}

const clearProviderValueInGridForNewFacility = (encounter: EncounterEntity, gridType: GridCodeType, values: IdDescriptionBase[]) => {
  const newCodes: BaseCodeRow[] = [];
  let changed = false;
  for (let ind = 0, len = encounter[gridType].codes.length; ind < len; ind++) {
    const code: BaseCodeRow = encounter[gridType].codes[ind];
    const { provider } = code;
    if (provider !== '' && provider !== undefined && provider !== null) {
      const existingValue = checkFieldForCollection(provider, values);
      if (existingValue) {
        newCodes.push(code);
      } else {
        newCodes.push({
          ...code,
          provider: '',
        })

        changed = true;
      }
    } else {
      newCodes.push(code);
    }
  }

  if (!changed) {
    return null;
  }

  return newCodes;
}

// update comboboxes to the new facility
// update stored providers
export const handleSingleChoiceListUpdated = (state: EncounterState, loadedLists): EncounterState => {
  // GUI-2192: comment next code: all mapping should be on the server side
  return state;

  /*
  const newEncounter = {
    ...state.inProgress,
  };

  const CHOICE_LISTS_FIELDS = {
    patientStatuses: 'patientStatus',
    services: 'service',
    financialClasses: '',   // do not clear this field
    recordStatuses: 'recordStatus',
    sexes: 'sex',
    ofa: 'ofa',
    providers: 'attendingMd',
    payerFlags: 'payerFlag',
  }

  let changed = false;

  for (let ind = 0, len = loadedLists.length; ind < len; ind++) {
    // use first encounter type if current encounter type is not existing in this facility
    if (loadedLists[ind].name === 'encounterTypes') {
      const existingEncounterType = checkFieldForCollection(newEncounter.type, loadedLists[ind].values);
      if (!existingEncounterType) {
        newEncounter.type = loadedLists[ind].values.length > 0 ? loadedLists[ind].values[0].ViewId || '' : PatientEncounterType.IP;
        changed = true;
      }
    }

    // clear choiceList field if its value is not existing in this facility
    const field = CHOICE_LISTS_FIELDS[loadedLists[ind].name];
    if (field && newEncounter[field] !== '' && newEncounter[field] !== undefined && newEncounter[field] !== null) {
      const existingValue = checkFieldForCollection(newEncounter[field], loadedLists[ind].values);
      if (!existingValue) {
        newEncounter[field] = '';
        changed = true;
      }
    }

    // clear Provider field in grids if its value is not existing in this facility
    if (loadedLists[ind].name === 'providers') {
      const newInProcedures = clearProviderValueInGridForNewFacility(newEncounter, GridCodeType.INPROCEDURES, loadedLists[ind].values);
      if (newInProcedures) {
        newEncounter.inProcedures = {
          ...newEncounter.inProcedures,
          codes: newInProcedures
        }

        changed = true;
      }

      const newOutProcedures = clearProviderValueInGridForNewFacility(newEncounter, GridCodeType.OUTPROCEDURES, loadedLists[ind].values);
      if (newOutProcedures) {
        newEncounter.outProcedures = {
          ...newEncounter.outProcedures,
          codes: newOutProcedures
        }

        changed = true;
      }
    }
  }

  return {
    ...state,
    inProgress: newEncounter,
    dirty: changed ? true : state.dirty,
    lastChangeTime: changed ? moment.now() : state.lastChangeTime,
  };
  */
};

