// import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';
import { B2CState } from '../models/b2c';
import * as actionTypes from '../constants';

const initialState: B2CState = {
  initializing: false,
  initialized: false,
  idToken: null,
  accessToken: null,
  state: 'Unauthenticated', // AuthenticationState.Unauthenticated
  tokenInfo: {},
};

export const b2cReducer = (state = initialState, action) => {
  switch (action.type) {
    /*    
    case AuthenticationActions.Initializing:
      return {
        ...state,
        initializing: true,
        initialized: false,
      };
    case AuthenticationActions.Initialized:
      return {
        ...state,
        initializing: false,
        initialized: true,
      };
    case AuthenticationActions.AcquiredIdTokenSuccess:
      return {
        ...state,
        idToken: action.payload,
      };
    case AuthenticationActions.AcquiredAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.payload,
      };
    case AuthenticationActions.AcquiredAccessTokenError:
      return {
        ...state,
        accessToken: null,
      };
    case AuthenticationActions.LoginSuccess:
      return {
        ...state,
        account: action.payload.account,
      };
    case AuthenticationActions.LoginError:
    case AuthenticationActions.AcquiredIdTokenError:
    case AuthenticationActions.LogoutSuccess:
      return { ...state, idToken: null, accessToken: null, account: null };
    case AuthenticationActions.AuthenticatedStateChanged:
      return {
        ...state,
        state: action.payload,
      };
*/
    case actionTypes.B2C_UPDATE_TOKEN_INFO:
      return {
        ...state,
        tokenInfo: { ...action.payload }
      }

    default:
      return state;
  }
};
