import './AnnouncementList.less';

import * as React from 'react';
import { SystemAnnouncement } from '../../models/systemAnnouncements';
import { Button, ButtonMode, Icon, IconType } from '..';

export interface AnnouncementListProps {
  content: SystemAnnouncement[];
  onDismissAnnouncement: (id: string) => void;
}

export class AnnouncementList extends React.PureComponent<AnnouncementListProps> {
  render() {
    return (
      <ul className="announcement-list">
        {this.props.content.map((item) => {
          return (
            <li key={item.Id} className={`type-${item.Severity}`}>
              <span className="message">{item.Message}</span>
              <Button className="action" onClick={() => { this.onClose(item.Id); }} mode={ButtonMode.Transparent} title="Dismiss"><Icon type={IconType.CancelMark} /></Button>
            </li>
          );
        })}
      </ul>
    );
  }

  private onClose = (id: string) => {
    this.props.onDismissAnnouncement(id);
  }
}
