import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import { EncounterEntity, CodeType, GridCodeType, EncounterState, ADMIT_DX_ID } from '../../models';
import { BaseCodeRow } from '../../models/codeGrid';
import { ValuesRow, ValuesGridType } from '../../models/valuesGrid';
import { mapEncounterToServiceEntity } from '../../services/encounter/encounterMapping';

export function getId() {
  return uuid().replace(/-/g, '');
}

export function getEmpty(type: CodeType, gridCodeType: GridCodeType) {
  const empty: BaseCodeRow = { empty: true, id: getId(), type, gridCodeType, Validations: [] };
  return empty;
}

export const cloneEncounterEntity = (encounter: EncounterEntity): EncounterEntity => {
  // TODO: can we avoid full cloning?
  const newEncounter = cloneDeep(encounter);

  return newEncounter;
};

export const createEmptyEncounter = (): EncounterEntity => {
  const codeDXEmpty = getEmpty(CodeType.ICD10CM_DX, GridCodeType.DIAGNOSES);
  const codePREmpty = getEmpty(CodeType.ICD10PCS_PR, GridCodeType.INPROCEDURES);
  const codeCPTHCPCSEmpty = getEmpty(CodeType.CPT4, GridCodeType.OUTPROCEDURES);
  const codeVISITREASONEmpty = getEmpty(CodeType.ICD10CM_DX, GridCodeType.VISITREASONS);
  const codeADMITDXEmpty = getEmpty(CodeType.ICD10CM_DX, GridCodeType.ADMIT_DX);

  return {
    accountNumber: '',
    admitDate: '',
    ageInYears: '',

    admitDiagnosisCode: { ...codeADMITDXEmpty, id: ADMIT_DX_ID },
    diagnoses: { codes: [codeDXEmpty] },
    inProcedures: { codes: [codePREmpty] },
    outProcedures: { codes: [codeCPTHCPCSEmpty] },
    visitReasons: { codes: [codeVISITREASONEmpty] },
    lastActiveCodeId: undefined,

    dateOfBirth: '',
    dischargeDate: '',
    facility: '',
    firstName: '',
    id: '0', // we show error instead of the empty encounter
    lastName: '',
    medicalRecordNumber: '',
    middleInitial: '',
    primaryCoder: 'Admin',
    sex: undefined,
    type: '',

    conditionCodes: [{ empty: true, gridType: ValuesGridType.CONDITION_CODES, id: getId() }],
    valueCodes: [{ empty: true, gridType: ValuesGridType.VALUE_CODES, id: getId() }],

    ValidationResult: {
      IsInpatient: true,
      ValidationEdits: [],
      FieldsEdits: [],
      CodeInfos: [],
      GroupingSources: [],
    },
    GroupingResults: [],
    ProcessMNEResult: {
      MneEdits: [],
    },
  };
};

export const createEmptyServiceEncounter = () => {
  const entity = createEmptyEncounter();
  // all fields must be empty
  const serviceEntity = mapEncounterToServiceEntity(entity, { providers: [], financialClasses: [] });
  return serviceEntity;
}

export const createEmptyEncounterState = (): EncounterState => ({
  dirty: true,
  inProgress: createEmptyEncounter(),
  saved: createEmptyEncounter(),
  savedServiceEntity: createEmptyServiceEncounter(),
});

// dupes are analyzed on the backend side
// add empty values to the end
export function updateCodes(_inProgress: EncounterEntity) {
  const inProgress = _inProgress;
  inProgress.diagnoses.codes = checkCodes(inProgress.diagnoses.codes, CodeType.ICD10CM_DX, GridCodeType.DIAGNOSES);
  inProgress.inProcedures.codes = checkCodes(
    inProgress.inProcedures.codes,
    CodeType.ICD10PCS_PR,
    GridCodeType.INPROCEDURES
  );
  inProgress.outProcedures.codes = checkCodes(
    inProgress.outProcedures.codes,
    CodeType.CPT4,
    GridCodeType.OUTPROCEDURES
  );
  const visitReasonsCodes = checkCodes(
    inProgress.visitReasons.codes,
    CodeType.ICD10CM_DX,
    GridCodeType.VISITREASONS
  );

  inProgress.visitReasons.codes = visitReasonsCodes.length <= 3 ? visitReasonsCodes : visitReasonsCodes.slice(0, 3);


  function checkCodes(codes: BaseCodeRow[], codeType: CodeType, gridCodeType: GridCodeType) {
    let emptyCount = 0;
    let ind;
    const len = codes.length;
    for (ind = 0; ind < len; ind++) {
      if (codes[ind].empty) {
        emptyCount++;
      }
    }

    // do not manipulate with codes if we already have single emptyItem as last one
    if (emptyCount === 1 && len > 0 && codes[len - 1].empty) {
      return codes;
    }

    const newCodes: BaseCodeRow[] = [];
    for (ind = 0; ind < len; ind++) {
      if (!codes[ind].empty) {
        newCodes.push(codes[ind]);
      }
    }

    newCodes.push(getEmpty(codeType, gridCodeType));

    return newCodes;
  }
}

// clean and prepare values grids
export const updateValueCodes = (valueCodes: ValuesRow[], gridType: ValuesGridType) => {
  let ind = 0;
  while (ind < valueCodes.length) {
    const code = valueCodes[ind];
    if (code.gridType === ValuesGridType.CONDITION_CODES && (!code.ConditionCode || code.ConditionCode.trim() === '')) {
      code.empty = true;
    }
    if (code.gridType === ValuesGridType.VALUE_CODES && (!code.ValueCode || code.ValueCode.trim() === '')) {
      code.empty = true;
      code.Amount = ''; // clear amount if we have not code
    }

    // empty element must be at the last place
    if (code.empty && ind < valueCodes.length - 1) {
      valueCodes.splice(ind, 1);
    } else {
      ind++;
    }
  }

  const maxLength = gridType === ValuesGridType.CONDITION_CODES ? 11 : 12;

  // add empty element to the end
  if ((valueCodes.length === 0 || !valueCodes[valueCodes.length - 1].empty) && valueCodes.length < maxLength) {
    valueCodes.push({
      id: getId(),
      empty: true,
      gridType
    });
  }

  // length restrictions
  if (valueCodes.length > maxLength) {
    valueCodes.splice(maxLength, maxLength - valueCodes.length);
  }
}
