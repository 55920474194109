export const MAX_EMAIL_USERNAME_LENGTH = 63;
export const MAX_EMAIL_OVERALL_LENGTH = 254;
export const REGEX_ALPHANUMERIC_PATTERN = /^[A-Za-z0-9]+$/;

export interface KendoInputChangeEvent {
  syntheticEvent: React.SyntheticEvent;
  nativeEvent: Event;
  value: string | number | string[];
  target: HTMLInputElement;
}

export const HackReactChangeEventToKendoEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
  const temp = e as any;
  const newEvent: KendoInputChangeEvent = {
    nativeEvent: temp.nativeEvent,
    syntheticEvent: temp.syntheticEvent,
    target: temp.target,
    value: temp.value,
  };
  return newEvent;
};

export const CheckEmailValidity = (emails: string[]) => {
  const emailRegex = new RegExp("^([\\w+-]+\\.)*[\\w!#$%&*+/=?^_{|}~-]*@[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\\.[a-zA-Z]{2,3}$");
  let badEmail: string | undefined;

  const allEmailsAreValid = emails.every((email) => {
    let result = emailRegex.test(email);
    if(result) {
      const emailParts = email.split('@');
      if(emailParts.length === 2) {
        if(emailParts[0].length > MAX_EMAIL_USERNAME_LENGTH) {
          result = false;
        }
      }
      if(email.length > MAX_EMAIL_OVERALL_LENGTH) {
        result = false;
      }
    }

    if(!result) {
      badEmail = email;
    }
    return result;
  });

  return {allEmailsAreValid, badEmail};
}
