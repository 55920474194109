import './MultilineTooltipTemplate.less';

import * as React from 'react';
import { getSingleErrorText, SingleValidationError, ValidationErrors } from '../../models/encounterErrors';

export interface MultilineTooltipTemplateProps {
  content?: ValidationErrors;
}

export class MultilineTooltipTemplate extends React.PureComponent<MultilineTooltipTemplateProps> {
  formatItems() {
    const items = Array.isArray(this.props.content) ? this.props.content : [this.props.content];
    const formatted = items.map(
      (item) => {
        const errorText = getSingleErrorText(item);

        return <li key={errorText} dangerouslySetInnerHTML={{ __html: errorText }}/>
      });
    return formatted;
  }

  formatContent = (error) => {
    const errorText = getSingleErrorText(error as unknown as SingleValidationError);

    return <span dangerouslySetInnerHTML={{ __html: errorText }} />;
  }

  render() {
    const wrapperClass = 'formatted-tooltip';
    const severalItems = Array.isArray(this.props.content) && this.props.content.length > 1;

    return severalItems ? (
      <div className={wrapperClass}><ul>
        {this.formatItems()}
      </ul></div>
    ) : (
      <div className={wrapperClass}><span>{Array.isArray(this.props.content) ? this.formatContent(this.props.content[0]) : this.formatContent(this.props.content)}</span></div>
    );
  }
}
