import { getCodeMetadata } from '../../../actions/codeMetadata';
import { choiceListsAPI } from '../../../services/choiceLists';
import { getFacilitiesCompleted, getFacilitiesError } from '../../../actions/choiceLists';
import { IdDescriptionBase } from '../../../models/patientEncounter';

// prepare all storages to open encounter:
// - load code metadata if it is not loaded
// - load facilities
export const prepareToOpenEncounter = (dispatch, codeMetadataInitialized: boolean, loadedFacilities?: IdDescriptionBase[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initCalls: Promise<any>[] = [];  

  initCalls.push(
    getCodeMetadata(dispatch, codeMetadataInitialized)
      .then((loaded) => {
        return {
          codeMetadata: loaded
        }
      })
  );

  initCalls.push(
    getFacilities(dispatch, loadedFacilities)
      .then((facilities) => {
        return {
          facilities
        }
      })
  );

  return Promise.all(initCalls)
    .then((results) => {
      // it is necessary to return fresh facilities list. find it within responses
      const allFacilities = results.find(item => item.facilities);
      return allFacilities ? allFacilities.facilities : [];
    })
};

const getFacilities = (dispatch, loadedFacilities?: IdDescriptionBase[]) => {
  // ability to use already loaded data
  if (loadedFacilities && loadedFacilities.length) {
    return Promise.resolve()
      .then(() => {
        return loadedFacilities;
      })
  }

  return choiceListsAPI
    .getFacilities()
    .then((facilities) => {
      dispatch(getFacilitiesCompleted(facilities));
      if (!facilities || facilities.length === 0) {
        dispatch(getFacilitiesError('No Facilities'));
      }

      return facilities;
    })
    .catch(() => {
      dispatch(getFacilitiesError('Facilities fetch error'));
      throw new Error('Facilities fetch error');
    })
}
