import * as React from 'react';

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return Promise.all([
    new Promise<{ default: React.ComponentType<any> }>((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              // reject('maximum retries exceeded');
              reject(error);
              return;
            }

            // Passing on "reject" is the important part
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    }),
    // Note: this technique with Promise.all would allow the fallback content
    // from Suspense to display for a minimum amount of time. I decided not to
    // go with it as my flicker concerns were instead addressed by .preload().
    // However, it could be optionally useful in the future as conditional
    // controlled by argument
    // ----------------
    // new Promise((resolve) => { setTimeout((arg)=>{
    // resolve(arg); }, 1000);
    // })
  ]).then(([moduleExports]) => moduleExports);
}
