import * as actionTypes from '../constants';
import { userPreferencesAPI } from '../services/userPreferences';
import { UserPreferencesEntity } from '../models';
import { parseServiceErrors } from '../services/encounter/serviceErrors';
import { addErrorsAction } from './errors';

// using Dispatch type here causes error, not sure why
export const getUserPreferencesAction = () => (dispatch) => {
  dispatch(getUserPreferencesBegin());
  userPreferencesAPI.getUserPreferences().then((preferences) => {
    dispatch(getUserPreferencesCompleted(preferences));
  })
  .catch((reason) => {
    const errors = parseServiceErrors(reason, 'Error Retrieving User Preferences');
    dispatch(addErrorsAction(errors));
    dispatch(getUserPreferencesError('Error Retrieving User Preferences'));
  });
}

const getUserPreferencesBegin = () => ({
  type: actionTypes.GET_USER_PREFERENCES_BEGIN,
});

const getUserPreferencesCompleted = (preferences: object) => ({
  payload: preferences,
  type: actionTypes.GET_USER_PREFERENCES_COMPLETED,
});

const getUserPreferencesError = (error: string) => ({
  payload: error,
  type: actionTypes.GET_USER_PREFERENCES_ERROR,
});
