import './MultiColumnComboBox.less';

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { v4 as uuid } from 'uuid';
import { DropDownsPopupSettings as KDropDownsPopupSettings, MultiColumnComboBox as KMultiColumnComboBox, MultiColumnComboBoxHandle as KMultiColumnComboBoxHandle } from '@progress/kendo-react-dropdowns';
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { ErrorWrap } from '../ErrorWrap/ErrorWrap';
import { ValidationErrors } from '../../models/encounterErrors';
import { debounce } from '../../utils/debounce';

export const NO_INITIAL_SORT = 'NO_INITIAL_SORT';

const getStringValue = (value: any, idField?: string, titleField?: string, getShowField?: Function) => {
  if (!value || typeof value === 'string') {
    return value || '';
  }

  const stringValue = getShowField ? getShowField(value) : `${value[idField || 'id']} - ${value[titleField || 'title']}`;

  return stringValue;
}

export interface MultiColumnComboBoxProps {
  field: string;                  // referenced field
  value?: any;                    // current value
  data: object[];                 // current list

  valueField?: string;            // value field
  idField?: string;               // first part of text field (id - title)
  titleField?: string;            // second part of text field (id - title)
  columns?: any[];                // special columns set
  firstColumnWidth?: number;      // custom width for first standard column
  secondColumnWidth?: number;     // custom width for second standard column

  acceptIncorrectValue?: boolean;   // true: user can type incorrect values; false: any incorrect value would be reverted or cleared
  revertIncorrectValue?: boolean;   //    used only with acceptIncorrectValue = false. true: use previous value instead of ''
  showIncorrectValue?: boolean;     //    used only with acceptIncorrectValue = false. true: incorrect value can be shown in the edit

  className?: string;             // control className
  hideClearButton?: boolean;      // hide clear button
  inputId?: string;               // special id for the input (to allow controls navigation)
  disabled?: boolean;             // control is disabled
  loading?: boolean;              // loading indicator

  popupSettings?: KDropDownsPopupSettings;   // popup special settings like custom className

  onlyControl?: boolean;          // do not wrap control with k-textbox and label
  label?: string;                 // wrapper label
  error?: ValidationErrors;         // error to be shown on wrapper

  additionalFilterColumn?: string;    // additionally filter by this column. Currently it can be only third column to hightlight
  filterIdByContainsFilter?: boolean; // special filtering to filter id column only with contains filter
  initialSortField?: string;          // data is initially sorted by this field

  // field change callback
  onChange: (fieldName: string, value?: string) => void;
  // ask parent if we can blur with current value
  checkPreventBlur?: (fieldName: string, value: string) => boolean;
  keyDownHandler?: (e) => void;     // keyDown handler
  // we can prevent ddl opening because of the validation error in other field
  checkPreventOpen?: (fieldName: string) => boolean;
  onOpen?: () => void;                      // to do something after list opening
  onFocus?: () => void;                     // to do something after focusing
  onBlur?: (fieldName: string, value: string) => void;  // blur event

  getShowField?: (item) => string;          // special rendering for the dataItem
  regexp?: RegExp;                          // apply regexp during typing
}

export interface MultiColumnComboBoxState {
  sort: SortDescriptor[];     // current sorting
  filter: string;             // typed filter value
  propsStringValue?: string;  // string value from props
  propsValue?: any;           // previous value
  opened: boolean;            // opened state
  shouldFocusMCCB?: boolean;  // return focus after prevented blur
}

export class MultiColumnComboBox
  extends React.PureComponent<MultiColumnComboBoxProps, MultiColumnComboBoxState> {

  multiRef: KMultiColumnComboBoxHandle | null;

  constructor(opts: MultiColumnComboBoxProps) {
    super(opts);
    this.state = {
      sort: [],
      filter: '',
      propsStringValue: getStringValue(opts.value, opts.idField, opts.titleField, opts.getShowField),
      propsValue: opts.value,
      opened: false,
    };
  }

  private static readonly sortDirectionMap = { 'asc': 'desc', 'desc': 'asc', '': 'desc' };

  static getDerivedStateFromProps(nextProps: MultiColumnComboBoxProps, prevState: MultiColumnComboBoxState) {
    // update filter after value changing
    if (nextProps.value !== prevState.propsValue) {
      return {
        // filter: '',
        propsStringValue: getStringValue(nextProps.value, nextProps.idField, nextProps.titleField, nextProps.getShowField),
        propsValue: nextProps.value,
      }
    }

    return null;
  }

  componentDidUpdate () {
    // work around to keep focus after prevented blur
    if (this.state.shouldFocusMCCB && this.multiRef) {
      this.multiRef.focus();
      // Kendo work around
      // TODO: must be carefull with it when opening modal dialogs with focusable elements: can be infinite focusing cycle
      this.setState({ shouldFocusMCCB: false });
    }

    // sanitize Provider/Facility information from LogRocket collection
    if (this.props.field.toLowerCase() === 'provider' || this.props.field.toLowerCase() === 'facility' || this.props.field.toLowerCase() === 'attendingmd') {
      const privateDataColumns = document.querySelectorAll(".data-private");
      for (let ind = 0, len = privateDataColumns.length; ind < len; ind++) {
        const privateDataColumn = privateDataColumns && privateDataColumns[ind];
        if (privateDataColumn) {
          privateDataColumn.setAttribute('data-private', '');
        }
      }
    }
  }

  private renderSortIcon(field: string) {
    if (this.state.sort.length && this.state.sort[0].field === field) {
      return (
        this.state.sort[0].dir === "asc" ? "k-i-sort-asc-sm" : "k-i-sort-desc-sm"
      );
    }

    return '';
  }

  private renderSortIcons = () => {
    const columnHeaders = document.getElementsByClassName('mccb-header');
    const idField = this.getIdField();
    const titleField = this.getTitleField();

    Array.prototype.forEach.call(columnHeaders, (header) => {
      if (header.getAttribute('listener') !== 'true') {
        header.addEventListener('click', (e) => {

          header.setAttribute('listener', 'true');
          const { sort } = this.state;
          const { columns, initialSortField } = this.props;

          // define field
          const fieldName = e.currentTarget.innerText.toLowerCase();
          let field = fieldName;
          if (!columns) {
            // standard definition
            field = fieldName === 'id' ? idField : titleField;
          } else {
            const sortedColumn = columns.find(column => column.headerText.toUpperCase() === field.toUpperCase());
            field = sortedColumn ? sortedColumn.field : field;
          }

          // define and change dir
          let oldDescriptor = sort.filter((d) => d.field === field)[0];
          if (!sort || !sort.length) {
            // initial sorting is active
            const firstField = columns ? columns[0].field : idField;
            oldDescriptor = initialSortField !== NO_INITIAL_SORT ? { field: initialSortField || firstField, dir: 'asc' } : { field: firstField, dir: 'desc' };
          }

          let dir = MultiColumnComboBox.sortDirectionMap[oldDescriptor && oldDescriptor.dir || ''];

          // use asc after field changing
          if (!oldDescriptor || oldDescriptor.field !== field) {
            dir = 'asc';
          }

          const newDescriptor = (dir === '' ? [] : [{ field, dir }]) as SortDescriptor[];
          this.setState({
            sort: newDescriptor
          });

          const sortClass = `${this.props.field}-${field}-icon`;

          // remove sort icons for all fields
          const allFields = columns ? columns.map((column) => column.field) : [idField, titleField];
          allFields.forEach((columnField) => {
            const unSortedHeaders = document.getElementsByClassName(`${this.props.field}-${columnField}-icon`);
            if (unSortedHeaders) {
              for (let ind = 0, len = unSortedHeaders.length; ind < len; ind++) {
                const unSortedHeader = unSortedHeaders && unSortedHeaders[ind];
                if (unSortedHeader) {
                  unSortedHeader.classList.remove('k-i-sort-asc-sm', 'k-i-sort-desc-sm');
                }
              }
            }
          })

          // render sort icon
          const icons = header.getElementsByClassName(sortClass);
          const icon = icons && icons[0];

          const sortIcon = this.state.sort.length > 0 ? this.renderSortIcon(this.state.sort[0].field) : '';
          if (sortIcon && icon) {
            icon.classList.toggle(sortIcon);
          }
        });
      }
    })
  }

  private getValueField = () => {
    return this.props.valueField || 'id';
  }

  private getIdField = () => {
    return this.props.idField || 'id';
  }

  private getTitleField = () => {
    return this.props.titleField || 'title';
  }

  // use custom or standard columns
  private prepareColumns = () => {
    if (this.props.columns) {
      return this.props.columns;
    }

    const { field } = this.props;
    const idField = this.getIdField();
    const titleField = this.getTitleField();

    // one more work around: extend last column to the all possible width
    const width = this.multiRef?.element?.clientWidth || 0;
    const firstColumnWidth = this.props.firstColumnWidth ? this.props.firstColumnWidth : 65;
    const placeForLastColumn = width - firstColumnWidth;
    let secondColumnWidth = this.props.secondColumnWidth ? this.props.secondColumnWidth : undefined;
    if (placeForLastColumn > 0 && (!secondColumnWidth || placeForLastColumn > secondColumnWidth)) {
      secondColumnWidth = placeForLastColumn > 200 ? placeForLastColumn : 200;
    }

    const columns = [
      {
        encounterField: this.props.field,
        field: idField,
        header:
          <div
            style={{ width: '100%', display: 'inline-block' }}
            className={`${field}-id-header mccb-header`}
            onMouseDown={(ev) => ev?.preventDefault()}
          >
            Id<i className={`${field}-id-icon k-icon${this.props.initialSortField !== NO_INITIAL_SORT ? ' k-i-sort-asc-sm' : ''}`} />
          </div>,
        width: firstColumnWidth,
      },
      {
        encounterField: this.props.field,
        field: titleField,
        header:
          <div
            style={{ width: '100%', display: 'inline-block' }}
            className={`${field}-title-header mccb-header`}
            onMouseDown={(ev) => ev?.preventDefault()}>
              Description<i className={`${field}-title-icon k-icon`} />
          </div>,
        width: secondColumnWidth,
      }
    ]

    return columns;
  }

  // add additional field to show, sort and filter data
  private prepareData = (filterStr?: string) => {
    const idField = this.getIdField();
    const titleField = this.getTitleField();

    const dataWithTemplate = this.props.data.map(
      (item) => {
        return {
          ...item,
          showField: this.props.getShowField ? this.props.getShowField(item) : `${item[idField]} - ${item[titleField]}`,
        };
      });
    const { sort } = this.state;
    const sorted = sort.length > 0 ? orderBy(dataWithTemplate, [sort[0]]) : dataWithTemplate;

    if (!filterStr) {
      return sorted;
    }

    let filtered = sorted;
    if (this.props.filterIdByContainsFilter) {
      const filter = { field: idField, operator: "contains", ignoreCase: true, value: filterStr };
      filtered = filterBy(sorted, filter);
    } else {
      const filter: CompositeFilterDescriptor = {
        logic: 'or',
        filters: [
          { field: 'showField', operator: "startswith", ignoreCase: true, value: filterStr },
          { field: titleField, operator: "startswith", ignoreCase: true, value: filterStr }
        ]
      }

      if (this.props.additionalFilterColumn) {
        filter.filters.push({ field: this.props.additionalFilterColumn, operator: "startswith", ignoreCase: true, value: filterStr });
      }

      filtered = filterBy(sorted, filter);
    }

    return filtered;
  }

  // return value in control format
  private prepareValue = (value) => {
    const valueField = this.getValueField();
    const idField = this.getIdField();
    const titleField = this.getTitleField();

    if (!value) {
      return null;
    }

    if (typeof value === "string") {
      if (this.props.acceptIncorrectValue || this.props.showIncorrectValue) {
        return {
          showField: value,
          [valueField]: value,
          [idField]: value,
          [titleField]: value
        }
      }

      return null;
    }


    const usedValue = {
      ...value,
      showField: this.props.getShowField ? this.props.getShowField(value) : `${value[idField]} - ${value[titleField]}`,
    };

    return usedValue;
  }

  render() {
    const { error, onlyControl } = this.props;
    const valueField = this.getValueField();

    const popupSettings = {
      ...this.props.popupSettings,
      className: `${this.props.popupSettings?.className} sort-workaround`,
    }

    const combobox =
      <KMultiColumnComboBox
        ref={(ref) => { this.multiRef = ref }}
        className={`tc-combobox ${this.props.className}`}
        data={this.prepareData(this.state.filter)}
        value={this.prepareValue(this.props.value)}
        columns={this.prepareColumns()}
        textField='showField'
        dataItemKey={valueField}
        filterable
        allowCustom
        clearButton={this.props.hideClearButton !== true}
        disabled={this.props.disabled}
        opened={this.state.opened}

        onOpen={this.handleOnOpen}
        onClose={this.handleOnClose}
        onFocus={this.handleOnFocus}
        onBlur={this.handleOnBlur}
        onChange={this.handleOnChange}
        onFilterChange={this.handleOnFilterChange}
        valueRender={this.valueRender}
        itemRender={this.itemRender}

        loading={this.props.loading}
        listNoDataRender={this.props.loading ? this.loadingNoDataRender : undefined}

        popupSettings={popupSettings}
      />

    // I need in not wrapped MultiColumnComboBox for grid cells
    if (onlyControl) {
      return <div ref={this.setRef}>
        {combobox}
      </div>;
    }

    const id = `id-${uuid()}`;
    return (
      <ErrorWrap error={error}>
        <div className="k-textbox-container" ref={this.setRef}>
          {combobox}
          <label className="k-label" htmlFor={id}>{this.props.label}</label>
        </div>
      </ErrorWrap>
    );
  }

  handleOnOpen = () => {
    setTimeout(() => {
      // list opening can be prevented by error or by not loaded list
      if (this.props.checkPreventOpen && this.props.checkPreventOpen(this.props.field)) {
        return;
      }

      if (this.props.onOpen) {
        this.props.onOpen();
      }

      this.setState({
        opened: true,
      }, () => {
        this.renderSortIcons();
      })

    }, 100);
  }

  handleOnClose = () => {
    this.setState({ opened: false }, () => {this.forceUpdate()});
  }

  handleOnFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  // check if we can left current field
  handleOnBlur = (ev) => {
    const valueField = this.getValueField();
    const comboValue = ev.value;

    // value was selected from the list
    // I have strange effect with { id: typedValue, title: typedValue, showField: typedValue } after first blut attempt
    // is it Kendo dev version side effect?
    // I must additionally check this value
    if (comboValue && comboValue[valueField]) {
      const valuePart = comboValue[valueField].toUpperCase();
      const ddlItem = this.props.data.find((item) => item[valueField] && item[valueField].toUpperCase() === valuePart);
      if (ddlItem) {
        if (this.props.onBlur) {
          this.props.onBlur(this.props.field, ddlItem[valueField]);
        }

        return;
      }
    }

    const value = this.getReturnedValue(comboValue);
    if (this.props.checkPreventBlur) {
      const canBlur = this.props.checkPreventBlur(this.props.field, value);
      if (!canBlur) {
        ev.syntheticEvent.stopPropagation();
        // return focus back
        this.setState({ shouldFocusMCCB: true });

        // do not send prevented blur
        return;
      }
    }

    if (this.props.onBlur) {
      this.props.onBlur(this.props.field, value);
    }
  }

  // calculate value to be returned from the combobox
  getReturnedValue = (comboValue) => {
    const { revertIncorrectValue, acceptIncorrectValue } = this.props;

    const valueField = this.getValueField();
    const idField = this.getIdField();
    const showField = 'showField';

    // value was selected from the list. It must be correct. Send its [valueField] to the parent
    if (comboValue && comboValue[valueField]) {
      return comboValue[valueField];
    }

    // value was cleared or typed
    const value = comboValue && comboValue[showField] ? comboValue[showField] : '';

    // value can be only selected from the list
    if (!acceptIncorrectValue) {
      // a) we have at least single list item filtered by current text: return first such item's value
      // b) we have empty value: return empty
      // c) we have custom value: return previous value with revertIncorrectValue. Return empty otherwise

      // get first item from the filtered list. Filter by current value because state.filter is debounced and can be updated in some time
      const data = this.prepareData(value);
      const listValue = value && data.length ? data[0] : null;
      if (listValue) {
        return listValue[valueField];
      }

      if (!value || !revertIncorrectValue) {
        return '';
      }

      const propsValue = this.prepareValue(this.props.value);
      const stringValue = propsValue ? propsValue[valueField] : '';
      return stringValue;
    }

    // value can be typed by user: acceptIncorrectValue
    // a) we have list item with exact [idField] = value: return this list item's value
    // b) we have empty value: return empty
    // c) we have custom value: return it

    // value was cleared or typed
    const upperValue = value ? value.toUpperCase() : '';

    // find corresponding value if possible. Use exact [idField] like in the desktop's Provider field
    const listValue = this.props.data.find((item) => item[idField] && item[idField].toUpperCase() === upperValue);
    const foundValue = listValue ? listValue[valueField] : value;

    return foundValue;
  }

  handleOnChange = (ev) => {
    // null - no value, {[showField]: 'value'} - typed value, {[showField]: 'value', [valueField]: 'id'} - selected value
    const value = this.getReturnedValue(ev.value);
    this.props.onChange(this.props.field, value);
  }

  handleOnFilterChange = debounce((ev) => {
    if (ev && ev.filter) {
      this.setState({
        filter: ev.filter.value || ''
      })
    }
  }, 200);

  // add keydown handler, add special id to input
  private valueRender = (element: React.ReactElement<HTMLSpanElement>) => {
    if (!element || !element.props || !element.props.children || !element.props.children.props) {
      return element;
    }

    const newProps = {
      ...element.props.children.props,
    }

    if (this.props.inputId) {
      newProps.id = this.props.inputId;
    }

    // special keydown handler if necessary
    if (this.props.keyDownHandler) {
      const onKeyDownInput = (ev) => {
        if (this.props.keyDownHandler) {
          this.props.keyDownHandler(ev);
        }

        if (element.props.children.props.onKeyDown) {
          element.props.children.props.onKeyDown(ev);
        }
      }

      newProps.onKeyDown = onKeyDownInput;
    }

    // regexp restrictions
    if (this.props.regexp) {
      const onChangeInput = (ev) => {
        const { value } = ev.target;

        // allow regexp value + any partial value
        if (this.props.regexp && this.props.regexp.test(value)) {
          const data = this.prepareData();
          const showField = 'showField';
          if (!data.find(rec => rec[showField] && rec[showField].toUpperCase().startsWith(value.toUpperCase()))) {
            ev.preventDefault();
            return;
          }
        }

        if (element.props.children.props.onChange) {
          element.props.children.props.onChange(ev);
        }
      }

      const onPasteInput = (ev) => {
        ev.preventDefault();

        const pastedValue = ev.clipboardData.getData('Text') || '';

        let proposedValue = '';
        for (let ind = 0, len = pastedValue.length; ind < len; ind++) {
          const nextValue = `${proposedValue}${pastedValue[ind]}`;
          if (!this.props.regexp?.test(nextValue)) {
            proposedValue = nextValue;
          }
        }

        ev.target.value = proposedValue;
      }

      newProps.onChange = onChangeInput;
      newProps.onPaste = onPasteInput;
    }

    const children = {
      ...element.props.children,
      props: newProps,
    };

    return React.cloneElement(element, { ...element.props }, children);
  }

  private highlightFilter = (value: string, filter: string, columnIndex: number, dataItem) => {
    if (!value || !filter) {
      return value;
    }

    // special filtering: filter only id column, filter by contains filter, highlight only first found text
    if (this.props.filterIdByContainsFilter && columnIndex === 0) {
      const columnFilter = filter.slice(0, value.length);
      const textPos = value.toLowerCase().indexOf(columnFilter);
      if (textPos !== -1) {
        const result =
          <span>
            <span className="filter">{value.slice(0, textPos)}</span>
            <span className="highlight-filter">{value.slice(textPos, textPos + columnFilter.length)}</span>
            <span className="filter">{value.slice(textPos + columnFilter.length, value.length)}</span>
          </span>

        return result;
      }

      return value;
    }

    const idField = this.getIdField();
    const titleField = this.getTitleField();
    const showTextNoCase = this.props.getShowField ? this.props.getShowField(dataItem) : `${dataItem[idField]} - ${dataItem[titleField]}`;
    const showText = showTextNoCase ? showTextNoCase.toLowerCase() : '';


    const firstText = dataItem[idField] ? dataItem[idField].toUpperCase() : '';
    const secondText = dataItem[titleField];

    // id column
    if (columnIndex === 0) {
      let columnFilter = filter;
      if (showText.startsWith(filter) && filter.length > value.length) {
        columnFilter = filter.slice(0, value.length);
      }

      if (value.toLowerCase().startsWith(columnFilter)) {
        const result =
          <span>
            <span className="highlight-filter">{value.slice(0, columnFilter.length)}</span>
            <span className="filter">{value.slice(columnFilter.length, value.length)}</span>
          </span>
        return result;
      }

      return value;
    }

    // text column
    if (columnIndex === 1) {
      let columnFilter = filter;
      const previousColumnsLength = firstText.length + 3;
      if (showText.startsWith(filter) && filter.length >= previousColumnsLength) {
        columnFilter = filter.slice(previousColumnsLength, filter.length);

        if (columnFilter.length > value.length) {
          columnFilter = columnFilter.slice(0, value.length);
        }
      }

      if (value.toLowerCase().startsWith(columnFilter)) {
        const result =
          <span>
            <span className="highlight-filter">{value.slice(0, columnFilter.length)}</span>
            <span className="filter">{value.slice(columnFilter.length, value.length)}</span>
          </span>
        return result;
      }

      return value;
    }

    // service column for Provider
    if (columnIndex === 2 && this.props.additionalFilterColumn) {
      let columnFilter = filter;
      const previousColumnsLength = firstText.length + 3 + secondText.length + 3;
      if (showText.startsWith(filter) && filter.length >= previousColumnsLength) {
        columnFilter = filter.slice(previousColumnsLength, filter.length);

        if (columnFilter.length > value.length) {
          columnFilter = columnFilter.slice(0, value.length);
        }
      }

      if (value.toLowerCase().startsWith(columnFilter)) {
        const result =
          <span>
            <span className="highlight-filter">{value.slice(0, columnFilter.length)}</span>
            <span className="filter">{value.slice(columnFilter.length, value.length)}</span>
          </span>
        return result;
      }

      return value;
    }

    return value;
  }

  private itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps) => {
    const columns = this.prepareColumns();

    const itemChildren = columns.map((m, i) => {
      const privateData = m.encounterField.toLowerCase() === 'provider' || m.encounterField.toLowerCase() === 'facility' || m.encounterField.toLowerCase() === 'attendingmd' ? 'data-private' : '';
      const className = `k-cell ${privateData}`;

      const original = itemProps.dataItem[m.field];
      const filter = this.state.filter || this.state.propsStringValue;
      const text = original && filter ? this.highlightFilter(original, filter.toLowerCase(), i, itemProps.dataItem) : original;

      return (
        <span key={m.field} className={className} style={{ width: `${m.width}px` }} title={original || undefined}>
          {text}
        </span>);
    });

    const liProps = {
      ...li.props,
      className: `${li.props.className || ''} provider-ddl ddl-row`,
    };

    return React.cloneElement(li, {...liProps}, itemChildren);
  }

  // workaround to correctly handle focusing
  private setRef = (ref) => {
    if (ref && ref.getElementsByClassName && ref.getElementsByTagName) {
      const arrows = ref.getElementsByClassName('k-icon k-i-arrow-s');
      const inputs = ref.getElementsByTagName('input');

      if (arrows.length && inputs.length) {
        const arrow = arrows[0];
        const { onmousedown } = arrow;
        arrow.onmousedown = (ev) => {
          if (!this.props.disabled) {
            inputs[0].focus();
          }

          if (onmousedown) {
            onmousedown(ev);
          }
        }
      }
    }
  }

  private loadingNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: '1em' }}>
        Loading...
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  }
}
