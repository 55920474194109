import { DDLValues } from '.';

export enum CodingLevel {
  B = 'B',
  E = 'E'
}

const B = { title: 'Beginner', id: CodingLevel.B };
const E = { title: 'Experienced', id: CodingLevel.E};

export const CodingLevelCollection: DDLValues[] = [
  B,
  E
];
