import * as moment from 'moment';
import { EncounterEntity } from '../../../models/encounterEntity';
import { toFormattedDate } from '../../../utils/date';

export default function calculateLOS(encounter: EncounterEntity): number {
  // use server answer if possible
  if (encounter.ValidationResult && encounter.ValidationResult.LengthOfStay) {
    return Number(encounter.ValidationResult.LengthOfStay);
  }

  const admitDate = toFormattedDate(encounter.admitDate);

  if (!admitDate) {
    return 1;
  }

  const dischargeDate = toFormattedDate(encounter.dischargeDate);
  const lastDate = dischargeDate ? moment(dischargeDate) : moment();

  const difference = lastDate.diff(admitDate, 'd');

  return difference <= 0 ? 1 : difference;
}