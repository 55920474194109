import './Card.less';

import * as React from 'react';
import { Card as KCard } from '@progress/kendo-react-layout';
// FIXME: once this export is fixed
import { CardProps as KCardProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/CardProps';

export const Card = function render(props: KCardProps) {
  const { className, ...otherProps } = props;
  return <KCard
    className={`tc-card ${className}`}
    {...otherProps}
  />;
}
