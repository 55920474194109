import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import * as actionTypes from '../constants';
import { EncoderUserEntity, FacilityRolesEntity, ManageEncoderUserState, UserStatus } from '../models';
import { SaveUserPayload } from '../scenes/Users/actions/saveSelectedEncoderUser';
import { FacilityRolesUpdatedPayload } from '../scenes/Users/actions/updateFacilityRoles';


const createEmptyEncoderUser = (): EncoderUserEntity => {
  return {
    clientId: '',
    customerId: '',
    firstName: '',
    lastName: '',
    userId: '',
    userName: '',
    email: '',
    isSystemAdmin: false,
    status: UserStatus.DISABLED,
    version: '',
    roles: [],
  }
};

const createEmptyManageEncoderUserState = (): ManageEncoderUserState => ({
  selectedAccount: createEmptyEncoderUser(),
  errorText: '',
  inviteErrorTexts: [],
  inviteSuccesses: [],
  loading: false,
  dirty: false,
});

export const manageEncoderUserReducer = (state = createEmptyManageEncoderUserState(), action) => {
  switch (action.type) {
    case actionTypes.SET_USERS_LOADING:
      return handleSetUsersLoading(state, action.payload);
    case actionTypes.GET_SELECTED_USER_BEGIN:
    case actionTypes.GET_SELECTED_USER_ROLES_BEGIN:
    case actionTypes.CREATE_NEW_USER_BEGIN:
      return handleGetSelectedEncoderUserBegin();
    case actionTypes.GET_SELECTED_USER_COMPLETED:
      return handleGetSelectedEncoderUserCompleted(state, action.payload);
    case actionTypes.GET_SELECTED_USER_ERROR:
    case actionTypes.GET_SELECTED_USER_ROLES_ERROR:
    case actionTypes.SAVE_SELECTED_USER_ROLES_ERROR:
      return handleGetSelectedEncoderUserError(state, action.payload);
    case actionTypes.UPDATE_SELECTED_ENCODER_USER_FIELD:
      return handleUpdateEncoderUserField(state, action.payload);
    case actionTypes.SAVE_SELECTED_ENCODER_USER_BEGIN:
    case actionTypes.INVITE_NEW_USER_BEGIN:
    case actionTypes.INVITE_MULTIPLE_NEW_USERS_BEGIN:
      return handleSaveSelectedEncoderUserBegin(state, action.payload);
    case actionTypes.SAVE_SELECTED_ENCODER_USER_COMPLETED:
      return handleSaveSelectedEncoderUserCompleted(state, action.payload);
    case actionTypes.SAVE_SELECTED_ENCODER_USER_ERROR:
      return handleSaveSelectedEncoderUserError(state, action.payload);
    case actionTypes.GET_SELECTED_USER_ROLES_COMPLETED:
      return handleGetSelectedUserRolesCompleted(state, action.payload);
    case actionTypes.UPDATE_FACILITY_ROLES:
      return handleSelectedUserRolesChanged(state, action.payload);
    case actionTypes.SET_ALL_FACILITY_ROLES:
      return handleSelectedUserChangeAllRoles(state, action.payload);
    case actionTypes.CREATE_NEW_USER_COMPLETED:
      return handleCreateNewUserCompleted(state, action.payload);
    case actionTypes.SAVE_SELECTED_USER_ROLES_COMPLETED:
      return handleSaveSelectedUserRolesCompleted(state, action.payload);
    case actionTypes.REINVITE_USER_BEGIN:
      return handleReinviteUserBegin(state, action.payload);
    case actionTypes.INVITE_NEW_USER_COMPLETED:
    case actionTypes.DELETE_USER_INVITE_COMPLETED:
      return handleInviteUserCompleted(state, action.payload);
    case actionTypes.INVITE_MULTIPLE_NEW_USERS_COMPLETED:
      return handleInviteMultipleUserCompleted(state, action.payload);
    case actionTypes.INVITE_MULTIPLE_NEW_USERS_PROCESSING:
      return handleInviteMultipleUserProcessing(state, action.payload);
    case actionTypes.REINVITE_USER_COMPLETED:
      return handleReinviteUserCompleted(state, action.payload);
    case actionTypes.CANCEL_CREATE_NEW_USER_COMPLETED:
    case actionTypes.CREATE_NEW_ENCOUNTER:
      return createEmptyManageEncoderUserState();
    case actionTypes.CLEAR_INVITE_NEW_USER_ERROR:
      return handleClearInviteUserError(state, action.payload);
    case actionTypes.INVITE_NEW_USER_ERROR:
      return handleInviteUserError(state, action.payload);
    default:
      return state;
  }
};

const handleUpdateEncoderUserField = (state: ManageEncoderUserState, payload: any): ManageEncoderUserState => {
  const userChanged = state.selectedAccount[payload.fieldName] !== payload.value || state.selectedAccount.userId === '';
  if(!userChanged) {
    return state;
  }

  const selectedAccount = {
    ...state.selectedAccount,
    [payload.fieldName]: payload.value,
    errorText: '',
  };
  return {
    ...state,
    loading: false,
    dirty: true,
    selectedAccount,
  };
};

const handleGetSelectedEncoderUserBegin = (): ManageEncoderUserState => ({
  selectedAccount: createEmptyEncoderUser(),
  errorText: '',
  loading: true,
  dirty: false,
});

const handleSelectedUserRolesChanged = (state: ManageEncoderUserState, payload: FacilityRolesUpdatedPayload): ManageEncoderUserState => {
  const selectedAccount = {
    ...state.selectedAccount,
    errorText: ''
  };

  const newRoles = cloneDeep(state.selectedAccount.roles);
  const updatedFacility = newRoles.find((f) => f.Facility === payload.facility);

  if(updatedFacility) {
    updatedFacility.Role = payload.role;
  }

  selectedAccount.roles = newRoles;
  return {
    ...state,
    dirty: true,
    loading: false,
    selectedAccount,
  };
};

const handleSelectedUserChangeAllRoles = (state: ManageEncoderUserState, payload: FacilityRolesUpdatedPayload): ManageEncoderUserState => {
  const selectedAccount = {
    ...state.selectedAccount,
    errorText: ''
  };

  selectedAccount.roles = selectedAccount.roles.map((el) => ({...el, Role: payload.role}));

  return {
    ...state,
    dirty: true,
    loading: false,
    selectedAccount,
  };
};


const handleSaveSelectedEncoderUserBegin= (state: ManageEncoderUserState, payload: EncoderUserEntity): ManageEncoderUserState => {
  const updatedState = {
    ...state,
    selectedAccount: state.selectedAccount,
    errorText: '',
    loading: true,
  };

  return updatedState;
};

const handleSaveSelectedEncoderUserCompleted = (state: ManageEncoderUserState, payload: SaveUserPayload): ManageEncoderUserState => {
  const updatedState = {
    selectedAccount: payload.user,
    errorText: '',
    dirty: payload.saveRolesNeeded,
    loading: payload.saveRolesNeeded,
  };

  return updatedState;
};

const handleSaveSelectedEncoderUserError = (state: ManageEncoderUserState, error): ManageEncoderUserState => {
  const updatedState = {
    selectedAccount: state.selectedAccount,
    errorText: error.message,
    dirty: true,
    loading: false,
  };

  return updatedState;
};

const handleGetSelectedEncoderUserCompleted = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {
  const updatedState = {
    selectedAccount: payload,
    errorText: '',
    dirty: false,
    loading: false
  };

  return updatedState;
};

const handleGetSelectedEncoderUserError = (state: ManageEncoderUserState, error): ManageEncoderUserState => {
  const updatedState = {
    ...state,
    selectedAccount: createEmptyEncoderUser(),
    errorText: error.message,
    loading: false,
  };

  return updatedState;
};

const handleGetSelectedUserRolesCompleted = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {
  const selectedAccount = {
    ...payload.selectedUser,
    roles: payload.roles
  }

  return {
    ...state,
    loading: false,
    dirty: payload.forceDirty,
    selectedAccount,
  };
}

const handleSaveSelectedUserRolesCompleted = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {

  const updatedUser = payload.newUser ? createEmptyEncoderUser() : state.selectedAccount;

  const updatedState = {
    selectedAccount: updatedUser,
    errorText: '',
    dirty: false,
    loading: payload.newUser,
  }

  return updatedState;
}

const handleCreateNewUserCompleted = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {
  const newUserRoles: FacilityRolesEntity[] = payload.map((facility) => {
    return {
      Facility: facility.Id,
      FacilityId: facility.ViewId,
      Role: 'NoRole',
      UserId: '',
      Version: '',
      FacilityIsActive: facility.IsActive
    };
  });

  const selectedAccount = {
    ...state.selectedAccount,
    roles: newUserRoles
  }

  return {
    selectedAccount,
    errorText: '',
    dirty: false,
    loading: false,
  }
}

const handleReinviteUserBegin= (state: ManageEncoderUserState, payload: EncoderUserEntity): ManageEncoderUserState => {
  const updatedState = {
    ...state,
    errorText: '',
    loading: true,
    dirty: false,
  };

  return updatedState;
};

const handleInviteMultipleUserProcessing = (state: ManageEncoderUserState, payload: EncoderUserEntity): ManageEncoderUserState => {
  const selectedAccount = {
    ...state.selectedAccount,
    email: payload.email,
  }

  const updatedState = {
    selectedAccount,
    errorText: '',
    dirty: true,
    loading: false,
  };

  return updatedState;
}

const handleInviteMultipleUserCompleted = (
  state: ManageEncoderUserState,
  payload: { lastInvitedUser: EncoderUserEntity, errors: string[], successes: string[] },
): ManageEncoderUserState => {
  if (payload.errors.length > 0) {
    return {
      ...state,
      inviteErrorTexts: [...payload.errors],
      inviteSuccesses: [...payload.successes],
      dirty: false,
      loading: false,
    }
  }

  const selectedAccount = {
    ...state.selectedAccount,
    userId: undefined,
    customerId: undefined,
    roles: payload.lastInvitedUser?.roles || [],
    status: UserStatus.DISABLED,
  }

  const updatedState = {
    selectedAccount,
    errorText: '',
    dirty: false,
    loading: false,
  };

  return updatedState;
}

const handleInviteUserCompleted = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {
  const updatedState = {
    selectedAccount: payload,
    errorText: '',
    dirty: false,
    loading: false,
  };

  return updatedState;
};

const handleReinviteUserCompleted = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {
  const selectedAccount = {
    ...payload.selectedUser,
    roles: payload.roles
  }

  return {
    ...state,
    loading: false,
    dirty: false,
    selectedAccount,
  };
};

const handleClearInviteUserError = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {
  const updatedState = {
    selectedAccount: createEmptyEncoderUser(),
    errorText: state.errorText,
    inviteErrorTexts: [],
    inviteSuccesses: [],
    loading: false,
    dirty: false,
  };

  return updatedState;
}

const handleInviteUserError = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {
  const updatedState = {
    selectedAccount: createEmptyEncoderUser(),
    errorText: payload,
    loading: false,
    dirty: false,
  };

  return updatedState;
}

const handleSetUsersLoading = (state: ManageEncoderUserState, payload): ManageEncoderUserState => {
  const updatedState = {
    ...state,
    loading: payload,
  };

  return updatedState;
}
