import './Ripple.less';

import * as React from 'react';
import { Ripple as KRipple, RippleProps } from '@progress/kendo-react-ripple';

export const Ripple = function render(props: React.PropsWithChildren<RippleProps>) {
  const { className, ...otherProps } = props;
  return (
    <KRipple className={`tc-ripple ${className}`} {...otherProps}>{props.children}</KRipple>
  );
}

export default Ripple;
