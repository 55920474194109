export enum NetActivityStatus {
  NET_ACTIVITY_ACTIVE = 'In progress',
  NET_ACTIVITY_COMPLETED = 'Completed',
  NET_ACTIVITY_ERROR = 'Error',
  NET_ACTIVITY_CANCELLED = 'Cancelled',
}

export interface NetActivity {
  id: string;
  url?: string;
  startTime?: string;
  endTime?: string;
  status?: NetActivityStatus;
  responseStatus?: string;
  errorReason?: string;
  singletonKey?: string;
}

export interface NetActivityState {
  activities: NetActivity[];
  hasActiveRequest: boolean;
}
