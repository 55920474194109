import { v4 as uuid } from 'uuid';
import * as actionTypes from '../constants';
import { FacilitySettingsState, FacilityServiceFieldSetting, FieldSettings, FacilityPreferences } from '../models/facilitySettings';

const createEmptyFacilitySettings = (): FacilitySettingsState => {
  return {
    fieldSettings: {},
    serviceFieldSettings: [],
    preferences: {},
    fieldSettingsUUID: 'unloaded',
    loading: false,
  };
};

export const facilitySettingsReducer = (state = createEmptyFacilitySettings(), action) => {
  switch (action.type) {
    case actionTypes.SET_FACILITY_SETTINGS_BEGIN:
      return handleSetFacilitySettingsBegin(state);
    case actionTypes.SET_FACILITY_SETTINGS:
      return handleSetFacilitySettings(state, action.payload);
    case actionTypes.SET_FACILITY_PREFERENCES:
      return handleSetFacilityPreferences(state, action.payload);
    case actionTypes.GET_FACILITIES_FAIL:
    case actionTypes.GET_FACILITY_SETTINGS_FAIL:
      return handleGetFacilitySettingsFailed(state);
    default:
      return state;
  }
};

const handleSetFacilitySettingsBegin = (state: FacilitySettingsState ): FacilitySettingsState => {
  return {
    ...state,
    loading: true
  };
};

const handleSetFacilitySettings = (state: FacilitySettingsState, payload: {
  serviceSettings: FacilityServiceFieldSetting[], clientSettings: FieldSettings }): FacilitySettingsState => {
  return {
    ...state,
    serviceFieldSettings: payload.serviceSettings,
    fieldSettings: payload.clientSettings,
    fieldSettingsUUID: uuid(),
    loading: false
  };
};

const handleSetFacilityPreferences = (state: FacilitySettingsState, payload: { servicePreferences: FacilityPreferences }): FacilitySettingsState => {
  return {
    ...state,
    loading: false,
    preferences: payload.servicePreferences,
  };
};

const handleGetFacilitySettingsFailed = (state: FacilitySettingsState): FacilitySettingsState => {
  return {
    ...state,
    fieldSettings: {},
    serviceFieldSettings: [],
    preferences: {},
    fieldSettingsUUID: uuid(),
    loading: false,
  };
};
