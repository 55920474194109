import './DatePicker.less';

import * as React from 'react';

import { CalendarNavigationItem, CalendarNavigationItemProps } from '@progress/kendo-react-dateinputs';

const CustomNavigationItem = (props: CalendarNavigationItemProps) => {
  return (
    <CalendarNavigationItem
      {...props}
      value={props.value}
      onClick={props.onClick}
    >
      {props.isRangeStart && props.view === 0 ? 'Jan' : props.children}
    </CalendarNavigationItem>
  );
};

export { CustomNavigationItem };
