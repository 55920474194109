import './Initializing.less';

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ErrorDetails, LookupState, UI, UserState } from '../../models';
import UpdatePoller from '../UpdatePoller/UpdatePoller';
import { ErrorPageContainer } from '../ErrorPage/ErrorPageContainer';

interface InitializingProps extends RouteComponentProps {
  lookups: LookupState;
  ui: UI,
  initialize: () => void;
  updateFacilities: () => void;
  getUserPreferences: () => void;
  skipInit?: boolean;
}

export class Initializing extends React.PureComponent<InitializingProps> {
  constructor(props: InitializingProps) {
    super(props);
    this.onRetryClick = this.onRetryClick.bind(this);
  }

  componentDidMount() {
    if (!this.props.ui.initializing && !this.props.ui.initialized) {
      this.props.initialize();
    }
  }

  render() {
    if (this.props.skipInit) {
      return (
        <div className="initializing-wrapper full-height" >
          {this.props.children}
        </div>
      );
    }

    // negate not instant logout redirection visual effects
    if (this.props.location.hash && this.props.location.hash.indexOf('logouted') !== -1) {
      return <div className="initializing-wrapper full-height" />
    }

    let content: React.ReactNode = <p className="initializing-status"><span className="k-icon k-loading-image" /></p>;
    // FIXME: loading codeBooks only affects CCA, how to deal with this container more generally?
    if (!this.loadingIsActive()) {
      // Determine token status
      // if (true) {
      if (this.props.ui.initializingError) {
        const err: ErrorDetails = {
          error: this.props.ui.initializingErrorDetail,
          name: this.props.ui.initializingErrorReason,
        }
        content = <ErrorPageContainer customError={err} history={this.props.history} onTryAgainClick={this.onRetryClick} />
      } else {
        content = this.props.children;
      }
      // Determine lookup status
      if (this.props.lookups.error) {
        const err: ErrorDetails = {
          error: this.props.lookups.errorDetail,
          name: 'LookupError',
        }
        content = <ErrorPageContainer customError={err} history={this.props.history} onTryAgainClick={this.onRetryClick} />
      }
    }
    return (
      <div className="initializing-wrapper full-height" >
        {content}
        <UpdatePoller
          updateFacilities={this.props.updateFacilities}
        />
      </div>
    );
  }

  private loadingIsActive = () => {
    return this.props.ui.initializing;
  }

  private onRetryClick() {
    if (this.props.ui.initializingError) {
      this.props.initialize();
    }
  }
}
