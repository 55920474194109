import './Dialog.less';

import * as React from 'react';
import { Dialog as KDialog, DialogProps as KDialogProps } from '@progress/kendo-react-dialogs';


export const Dialog = function render(props: KDialogProps) {
  const { className, autoFocus,...otherProps } = props;
  return <KDialog
    autoFocus={true}
    className={`tc-dialog ${className}`}
    {...otherProps} />
}
