import { Dispatch } from 'redux';
import * as actionTypes from '../constants';
import { CodeGridColumnsInfo, CodeGridsColumns,
  ReorderColumnsParameters, ResizeColumnParameters } from '../models/columns';
import {
  loadColumnsInfoFromLocalStorage,
  loadShowReorderWarning,
  loadShowResizeWarning,
  saveColumnsInfoToLocalStorage,
  saveShowReorderWarning,
  saveShowResizeWarning
} from '../services/columnsInfo/columnsInfo';

export const initColumnsSettingsAction = () => (dispatch: Dispatch) => {
  const columnsInfo = loadColumnsInfoFromLocalStorage();

  dispatch(initColumnsSettings(columnsInfo));
};

export const resizeColumnAction = (resizeParameters: ResizeColumnParameters) => (dispatch: Dispatch) => {
  const columnKey = resizeParameters.gridSettings.columnsOrder[resizeParameters.columnIndex];
  const newWidthStr = `${resizeParameters.newWidth}px`;
  const newGridSettings = {
    ...resizeParameters.gridSettings,
    columnsInfo: {
      ...resizeParameters.gridSettings.columnsInfo,
      [columnKey]: {
        ...resizeParameters.gridSettings.columnsInfo[columnKey],
        width: newWidthStr
      }
    }
  };

  if (!resizeParameters.notUpdateStorage) {
    saveColumnsInfoToLocalStorage(resizeParameters.gridKey, newGridSettings);
  }

  // -2 is used as a flag to determine if we need to delete the width field from the
  // columnsInfo object to allow the column to take up the remaining white space
  if(resizeParameters.newWidth === -2) {
    delete newGridSettings.columnsInfo[columnKey].width;
  }

  dispatch(changeGridSettings(newGridSettings, resizeParameters.gridKey));
};

const initColumnsSettings = (columnsInfo: CodeGridsColumns) => ({
  payload: columnsInfo,
  type: actionTypes.UI_INIT_COLUMNS_SETTINGS,
});

const changeGridSettings = (gridSettings: CodeGridColumnsInfo, gridKey: string) => ({
  payload: { gridSettings, gridKey },
  type: actionTypes.UI_CHANGE_GRID_SETTINGS,
});

// *** Show Warning after resize section ***

export const initShowColumnsResizeWarningAction = () => (dispatch: Dispatch) => {
  const show = loadShowResizeWarning();
  dispatch(setShowColumnsResizeWarning(show));
};

export const changeShowColumnsResizeWarningAction = (show: boolean) => (dispatch: Dispatch) => {
  saveShowResizeWarning(show);

  dispatch(setShowColumnsResizeWarning(show));
};

const setShowColumnsResizeWarning = (show: boolean) => ({
  payload: show,
  type: actionTypes.UI_RESIZE_SHOW_WARNING,
});

// *** Reorder columns section ***

export const reorderColumnsAction = (reorderParameters: ReorderColumnsParameters) => (dispatch: Dispatch) => {
  const newGridSettings = {
    ...reorderParameters.gridSettings,
    columnsOrder: [...reorderParameters.newOrder]
  };

  if (!reorderParameters.notUpdateStorage) {
    saveColumnsInfoToLocalStorage(reorderParameters.gridKey, newGridSettings);
  }

  dispatch(changeGridSettings(newGridSettings, reorderParameters.gridKey));
};

// *** Show Warning after reorder section ***

export const initShowColumnsReorderWarningAction = () => (dispatch: Dispatch) => {
  const show = loadShowReorderWarning();
  dispatch(setShowColumnsReorderWarning(show));
};

export const changeShowColumnsReorderWarningAction = (show: boolean) => (dispatch: Dispatch) => {
  saveShowReorderWarning(show);

  dispatch(setShowColumnsReorderWarning(show));
};

const setShowColumnsReorderWarning = (show: boolean) => ({
  payload: show,
  type: actionTypes.UI_REORDER_SHOW_WARNING,
});
