import * as actionTypes from '../constants';
import { EncoderUserListResultEntity, EncoderUserListState } from '../models';

const createEmptyEncoderUserListState = (): EncoderUserListState => ({
  totalUsers: 0,
  users: [],
  errorText: '',
  loading: false,
});

export const encoderUserListReducer = (state = createEmptyEncoderUserListState(), action) => {
  switch (action.type) {
    case actionTypes.GET_ENCODER_USER_LIST_REQUEST_BEGIN:
      return handleGetUserListBegin();
    case actionTypes.GET_ENCODER_USER_LIST_COMPLETED:
      return handleGetUserListCompleted(state, action.payload);
    case actionTypes.REMOVE_ENCODER_USER_COMPLETED:
      return handleRemoveUser(state, action.payload);
    case actionTypes.GET_ENCODER_USER_LIST_ERROR:
    case actionTypes.REMOVE_ENCODER_USER_ERROR:
      return handleEncoderUserListError(state, action.payload);
    default:
      return state;
  }
};

const handleGetUserListBegin = (): EncoderUserListState => {
  const updatedState = {
    totalUsers: 0,
    users: [],
    errorText: '',
    loading: true,
  }

  return updatedState;
};

const handleGetUserListCompleted = (state: EncoderUserListState, payload: EncoderUserListResultEntity): EncoderUserListState => {
  const updatedState = {
    users: payload.users,
    totalUsers: payload.total_count,
    errorText: '',
    loading: false,
  };

  return updatedState;

};

const handleRemoveUser = (state: EncoderUserListState, payload: EncoderUserListResultEntity): EncoderUserListState => {
  const updatedState = {
    users: payload.users,
    totalUsers: payload.total_count,
    errorText: '',
    loading: false
  };

  return updatedState;
};

const handleEncoderUserListError = (state: EncoderUserListState, error): EncoderUserListState => {
  const updatedState = {
    ...state,
    errorText: error.message,
    loading: false,
  };

  return updatedState;
};
