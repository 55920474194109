import * as React from 'react';
import {
  DropDownList as KDropDownList,
  DropDownListChangeEvent as KDropDownListChangeEvent,
  DropDownListProps as KDropDownListProps,
  ListItemProps as KListItemProps
} from "@progress/kendo-react-dropdowns";

import './DropDownList.less';

export interface DropDownListProps extends Omit<KDropDownListProps, 'onChange'> {
  preventNavigation?: boolean;
  returnFullRecord?: boolean;
  onChange: (fieldName: string, value: string, e: DropDownListChangeEvent) => void;
  alwaysShowTooltip: boolean;
}

export interface DropDownListKendoState {
  showTooltip: boolean;
}

export class DropDownList extends React.PureComponent<DropDownListProps, DropDownListKendoState> {
  static defaultProps = {
    alwaysShowTooltip: true,
  };

  dropDownList: KDropDownList | null;

  constructor(opts: DropDownListProps) {
    super(opts);

    this.state = { showTooltip: this.props.alwaysShowTooltip };
  }

  componentDidUpdate(): void {
    this.showTooltipCheck();
  }

  componentDidMount(): void {
    this.showTooltipCheck();
  }

  render() {
    const onKendoChange = (e: KDropDownListChangeEvent, providedChangeHandler) => {
      const value = this.props.dataItemKey && !this.props.returnFullRecord ? e.value[this.props.dataItemKey] || e.value : e.value;
      providedChangeHandler(e.target.name, value, e);
    };

    const dropDownListControl = (
      <KDropDownList
        ref={(ref) => this.DropDownListRef(ref)}
        className={`tc-ddl ${this.props.className || ''}`}
        title={this.state.showTooltip ? this.props.title : ''}
        data={this.props.data}
        textField={this.props.textField}
        dataItemKey={this.props.dataItemKey}
        value={this.props.value}
        loading={this.props.loading}
        disabled={this.props.disabled}
        valueRender={this.props.valueRender}
        onOpen={this.props.onOpen}
        onChange={(e) => {onKendoChange(e, this.props.onChange)}}
        onClose={this.props.onClose}
        onBlur={this.props.onBlur}
        onFilterChange={this.props.onFilterChange}
        onPageChange={this.props.onPageChange}
        listNoDataRender={this.props.listNoDataRender}
        header={this.props.header}
        itemRender={this.props.itemRender}
        popupSettings={this.props.popupSettings}
        opened={this.props.opened}
        filterable={this.props.filterable}
        virtual={this.props.virtual}
        label={this.props.label}
        required={this.props.required}
        validationMessage={this.props.validationMessage}
        valid={this.props.valid}
        filter={this.props.filter}
        style={this.props.style}
      />);

    return dropDownListControl;
  }

  isDropDownOpen(): boolean {
    const el = this.dropDownList;
    if(el) {
      return el.state.opened || false;
    }
    return false;
  }

  private isEllipsisVisible = (element: Element | null) => {
    if(element) {
      if(element.clientWidth < element.scrollWidth) {
        const style = window.getComputedStyle(element);
        return style && style.textOverflow === 'ellipsis';
      }
    }
    return false;
  }

  private showTooltipCheck = () => {
    const el = this.dropDownList?.element;
    if(el && !this.props.alwaysShowTooltip) {
      const inputElement = el.querySelector('span.k-input');
      const isEllipsisVisible = this.isEllipsisVisible(inputElement);
      this.setState({
        showTooltip: isEllipsisVisible,
      })
    }
  }

  private DropDownListRef = (ref: KDropDownList | null) => {
    this.dropDownList = ref;
  }
}

export interface DropDownListChangeEvent extends KDropDownListChangeEvent {}

export interface ListItemProps extends KListItemProps {};
