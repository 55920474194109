import * as React from "react";

import './FormComponent.less';

interface FormComponentProps {
  editorId: string;
  editorValid: boolean;
  label: string;
  children: React.ReactNode;
  validationMessage: string;
  required?: boolean;
}

export const FormComponent = (props: FormComponentProps) => {
  return (
    <div className='k-form-field'>
      <label
        htmlFor={props.editorId}
        className={`k-label ${!props.editorValid ? 'k-text-error' : ''}`}
      >
        {props.label}{props.required ? <span className='required-field-mark'>*</span> : null}
      </label>
      {props.children}
      {!props.editorValid && (
        <div role='alert' className='k-form-error k-text-start'>{props.validationMessage}</div>
      )}
    </div>
  );
};