import { ParsedQuery } from "query-string";

export function getParameterByName(name: string, url?: string) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${  name  }(=([^&#]*)|&|#|$)`);
  const address = url || window.location.href;
  const results = regex.exec(address);
  if (!results) { return null; }

  if (!results[2]) { return ''; }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function isChildWindow() {
  const isModalCodebooksWindow = window.location.pathname.startsWith('/codebooks');
  const isModalReferencesWindow = window.location.pathname.startsWith('/references');

  return isModalCodebooksWindow || isModalReferencesWindow;  
}

const entityMap = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
};

// partial html characters escaping to fit kendo combobox template: escape &, <, >
export const escapeHtml = (str) => {
  return String(str).replace(/[&<>]/g, (s) => {
    return entityMap[s];
  });
}

// find tag end position. Tag can include something like title="<...>" and text in quotes must be ignored
export const findTagEnd = (str: string, from: number, tagName: string) => {
  let inTag = false;
  let level = 0;
  const tagLen = tagName.length;
  for (let ind = from, len = str.length; ind <= len - tagLen; ind++) {
    if (!inTag) {
      if (str.slice(ind, ind + tagLen) === tagName && level <= 1) {
        return ind;
      }
      
      if (str[ind] === '>') {
        level--;
      }

      if (str[ind] === '<') {
        level++;
      }
    }

    if (str[ind] === '"') {
      inTag = !inTag;
    }
  }

  return -1;
}

export const getStringParameter = (params: ParsedQuery<string>, name: string): string => {
  if (!params || !params[name]) {
    return '';
  }

  const param = params[name];
  if (Array.isArray(param)) {
    return param[0];
  }

  return param || '';
}
