import { WindowId } from '../constants/tee';
import { TAB_CODING_ID } from '../models';

export const openStandaloneWindow = (standAloneId: string, to: string) => {
  let { width, height, x, y } = getWindowMetadata(standAloneId);

  window.open(
   to as string,
    `${standAloneId}`,
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${width},height=${height},screenX=${x},screenY=${y}`
  );
}

export function getWindowMetadata(windowId: string) {
  let width = '1055';
  let height = '550';

  const savedDimensions = localStorage.getItem(`${windowId}:size`);
  if (savedDimensions) {
    try {
      const dimensions = JSON.parse(savedDimensions);
      width = dimensions.width;
      height = dimensions.height;
    } catch {
      // swallow error
    }
  } else if (windowId === WindowId.EncounterCodeBooks) {
    // try get current size of coding frame
    const codingPane = document.querySelector(`.${TAB_CODING_ID}`);
    if (codingPane) {
      width = codingPane.clientWidth.toString();
      height = codingPane.clientHeight.toString();
    }
  }

  let x = 0;
  let y = 0;
  const savedPosition = localStorage.getItem(`${windowId}:position`);
  if (savedPosition) {
    try {
      const position = JSON.parse(savedPosition);
      x = position.x;
      y = position.y;
    } catch {
      // swallow error
    }
  }
  return { width, height, x, y };
}
