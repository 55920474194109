/* eslint-disable @typescript-eslint/no-explicit-any */
import { orderBy } from '@progress/kendo-data-query';
import { IdDescriptionBase, ChoiceListsState } from '../../models/patientEncounter';
import { get, post } from '../../utils/net';
import { mapChoiceLists } from '../encounter/encounterMapping';
import { toServiceDate } from '../../utils/date';
import { ChoiceListDataItem, ChoiceListDefaultValue } from '../../scenes/ChoiceListsSettings/models/choiceListsSettings';
import { EncounterEntity } from '../../models/encounterEntity';
import { FooterField } from '../../models/encounterServiceEntity';

const baseURL = process.env.REACT_APP_API_PATH;
const codebooksURL = process.env.REACT_APP_TEE_CBK;
const groupingURL = process.env.REACT_APP_TEE_GRP;

const getFacilities = (selected?: string): Promise<IdDescriptionBase[]> => {
  const url = `${baseURL}/api/ChoiceLists/facilities${selected ? `?selected=${selected}` : ''}`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    const items = result.body || [];
    const facilities: IdDescriptionBase[] = items.map((facility) => {
      return {
        id: facility.Id,
        title: facility.Description,
        CurrentUserRole: facility.CurrentUserRole,
        // GUI-2192: use ViewId
        ViewId: facility.ViewId,
      };
    });

    return orderBy(facilities, [{ field: 'id', dir: 'asc' }]);
  });
};

// not used now. Was used to load all choices lists
const getChoiceListDataByFacility = (id: string | number, choiceLists: ChoiceListsState): Promise<ChoiceListsState> => {
  const url = `${baseURL}/api/choicelists/facility/${id}`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) =>
    mapChoiceLists(result.body, choiceLists)
  );
};

const providerChoiceListMapper = (item: any) => {
  return {
    id: item.Id,
    name: item.Description,
    service: item.Service || '',
    // GUI-2192: use ViewId
    ViewId: item.ViewId,
  }
}

// TODO: temp version until comboboxes are working: load all lists that must be initialized initially
const getInitialChoiceListDataByFacility = (facility: string, encounterType: string, encounter?: EncounterEntity): Promise<object> => {
  const choiceListsCalls: Promise<object>[] = [];

  choiceListsCalls.push(
    getChoiceListData(facility, 'EncounterTypes', encounterType)
      .then((results) => {
        return {
          name: 'encounterTypes',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    getChoiceListData(facility, 'PatientStatuses', encounter?.patientStatus)
      .then((results) => {
        return {
          name: 'patientStatuses',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    getChoiceListData(facility, 'Services', encounter?.service)
      .then((results) => {
        return {
          name: 'services',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    getChoiceListData(facility, 'FinancialClasses', encounter?.financialClass)
      .then((results) => {
        return {
          name: 'financialClasses',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    getChoiceListData(facility, 'RecordStatuses', encounter?.recordStatus)
      .then((results) => {
        return {
          name: 'recordStatuses',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    getChoiceListData(facility, 'Sexes', encounter?.sex)
      .then((results) => {
        return {
          name: 'sexes',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    getChoiceListData(facility, 'SourceOfAdmissions', encounter?.ofa)
      .then((results) => {
        return {
          name: 'ofa',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    // this list has its own mapping and multiple columns
    getChoiceListData(facility, 'Providers', encounter?.attendingMd, providerChoiceListMapper, 'name')
      .then((results) => {
        return {
          name: 'providers',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    getPayerFlags()
      .then((results) => {
        return {
          name: 'payerFlags',
          values: results
        };
      })
  );

  choiceListsCalls.push(
    getBillingNotes()
      .then((results) => {
        return {
          name: 'billingNotes',
          values: results
        };
      })
  );

  return Promise.all(choiceListsCalls)
    .then((results) => {
      return results;
    });
};

// use it when we rework comboboxes. load all lists that must be initialized initially
const getInitialChoiceListDataByFacilityFinal = (facility: string, encounterType: string): Promise<object> => {
  return getChoiceListData(facility, 'EncounterTypes', encounterType).then((encounterTypes) => {
    return {
      name: 'encounterTypes',
      values: encounterTypes,
    }
  });
};

const getChoiceListData = (facility: string, name: string, selected?: string, fieldsMapper?: (item: any) => any, sortField = 'id'): Promise<IdDescriptionBase[]> => {
  // Use skip and top fields in the future: skip=0&top=100&
  const url = `${baseURL}/api/choicelists/choicelist/${facility}/${name}?selected=${selected}`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    const items = result.body || [];
    const unsorted = items.map(item => {
      const newItem = fieldsMapper ? fieldsMapper(item) : {
        id: item.Id,
        title: item.Description,
        // GUI-2192: use ViewId
        ViewId: item.ViewId,
      }

      return newItem;
    })

    const sorted = sortField ? orderBy(unsorted, [{ field: sortField, dir: 'asc' }]) : unsorted;

    return sorted;
  });
};

const loadModifiersList = (approvedModifiersOnly: boolean, date?: string): Promise<IdDescriptionBase[]> => {
  const serviceDate = toServiceDate(date) || '';

  const url = `${codebooksURL}/CodeBooks/ListModifiers?date=${serviceDate}&full_details=false&hospital_approved_only=${approvedModifiersOnly ? "true" : "false"}`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return result.body.map(item => {
      return {
        id: item.code,
        title: item.short_description,
        type: item.book
      };
    })
  });
};

const mapChoiceListItemForChoiceListsSettings = (item) => {
  return {
    ...item,
    Id: item.Id || '',
    Description: item.Description || '',
    Name: item.Name || '',
    Service: item.Service || '',
    MappedToDefault: item.MappedToDefault || ''
  }
}

const getChoiceListDataForChoiceListsSettings = (facility: string, name: string): Promise<ChoiceListDataItem[]> => {
  const url = `${baseURL}/api/Facilities/${facility}/${name}?useOData=false&SortAsc.Id=1`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return result.body.Items.map(item => mapChoiceListItemForChoiceListsSettings(item));
  });
};

const getChoiceListDefaultValuesForChoiceListsSettings = (facility: string, name: string): Promise<ChoiceListDefaultValue[]> => {
  const url = `${groupingURL}/groupinginfo/choicelists/${name}/custom`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return result.body?.Items || [];
  });
};

const updateChoiceListDataForChoiceListsSettings = (facility: string, name: string, choiceList: ChoiceListDataItem): Promise<ChoiceListDataItem> => {
  const url = `${baseURL}/api/Facilities/${facility}/${name}`;

  const extraOptions = {
    useExactPath: true,
  };

  return post(url, choiceList, extraOptions).then((result) => {
    return mapChoiceListItemForChoiceListsSettings(result.body);
  });
};

const getPayerFlags = (): Promise<IdDescriptionBase[]> => {
  const url = `${groupingURL}/groupinginfo/ChoiceLists/payerflags/custom`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    const payerFlags: IdDescriptionBase[] = (result.body?.Items || []).map((flag) => {
      return {
        id: flag.Id,
        title: flag.Description,
        // GUI-2192: use ViewId
        ViewId: flag.ViewId,
      };
    });

    return orderBy(payerFlags, [{ field: 'id', dir: 'asc' }]);
  });
};

const loadFooterFieldsList = (grouperType: string, pricerType: string): Promise<FooterField[]> => {
  const url = `${groupingURL}/GroupingFields/${grouperType}/${pricerType || 'None'}`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

const getBillingNotes = (): Promise<IdDescriptionBase[]> => {
  const url = `${groupingURL}/groupinginfo/choiceLists/billingNotes/custom`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    const items = result.body?.Items || [];
    const billingNotes: IdDescriptionBase[] = items.map((note) => {
      return {
        id: note.Code,
        title: note.Description,
      };
    });

    return orderBy(billingNotes, [{ field: 'id', dir: 'asc' }]);
  });
};

export const choiceListsAPI = {
  getFacilities,
  getChoiceListDataByFacility,
  getChoiceListData,
  getInitialChoiceListDataByFacility,
  loadModifiersList,
  loadFooterFieldsList,
  getChoiceListDataForChoiceListsSettings,
  getChoiceListDefaultValuesForChoiceListsSettings,
  updateChoiceListDataForChoiceListsSettings
};
