import './Notification.less';

import * as React from 'react';
import { NotificationGroup as KNotificationGroup, NotificationGroupProps as KNotificationGroupProps } from '@progress/kendo-react-notification';

export const NotificationGroup = function render(props: KNotificationGroupProps) {
  const { className, ...otherProps } = props;
  return <KNotificationGroup
    className={`tc-notification-group ${className}`}
    {...otherProps}
  />;
}
