export enum ControlTypes {
  CodeBooks = 'codeBooks',
  CustomContentMaintenance = 'customContentMaintenance',
  References = 'references',
  Research = 'research',
}

export enum WindowId {
  CodeBooks = 'STANDALONE_CODEBOOKS',
  References = 'STANDALONE_REFERENCES',
  EncounterCodeBooks = 'ENCOUNTER_CODEBOOKS',
}
