import * as React from 'react';
import { History } from 'history';
import { ErrorDetails, UserState } from '../../models';
import { State } from '../reducers';
import { GlobalErrorDialog } from '../GlobalErrorDialog/GlobalErrorDialog';
import { ErrorPageContainer } from '../ErrorPage/ErrorPageContainer';

// Now we do not have the ability to recover from errors
// So I am using only local error from the state

interface ErrorBoundaryProps {
  globalState: State;
  history: History;
  forcedError?: ErrorDetails;
}
interface ErrorBoundaryState {
  error?: ErrorDetails;
  showErrorDialog: boolean;
  globalError?: ErrorDetails;
  stackCopied?: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: undefined,
      showErrorDialog: false,
      stackCopied: false,
    }
  }

  componentDidCatch(error: { message: string, name: string }, errorInfo: { componentStack: string }) {
    // Display fallback UI
    this.setState({
      error: {
        error: error.message,
        name: error.name,
        stack: errorInfo.componentStack,
      },
    });

    // You can also log the error to an error reporting service
    // TODO: telemetry service
  }

  componentDidMount() {
    window.onerror = ((message, source, lineno, colno, error) => {
      this.showGlobalError(message, error?.stack);
      return true;
    });
  }

  render() {
    if (this.state.error && this.state.error.error || this.state.globalError || this.props.forcedError) {
      let content;

      // You can render any custom fallback UI
      if (this.state.error && this.state.error.error) {
        content = <ErrorPageContainer
          customError={this.state.error}
          history={this.props.history} />;
      } else if (this.props.forcedError) {
        content = <ErrorPageContainer
          customError={this.props.forcedError}
          history={this.props.history} />;
      }

      return (
        <div>
          {content}
          {this.state.globalError && process.env.REACT_APP_QA_MODE === 'yes' && (
            <GlobalErrorDialog
              errorDetails={this.state.globalError}
              visible={this.state.showErrorDialog}
              onCopyClick={this.handleOnCopyError}
              toggleDialog={this.handleDismissError}
              stackCopied={this.state.stackCopied}
              globalState={this.props.globalState}
            />
          )}
          {this.state.globalError && process.env.REACT_APP_QA_MODE !== 'yes' && (
            <ErrorPageContainer
              customError={this.state.globalError}
              history={this.props.history} />
          )}
        </div>
      );
    }

    return this.props.children;
  }

  handleOnCopyError = () => {
    const dummyElement = document.createElement('textarea');
    document.body.appendChild(dummyElement);
    dummyElement.value = `${this.state.globalError.stack}\n\n State Data: \n ${JSON.stringify(this.props.globalState, null, 2)}`;
    dummyElement.select();
    document.execCommand('copy');
    document.body.removeChild(dummyElement);
    this.setState({ stackCopied: true });

    setTimeout(() => { this.setState({ stackCopied: false }) }, 3000);
  }

  handleDismissError = () => {
    this.setState({
      showErrorDialog: false
    })
  }

  showGlobalError = (message: any, error: any) => {
    this.setState({
      globalError: {
        error: message,
        name: message,
        stack: error,
      },
      showErrorDialog: true
    })
  }
}
