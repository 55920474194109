export interface FacilityEntity {
  ViewId: string;
  Id?: string | null;
  CMSCertificationNumber?: string | null;
  MedicareProviderNumber?: string | null;
  CcnType?: string | null;
  Mac?: string | null;
  Description?: string | null;
  Version?: string | null;
  IsActive?: boolean | null;

  // created and not saved item
  createdItemViewId?: string;
  // edited item
  inEdit?: boolean;
  // saving state for this item
  saving?: boolean;
  // MAC Name for sorting
  MacName?: string;
}

export interface FacilityTypeDropDownRow {
  Name: string;
  Description: string;
}

export const FacilityTypeDropDownModel = {
  columns: [
    { title: 'Description', fieldName: 'Description', className: 'facility-type-ddl-cell-description'},
  ],
  valueColumn: 'Name',
  textColumn: 'Description'
};

export interface CCNDropDownRow {
  Ccn: string;
  Type: string;
  State: string;
}

export interface CCNDropDownData {
  CMSCertificationNumberInfos: CCNDropDownRow[];
  Total: number;
}

export const CCNDropDownModelModel = {
  columns: [
    { title: 'Id', fieldName: 'Ccn', className: 'ccn-ddl-cell-id'},
    { title: 'Description', fieldName: 'Description', className: 'ccn-ddl-cell-description'},
    { title: 'State', fieldName: 'State', className: 'ccn-ddl-cell-state'},
  ],
  valueColumn: 'Ccn',
  textColumn: 'Ccn',
  popupWidth: '540px'
};

export interface CCNGetResponse {
  total: number;
  data: CCNDropDownRow[];
  errors: string[];
}

export interface MACDropDownRow {
  Id?: string;
  Mac: string;
  MacName: string;
  State: string;
}

export const MACDropDownModelModel = {
  columns: [
    { title: 'ID', fieldName: 'Mac', className: 'mac-ddl-cell-id'},
    { title: 'MAC', fieldName: 'MacName', className: 'mac-ddl-cell-name'},
    { title: 'State(s)', fieldName: 'State', className: 'mac-ddl-cell-state'},
  ],
  valueColumn: 'Id',
  textColumn: 'MacName'
};

export const areFacilitiesEqual = (first: FacilityEntity, second: FacilityEntity) => {
  return first.Id === second.Id
    && first.Description === second.Description
    && first.CcnType === second.CcnType
    && first.CMSCertificationNumber === second.CMSCertificationNumber
    && first.Mac === second?.Mac
    && first.IsActive === second?.IsActive
}

export const createEmptyFacilityEntity = () => {
  return {
    ViewId: '',
    Id: '',
    Description: '',
    CcnType: '',
    CMSCertificationNumber: '',
    MedicareProviderNumber: '',
    Mac: '',
    IsActive: false,    
  }
}
