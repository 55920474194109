import * as moment from 'moment';
import * as actionTypes from '../../../constants';
import { facilitySettingsAPI } from '../../../services/facility/settings';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';
import { addErrors, addErrorsAction } from '../../../actions/errors';
import { facilitiesAPI } from '../../../services/facilities';
import { FacilityEntity } from '../../Facility/models/facilities';
import { mapPreferencesTypetoString } from '../models/preferences';
import { groupingUtilsAPI } from '../../../services/grouping/utils';
import { IdDescriptionBase } from '../../../models/patientEncounter';
import { ErrorDetails } from '../../../models/ui';
import { parseFinancialClassErrors } from '../../FinancialClass/components/utils';
import { checkForConcurrencyError } from '../../../utils/checks';

const startLoading = () => ({
  payload: true,
  type: actionTypes.PREFERENCES_LOADING,
});

const finishLoading = () => ({
  payload: false,
  type: actionTypes.PREFERENCES_LOADING,
});

export const getRevenueCodesAction = () => (dispatch) => {
  dispatch(startLoading());

  return groupingUtilsAPI.getRevenueCodes()
    .then((revenueCodes) => {
      dispatch(getRevenueCodesCompleted(revenueCodes));
      dispatch(finishLoading());
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Cannot Get Revenue Codes');
      dispatch(addErrorsAction(errors));
      dispatch(getRevenueCodesCompleted([]));
      dispatch(finishLoading());
    });
};

const getRevenueCodesCompleted = (revenueCodes: IdDescriptionBase[]) => ({
  payload: { revenueCodes },
  type: actionTypes.PREFERENCES_SET_REVENUE_CODES,
});

export const facilityChangedAction = (facilityViewId: string) => (dispatch) => {
  // load selected facility. load preferences for the selected facility
  dispatch(startLoading());

  return facilitiesAPI.getFacility(facilityViewId)
    .then((facility) => {
      return facilitySettingsAPI.getAllFacilityFormPreferences(facilityViewId)
        .then((preferences) => {
          dispatch(facilityChanged(preferences, facilityViewId, facility, ''));
          dispatch(finishLoading());
        })
        .catch((reason) => {
          const errors = parseServiceErrors(reason, 'Cannot Load Preferences');
          dispatch(addErrors(errors));
          dispatch(facilityChanged(undefined, facilityViewId, facility, 'Cannot Load Preferences'));
          dispatch(finishLoading());
        });
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Cannot Load Facility');
      dispatch(addErrors(errors));
      dispatch(facilityChanged(undefined, facilityViewId, null, 'Cannot Load Facility'));
      dispatch(finishLoading());
    });
};

const facilityChanged = (preferences, facilityViewId: string, facility: FacilityEntity | null, error) => ({
  payload: { preferences, facilityViewId, facility, error },
  type: actionTypes.PREFERENCES_SELECT_FACILITY,
});

export const concurrencyFacilityVersionError = (errors: ErrorDetails[]) => ({
  payload: errors,
  type: actionTypes.FACILITY_CONCURRENCY_VERSION_ERROR,
});


export const preferencesTypeChangedAction = (preferencesType: number) => (dispatch) => {
  dispatch({
    payload: preferencesType,
    type: actionTypes.PREFERENCES_SELECT_PREFERENCES_TYPE,
  });
};

export const changePreferencesAction = (facilityViewId: string, preferencesType: number, preferences) => (dispatch) => {
  dispatch(startLoading());

  const preferencesTypeString = mapPreferencesTypetoString(preferencesType);

  return facilitySettingsAPI.updateFacilityFormPreferences(facilityViewId, preferencesTypeString, preferences)
    .then((updatedPreferences) => {
      dispatch(preferencesUpdated(preferencesType, updatedPreferences));
      dispatch(finishLoading());
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Cannot Update Preferences');
      if(checkForConcurrencyError(errors)) {
        dispatch(concurrencyFacilityVersionError(errors));
      } else {
        dispatch(addErrors(errors));
      }
      dispatch(finishLoading());
    });
}

const preferencesUpdated = (preferencesType: number, updatedPreferences) => ({
  payload: { preferencesType, updatedPreferences },
  type: actionTypes.PREFERENCES_CHANGE_PREFERENCE,
});

export const changeEditedFacilityAction = (facility) => (dispatch) => {
  dispatch({
    payload: facility,
    type: actionTypes.PREFERENCES_CHANGE_EDITED_FACILITY,
  });
};

export const changeEditedFacilityErrorTextAction = (errorTexts) => (dispatch) => {
  dispatch({
    payload: errorTexts,
    type: actionTypes.PREFERENCES_CHANGE_EDITED_FACILITY_ERROR_TEXT,
  });
};

export const startEditedFacilityValidationAction = (start) => (dispatch) => {
  dispatch({
    payload: start,
    type: actionTypes.PREFERENCES_START_EDITED_FACILITY_VALIDATION,
  });
};

export const confirmChangeFacilityActionInPreferences = (facility: FacilityEntity) => (dispatch) => {
  dispatch(startLoading());

  const error = 'Cannot Update Facility';

  const serviceFacility = {
    ...facility,
    Mac: facility.Mac || null,
  }

  return facilitiesAPI
    .updateFacility(serviceFacility)
    .then((result) => {
      // update storages with new facility content
      dispatch(confirmChangeFacilityInPreferences(result));
      dispatch(startEditedFacilityValidationAction(false));
      dispatch(finishLoading());
    })
    .catch((reason) => {
      // must not occur here because Id is not editable. I want to keep equal code for the future
      let duplicateError: ErrorDetails[] | null = null;
      if (reason.response && reason.response.status === 409) {
        const serverResponse = reason.response.body && reason.response.body.Message;
        if (serverResponse) {
          duplicateError = [{ error, description: serverResponse, time: moment.now() }];
        }
      }

      const errors = duplicateError || parseFinancialClassErrors(reason, error);
      const errorTexts = errors.map(singleError => singleError.description || '');

      if(checkForConcurrencyError(errors)) {
        dispatch(concurrencyFacilityVersionError(errors))
      } else {
        dispatch(addErrorsAction(errors));
        dispatch(changeEditedFacilityErrorTextAction(errorTexts));
      }

      dispatch(finishLoading());
    });
};

const confirmChangeFacilityInPreferences = (facility: FacilityEntity) => (dispatch) => {
  dispatch({
    payload: facility,
    type: actionTypes.PREFERENCES_EDITED_FACILITY_UPDATED,
  });
};
