import clone from 'lodash/clone';
import { CodeBooksModalOptions } from '../components/TEEModals/codeBooksModalActions';
import { ReferencesModalOptions } from '../components/TEEModals/referencesModalActions';
import * as actionTypes from '../constants';
import { ErrorDetails, UI, GridCodeType, TAB_PATIENT_ENCOUNTER, TAB_CODING } from '../models';
import { CodeGridColumnsInfo, CodeGridsColumns, DEFAULT_CODEGRIDS_COLUMNS } from '../models/columns';
import { TEEModalOpts } from '../models/teeModal';
import { UserPreferencesEntity } from '../models/user';
import { ConfirmDiscardDirtyChangesPayload } from '../scenes/CodingAdvice/actions/confirmDirtyChanges';
import { ResequenceRequestStatus } from '../models/resequence';
import { isEncounterClosedError } from '../utils/encounter';
import { checkForConcurrencyError } from '../utils/checks';


const createEmptyUIState = (): UI => {
  return {
    changingEncounter: false,
    codeBooksModal: { show: false, },
    codeGridsColumns: DEFAULT_CODEGRIDS_COLUMNS,
    codeGridsShowReorderWarning: true,
    codeGridsShowResizeWarning: true,
    confirmDiscardDirtyAdvice: false,
    confirmDiscardDirtyAdviceCancelCallback: undefined,
    confirmDiscardDirtyAdviceOkCallback: undefined,
    creatingAdvice: false,
    initializing: false,
    initialized: false,
    initializingError: false,
    deletingAdvice: false,
    editingAdvice: false,
    errors: [],
    isShowingPdxAnalysis: false,
    loadingAdvice: false,
    loadingCodebooks: false,
    loadingEncounter: false,
    loadingEncounterTemplates: false,
    needSaveEncounter: false,
    ignoreWarningsDuringSave: false,
    ignoreResequenceWarning: false,
    showPreventSaveDialog: false,
    needResequenceEncounterCodes: ResequenceRequestStatus.RESEQUENCE_REQUEST_NO_REQUEST,
    isCodeGridDragging: false,

    processingEncounter: false,
    promptDiscardDirtyAdvice: false,
    referencesModal: { show: false, },
    savingAdvice: false,
    savingEncounter: false,
    searchingAdvice: false,
    searchingEncounters: false,
    showOnlyApprovedModifiers: false,
    unhandledError: null,
    patientEncounterTab: TAB_PATIENT_ENCOUNTER,
    concurrencyHeartbeatError: false,
    concurrencyUpdateError: false,
    concurrencyFacilityVersionError: false,
  };
};

export const uiReducer = (state = createEmptyUIState(), action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE_BEGIN:
      return handleInitializeBegin(state);
    case actionTypes.INITIALIZE_COMPLETED:
      return handleInitializeCompleted(state);
    case actionTypes.INITIALIZE_FAIL:
      return handleInitializeError(state, action.payload);
    case actionTypes.CODEBOOKS_LIST_BEGIN:
      return handleCodebooksListBegin(state);
    case actionTypes.CODEBOOKS_LIST_COMPLETED:
      return handleCodebooksListCompleted(state);
    case actionTypes.CODEBOOKS_LIST_FAIL:
      return handleCodebooksListError(state);
    case actionTypes.ENCOUNTER_TEMPLATES_LIST_BEGIN:
    case actionTypes.ENCOUNTER_TEMPLATES_UPDATE_BEGIN:
    case actionTypes.ENCOUNTER_TEMPLATES_DELETE_BEGIN:
    case actionTypes.ENCOUNTER_TEMPLATES_CREATE_BEGIN:
      return handleEncounterTemplatesActionBegin(state);
    case actionTypes.ENCOUNTER_TEMPLATES_LIST_COMPLETED:
    case actionTypes.ENCOUNTER_TEMPLATES_UPDATE_COMPLETED:
    case actionTypes.ENCOUNTER_TEMPLATES_DELETE_COMPLETED:
    case actionTypes.ENCOUNTER_TEMPLATES_CREATE_COMPLETED:
      return handleEncounterTemplatesActionCompleted(state);
    case actionTypes.ENCOUNTER_TEMPLATES_LIST_ERROR:
    case actionTypes.ENCOUNTER_TEMPLATES_UPDATE_ERROR:
    case actionTypes.ENCOUNTER_TEMPLATES_DELETE_ERROR:
    case actionTypes.ENCOUNTER_TEMPLATES_CREATE_ERROR:
      return handleEncounterTemplatesActionError(state);
    case actionTypes.CCA_FETCH_ADVICE_BY_ID_BEGIN:
      return handleFetchAdviceByIdBegin(state);
    case actionTypes.CCA_FETCH_ADVICE_BY_ID_COMPLETED:
      return handleFetchAdviceByIdCompleted(state);
    case actionTypes.CCA_FETCH_ADVICE_BY_ID_ERROR:
      return handleFetchAdviceByIdError(state);
    case actionTypes.CCA_EDITING_ADVICE_BEGIN:
      return handleEditAdviceStart(state);
    case actionTypes.CCA_EDITING_ADVICE_END:
      return handleEditAdviceEnd(state);
    case actionTypes.CCA_CREATE_ADVICE:
      return handleCreateAdvice(state);
    case actionTypes.CCA_SAVE_ADVICE_BEGIN:
      return handleSaveAdviceBegin(state);
    case actionTypes.CCA_SAVE_ADVICE_COMPLETE:
      return handleSaveAdviceComplete(state);
    case actionTypes.CCA_SAVE_ADVICE_ERROR:
      return handleSaveAdviceError(state);
    case actionTypes.CCA_DELETE_ADVICE_BEGIN:
      return handleDeleteAdviceBegin(state);
    case actionTypes.CCA_DELETE_ADVICE_COMPLETED:
      return handleDeleteAdviceComplete(state);
    case actionTypes.CCA_DELETE_ADVICE_ERROR:
      return handleDeleteAdviceError(state);
    case actionTypes.CCA_SEARCH_ADVICE_BEGIN:
      return handleSearchAdviceBegin(state);
    case actionTypes.CCA_SEARCH_ADVICE_ERROR:
      return handleSearchAdviceError(state);
    case actionTypes.CCA_SEARCH_ADVICE_COMPLETED:
      return handleSearchAdviceCompleted(state);
    case actionTypes.CCA_CONFIRM_DISCARD_DIRTY_CHANGES:
      return handleConfirmDiscardDirtyChanges(state, action.payload);
    case actionTypes.CCA_PROMPT_DISCARD_DIRTY_CHANGES:
      return handlePromptDiscardDirtyChanges(state, action.payload);
    case actionTypes.CODEBOOKS_MODAL_SHOW:
    case actionTypes.CODEBOOKS_MODAL_HIDE:
      return handleCodeBooksModalChange(state, action.payload);
    case actionTypes.CODEBOOKS_MODAL_SET_REFS:
      return handleCodeBooksModalSetRefs(state, action.payload);
    case actionTypes.REFERENCES_MODAL_SHOW:
    case actionTypes.REFERENCES_MODAL_HIDE:
      return handleReferencesModalChange(state, action.payload);
    case actionTypes.REFERENCES_MODAL_SET_REFS:
      return handleReferencesModalSetRefs(state, action.payload);
    case actionTypes.PDX_ANALYZE_TOGGLE:
      return handlePdxAnalyzeToggle(state, action.payload);
    case actionTypes.CLEAR_GRID:
      return handleClearGrid(state, action.payload);
    case actionTypes.CODE_GRID_MOVE_TO_PDX:
      return handleCodeGridMoveToPdx(state);
    case actionTypes.UNHANDLED_LOGIC_ERROR:
      return handleLogicError(state, action.payload);
    case actionTypes.SEARCH_ENCOUNTER_FILTER_SEARCH_BEGIN:
      return handleSearchEncountersBegin(state);
    case actionTypes.SEARCH_ENCOUNTER_FILTER_SEARCH_ERROR:
      return handleSearchEncountersError(state, action.payload);
    case actionTypes.SEARCH_ENCOUNTER_FILTER_SEARCH_COMPLETED:
      return handleSearchEncountersCompleted(state);
    case actionTypes.ENCOUNTER_PROMPT_DISCARD_DIRTY_CHANGES:
    case actionTypes.CHOICE_LISTS_PROMPT_DISCARD_DIRTY_CHANGES:
      return handlePromptDiscardDirtyChanges(state, action.payload);
    case actionTypes.ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_UI:
    case actionTypes.SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES_UI:
      return handleConfirmDiscardDirtyChanges(state, action.payload);
    case actionTypes.CREATE_ENCOUNTER_BEGIN:
    case actionTypes.OPEN_ENCOUNTER_BY_ID_BEGIN:
    case actionTypes.GET_ENCOUNTER_BEGIN:
      return handleLoadEncounterBegin(state);
    case actionTypes.CREATE_ENCOUNTER_COMPLETED:
    case actionTypes.OPEN_ENCOUNTER_BY_ID_COMPLETED:
    case actionTypes.GET_ENCOUNTER_COMPLETED:
      return handleLoadEncounterCompleted(state, action.payload);
    case actionTypes.CREATE_ENCOUNTER_ERROR:
    case actionTypes.OPEN_ENCOUNTER_BY_ID_ERROR:
    case actionTypes.GET_ENCOUNTER_ERROR:
      return handleLoadEncounterError(state, action.payload);
    case actionTypes.SAVE_ENCOUNTER_BEGIN:
      return handleSaveEncounterBegin(state);
    case actionTypes.SAVE_ENCOUNTER_CHANGES_BEGIN:
      return handleChangeEncounterBegin(state);
    case actionTypes.SAVE_ENCOUNTER_COMPLETED:
      return handleSaveEncounterCompleted(state);
    case actionTypes.SAVE_ENCOUNTER_CHANGES_COMPLETED:
      return handleChangeEncounterCompleted(state, action.payload.heartbeatMode);
    case actionTypes.SAVE_ENCOUNTER_ERROR:
      return handleSaveEncounterError(state, action.payload);
    case actionTypes.SAVE_ENCOUNTER_CHANGES_ERROR:
      return handleChangeEncounterError(state, action.payload.errors, action.payload.heartbeatMode);
    case actionTypes.PROCESS_ENCOUNTER_BEGIN:
      return handleProcessEncounterBegin(state);
    case actionTypes.PROCESS_ENCOUNTER_COMPLETED:
      return handleProcessEncounterCompleted(state);
    case actionTypes.PROCESS_ENCOUNTER_ERROR:
      return handleProcessEncounterError(state, action.payload);
    case actionTypes.HEARTBEAT_ENCOUNTER_ERROR:
      return handleHeartbeatEncounterError(state, action.payload);
    case actionTypes.UI_ADD_ERRORS:
    case actionTypes.CLOSE_ENCOUNTER_ERROR:
    case actionTypes.GET_FACILITY_SETTINGS_FAIL:
      return handleAddErrors(state, action.payload);
    case actionTypes.UI_CLEAR_ERRORS:
      return handleClearErrors(state, action.payload);
    case actionTypes.UI_INIT_COLUMNS_SETTINGS:
      return handleInitColumnsSettings(state, action.payload);
    case actionTypes.UI_CHANGE_GRID_SETTINGS:
      return handleResizeGridColumn(state, action.payload);
    case actionTypes.UI_RESIZE_SHOW_WARNING:
      return handleChangeShowResizeWarning(state, action.payload);
    case actionTypes.UI_REORDER_SHOW_WARNING:
      return handleChangeShowReorderWarning(state, action.payload);
      /*  case actionTypes.SAVE_USER_PREFERENCE_BEGIN:
      return handleSaveUserPreference(state, action.payload); */
    case actionTypes.CHANGE_SHOW_ONLY_APPROVED_MODIFIERS:
      return handleChangeShowOnlyApprovedModifiers(state, action.payload);
    case actionTypes.SET_NEED_SAVE_ENCOUNTER:
      return handleChangeNeedSaveEncounter(state, action.payload);
    case actionTypes.SET_SHOW_PREVENT_SAVE:
      return handleChangeShowPreventSaveDialog(state, action.payload);
    case actionTypes.SET_NEED_RESEQUENCE_ENCOUNTER_CODES:
      return handleChangeNeedResequenceEncounterCodes(state, action.payload);
    case actionTypes.SET_CODE_GRID_DRAGGING:
      return handleSetCodeGridDragging(state, action.payload);
    case actionTypes.PATIENT_ENCOUNTER_CHANGE_TAB:
      return handlePatientEncounterTabChanged(state, action.payload);
    case actionTypes.SYSTEM_ANNOUNCEMENT_ERROR:
      return handleGetSystemAnnouncementsError(state, action.payload);
    case actionTypes.FACILITY_CONCURRENCY_VERSION_ERROR:
      return handleFacilityConcurrencyVersionError(state, action.payload);
    default:
      return state;
  }
};

const handleLogicError = (state: UI, error?: ErrorDetails | null): UI => {
  return {
    ...state,
    unhandledError: error ? {
      error: error.error,
      name: error.name,
      stack: error.stack,
    } : null,
  };
};

// #region Begin mutually exclusive action states

enum MutuallyExclusiveAdviceActionState {
  CreatingAdvice = 'creatingAdvice',
  DeletingAdvice = 'deletingAdvice',
  EditingAdvice = 'editingAdvice',
  LoadingAdvice = 'loadingAdvice',
  SavingAdvice = 'savingAdvice',
}

// These 5 advice states can only exclusively exist
const singleAdviceAction = (key?: MutuallyExclusiveAdviceActionState) => {
  const actionStates = {
    creatingAdvice: false,
    deletingAdvice: false,
    editingAdvice: false,
    loadingAdvice: false,
    savingAdvice: false,
  };
  if (!key) {
    return actionStates;
  }
  return {
    ...actionStates,
    [key]: true
  };
};

// *Almost* all are mutually exclusive...
const handleCreateAdvice = (state: UI): UI => {
  const adviceActionState = singleAdviceAction(MutuallyExclusiveAdviceActionState.CreatingAdvice);
  return {
    ...state,
    ...adviceActionState,
    editingAdvice: true,
  };
};

const handleDeleteAdviceBegin = (state: UI): UI => {
  const adviceActionState = singleAdviceAction(MutuallyExclusiveAdviceActionState.DeletingAdvice);
  return {
    ...state,
    ...adviceActionState
  };
};

const handleDeleteAdviceComplete = (state: UI): UI => {
  const adviceActionState = singleAdviceAction();
  return {
    ...state,
    ...adviceActionState
  };
};

const handleDeleteAdviceError = (state: UI): UI => {
  const adviceActionState = singleAdviceAction();
  return {
    ...state,
    ...adviceActionState
  };
};

const handleEditAdviceStart = (state: UI): UI => {
  const adviceActionState = singleAdviceAction(MutuallyExclusiveAdviceActionState.EditingAdvice);
  return {
    ...state,
    ...adviceActionState
  };
};

const handleEditAdviceEnd = (state: UI): UI => {
  const adviceActionState = singleAdviceAction();
  return {
    ...state,
    ...adviceActionState
  };
};

const handleFetchAdviceByIdBegin = (state: UI): UI => {
  const adviceActionState = singleAdviceAction(MutuallyExclusiveAdviceActionState.LoadingAdvice);
  return {
    ...state,
    ...adviceActionState
  };
};

const handleFetchAdviceByIdCompleted = (state: UI): UI => {
  const adviceActionState = singleAdviceAction();
  return {
    ...state,
    ...adviceActionState
  };
};

const handleFetchAdviceByIdError = (state: UI): UI => {
  const adviceActionState = singleAdviceAction();
  return {
    ...state,
    ...adviceActionState
  };
};

const handleSaveAdviceBegin = (state: UI): UI => {
  const adviceActionState = singleAdviceAction(MutuallyExclusiveAdviceActionState.SavingAdvice);
  return {
    ...state,
    ...adviceActionState
  };
};

const handleSaveAdviceComplete = (state: UI): UI => {
  const adviceActionState = singleAdviceAction();
  return {
    ...state,
    ...adviceActionState
  };
};

const handleSaveAdviceError = (state: UI): UI => {
  const adviceActionState = singleAdviceAction(MutuallyExclusiveAdviceActionState.EditingAdvice);
  return {
    ...state,
    ...adviceActionState
  };
};

// #endregion End mutually exclusive action states

const handleInitializeBegin = (state: UI): UI => {
  return {
    ...state,
    initializing: true,
    initialized: false,
    initializingError: false
  };
};

const handleInitializeCompleted = (state: UI): UI => {
  return {
    ...state,
    initialized: true,
    initializing: false,
    initializingError: false
  };
};

const handleInitializeError = (state: UI, payload: { errorReason: string, errorDetail: string }): UI => {
  return {
    ...state,
    initializing: false,
    initializingError: true,
    initializingErrorReason: payload.errorReason,
    initializingErrorDetail: payload.errorDetail
  };
};

const handleCodebooksListBegin = (state: UI): UI => {
  return {
    ...state,
    loadingCodebooks: true,
  };
};

const handleCodebooksListCompleted = (state: UI): UI => {
  return {
    ...state,
    loadingCodebooks: false,
  };
};

const handleCodebooksListError = (state: UI): UI => {
  return {
    ...state,
    loadingCodebooks: false,
  };
};

const handleEncounterTemplatesActionBegin = (state: UI): UI => {
  return {
    ...state,
    loadingEncounterTemplates: true,
  };
};

const handleEncounterTemplatesActionCompleted = (state: UI): UI => {
  return {
    ...state,
    loadingEncounterTemplates: false,
  };
};

const handleEncounterTemplatesActionError = (state: UI): UI => {
  return {
    ...state,
    loadingEncounterTemplates: false,
  };
};

const handleConfirmDiscardDirtyChanges = (state: UI, confirmed: boolean): UI => {
  return {
    ...state,
    confirmDiscardDirtyAdvice: confirmed,
    confirmDiscardDirtyAdviceCancelCallback: undefined,
    confirmDiscardDirtyAdviceOkCallback: undefined,
    promptDiscardDirtyAdvice: false,
  };
};

const handlePromptDiscardDirtyChanges = (state: UI, payload: ConfirmDiscardDirtyChangesPayload): UI => {
  return {
    ...state,
    confirmDiscardDirtyAdviceCancelCallback: payload.cancelCallback || undefined,
    confirmDiscardDirtyAdviceOkCallback: payload.okCallback || undefined,
    promptDiscardDirtyAdvice: true,
  };
};

const handleSearchAdviceBegin = (state: UI): UI => {
  return {
    ...state,
    searchingAdvice: true,
  };
};

const handleSearchAdviceError = (state: UI): UI => {
  return {
    ...state,
    searchingAdvice: false,
  };
};

const handleSearchAdviceCompleted = (state: UI): UI => {
  return {
    ...state,
    searchingAdvice: false,
  };
};

const handleCodeBooksModalChange = (state: UI, payload: CodeBooksModalOptions): UI => {
  if (!payload.show) {
    return {
      ...state,
      codeBooksModal: {
        ...state.codeBooksModal,
        visible: payload.visible,
        show: false
      }
    };
  }
  return {
    ...state,
    codeBooksModal: {
      ...payload,
      visible: true,
      show: true
    }
  };
};

const handleCodeBooksModalSetRefs = (state: UI, payload: TEEModalOpts): UI => {
  const codeBooksModal: CodeBooksModalOptions = clone(state.codeBooksModal);
  codeBooksModal.ref = payload.ref;
  codeBooksModal.windowRef = payload.windowRef;
  return {
    ...state,
    codeBooksModal
  };
};

const handleReferencesModalChange = (state: UI, payload: ReferencesModalOptions): UI => {
  if (!payload.show) {
    return {
      ...state,
      referencesModal: { show: false }
    };
  }
  return {
    ...state,
    referencesModal: payload,
  };
};

const handleReferencesModalSetRefs = (state: UI, payload: TEEModalOpts): UI => {
  const referencesModal: ReferencesModalOptions = clone(state.referencesModal);
  referencesModal.ref = payload.ref;
  referencesModal.windowRef = payload.windowRef;
  return {
    ...state,
    referencesModal
  };
};

const handlePdxAnalyzeToggle = (state: UI, isShowing: boolean): UI => {
  return {
    ...state,
    isShowingPdxAnalysis: isShowing
  };
};

const handleClearGrid = (state: UI, gridCodeType: GridCodeType): UI => {
  if (gridCodeType !== GridCodeType.DIAGNOSES) {
    return state;
  }

  // turn off pdx analysis after grid clearing
  return {
    ...state,
    isShowingPdxAnalysis: false
  };
};

const handleCodeGridMoveToPdx = (state: UI): UI => {
  return {
    ...state,
    isShowingPdxAnalysis: false
  };
};

const handleSearchEncountersBegin = (state: UI): UI => {
  return {
    ...state,
    searchingEncounters: true
  };
};

const handleSearchEncountersCompleted = (state: UI): UI => {
  return {
    ...state,
    searchingEncounters: false
  };
};

const handleSearchEncountersError = (state: UI, errors: ErrorDetails[]): UI => {
  return {
    ...state,
    errors: errors && errors.length > 0 ? [...state.errors, ...errors] : state.errors,
    searchingEncounters: false
  };
};

const handleLoadEncounterBegin = (state: UI): UI => {
  return {
    ...state,
    loadingEncounter: true,
    needSaveEncounter: false
  };
};

const handleLoadEncounterCompleted = (state: UI, payload: { openEncounterToCodingTab?: boolean }): UI => {
  const newEncounterTab = payload.openEncounterToCodingTab ? TAB_CODING : TAB_PATIENT_ENCOUNTER;

  return {
    ...state,
    concurrencyUpdateError: false,
    concurrencyHeartbeatError: false,
    loadingEncounter: false,
    isCodeGridDragging: false,
    patientEncounterTab: payload.openEncounterToCodingTab === undefined ? state.patientEncounterTab : newEncounterTab,
  };
};

const handleLoadEncounterError = (state: UI, errors: ErrorDetails[]): UI => {
  return {
    ...state,
    errors: errors && errors.length > 0 ? [...state.errors, ...errors] : state.errors,
    loadingEncounter: false,
    isCodeGridDragging: false
  };
};

const handleChangeEncounterBegin = (state: UI): UI => {
  return {
    ...state,
    changingEncounter: true,
  };
};

const handleChangeEncounterCompleted = (state: UI, heartbeatMode = false): UI => {
  // no need to update flags
  if (heartbeatMode) {
    return state;
  }

  return {
    ...state,
    changingEncounter: false,
  };
};

const handleChangeEncounterError = (state: UI, errors: ErrorDetails[], heartbeatMode = false): UI => {
  const concurrencyConflict = errors[0].description === 'Conflict';

  return {
    ...state,
    concurrencyUpdateError: concurrencyConflict,
    concurrencyHeartbeatError: false,
    errors: !isEncounterClosedError(errors[0].description) && errors && errors.length > 0 ? [...state.errors, ...errors] : state.errors,
    changingEncounter: heartbeatMode ? state.changingEncounter : false,
  };
};

const handleSaveEncounterBegin = (state: UI): UI => {
  return {
    ...state,
    savingEncounter: true,
    needSaveEncounter: false,
    ignoreWarningsDuringSave: false,
    ignoreResequenceWarning: false,
    showPreventSaveDialog: false
  };
};

const handleSaveEncounterCompleted = (state: UI): UI => {
  return {
    ...state,
    savingEncounter: false,
  };
};

const handleSaveEncounterError = (state: UI, errors: ErrorDetails[]): UI => {
  const concurrencyConflict = errors[0].description === 'Conflict';

  return {
    ...state,
    concurrencyUpdateError: concurrencyConflict,
    concurrencyHeartbeatError: false,
    errors: errors && errors.length > 0 ? [...state.errors, ...errors] : state.errors,
    savingEncounter: false,
  };
};

const handleProcessEncounterBegin = (state: UI): UI => {
  return {
    ...state,
    processingEncounter: true,
  };
};

const handleProcessEncounterCompleted = (state: UI): UI => {
  return {
    ...state,
    processingEncounter: false,
    // clear resequence request status to show button for new Result
    needResequenceEncounterCodes: state.needResequenceEncounterCodes === ResequenceRequestStatus.RESEQUENCE_REQUEST_COMPLETED
      ? ResequenceRequestStatus.RESEQUENCE_REQUEST_NO_REQUEST : state.needResequenceEncounterCodes
  };
};

const handleProcessEncounterError = (state: UI, errors: ErrorDetails[]): UI => {
  const concurrencyConflict = errors[0].description === 'Conflict';

  return {
    ...state,
    concurrencyUpdateError: concurrencyConflict,
    concurrencyHeartbeatError: false,
    errors: errors && errors.length > 0 ? [...state.errors, ...errors] : state.errors,
    processingEncounter: false,
  };
};

const handleHeartbeatEncounterError = (state: UI, errors: ErrorDetails[]): UI => {
  const concurrencyConflict = errors[0].description === 'Conflict';

  return {
    ...state,
    concurrencyUpdateError: false,
    concurrencyHeartbeatError: concurrencyConflict,
    errors: errors && errors.length > 0 ? [...state.errors, ...errors] : state.errors,
  };
};

const handleAddErrors = (state: UI, errors: ErrorDetails[]): UI => {
  return {
    ...state,
    errors: [...state.errors, ...errors]
  };
};

const handleClearErrors = (state: UI, options: { index?: number, type?: string }): UI => {
  if (options && options.index !== undefined) {
    const newErrors: ErrorDetails[] = [];
    for (let ind = 0, len = state.errors.length; ind < len; ind++) {
      if (ind !== options.index) {
        newErrors.push(state.errors[ind]);
      }
    }

    return {
      ...state,
      errors: newErrors
    };
  }

  return {
    ...state,
    errors: []
  };
};

const handleInitColumnsSettings = (state: UI, columnsInfo: CodeGridsColumns) => {
  return {
    ...state,
    codeGridsColumns: columnsInfo
  };
};

const handleResizeGridColumn = (state: UI, payload: { gridKey: string, gridSettings: CodeGridColumnsInfo }) => {
  return {
    ...state,
    codeGridsColumns: {
      ...state.codeGridsColumns,
      [payload.gridKey]: payload.gridSettings
    }
  };
};

const handleChangeShowResizeWarning = (state: UI, payload: boolean) => {
  return {
    ...state,
    codeGridsShowResizeWarning: payload
  };
};

const handleChangeShowReorderWarning = (state: UI, payload: boolean) => {
  return {
    ...state,
    codeGridsShowReorderWarning: payload
  };
};

const handleSaveUserPreference = (state: UI, payload: UserPreferencesEntity) => {
  return {
    ...state,
    preference: payload
  };
};

const handleChangeShowOnlyApprovedModifiers = (state: UI, payload: boolean) => {
  return {
    ...state,
    showOnlyApprovedModifiers: payload
  }
}

const handleChangeNeedSaveEncounter = (state: UI, payload: { needSave: boolean, ignoreWarnings: boolean, ignoreResequenceWarning: boolean }) => {
  return {
    ...state,
    needSaveEncounter: payload.needSave,
    ignoreWarningsDuringSave: payload.ignoreWarnings,
    ignoreResequenceWarning: payload.ignoreResequenceWarning,
  }
}

const handleChangeShowPreventSaveDialog = (state: UI, payload: boolean) => {
  return {
    ...state,
    showPreventSaveDialog: payload,
    needSaveEncounter: payload ? false : state.needSaveEncounter
  }
}

const handleChangeNeedResequenceEncounterCodes = (state: UI, payload: ResequenceRequestStatus) => {
  return {
    ...state,
    needResequenceEncounterCodes: payload
  }
}

const handleSetCodeGridDragging = (state: UI, payload: boolean) => {
  return {
    ...state,
    isCodeGridDragging: payload
  }
}

const handlePatientEncounterTabChanged = (state: UI, selectedTab: number) => {
  return {
    ...state,
    patientEncounterTab: selectedTab
  }
}

const handleGetSystemAnnouncementsError = (state: UI, errors: ErrorDetails[]) => {
  return {
    ...state,
    concurrencyUpdateError: false,
    concurrencyHeartbeatError: false,
    errors: errors && errors.length > 0 ? [...state.errors, ...errors] : state.errors
  }
}

const handleFacilityConcurrencyVersionError = (state: UI, errors: ErrorDetails[]) => {
  const concurrencyError = checkForConcurrencyError(errors);
  return {
    ...state,
    concurrencyFacilityVersionError: concurrencyError,
  }
}
