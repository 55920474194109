import * as React from 'react';

interface UpdatePollerProps {
  updateFacilities: () => void;
}

// 1 minute: this Poller refreshes access token during facilities loading
class UpdatePoller extends React.Component<UpdatePollerProps, {}> {
  constructor(props: UpdatePollerProps) {
    super(props);

    this.onUpdateTimerTick = this.onUpdateTimerTick.bind(this);
  }

  private updateTimerID: string | null = null;

  componentDidMount() {
    // uncomment to continue facilities updating.
    // TODO: accessToken can be not updated too
    // this.updateTimerID = window.subscribeWorkerTimer(this.onUpdateTimerTick, 60);
  }

  componentWillUnmount() {
    if (this.updateTimerID) {
      window.unsubscribeWorkerTimer(this.updateTimerID);
    }
  }

  render() {
    return null;
  }

  private onUpdateTimerTick() {
    this.props.updateFacilities();
  }
}

export default UpdatePoller;
