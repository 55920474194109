import * as moment from 'moment';
import { ErrorDetails } from '../../models/ui';

export const parseServiceErrors = (reason: Error, uiErrorText: string): ErrorDetails[] => {
  let errorText = uiErrorText;
  // use backend message for unauthorized error
  const responseObject = reason as unknown as { status: number, response: { body: { Message: string, IsInterfaceError: boolean, isInterfaceError: boolean, title: string }}};
  const IsInterfaceError = responseObject?.response?.body?.IsInterfaceError || responseObject?.response?.body?.isInterfaceError;
  if (IsInterfaceError) {
    errorText = responseObject?.response?.body?.Message || responseObject?.response?.body?.title;
  } else if (responseObject?.status === 401 && responseObject?.response?.body?.Message) {
    errorText = responseObject?.response?.body?.Message;
  } else if (reason && reason.message) {
    try {
      const errObjInMsg = reason.message;
      const errObj = JSON.parse(errObjInMsg);
      errorText = errObj.Message;
    } catch (err) {
      // incorrect format - if it is string - use it  otherwise use default text
      if (typeof reason.message === 'string') {
        errorText = reason.message;
      }
    }
  } else if (responseObject && responseObject.response) {
    try {
      const errObj = JSON.parse(responseObject.response.text);
      errorText = errObj.Message;
    }catch (err) {
      if (typeof responseObject.response.body.Message === 'string') {
        errorText = responseObject.response.body.Message;
      }
    }
  }

  return [{ error: uiErrorText, description: errorText, time: moment.now() }];
};

export const parseTraceId = (responseObject): string | undefined => {
  const traceId = responseObject?.response?.body?.TraceId || responseObject?.response?.body?.traceId
    || responseObject?.TraceId || responseObject?.traceId  || undefined;
  return traceId;
}

export const isSessionOutdatedError = (description?: string) => {
  if (description === 'ConcurrencyException') {
    return true;
  }

  if (description?.indexOf('Expected session version') === 0 && description?.indexOf("doesn't match retrieved version") !== -1) {
    return true;
  }

  return false;
}

export const parseSessionId = (responseObject): string | undefined => {
  const sessionId = responseObject?.response?.body?.SessionId || responseObject?.response?.body?.sessionId
    || responseObject?.SessionId || responseObject?.sessionId  || undefined;
  return sessionId;
}