import { connect } from 'react-redux';
import { clearErrorsAction } from '../../actions/errors';
import { clearApplicationAction, logoutUserAction } from '../../actions/logout';
import { State } from '../../reducers';
import { ChoiceListsState } from '../../models/patientEncounter';
import { buildNewEncounterChoiceListsAction } from '../../scenes/Encounter/actions/buildChoiceLists';
import { setUserPreferenceAction } from '../../actions/setUserPreference';
import { AppWrapper } from './AppWrapper';
import { getFacilitiesChoiceListAction } from '../../actions/choiceLists';
import { dismissAnnouncementAction } from '../../actions/announcements';
import { getRecoveredEncountersAction } from '../../scenes/RecoveredEncounters/actions/recoveredEncountersAction';

const mapStateToProps = (state: State) => ({
  errors: state.ui.errors,
  choiceLists: state.choiceLists,
  user: state.user,
  b2c: state.b2c,
  hasActiveRequest: state.netActivity.hasActiveRequest,
  encounter: state.encounter,
  announcements: state.systemAnnouncements,
  recoveredEncounters: state.recoveredEncounters,
});

const mapDispatchToProps = (dispatch) => ({
  clearApplication: () => dispatch(clearApplicationAction()),
  logoutUser: (accountIdentifier: string, unsavedChanges?: boolean) => dispatch(logoutUserAction(accountIdentifier, unsavedChanges)),
  clearErrors: () => dispatch(clearErrorsAction()),
  buildNewEncounterChoiceLists: (choiceLists: ChoiceListsState, facility: string) => dispatch(buildNewEncounterChoiceListsAction(choiceLists, facility)),
  saveUserPreference: (preference: string, value: any ) => dispatch(setUserPreferenceAction(preference, value)),
  getFacilitiesChoiceList: () => dispatch(getFacilitiesChoiceListAction(false, 'loadingNewEncounterFacilities')),  // do not clear previous list on error
  dismissAnnouncement: (id: string) => dispatch(dismissAnnouncementAction(id)),
  refreshEncounterList: () => dispatch(getRecoveredEncountersAction(true)),
});

export const AppWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
