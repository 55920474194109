import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import ReduxThunk from 'redux-thunk';
import { createStateSyncMiddleware, initMessageListener, initStateWithPrevTab } from 'redux-state-sync';
import LogRocket from 'logrocket';
import logic from './logic';
import * as actionTypes from './constants';
import { createRootReducer, State } from './reducers';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
  }
}

export interface LogicDependencies {
  uh: {
    msg: string;
  };
}

export interface LogicLifecycleParams extends LogicDependencies {
  action: {
    payload: any;
    type: string;
  };
  cancelled$: any;
  getState: () => State;
}

const deps: any = {
  // injected dependencies for logic layer
  uh: { msg: 'hi' }
};

const reduxStateSyncConfig = {
  whitelist: [
    actionTypes.USER_ACTIVITY_UPDATE_LAST_ACTIVITY,
    actionTypes.USER_ACTIVITY_UPDATE_TIMEOUT_DIALOG,
    //  actionTypes.UPDATE_USER_COMPLETED,
    actionTypes.LOGOUT_CURRENT_USER,
  ],
};

const reduxStateSyncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig);

const logicMiddleware = createLogicMiddleware(logic, deps);
const middlewares = [ReduxThunk, logicMiddleware, reduxStateSyncMiddleware,
  LogRocket.reduxMiddleware({
    stateSanitizer: (state) => {
      const ageValue = state.encounter.inProgress.ageInYears.split(' ');
      return {
        ...state,
        encounter: {
          ...state.encounter,
          inProgress: {
            ...state.encounter.inProgress,
            medicalRecordNumber: '***PHI DATA HIDDEN***',
            firstName: '***PHI DATA HIDDEN***',
            lastName: '***PHI DATA HIDDEN***',
            middleInitial: '***PHI DATA HIDDEN***',
            dateOfBirth: '***PHI DATA HIDDEN***',
            accountNumber: '***PHI DATA HIDDEN***',
            facility: '***PHI DATA HIDDEN***',
            attendingMd: '***PHI DATA HIDDEN***',
            ageInYears: ageValue[0] < 89 ? state.encounter.inProgress.ageInYears : '89 y',
            diagnoses: {
              ...state.encounter.inProgress.diagnoses,
              codes: state.encounter.inProgress.diagnoses.codes.map(code => {
                return {
                  ...code,
                  provider: '***PHI DATA HIDDEN***',
                }
              })
            },
            inProcedures: {
              ...state.encounter.inProgress.inProcedures,
              codes: state.encounter.inProgress.inProcedures.codes.map(code => {
                return {
                  ...code,
                  provider: '***PHI DATA HIDDEN***',
                }
              })
            },
            outProcedures: {
              ...state.encounter.inProgress.outProcedures,
              codes: state.encounter.inProgress.outProcedures.codes.map(code => {
                return {
                  ...code,
                  provider: '***PHI DATA HIDDEN***',
                }
              })
            },
          },
          saved: {
            ...state.encounter.saved,
            medicalRecordNumber:'***PHI DATA HIDDEN***',
            firstName: '***PHI DATA HIDDEN***',
            lastName: '***PHI DATA HIDDEN***',
            middleInitial: '***PHI DATA HIDDEN***',
            dateOfBirth: '***PHI DATA HIDDEN***',
            accountNumber: '***PHI DATA HIDDEN***',
            facility: '***PHI DATA HIDDEN***',
            attendingMd: '***PHI DATA HIDDEN***',
            ageInYears: ageValue[0] < 89 ? state.encounter.saved.ageInYears : '89 y',
            diagnoses: {
              ...state.encounter.inProgress.diagnoses,
              codes: state.encounter.inProgress.diagnoses.codes.map(code => {
                return {
                  ...code,
                  provider: '***PHI DATA HIDDEN***',
                }
              })
            },
            inProcedures: {
              ...state.encounter.saved.inProcedures,
              codes: state.encounter.saved.inProcedures.codes.map(code => {
                return {
                  ...code,
                  provider: '***PHI DATA HIDDEN***',
                }
              })
            },
            outProcedures: {
              ...state.encounter.saved.outProcedures,
              codes: state.encounter.saved.outProcedures.codes.map(code => {
                return {
                  ...code,
                  provider: '***PHI DATA HIDDEN***',
                }
              })
            },
          },
          savedServiceEntity: {
            ...state.encounter.savedServiceEntity,
            AccountNumber: '***PHI DATA HIDDEN***',
            DateOfBirth: '***PHI DATA HIDDEN***',
            Facility: '***PHI DATA HIDDEN***',
            FirstName: '***PHI DATA HIDDEN***',
            LastName: '***PHI DATA HIDDEN***',
            MedicalRecordNumber: '***PHI DATA HIDDEN***',
            MiddleInitial: '***PHI DATA HIDDEN***',
            Provider: '***PHI DATA HIDDEN***',
            PatientAge: ageValue[0] < 89 ? state.encounter.saved.ageInYears : '89 y',
            Diagnoses: {
              ...state.encounter.savedServiceEntity.Diagnoses.map(code => {
                return {
                  ...code,
                  Provider: '***PHI DATA HIDDEN***',
                }

              })
            },
            InProcedures: {
              ...state.encounter.savedServiceEntity.InProcedures.map(code => {
                return {
                  ...code,
                  Provider: '***PHI DATA HIDDEN***',
                }
              }),
            },
            OutProcedures: {
              ...state.encounter.savedServiceEntity.OutProcedures.map(code => {
                return {
                  ...code,
                  Provider: '***PHI DATA HIDDEN***',
                }
              }),
            },
          }
        },
        recoveredEncounters: {
          encounters: (state.recoveredEncounters.length > 0 && state.recoveredEncounters.encounters.length > 0) ? {
            ...state.recoveredEncounters.encounters.map(encounter => {
              return {
                ...encounter,
                AccountNumber: '***PHI DATA HIDDEN***',
                DateOfBirth: '***PHI DATA HIDDEN***',
                FacilityId: '***PHI DATA HIDDEN***',
                FirstName: '***PHI DATA HIDDEN***',
                LastName: '***PHI DATA HIDDEN***',
                MedicalRecordNumber: '***PHI DATA HIDDEN***',
                MiddleInitial: '***PHI DATA HIDDEN***',
                PatientAge: ageValue[0] < 89 ? state.encounter.saved.ageInYears : '89 y',
              }
            }),
          } :
            ''
        },
        searchEncounters: {
          encounters: state.searchEncounters.encounters.length > 0 ? {
            ...state.searchEncounters.encounters.map(encounter => {
              return {
                ...encounter,
                medicalRecordNumber:'***PHI DATA HIDDEN***',
                firstName: '***PHI DATA HIDDEN***',
                lastName: '***PHI DATA HIDDEN***',
                middleInitial: '***PHI DATA HIDDEN***',
                dateOfBirth: '***PHI DATA HIDDEN***',
                accountNumber: '***PHI DATA HIDDEN***',
                facility: '***PHI DATA HIDDEN***',
                attendingMd: '***PHI DATA HIDDEN***',
                ageInYears: ageValue[0] < 89 ? state.encounter.saved.ageInYears : '89 y',
                inProcedures: {
                  ...state.encounter.saved.inProcedures,
                  codes: state.encounter.saved.inProcedures.codes.map(code => {
                    return {
                      ...code,
                      provider: '***PHI DATA HIDDEN***',
                    }
                  })
                },
                outProcedures: {
                  ...state.encounter.saved.outProcedures,
                  codes: state.encounter.saved.outProcedures.codes.map(code => {
                    return {
                      ...code,
                      provider: '***PHI DATA HIDDEN***',
                    }
                  })
                },
              }
            }),
          } :
            '',
          filter: '***PHI DATA HIDDEN***',
        },
        router: {
          ...state.router,
          location: {
            ...state.router.location,
            state: {
              ...state.router.location.state,
              mrn: '***PHI DATA HIDDEN***',
              facility: '***PHI DATA HIDDEN***'
            }
          }
        }
        /* TODO: Is this needed?
        encounterSnapshot: {
          ...state.encounterSnapshot,
            snapshot: {
              Encounter: {
              ...state.encounterSnapshot.snapshot.Encounter,
              AccountNumber: '***PHI DATA HIDDEN***',
              DateOfBirth: '***PHI DATA HIDDEN***',
              Facility: '***PHI DATA HIDDEN***',
              FirstName: '***PHI DATA HIDDEN***',
              LastName: '***PHI DATA HIDDEN***',
              MedicalRecordNumber: '***PHI DATA HIDDEN***',
              MiddleInitial: '***PHI DATA HIDDEN***',
              Provider: '***PHI DATA HIDDEN***',
              InProcedures: {
                ...state.encounter.savedServiceEntity.InProcedures.map(code => {
                  return {
                    ...code,
                    Provider: '***PHI DATA HIDDEN***',
                  }
                }),
              },
              OutProcedures: {
                ...state.encounter.savedServiceEntity.OutProcedures.map(code => {
                  return {
                    ...code,
                    Provider: '***PHI DATA HIDDEN***',
                  }
                }),
              },
            }
          }
        } */
      }
    },
    actionSanitizer: (action) => {
      return {
        ...action,
        payload: '***PAYLOAD DATA SANITIZED***'
      }
    }
  })];

if (process.env.NODE_ENV === `development` || process.env.NODE_ENV === `local`) {
  // const { logger } = require('redux-logger');
  // middlewares.push(logger);
}

let cccompose = compose;
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === `local`) {
  cccompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

export const store: Store<State> = createStore(
  createRootReducer(history),
  cccompose(
    applyMiddleware(
      ...middlewares,
      routerMiddleware(history),
    ),
  )
);

// this is used to pass store.dispatch to the message listener
initMessageListener(store);
