/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CodingAdviceAPI } from '../../models';

interface TEEControlProps {
  className?: string;
  options: CodingAdviceAPI;
}

export class TEEControl extends React.PureComponent<TEEControlProps> {
  render() {
    const className = this.props.className
      ? `${this.props.className} full-height`
      : 'full-height';
    return (
      <div className={className}>{this.props.children}</div>
    );
  }

  tcClose = () => {
    const el = this.props.options.el as HTMLElement;
    const specificElement = document.getElementById(el.id);
    ReactDOM.unmountComponentAtNode(specificElement as HTMLElement);
  }

  // FIXME: finish TEE control BaseView stubs as/if/when necessary
  tcOff = (event: string, callback: any) => {
    //
  }

  tcOn = (event: string, callback: any, doOnce?: boolean) => {
    //
  }

  tcTrigger = (event: string, options: any) => {
    //
  }

  tcVisible = (isVisible?: boolean) => {
    //
  }
}
