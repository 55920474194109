import './idleTimer.less';

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactIdleTimer from 'react-idle-timer';
import * as moment from 'moment';

import { getAccountIdentifier } from '../../authProvider';
import { isChildWindow } from '../../utils/url';
import { WAIT_TICKS, /* IDLE_TIMEOUT, */ THROTTLE_TIMEOUT, IDLE_TIMER_TICK_TIMEOUT, SECOND_TIMEOUT } from '../../models/userActivity';


import { Button, Dialog, DialogActionsBar } from '..';
import { SystemPreferencesState } from '../../scenes/SystemPreferences/models/systemPreferencesState';

interface IdleTimerProps extends RouteComponentProps {
  redirectLocation: string;
  idleTimeoutInSeconds: number;
  countdownTicks: number;

  lastActivity: string;
  showTimeoutDialog: boolean;
  showTimeoutDialogFrom?: string;
  shownTimeout: number;
  unsavedChanges: boolean;
  systemPreferences: SystemPreferencesState;

  clearApplication: () => void;
  logoutUser: (accountIdentifier: string, unsavedChanges?: boolean) => void;
  updateUserActivity: (lastActivity: string) => void;
  updateTimeoutDialog: (showTimeoutDialog: boolean, shownTimeout: number, startTime?: string) => void;
}

export default class IdleTimer extends React.Component<IdleTimerProps, {}> {
  public static defaultProps = {
    countdownTicks: WAIT_TICKS,
    idleTimeoutInSeconds: 0, // not used
    redirectLocation: '/',
  };

  constructor(props: IdleTimerProps) {
    super(props);
    this.onIdle = this.onIdle.bind(this);
    this.onAction = this.onAction.bind(this);
    this.onTimerTick = this.onTimerTick.bind(this);
    this.onNoClick = this.onNoClick.bind(this);
    this.onYesClick = this.onYesClick.bind(this);
    this.clearTimers = this.clearTimers.bind(this);
    this.onUsualTimerTick = this.onUsualTimerTick.bind(this);

    this.state = {};

  }

  private timerID: NodeJS.Timer;
  private serviceWorkerTimerID: string | null = null;
  private isTimerHandlingActive = false;
  private reactIdleTimer: ReactIdleTimer | null = null;
  private activeClosing = false;

  componentDidUpdate(prevProps: IdleTimerProps) {
    // reset IdleTimer after user activity on any tab
    if (this.reactIdleTimer && prevProps.lastActivity !== this.props.lastActivity || prevProps.systemPreferences.generalPreferences.IdleTime !== this.props.systemPreferences.generalPreferences.IdleTime) {
      this.reactIdleTimer.reset();
    }

    // close all tabs on timeout
    if (this.props.shownTimeout <= 0) {
      this.onNoClick();
    }

    // restart timer if it was stopped on the current tab (tab reload)
    if (!isChildWindow() && this.props.showTimeoutDialog && this.props.shownTimeout > 0 && (!this.timerID || !!this.serviceWorkerTimerID)) {
      if (!this.timerID) {
        this.timerID = setTimeout(this.onUsualTimerTick, IDLE_TIMER_TICK_TIMEOUT);
      }
      if (!this.serviceWorkerTimerID) {
        this.serviceWorkerTimerID = window.subscribeWorkerTimer(this.onTimerTick, 1);
      }
    }
  }

  render() {
    return (
      <div className="idle-timer-dialog">
        <div className="confirmation-dialog">
          {this.props.showTimeoutDialog && !this.props.systemPreferences.loadingGeneralPreferences &&
            <Dialog title="User timeout" onClose={this.onYesClick}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                You haven&apos;t taken any actions in a while.
                Are you still here?
              </p>
              <DialogActionsBar>
                <Button onClick={ this.onNoClick}>
                  {isChildWindow() ? 'No, close' : 'No, sign out'}
                </Button>
                <Button primary onClick={this.onYesClick}>
                  Yes ({this.props.shownTimeout})
                </Button>
              </DialogActionsBar>
            </Dialog>}
        </div>

        <ReactIdleTimer
          ref={(ref) => { this.reactIdleTimer = ref; }}
          timeout={this.props.systemPreferences.calculatedIdleTime}
          onIdle={this.onIdle}
          onAction={this.onAction}
          throttle={THROTTLE_TIMEOUT}
        />
      </div>
    );
  }

  private clearTimers() {
    clearTimeout(this.timerID);
    if (this.serviceWorkerTimerID) {
      window.unsubscribeWorkerTimer(this.serviceWorkerTimerID);
      this.serviceWorkerTimerID = null;
    }
  }

  private onIdle() {
    // only main window must start dialog showing
    if (!isChildWindow()) {
      if (!this.props.showTimeoutDialog) {
        const startTime = moment().format('YYYY-MM-DD HH:mm:ss');
        this.props.updateTimeoutDialog(true, this.props.countdownTicks, startTime);
      }

      // any main tab has own timer
      this.clearTimers();
      this.timerID = setTimeout(this.onUsualTimerTick, IDLE_TIMER_TICK_TIMEOUT);
      this.serviceWorkerTimerID = window.subscribeWorkerTimer(this.onTimerTick, 1);
    }
  }

  private onAction() {
    const { showTimeoutDialog } = this.props;
    if (!showTimeoutDialog && !isChildWindow()) {
      this.clearTimers();
    }

    // update last activity in the storage to refresh timer on all windows
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.props.updateUserActivity(currentTime);
  }

  private onUsualTimerTick() {
    const restartTimer = this.onTimerTick();
    if (restartTimer) {
      this.timerID = setTimeout(this.onUsualTimerTick, IDLE_TIMER_TICK_TIMEOUT);
    }
  }

  private onTimerTick() {
    if (this.isTimerHandlingActive) {
      return true;
    }

    this.isTimerHandlingActive = true;

    const { shownTimeout, showTimeoutDialog, showTimeoutDialogFrom } = this.props;

    // only main window can control timer sequence
    if (!showTimeoutDialog || isChildWindow()) {
      this.clearTimers();
      this.isTimerHandlingActive = false;
      return false;
    }

    if (shownTimeout <= 0) {
      // time is over
      this.onNoClick();
      this.isTimerHandlingActive = false;
      return false;
    }

    const currentTime = moment();
    const diff = WAIT_TICKS - currentTime.diff(moment(showTimeoutDialogFrom), 'second');

    this.props.updateTimeoutDialog(true, diff >= 0 ? diff : 0, showTimeoutDialogFrom);

    this.isTimerHandlingActive = false;
    
    return true;
  }

  private onNoClick() {
    if (this.activeClosing) {
      return;
    }

    this.activeClosing = true;

    if (!isChildWindow()) {
      this.clearTimers();
      // force all child windows to close
      this.props.updateTimeoutDialog(false, 0);
      this.props.updateTimeoutDialog(false, this.props.countdownTicks);
    }

    // We must clear anything that can prevent navigation: clear dirty flag in Encounter
    const hasUnsavedChanges = this.props.unsavedChanges;
    this.props.clearApplication();

    if (isChildWindow()) {
      // close child window instead of logout
      window.close();
    } else {
      localStorage.setItem("showCopyrightModal", "yes");
      // logout current user
      if (window.useAzureADAuth) {
        const accountIdentifier = getAccountIdentifier();

        // logout current user from all tabs
        this.props.logoutUser(accountIdentifier, hasUnsavedChanges);

        // user must be redirected to sign in page
        return;
      }

      this.props.history.push(this.props.redirectLocation);
    }
  }

  private onYesClick() {
    if (!isChildWindow()) {
      this.clearTimers();
    }

    this.props.updateTimeoutDialog(false, this.props.countdownTicks);
  }
}
