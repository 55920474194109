import * as moment from 'moment';
import { ErrorDetails } from "../../../models/ui";
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';
import { toFormattedDate } from "../../../utils/date";
import { isNumberValidator } from "../../../validators/isNumberValidator";
import { FinancialClassEntity, PricerCardFieldDefaultValue, PricerCardRow } from "../models/financialClass";

export const getSettingCardTitle = (_effDate: string, _expDate: string) => {
  const effDate = toFormattedDate(_effDate);
  const expDate = toFormattedDate(_expDate);

  if (!effDate) {
    return '\u00A0';
  }

  return `${_effDate}${expDate ? ' - ' : ''}${expDate ? _expDate : ''}`;
}

export const hasIntersection = (from: string, to: string, effDate: Date | null, expDate: Date | null) => {
  const fromDate = toFormattedDate(from);
  const toDate = toFormattedDate(to);

  // no period
  if (!fromDate || !effDate) {
    return false;
  }

  if (effDate >= fromDate) {
    if (!toDate || effDate <= toDate) {
      return true;
    }
  }

  if (effDate < fromDate) {
    if (!expDate || expDate >= fromDate) {
      return true;
    }
  }

  return false;
}

export const findConcurrencyVersion = (financialClasses: FinancialClassEntity[], selectedFinancialClassViewId: string) => {
  if (!selectedFinancialClassViewId) {
    return '';
  }

  const financialClass = financialClasses.find(finClass => finClass.ViewId === selectedFinancialClassViewId);

  return financialClass ? financialClass.Version : '';
}

export const isValidPricerCardValue = (dataItem: PricerCardRow, value: string, data?: { Value: string }[]) => {
  const { RangeStart, RangeEnd, Regex, AllowUserDefinedValue, HasDefaultValues } = dataItem;
  if (value === '') {
    return ''
  }

  if (HasDefaultValues) {
    if (!AllowUserDefinedValue) {
      if (data && data.length && !data.find(item => item.Value === value)) {
        return 'Invalid value';
      }
    }
  }

  if (RangeStart !== null || RangeEnd !== null) {
    if (value === '-') {
      return '';
    }

    // accept only numbers in this interval
    if (!isNumberValidator(value).succeeded) {
      return 'The field value should be numeric.';
    }

    const number = Number(value);
    if (RangeStart !== null && Number(RangeStart) > number) {
      return `Field value can't be less than ${RangeStart}`;
    }

    if (RangeEnd !== null && Number(RangeEnd) < number) {
      return `Field value can't be more than ${RangeEnd}`;
    }
  }

  if (Regex) {
    const regexp = new RegExp(`^${Regex}$`);
    if (!regexp.test(value)) {
      return `Invalid value.`;
    }
  }

  return '';
}

export const getPricerCardFieldDefaultValue = (defaultValues: PricerCardFieldDefaultValue[] | null) => {
  if (!defaultValues || !defaultValues.length) {
    return '';
  }

  if (defaultValues.length === 1) {
    return defaultValues[0].Value;
  }

  return '';
}

export const parseFinancialClassErrors = (reason, uiErrorText: string): ErrorDetails[] => {
  // parse special errors response
  if (reason && reason.response && reason.response.body && Array.isArray(reason.response.body) && reason.response.body.length) {
    const errors: ErrorDetails[] = [];
    for (let ind = 0, len = reason.response.body.length; ind < len; ind++) {
      const error = reason.response.body[ind];
      errors.push({ error: uiErrorText, description: error.ErrorDescription, time: moment.now() });
    }

    return errors;
  }

  return parseServiceErrors(reason, uiErrorText);
};
