import { IdDescriptionBase } from '../models/patientEncounter';

export const convertToComboboxModel = (model: IdDescriptionBase[], id?: string, valueFieldName = 'ViewId'): IdDescriptionBase | undefined => {
  if (!id) {
    return undefined;
  }

  const result = model.filter((rec) => {
    if (!rec[valueFieldName]) {
      return false;
    }

    return rec[valueFieldName].toUpperCase() === id.toUpperCase();
  });

  return result && result.length > 0 ? result[0] : undefined;
};

export const checkFieldForCollection = (id: string, collection: IdDescriptionBase[], valueField = 'ViewId'): string => {
  let found = '';
  const len = collection.length;

  // check if value is valid
  if (id) {
    for (let ind = 0; ind < len; ind++) {
      if (collection[ind][valueField] && collection[ind][valueField].toUpperCase() === id.toUpperCase()) {
        found = collection[ind][valueField];
        break;
      }
    }
  }

  return found;
};

export const findComboValueById = (model: IdDescriptionBase[], id?: string, valueField = 'ViewId'): string | undefined => {
  if (!id) {
    return undefined;
  }

  const len = model.length;

  for (let ind = 0; ind < len; ind++) {
    if (model[ind][valueField] && model[ind][valueField].toUpperCase() === id.toUpperCase()) {
      return model[ind].title;
    }
  }

  return undefined;
};

export const findComboIdByValue = (model: IdDescriptionBase[], value?: string, valueField = 'ViewId'): string | undefined => {
  if (!value) {
    return undefined;
  }

  const len = model.length;

  for (let ind = 0; ind < len; ind++) {
    if (model[ind][valueField] && model[ind][valueField].toUpperCase() === value.toUpperCase()) {
      return model[ind][valueField];
    }
  }

  return undefined;
};
