import { get, post } from '../../utils/net';
import { FacilityServiceFieldSetting, FacilityPreferences } from '../../models/facilitySettings';
import { UserRoleMap } from '../../models';

const baseURL = process.env.REACT_APP_API_PATH;

const getFacilityFieldSettings = (facility: string, type: string, isInterfaceMode?: boolean): Promise<FacilityServiceFieldSetting[]> => {
  const url = `${baseURL}/api/fieldsettings/${facility}/${type}?interfaced=${isInterfaceMode ? "true" : "false"}`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    const settings: FacilityServiceFieldSetting[] = result.body.map((setting) => {
      return {
        ...setting
      };
    });

    return settings;
  });
};

const getFacilityFormPreferences = (facility: string, preferencesType: string): Promise<FacilityServiceFieldSetting[]> => {
  const url = `${baseURL}/api/facilities/${facility}/${preferencesType}`;
  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

const getAllFacilityFormPreferences = (facility: string): Promise<FacilityPreferences> => {
  const preferencesCalls: Promise<object>[] = [];

  preferencesCalls.push(
    getFacilityFormPreferences(facility, 'GeneralPreferences')
      .then((results) => {
        return {
          name: 'GeneralPreferences',
          values: results
        }
      })
  );

  preferencesCalls.push(
    getFacilityFormPreferences(facility, 'OutPatientPreferences')
      .then((results) => {
        return {
          name: 'OutPatientPreferences',
          values: results
        }
      })
  );

  preferencesCalls.push(
    getFacilityFormPreferences(facility, 'InPatientPreferences')
      .then((results) => {
        return {
          name: 'InPatientPreferences',
          values: results
        }
      })
  );

  return Promise.all(preferencesCalls)
    .then((results) => {
      const allPreferences: FacilityPreferences = {};

      results.forEach((prefs: { name: string, values: object }) => {
        if (prefs) {
          allPreferences[prefs.name] = prefs.values;
        }
      })

      return allPreferences;
    });
}

const updateFacilityFormPreferences = (facility: string, preferencesType: string, preferences): Promise<FacilityServiceFieldSetting[]> => {
  const url = `${baseURL}/api/facilities/${facility}/${preferencesType}`;
  const extraOptions = {
    useExactPath: true
  };

  return post(url, preferences, extraOptions).then((result) => {
    return result.body;
  });
};

const getFacilityPreferences = (facility: string): Promise<FacilityPreferences> => {
  const url = `${baseURL}/api/ChoiceLists/facility/${facility}`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    const roleString = result.body.CurrentUserRole;
    const mapped: FacilityPreferences = result.body;
    mapped.CurrentUserRole = UserRoleMap[roleString];
    return mapped;
  });
};

export const facilitySettingsAPI = {
  getFacilityFieldSettings,
  getFacilityPreferences,
  getAllFacilityFormPreferences,
  updateFacilityFormPreferences
};
