import './SingleCodeSelector.less';
import * as React from 'react';
import { Key } from '../../constants/keyboard';
import { ADMIT_DX_ID, CodeType, GridCodeType } from '../../models/codeTypes';
import { GridCodeFormat } from '../../models/codeFormats';
import { Button, Input } from '..';
import { IconType } from '../Icon/Icon';

interface SingleCodeSelectorProps {
  value?: string;
  id: string;
  viewId: string;
  className?: string;
  onFocus?: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onBlur?: (value: string, blurToCodebooks?: boolean) => void;
  onClick: (e: React.MouseEvent) => void;
  valid?: boolean;
  validationMessage?: string;
  codeType: CodeType;
  gridCodeType: GridCodeType;
  codeFormat: GridCodeFormat;
  primary?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  isShowingPdxAnalysis?: boolean;
}

export class SingleCodeSelector extends React.PureComponent<SingleCodeSelectorProps, {}> {
  input: HTMLInputElement;
  regexp: RegExp;
  partialRegexp: RegExp | null;
  skipNextBlur: boolean;
  codebooksButton: Button | null;

  constructor(opts: SingleCodeSelectorProps) {
    super(opts);

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onBeforeChangeInput = this.onBeforeChangeInput.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.onKeyDownInput = this.onKeyDownInput.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);
    this.onBeforePasteInput = this.onBeforePasteInput.bind(this);
  }

  componentDidMount() {
    // create it once. CodeFormat cannot be changed
    this.regexp = new RegExp(this.props.codeFormat.regexp);
    this.partialRegexp = this.props.codeFormat.partialRegexp ?
      new RegExp(this.props.codeFormat.partialRegexp) : null;
    this.skipNextBlur = false;
  }

  render() {
    const className = this.props.className ? `${this.props.className} code-selector-wrapper` : 'code-selector-wrapper';
    return (
      <span className={className}>
        <Input
          value={this.props.value || ''}
          className="code-text"
          id={`view-id-${this.props.viewId}`}
          inputClass={this.props.readOnly ? "read-only-code" : ""}
          name="single-code-selector"
          label=""
          onBlur={this.onBlurInput}
          onBeforeChange={this.onBeforeChangeInput}
          onChange={this.onChangeInput}
          onKeyDown={this.onKeyDownInput}
          onFocus={this.onFocusInput}
          onBeforePaste={this.onBeforePasteInput}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
        />
        <Button
          iconType={IconType.Dots}
          onClick={this.props.onClick}
          id={this.getCodebooksButtonId()}
          ref={(ref) => { this.codebooksButton = ref; }}
          onKeyDown={this.onKeyDownButton}
          primary={this.props.primary}
          disabled={this.props.disabled}
        />
      </span>
    );
  }

  private onBeforeChangeInput(value: string) {
    let val = value.toUpperCase();

    val = this.addDecimalPoint(val, this.props.codeFormat.decimalPosition);

    if (!this.isCorrect(val, this.props.codeFormat, this.regexp, this.partialRegexp)) {
      return {
        valid: false,
        value: ''
      };
    }

    return {
      valid: true,
      value: val
    };
  }

  private onChangeInput(name: string, value: string, e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.onChange) {
      this.props.onChange(e, value);
    }
  }

  private onBlurInput(name: string, value: string, e: React.FocusEvent<HTMLInputElement>) {
    const blurToCodebooks = this.blurToCodebooksButton(e);

    if (this.props.onBlur && !this.skipNextBlur) {
      this.props.onBlur(value, blurToCodebooks);
    }

    this.skipNextBlur = false;
  }

  private getCodebooksButtonId = () => {
    return `open-codebook-button-${this.props.id}`;
  }

  private onKeyDownInput(e: React.KeyboardEvent<HTMLInputElement>) {
    // ignore navigation from empty code for the isShowingPdxAnalysis mode
    const isEmptyCode = (this.props.value || '').trim() === "";
    if (isEmptyCode && this.props.isShowingPdxAnalysis && (e.key === Key.Enter || e.key === Key.Tab)) {
      e.preventDefault();
      return;
    }

    if (e.key === Key.Enter || e.key === Key.Space || e.key === Key.Escape) {
      // blur event is missed during enter keydown event
      if (e.key === Key.Enter && this.props.onBlur) {
        if (!e.shiftKey || this.props.id !== ADMIT_DX_ID) { // keep focus for shift-enter from admitDx
          this.props.onBlur(this.props.value || '');
        }
      }

      // do not delete row if we open codebooks
      const isEmptyCode = !this.props.value || this.props.value.trim() === '';
      if (e.key === Key.Space && isEmptyCode) {
        this.skipNextBlur = true;
      }

      this.props.onKeyDown(e);
    }

    if (e.key === Key.Tab) {
      e.preventDefault();
      if (this.props.onBlur) {
        this.props.onBlur(this.props.value || '');
      }
      this.props.onKeyDown(e);
    }

    if(e.key === Key.G) {
      if(e.ctrlKey){
        e.preventDefault();
        if (this.props.onBlur) {
          this.props.onBlur(this.props.value || '');
        }
        this.props.onKeyDown(e);
      }

    }

    // clear admitDX with ctrl-del
    if (e.key === Key.Delete && e.ctrlKey && this.props.gridCodeType === GridCodeType.ADMIT_DX) {
      e.preventDefault();
      if (this.props.onChange) {
        this.props.onChange(e, '');
      }
    }
  }

  onKeyDownButton = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === Key.Tab && !e.shiftKey) {
      const isEmptyCode = !this.props.value || this.props.value.trim() === '';
      if (isEmptyCode && this.props.onBlur) {
        // clear empty codes on Tab from the Ripple button
        this.props.onBlur('');
      }
    }
  }

  private onFocusInput(e: React.FocusEvent<HTMLInputElement>) {
    const blurFromButton = this.blurToCodebooksButton(e);

    this.skipNextBlur = blurFromButton;
    if (this.props.onFocus && !blurFromButton) {
      this.props.onFocus();
    }
  }

  private isCorrect = (value: string, codeFormat: GridCodeFormat, regexp: RegExp, partialRegexp: RegExp | null) => {
    if (!value) {
      return true;
    }

    if (regexp.test(value)) {
      return true;
    }

    if (partialRegexp && partialRegexp.test(value)) {
      return true;
    }

    return false;
  }

  private addDecimalPoint = (val: string, decimalPos: number): string => {
    if (decimalPos < 0) {
      return val;
    }

    if (val.length > decimalPos && val[decimalPos] !== '.') {
      return `${val.substr(0, decimalPos)}.${val.substr(decimalPos)}`;
    }

    return val;
  }

  private onBeforePasteInput = (pastedText: string) => {
    let val = pastedText.toUpperCase();
    // skip all special characters including .
    const pasted = val.replace(/[^\w]/gi, '');
    val = this.addDecimalPoint(val, this.props.codeFormat.decimalPosition);

    if (!this.isCorrect(val, this.props.codeFormat, this.regexp, this.partialRegexp)) {
      // keep only acceptable characters
      val = '';
      for (let ind = 0, len = pasted.length; ind < len; ind++) {
        const char = pasted.substr(ind, 1);
        let nextVal = `${val}${char}`;
        nextVal = this.addDecimalPoint(nextVal, this.props.codeFormat.decimalPosition);
        if (this.isCorrect(nextVal, this.props.codeFormat, this.regexp, this.partialRegexp)) {
          val = nextVal;
        }
      }
    }

    return val;
  }

  private blurToCodebooksButton = (e: React.FocusEvent<HTMLInputElement>): boolean => {
    let blurResult = false;

    if(e.relatedTarget) {
      blurResult = e.relatedTarget?.id === this.getCodebooksButtonId();
    }

    return blurResult;
  }
}
