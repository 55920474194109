export enum CodeType {
  ALL = '',
  ICD9CM_DX = 'ICD9CM_DX',
  ICD9CM_PR = 'ICD9CM_PR',
  ICD10CM_DX = 'ICD10CM_DX',
  ICD10CM_E = 'ICD10CM_E',
  ICD10PCS_PR = 'ICD10PCS_PR',
  CPT4 = 'CPT4',
  HCPCS = 'HCPCS',
}

const All = { id: CodeType.ALL, title: 'All' };
const ICD10CM_DX = { id: CodeType.ICD10CM_DX, title: 'Diagnosis, ICD-10-CM' };
const ICD10CM_E = { id: CodeType.ICD10CM_E, title: 'External Cause, ICD-10-CM' };
const ICD10PCS_PR = { id: CodeType.ICD10PCS_PR, title: 'Procedure, ICD-10-PCS' };
const CPT4 = { id: CodeType.CPT4, title: 'CPT' };
const HCPCS = { id: CodeType.HCPCS, title: 'HCPCS' };

export const CodeTypesMap = {
  CPT4,
  HCPCS,
  ICD10CM_DX,
  ICD10CM_E,
  ICD10PCS_PR,
};

export const CodeTypes = [
  All,
  ICD10CM_DX,
  ICD10CM_E,
  ICD10PCS_PR,
  CPT4,
  HCPCS
];

export enum GridCodeType {
  // these string values must _not_ change as they are used to lookup the appropriate array
  VISITREASONS = 'visitReasons',
  DIAGNOSES = 'diagnoses',
  INPROCEDURES = 'inProcedures',
  OUTPROCEDURES = 'outProcedures',
  ADMIT_DX = 'admit_dx'
}

export const ADMIT_DX_ID = 'admit_dx_id';
