import * as actionTypes from '../constants';

import { ChoiceListsSettingsState } from '../scenes/ChoiceListsSettings/models/choiceListsSettingsState';
import { ChoiceListDataItem, getDefaultChoiceLists, ChoiceListDefaultValue } from '../scenes/ChoiceListsSettings/models/choiceListsSettings';
import { checkForConcurrencyError } from '../utils/checks';
import { UI } from '../models';

const createEmptyChoiceListsSettingsState = (): ChoiceListsSettingsState => {
  const choiceLists = getDefaultChoiceLists();
  return {
    loading: false,
    selectedFacilityViewId: '',

    choiceLists,
    selectedChoiceListId: (choiceLists.length && choiceLists[0].id) || '',

    items: [],
    originalItems: [],
    defaultValues: [],
    errorText: '',
    hideInactiveItems: true,
  }
}

export const choiceListsSettingsReducer = (state = createEmptyChoiceListsSettingsState(), action) => {
  switch (action.type) {
    case actionTypes.CHOICE_LISTS_SETTINGS_LOADING:
      return handleSetLoading(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_LIST_LOADED:
      return handleChoiceListLoaded(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_CHANGE_ITEM:
      return handleChangeDataItem(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_CONFIRM_CHANGE_ITEM:
      return handleConfirmChangeItem(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_DISCARD_CHANGE_ITEM:
      return handleDiscardChangeItem(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_REMOVE_ITEM:
      return handleRemoveItem(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_CREATE_ITEM:
      return handleCreateItem(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_CONFIRM_CREATE_ITEM:
      return handleConfirmCreate(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_DISCARD_CREATE_ITEM:
      return handleDiscardCreate(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_DISCARD_ALL_CHANGES:
    case actionTypes.SETTINGS_CONFIRM_DISCARD_DIRTY_CHANGES:
      return handleDiscardAllChanges(state);
    case actionTypes.CHOICE_LISTS_SETTINGS_SET_ERROR:
      return handleSetError(state, action.payload);
    case actionTypes.CHOICE_LISTS_SETTINGS_TOGGLE_HIDE_INACTIVE_ITEMS:
      return handleToogleHideInactiveItems(state);

    default:
      return state;
  }
};

const handleSetLoading = (state: ChoiceListsSettingsState, loading: boolean): ChoiceListsSettingsState => {
  const newState = {
    ...state,
    loading,
  };

  return newState;
}

const handleChoiceListLoaded = (state: ChoiceListsSettingsState, payload: { facilityViewId: string, choiceListId: string, choiceList: ChoiceListDataItem[], defaultValues: ChoiceListDefaultValue[] }): ChoiceListsSettingsState => {
  const newState = {
    ...state,
    selectedFacilityViewId: payload.facilityViewId,
    selectedChoiceListId: payload.choiceListId,
    items: [...payload.choiceList],
    originalItems: [...payload.choiceList],
    defaultValues: [...payload.defaultValues],
    errorText: ''
  };

  return newState;
}


const handleChangeDataItem = (state: ChoiceListsSettingsState, dataItem: ChoiceListDataItem): ChoiceListsSettingsState => {
  // it is used for some service fields, so errorText must be cleared separately
  const newState = {
    ...state,
    items: state.items.map(item => item.ViewId === dataItem.ViewId ? { ...dataItem } : item)
  };

  return newState;
}

const handleRemoveItem = (state: ChoiceListsSettingsState, dataItem: ChoiceListDataItem): ChoiceListsSettingsState => {
  const newState = {
    ...state,
    items: state.items.filter(item => item.ViewId !== dataItem.ViewId),
    originalItems: state.originalItems.filter(item => item.ViewId !== dataItem.ViewId),
    errorText: ''
  };

  return newState;
}

const handleCreateItem = (state: ChoiceListsSettingsState, dataItem: ChoiceListDataItem): ChoiceListsSettingsState => {
  const newState = {
    ...state,
    items: [dataItem, ...state.items],
    errorText: ''
  };

  return newState;
}

const handleConfirmCreate = (state: ChoiceListsSettingsState, payload: { createdItemViewId: string, dataItem: ChoiceListDataItem }): ChoiceListsSettingsState => {
  const newItem = { ...payload.dataItem, createdItemViewId: '', inEdit: undefined };

  const newState = {
    ...state,
    items: state.items.map(item => item.ViewId === payload.createdItemViewId ? newItem : item),
    originalItems: [{ ...newItem }, ...state.originalItems],
    errorText: ''
  };

  return newState;
}

const handleDiscardCreate = (state: ChoiceListsSettingsState, dataItem: ChoiceListDataItem): ChoiceListsSettingsState => {
  const newState = {
    ...state,
    items: state.items.filter(item => item.ViewId !== dataItem.ViewId),
    errorText: ''
  };

  return newState;
}

const handleConfirmChangeItem = (state: ChoiceListsSettingsState, dataItem: ChoiceListDataItem): ChoiceListsSettingsState => {
  const newItem = { ...dataItem, inEdit: undefined };

  const newState = {
    ...state,
    items: state.items.map(item => item.ViewId === dataItem.ViewId ? newItem: item),
    originalItems: state.originalItems.map(item => item.ViewId === dataItem.ViewId ? { ...newItem } : item),
    errorText: ''
  };

  return newState;
}

const handleDiscardChangeItem = (state: ChoiceListsSettingsState, dataItem: ChoiceListDataItem): ChoiceListsSettingsState => {
  const original = state.originalItems.find(item => item.ViewId === dataItem.ViewId);
  if (!original) {
    return {
      ...state,
      items: state.items.filter(item => item.ViewId !== dataItem.ViewId),
      errorText: ''
    }
  }

  const newState = {
    ...state,
    items: state.items.map(item => item.ViewId === original.ViewId ? { ...original } : item),
    errorText: ''
  };

  return newState;
}

const handleDiscardAllChanges = (state: ChoiceListsSettingsState): ChoiceListsSettingsState => {
  const newState = {
    ...state,
    items: [...state.originalItems],
    errorText: ''
  };

  return newState;
}

const handleSetError = (state: ChoiceListsSettingsState, errorText: string): ChoiceListsSettingsState => {
  const concurrencyError = checkForConcurrencyError(errorText);
  const newState = {
    ...state,
    errorText: concurrencyError ? '' : errorText,
  };

  return newState;
}

const handleToogleHideInactiveItems = (state: ChoiceListsSettingsState): ChoiceListsSettingsState => {
  return {
    ...state,
    hideInactiveItems: !state.hideInactiveItems,
  }
}
