/* eslint-disable @typescript-eslint/no-explicit-any */
import { DDLValues } from '.';
import { CodeType, GridCodeType } from './codeTypes';
import { CodeValidation } from './encounterServiceEntity';
import { IdDescriptionBase } from './patientEncounter';

export enum PresentOnAdmission {
  Y = 'Y',
  N = 'N',
  U = 'U',
  W = 'W',
  ONE = '1',
  EMPTY = '',
}

const Y = { title: 'Yes', id: PresentOnAdmission.Y, ViewId: PresentOnAdmission.Y };
const N = { title: 'No', id: PresentOnAdmission.N, ViewId: PresentOnAdmission.N };
const U = { title: 'Unknown', id: PresentOnAdmission.U, ViewId: PresentOnAdmission.U };
const W = { title: 'Clinically undetermined', id: PresentOnAdmission.W, ViewId: PresentOnAdmission.W };
const ONE = { title: 'Unreported / Not used (Exempt from POA reporting)', id: PresentOnAdmission.ONE, ViewId: PresentOnAdmission.ONE };

export const PresentOnAdmissionMap = {
  1: ONE,
  N,
  U,
  W,
  Y,
};

export const PresentOnAdmissionCollection: DDLValues[] = [
  Y,
  N,
  U,
  W,
  ONE,
];

export const PresentOnAdmissionList: IdDescriptionBase[] = [
  { title: 'Yes', id: PresentOnAdmission.Y, ViewId: PresentOnAdmission.Y },
  { title: 'No', id: PresentOnAdmission.N, ViewId: PresentOnAdmission.N },
  { title: 'Unknown', id: PresentOnAdmission.U, ViewId: PresentOnAdmission.U },
  { title: 'Clinically undetermined', id: PresentOnAdmission.W, ViewId: PresentOnAdmission.W },
  { title: 'Unreported / Not used (Exempt from POA reporting)', id: PresentOnAdmission.ONE, ViewId: PresentOnAdmission.ONE }
];

export interface Provider {
  id: number;
  name: string;
  service: string;
}

export const ProviderCollection: Provider[] = [
  { id: 1, name: 'Provider1', service: 'Service1' },
  { id: 2, name: 'Provider2', service: 'Service4' },
  { id: 3, name: 'Provider3', service: 'Service3' },
];

export interface BaseCodeRow {
  autoChanged?: string[];
  code?: string;
  type?: CodeType; // TODO: make mandatory after SVC-1617 type resolved
  gridCodeType: GridCodeType;
  presentOnAdmission?: PresentOnAdmission|null;
  description?: string; // currently shown description. Calculate it from custom and server
  id: string;
  grouperFlag?: string;
  grouperFlagAffectsDRG?: boolean;  // has AD flag to show some flags with bold and to create tooltip text
  grouperFlagHFlag?: boolean;       // has H flag to show some flags with bold
  episode?: string;
  provider?: string;
  date?: Date|string;
  edits?: any[];
  mneEdits?: any[];
  modifiers?: string[];
  units?: string;
  charges?: string;
  nccharges?: string;
  apc?: string;
  hccs?: any[];         // all hccs
  hccs24?: any[];       // versioned hccs
  hccs28?: any[];       // versioned hccs
  // Exempt POA flag: undefined - no exempt / null - blank / '1' - '1'
  exemptPoa?: string | null;
  valid?: boolean;
  validationMessages?: string[];
  empty?: boolean;
  inEdit?: boolean;
  loadingValidity?: boolean;
  revenueCode?: string;
  drg?: string;
  drgDescription?: string;
  drgWeight?: number;
  reimbursement?: number;
  editField?: string;
  procedureData?: any;
  initialCode?: string;
  serviceId?: string | null;
  serviceCode?: string | null;              // stored on the services side code
  serviceCustomDescription?: string | null; // stored on the services side description
  customCodeDescription?: string | null;    // raw user description
  loaded?: boolean;                         // indicates that code was synchronized with services
  psychComorbidityAdjustmentFactor?: string | null;
  psychComorbidityCategoryDescription?: string | null;
  IsChargeDriven?: boolean;                   // this code cannot be edited

  // service side code description
  ServiceCodeDescription?: string | null;

  // all code validations
  Validations: CodeValidation[];
}

export interface KeyedCodeRowCollection {
  [index: string]: BaseCodeRow;
}

export interface BaseCodeRowCollection {
  items: KeyedCodeRowCollection;
  last: string;
}

export interface EncounterCodeGridState {
  rows: BaseCodeRowCollection;
  currentRow: string;
}

export interface ModifierCollection {
  id: string;
  title: string;
  type: string;
}

export interface CodeGroup {
  codes: BaseCodeRow[];
  editedCode?: BaseCodeRow;
  locked?: boolean;
}

export const CPTModifierCollection: ModifierCollection[] = [
  { id: '22', title: 'Increased Procedural Services', type: 'CPT' },
  { id: '23', title: 'Unusual Anesthesia', type: 'CPT' },
  { id: '24', title: 'Unrelated E&M Sv Same Pro PO', type: 'CPT' },
  { id: '25', title: 'Sig/Sep ID E&M Same Pro/Day', type: 'CPT' },
  { id: '26', title: 'Professional Component', type: 'CPT' },
  { id: '27', title: 'Mult OP E/M Enctr, Same Date', type: 'CPT' },
  { id: '32', title: 'Mandated Services', type: 'CPT' },
  { id: '33', title: 'Preventive Services', type: 'CPT' },
  { id: '47', title: 'Anesthesia by Surgeon', type: 'CPT' },
  { id: '50', title: 'Bilateral Procedure', type: 'CPT' },
  { id: '51', title: 'Multiple Procedures', type: 'CPT' },
  { id: '52', title: 'Reduced Services', type: 'CPT' },
  { id: '53', title: 'Discontinued Procedure', type: 'CPT' },
  { id: '54', title: 'Surgical Care Only', type: 'CPT' },
  { id: '55', title: 'Postoperative Management Only', type: 'CPT' },
  { id: '56', title: 'Preoperative Management Only', type: 'CPT' },
  { id: '57', title: 'Decision for Surgery', type: 'CPT' },
  { id: '58', title: 'Stg/Rltd Pr/Svc Same Pro, PO', type: 'CPT' },
  { id: '59', title: 'Distinct Procedural Service', type: 'CPT' },
  { id: '62', title: 'Two Surgeons', type: 'CPT' },
  { id: '63', title: 'PR Performed on Infance <4 kg', type: 'CPT' },
  { id: '66', title: 'Surgical Team', type: 'CPT' },
  { id: '73', title: 'Discont OP Pr Prior to Anest', type: 'CPT' },
  { id: '74', title: 'Discont OP After Anesthesia', type: 'CPT' },
  { id: '76', title: 'Repeated Proc/Svc, Same Profes', type: 'CPT' },
  { id: '77', title: 'Repeated Proc, ANother Profes', type: 'CPT' },
  { id: '78', title: 'Return to OR Related Proc, PO', type: 'CPT' },
  { id: '79', title: 'Unrelated Proc, Same Pro, PO', type: 'CPT' },
  { id: '80', title: 'Assistant Surgeon', type: 'CPT' },
  { id: '81', title: 'Minimum Assitant Surgeon', type: 'CPT' },
  { id: '82', title: 'Assistant Surgeon', type: 'CPT' },
  { id: '90', title: 'Reference (Outside) Lab', type: 'CPT' },
  { id: '91', title: 'Repeat Clinical Diag Lab Test', type: 'CPT' },
  { id: '92', title: 'Alternate Lab Platform Test', type: 'CPT' },
  { id: '95', title: 'Synchronous Telemedicine Service Rendered Via a Real-Time Interactive Audio and Video Telecommunications System ', type: 'CPT' },
  { id: '96', title: 'Habilitative Services', type: 'CPT' },
  { id: '97', title: 'Rehabilitative Services', type: 'CPT' },
  { id: '99', title: 'Multiple Modifiers', type: 'CPT' },
  { id: 'E1', title: 'Upper Left Eyelid', type: 'CPT/HCPCS' },
  { id: 'E2', title: 'Lower Left Eyelid', type: 'CPT/HCPCS' },
  { id: 'E3', title: 'Upper Right Eyelid', type: 'CPT/HCPCS' },
  { id: 'E4', title: 'Lower Right Eyelid', type: 'CPT/HCPCS' },
  { id: 'F1', title: 'Left Hand, Second Digit', type: 'CPT/HCPCS' },
  { id: 'F2', title: 'Left Hand, Third Digit', type: 'CPT/HCPCS' },
  { id: 'F3', title: 'Left Hand, Fourth Digit', type: 'CPT/HCPCS' },
  { id: 'F4', title: 'Left Hand, Fifth Digit', type: 'CPT/HCPCS' },
  { id: 'F5', title: 'Right Hand, Thumb', type: 'CPT/HCPCS' },
  { id: 'F6', title: 'Right Hand, Second Digit', type: 'CPT/HCPCS' },
  { id: 'F7', title: 'Right Hand, Third Digit', type: 'CPT/HCPCS' },
  { id: 'F8', title: 'Right Hand, Fourth Digit', type: 'CPT/HCPCS' },
  { id: 'F9', title: 'Right Hand, Fifth Digit', type: 'CPT/HCPCS' },
  { id: 'FA', title: 'Left Hand, Thumb', type: 'CPT/HCPCS' },
  { id: 'GG', title: 'Payment Screen Mam + Diagmam', type: 'CPT/HCPCS' },
  { id: 'GH', title: 'Diag Mammo Frm Screen Mamo', type: 'CPT/HCPCS' },
  { id: 'LC', title: 'Lft Circum Coronary Artery', type: 'CPT/HCPCS' },
  { id: 'LD', title: 'Left Ant Des Coronary Artery', type: 'CPT/HCPCS' },
  { id: 'LM', title: 'Left Main Coronary Artery', type: 'CPT/HCPCS' },
  { id: 'LT', title: 'Left Side', type: 'CPT/HCPCS' },
  { id: 'QM', title: 'Ambulance Arr By Provider', type: 'CPT/HCPCS' },
  { id: 'QN', title: 'Ambulance Furn By Provider', type: 'CPT/HCPCS' },
  { id: 'RC', title: 'Right Coronary Artery', type: 'CPT/HCPCS' },
  { id: 'RI', title: 'Ramus Intermedius Cor Artery', type: 'CPT/HCPCS' },
  { id: 'RT', title: 'Right Side', type: 'CPT/HCPCS' },
  { id: 'T1', title: 'Left Foot, Second Digit', type: 'CPT/HCPCS' },
  { id: 'T2', title: 'Left Foot, Third Digit', type: 'CPT/HCPCS' },
  { id: 'T3', title: 'Left Foot, Fourth Digit', type: 'CPT/HCPCS' },
  { id: 'T4', title: 'Left Foot, Fifth Digit', type: 'CPT/HCPCS' },
  { id: 'T5', title: 'Right Foot, Great Toe', type: 'CPT/HCPCS' },
  { id: 'T6', title: 'Right Foot, Second Digit', type: 'CPT/HCPCS' },
  { id: 'T7', title: 'Right Foot, Third Digit', type: 'CPT/HCPCS' },
  { id: 'T8', title: 'Right Foot, Fourth Digit', type: 'CPT/HCPCS' },
  { id: 'T9', title: 'Right Foot, Fifth Digit', type: 'CPT/HCPCS' },
  { id: 'TA', title: 'Left Foot, Great Toe', type: 'CPT/HCPCS' },
  { id: 'XE', title: 'Separate Encounter', type: 'CPT/HCPCS' },
  { id: 'XP', title: 'Separate Practitioner', type: 'CPT/HCPCS' },
  { id: 'XS', title: 'Separate Structure', type: 'CPT/HCPCS' },
  { id: 'XU', title: 'Unusual Non-Overlapping Service', type: 'CPT/HCPCS' },
  { id: '1P', title: 'PM Excl D/T Medical Reasons', type: 'CPT' },
  { id: '2P', title: 'PM Excl D/T Patient Reasons', type: 'CPT' },
  { id: '3P', title: 'PM Excl D/T System Reasons', type: 'CPT' },
  { id: '8P', title: 'Perf Measure Mod, Reason NOS', type: 'CPT' },
  { id: 'P1', title: 'Normal Healthy Patient', type: 'CPT' },
  { id: 'P2', title: 'Patient w/Mild Syst Disease', type: 'CPT' },
  { id: 'P3', title: 'Patient w/Severe Sys Disease', type: 'CPT' },
  { id: 'P4', title: 'Pt w/Sev Sys Dis Threat Life', type: 'CPT' },
  { id: 'P5', title: 'Pt Not Expect Surv w/o Oper', type: 'CPT' },
  { id: 'P6', title: 'Brain-Dead Pt Organs Removed', type: 'CPT' },
  { id: 'A1', title: 'Dressing for One Wound', type: 'HCPCS' },
  { id: 'A2', title: 'Dressing for Two Wounds', type: 'HCPCS' },
  { id: 'A3', title: 'Dressing for Three Wounds', type: 'HCPCS' },
  { id: 'A4', title: 'Dressing for Four Wounds', type: 'HCPCS' },
  { id: 'A5', title: 'Dressing for Five Wounds', type: 'HCPCS' },
  { id: 'A6', title: 'Dressing for Six Wounds', type: 'HCPCS' },
  { id: 'A7', title: 'Dressing for Sevent Wounds', type: 'HCPCS' },
  { id: 'A8', title: 'Dressing for Eight Wounds', type: 'HCPCS' },
  { id: 'A9', title: 'Dressing for 9 Or More Wound', type: 'HCPCS' },
  { id: 'AA', title: 'Anesthesia Perf By Anaesgst', type: 'HCPCS' },
];

const ApprovedModifiersIds = [
  '25', '27', '33', '50', '52', '58', '59', '73', '74', '76', '77', '78', '79', '91',
  'E1', 'E2', 'E3', 'E4', 'ER', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9',
  'FA', 'GG', 'GH', 'F1', 'LC', 'LD', 'LM', 'LT', 'QM', 'QN', 'RC', 'RI', 'RT', 'T1',
  'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9', 'TA', 'XE', 'XP', 'XS', 'XU'
];

export const CPTModifierCollectionApproved =
  CPTModifierCollection.filter((modifier: ModifierCollection) =>
    ApprovedModifiersIds.some((approvedId: string) => approvedId === modifier.id));
