import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initializeAction } from '../../actions/initialize';
import { State } from '../../reducers';
import { Initializing } from './Initializing';
import { updateFacilitiesAction } from '../../actions/choiceLists';
import { getUserPreferencesAction } from '../../actions/getUserPreferences';

const mapStateToProps = (state: State) => ({
  lookups: state.lookups,
  ui: state.ui
});

const mapDispatchToProps = (dispatch) => ({
  initialize: () => dispatch(initializeAction()),
  // TODO: this looks unused
  updateFacilities: () => dispatch(updateFacilitiesAction()),
  getUserPreferences: () => dispatch(getUserPreferencesAction()),
});

export const InitializingContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Initializing));
