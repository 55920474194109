import './Debug.less';

import * as React from 'react';
import get from 'lodash/get';
import { B2CState } from '../../models/b2c';
import { NetActivityGrid } from './NetActivityGrid';
import { NetActivity } from '../../models/netActivity';
import { EncounterSnapshotState } from '../../models';
import { Button } from '..';

interface DebugProps {
  b2c: B2CState,
  lastActivity: string,
  activities: NetActivity[];
  id: string;
  snapshotEncounter: (id: string) => void;
  snapshot: EncounterSnapshotState;
}

export class Debug extends React.Component<DebugProps> {
  highlightedProperties: string[];

  constructor(props: DebugProps) {
    super(props);
    this.highlightedProperties = [
      'b2c.accessToken.expiresOn',
      // 'b2c.initializing',
      // etc
    ];
    this.prepareClick = this.prepareClick.bind(this);
  }

  render() {
    const combinedDebug = {
      b2c: this.props.b2c.tokenInfo || {},
      lastActivity: this.props.lastActivity,
    }
    const highlightedContents: JSX.Element[] = [];
    this.highlightedProperties.forEach((path) => {
      const val = get(combinedDebug, path);
      if (typeof val !== 'undefined') {
        highlightedContents.push(Debug.format(path, val));
      }
      return null;
    });
    const rawContents = Debug.formatObject(combinedDebug);
    let snapshot: JSX.Element | null = null;
    let download: JSX.Element | null = null;
    const hasId = this.props.id && this.props.id !== '0';
    if (this.props.snapshot.fetched) {
      const filename = `${this.props.id}-${process.env.REACT_APP_API_PATH}.json`;
      const blob = new Blob([JSON.stringify(this.props.snapshot.snapshot, null, 2)], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const dlClasses = `k-button tc-button ${this.props.snapshot.fetched ? '' : 'k-state-disabled'}`;
      download = (<a className={dlClasses} download={filename} href={url}>Download</a>);
    }
    snapshot = (
      <React.Fragment key="snapshot">
        <dt>Enconter Snapshot</dt>
        <dd><Button onClick={this.prepareClick} disabled={!hasId || this.props.snapshot.fetching || this.props.snapshot.fetched}>Prepare</Button> {download}</dd>
        <div className="snapshot-hint"><b>Note:</b> Snapshots are only available for active encounters. Read more at <a href="https://trucode.atlassian.net/wiki/spaces/TEE/blog/2021/04/19/2095743462/Apollo+0.8+Available#%F0%9F%93%B8-Encounter-snapshots---please-read" target="_blank" rel="noopener noreferrer" data-ignore-domain="true">Apollo 0.8 Available</a></div>
      </React.Fragment>
    );

    return (
      <dl className="inline-flex" key="debug">
        {snapshot}
        {highlightedContents}
        {rawContents}
        <NetActivityGrid
          activities={this.props.activities}
        />
      </dl>
    );
  }

  private prepareClick() {
    this.props.snapshotEncounter(this.props.id);
  }

  private static onClick(e: React.MouseEvent<HTMLTextAreaElement>) {
    e.currentTarget.select();
  }

  private static formatKeyVal(k: string, v: string, multiRow?: boolean) {
    const formattedValue = typeof v !== 'undefined' ? <textarea value={v} rows={multiRow ? 5 : 1} onClick={this.onClick} readOnly /> : <code><i>undefined</i></code>;
    return (
      <React.Fragment key={k}>
        <dt title={k}><code>{k}</code></dt>
        <dd>{formattedValue}</dd>
      </React.Fragment>
    )
  }

  private static format(key: string, value: string) {
    return Debug.formatKeyVal(key, value);
  }

  private static formatObject(obj: object) {
    return Object.keys(obj).sort().map((key) => {
      let val = obj[key];
      if (typeof val === 'object') {
        val = JSON.stringify(val, undefined, 2);
        return Debug.formatKeyVal(key, val, true);
      }
      return Debug.formatKeyVal(key, val);
    });
  }
}
