import './DatePicker.less';

import * as React from 'react';
import { Calendar, CalendarProps } from '@progress/kendo-react-dateinputs';
import { CustomNavigationItem } from './customNavigationItem';

const CustomCalendar = (props: CalendarProps) => {
  return (
    <Calendar
      {...props}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      value={props.value}
      navigationItem={CustomNavigationItem} />
  );
}

export { CustomCalendar };
