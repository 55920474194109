import './AppBar.less';

import * as React from 'react';
import { AppBarSection as KAppBarSection, AppBarSectionProps as KAppBarSectionProps } from '@progress/kendo-react-layout';

export const AppBarSection = function render(props: KAppBarSectionProps) {
  const { className, ...otherProps } = props;
  return <KAppBarSection
    className={`tc-appbar-section ${className}`}
    {...otherProps}
  />;
}
