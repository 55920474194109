// import { AuthenticationActions } from 'react-aad-msal';
import forEach from 'lodash/forEach';
import LogRocket from 'logrocket';
import * as actionTypes from '../constants';
import {
  CurrentUserEntity,
  DecodeTokenResultEntity,
  FacilityMap,
  UserState,
  UserPreferencesEntity,
  UserRole,
  UserStatus,
  EncoderUserEntity,
  getUserPrivileges,
} from '../models';
import { FacilityPreferences } from '../models/facilitySettings';
import { ClientMode } from '../models/system';
import { EncoderUserFieldChangePayload } from '../scenes/Users/actions/encoderUserFieldChange';
import { mapRoleEnumToRole } from '../utils/user';
import { getUserEmail } from '../authProvider';

export const createEmptyUserState = (token?: string): UserState => {
  return {
    isSystemAdmin: false,
    highestRole: UserRole.NOROLE,

    error: false,
    errorDetail: '',
    errorReason: 'There was an error validating your credentials.',
    facilities: {},
    id: null,
    isAdviceAdmin: false,
    isPartnerAdviceAdmin: false,
    name: null,
    token: token || '',
    tokenDecoded: null,
    preferences: {
      HideExternalLinkWarning: false,
      AnimationDisabled: true,
    },
    loadingPreferences: false,
    clientMode: ClientMode.Standard,
    privileges: getUserPrivileges(),


    account: createEmptyEncoderUser(),
    avatarText: '',
    loading: true,
    loaded: false,
    dirty: false,
    organizationName: '',
    organizationId: '',
  };
};

const createEmptyEncoderUser = (): EncoderUserEntity => {
  return {
    clientId: '',
    customerId: '',
    firstName: '',
    lastName: '',
    userId: '',
    userName: '',
    email: '',
    isSystemAdmin: false,
    status: UserStatus.DISABLED,
    version: '',
    roles: [],
  };
};

export const userReducer = (state = createEmptyUserState(), action): UserState => {
  switch (action.type) {
    case actionTypes.INITIALIZE_BEGIN:
      return handleInitializeBegin(state, action.payload);
    /* case actionTypes.INITIALIZE_COMPLETED:
      return handleInitializeCompleted(state, action.payload);
    case actionTypes.INITIALIZE_FAIL:
      return handleInitializeFail(state, action.payload);
    */
    case actionTypes.GET_CURRENT_USER_COMPLETED:
      return handleGetCurrentUserCompleted(state, action.payload);
    case actionTypes.GET_CURRENT_USER_ORGANIZATION_COMPLETED:
      return handleGetCurrentUserOrganizationCompleted(state, action.payload);
    case actionTypes.GET_USER_PREFERENCES_BEGIN:
    case actionTypes.SET_USER_PREFERENCE_BEGIN:
      return handleSetUserPreferenceBegin(state);
    case actionTypes.SET_USER_PREFERENCE_COMPLETED:
      return handleSetUserPreferenceCompleted(state, action.payload);
    case actionTypes.SET_USER_PREFERENCE_ERROR:
      return handleSetUserPreferenceError(state, action.payload);
    case actionTypes.GET_USER_PREFERENCES_COMPLETED:
      return handleGetUserPreferencesCompleted(state, action.payload);
    case actionTypes.GET_USER_PREFERENCES_ERROR:
      return handleGetUserPreferencesError(state, action.payload);
    // case AuthenticationActions.LoginSuccess:
    //  return handleLoginSuccessful(state, action.payload);
    case actionTypes.GET_USER_BEGIN:
      return handleGetEncoderUserBegin(state);
    case actionTypes.GET_USER_COMPLETED:
      return handleGetEncoderUserCompleted(state, action.payload);
    case actionTypes.GET_USER_ERROR:
      return handleGetEncoderUserError(state, action.payload);
    case actionTypes.UPDATE_CURRENT_USER_FIELD:
      return handleUpdateEncoderUserField(state, action.payload);
    case actionTypes.SAVE_CURRENT_USER_BEGIN:
      return handleSaveCurrentUserBegin(state, action.payload);
    case actionTypes.SAVE_CURRENT_USER_COMPLETED:
      return handleSaveCurrentUserCompleted(state, action.payload);
    case actionTypes.SAVE_CURRENT_USER_ERROR:
      return handleSaveEncoderUserError(state, action.payload);
    case actionTypes.SET_FACILITY_PREFERENCES:
      return handleFacilityUpdate(state, action.payload);
    default:
      return state;
  }
};

const handleFacilityUpdate = (state: UserState, payload: { servicePreferences: FacilityPreferences }): UserState => {
  const privileges = getUserPrivileges(state, payload.servicePreferences.CurrentUserRole);
  return {
    ...state,
    privileges
  };
}

const handleInitializeBegin = (state: UserState, payload: string): UserState => {
  return createEmptyUserState(payload);
};

const handleGetCurrentUserCompleted = (state: UserState, payload: CurrentUserEntity): UserState => {
  const userState = {
    ...state,
    highestRole: payload.highestRole,
    isSystemAdmin: payload.isSystemAdmin,
    facilityRoles: payload.roles,
    loading: false,
    loaded: true,
    clientMode: payload.clientMode,
  } as UserState;

  userState.privileges = getUserPrivileges(userState);
  return userState;
};

const handleGetCurrentUserOrganizationCompleted = (state: UserState, payload): UserState => {
  const userEmail = getUserEmail();
  const userState = {
    ...state,
    account: { ...state.account,
      email: userEmail,
    },
    organizationName: payload.Name,
    organizationId: payload.ViewId,
  } as UserState;

  LogRocket.identify(userState.account.email, {organization: userState.organizationName, organizationid: userState.organizationId, role: mapRoleEnumToRole(userState.highestRole)});
  userState.privileges = getUserPrivileges(userState);
  return userState;
};

const handleInitializeCompleted = (state: UserState, payload: DecodeTokenResultEntity): UserState => {
  const facilities: FacilityMap = {};
  if (payload.facilities) {
    forEach(payload.facilities, (o) => {
      facilities[o.id] = o.description || null;
    });
  }
  return {
    ...state,
    facilities,
    id: payload.token.user_id ? payload.token.user_id : null,
    isAdviceAdmin: payload.content_management_config.content_admin,
    isPartnerAdviceAdmin: payload.content_management_config.partner_content_admin,
    name: payload.token.user_name ? payload.token.user_name : null,
    tokenDecoded: payload,
  };
};

const handleInitializeFail = (state: UserState, payload: { errorReason: string; errorDetail: string }): UserState => {
  return {
    ...state,
    error: true,
    errorReason: payload.errorReason,
    errorDetail: payload.errorDetail,
  };
};

const handleSetUserPreferenceBegin = (state: UserState): UserState => {
  return {
    ...state,
    loadingPreferences: true,
  };
};

const handleSetUserPreferenceCompleted = (state: UserState, payload: UserPreferencesEntity): UserState => {
  return {
    ...state,
    preferences: { ...state.preferences, [payload.key]: payload.value },
    loadingPreferences: false,
  };
};

const handleSetUserPreferenceError = (state: UserState, payload: string): UserState => {
  return {
    ...state,
    error: true,
    errorReason: payload,
    errorDetail: '',
    loadingPreferences: false,
  };
};

const handleGetUserPreferencesCompleted = (state: UserState, payload: object): UserState => {
  return {
    ...state,
    preferences: { ...state.preferences, ...payload },
    loadingPreferences: false,
  };
};

const handleGetUserPreferencesError = (state: UserState, payload: string): UserState => {
  return {
    ...state,
    error: true,
    errorReason: payload,
    errorDetail: '',
    loadingPreferences: false,
  };
};

const handleLoginSuccessful = (state: UserState, payload: any): UserState => {
  return {
    ...state,
    account: {
      ...state.account,
      userName: payload.account.idToken.user_name,
    },
  };
};

const handleUpdateEncoderUserField = (state: UserState, payload: EncoderUserFieldChangePayload): UserState => {
  const account = {
    ...state.account,
    [payload.fieldName]: payload.value,
    errorText: '',
  };

  return {
    ...state,
    loading: false,
    account,
    dirty: true,
  };
};

const handleGetEncoderUserBegin = (state: UserState): UserState => {
  return {
    ...state,
    loading: true,
  };
};

const handleGetEncoderUserCompleted = (state: UserState, payload: EncoderUserEntity): UserState => {
  const updatedState: UserState = {
    ...state,
    account: payload,
    avatarText: `${payload?.firstName?.slice(0, 1).toUpperCase()}${payload?.lastName?.slice(0, 1).toUpperCase()}`,
    errorDetail: '',
    loading: false,
    loaded: true,
    dirty: false,
  };

  return updatedState;
};

const handleSaveCurrentUserBegin = (state: UserState, payload: EncoderUserEntity): UserState => {
  const updatedState = {
    avatarText: `${payload?.firstName?.slice(0, 1).toUpperCase()}${payload?.lastName?.slice(0, 1).toUpperCase()}`,
    errorText: '',
    loading: true,
    dirty: false,
  };

  return {
    ...state,
    ...updatedState,
  };
};

const handleSaveCurrentUserCompleted = (state: UserState, payload: EncoderUserEntity): UserState => {
  const updatedState = {
    account: payload,
    errorText: '',
    loading: false,
    dirty: false,
  };

  return {
    ...state,
    ...updatedState,
  };
};

const handleGetEncoderUserError = (state: UserState, error): UserState => {
  const updatedState = {
    account: createEmptyEncoderUser(),
    errorText: error.message,
    loading: false,
    dirty: false,
  };

  return {
    ...state,
    ...updatedState,
  };
};

const handleSaveEncoderUserError = (state: UserState, error): UserState => {
  const updatedState = {
    errorText: error.message,
    loading: false,
    dirty: true,
  };

  return {
    ...state,
    ...updatedState,
  };
};
