import clone from 'lodash/clone';
import find from 'lodash/find';
import includes from 'lodash/includes';
import map from 'lodash/map';
import * as actionTypes from '../constants';
import {
  AdviceCodeRangeEntity,
  AdviceEntity,
  AdviceState,
  CodeRangeExpansionEntity,
  CodeTypes,
  CodingAdviceAPI,
  FACILITY_PREFIX,
  UserState,
  UserScope
} from '../models';
import { AdviceAddCodesPayload } from '../scenes/CodingAdvice/actions/addCodes';
import { AdviceDeleteCodeRangePayload } from '../scenes/CodingAdvice/actions/deleteCodeRange';
import { AdviceExcludeCodeInRangePayload } from '../scenes/CodingAdvice/actions/excludeCodeInRange';
import { AdviceFieldChangePayload } from '../scenes/CodingAdvice/actions/fieldChange';

const createEmptyAdvice = (user?: UserState, apiConfig?: CodingAdviceAPI): AdviceEntity => {
  let scope = UserScope.UNKNOWN;
  if (user) {
    if (user.id) { scope = UserScope.PERSONAL; }
    if (user.isAdviceAdmin) { scope = UserScope.ALL; }
    if (user.isPartnerAdviceAdmin) { scope = UserScope.PARTNER; }
  }
  let codeType = CodeTypes.filter((item) => item.title !== 'All')[0].id;
  if (apiConfig && apiConfig.codeTypeFilter) {
    const ds = new window.kendo.data.DataSource({ data: CodeTypes.filter((item) => item.title !== 'All') });
    const filter = Array.isArray(apiConfig.codeTypeFilter)
      ? apiConfig.codeTypeFilter
      : [apiConfig.codeTypeFilter];
    ds.filter(filter);
    codeType = ds.view().toJSON()[0].id;
  }
  return {
    codeType,
    codes: [],
    concurrencyVersion: '',
    created: '',
    deletedCodes: [],
    description: '',
    encounterType: 'All',
    id: '0',
    modified: '',
    modifiedBy: '0',
    scope,
    source: '',
    title: '',
  };
};

const createEmptyAdviceState = (user?: UserState, apiConfig?: CodingAdviceAPI): AdviceState => ({
  dirty: false,
  inProgress: createEmptyAdvice(user, apiConfig),
  saved: createEmptyAdvice(user, apiConfig),
});

export const adviceReducer = (state = createEmptyAdviceState(), action) => {
  switch (action.type) {
    case actionTypes.CCA_FETCH_ADVICE_BY_ID_COMPLETED:
      return handleFetchAdviceByIdCompleted(state, action.payload);
    case actionTypes.CCA_EDITING_ADVICE_CANCEL:
      return handleCancelAdvice(state);
    case actionTypes.CCA_UPDATE_ADVICE_FIELD:
      return handleUpdateAdviceField(state, action.payload);
    case actionTypes.CCA_ADD_ADVICE_CODES:
      return handleAddAdviceCodes(state, action.payload);
    case actionTypes.CCA_DELETE_ADVICE_CODE_RANGE:
      return handleDeleteAdviceCodes(state, action.payload);
    case actionTypes.CCA_UNDO_DELETE_ADVICE_CODE_RANGE:
      return handleUndoDeleteAdviceCodes(state, action.payload);
    case actionTypes.CCA_EXCLUDE_ADVICE_CODE_WITHIN_RANGE:
      return handleExcludeCodeWithinRange(state, action.payload);
    case actionTypes.CCA_UNDO_EXCLUDE_ADVICE_CODE_WITHIN_RANGE:
      return handleUndoExcludeCodeWithinRange(state, action.payload);
    case actionTypes.CCA_CREATE_ADVICE:
      return handleCreateAdvice(state, action.payload);
    case actionTypes.CCA_DELETE_ADVICE_COMPLETED:
      return handleDeleteAdviceCompleted();
    case actionTypes.EXPAND_CODE_RANGE_COMPLETED:
      return handleExpandCodeRangeCompleted(state, action.payload);
    case actionTypes.CCA_SAVE_ADVICE_COMPLETE:
      return handleSaveAdviceCompleted(state, action.payload);
    case actionTypes.CCA_SAVE_ADVICE_ERROR:
      return handleSaveAdviceError(state);
    default:
      return state;
  }
};

const handleExpandCodeRangeCompleted = (state: AdviceState, payload: CodeRangeExpansionEntity) => {
  const codesClone = state.inProgress.codes ? map(state.inProgress.codes, clone) : [];
  const codeToChange: AdviceCodeRangeEntity | undefined = find(codesClone, { id: payload.id });
  if (codeToChange) {
    codeToChange.count = payload.searchTotalCount;
    if (payload.codes.length === 1) {
      codeToChange.description = payload.codes[0].description;
    }
    const inProgress = {
      ...state.inProgress,
      codes: codesClone,
    };
    return {
      ...state,
      dirty: true,
      inProgress,
    };
  }
  return state;
};

interface CreateAdvicePayload {
  user: UserState;
  apiConfig: CodingAdviceAPI;
}

const handleCreateAdvice = (state: AdviceState, payload: CreateAdvicePayload): AdviceState => {
  return createEmptyAdviceState(payload.user, payload.apiConfig);
};

const handleSaveAdviceCompleted = (state: AdviceState, payload: AdviceEntity): AdviceState => {
  return {
    ...state,
    dirty: false,
    inProgress: payload,
    saved: payload,
  };
};

const handleSaveAdviceError = (state: AdviceState): AdviceState => {
  return {
    ...state,
    dirty: true,
  };
};

const handleFetchAdviceByIdCompleted = (state: AdviceState, payload: AdviceEntity): AdviceState => {
  return {
    dirty: false,
    inProgress: payload,
    saved: payload,
  };
};

const handleDeleteAdviceCompleted = (): AdviceState => {
  return createEmptyAdviceState();
};

const handleUpdateAdviceField = (state: AdviceState, payload: AdviceFieldChangePayload): AdviceState => {
  const inProgress = {
    ...state.inProgress,
    [payload.fieldValidationResult.key as string]: payload.value,
  };
  if (payload.fieldValidationResult.key === 'scope' && payload.value.toString().indexOf(FACILITY_PREFIX) !== -1) {
    inProgress.scope = UserScope.FACILITY;
    inProgress.facilityId = payload.value.toString().replace(new RegExp(`^${FACILITY_PREFIX}`), '');
  }
  if (payload.fieldValidationResult.key === 'codeType') {
    inProgress.codes = [];
  }
  return {
    ...state,
    dirty: true,
    inProgress,
  };
};

const handleAddAdviceCodes = (state: AdviceState, payload: AdviceAddCodesPayload): AdviceState => {
  if (payload.fieldValidationResult.succeeded) {
    const codesClone = state.inProgress.codes ? map(state.inProgress.codes, clone) : [];
    codesClone.push({
      count: payload.range.count,
      description: payload.range.description,
      end: payload.range.end,
      id: payload.range.id,
      start: payload.range.start,
    });
    const inProgress = {
      ...state.inProgress,
      codeType: payload.codeType,
      codes: codesClone,
    };

    return {
      dirty: true,
      inProgress,
      saved: state.saved
    };
  }
  return state;
};

const handleDeleteAdviceCodes = (state: AdviceState, payload: AdviceDeleteCodeRangePayload): AdviceState => {
  const deletedCodes = (state.inProgress.deletedCodes || []).filter(() => true);
  if (!includes(deletedCodes, payload.range.id)) {
    deletedCodes.push(payload.range.id);
  }
  const inProgress = {
    ...state.inProgress,
    deletedCodes
  };

  return {
    ...state,
    dirty: true,
    inProgress,
  };
};

const handleUndoDeleteAdviceCodes = (state: AdviceState, payload: AdviceDeleteCodeRangePayload): AdviceState => {
  const deletedCodes = (state.inProgress.deletedCodes || []).filter((c) => c !== payload.range.id);
  const inProgress = {
    ...state.inProgress,
    deletedCodes
  };

  return {
    ...state,
    dirty: true,
    inProgress
  };
};

const handleExcludeCodeWithinRange = (state: AdviceState, payload: AdviceExcludeCodeInRangePayload): AdviceState => {
  let codesClone = state.inProgress.codes ? map(state.inProgress.codes, clone) : [];
  codesClone = codesClone.map((c) => {
    let exclude = c.exclude || [];
    if (c.start === payload.range.start && c.end === payload.range.end) {
      exclude = exclude.concat(payload.code);
    }
    return {
      ...c,
      exclude
    };
  });
  const inProgress = {
    ...state.inProgress,
    codes: codesClone,
  };

  return {
    ...state,
    dirty: true,
    inProgress,
  };
};

const handleUndoExcludeCodeWithinRange = (
  state: AdviceState,
  payload: AdviceExcludeCodeInRangePayload): AdviceState => {
  const codesClone = (state.inProgress.codes || []).map((c) => {
    let exclude = c.exclude || [];
    if (c.start === payload.range.start && c.end === payload.range.end) {
      exclude = exclude.filter((e) => e !== payload.code);
    }
    return {
      ...c,
      exclude
    };
  });
  const inProgress = {
    ...state.inProgress,
    codes: codesClone,
  };

  return {
    dirty: true,
    inProgress,
    saved: state.saved
  };
};

const handleCancelAdvice = (state: AdviceState): AdviceState => {
  return {
    dirty: false,
    inProgress: state.saved,
    saved: state.saved
  };
};
