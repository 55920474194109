import * as actionTypes from '../constants';
import { LookupState, CodebookItem } from '../models';

const createEmptyLookups = (): LookupState => ({
  codebooks: [],
  error: false,
  errorDetail: '',
});

export const lookupReducer = (state = createEmptyLookups(), action) => {
  switch (action.type) {
    case actionTypes.CODEBOOKS_LIST_COMPLETED:
      return handleCodeBooksListCompleted(state, action.payload);
    case actionTypes.CODEBOOKS_LIST_FAIL:
      return handleCodeBooksListError(state, action.payload);
    default:
      return state;
  }
};

const handleCodeBooksListCompleted = (state: LookupState, payload: Array<CodebookItem>) => {
  return {
    ...state,
    codebooks: payload,
  };
};

const handleCodeBooksListError = (state: LookupState, payload: string) => {
  return {
    ...state,
    error: true,
    errorDetail: payload,
  };
};
