import * as actionTypes from '../constants';

// using Dispatch type here causes error, not sure why
export const dismissAnnouncementAction = (id: string) => (dispatch) => {
  dispatch(dismissAnnouncement(id));
};

export const getSystemAnnouncementsCompleted = (announcements) => ({
  payload: announcements,
  type: actionTypes.SYSTEM_ANNOUNCEMENTS_COMPLETE,
});

export const getSystemAnnouncementsError = (error) => ({
  payload: error,
  type: actionTypes.SYSTEM_ANNOUNCEMENT_ERROR,
});

const dismissAnnouncement = (id) => ({
  payload: id,
  type: actionTypes.SYSTEM_ANNOUNCEMENT_DISMISSED,
});
