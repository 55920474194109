import * as moment from 'moment';
import * as actionTypes from '../constants';
import { GridCodeType, UserPreferencesEntity } from '../models';
import { EncounterEntity, EncounterState } from '../models/encounterEntity';
import {
  EncounterChoiceListsGridRowUpdate,
  EncounterChoiceListsUpdate,
  EncounterFieldChangePayload,
} from '../scenes/Encounter/actions/encounterFieldChange';

import { findAllCodesInformation, findAllConditionCodesInformation } from '../services/encounter/encounterMapping';
import {
  handleValuesGridChange,
  handleValuesGridEnterEdit,
  handleValuesGridExitEdit,
  handleValuesGridKeypress,
  handleValuesGridDragAndDropRow,
  handleValuesGridNavigate,
  handleValuesGridEditLocateEvent,
} from './Helpers/encounterValuesGrid';
import { createEmptyEncounterState, cloneEncounterEntity } from './Helpers/encounterCommon';
import {
  handleEncounterOpen,
  handleCurrentEncounterSaved,
  handleEncounterStartUpdate,
  handleEncounterUpdated,
  handleEncounterStartProcess,
  handleEncounterProcessed,
  handleSaveEncounterChangesError,
  handleSaveEncounterErrorUpdate,
  handleIncorrectEncounter,
  handleCloseEncounter,
  handleMarkEncounterOutdated,
  handleSingleChoiceListUpdated,
} from './Helpers/encounterServices';
import {
  handleCodeGridChange,
  handleCodeGridExitAllEdit,
  handleCodeBooksModalCodePosted,
  handleCodeBooksModalCancelled,
  handleCodeGridCodeBlur,
  handleCodeGridFieldBlur,
  handleCodeGridKeypress,
  handleCodeGridEnterEdit,
  handleCodeGridCancelEdit,
  handleCodeGridTryExitEdit,
  handleNavigateFromAdmitDXDescription,
  handleAdmitDXDescriptionBlur,
  handleCodeGridRemove,
  handleCodeGridMoveToPdx,
  handlePdxAnalyzeCompleted,
  handleCodeGridDragAndDropRow,
  handleSetAllPOAToValue,
  handleClearGrid,
  handleInsertRow,
  handleClearEmptyRowsInGrid,
  handleUpdateExemptPOAValues,
  handleFocusFistGridField,
  handleCodeGridAutoChange,
  handleEditLocateEvent,
} from './Helpers/encounterCodeGrid';
import { handleResearchPaneCodeInteraction } from './Helpers/encounterResearchPane';
import { resequenceCodes, getResequenceMode, needResequenceEncounterCodes } from '../utils/encounter';
import { FacilityPreferences } from '../models/facilitySettings';
import { BaseCodeRow } from '../models/codeGrid';
import { ValuesGridType, isValuesOrConditionsGridClassName } from '../models/valuesGrid';
// import { PatientEncounterType } from '../models/encounterTypes';

export const encounterReducer = (state = createEmptyEncounterState(), action) => {
  switch (action.type) {
    case actionTypes.CREATE_NEW_ENCOUNTER:
      return handleCreateNewEncounter();
    case actionTypes.OPEN_ENCOUNTER_BY_ID_COMPLETED: {
      const encounterEntity = (action.payload.encounterEntity as unknown) as EncounterEntity;
      const isDirtyFromBackend = encounterEntity.IsDirty || false;
      return handleEncounterOpen(state, action.payload, {
        isNewEncounter: false,
        isDirtyEncounter: isDirtyFromBackend,
      });
    }
    case actionTypes.GET_ENCOUNTER_COMPLETED:
      return handleEncounterOpen(state, action.payload, { isNewEncounter: false, isDirtyEncounter: true });
    case actionTypes.CREATE_ENCOUNTER_FROM_TEMPLATE:
    case actionTypes.CREATE_ENCOUNTER_COMPLETED:
      return handleEncounterOpen(state, action.payload, { isNewEncounter: true, isDirtyEncounter: true });
    case actionTypes.SAVE_ENCOUNTER_COMPLETED:
      return handleCurrentEncounterSaved(state, action.payload);
    case actionTypes.SAVE_ENCOUNTER_CHANGES_BEGIN:
      return handleEncounterStartUpdate(state);
    case actionTypes.SAVE_ENCOUNTER_CHANGES_COMPLETED:
      return handleEncounterUpdated(
        state,
        action.payload.result,
        action.payload.encounterToSave,
        action.payload.serviceEncounterToSave,
        action.payload.heartbeatMode,
        action.payload.updateTime
      );
    case actionTypes.PROCESS_ENCOUNTER_BEGIN:
      return handleEncounterStartProcess(state);
    case actionTypes.PROCESS_ENCOUNTER_COMPLETED:
      return handleEncounterProcessed(state, action.payload);
    case actionTypes.SAVE_ENCOUNTER_CHANGES_ERROR:
    case actionTypes.PROCESS_ENCOUNTER_ERROR:
      return handleSaveEncounterChangesError(state, action.payload);
    case actionTypes.SAVE_ENCOUNTER_CHANGES_UPDATE_ERROR:
      return handleSaveEncounterErrorUpdate(state);
    case actionTypes.RESEARCH_EDIT_LOCATE_EVENT:
      if (isValuesOrConditionsGridClassName(action.payload?.gridCodeType || '')) {
        return handleValuesGridEditLocateEvent(state, action.payload);
      }
      return handleEditLocateEvent(state, action.payload);
    case actionTypes.CODE_GRID_CHANGE:
      return handleCodeGridChange(state, action.payload);
    case actionTypes.CODE_GRID_CLEAR_AUTOCHANGE:
      return handleCodeGridAutoChange(state, action.payload);
    case actionTypes.CODE_GRID_EXIT_ALL_EDIT:
      return handleCodeGridExitAllEdit(state);
    case actionTypes.CODEBOOKS_MODAL_CODE_POSTED:
      return handleCodeBooksModalCodePosted(state, action.payload);
    case actionTypes.CODEBOOKS_MODAL_CANCELLED:
      return handleCodeBooksModalCancelled(state, action.payload);
    case actionTypes.RESEARCH_PANE_INTERACTION:
      return handleResearchPaneCodeInteraction(state, action.payload);
    case actionTypes.CODE_GRID_BLUR_CODE_FIELD:
      return handleCodeGridCodeBlur(state, action.payload);
    case actionTypes.CODE_GRID_BLUR_FIELD:
      return handleCodeGridFieldBlur(state, action.payload);
    case actionTypes.CODE_GRID_KEYPRESS:
      return handleCodeGridKeypress(state, action.payload);
    case actionTypes.CODE_GRID_ENTER_EDIT:
      return handleCodeGridEnterEdit(state, action.payload);
    case actionTypes.CODE_GRID_CANCEL_EDIT:
      return handleCodeGridCancelEdit(state, action.payload);
    case actionTypes.CODE_GRID_EXIT_EDIT:
      return handleCodeGridTryExitEdit(state, action.payload);
    case actionTypes.NAVIGATE_FROM_ADMIT_DX_DESCRIPTION:
      return handleNavigateFromAdmitDXDescription(
        state,
        action.payload.back,
        action.payload.fieldSettings,
        action.payload.isShowingPdxAnalysis
      );
    case actionTypes.ADMIT_DX_DESCRIPTION_BLUR:
      return handleAdmitDXDescriptionBlur(state, action.payload);
    case actionTypes.CODE_GRID_REMOVE:
      return handleCodeGridRemove(state, action.payload);
    case actionTypes.CODE_GRID_MOVE_TO_PDX:
      return handleCodeGridMoveToPdx(state, action.payload);
    case actionTypes.PDX_ANALYZE_COMPLETED:
      return handlePdxAnalyzeCompleted(state, action.payload);
    case actionTypes.UPDATE_ENCOUNTER_FIELD:
      return handleUpdateEncounterField(state, action.payload);
    case actionTypes.UPDATE_ENCOUNTER_FIELDS:
      return handleUpdateEncounterFields(state, action.payload);
    case actionTypes.UPDATE_ENCOUNTER_CHOICE_LISTS:
      return handleUpdateEncounterChoiceLists(state, action.payload);
    case actionTypes.ENCOUNTER_CONFIRM_DISCARD_DIRTY_CHANGES_ENC:
      return handleDiscardEncounterDirtyChanges(state, action.payload);
    case actionTypes.CODE_GRID_DRAG_DROP_ROW:
      return handleCodeGridDragAndDropRow(state, action.payload);
    case actionTypes.OPEN_ENCOUNTER_BY_ID_ERROR:
    case actionTypes.CREATE_ENCOUNTER_ERROR:
    case actionTypes.GET_ENCOUNTER_ERROR:
      return handleIncorrectEncounter();
    case actionTypes.ENCOUNTER_CLEAR_DIRTY_CHANGES_FLAG:
      return handleClearDirtyChangesFlag(state);
    case actionTypes.ENCOUNTER_CHANGE_GROUPING:
      return handleChangeGrouping(state, action.payload);
    case actionTypes.CLOSE_ENCOUNTER_COMPLETED:
      return handleCloseEncounter();
    case actionTypes.MARK_ENCOUNTER_OUTDATED:
      return handleMarkEncounterOutdated(state);
    case actionTypes.CLEAR_GRID:
      return handleClearGrid(state, action.payload);
    case actionTypes.SET_ALL_POA_TO_VALUE:
      return handleSetAllPOAToValue(state, action.payload);
    case actionTypes.UPDATE_EXEMPT_POA_VALUES:
      return handleUpdateExemptPOAValues(state);
    case actionTypes.INSERT_ROW_BEFORE:
      return handleInsertRow(state, action.payload, true);
    case actionTypes.INSERT_ROW_AFTER:
      return handleInsertRow(state, action.payload, false);
    case actionTypes.CLEAR_EMPTY_ROWS_IN_GRID:
      return handleClearEmptyRowsInGrid(state, action.payload);
    case actionTypes.RESEQUENCE_ENCOUNTER_CODES:
      return handleResequenceEncounterCodes(state);
    case actionTypes.APPLY_FACILITY_PREFERENCES:
      return handleApplyFacilityPreferences(state, action.payload);
    case actionTypes.ENCOUNTER_GRIDS_FOCUS_FIRST_FIELD:
      return handleFocusFistGridField(state, action.payload);

    case actionTypes.VALUES_GRID_ENTER_EDIT:
      return handleValuesGridEnterEdit(state, action.payload);
    case actionTypes.VALUES_GRID_CHANGE:
      return handleValuesGridChange(state, action.payload);
    case actionTypes.VALUES_GRID_EXIT_EDIT:
      return handleValuesGridExitEdit(state, action.payload);
    case actionTypes.VALUES_GRID_KEYPRESS:
      return handleValuesGridKeypress(state, action.payload);
    case actionTypes.VALUES_GRID_DRAG_DROP_ROW:
      return handleValuesGridDragAndDropRow(state, action.payload);
    case actionTypes.VALUES_GRID_NAVIGATE:
      return handleValuesGridNavigate(state, action.payload);

    case actionTypes.GET_CHOICELIST_SUCCESS:
      return handleSingleChoiceListUpdated(state, action.payload);

    default:
      return state;
  }
};

const handleCreateNewEncounter = (): EncounterState => {
  return createEmptyEncounterState();
};

const handleUpdateEncounterField = (state: EncounterState, payload: EncounterFieldChangePayload): EncounterState => {
  const changed = state.inProgress[payload.fieldName] !== payload.value;
  if (!changed) {
    return state;
  }

  const inProgress = {
    ...state.inProgress,
    [payload.fieldName]: payload.value,
    // after each change we need to update modifiedOn field
    modifiedOn: moment(moment.now()).format('M/D/YYYY'),
  };

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

const handleUpdateEncounterFields = (state: EncounterState, payload: EncounterFieldChangePayload[]): EncounterState => {
  let changed = false;
  const inProgress = {
    ...state.inProgress,
    // after each change we need to update modifiedOn field
    modifiedOn: moment(moment.now()).format('M/D/YYYY'),
  };

  for (let ind = 0, len = payload.length; ind < len; ind++) {
    const singleChange = payload[ind];
    if (inProgress[singleChange.fieldName] !== singleChange.value) {
      inProgress[singleChange.fieldName] = singleChange.value;
      changed = true;
    }
  }

  if (!changed) {
    return state;
  }

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

const updateProviderInRows = (rows: BaseCodeRow[], result: EncounterChoiceListsGridRowUpdate[]) => {
  let changed = false;

  if (result) {
    for (let ind = 0, len = result.length; ind < len; ind++) {
      const { ViewId } = result[ind];
      const Provider = result[ind].Provider || '';
      for (let rind = 0, rlen = rows.length; rind < rlen; rind++) {
        const row = rows[rind];
        if (row.id === ViewId) {
          if (row.provider !== Provider) {
            row.provider = Provider;
            changed = true;
            break;
          }
        }
      }
    }
  }

  return changed;
};

const handleUpdateEncounterChoiceLists = (
  state: EncounterState,
  payload: EncounterChoiceListsUpdate
): EncounterState => {
  let changed = false;
  const inProgress = cloneEncounterEntity(state.inProgress);
  // after each change we need to update modifiedOn field
  inProgress.modifiedOn = moment(moment.now()).format('M/D/YYYY');

  const fieldsList = [
    { serviceField: 'Facility', clientField: 'facility' },
    { serviceField: 'EncounterType', clientField: 'type' },
    { serviceField: 'CustomField1', clientField: 'customField1' },
    { serviceField: 'CustomField2', clientField: 'customField2' },
    { serviceField: 'CustomField3', clientField: 'customField3' },
    { serviceField: 'CustomField4', clientField: 'customField4' },
    { serviceField: 'RecordStatus', clientField: 'recordStatus' },
    { serviceField: 'SourceOfAdmission', clientField: 'ofa' },
    { serviceField: 'PayerFlag', clientField: 'payerFlag' },
    { serviceField: 'PatientStatus', clientField: 'patientStatus' },
    { serviceField: 'PatientSex', clientField: 'sex' },
    { serviceField: 'Provider', clientField: 'attendingMd' },
    { serviceField: 'FinancialClass', clientField: 'financialClass' },
    { serviceField: 'Service', clientField: 'service' },
  ];

  for (let ind = 0, len = fieldsList.length; ind < len; ind++) {
    const { serviceField, clientField } = fieldsList[ind];
    const serviceValue = payload[serviceField] || '';
    const clientValue = inProgress[clientField];
    if (serviceValue !== clientValue) {
      inProgress[clientField] = serviceValue;
      changed = true;
    }
  }

  const changedIPProviders = updateProviderInRows(inProgress.inProcedures.codes, payload.IpProviders);
  const changedOPProviders = updateProviderInRows(inProgress.outProcedures.codes, payload.OpProviders);
  changed = changed || changedIPProviders || changedOPProviders;

  if (!changed) {
    return state;
  }

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};

const handleDiscardEncounterDirtyChanges = (state: EncounterState, payload): EncounterState => {
  if (!payload.confirmed) {
    // cancel
    return {
      ...state,
    };
  }

  // discard changes
  return handleCreateNewEncounter();
};

const handleClearDirtyChangesFlag = (state: EncounterState): EncounterState => {
  return {
    ...state,
    dirty: false,
  };
};

const handleGetUserPreferenceBegin = (state: EncounterState, payload: UserPreferencesEntity) => {
  return {
    ...state,
    preference: payload,
  };
};

const handleGetUserPreferenceCompleted = (state: EncounterState, payload: UserPreferencesEntity) => {
  // FIXME: Change this if/when services are updated to return first type in choicelist
  // not used now
  const encounterType = payload.value || 'IP';

  return {
    ...state,
    inProgress: {
      ...state.inProgress,
      type: encounterType,
    },
  };
};

const handleChangeGrouping = (state: EncounterState, payload: { isIPGrouping: boolean; groupId: string }) => {
  const newEncounter = {
    ...state.inProgress,
    ipGroupingType: payload.isIPGrouping ? payload.groupId : state.inProgress.ipGroupingType,
    opGroupingType: !payload.isIPGrouping ? payload.groupId : state.inProgress.opGroupingType,
  };

  // recalculate all code information using new grouping
  findAllCodesInformation(newEncounter);
  findAllConditionCodesInformation(newEncounter);

  return {
    ...state,
    inProgress: newEncounter,
  };
};

const handleResequenceEncounterCodes = (state: EncounterState) => {
  const newEncounter = resequenceCodes(state.inProgress);

  return {
    ...state,
    inProgress: newEncounter,
    dirty: true,
    lastChangeTime: moment.now(),
  };
};

// force empty processing to be activated: auto resequence is applied after /process for the facility field
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleApplyFacilityPreferences = (state: EncounterState, preferences: FacilityPreferences) => {
  const newEncounter = cloneEncounterEntity(state.inProgress);
  const { dirty, lastChangeTime } = state;

  return {
    ...state,
    inProgress: newEncounter,
    dirty,
    lastChangeTime,
    needHandling: true,
  };
};
