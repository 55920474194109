import * as React from 'react';
import { Checkbox as KCheckbox, CheckboxProps as KCheckboxProps } from '@progress/kendo-react-inputs';

import './Checkbox.less';

interface CheckboxProps extends KCheckboxProps {
  forceLabelPlacement?: boolean;
}

export const Checkbox = function render(props: CheckboxProps) {
  const { className, forceLabelPlacement, labelPlacement, ...otherProps } = props;
  // label placement is preferred after, we want to force it to be consistent
  // across the application unless absolutely certain
  const preferredLabelPlacement = forceLabelPlacement ? labelPlacement : "after";
  return <KCheckbox
    className={`tc-checkbox ${className}`}
    labelPlacement={preferredLabelPlacement}
    {...otherProps}
  />;
}

export default Checkbox;
