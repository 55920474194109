import './Grid.less';

import * as React from 'react';
import { GridColumnMenuItem as KGridColumnMenuItem } from "@progress/kendo-react-grid";
// TODO: fix this once they resolve import path
import { GridColumnMenuItemProps as KGridColumnMenuItemProps } from '@progress/kendo-react-grid/dist/npm/columnMenu/GridColumnMenuItem';

export const GridColumnMenuItem = function render(props: KGridColumnMenuItemProps ) {
  return <KGridColumnMenuItem
    {...props}
  />;
}

export interface GridColumnMenuItemProps extends KGridColumnMenuItemProps {};


