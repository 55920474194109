import { v4 as uuid } from 'uuid';

import * as actionTypes from '../constants';

import { NetActivityState, NetActivity, NetActivityStatus } from '../models/netActivity';

const createEmptyNetActivityState = (): NetActivityState => ({
  activities: [],
  hasActiveRequest: false,
});

export const netActivityReducer = (state = createEmptyNetActivityState(), action) => {
  switch (action.type) {
    case actionTypes.NET_ACTIVITY_STARTED:
      return handleStartNetActivity(state, action.payload);
    case actionTypes.NET_ACTIVITY_UPDATED:
      return handleUpdateNetActivity(state, action.payload);      
    case actionTypes.NET_ACTIVITY_RESTARTED:
      return handleRestartNetActivity(state, action.payload);      
    default:
      return state;
  }
};

const hasActiveRequest = (activities: NetActivity[]) => {
  const firstActive = activities.find(activity => activity.singletonKey === NetActivityStatus.NET_ACTIVITY_ACTIVE);
  return !!firstActive;
}

const handleStartNetActivity = (state: NetActivityState, payload: NetActivity): NetActivityState => {  
  const newState = {
    ...state,
    activities: [payload, ...state.activities],
    hasActiveRequest: true,
  };

  return newState;
}

const handleUpdateNetActivity = (state: NetActivityState, payload: NetActivity): NetActivityState => {  
  const newActivities = state.activities.map(activity => activity.id === payload.id ? { ...activity, ...payload } : activity);
  const newState = {
    ...state,
    activities: newActivities,
    hasActiveRequest: hasActiveRequest(newActivities),
  };

  return newState;
}

const handleRestartNetActivity = (state: NetActivityState, payload: NetActivity): NetActivityState => {  
  // previously started activity
  const oldActivity = state.activities.find(activity => activity.singletonKey === payload.singletonKey && activity.id !== payload.id);
  // restarted activity
  const newActivity = state.activities.find(activity => activity.id === payload.id);
  if (!oldActivity || !newActivity) {
    return state;
  }

  // change singletonKey in the cancelled activity to fix it. Change its id
  const cancelledActivity = {
    ...oldActivity,
    id: uuid(),
    singletonKey: undefined,
    endTime: newActivity.startTime,
    status: NetActivityStatus.NET_ACTIVITY_CANCELLED,
  }

  const updatedActivities = state.activities.map(activity => activity.id === oldActivity.id ? cancelledActivity : activity);
  const newState = {
    ...state,
    activities: [...updatedActivities],
    hasActiveRequest: true,
  };

  return newState;
}