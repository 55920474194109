import { v4 as uuid } from 'uuid';
import * as moment from 'moment';

import * as actionTypes from '../constants';

import { NetActivityStatus, NetActivity } from '../models/netActivity';
import { store } from '../reduxStore';

export const startNetActivityAction = (dispatch, url, singletonKey?: string) => {
  if (!dispatch) {
    return '';
  }

  const id = uuid();
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

  const netActivity: NetActivity = {
    id,
    url,
    startTime: currentTime,
    status: NetActivityStatus.NET_ACTIVITY_ACTIVE,
    singletonKey
  }

  dispatch(netActivityStarted(netActivity));

  return id;
};

const netActivityStarted = (netActivity) => ({
  payload: netActivity,
  type: actionTypes.NET_ACTIVITY_STARTED,
});

export const stopNetActivityWithErrorAction = (dispatch, id, errorReason, responseStatus) => {
  if (!dispatch || !id) {
    return;
  }
  
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

  const netActivity: NetActivity = {
    id,
    errorReason,
    responseStatus,
    endTime: currentTime,
    status: NetActivityStatus.NET_ACTIVITY_ERROR,
  }

  dispatch(netActivityUpdated(netActivity));
};

const netActivityUpdated = (netActivity) => ({
  payload: netActivity,
  type: actionTypes.NET_ACTIVITY_UPDATED,
});

export const stopNetActivityAction = (dispatch, id, responseStatus?: string | number) => {
  if (!dispatch || !id) {
    return;
  }

  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

  const netActivity: NetActivity = {
    id,
    endTime: currentTime,
    status: NetActivityStatus.NET_ACTIVITY_COMPLETED,
    responseStatus: responseStatus ? `${responseStatus}` : ''
  }

  dispatch(netActivityUpdated(netActivity));
};

export const restartNetActivityAction = (dispatch, id, singletonKey) => {
  if (!dispatch || !id || !singletonKey) {
    return;
  }

  const netActivity: NetActivity = {
    id,
    singletonKey,    
  }

  dispatch(netActivityRestarted(netActivity));
};

const netActivityRestarted = (netActivity) => ({
  payload: netActivity,
  type: actionTypes.NET_ACTIVITY_RESTARTED,
});


export const addTokenToStorageAction = (dispatch, tokenInfo) => {
  if (!dispatch || !tokenInfo) {
    return;
  }
  
  dispatch(tokenUpdated(tokenInfo));
};

const tokenUpdated = (tokenInfo) => ({
  payload: tokenInfo,
  type: actionTypes.B2C_UPDATE_TOKEN_INFO,
});

// allow functions to be available in the trucode.all.js

window.startNetActivity = (url) => {
  return startNetActivityAction(store?.dispatch, url);
}

window.stopNetActivityWithError = (id, errorReason, responseStatus) => {
  return stopNetActivityWithErrorAction(store?.dispatch, id, errorReason, responseStatus);
}

window.stopNetActivity = (id, responseStatus?: string | number) => {
  return stopNetActivityAction(store?.dispatch, id, responseStatus);
}
