import './Grid.less';

import * as React from 'react';
import { Grid as KGrid, GridProps as KGridProps, GridRowProps as KGridRowProps } from "@progress/kendo-react-grid";

interface GridProps extends React.PropsWithChildren<KGridProps> {
  disabled?: boolean;
}

export class Grid extends React.PureComponent<GridProps> {
  public static displayName = 'KendoReactGrid';

  render() {
    // force rowHeight to be 40
    const { className, rowHeight, disabled, editField, cellRender, rowRender, ...otherProps } = this.props;
    const isDisabled = typeof disabled !== 'undefined' && disabled === true;

    return <KGrid
      rowHeight={40}
      className={`tc-grid ${isDisabled ? 'tc-grid-disabled' : ''} ${className}`}
      // prevent editing onclick if disabled
      editField={isDisabled ? undefined : editField}
      cellRender={isDisabled ? undefined : cellRender}
      rowRender={isDisabled ? undefined : rowRender}
      {...otherProps}
    />;
  }
}
export interface GridRowProps extends KGridRowProps { };


