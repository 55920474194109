import { GridCodeType } from "../../../models";
import { BaseCodeRow } from "../../../models/codeGrid";

export const isSOIFlag = (flag) => {
  return flag[0].toUpperCase() === 'S';
}

export const isROMFlag = (flag) => {
  return flag[0].toUpperCase() === 'R';
}

export const isSOIROMFlag = (flag) => {
  return isSOIFlag(flag) || isROMFlag(flag);
}

export const affectsRisk = (flag) => {
  return flag[0] === 'S' || flag[0] === 'R';
}

export const getSOIROMTooltip = (flag: string) => {
  const affectsText = affectsRisk(flag) ? 'Affects ' : 'Does Not Affect ';
  const flagText = flag[0].toUpperCase() === 'S' ? 'Severity of Illness' : 'Risk of Mortality';

  if (flag.toUpperCase() === 'SP' || flag.toUpperCase() === 'RP') {
    return `${flag[0].toUpperCase()}: ${affectsText}${flagText}`;
  }

  return `${flag.toUpperCase()}: ${affectsText}${flagText}, Level ${flag[1]}`;
}

export const getUsualFlagTooltip = (flag) => {
  switch (flag) {
    case 'CC':
      return 'CC: Complication/Comorbid Condition';
    case 'H':
      return 'H: Hospital Acquired Condition that is not used in the calculation of the DRG due to POA of N or U';
    case 'MC':
      return 'MC: Major Complication/Comorbid Condition'
    case 'NOR':
      return 'NOR: Non-Operative Room Procedure';
    case 'OR':
      return 'OR: Operative Room Procedure';
    case 'AD':
      return 'AD: Affects DRG'; //, but is not a CC or MC
    case 'CF':
      return 'CF: Code First Adjustment Factor';
    default:
      break;
  }

  return '';
}

export const getAffectsDrgText = (grouperFlagAffectsDRG) => {
  return grouperFlagAffectsDRG ? 'AD: Affects DRG' : 'Does Not Affect DRG';
}

// Flags filtering and preparations to rendering
export const prepareCodeFlags = (code: BaseCodeRow, grouperFlags: string[], gridCodeType: GridCodeType, rowIndex: number) => {
  let allFlags: string[] = grouperFlags;
  let result: string[] = [];
  if (gridCodeType === GridCodeType.DIAGNOSES) {
    // keep only grid specific flags. Set them in the order like backend
    const foundFlags = {
      AD: false,
      CC: false,
      CF: false,
      H: false,
      MC: false,
    };

    const len = allFlags.length;
    let SOIFlags: string[] = [];
    let ROMFlags: string[] = [];

    for (let ind = 0; ind < len; ind++) {
      if (allFlags[ind] === "AD") {
        foundFlags.AD = true;
      } else if (allFlags[ind] === "MC") {
        foundFlags.MC = true;
      } else if (allFlags[ind] === "CF") {
        foundFlags.CF = true;
      } else if (allFlags[ind] === "CC") {
        foundFlags.CC = true;
      } else if (allFlags[ind] === "H") {
        foundFlags.H = true;
      } else if (isSOIFlag(allFlags[ind])) {
        SOIFlags.push(allFlags[ind])
      } else if (isROMFlag(allFlags[ind])) {
        ROMFlags.push(allFlags[ind])
      }
    }

    if (foundFlags.MC) {
      result.push("MC");
    }
    if (foundFlags.CF) {
      result.push("CF");
    }
    if (foundFlags.CC) {
      result.push("CC");
    }
    if (foundFlags.H) {
      result.push("H");
    }
    result = [...result, ...SOIFlags, ...ROMFlags];

    if (foundFlags.AD && rowIndex > 0 && !(foundFlags.MC || foundFlags.CC || foundFlags.H)) {
      result.push("AD");
    }
    code.grouperFlag = result.join(", ");
    code.grouperFlagAffectsDRG = foundFlags.AD;
    code.grouperFlagHFlag = foundFlags.H;
  } else if (gridCodeType === GridCodeType.INPROCEDURES) {
    // keep only grid specific flags. Set them in the order like backend
    const foundFlags = {
      AD: false,
      OR: false,
      NOR: false,
    };

    const len = allFlags.length;
    let SOIFlags: string[] = [];
    let ROMFlags: string[] = [];

    for (let ind = 0; ind < len; ind++) {
      if (allFlags[ind] === "AD") {
        foundFlags.AD = true;
      } else if (allFlags[ind] === "OR") {
        foundFlags.OR = true;
      } else if (allFlags[ind] === "NOR" || allFlags[ind] === "Non-OR") {
        foundFlags.NOR = true;
      } else if (isSOIFlag(allFlags[ind])) {
        SOIFlags.push(allFlags[ind])
      } else if (isROMFlag(allFlags[ind])) {
        ROMFlags.push(allFlags[ind])
      }
    }

    if (foundFlags.OR) {
      result.push("OR");
    }
    if (foundFlags.NOR) {
      result.push("NOR");
    }
    result = [...result, ...SOIFlags, ...ROMFlags];

    if (foundFlags.AD && rowIndex > 0 && !(foundFlags.OR || foundFlags.NOR)) {
      result.push("AD");
    }
    code.grouperFlag = result.join(", ");
    code.grouperFlagAffectsDRG = foundFlags.AD;
    code.grouperFlagHFlag = undefined;
  } else {
    // clear flags for other grid types
    code.grouperFlag = undefined;
    code.grouperFlagAffectsDRG = undefined;
    code.grouperFlagHFlag = undefined;
  }
}