/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLogic } from 'redux-logic';
import * as actionTypes from '../constants';
import { EncounterEntity } from '../models';
import { LogicLifecycleParams } from '../reduxStore';
import { drgAnalyzeBegin, drgAnalyzeCompleted, drgAnalyzeError, drgAnalyzeNoDataError } from '../scenes/Encounter/actions/grouping';
import { encounterAPI } from '../services/encounter';
import { encounterGroupingNeedProcess, findGroupingResult } from '../services/encounter/encounterMapping';

export const drgAnalyzeLogic = createLogic({
  type: [
    actionTypes.OPEN_ENCOUNTER_BY_ID_COMPLETED,
    actionTypes.PROCESS_ENCOUNTER_COMPLETED,
    actionTypes.ENCOUNTER_CHANGE_GROUPING,
  ],
  latest: true, // take latest only

  process({ getState }: LogicLifecycleParams, dispatch: any, done: any) {
    const currentEncounter: EncounterEntity = getState().encounter.inProgress;

    const resetDrgState = () => {
      // clear fields and update containers
      if (getState().drgAnalyze.result) {
        dispatch(drgAnalyzeBegin());
        dispatch(drgAnalyzeNoDataError());
      }
    }

    // ignore drgAnalyze until we process encounter for outdated grouping
    if (encounterGroupingNeedProcess(currentEncounter, currentEncounter.ipGroupingType) !== null) {
      resetDrgState();
      done();
      return;
    }

    if (currentEncounter.ipGroupingType) {
      const groupingResult = findGroupingResult(true, currentEncounter, currentEncounter.ipGroupingType);
      // do not analyze if Drg is empty
      if (!groupingResult?.Values?.Drg) {
        resetDrgState();
        done();
        return;
      }

      dispatch(drgAnalyzeBegin());

      encounterAPI
        .analyzeEncounter(currentEncounter)
        .then((res) => {
          // console.log('result', res);
          dispatch(drgAnalyzeCompleted(res));
          done();
        })
        .catch((reason) => {
          dispatch(drgAnalyzeError(reason));
          done(); // call when finished dispatching
        });
    } else {
      resetDrgState();
      done();
    }
  },
});

// multiple logic groups can be exported here
export default [drgAnalyzeLogic];
