import * as actionTypes from '../constants';
import { LicensePackage } from '../models/license';
import { licenseAPI } from '../services/license';

export const getAvailableLicensePackagesAction = () => (dispatch) => {
  dispatch(getAvailableLicensePackagesActionBegin());

  return licenseAPI.getAvailableLicensePackages()
    .then((list) => {
      dispatch(getAvailableLicensePackagesActionCompleted(list));
    })
    .catch((error) => {
      dispatch(getAvailableLicensePackagesActionError(error));
    });

};

const getAvailableLicensePackagesActionBegin = () => ({
  payload: '',
  type: actionTypes.AVAILABLE_LICENSE_PACKAGES_BEGIN,
});

const getAvailableLicensePackagesActionCompleted = (list: LicensePackage[]) => ({
  payload: list,
  type: actionTypes.AVAILABLE_LICENSE_PACKAGES_COMPLETED,
});

const getAvailableLicensePackagesActionError = (error: Error) => ({
  payload: error,
  type: actionTypes.AVAILABLE_LICENSE_PACKAGES_ERROR,
});
