import './Menu.less';

import * as React from 'react';
import { Router, match } from 'react-router-dom';
import * as History from 'history';
import { history } from '../../../../reduxStore';
import TruLink from '../../../TruLink/TruLink';
import { Icon, IconType } from '../../../Icon/Icon';
import { UserRole, UserState } from '../../../../models';
import { Button, ButtonMode } from '../../..';

export interface SettingsMenuProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  match: match<any>;
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  onBlur: () => void;
  onMenuOpen: (el: HTMLUListElement | null) => void;
  user: UserState;
  isErrorMode?: boolean;
  encounterOpened: boolean;
}

export default class SettingsMenu extends React.PureComponent<SettingsMenuProps, {}> {
  ref: HTMLUListElement | null;
  timeout: number;

  constructor(props: SettingsMenuProps) {
    super(props);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    this.props.onMenuOpen(this.ref);
  }

  render() {
    const isErrorMode = this.props.isErrorMode || false;
    const canManageFacilities = this.props.user.isSystemAdmin || this.props.user.highestRole >= UserRole.ADMIN;
    const canManageUsers = this.props.user.isSystemAdmin;
    const canManageSystemSettings = this.props.user.privileges.canSystemSettings;

    const facilitiesButton = canManageFacilities
      ? (
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to="/settings/facility"
          isErrorMode={isErrorMode}
          encounterOpened={this.props.encounterOpened}
        >
          <span className="external-button">
            <Button
              mode={ButtonMode.Transparent}
              iconType={IconType.Building}
              label="Facilities" />
            {this.props.encounterOpened ? <Icon type={IconType.ExternalLink}/> : undefined}
          </span>
        </TruLink>
      )
      : (
        <Button
          mode={ButtonMode.Transparent}
          iconType={IconType.Building}
          disabled
          label="Facilities" />
      );

    const systemButton = canManageSystemSettings
      ? (
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to="/settings/system"
          isErrorMode={isErrorMode}
          encounterOpened={this.props.encounterOpened}
        >
          <span className="external-button">
            <Button
              mode={ButtonMode.Transparent}
              iconType={IconType.Wrench}
              label="System" />
            {this.props.encounterOpened ? <Icon type={IconType.ExternalLink}/> : undefined}
          </span>
        </TruLink>
      )
      : (
        <Button
          mode={ButtonMode.Transparent}
          disabled
          iconType={IconType.Wrench}
          label="System" />
      );

    const usersButton = canManageUsers
      ? (
        <TruLink
          hasChangesCallback={this.props.hasChangesCallback}
          to="/settings/users"
          isErrorMode={isErrorMode}
          encounterOpened={this.props.encounterOpened}
        >
          <span className="external-button">
            <Button
              mode={ButtonMode.Transparent}
              disabled={!canManageUsers}
              iconType={IconType.Users}
              label="Users" />
            {this.props.encounterOpened ? <Icon type={IconType.ExternalLink}/> : undefined}
          </span>
        </TruLink>
      )
      : (
        <Button
          mode={ButtonMode.Transparent}
          disabled
          iconType={IconType.Users}
          label="Users" />
      );

    return (
      <Router history={history}>
        <ul className="tc-appbar-menu left" ref={(e) => { this.ref = e; }} tabIndex={0} onFocus={this.onFocus} onBlur={this.onBlur}>
          <li>{facilitiesButton}</li>
          <li>{systemButton}</li>
          <li>{usersButton}</li>
        </ul>
      </Router>
    );
  }

  private onFocus() {
    clearTimeout(this.timeout);
  }

  private onBlur() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.props.onBlur);
  }
}
