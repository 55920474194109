import { addErrorsAction } from '../../../actions/errors';
import { getRecoveredEncountersBegin, getRecoveredEncountersCompleted, getRecoveredEncountersError } from '../../../actions/initialize';
import * as actionTypes from '../../../constants';
import { RecoveredEncounterEntity, RecoveredEncountersState } from '../../../models';
import { encounterAPI } from '../../../services/encounter';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';

export const removeEncounterFromRecoveryAction = (
  encounterId: string) => (dispatch) => {

  return encounterAPI.
    removeRecoveredEncounter(encounterId)
    .then(() => {
      return encounterAPI.getRecoveredEncounters()
        .then((encounters) => {
          dispatch(removeEncounterFromRecoveryCompleted(encounters));
        })
    })
    .catch((error) => {
      dispatch(removeEncounterFromRecoveryError(error));
    })
};

export const getRecoveredEncountersAction = (refresh = false) => (dispatch) => {

  if(!refresh) {
    dispatch(getRecoveredEncountersBegin()); // reload recovery encounters
  }
  return encounterAPI
    .getRecoveredEncounters()
    .then((recoveredEncounters) => {
      dispatch(getRecoveredEncountersCompleted(recoveredEncounters));
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Cannot Retrieve Recovered Encounters');
      dispatch(addErrorsAction(errors));
      dispatch(getRecoveredEncountersError('Cannot Retrieve Recovered Encounters'));
    })

}

export const clearRemoveEncounterFromRecoveryAction = (state: RecoveredEncountersState) => (dispatch) => {
  dispatch(clearRemoveEncounterFromRecoveryError(state));
};

const removeEncounterFromRecoveryCompleted = (encounters: RecoveredEncounterEntity[]) => ({
  payload: encounters,
  type: actionTypes.REMOVE_ENCOUNTER_FROM_RECOVERY_COMPLETED
});

const removeEncounterFromRecoveryError = (error: Error) => ({
  payload: error,
  type: actionTypes.REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR
});

const clearRemoveEncounterFromRecoveryError = (state: RecoveredEncountersState) => ({
  payload: state,
  type: actionTypes.CLEAR_REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR
});
