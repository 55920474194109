import "./Skeleton.less";

import * as React from "react";
import { Skeleton as KSkeleton, SkeletonProps as KSkeletonProps } from "@progress/kendo-react-indicators";

export interface SkeletonProps extends KSkeletonProps {
  type?: "button" | "circle" | "input" | "grid-row" | "grid-header" | "header";
}

export const Skeleton = function render(props: SkeletonProps) {
  const { className, shape, type, ...otherProps } = props;
  let finalShape = shape;
  switch (type) {
    case "button":
    case "input":
    case "grid-row":
    case "grid-header":
      finalShape = "rectangle";
      break;
    case "circle":
      finalShape = "circle";
      break;
    case "header":
    default:
      break;

  }
  return <KSkeleton
    className={`tc-skeleton tc-skeleton-${type} ${className}`}
    shape={finalShape}
    {...otherProps}
  />;
}

export interface SkeletonWrapProps {
  layout?: "row" | "column";
  className?: string;
}
export const SkeletonWrap = function render(props: React.PropsWithChildren<SkeletonWrapProps>) {
  return (<div className={`tc-skeleton-wrap tc-skeleton-${props.layout || 'column'} ${props.className}`}>{props.children}</div>);
}
