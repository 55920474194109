import './AppBar.less';

import * as React from 'react';
import { AppBarSpacer as KAppBarSpacer, AppBarSpacerProps as KAppBarSpacerProps } from '@progress/kendo-react-layout';

export const AppBarSpacer = function render(props: KAppBarSpacerProps) {
  const { className, ...otherProps } = props;
  return <KAppBarSpacer
    className={`tc-appbar-spacer ${className}`}
    {...otherProps}
  />;
}
