import './Grid.less';

import * as React from 'react';
import { GridColumn as KGridColumn, GridColumnProps as KGridColumnProps, GridColumnMenuProps as KGridColumnMenuProps } from "@progress/kendo-react-grid";

export class GridColumn extends React.PureComponent<GridColumnProps> {
  public static displayName = 'KendoReactGridColumn';

  render() {
    const { className, ...otherProps } = this.props;
    return <KGridColumn
      className={`tc-grid-column ${className}`}
      {...otherProps}
    />;
  }
}

export interface GridColumnProps extends KGridColumnProps { };
export interface GridColumnMenuProps extends KGridColumnMenuProps { };

