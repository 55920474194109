import { DDLValues } from '.';
import { UserRole } from "./user";

const FA = { title: 'Facility Admin', id: UserRole.ADMIN };
const PC = { title: 'Power Coder', id: UserRole.POWER };
const C = { title: 'Coder', id: UserRole.CODER };
const CM = { title: 'Case Manager', id: UserRole.MANAGER };
const NR = { title: 'No Role', id: UserRole.NOROLE };

export const FacilityRoleCollection: DDLValues[] = [
  FA,
  PC,
  C,
  CM,
  NR
];
