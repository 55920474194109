import * as actionTypes from '../constants';
import { CodeRangeExpansionEntity, CodeRangeExpansionRequestEntity, CodeRangeExpansionState } from '../models';

const createEmptyExpandedCode = (): CodeRangeExpansionState => ({});

export const expandedCodeReducer = (state = createEmptyExpandedCode(), action) => {
  switch (action.type) {
    case actionTypes.EXPAND_CODE_RANGE_START:
      return handleExpandCodeRangeStart(state, action.payload);
    case actionTypes.EXPAND_CODE_RANGE_COMPLETED:
      return handleExpandCodeRangeCompleted(state, action.payload);
    case actionTypes.EXPAND_CODE_RANGE_ERROR:
      return handleExpandCodeRangeError(state, action.payload);
    default:
      return state;
  }
};

const handleExpandCodeRangeStart = (state: CodeRangeExpansionState, payload: CodeRangeExpansionRequestEntity) => {
  return {
    ...state,
    [payload.id]: {
      loading: true,
      request: payload,
      response: null,
    }
  };
};

const handleExpandCodeRangeCompleted = (state: CodeRangeExpansionState, payload: CodeRangeExpansionEntity) => {
  return {
    ...state,
    [payload.id]: {
      loading: false,
      request: state[payload.id].request,
      response: payload
    }
  };
};

const handleExpandCodeRangeError = (state: CodeRangeExpansionState, payload: CodeRangeExpansionEntity) => {
  return {
    ...state,
    [payload.id]: {
      loading: false,
      request: state[payload.id].request,
      response: payload
    }
  };
};
