import './page.less';
import '../../components/AppWrapper/AppWrapper.less'

import * as React from 'react';
import { PageHeader, PageContent, Page, Ripple, AppBar, AppBarSection, TruLink } from '../../components';
import { ConnectedRouter } from 'connected-react-router';

const AUTH_ERRORS = {
  expiredinvitation: 'Your invitation has expired. Please contact your site administrator to request a new invitation.',
  toomanyretries: 'You have exceeded the maximum retry count allowed.',
}

const AuthErrorPage = (props) => {
  const search = window.location.search.substr(1);
  const params = search.split('&');
  const firstParamParts = params.length  && params[0].split('=');
  const errorValueParam = firstParamParts && firstParamParts.length > 1 && firstParamParts[0] === 'error' && firstParamParts[1];
  const errorValue = decodeURIComponent(errorValueParam || '');
  const errorText = errorValue && AUTH_ERRORS[errorValue] ? AUTH_ERRORS[errorValue] : 'Cannot authorize';
  const errorDescription = errorText === 'Cannot authorize' ? (errorValue || 'Unknown error') : '';

  return (
    <ConnectedRouter history={props.history}>
      <Ripple disabled>
          <AppBar themeColor="inherit" positionMode="static" className="tc-appbar">
            <AppBarSection>
                <TruLink
                  isErrorMode
                  to="/"
                >
                  <h1 id="logo-title" className="title">{process.env.REACT_APP_PRODUCT_NAME}</h1>
                </TruLink>
            </AppBarSection>
          </AppBar>
          <div className="contents-wrapper">
              <Page id="error-page">
                <PageHeader>&nbsp;</PageHeader>
                <PageContent>
                  <div className="error-context">
                    <div className='error-text'>
                      {errorText}
                    </div>
                    <div className='error-description'>
                      {errorDescription}
                    </div>
                  </div>
                </PageContent>
              </Page>
            </div>
      </Ripple>
    </ConnectedRouter>
  );
};

export default AuthErrorPage;
