import './Avatar.less';

import * as React from 'react';
import { Avatar as KAvatar } from '@progress/kendo-react-layout';
// FIXME: fix once export is corrected
import { AvatarProps as KAvatarProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/AvatarProps';

export const Avatar = function render(props: KAvatarProps) {
  const { className, ...otherProps } = props;
  return <KAvatar
    className={`tc-avatar ${className}`}
    {...otherProps}
  />;
}
