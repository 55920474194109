import './Card.less';

import * as React from 'react';
import { CardTitle as KCardTitle } from '@progress/kendo-react-layout';
// FIXME: once this export is fixed
import { CardTitleProps as KCardTitleProps } from '@progress/kendo-react-layout/dist/npm/card/interfaces/CardTitleProps';

export const CardTitle = function render(props: KCardTitleProps) {
  const { className, ...otherProps } = props;
  return <KCardTitle
    className={`tc-card-title ${className}`}
    {...otherProps}
  />;
}
