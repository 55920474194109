export function formatTotalCharges(initialValue?: string | number): string {
  let value = initialValue || '';
  if (value === undefined || value === '') {
    return '';
  }

  if (typeof value === 'number') {
    value = value.toString();
  }

  // remove leading and finishing 0
  while (value[0] === '0') {
    value = value.substring(1);
  }

  if (value.indexOf('.') !== -1) {
    while (value[value.length - 1] === '0') {
      value = value.substring(0, value.length - 1);
    }
  }

  if (!value || value === '.') {
    return '';
  }

  // add leading 0 before .
  if (value[0] === '.') {
    value = `0${value}`;
  }

  // remove finishing .
  if (value[value.length - 1] === '.') {
    value = value.substring(0, value.length - 1);
  }

  return value;
}
