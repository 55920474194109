import * as moment from 'moment';
import { ConcurrencyTarget } from '../../models/concurrencyTargetEnum';
import { IdDescriptionBase } from '../../models/patientEncounter';
import { InterfaceRegistration } from '../../scenes/SystemPreferences/models/interfaceRegistration';
import { GeneralPreferenceObject, OrganizationObject, TestOrganizationSyncStatus, SystemPreferencesObject, SystemPreferencesTypeSpecificObject, OrganizationSecurityOptionsObject, AuditItemObject } from '../../scenes/SystemPreferences/models/systemPreferences';
import { deleteEndpoint, get, post } from '../../utils/net';
import { EncoderUserEntity } from '../../models';

const baseURL = `${process.env.REACT_APP_API_PATH}/api`;


const getInterfaceTypes = (): Promise<IdDescriptionBase[]> => {
  const url = `${baseURL}/interface/types`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    const types =  result.body.map(rec => { return { id: rec.ViewId || '', title: rec.Name }; });
    return types;
  });
};

const getInterfaceSystemPreferences = (): Promise<SystemPreferencesObject> => {
  const url = `${baseURL}/systempreferences/interface`;

  const extraOptions = {
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

const getInterfaceTypeSpecificPreferences = (type: string): Promise<SystemPreferencesTypeSpecificObject> => {
  const url = `${baseURL}/systempreferences/interface/${type}`;

  const extraOptions = {
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

const updateInterfaceSystemPreferences = (_newPreferences: SystemPreferencesObject): Promise<SystemPreferencesObject> => {
  const url = `${baseURL}/systempreferences/interface`;

  const newPreferences = _newPreferences;
  const Version = newPreferences.ConcurrencyVersion;
  const headers: { 'If-Match'?: string } = {};

  if (Version) {
    headers['If-Match'] = Version;
    delete newPreferences.ConcurrencyVersion;
  }

  const extraOptions = {
    headers,
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return post(url, newPreferences, extraOptions).then((result) => {
    return result.body;
  });
};

const updateInterfaceTypeSpecificPreferences = (type: string, typeSpecificPreferences: SystemPreferencesTypeSpecificObject): Promise<SystemPreferencesTypeSpecificObject> => {
  const url = `${baseURL}/systempreferences/interface/${type}`;

  const newPreferences = typeSpecificPreferences;
  const Version = newPreferences.ConcurrencyVersion;
  const headers: { 'If-Match'?: string } = {};

  if (Version) {
    headers['If-Match'] = Version;
    delete newPreferences.ConcurrencyVersion;
  }

  const extraOptions = {
    headers,
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return post(url, typeSpecificPreferences, extraOptions).then((result) => {
    return result.body;
  });
};

const getRegisteredInterfaces = (): Promise<IdDescriptionBase[]> => {
  const url = `${baseURL}/SystemPreferences/InterfaceClientRegistrations`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

const registerInterface = (newRegistration: InterfaceRegistration): Promise<InterfaceRegistration> => {
  const url = `${baseURL}/SystemPreferences/InterfaceClientRegistrations`;

  const extraOptions = {
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return post(url, newRegistration, extraOptions).then((result) => {
    return result.body;
  });
};

const deleteRegisteredInterface = (ViewId: string): Promise<boolean> => {
  const url = `${baseURL}/SystemPreferences/InterfaceClientRegistrations/${ViewId}`;

  const extraOptions = {
    useExactPath: true,
  };

  return deleteEndpoint(url, extraOptions).then((result) => {
    return result.body;
  });
}

const getGeneralPreferences = (): Promise<GeneralPreferenceObject> => {
  const url = `${baseURL}/SystemPreferences/GetGeneralOptions`;

  const extraOptions = {
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
}

const updateGeneralPreferences = (option, ConcurrencyVersion): Promise<string> => {
  const url = `${baseURL}/SystemPreferences/UpdateGeneralOptions`;

  const version = ConcurrencyVersion || '_FORCE_DEV_';

  const headers = {
    'If-Match': version,
  };

  const extraOptions = {
    useExactPath: true,
    headers,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return post(url, option, extraOptions).then((result) => {
    return result.body?.ConcurrencyVersion;
  });
}

const getCurrentOrganization = (): Promise<OrganizationObject> => {
  const url = `${baseURL}/organizations/current`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return { ...result.body,
      ConcurrencyVersion: result.header.etag };
  });
};

const setCurrentOrganization = (organization): Promise<OrganizationObject> => {
  const url = `${baseURL}/organizations/update`;

  const newOrganization = organization;
  const Version = organization.ConcurrencyVersion;
  const headers: { 'If-Match'?: string } = {};

  if (Version) {
    headers['If-Match'] = Version;
    delete newOrganization.ConcurrencyVersion;
  }

  const extraOptions = {
    headers,
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return post(url, organization, extraOptions).then((result) => {
    return { ...result.body,
      ConcurrencyVersion: result.header.etag };
  });
};

const createTestOrganization = (options): Promise<OrganizationObject> => {
  const url = `${baseURL}/organizations/create/test`;

  const extraOptions = {
    useExactPath: true
  };

  return post(url, options, extraOptions).then((result) => {
    return result.body;
  });
};

const syncTestOrganizationSettings = (options): Promise<any> => {
  const url = `${baseURL}/organizations/synctestorgsettings/start`;

  const extraOptions = {
    useExactPath: true
  };

  return post(url, options, extraOptions).then((result) => {
    return result;
  });
};

const checkSyncTestOrganizationSettings = (): Promise<TestOrganizationSyncStatus> => {
  const url = `${baseURL}/organizations/synctestorgsettings/status`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

const getOrganizationSecurityOptions = (): Promise<OrganizationSecurityOptionsObject> => {
  const url = `${baseURL}/Organizations/OrganizationSecurityOptions`;

  const extraOptions = {
    useExactPath: true,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
}

const updateOrganizationSecurityOptions = (options): Promise<string> => {
  const url = `${baseURL}/Organizations/OrganizationSecurityOptions`;
  const version = options.ConcurrencyVersion || '_FORCE_DEV_';
  const newOptions = {
    ...options,
    ConcurrencyVersion: undefined,
  }

  const headers = {
    'If-Match': version,
  };

  const extraOptions = {
    useExactPath: true,
    headers,
    concurrencyTarget: ConcurrencyTarget.Encounter,
  };

  return post(url, newOptions, extraOptions).then((result) => {
    return result.body?.ConcurrencyVersion;
  });
}

const getAuditCategoryList = (): Promise<any> => {
  const url = `${baseURL}/AuditLogMaintenance/eventtypes`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
}

const getAuditExportLogCsv = (startDate?: string, endDate?: string, selectedUser?: EncoderUserEntity, auditList?: AuditItemObject[]): Promise<any> => {
  const url = `${baseURL}/AuditLogMaintenance/export${createExportQueryString(startDate, endDate, selectedUser, auditList)}`;

  const extraOptions = {
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) => {
    return result;
  });
}

const createExportQueryString = (startDate?: string, endDate?: string, selectedUser?: EncoderUserEntity, auditList?: AuditItemObject[]): string => {
  if(!(startDate || endDate)) {
    throw new Error('Start and/or end date are missing');
  }

  const utcStart = convertDateStringToUTC(startDate);
  const utcEnd = convertDateStringToUTC(`${endDate} 23:59:59`);

  const start = moment(utcStart, 'ddd, D MMM YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
  const end = moment(utcEnd, 'ddd, D MMM YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
  let queryString = `?From.Timestamp=${start}&To.Timestamp=${end}`;

  if(selectedUser) {
    queryString += `&Match.UserId=${selectedUser.userId}`
  }

  if(auditList && auditList.length > 0) {
    if(!(auditList.every((item) => item.Selected))) {
      auditList.forEach((item) => {
        if(item.Selected) {
          queryString += `&eventtype=${item.Id}`
        }
      });
    }
  }

  // sort by datetime
  queryString += '&SortAsc.Timestamp=1';

  return queryString;
}

const convertDateStringToUTC = (date?: string) => {
  if(!date) {
    return undefined;
  }

  const dateValue = new Date(date);
  return dateValue.toUTCString();
}

export const systemPreferencesAPI = {
  getInterfaceTypes,
  getInterfaceSystemPreferences,
  getInterfaceTypeSpecificPreferences,
  updateInterfaceSystemPreferences,
  updateInterfaceTypeSpecificPreferences,
  getRegisteredInterfaces,
  registerInterface,
  deleteRegisteredInterface,
  getGeneralPreferences,
  updateGeneralPreferences,
  getCurrentOrganization,
  setCurrentOrganization,
  createTestOrganization,
  syncTestOrganizationSettings,
  checkSyncTestOrganizationSettings,
  getOrganizationSecurityOptions,
  updateOrganizationSecurityOptions,
  getAuditCategoryList,
  getAuditExportLogCsv,
};
