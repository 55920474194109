import * as React from 'react';
import find from 'lodash/find';
import { DropDownList, DropDownListProps, ListItemProps } from '..';
import { LicensePackage, LicenseState } from '../../models/license';

import './LicenseDDL.less';

const FAKE_DEFAULT_LICENSE_ID = 'FAKE_DEFAULT_LICENSE_ID';
export interface LicenseDDLProps extends DropDownListProps {
  license: LicenseState;
  getAvailableLicensePackages: () => Promise<void>;
  value?: string;
  // the license active for the session
  activeLicense?: string;
}

export class LicenseDDL extends React.PureComponent<LicenseDDLProps> {

  componentDidMount() {
    this.props.getAvailableLicensePackages();
  }

  render() {
    const { onChange, value, ...otherProps } = this.props;
    const itemRender = this.itemRender.bind(this);
    const selectedValue = this.props.license.packages.find((el, idx) => { return el.ViewId === this.props.value });
    return (
      <>
        <DropDownList
          dataItemKey="ViewId"
          textField="PackageName"
          name="licensePackageId"
          data={this.props.license.packages}
          itemRender={itemRender}
          popupSettings={{ className: "license-ddl-popup" }}
          value={selectedValue}
          label="License"
          loading={this.props.license.loading}
          onChange={this.props.onChange}
          defaultItem={{ ViewId: FAKE_DEFAULT_LICENSE_ID, PackageName: 'Select a license...' }}
          {...otherProps}
        />
        <p className="license-note"><b>Note:</b> Changes will not take effect until the user logs back in.</p>
      </>
    );
  }

  private itemRender(li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps) {
    const taken = itemProps?.dataItem?.TakenSeatCount || 0;
    const total = itemProps?.dataItem?.TotalSeatCount || 0;
    const remaining = total - taken;
    const className = `item-wrap ${remaining === 0 ? 'unavailable' : 'available'}`;
    const itemChildren = (
      <div className={className}>
        <span className="package-name">{li.props.children}</span>
        {itemProps?.dataItem?.ViewId === this.props.activeLicense && (<span className="current">(current)</span>)}
        <span className="seats">{remaining} of {total} {total === 1 ? 'license' : 'licenses'} remaining</span>
      </div>
    );
    const itemClass = remaining > 0 ? 'k-item' : 'k-item k-state-disabled';
    const props = {
      ...li.props,
      className: itemClass,
    }
    return React.cloneElement(li, props, itemChildren);
  }
}
