import * as moment from 'moment';
import * as actionTypes from '../constants';
import {
  initColumnsSettingsAction,
  initShowColumnsReorderWarningAction,
  initShowColumnsResizeWarningAction,
} from './gridColumnsSettings';
import { userPreferencesAPI } from '../services/userPreferences';
import { parseServiceErrors } from '../services/encounter/serviceErrors';
import { addErrorsAction } from './errors';
import { currentUserAPI } from '../services/currentUser';
import { CurrentUserEntity, RecoveredEncounterEntity } from '../models';
import { systemAnnouncementsAPI } from '../services/systemAnnouncements';
import { getSystemAnnouncementsCompleted, getSystemAnnouncementsError } from './announcements';
import { encounterAPI } from '../services/encounter';
import { systemPreferencesAPI } from '../services/systemPreferences';
import { getGeneralPreferenceActionCompleted, getGeneralPreferenceActionError } from '../scenes/SystemPreferences/actions/generalPreferenceAction';
import { getCurrentUserOrganizationComplete } from '../scenes/SystemPreferences/actions/organizationAction';

// using Dispatch type here causes error, not sure why
export const initializeAction = () => (dispatch) => {
  dispatch(initializeStart());
  dispatch(getUserPreferencesBegin());
  dispatch(getCurrentUserBegin());
  dispatch(getRecoveredEncountersBegin());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initCalls: Promise<any>[] = [];

  // system announcements
  initCalls.push(
    systemAnnouncementsAPI
      .getSystemAnnouncements()
      .then((announcements) => {
        dispatch(getSystemAnnouncementsCompleted(announcements));
      })
      .catch((reason) => {
        dispatch(
          getSystemAnnouncementsError(
            [
              {
                description: 'Error Retrieving System Announcements',
                error: 'Error Retrieving System Announcements',
                time: moment.now(),
              },
            ]
          ));
      })
  );

  // User preferences
  initCalls.push(
    userPreferencesAPI
      .getUserPreferences()
      .then((preferences) => {
        dispatch(getUserPreferencesCompleted(preferences));
      })
      .catch((reason) => {
        const errors = parseServiceErrors(reason, 'Error Retrieving User Preferences');
        dispatch(addErrorsAction(errors));
        dispatch(getUserPreferencesError('Error Retrieving User Preferences'));
        throw new Error('User preferences fetch error');
      })
  );

  // Recovered Encounters (if any)
  initCalls.push(
    encounterAPI
      .getRecoveredEncounters()
      .then((recoveredEncounters) => {
        dispatch(getRecoveredEncountersCompleted(recoveredEncounters));
      })
      .catch((reason) => {
        const errors = parseServiceErrors(reason, 'Cannot Retrieve Recovered Encounters');
        dispatch(addErrorsAction(errors));
        dispatch(getRecoveredEncountersError('Cannot Retrieve Recovered Encounters'));
      })
  );

  // User permissions
  initCalls.push(
    currentUserAPI
      .getCurrentUser({ includeRoles: false })
      .then((user) => {
        dispatch(getCurrentUserCompleted(user));
      })
      .catch((reason) => {
        const errors = parseServiceErrors(reason, 'Error Retrieving Current User');
        dispatch(addErrorsAction(errors));
        dispatch(getCurrentUserError('Error Retrieving Current User'));
        throw new Error('Current user fetch error');
      })
  );

  initCalls.push(
    systemPreferencesAPI
      .getGeneralPreferences()
      .then((generalPreferences) => {
        dispatch(getGeneralPreferenceActionCompleted(generalPreferences))
      })
      .catch((reason) => {
        const errors = parseServiceErrors(reason, 'Error Retrieving General Preferences');
        dispatch(addErrorsAction(errors));
        dispatch(getGeneralPreferenceActionError(reason));
      })
  );

  initCalls.push(
    systemPreferencesAPI
      .getCurrentOrganization()
      .then((organization) => {
        tenantId = organization.ViewId;
        dispatch(getCurrentUserOrganizationComplete(organization || ''));
      })
      .catch((reason) => {
        const errors = parseServiceErrors(reason, 'Error Retrieving Organization Name');
        dispatch(addErrorsAction(errors));
        dispatch(getGeneralPreferenceActionError(reason));
      })
  );

  /*
  let loadedFacilities: IdDescriptionBase[] = [];
  // TODO: stop fetching facilities until necessary
  // application loading sequence. I want to have single flag (initializing) to indicate loading
  initCalls.push(
    choiceListsAPI
      .getFacilities()
      .then((facilities) => {
        loadedFacilities = facilities;
        dispatch(getFacilitiesCompleted(facilities));
        if (!loadedFacilities || loadedFacilities.length === 0) {
          dispatch(getFacilitiesError('No Facilities'));
        }
      })
      .catch(() => {
        dispatch(getFacilitiesError('Facilities fetch error'));
        throw new Error('Facilities fetch error');
      })
  );
  */

  let tenantId: string | undefined;

  Promise.all(initCalls)
    .then(() => {
      dispatch(initColumnsSettingsAction());
      dispatch(initShowColumnsReorderWarningAction());
      dispatch(initShowColumnsResizeWarningAction());
      dispatch(initializeCompleted());

      const previousTenant = localStorage.getItem("previousTenant");
      localStorage.setItem('previousTenant', tenantId || '');

      // navigate to home page is tenant id is different from before
      if (((previousTenant && previousTenant !== tenantId)) && window.location.pathname !== '/') {
        window.location.assign('/');
      }
    })
    .catch((reason: Error) => {
      dispatch(initializeError({ errorReason: 'Error while initializing', errorDetail: reason.message }));
    });
};

const initializeStart = () => ({
  type: actionTypes.INITIALIZE_BEGIN,
});

const initializeCompleted = () => ({
  payload: '',
  type: actionTypes.INITIALIZE_COMPLETED,
});

const initializeError = (error: { errorReason: string; errorDetail: string }) => ({
  payload: error,
  type: actionTypes.INITIALIZE_FAIL,
});

const getUserPreferencesBegin = () => ({
  type: actionTypes.GET_USER_PREFERENCES_BEGIN,
});

const getUserPreferencesCompleted = (preferences: object) => ({
  payload: preferences,
  type: actionTypes.GET_USER_PREFERENCES_COMPLETED,
});

const getUserPreferencesError = (error: string) => ({
  payload: error,
  type: actionTypes.GET_USER_PREFERENCES_ERROR,
});

const getCurrentUserCompleted = (user: CurrentUserEntity) => ({
  payload: user,
  type: actionTypes.GET_CURRENT_USER_COMPLETED,
});

const getCurrentUserError = (error: string) => ({
  payload: error,
  type: actionTypes.GET_CURRENT_USER_ERROR,
});

const getCurrentUserBegin = () => ({
  type: actionTypes.GET_CURRENT_USER_BEGIN,
});

export const getRecoveredEncountersBegin = () => ({
  type: actionTypes.GET_RECOVERED_ENCOUNTERS_BEGIN,
});

export const getRecoveredEncountersCompleted = (encounters: RecoveredEncounterEntity[]) => ({
  payload: encounters,
  type: actionTypes.GET_RECOVERED_ENCOUNTERS_COMPLETED,
});

export const getRecoveredEncountersError = (errors: string) => ({
  payload: errors,
  type: actionTypes.GET_RECOVERED_ENCOUNTERS_ERROR,
});
