import './Button.less';

import * as React from 'react';
import { Button as KButton, ButtonLook, ButtonProps as KButtonProps } from '@progress/kendo-react-buttons';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { Icon, IconType } from '../Icon/Icon';

export enum ButtonMode {
  Default,
  Transparent,
  Clear,
}

interface ButtonProps extends KButtonProps {
  iconType?: IconType;
  className?: string;
  // TODO: why our styles are so different? see fcCard
  useKendoClassName?: boolean;
  tooltip?: string;
  onClick?: (e: React.MouseEvent) => void;
  // TODO: consider making this a list of strings instead
  mode?: ButtonMode;
  label?: string;
  showMenu?: boolean;
  iconPosition?: 'left' | 'right';
  badge?: string | number;
  danger?: boolean;
}

export class Button extends React.PureComponent<ButtonProps> {
  htmlElement: HTMLSpanElement | null;

  render() {
    const hasIcon = this.props.iconType ? 'has-icon' : '';
    const cn = this.props.className || '';
    const iconOnly = (hasIcon && !this.props.label) ? 'icon-only' : '';
    let className = this.props.useKendoClassName ? '' : `${cn} ${hasIcon} ${iconOnly} tc-button`;
    let buttonMode: ButtonLook | "clear" = "default";
    switch (this.props.mode) {
      case ButtonMode.Clear:
        buttonMode = "clear";
        break;
      case ButtonMode.Transparent:
        buttonMode = "flat";
        break;
      case ButtonMode.Default:
      default:
        buttonMode = "default";
        break;
    }
    if (this.props.danger) { className += ' tc-danger'; }
    return (
      <KButton
        disabled={this.props.disabled}
        primary={this.props.primary}
        selected={this.props.selected}
        togglable={this.props.togglable}
        className={className}
        onClick={this.props.onClick}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        title={this.props.title}
        look={buttonMode}
        id={this.props.id}
      >
        {typeof this.props.badge !== 'undefined' &&
          <BadgeContainer>
            <Icon type={this.props.iconType} />
            <Badge>{this.props.badge}</Badge>
          </BadgeContainer>
        }
        {!this.props.badge && this.props.iconType && this.props.iconPosition !== 'right' &&
          <Icon type={this.props.iconType} />
        }
        {this.props.label &&
          <span className="label" ref={e => { this.htmlElement = e }}>{this.props.label}</span>
        }
        {this.props.children}
        {this.props.iconType && this.props.iconPosition === 'right' &&
          <Icon
            type={this.props.iconType}
            className="right" />
        }
        {
          this.props.showMenu && typeof this.props.label !== 'undefined' &&
          <Icon
            type={IconType.ChevronDown}
            className="tc-btn-transparent tc-icon-button tc-menu" />
        }
      </KButton>
    );
  }
}
