import * as History from 'history';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { WindowId } from '../../constants/tee';
import { openStandaloneWindow } from '../../utils/window';

interface TruLinkProps extends LinkProps {
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  clickHandler?: () => void;
  preventNavigation?: boolean;
  singleInstanceId?: WindowId;
  currentUrl?: string;
  isActive?: boolean;
  isErrorMode?: boolean;
  encounterOpened?: boolean;
}

export class TruLink extends React.Component<TruLinkProps> {
  constructor(props: TruLinkProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  render() {
    let className = this.props.className ? this.props.className : '';
    const isErrorMode = this.props.isErrorMode || false;
    if (this.props.isActive || this.props.to === this.props.currentUrl) {
      className = `${className} active`;
    }
    return isErrorMode
      ? (
        <a
          tabIndex={this.props.tabIndex}
          className={className}
          href={this.props.to.toString()}
          onClick={this.onClick}>
          {this.props.children}
          id={this.props.id}
        </a>
      )
      : (
        <Link
          tabIndex={this.props.tabIndex}
          className={className}
          to={this.props.to}
          replace={this.props.replace}
          onClick={this.onClick}
          target={this.props.encounterOpened ? "_blank" : "_self"}
          id={this.props.id}
        >
          {this.props.children}
        </Link>
      );
  }

  onClick = (e) => {
    if (this.props.singleInstanceId) {
      e.preventDefault();
      openStandaloneWindow(this.props.singleInstanceId, this.props.to as string);
    } else {
      if (this.props.preventNavigation) {
        e.preventDefault();
      }

      if (this.props.hasChangesCallback && !this.props.encounterOpened) {
        // if we must show discard changes dialog:
        // - do not change location
        // - show dialog
        //      - do nothing if location changing cancelled
        //      - change location and execute clickHandler after if changes discarded
        e.preventDefault();
        this.props.hasChangesCallback(this.props.to, this.props.clickHandler);
      } else if (this.props.clickHandler) {
        // - execute special handling after location changing
        // we can bind necessary clickHandler to any Link button
        setTimeout(this.props.clickHandler, 0);
      }
      else if (this.props.encounterOpened) {
        e.preventDefault();
        window.open(this.props.to);
      }
    }
  }
}

export default TruLink;
