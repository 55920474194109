import './Splitter.less';

import * as React from 'react';
import { SplitterOnChangeEvent as KSplitterOnChangeEvent, Splitter as KSplitter, SplitterProps as KSplitterProps, SplitterPaneProps as KSplitterPaneProps } from '@progress/kendo-react-layout';

export interface SplitterProps extends KSplitterProps { }
export interface SplitterPaneProps extends KSplitterPaneProps { }
export interface SplitterOnChangeEvent extends KSplitterOnChangeEvent { }

export class Splitter extends React.PureComponent<KSplitterProps> {
  public static displayName = 'Splitter';

  render() {
    const { className, ...otherProps } = this.props;
    return <KSplitter
      className={`tc-splitter ${className}`}
      {...otherProps}
    />;
  }
}
