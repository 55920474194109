
import { Dialog, DialogActionsBar } from '..';
import * as React from 'react';
import { ErrorDetails } from '../../models';
import { State } from '../reducers';

interface GlobalErrorDialogProps {
  visible: boolean;
  onCopyClick: () => void;
  toggleDialog: () => void;
  errorDetails?: ErrorDetails;
  stackCopied?: boolean;
  globalState: State;
}

interface GlobalErrorDialogState {
  showErrorDialog: boolean;
}

export class GlobalErrorDialog extends React.PureComponent<GlobalErrorDialogProps, GlobalErrorDialogState> {
  constructor(props: GlobalErrorDialogProps) {
    super(props);

    this.state = {
      showErrorDialog: !!this.props.errorDetails,
    }

    this.onCopy = this.onCopy.bind(this);
  }

  render() {
    const stateData = JSON.stringify(this.props.globalState, null, 2);
    return (
      <div className="global-error-dialog">
        { this.props.visible &&
          <Dialog title="Unhandled  Error" onClose={this.props.toggleDialog}>
            <p style={{ margin: '25px', textAlign: 'center', fontSize: '16px'}}>An unhandled error has occurred. Please copy any fiddler
          logs and/or F12 Network logs and create a GUI case with details</p>
            <p style={{ whiteSpace: 'pre-wrap', maxHeight: '200px', overflowY: 'scroll' }}><code>{this.props.errorDetails.stack}</code></p>
            <div>
              <h3>State Data</h3>
              <p style={{ whiteSpace: 'pre-wrap', maxHeight: '200px', maxWidth: '1200px', overflowY: 'scroll' }}><code>{stateData}</code></p>
            </div>
            <DialogActionsBar>
              <button className="k-button k-primary" onClick={this.props.toggleDialog} type="button">Dismiss</button>
              <button
                className='k-button k-primary'
                onClick={this.onCopy}
                type="button"
              >
                {this.props.stackCopied ? 'Copied!' : 'Copy exception' }
              </button>
            </DialogActionsBar>
          </Dialog>}
      </div>

    );
  }

  onCopy() {
    this.props.onCopyClick();
  }
}
