import { FacilityEntity } from "../scenes/Facility/models/facilities";
import { UserRole } from '../models';

export const userHasAvailableFacilities = (facilities: FacilityEntity[]) => {
  if (facilities.length === 0) {
    return false;
  }

  return true;
}

export const facilityIsAvailableForUser = (facility: string, facilities: FacilityEntity[]) => {
  if (facilities.find(available => available.ViewId === facility)) {
    return true;
  }

  return false;
}

export const mapRoleEnumToRole = (roleName: UserRole): string => {
  switch (roleName) {
    case UserRole.ADMIN:
      return 'FacilityAdmin';
    case UserRole.CODER:
      return 'Coder';
    case UserRole.POWER:
      return 'PowerCoder';
    case UserRole.MANAGER:
      return 'CaseManager';
    case UserRole.NOROLE:
    default:
      return 'NoRole';
  }
}
