import { connect } from 'react-redux';
import { State } from '../../../reducers';
import { FacilityConfigurationMenu } from './FacilityConfigurationMenu';

const mapStateToProps = (state: State) => ({
  router: state.router,
  facilities: state.facilities,
});

const mapDispatchToProps = (dispatch) => ({
});

export const FacilityConfigurationMenuContainer = connect(mapStateToProps, mapDispatchToProps)(FacilityConfigurationMenu);
