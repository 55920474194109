import { FieldValidationResult } from 'lc-form-validation';

export const isNumberValidator = (value: string) => {
  const validationResult = new FieldValidationResult();
  validationResult.errorMessage = 'Incorrect Value.';
  validationResult.type = 'INCORRECT_NUMBER';

  validationResult.succeeded = true;

  if (!value) {
    return validationResult;
  }

  const trimValue = value.trim();

  if (Number.isNaN(Number(trimValue))) {
    validationResult.succeeded = false;
    return validationResult;
  }

  return validationResult;
};
