/* eslint-disable @typescript-eslint/no-explicit-any */
import * as moment from 'moment';
import { ConcurrencyTarget } from '../../models/concurrencyTargetEnum';
import { NetCancel, TEENet } from '../../types/tee';

const TIMEOUT = 0;

export interface ExtraOptions {
  // use provided url
  useExactPath?: boolean;
  // additional headers
  headers?: object;
  // the field to return concurrency version
  concurrencyTarget?: ConcurrencyTarget;
  method?: string;
  // use provided headers
  useExactHeaders?: boolean;
  // do not add any Content-Type
  skipContentTypeHeader?: boolean;
  // the same key for requests
  singletonKey?: string;
}

const handleError = (t: JQueryXHR) => {
  let errorDetail = '';
  if (t.statusText) {
    if (t.responseText) { errorDetail = t.responseText; }
  } else {
    errorDetail = t.responseText;
  }
  throw new Error(errorDetail);
};

export const convertToPromise = <T>(
  pathOrOptionsOrMethod: any | (() => JQueryPromise<T>),
  optionsOrMethod: any | (() => JQueryPromise<T>),
  method?: (...args: any[]) => JQueryPromise<T>) => {
  let resolver;
  let rejecter;
  const promise = new Promise<T>((resolve, reject) => {
    resolver = resolve;
    rejecter = reject;
  });
  if (typeof optionsOrMethod === 'function') {
    optionsOrMethod.call(null, resolver, rejecter);
  } else if (typeof optionsOrMethod === 'object' && typeof method === 'function') {
    method.call(null, optionsOrMethod, resolver, rejecter);
  }
  return promise.catch(handleError);
};

export const convertGetToPromise = <T>(
  path: string,
  options: any,
  method: (...args: any[]) => JQueryPromise<T>) => {
  let resolver;
  let rejecter;
  const promise = new Promise<T>((resolve, reject) => {
    resolver = resolve;
    rejecter = reject;
  });
  // -> .call(this, path, data, callback, errorback, exactUri?)
  method.call(null, path, options, resolver, rejecter, true);
  return promise.catch(handleError);
};

export const get = <T>(Net: TEENet, url: string, cancelRequestRef?: NetCancel, extraOptions?: ExtraOptions) => {
  return tryUntilTimeout<T>(Net.get, url, null, cancelRequestRef, extraOptions);
};

export const post = <T>(Net: TEENet, url: string, body: any,
  cancelLoadToken?: NetCancel, extraOptions?: ExtraOptions) => {
  return tryUntilTimeout<T>(Net.post, url, body, cancelLoadToken, extraOptions);
};

const tryUntilTimeout = <T>(
  teeFunc: (
    path: string,
    data: any,
    callback?: (data: any, textStatus: string, jqXHR: JQueryXHR) => void,
    errorback?: (jqxhr: JQueryXHR, textStatus: string, error: string) => void,
    useExactPath?: boolean,
    headers?: object,
    method?: string) => void,
  url: string,
  body?: any,
  _cancelRequestRef?: NetCancel,
  extraOptions?: ExtraOptions) => {

  const cancelRequestRef = _cancelRequestRef;
  const useExactPath = extraOptions && extraOptions.useExactPath;
  const headers = extraOptions && extraOptions.headers ? extraOptions.headers : undefined;
  const method = extraOptions && extraOptions.method ? extraOptions.method : undefined;

  let ok;
  let err;
  const promise = new Promise<T>((resolve, reject) => {
    ok = resolve;
    err = reject;
  });
  const startTime = moment();
  const exec = () => {
    let resolver;
    let rejecter;
    const teePromise = new Promise<T>((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });
    const req: any = body
      ? teeFunc(url, body, (v, t, x) => { onNetResponse(v, t, x, resolver, extraOptions); },
        rejecter, useExactPath, headers, method)
      : teeFunc(url, null, (v, t, x) => { onNetResponse(v, t, x, resolver, extraOptions); },
        rejecter, useExactPath, headers);
    if (cancelRequestRef) {
      cancelRequestRef.cancel = () => {
        if (req.abort) { req.abort(); }
        if (req.reject) { req.reject(); }
      };
    }
    return teePromise.then(ok).catch((jqxhr: JQueryXHR) => {
      const now = moment();
      const duration = moment.duration(now.diff(startTime));
      if (duration.asSeconds() < TIMEOUT) {
        exec();
      } else {
        err(jqxhr);
      }
    });
  };
  exec();
  return promise.catch(handleError);
};

const onNetResponse = (data: any, textStatus: string, xhr: JQueryXHR, resolver: any, extraOptions?: ExtraOptions) => {
  const concurrencyTarget = extraOptions && extraOptions.concurrencyTarget ? extraOptions.concurrencyTarget : undefined;
  if (concurrencyTarget) {
    checkHeaders(data, xhr, concurrencyTarget);
  }
  resolver(data);
};

const checkHeaders = (_data: any, x: JQueryXHR, concurrencyTarget?: ConcurrencyTarget) => {
  let data = _data;
  const concurrency = x.getResponseHeader('ETag') || x.getResponseHeader('X-TC-ConcurrencyVersion');
  if (concurrency) {
    switch (concurrencyTarget) {
      case ConcurrencyTarget.Encounter:
        data.ConcurrencyVersion = concurrency;
        break;
      case ConcurrencyTarget.Changeset:
        // FIXME: it can't be returned. But this concurrencyTarget is not used
        data = {
          changes: data,
          concurrencyVersion: concurrency,
        };
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(`concurrency wasn't handled! ${concurrency}`);
        break;
    }
  }
};
