import { SystemAnnouncement } from '../../models/systemAnnouncements';
import { get, post } from '../../utils/net';

const baseURL = `${process.env.REACT_APP_API_PATH}/api`;

const getSystemAnnouncements = (): Promise<SystemAnnouncement[]> => {
  const url = `${baseURL}/announcements`;

  const extraOptions = {
    useExactPath: true
  };

  return get(url, extraOptions).then((result) => {
    return result.body;
  });
};

export const systemAnnouncementsAPI = {
  getSystemAnnouncements,
};
