import { CodeValidation } from "./encounterServiceEntity";

export enum ValuesGridType {
  CONDITION_CODES = 'CONDITION_CODES',
  VALUE_CODES = 'VALUE_CODES'
}

export const VALUES_GRIDS_CLASS_NAMES = {
  CONDITION_CODES: 'condtioncodesgrid',
  VALUE_CODES: 'valuecodesgrid,'
}

export const isValuesGridClassName = (value: string) => {
  return value === VALUES_GRIDS_CLASS_NAMES.VALUE_CODES;
}

export const isConditionsGridClassName = (value: string) => {
  return value === VALUES_GRIDS_CLASS_NAMES.CONDITION_CODES;
}

export const isValuesOrConditionsGridClassName = (value: string) => {
  return isValuesGridClassName(value) || isConditionsGridClassName(value);
}

export interface ValuesRow {
  ConditionCode?: string;
  ValueCode?: string;
  Amount?: string;

  id: string;
  gridType: ValuesGridType;
  empty?: boolean;
  inEdit?: boolean;
  editField?: string;

  Validations?: CodeValidation[];
}
