import './Menu.less';

import * as React from 'react';
import { Router, match } from 'react-router-dom';
import * as History from 'history';
import { UserState } from '../../../../models';
import TruLink from '../../../TruLink/TruLink';
import { B2CState } from '../../../../models/b2c';
import { Button, ButtonMode, Icon, IconType } from '../../..';

export interface HelpMenuProps {
  logout: () => void;
  changePassword: () => void;
  user: UserState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  match: match<any>;
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  onBlur: () => void;
  onMenuOpen: (el: HTMLUListElement | null) => void;
  b2c: B2CState;
  isErrorMode?: boolean;
  encounterOpened?: boolean;
}

export default class HelpMenu extends React.PureComponent<HelpMenuProps> {
  ref: HTMLUListElement | null;
  timeout: number;

  constructor(props: HelpMenuProps) {
    super(props);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    this.props.onMenuOpen(this.ref);
  }

  render() {
    const isErrorMode = this.props.isErrorMode || false;
    return (
      <ul className="tc-appbar-menu left" ref={(e) => { this.ref = e; }} tabIndex={0} onFocus={this.onFocus} onBlur={this.onBlur}>
        <li>
          <a href={window.runtimeConfig.helpUri} target="_blank" className="help" rel="noreferrer">
            <span className="external-button">
              <Button
                mode={ButtonMode.Transparent}
                iconType={IconType.HelpAlt}
                label="Help" />
              <Icon type={IconType.ExternalLink}/>
            </span>
          </a>
        </li>
        <li>
          <TruLink
            to="/about"
            hasChangesCallback={this.props.hasChangesCallback}
            isErrorMode={isErrorMode}
            encounterOpened={this.props.encounterOpened}
          >
            <span className="external-button">
              <Button
                mode={ButtonMode.Transparent}
                iconType={IconType.InfoAlt}
                label="About" />
              {this.props.encounterOpened ? <Icon type={IconType.ExternalLink}/> : undefined}
            </span>
          </TruLink>
        </li>
      </ul>
    );
  }

  private onFocus() {
    clearTimeout(this.timeout);
  }

  private onBlur() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.props.onBlur);
  }
}
