import * as actionTypes from '../constants';
import { userPreferencesAPI } from '../services/userPreferences';
import { UserPreferencesEntity } from '../models';
import { addErrorsAction } from './errors';
import { parseServiceErrors } from '../services/encounter/serviceErrors';

export const setUserPreferenceAction = (preference: string, value: any) => (dispatch) => {
  dispatch(setUserPreferenceBegin());

  userPreferencesAPI.setPreference(preference, value).then((userPreference) => {
    dispatch(setUserPreferenceCompleted(userPreference));
  })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Error Setting User Preference');
      dispatch(addErrorsAction(errors));
      dispatch(setUserPreferenceError(reason));
    })
};

const setUserPreferenceBegin = () => ({
  type: actionTypes.SET_USER_PREFERENCE_BEGIN,
});

const setUserPreferenceCompleted = (userPreference: UserPreferencesEntity) => ({
  payload: userPreference,
  type: actionTypes.SET_USER_PREFERENCE_COMPLETED,
});

const setUserPreferenceError = (error: string) => ({
  payload: error,
  type: actionTypes.SET_USER_PREFERENCE_ERROR,
});
