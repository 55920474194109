import './Debug.less';

import * as React from 'react';
import { NetActivity } from '../../models/netActivity';
import { Grid, GridColumn, GridNoRecords } from '../index';

interface NetActivityGridProps {
  activities: NetActivity[];
}

export class NetActivityGrid extends React.Component<NetActivityGridProps, {}> {
  constructor(opts: NetActivityGridProps) {
    super(opts);

    this.state = {};
  }

  render() {
    return (
      <div className='network-activities'>
        Network Activity:
        <div className="network-activities-grid">
          <Grid
            style={{ height: '100%' }}
            data={this.props.activities}
            rowHeight={40}
          >
            <GridColumn
              field="url"
              title="URL"
              width="380"
            />
            <GridColumn
              field="status"
              title="Status"
              width="120"
            />
            <GridColumn
              field="startTime"
              title="Start"
              width="150"
            />
            <GridColumn
              field="endTime"
              title="End"
              width="150"
            />
            <GridColumn
              field="responseStatus"
              title="Response Status"
              width="150"
            />
            <GridColumn
              field="errorReason"
              title="Error Reason"
              width="400"
            />

            <GridNoRecords>
              No Network Activity
            </GridNoRecords>
          </Grid>
        </div>
      </div>
    );
  }
}

