export interface UserActivityState {
  lastActivity: string;           // last user activity datetime
  showTimeoutDialog: boolean;     // timeout dialog is visible
  showTimeoutDialogFrom?: string; // time when timeout dialog was shown
  shownTimeout: number;           // timeout on the dialog (59...0)
}

let idleInMinutes = 30;
const idleTime = window.runtimeConfig?.idleTime;
try {
  idleInMinutes = parseInt(idleTime.toString() || '30', 10);
} catch (e) {
  // swallow
}
export const WAIT_TICKS = 60;
export const SECOND_TIMEOUT = 1000;
export const IDLE_TIMEOUT = idleInMinutes * 60 * SECOND_TIMEOUT;
export const THROTTLE_TIMEOUT = 30 * SECOND_TIMEOUT;
export const IDLE_TIMER_TICK_TIMEOUT = 0.2 * SECOND_TIMEOUT;

export const DEFAULT_IDLE_TIME_NUMBER = 30;

export const DEFAULT_IDLE_TIME = process.env.REACT_APP_IDLE_TIME_MINS?.toString() || DEFAULT_IDLE_TIME_NUMBER.toString()
export const DEFAULT_CALCULATED_IDLE_TIME = parseInt((process.env.REACT_APP_IDLE_TIME_MINS?.toString() || DEFAULT_IDLE_TIME_NUMBER.toString()) , 10) * 60 * SECOND_TIMEOUT
