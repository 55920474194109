export enum Key {
  Backspace = 'Backspace',
  Enter = 'Enter',
  Escape = 'Escape',
  Insert ='Insert',
  Tab = 'Tab',
  Space = ' ',
  G = 'g', // Used for cell grid keyboard shortcut
  Delete = 'Delete',
}

export const isDigitKey = (key: string) => {
  return parseInt(key, 10) >= 0 && parseInt(key, 10) <= 9;
}

export const isAgeCharacterKey = (key: string) => {
  const code = key && key.toUpperCase();
  return code === 'Y' || code === 'D' || code === 'M';
}

export const isWeightCharacterKey = (key: string) => {
  const code = key && key.toUpperCase();
  return code === 'L' || code === 'B' || code === 'S'
    || code === 'O' || code === 'Z' || code === 'G';
}

export const isServiceKey = (key: string) => {
  if (key === Key.Backspace || key === 'Home'
    || key === 'End' || key === 'ArrowRight'
    || key === 'ArrowUp' || key === 'ArrowDown'
    || key === 'ArrowLeft' || key === Key.Tab
    || key === Key.Delete || key === 'Insert') {
    return true;
  }

  return false;
}
