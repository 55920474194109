import { addErrorsAction } from '../../../actions/errors';
import * as actionTypes from '../../../constants';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';
import { systemPreferencesAPI } from '../../../services/systemPreferences';
import { checkForConcurrencyError } from '../../../utils/checks';
import { concurrencyFacilityVersionError } from '../../FacilityPreferences/actions/preferencesActions';
import { GeneralPreferenceObject } from '../models/systemPreferences';

export const getGeneralPreferenceAction = () => (dispatch) => {
  dispatch(getGeneralPreferenceActionBegin());

  const error = `Cannot get general system preferences`;

  return systemPreferencesAPI
    .getGeneralPreferences()
    .then((generalPreferences) => {
      dispatch(getGeneralPreferenceActionCompleted(generalPreferences));
    })
    .catch(() => {
      dispatch(getGeneralPreferenceActionError(error));
    });
};

export const setGeneralPreferenceAction = (Name: string, Value: string, allGeneralPreferences: GeneralPreferenceObject) => (dispatch) => {
  dispatch(setGeneralPreferenceActionBegin());

  const error = `Cannot Set${ Name}`;

  const preferenceObject = {
    [Name]: Value,
  }

  return systemPreferencesAPI
    .updateGeneralPreferences(preferenceObject, allGeneralPreferences.ConcurrencyVersion)
    .then((newVersion) => {
      const newPreferences = {
        ...allGeneralPreferences,
        ...preferenceObject,
        ConcurrencyVersion: newVersion,
      }
      dispatch(setGeneralPreferenceActionCompleted(newPreferences));
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, error);
      if (checkForConcurrencyError(errors)) {
        dispatch(concurrencyFacilityVersionError(errors));
        dispatch(setGeneralPreferenceActionError(error));
      } else {
        dispatch(addErrorsAction(errors));
        dispatch(setGeneralPreferenceActionError(error));
      }
    });
};

export const getGeneralPreferenceActionBegin = () => ({
  type: actionTypes.GET_GENERAL_PREFERENCE_BEGIN,
});

export const getGeneralPreferenceActionCompleted = (value: GeneralPreferenceObject) => ({
  payload: value,
  type: actionTypes.GET_GENERAL_PREFERENCE_COMPLETED,
});

export const getGeneralPreferenceActionError = (error: string) => ({
  payload: error,
  type: actionTypes.GET_GENERAL_PREFERENCE_ERROR,
});


const setGeneralPreferenceActionBegin = () => ({
  payload: '',
  type: actionTypes.SET_GENERAL_PREFERENCE_BEGIN,
});

const setGeneralPreferenceActionCompleted = (value) => ({
  payload: value,
  type: actionTypes.SET_GENERAL_PREFERENCE_COMPLETED,
});

const setGeneralPreferenceActionError = (error: string) => ({
  payload: error,
  type: actionTypes.SET_GENERAL_PREFERENCE_ERROR,
});
