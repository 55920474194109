import { isCriticalLevelError } from '../scenes/Encounter/components/CodeGrid/TooltipTypes';
import { CodeValidation } from './encounterServiceEntity';

export const MISSING_DIAGNOSIS_STRING = 'Missing ICD-10 Principal diagnosis';
export const MISSING_ADMIT_DX_DIAGNOSIS_STRING =
  'Admitting diagnosis or Reason for Visit may be required. The UB-04 requires Reason for Visit code(s) for all unscheduled outpatient visits which are described as Revenue Codes of 045X (ER), 0516 (Urgent Care Clinic), 0526 (Free Standing Urgent Care Clinic), or 0762 (Observation Room) together with Form Locator 14 (priority of visit/type of admission) codes 1 (Emergency), 2 (Urgent), or 5 (Trauma)';

export interface ValidationInfo {
  description: string;
  level: string;
}

// can be string or object with description + level
export type SingleValidationError = string | ValidationInfo;
export type ValidationErrors = SingleValidationError | SingleValidationError[];

export interface EncounterValidation {
  medicalRecordNumber?: ValidationErrors;
  lastName?: ValidationErrors;
  firstName?: ValidationErrors;
  middleInitial?: ValidationErrors;
  dateOfBirth?: ValidationErrors;
  ageInYears?: ValidationErrors;
  sex?: ValidationErrors;
  birthWeight?: ValidationErrors;

  accountNumber?: ValidationErrors;
  facility?: ValidationErrors;
  type?: ValidationErrors;
  service?: ValidationErrors;
  ofa?: ValidationErrors;
  admitDate?: ValidationErrors;
  dischargeDate?: ValidationErrors;
  financialClass?: ValidationErrors;
  patientStatus?: ValidationErrors;
  billType?: ValidationErrors;
  los?: ValidationErrors;
  overrideLOS?: ValidationErrors;
  attendingMd?: ValidationErrors;
  managedCareFlag?: ValidationErrors;
  totalCharges?: ValidationErrors;
  thirdPartyLiability?: ValidationErrors;
  recordStatus?: ValidationErrors;

  primaryCoder?: ValidationErrors;
  secondaryCoder?: ValidationErrors;
  reviewer?: ValidationErrors;
  reviewDate?: ValidationErrors;
  memo?: ValidationErrors;
  createdOn?: ValidationErrors;
  finalizedOn?: ValidationErrors;
  modifiedOn?: ValidationErrors;
  totalCodingTime?: ValidationErrors;
  customField1?: ValidationErrors;
  customField2?: ValidationErrors;
  customField3?: ValidationErrors;
  customField4?: ValidationErrors;

  diagnoses?: ValidationErrors;
  conditionCodes?: ValidationErrors;
  valueCodes?: ValidationErrors;
  visitReasons?: ValidationErrors;

  admitDX?: ValidationErrors;
  admitDXMissing?: ValidationErrors;

  clientCriticalError?: boolean;  // encounter has critical error. We must not allow to blur field
  clientPreventsSave?: boolean;   // encounter has error. We can blur field. We must prevent save and /change. We must disable Save / Close / Record Status
  gridsError?: ValidationErrors;  // common error to indicate incorrect date or provider in coderow. Can be helpful after facility changing with errors
  maxEditsLevel?: string;         // the highest error level in edits
  billingNotes?: ValidationErrors;
}

// for Research Pane edites we need in the exact order
export const EncounterOrderedFields = [
  'medicalRecordNumber',
  //
  'lastName',
  'firstName',
  'middleInitial',
  //
  'dateOfBirth',
  'ageInYears',
  'sex',
  //
  'birthWeight',
  //
  'accountNumber',
  'diagnoses',
  'facility',
  'managedCareFlag',
  //
  'type',
  'service',
  'ofa',
  //
  'admitDate',
  'dischargeDate',
  //
  'financialClass',
  'patientStatus',
  'overrideLOS',
  'billType',
  //
  'attendingMd',
  'totalCharges',
  'recordStatus',
  'thirdPartyLiability',
  //
  'primaryCoder',
  'secondaryCoder',
  //
  'reviewer',
  'reviewDate',
  //
  'memo',
  //
  'createdOn',
  'finalizedOn',
  //
  'modifiedOn',
  'totalCodingTime',
  //
  'customField1',
  'customField2',
  'customField3',
  'customField4',
  //
  'conditionCodes',
  'valueCodes',
  'visitReasons',
  'admitDX',
  'admitDXMissing',
  'gridsError',
];

export function ClientToServiceFieldMap(f: string) {
  switch (f) {
    // do not map (yet):
    // ageInYears
    // diagnoses
    // secondaryCoder
    // createdOn
    // finalizedOn
    // modifiedOn
    // totalCodingTime
    // conditionCodes
    // valueCodes
    // visitReasons
    // admitDXMissing
    // gridsError
    case 'sex':
      return 'PatientSex';
    case 'managedCareFlag':
      return 'PayerFlag';
    case 'type':
      return 'EncounterType';
    case 'ofa':
      return 'SourceOfAdmission';
    case 'attendingMd':
      return 'Provider';
    case 'admitDX':
    case 'admitDXMissing':
      return 'AdmitDiagnosis';
    case 'zzz':
      return 'zzz';
    default:
      return `${f.charAt(0).toUpperCase()}${f.slice(1)}`;
  }
}

// add new validation for the field
export const addValidation = (_validations: EncounterValidation, field: string, description: string, level: string) => {
  const newValidation: ValidationInfo = {
    description,
    level,
  };

  const validations = _validations;
  if (!validations[field]) {
    validations[field] = newValidation;
    return;
  }

  if (Array.isArray(validations[field])) {
    if (validations[field].find((existing) => existing === newValidation)) {
      return;
    }
    validations[field].push(newValidation);
  } else {
    const oldValue = validations[field];
    const oldValidation = oldValue as ValidationInfo;
    if (oldValidation.description === newValidation.description) {
      return;
    }
    validations[field] = [oldValue, newValidation];
  }
};

export const hasValidationError = (errors?: ValidationErrors) => {
  if (!errors) {
    return false;
  }

  if (Array.isArray(errors)) {
    return errors.length > 0;
  }

  return true;
};

// all client side strings are critical errors
export const isCriticalValidationError = (errors?: ValidationErrors) => {
  if (!errors) {
    return false;
  }

  if (Array.isArray(errors)) {
    for (let ind = 0, len = errors.length; ind < len; ind++) {
      const errorRow = errors[ind];
      if (typeof errorRow === 'string') {
        return true;
      }

      if (typeof errorRow === 'object') {
        if (isCriticalLevelError(errorRow.level)) {
          return true;
        }
      }
    }
  } else {
    if (typeof errors === 'string') {
      return true;
    }

    if (typeof errors === 'object') {
      return isCriticalLevelError(((errors as unknown) as ValidationInfo).level);
    }
  }

  return false;
};

export const getSingleErrorText = (error?: string | ValidationInfo) => {
  if (!error) {
    return '';
  }

  if (typeof error === 'string') {
    return error || '';
  }

  return error.description;
};

/*
  TODO: remove this after Supplementary rework to ErrorWrap
  components\Tooltip\MultilineTooltipTemplate.tsx takes over this behavior
*/
// return first error
export function getFieldErrorTooltip(errors?: ValidationErrors) {
  if (!errors) {
    return '';
  }

  if (Array.isArray(errors)) {
    if (errors.length > 0) {
      return getSingleErrorText(errors[0]);

      /*
        let result = '';
        for (let ind = 0, len = error.length; ind < len; ind++) {
          result = `${result}${ind > 0 ? ' ' : ''}${error[ind]}`;
        }

        return result;
      */
    }

    return ';';
  }

  return getSingleErrorText(errors);
}

// there are special entire grid or AdmitDx referenced errors. They must not intersect with usual code errors. So keep suppression here.
export const addErrorToDataItemValidations = (
  Validations: CodeValidation[],
  field: string,
  description: string,
  level: string
) => {
  if (!Validations.find((error) => error.Description === description)) {
    Validations.push({
      Description: description,
      Field: field,
      Level: level,
    });
  }
};

export const addErrorsToDataItemValidations = (
  Validations: CodeValidation[],
  field: string,
  errors?: ValidationErrors,
  proposedLevel?: string
) => {
  if (!errors) {
    return;
  }

  if (Array.isArray(errors)) {
    for (let ind = 0, len = errors.length; ind < len; ind++) {
      const errorRow = errors[ind];
      const description = typeof errorRow === 'string' ? errorRow : errorRow.description;
      const errorLevel = typeof errorRow === 'string' ? 'Critical' : errorRow.level;
      const level = proposedLevel || errorLevel;

      addErrorToDataItemValidations(Validations, field, description, level);
    }
  } else {
    const description = typeof errors === 'string' ? errors : errors.description;
    const errorLevel = typeof errors === 'string' ? 'Critical' : errors.level;
    const level = proposedLevel || errorLevel;

    addErrorToDataItemValidations(Validations, field, description, level);
  }
};
