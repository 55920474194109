export enum CHOICE_LIST_STATUS {
  NOT_LOADED = '',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

export interface CachedChoiceList {
  status: CHOICE_LIST_STATUS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];  // It can be any data here
}

export interface CachedChoiceLists {
  [key: string]: CachedChoiceList
}

export const getModifiersCachedKey = (approvedModifiersOnly: boolean, date?: string) => {
  return `MODIFIERS-${approvedModifiersOnly ? 'APPROVED' : 'ALL'}-${date || ''}`
}

export const getFooterFieldsCachedKey = (grouperType: string, pricerType: string) => {
  return `FOOTER-FIELDS-${grouperType}-${pricerType}`
}