import './Grid.less';

import * as React from 'react';
import { GridNoRecords as KGridNoRecords, GridNoRecordsProps as KGridNoRecordsProps } from "@progress/kendo-react-grid";

export class GridNoRecords extends React.PureComponent<KGridNoRecordsProps> {
  public static displayName = 'KendoReactGridNoRecords';

  render() {
    return <KGridNoRecords
      {...this.props}
    />;
  }
}

export interface GridNoRecordsProps extends KGridNoRecordsProps { };
