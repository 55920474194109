import './NotificationDialog.less';

import * as React from 'react';

import * as moment from 'moment';

import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ErrorDetails } from '../../models';
import { isEncounterClosedError } from '../../utils/encounter';


const ONE_SECOND = 1000;
const SHOW_TIME = 3000;

interface NotificationDialogProps {
  errors: ErrorDetails[];
  clearErrors: (index?: number) => void;
}

export default class NotificationDialog extends React.Component <NotificationDialogProps> {
  constructor(props: NotificationDialogProps) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onTimerTick = this.onTimerTick.bind(this);
  }

  private timerID: NodeJS.Timer;

  componentDidMount() {
    // do not use service workers timer to avoid force update in the inactve mode
    this.timerID = setTimeout(this.onTimerTick, ONE_SECOND);
  }

  componentWillUnmount() {
    if (this.timerID) {
      clearTimeout(this.timerID);
    }
  }

  getTooltipContent = (props) => {
    return props.title;
  }

  render() {
    if (this.props.errors.length === 0) {
      return null;
    }

    return (

      <NotificationGroup
        style={{
          alignItems: 'flex-start',
          bottom: 0,
          left: 0,
          zIndex: 9,
        }}
      >
        {this.renderErrors(this.props.errors)}
      </NotificationGroup>

    );
  }

  renderErrors = (errors) => {
    const TEXT_PART_LEN = 40;
    const MAX_SHOWN_ONE_TIME = 5;
    const now = moment.now();
    const list: JSX.Element[] = [];
    // show only 5 last errors. Show most recent at the bottom
    const indTo = errors.length;
    let shown = 0;
    for (let ind = indTo - 1; ind >= 0 && shown <= MAX_SHOWN_ONE_TIME; ind--) {
      // do not display error if it is related to the session being closed from another location
      if(isEncounterClosedError(errors[ind].description)){
        break;
      }
      // hide errors after 3 seconds
      if (errors[ind].time && (now - errors[ind].time) <= SHOW_TIME) {
        shown++;

        const error = errors[ind].error as string;
        const { length } = error;
        const parts: JSX.Element[] = [];
        const onClose = () => {
          this.props.clearErrors(ind);
        };

        // separate long text on parts because Notification can show only single line
        let start = 0;
        while (start < length) {
          let end = start + TEXT_PART_LEN;
          while (end < length && end - start < TEXT_PART_LEN + 10 && error[end] !== ' ') {
            end++;
          }
          const textPart = error.substring(start, end);
          parts.push((<div key={start} title={errors[ind].description}>{textPart}</div>));
          start = end;
          while (start < length && error[start] === ' ') {
            start++;
          }
        }

        list.push(
          <Notification
            type={{ style: 'error', icon: true }}
            closable
            onClose={onClose}
            key={ind}
          >
            <Tooltip
              anchorElement="pointer"
              position="auto"
              content={this.getTooltipContent}
              openDelay={500}
              className="error-tooltip"
              showCallout={false}
            >
              <span key={ind}>
                {parts}
              </span>
            </Tooltip>
          </Notification>
        );
      }
    }

    return list;
  }

  onClose = (index?: number) => {
    this.props.clearErrors(index);
  }

  onTimerTick() {
    // update this component to hide all outdates notifications
    this.forceUpdate();

    this.timerID = setTimeout(this.onTimerTick, ONE_SECOND);
  }
}
