import './UnsavedChanges.less';

import * as React from 'react';
import { Button, Dialog, DialogActionsBar } from '..';
import { ClientMode } from '../../models/system';

interface UnsavedChangesProps {
  dirty: boolean;
  shouldShowDiscardPrompt: boolean;
  onConfirmDiscardDirtyChanges: (confirm: boolean) => void;
  confirmCallback?: () => void;
  cancelCallback?: () => void;
  clientMode?: ClientMode;
  useInterfaceModeMessage?: boolean;
}

export class UnsavedChanges extends React.PureComponent<UnsavedChangesProps> {

  handleCancelClick = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
      this.props.onConfirmDiscardDirtyChanges(false);
    }
  }

  handleConfirmClick = () => {
    if (this.props.confirmCallback) {
      this.props.confirmCallback();
      this.props.onConfirmDiscardDirtyChanges(true);
    }
  }

  render() {
    let dialog = (
      <Dialog title='Warning!' onClose={this.handleCancelClick} className="unsaved-changes">
        <p>You have unsaved changes. Are you sure you want to proceed and discard these changes?</p>
        <DialogActionsBar>
          <Button onClick={this.handleCancelClick}>Cancel</Button>
          <Button onClick={this.handleConfirmClick} danger>Discard</Button>
        </DialogActionsBar>
      </Dialog>
    );
    if (this.props.useInterfaceModeMessage || this.props.clientMode && this.props.clientMode === ClientMode.Basic) {
      dialog = (
        <Dialog title='Warning!' onClose={this.handleCancelClick} className="unsaved-changes">
          <p>Encounter data will not be recovered. Are you sure you want to close the current encounter and discard these changes?</p>
          <DialogActionsBar>
            <Button onClick={this.handleCancelClick}>No</Button>
            <Button onClick={this.handleConfirmClick} primary>Yes</Button>
          </DialogActionsBar>
        </Dialog>
      )
    }
    return (
      <div>
        {this.props.shouldShowDiscardPrompt &&
          <>
            {dialog}
          </>
        }
      </div>
    )

  }
}

export default UnsavedChanges;
