import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { withReduxStateSync } from 'redux-state-sync';

import {
  AdviceErrors,
  AdviceSearchQueryEntity,
  AdviceSearchResultState,
  AdviceState,
  ArticleState,
  CodeRangeExpansionState,
  EncoderUserListState,
  LookupState,
  ManageEncoderUserState,
  PolicyArticleState,
  UI,
  UserState
} from '../models';
import { CodeFormatsState } from '../models/codeFormats';
import { RecoveredEncountersState, EncounterSnapshotState, EncounterState } from '../models/encounterEntity';
import { EncounterTemplates } from '../models/encounterTemplate';
import { GroupingInfoState, PDxAnalyzeState, DrgAnalyzeState } from '../models/groupingEntity';
import { ChoiceListsState } from '../models/patientEncounter';
import { SearchEncounters } from '../models/searchEncounterFilter';
import { adviceReducer } from './advice';
import { adviceErrorsReducer } from './adviceErrors';
import { adviceSearchQueryReducer } from './adviceSearchQuery';
import { adviceSearchResultReducer } from './adviceSearchResult';
import { choiceListsReducer } from './choiceLists';
import { codeFormatsReducer } from './codeFormats';
import { encoderUserListReducer } from './encoderUserList';
import { encounterReducer } from './encounter';
import { encounterTemplatesReducer } from './encounterTemplates';
import { financialClassesReducer } from './financialClasses';
import { facilitiesReducer } from './facilities';
import { facilitySettingsReducer } from './facilitySettings';
import { expandedCodeReducer } from './expandedCode';
import { getArticleReducer } from './getArticle';
import { getPolicyArticleReducer } from './getPolicyArticle';
import { groupingInfoReducer } from './groupingInfo';
import { lookupReducer } from './lookups';
import { manageEncoderUserReducer } from './manageUsers';
import { pdxAnalyzeReducer } from './pdxAnalyze';
import { searchEncountersReducer } from './searchEncounters';
import { uiReducer } from './ui';
import { userReducer } from './user';
import { preferencesReducer } from './preferences';
import { FinancialClassState } from '../scenes/FinancialClass/models/financialClassState';
import { FacilitiesState } from '../scenes/Facility/models/facilitiesState';
import { FacilitySettingsState } from '../models/facilitySettings';
import { SettingsState } from '../scenes/FacilityPreferences/models/settingsState';
import { choiceListsSettingsReducer } from './choiceListsSettings';
import { b2cReducer } from './b2c';
import { B2CState } from '../models/b2c';
import { ChoiceListsSettingsState } from '../scenes/ChoiceListsSettings/models/choiceListsSettingsState';
import { UserActivityState } from '../models/userActivity';
import { userActivityReducer } from './userActivity';
import { NetActivityState } from '../models/netActivity';
import { netActivityReducer } from './netActivity';
import { drgAnalyzeReducer } from './drgAnalyze';
import { snapshotReducer } from './snapshot';
import { SystemPreferencesState } from '../scenes/SystemPreferences/models/systemPreferencesState';
import { systemPreferencesReducer } from './systemPreferences';
import { LicenseState } from '../models/license';
import { licenseReducer } from './license';
import { SystemAnnouncementsState } from '../models/systemAnnouncements';
import { systemAnnouncementsReducer } from './systemAnnouncements';
import { recoveredEncountersReducer } from './recoveredEncounters';

export interface State {
  advice: AdviceState;
  adviceErrors: AdviceErrors;
  adviceSearchQuery: AdviceSearchQueryEntity;
  adviceSearchResult: AdviceSearchResultState;
  article: ArticleState;
  b2c: B2CState;
  choiceLists: ChoiceListsState;
  choiceListsSettings: ChoiceListsSettingsState;
  codeFormats: CodeFormatsState;
  drgAnalyze: DrgAnalyzeState;
  encoderUserList: EncoderUserListState;
  encounter: EncounterState;
  encounterSnapshot: EncounterSnapshotState;
  encounterTemplates: EncounterTemplates;
  expandedCodeRange: CodeRangeExpansionState;
  facilities: FacilitiesState;
  facilitySettings: FacilitySettingsState;
  financialClasses: FinancialClassState;
  groupingInfo: GroupingInfoState;
  license: LicenseState;
  lookups: LookupState;
  manageEncoderUser: ManageEncoderUserState;
  pdxAnalyze: PDxAnalyzeState;
  policyArticle: PolicyArticleState;
  recoveredEncounters: RecoveredEncountersState;
  router: RouterState;
  searchEncounters: SearchEncounters;
  settings: SettingsState;
  ui: UI;
  user: UserState;
  systemAnnouncements: SystemAnnouncementsState;
  userActivity: UserActivityState;
  netActivity: NetActivityState;
  systemPreferences: SystemPreferencesState;
}

// FIXME: ts error? why?
export const createRootReducer = (history) => withReduxStateSync(combineReducers<State>({
  advice: adviceReducer,
  adviceErrors: adviceErrorsReducer,
  adviceSearchQuery: adviceSearchQueryReducer,
  adviceSearchResult: adviceSearchResultReducer,
  article: getArticleReducer,
  b2c: b2cReducer,
  choiceLists: choiceListsReducer,
  choiceListsSettings: choiceListsSettingsReducer,
  codeFormats: codeFormatsReducer,
  drgAnalyze: drgAnalyzeReducer,
  encoderUserList: encoderUserListReducer,
  encounter: encounterReducer,
  encounterSnapshot: snapshotReducer,
  encounterTemplates: encounterTemplatesReducer,
  expandedCodeRange: expandedCodeReducer,
  facilities: facilitiesReducer,
  facilitySettings: facilitySettingsReducer,
  financialClasses: financialClassesReducer,
  groupingInfo: groupingInfoReducer,
  license: licenseReducer,
  lookups: lookupReducer,
  manageEncoderUser: manageEncoderUserReducer,
  pdxAnalyze: pdxAnalyzeReducer,
  policyArticle: getPolicyArticleReducer,
  recoveredEncounters: recoveredEncountersReducer,
  router: connectRouter(history),
  searchEncounters: searchEncountersReducer,
  settings: preferencesReducer,
  ui: uiReducer,
  user: userReducer,
  userActivity: userActivityReducer,
  netActivity: netActivityReducer,
  systemPreferences: systemPreferencesReducer,
  systemAnnouncements: systemAnnouncementsReducer,
}));
