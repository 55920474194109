import './LogoutDialog.less';

import * as React from 'react';
import {Dialog, DialogActionsBar} from '../../'
import { Button } from '../..';

interface LogoutDialogProps {
  onLogoutNoClick: () => void;
  onLogoutYesClick: () => void;
  show: boolean;
}

const LogoutDialog = function render(props: LogoutDialogProps) {
  if (!props.show) {
    return null;
  }
  return (
    <Dialog title="Logout" onClose={props.onLogoutNoClick} className="logout-dialog">
      <p>Are you sure you want to logout?</p>
      <DialogActionsBar>
        <Button onClick={props.onLogoutNoClick}>
          Cancel
        </Button>
        <Button onClick={props.onLogoutYesClick} primary>
          Logout
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default LogoutDialog;

