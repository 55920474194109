import * as React from 'react';
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Button, Dialog, DialogActionsBar } from '..';

const PREVENT_WARNING = "Don't show this warning again";

interface NavigateExternalProps {
  saveUserPreference: (preference: string, value: any) => void;
  hideExternalLinkWarning: object;
}
interface NavigateExternalState {
  showExternalLinkDialog: boolean;
  clickedLink: string;
  preventWarning: boolean;
}

const LEFT_CLICK = 0;
const MIDDLE_CLICK = 1;

export default class NavigateExternal extends React.PureComponent<NavigateExternalProps, NavigateExternalState> {
  constructor(props) {
    super(props);
    this.toggleWarning = this.toggleWarning.bind(this);
    this.state = {
      showExternalLinkDialog: false,
      clickedLink: '',
      preventWarning: false
    };
  }

  handleCancelClick = () => {
    this.setState({
      showExternalLinkDialog: false
    })
  }

  handleConfirmClick = () => {
    window.open(this.state.clickedLink);
    this.setState({
      showExternalLinkDialog: false
    })
  }

  onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button === LEFT_CLICK || e.button === MIDDLE_CLICK) {
      const targetLink = e.target as HTMLAnchorElement;
      const targetURL = targetLink.href ? new URL(targetLink.href) : '';
      const targetDomain = targetURL ? targetURL.hostname : '';
      const shouldIgnore = targetLink.getAttribute('data-ignore-domain') === 'true';
      if (targetLink.tagName.toUpperCase() === 'A' && !shouldIgnore) {
        if (!targetURL && !targetDomain) {
          return;
        }

        if (targetDomain !== window.location.hostname && targetLink.href !== 'javascript:void(0)' && !targetLink.download) {
          e.preventDefault(); // stop initial follow
          if (!this.props.hideExternalLinkWarning) {
            this.setState({
              showExternalLinkDialog: true,
              clickedLink: targetLink.href
            })
          } else {
            window.open(targetLink.href);
          }

        }
      }
    }
  }

  render() {
    return (
      <div className="navigate-external" onMouseDown={this.onClick}>
        {this.props.children}
        {this.state.showExternalLinkDialog && <Dialog title='Warning!' onClose={this.handleCancelClick}>
          <p>The link you clicked is taking you to another site. Are you sure you wish to continue?</p>
          <p><i>{this.state.clickedLink}</i></p>
          <Checkbox
            label={PREVENT_WARNING}
            labelPlacement="after"
            checked={this.state.preventWarning}
            onChange={this.toggleWarning}
            disabled={false}
            id="prevent-warning"
          />
          <DialogActionsBar>
            <Button onClick={this.handleCancelClick}>Cancel</Button>
            <Button primary onClick={this.handleConfirmClick}>Continue</Button>
          </DialogActionsBar>
        </Dialog>
        }
      </div>
    )
  }

  toggleWarning(e: CheckboxChangeEvent) {
    this.setState(prevState => ({ preventWarning: !prevState.preventWarning }));
    this.props.saveUserPreference('HideExternalLinkWarning', e.value);
  }
}
