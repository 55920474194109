import { FieldValidationResult, FormValidationResult } from 'lc-form-validation';
import * as actionTypes from '../constants';
import { AdviceErrors, CodeRangeExpansionEntity, ServiceErrorDetail } from '../models';
import { AdviceAddCodesPayload } from '../scenes/CodingAdvice/actions/addCodes';
import { AdviceFieldChangePayload } from '../scenes/CodingAdvice/actions/fieldChange';

// FIXME: handle timeouts and give them an appropriate error detail

const createEmptyAdviceErrors = (): AdviceErrors => ({
  addRange: new FieldValidationResult(),
  asyncAddRange: { items: {}, last: '', size: 0, },
  codes: new FieldValidationResult(),
  delete: createEmptyServiceError(),
  description: new FieldValidationResult(),
  fetch: createEmptyServiceError(),
  save: createEmptyServiceError(),
  search: createEmptyServiceError(),
  source: new FieldValidationResult(),
});

const createEmptyServiceError = (): ServiceErrorDetail => {
  return {
    errorMessage: '',
    key: '',
    succeeded: true,
    type: '',
  };
};

export const adviceErrorsReducer = (state = createEmptyAdviceErrors(), action) => {
  switch (action.type) {
    case actionTypes.CCA_FETCH_ADVICE_BY_ID_BEGIN:
      return handleFetchAdviceBegin(state);
    case actionTypes.CCA_FETCH_ADVICE_BY_ID_COMPLETED:
      return handleFetchAdviceCompleted(state);
    case actionTypes.CCA_FETCH_ADVICE_BY_ID_ERROR:
      return handleFetchAdviceError(state, action.payload);
    case actionTypes.CCA_UPDATE_ADVICE_FIELD:
      return handleUpdateAdviceField(state, action.payload);
    case actionTypes.CCA_SAVE_ADVICE_VALIDATION_COMPLETE:
      return handleSaveAdvice(state, action.payload);
    case actionTypes.CCA_SAVE_ADVICE_BEGIN:
      return handleSaveAdviceBegin(state);
    case actionTypes.CCA_SAVE_ADVICE_ERROR:
      return handleSaveAdviceError(state, action.payload);
    case actionTypes.CCA_CREATE_ADVICE:
      return handleCreateAdvice(state);
    case actionTypes.CCA_ADD_ADVICE_CODES:
      return handleAddAdviceCodes(state, action.payload);
    case actionTypes.EXPAND_CODE_RANGE_COMPLETED:
      return handleExpandCodeRangeCompleted(state, action.payload);
    case actionTypes.EXPAND_CODE_RANGE_ERROR:
      return handleExpandCodeRangeError(state, action.payload);
    case actionTypes.CCA_CODE_CHOOSER_CHANGE_COMPLETED:
      return handleCodeChooseChangeCompleted(state);
    case actionTypes.CCA_DELETE_ADVICE_BEGIN:
      return handleDeleteAdviceBegin(state);
    case actionTypes.CCA_DELETE_ADVICE_ERROR:
      return handleDeleteAdviceError(state, action.payload);
    case actionTypes.CCA_SEARCH_ADVICE_BEGIN:
      return handleSearchAdviceBegin(state);
    case actionTypes.CCA_SEARCH_ADVICE_ERROR:
      return handleSearchAdviceError(state, action.payload);
    default:
      return state;
  }
};

const handleExpandCodeRangeCompleted = (state: AdviceErrors, payload: CodeRangeExpansionEntity) => {
  const isValid = payload.codes.length > 0;
  const validationResult = new FieldValidationResult();
  validationResult.errorMessage = 'This range contains invalid values';
  validationResult.type = 'CODE_RANGE_INVALID';
  validationResult.succeeded = false;
  // if it was valid, clear the error
  const items = {
    ...state.asyncAddRange.items,
    [payload.id]: isValid ? new FieldValidationResult() : validationResult,
  };
  return {
    ...state,
    asyncAddRange: {
      items,
      last: payload.id,
      size: state.asyncAddRange.size + 1,
    }
  };
};

const handleExpandCodeRangeError = (state: AdviceErrors, payload: CodeRangeExpansionEntity) => {
  const validationResult = new FieldValidationResult();
  validationResult.errorMessage = 'Service error encountered';
  validationResult.type = 'CODE_RANGE_SERVICE_ERROR';
  validationResult.succeeded = false;
  const items = {
    ...state.asyncAddRange.items,
    [payload.id]: validationResult,
  };
  return {
    ...state,
    asyncAddRange: {
      items,
      last: payload.id,
      size: state.asyncAddRange.size + 1,
    }
  };
};

const handleCodeChooseChangeCompleted = (state: AdviceErrors) => {
  return {
    ...state,
    addRange: new FieldValidationResult(),
  };
};

const handleFetchAdviceCompleted = (state: AdviceErrors) => {
  return {
    ...state,
    ...createEmptyAdviceErrors(),
    search: state.search,
  };
};

const handleFetchAdviceBegin = (state: AdviceErrors): AdviceErrors => {
  return {
    ...state,
    fetch: createEmptyServiceError(),
  };
};

const handleFetchAdviceError = (state: AdviceErrors, payload: Error): AdviceErrors => {
  const fetch: ServiceErrorDetail = {
    errorDetail: payload.message,
    errorMessage: 'Error loading advice',
    key: 'fetch',
    succeeded: false,
    type: 'ADVICE_FETCH_SERVICE_ERROR',
  };
  return {
    ...state,
    fetch
  };
};

const handleCreateAdvice = (state: AdviceErrors) => {
  return {
    ...state,
    ...createEmptyAdviceErrors(),
    search: state.search,
  };
};

const handleAddAdviceCodes = (state: AdviceErrors, payload: AdviceAddCodesPayload): AdviceErrors => {
  return {
    ...state,
    [payload.fieldValidationResult.key as string]: payload.fieldValidationResult,
    codes: new FieldValidationResult(),
  };
};

const handleUpdateAdviceField = (state: AdviceErrors, payload: AdviceFieldChangePayload): AdviceErrors => {
  return {
    ...state,
    [payload.fieldValidationResult.key as string]: payload.fieldValidationResult,
  };
};

const handleSaveAdvice = (state: AdviceErrors, payload: FormValidationResult): AdviceErrors => {
  const newAdviceErrors = { ...state };

  // FIXME: typescript error?
  return payload.fieldErrors.reduce(
    (adviceErrors, fieldValidationResult) => {
      // FIXME: eslint error
      adviceErrors[fieldValidationResult.key as string] = fieldValidationResult;
      return adviceErrors;
    },
    newAdviceErrors
  );
};

const handleSaveAdviceError = (state: AdviceErrors, payload: Error): AdviceErrors => {
  const save: ServiceErrorDetail = {
    errorDetail: payload.message,
    errorMessage: 'Error saving',
    key: 'save',
    succeeded: false,
    type: 'ADVICE_SAVE_SERVICE_ERROR',
  };
  return {
    ...state,
    save
  };
};

const handleSaveAdviceBegin = (state: AdviceErrors): AdviceErrors => {
  const save = new FieldValidationResult();
  return {
    ...state,
    save
  };
};

const handleDeleteAdviceError = (state: AdviceErrors, payload: Error): AdviceErrors => {
  const del: ServiceErrorDetail = {
    errorDetail: payload.message,
    errorMessage: 'Error deleting',
    key: 'delete',
    succeeded: false,
    type: 'ADVICE_DELETE_SERVICE_ERROR',
  };
  return {
    ...state,
    delete: del,
  };
};

const handleDeleteAdviceBegin = (state: AdviceErrors): AdviceErrors => {
  const del = new FieldValidationResult();
  return {
    ...state,
    delete: del,
  };
};

const handleSearchAdviceError = (state: AdviceErrors, payload: Error): AdviceErrors => {
  const search: ServiceErrorDetail = {
    errorDetail: payload.message,
    errorMessage: 'Error searching',
    key: 'search',
    succeeded: false,
    type: 'ADVICE_SEARCH_SERVICE_ERROR',
  };
  return {
    ...state,
    search
  };
};

const handleSearchAdviceBegin = (state: AdviceErrors): AdviceErrors => {
  return {
    ...state,
    search: createEmptyServiceError(),
  };
};
