import './Badge.less';

import * as React from 'react';
import { BadgeContainer as KBadgeContainer, BadgeContainerProps as KBadgeContainerProps } from "@progress/kendo-react-indicators";

export const BadgeContainer = function render(props: KBadgeContainerProps) {
  const { className, ...otherProps } = props;
  return <KBadgeContainer
    className={`tc-badge-container ${className}`}
    {...otherProps}
  />;
}
