export interface CodeGridColumnInfo {
  noEditHack?: boolean;
  title: string;
  width?: string;
  focusField?: string;
}

export interface CodeGridColumnInfoCollection {
  [index: string]: CodeGridColumnInfo;
}

export interface CodeGridColumnsInfo {
  // column sizes and names
  columnsInfo: CodeGridColumnInfoCollection;
  // columnIds in the necessary order
  columnsOrder: string[];
}

export interface CodeGridsColumns {
  [index: string]: CodeGridColumnsInfo;
}

export interface ResizeColumnParameters {
  gridSettings: CodeGridColumnsInfo;
  gridKey: string;
  columnIndex: number;
  newWidth: number;
  notUpdateStorage?: boolean;
}

export interface ReorderColumnsParameters {
  gridSettings: CodeGridColumnsInfo;
  gridKey: string;
  newOrder: string[];
  notUpdateStorage?: boolean;
}

export const DEFAULT_CODEGRIDS_COLUMNS: CodeGridsColumns = {
  diagnosesPdxOff: {
    columnsInfo: {
      colFakeDraggable: {
        title: '',
        width: '30px'
      },
      colOrder: {
        title: '#',
        width: '40px'
      },
      colMenu: {
        title: '',
        width: '20px'
      },
      colGrouperFlag: {
        title: 'GF',
        width: '75px'
      },
      colCode: {
        title: 'Code',
        width: '140px',
        focusField: 'code'
      },
      colPresentOnAdmission: {
        title: 'POA',
        width: '130px',
        focusField: 'presentOnAdmission'
      },
      colDescription: {
        title: 'Description',
        focusField: 'description'
      },
      colHcc24: {
        title: 'HCC v24',
        width: '75px'
      },
      colHcc28: {
        title: 'HCC v28',
        width: '75px'
      }
    },
    columnsOrder: [
      'colFakeDraggable', 'colOrder', 'colMenu', 'colGrouperFlag', 'colCode', 'colPresentOnAdmission', 'colDescription', 'colHcc24', 'colHcc28'
    ]
  },
  diagnosesPdxOn: {
    columnsInfo: {
      colOrder: {
        noEditHack: true,
        title: '#',
        width: '50px'
      },
      colCode: {
        noEditHack: true,
        title: 'Code',
        width: '140px',
        focusField: 'code'
      },
      colDescription: {
        noEditHack: true,
        title: 'Description',
        focusField: 'description'
      },
      colDrg: {
        noEditHack: true,
        title: 'DRG',
        width: '55px'
      },
      colDrgDescription: {
        noEditHack: true,
        title: 'DRG Description',
        width: '175px'
      },
      colDrgWeight: {
        noEditHack: true,
        title: 'DRG Wt',
        width: '85px'
      },
      colSOI: {
        noEditHack: true,
        title: 'SOI',
        width: '85px'
      },
      colROM: {
        noEditHack: true,
        title: 'ROM',
        width: '85px'
      },
      colReimbursement: {
        noEditHack: true,
        title: 'Reimbursement',
        width: '120px'
      },
      colToPDX: {
        noEditHack: true,
        title: 'To PDx',
        width: '70px'
      },
    },
    columnsOrder: [
      'colOrder', 'colCode', 'colDescription', 'colDrg', 'colDrgDescription',
      'colDrgWeight', 'colSOI', 'colROM', 'colReimbursement', 'colToPDX'
    ]
  },
  inProcedures: {
    columnsInfo: {
      colFakeDraggable: {
        title: '',
        width: '40px'
      },
      colOrder: {
        title: '#',
        width: '50px'
      },
      colMenu: {
        title: '',
        width: '20px'
      },
      colGrouperFlag: {
        title: 'GF',
        width: '75px'
      },
      colCode: {
        title: 'Code',
        width: '140px',
        focusField: 'code'
      },
      colDescription: {
        title: 'Description',
        focusField: 'description'
      },
      colEpisode: {
        title: 'EP',
        width: '110px',
        focusField: 'episode'
      },
      colProvider: {
        title: 'Provider',
        width: '150px',
        focusField: 'provider'
      },
      colDate: {
        title: 'Date',
        width: '160px',
        focusField: 'date'
      },
    },
    columnsOrder: [
      'colFakeDraggable', 'colOrder', 'colMenu', 'colGrouperFlag', 'colCode', 'colDescription',
      'colEpisode', 'colProvider', 'colDate'
    ]
  },
  outProcedures: {
    columnsInfo: {
      colFakeDraggable: {
        title: '',
        width: '40px'
      },
      colOrder: {
        title: '#',
        width: '50px'
      },
      colMenu: {
        title: '',
        width: '20px'
      },
      colCode: {
        title: 'Code',
        width: '116px',
        focusField: 'code'
      },
      colDescription: {
        title: 'Description',
        focusField: 'description'
      },
      colModifiers: {
        title: 'Modifiers',
        width: '160px',
        focusField: 'modifiers'
      },
      colUnits: {
        title: 'Units',
        width: '110px',
        focusField: 'units'
      },
      colRevenueCode: {
        title: 'RC',
        width: '130px',
        focusField: 'revenueCode'
      },
      colEpisode: {
        title: 'EP',
        width: '110px',
        focusField: 'episode'
      },
      colProvider: {
        title: 'Provider',
        width: '150px',
        focusField: 'provider'
      },
      colDate: {
        title: 'Date',
        width: '160px',
        focusField: 'date'
      },

      colCharges: {
        title: 'Charges',
        width: '90px',
        focusField: 'charges'
      },
      colNCCharges: {
        title: 'NC Charges',
        width: '90px',
        focusField: 'nccharges'
      },
      colApc: {
        title: 'APC',
        width: '70px'
      },
      colApcDescription: {
        title: 'APC Description',
        width: '150px'
      },
      colApcWeight: {
        title: 'APC Wt',
        width: '80px'
      },
      colApcStatusIndicator: {
        title: 'SI',
        width: '40px'
      },
      colApcCaFlag: {
        title: 'CA Flag',
        width: '70px'
      },
      colApcPercent: {
        title: '%',
        width: '50px'
      },
      colApcMedicareAmount: {
        title: 'Mcare Amt',
        width: '100px'
      },
      colApcAdjustedCoin: {
        title: 'Adj Coin',
        width: '80px'
      },
      colApcOutlierAmount: {
        title: 'Out Amount',
        width: '100px'
      },
      colApcPayment: {
        title: 'APC Payment',
        width: '110px'
      },

      colEAPG: {
        title: 'EAPG',
        width: '80px'
      },
      colEAPGDescription: {
        title: 'EAPG Description',
        width: '150px'
      },
      colEAPGWeight: {
        title: 'EAPG Wt',
        width: '80px'
      },
      colEAPGType: {
        title: 'Type',
        width: '120px'
      },
      colEAPGCategory: {
        title: 'Category',
        width: '120px'
      },
      colEAPGPayment: {
        title: 'EAPG Payment',
        width: '140px'
      },
      colEAPGPercentage: {
        title: '%',
        width: '50px'
      },
      colASCPayment: {
        title: 'ASC Pymt',
        width: '110px'
      },
      colASCWeight: {
        title: 'ASC Wt',
        width: '80px'
      },
      colASCPercentage: {
        title: '%',
        width: '50px'
      },
      colASCMedicareAmount: {
        title: 'Mcare Amt',
        width: '100px'
      },
      colASCAdjustedCoin: {
        title: 'Adj Coin',
        width: '80px'
      },
      colASCPaymentIndicator: {
        title: 'ASC PI',
        width: '60px'
      },
    },
    columnsOrder: [
      'colFakeDraggable', 'colOrder', 'colMenu', 'colCode', 'colDescription', 'colModifiers',
      'colUnits', 'colRevenueCode', 'colEpisode', 'colProvider', 'colDate',
      'colCharges', 'colNCCharges', 'colApc', 'colApcDescription', 'colApcWeight', 'colApcStatusIndicator',
      'colApcCaFlag', 'colApcPercent', 'colApcMedicareAmount', 'colApcAdjustedCoin', 'colApcOutlierAmount',
      'colApcPayment',
      'colEAPG', 'colEAPGDescription', 'colEAPGWeight', 'colEAPGType', 'colEAPGCategory', 'colEAPGPercentage', 'colEAPGPayment',
      'colASCWeight', 'colASCPaymentIndicator', 'colASCPercentage', 'colASCMedicareAmount', 'colASCAdjustedCoin', 'colASCPayment'
    ]
  },
  diagnosesPsych: {
    columnsInfo: {
      colFakeDraggable: {
        title: '',
        width: '30px'
      },
      colOrder: {
        title: '#',
        width: '40px'
      },
      colMenu: {
        title: '',
        width: '20px'
      },
      colGrouperFlag: {
        title: 'GF',
        width: '75px'
      },
      colCode: {
        title: 'Code',
        width: '140px',
        focusField: 'code'
      },
      colPresentOnAdmission: {
        title: 'POA',
        width: '100px',
        focusField: 'presentOnAdmission'
      },
      colDescription: {
        title: 'Description',
        focusField: 'description',
      },
      colHcc24: {
        title: 'HCC v24',
        width: '75px'
      },
      colHcc28: {
        title: 'HCC v28',
        width: '75px'
      },
      colComorbidity: {
        title: 'Comorbidity',
        width: '95px'
      }
    },
    columnsOrder: [
      'colFakeDraggable', 'colOrder', 'colMenu', 'colGrouperFlag', 'colCode', 'colPresentOnAdmission', 'colDescription', 'colHcc24', 'colHcc28', 'colComorbidity'
    ]
  },
  visitReasons: {
    columnsInfo: {
      colFakeDraggable: {
        title: '',
        width: '40px'
      },
      colOrder: {
        title: '#',
        width: '50px'
      },
      colMenu: {
        title: '',
        width: '20px'
      },
      colCode: {
        title: 'Code',
        width: '140px',
        focusField: 'code'
      },
      colDescription: {
        title: 'Description',
        focusField: 'description'
      },
      colHcc24: {
        title: 'HCC v24',
        width: '75px'
      },
      colHcc28: {
        title: 'HCC v28',
        width: '75px'
      }
    },
    columnsOrder: [
      'colFakeDraggable', 'colOrder', 'colMenu', 'colCode', 'colDescription', 'colHcc24', 'colHcc28',
    ]
  },
};
