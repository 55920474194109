import './Label.less';

import * as React from 'react';
import { v4 as uuid } from 'uuid';

export interface LabelProps {
  htmlFor?: string;
  className?: string;
  title?: string;
  visibleCondition?: boolean;
}

export class Label extends React.PureComponent<LabelProps> {
  el: HTMLInputElement;

  render() {
    const id = `id-${uuid()}`;
    const shouldHide = typeof this.props.visibleCondition === 'boolean' && this.props.visibleCondition === false;
    const className = this.props.className ? `${this.props.className} control-label tc-label` : 'control-label tc-label';
    return this.props.children
      ? (
        <label
          title={this.props.title}
          htmlFor={this.props.htmlFor}
          id={id}
          className={className}
          style={{ display: shouldHide ? 'none' : 'inline-block' }}
        >
          {this.props.children}
        </label>
      )
      : '';
  }

}
