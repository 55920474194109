import './Tabstrip.less';

import * as React from 'react';
import { TabStripTab, TabStripTabProps } from '@progress/kendo-react-layout';

export const TabstripTab = function render(props: TabStripTabProps) {
  return <TabStripTab
    {...props}
  />;
}
