import * as actionTypes from '../constants';
import { CodeFormatsState, CodeMetadata } from '../models/codeFormats';
import { CodeType, GridCodeType } from '../models/codeTypes';

const createEmptyCodeFormatsState = (): CodeFormatsState => {
  const emptyCodeFormat = {
    decimalPosition: -1,
    regexp: '',
    partialRegexp: ''
  };

  const result = {
    codesFormats: {
      [GridCodeType.ADMIT_DX]: emptyCodeFormat,
      [GridCodeType.DIAGNOSES]: emptyCodeFormat,
      [GridCodeType.VISITREASONS]: emptyCodeFormat,
      [GridCodeType.INPROCEDURES]: emptyCodeFormat,
      [GridCodeType.OUTPROCEDURES]: emptyCodeFormat    
    },
    codesMetadata: {},
    initialized: false,
  };

  return result;
};

export const codeFormatsReducer = (state = createEmptyCodeFormatsState(), action) => {
  switch (action.type) {
    case actionTypes.GET_CODE_METADATA_COMPLETED:
      return handleSetCodesMetadata(state, action.payload);
    case actionTypes.GET_CODE_METADATA_FAIL:
      return handleGetCodeMetadataFailed();
    default:
      return state;
  }
};

const addRegExpRanges = (regexp: string) => {
  return `^${regexp}$`;
}

const handleSetCodesMetadata = (state: CodeFormatsState, metadata: CodeMetadata[]): CodeFormatsState => {
  // I suppose all metadata is correct and there is no need to check it for the missed fields
  
  // create object with all metadata
  const codesMetadata = {};
  for (let ind = 0; ind < metadata.length; ind++) {
    codesMetadata[metadata[ind].code_type] = metadata[ind];
  }

  // prepare regexps for our grid code types

  const diagnosesFormat = {
    // decimalPosition must be equal for both ICD10CM_DX and ICD10CM_E
    decimalPosition: parseInt(codesMetadata[CodeType.ICD10CM_DX].decimal_position, 10),
    regexp: `(${addRegExpRanges(codesMetadata[CodeType.ICD10CM_DX].regex_pattern)})|(${addRegExpRanges(codesMetadata[CodeType.ICD10CM_E].regex_pattern)})`,
    partialRegexp: `(${codesMetadata[CodeType.ICD10CM_DX].partial_regex_pattern})|(${codesMetadata[CodeType.ICD10CM_E].partial_regex_pattern})`,    
  };

  const inProceduresFormat = {
    decimalPosition: parseInt(codesMetadata[CodeType.ICD10PCS_PR].decimal_position, 10),
    regexp: addRegExpRanges(codesMetadata[CodeType.ICD10PCS_PR].regex_pattern),
    partialRegexp: codesMetadata[CodeType.ICD10PCS_PR].partial_regex_pattern,
  };

  const outProceduresFormat = {
    // decimalPosition must be equal for both CPT4 and HCPCS
    decimalPosition: parseInt(codesMetadata[CodeType.CPT4].decimal_position, 10),
    regexp: `(${addRegExpRanges(codesMetadata[CodeType.CPT4].regex_pattern)})|(${addRegExpRanges(codesMetadata[CodeType.HCPCS].regex_pattern)})`,    
    partialRegexp: `(${codesMetadata[CodeType.CPT4].partial_regex_pattern})|(${codesMetadata[CodeType.HCPCS].partial_regex_pattern})`,
  };

  const codesFormats = {
    [GridCodeType.ADMIT_DX]: diagnosesFormat,
    [GridCodeType.DIAGNOSES]: diagnosesFormat,
    [GridCodeType.VISITREASONS]: diagnosesFormat,
    [GridCodeType.INPROCEDURES]: inProceduresFormat,
    [GridCodeType.OUTPROCEDURES]: outProceduresFormat
  };

  return {
    ...state,
    codesFormats,
    codesMetadata,
    initialized: true,
  };
};

const handleGetCodeMetadataFailed = (): CodeFormatsState => {
  // do we need to hardcode default formats?
  return createEmptyCodeFormatsState();
};