import * as actionTypes from '../constants';

import { Key } from '../constants/keyboard';
import { DrgWeightItem, FinancialClassState, GrouperOption } from '../scenes/FinancialClass/models/financialClassState';
import { FinancialClassEntity, IPCardEntity, OPCardEntity, GrouperCardEntity, PricerCardField, PricerCardRow, PricerCardValue, SETTINGS_TYPE_OP, GroupersChoiceListItem, CardEntity, SETTINGS_TYPE_IP } from '../scenes/FinancialClass/models/financialClass';
import { getPricerCardFieldDefaultValue, isValidPricerCardValue } from '../scenes/FinancialClass/components/utils';

const createEmptyFinancialClassState = (): FinancialClassState => ({
  selectedFacilityViewId: '',

  hideInactiveFinancialClasses: true,
  financialClasses: [],
  financialClassesLastLoadedPage: 0,
  financialClassesTotalRecords: 0,
  financialClassesMustBeReloaded: false,

  selectedFinancialClassViewId: '',
  selectedSettingsType: '',
  settingsCardsLastLoadedPage: 0,
  settingsCardsTotalRecords: 0,
  settingsCardsMustBeReloaded: false,
  ipCards: [],
  opCards: [],
  selectedSettingCardViewId: '',
  selectedSettingCardEffectiveDate: '',
  selectedSettingCard: undefined,
  grouperCards: [],
  groupersList: [],

  selectedGrouperCardViewId: '',
  selectedGrouperTitle: '',
  selectedGrouperPricerValues: [],
  selectedGrouperFields: [],
  selectedGrouperCard: [],
  selectedGrouperGrouperType: '',
  selectedGrouperGrouperVersion: '',
  selectedGrouperPricerType: '',
  selectedGrouperSimplePricing: true,
  selectedGrouperAllowCustomDrgWeights: false,
  selectedGrouperIsCustomDrgWeightsAvailable: false,

  selectedSettingsComparison: '',
  addingModeFinancialClass: false,
  addingModeSettingCard: false,
  addingModeGrouperCard: false,

  initialized: false,
  financialClassesLoading: false,
  financialClassesPageLoading: false,
  settingsLoading: false,
  settingsPageLoading: false,
  grouperCardsLoading: false,
  pricerCardLoading: false,
  concurrencyInteractions: 0,
  errorText: '',

  errorItemViewId: '',
  grouperOptions: [],

  drgWeightsLoading: false,
  drgWeightsData: [],
  drgWeightsLoaded: false,
  drgWeightsError: "",

  drgWeightsSuccessUploadMessage: '',
  drgWeightsFailedUploadMessage: '',
});

export const financialClassesReducer = (state = createEmptyFinancialClassState(), action) => {
  switch (action.type) {
    case actionTypes.FC_SELECT_FACILITY:
      return handleSelectFacility(state, action.payload);
    case actionTypes.FC_GET_FINANCIAL_CLASSES_BEGIN:
      return handleGetFinancialClassesStart(state);
    case actionTypes.FC_GET_FINANCIAL_CLASSES_COMPLETED:
      return handleGetFinancialClassesCompleted(state, action.payload);
    case actionTypes.FC_GET_FINANCIAL_CLASSES_FAIL:
      return handleGetFinancialClassesFailed(state, action.payload);
    case actionTypes.FC_GET_FINANCIAL_CLASSES_PAGE_BEGIN:
      return handleGetFinancialClassesPageStart(state);
    case actionTypes.FC_GET_FINANCIAL_CLASSES_PAGE_COMPLETED:
      return handleGetFinancialClassesPageCompleted(state, action.payload);
    case actionTypes.FC_GET_FINANCIAL_CLASSES_PAGE_FAIL:
      return handleGetFinancialClassesPageFailed(state, action.payload);
    case actionTypes.FC_TOGGLE_HIDE_INACTIVE_FCS:
      return handleToggleInactiveFinancialClasses(state, action.payload);
    case actionTypes.FC_FINANCIAL_CLASS_ADD:
      return handleFinancialClassAdd(state, action.payload);
    case actionTypes.FC_FINANCIAL_CLASS_CHANGE:
      return handleFinancialClassChange(state, action.payload);
    case actionTypes.FC_FINANCIAL_CLASS_DELETE:
      return handleFinancialClassDelete(state, action.payload);

    case actionTypes.FC_START_ADD_FINANCIAL_CLASS:
      return handleStartAddFinancialClass(state, action.payload);
    case actionTypes.FC_START_ADD_SETTING_CARD:
      return handleStartAddSettingCard(state, action.payload);
    case actionTypes.FC_START_ADD_GROUPER_CARD:
      return handleStartAddGrouperCard(state, action.payload);

    case actionTypes.FC_SHOW_SETTINGS:
      return handleShowSettingsAction(state, action.payload);

    case actionTypes.FC_GET_SETTINGS_BEGIN:
      return handleGetSettingsStart(state);
    case actionTypes.FC_GET_SETTINGS_COMPLETED:
      return handleGetSettingsCompleted(state, action.payload);
    case actionTypes.FC_GET_SETTINGS_FAIL:
      return handleGetSettingsFailed(state, action.payload);
    case actionTypes.FC_GET_SETTINGS_PAGE_BEGIN:
      return handleGetSettingsPageStart(state);
    case actionTypes.FC_GET_SETTINGS_PAGE_COMPLETED:
      return handleGetSettingsPageCompleted(state, action.payload);
    case actionTypes.FC_GET_SETTINGS_PAGE_FAIL:
      return handleGetSettingsPageFailed(state, action.payload);

    case actionTypes.FC_IP_CARD_ADD:
      return handleIPCardAdd(state, action.payload);
    case actionTypes.FC_IP_CARD_CHANGE:
      return handleIPCardChange(state, action.payload);
    case actionTypes.FC_IP_CARD_DELETE:
      return handleIPCardDelete(state, action.payload);
    case actionTypes.FC_OP_CARD_ADD:
      return handleOPCardAdd(state, action.payload);
    case actionTypes.FC_OP_CARD_CHANGE:
      return handleOPCardChange(state, action.payload);
    case actionTypes.FC_OP_CARD_DELETE:
      return handleOPCardDelete(state, action.payload);
    case actionTypes.FC_SELECT_COMPARISON:
      return handleSelectComparison(state, action.payload);

    case actionTypes.FC_SHOW_SETTING:
      return handleShowSettingAction(state, action.payload);

    case actionTypes.FC_GET_GROUPER_CARDS_BEGIN:
      return handleGetGrouperCardsStart(state);
    case actionTypes.FC_GET_GROUPER_CARDS_COMPLETED:
      return handleGetGrouperCardsCompleted(state, action.payload);
    case actionTypes.FC_GET_GROUPER_CARDS_FAIL:
      return handleGetGrouperCardsFailed(state, action.payload);
    case actionTypes.FC_GROUPER_CARD_ADD:
      return handleGrouperCardAdd(state, action.payload);
    case actionTypes.FC_GROUPER_CARD_CHANGE:
      return handleGrouperCardChange(state, action.payload);
    case actionTypes.FC_GROUPER_CARD_DELETE:
      return handleGrouperCardDelete(state, action.payload);

    case actionTypes.FC_GET_PRICER_CARD_BEGIN:
      return handleGetPricerCardStart(state);
    case actionTypes.FC_SHOW_PRICER_CARD:
      return handleShowPricerAction(state, action.payload);
    case actionTypes.FC_GET_PRICER_CARD_FAIL:
      return handleGetPricerCardFailed(state, action.payload);

    case actionTypes.FC_PRICER_CARD_VALUE_CHANGE:
      return handlePricerCardChangeValue(state, action.payload);
    case actionTypes.FC_PRICER_CARD_VALUE_ENTER_EDIT:
      return handlePricerCardEnterEditValue(state, action.payload);
    case actionTypes.FC_PRICER_CARD_VALUE_KEYDOWN:
      return handlePricerCardKeydownValue(state, action.payload);

    // backend interactions
    case actionTypes.FC_CONCURRENCY_INTERACTION_BEGIN:
      return handleStartConcurrencyInteraction(state);
    case actionTypes.FC_CONCURRENCY_INTERACTION_COMPLETED:
      return handleFinishConcurrencyInteraction(state);

    case actionTypes.FC_SET_ERROR_ITEM:
      return handleSetErrorItem(state, action.payload);
    case actionTypes.FC_SET_GROUPER_OPTIONS:
      return handleSetGrouperOptions(state, action.payload);
    case actionTypes.FC_SET_GROUPER_HAS_FILE:
      return handleSetGrouperHasFile(state, action.payload);
    case actionTypes.FC_SET_DRG_WEIGHTS_UPLOAD_MESSAGES:
      return handleSetDrgWeightsUploadMessages(state, action.payload);

    case actionTypes.FC_GET_DRG_WEIGHTS_BEGIN:
      return handleGetDrgWeightsBegin(state);
    case actionTypes.FC_GET_DRG_WEIGHTS_END:
      return handleGetDrgWeightsEnd(state, action.payload);
    case actionTypes.FC_GET_DRG_WEIGHTS_ERROR:
      return handleGetDrgWeightsError(state, action.payload);
    default:
      return state;
  }
};

const handleSelectFacility = (state: FinancialClassState, facilityViewId: string): FinancialClassState => {
  const newState = {
    ...state,
    selectedFacilityViewId: facilityViewId,
    financialClasses: [],
    financialClassesLastLoadedPage: 0,
    financialClassesMustBeReloaded: false,
    financialClassesTotalRecords: 0,
    selectedFinancialClassViewId: '',
    selectedSettingsType: '',
    selectedSettingCardViewId: '',
    selectedSettingCardEffectiveDate: '',
    selectedSettingCard: undefined,
    selectedGrouperCardViewId: '',
    errorText: '',
    addingModeFinancialClass: false,
    addingModeSettingCard: false,
    addingModeGrouperCard: false,
    errorItemViewId: '',
  };

  return newState;
}

const handleGetFinancialClassesStart = (state: FinancialClassState): FinancialClassState => {
  const newState = {
    ...state,
    financialClassesLoading: true,
    errorText: ''
  };

  return newState;
}

const handleGetFinancialClassesFailed = (state: FinancialClassState, error): FinancialClassState => {
  const newState = {
    ...state,
    financialClassesLoading: false,
    errorText: error.message
  };

  return newState;
}

const handleGetFinancialClassesCompleted = (state: FinancialClassState,
  payload: { data: FinancialClassEntity[], totalItems: number, page: number, focusedFinancialClassViewId?: string }): FinancialClassState => {
  const newState = {
    ...state,
    initialized: true,
    financialClassesLoading: false,
    errorText: '',
    // if we have focused financial class, it should be the first data item. store it as selectedFinancialClassViewId
    financialClasses: [...payload.data],
    financialClassesLastLoadedPage: payload.page,
    financialClassesTotalRecords: payload.totalItems,
    financialClassesMustBeReloaded: false,
    selectedFinancialClassViewId: payload.focusedFinancialClassViewId || state.selectedFinancialClassViewId,
    errorItemViewId: '',
  };

  return newState;
}

const handleGetFinancialClassesPageStart = (state: FinancialClassState): FinancialClassState => {
  const newState = {
    ...state,
    financialClassesPageLoading: true,
    errorText: ''
  };

  return newState;
}

const handleGetFinancialClassesPageFailed = (state: FinancialClassState, error): FinancialClassState => {
  const newState = {
    ...state,
    financialClassesPageLoading: false,
    errorText: error.message
  };

  return newState;
}

const handleGetFinancialClassesPageCompleted = (state: FinancialClassState,
  payload: { data: FinancialClassEntity[], totalItems: number, page: number }): FinancialClassState => {
  // do not add focused financial class second time
  const focusedFinanicalClassLoaded = state.financialClasses.some((fc) => fc.ViewId === state.selectedFinancialClassViewId);
  const newData = focusedFinanicalClassLoaded ?
    payload.data.filter((fc) => fc.ViewId !== state.selectedFinancialClassViewId): payload.data;
  const newState = {
    ...state,
    financialClassesPageLoading: false,
    errorText: '',
    financialClasses: [...state.financialClasses, ...newData],
    financialClassesLastLoadedPage: payload.page,
    financialClassesTotalRecords: payload.totalItems,
  };

  return newState;
}

const handleToggleInactiveFinancialClasses = (state: FinancialClassState, hideInactiveItems: boolean): FinancialClassState => {
  return {
    ...state,
    hideInactiveFinancialClasses: hideInactiveItems
  }
}

const handleFinancialClassAdd = (state: FinancialClassState, payload: FinancialClassEntity): FinancialClassState => {
  const newFinancialClasses = [{ ...payload }, ...state.financialClasses];

  const newState = {
    ...state,
    addingModeFinancialClass: false,
    financialClasses: newFinancialClasses,
    financialClassesTotalRecords: state.financialClassesTotalRecords + 1,
    financialClassesMustBeReloaded: true,
  };

  return newState;
}

const handleFinancialClassChange = (state: FinancialClassState, payload: FinancialClassEntity): FinancialClassState => {
  const newState = {
    ...state,
    financialClasses: state.financialClasses.map(item => item.ViewId === payload.ViewId ? payload : item)
  };

  return newState;
}

const handleFinancialClassDelete = (state: FinancialClassState, payload: string): FinancialClassState => {

  const deleteSelectedFC = state.selectedFinancialClassViewId === payload;

  const newState = {
    ...state,

    selectedFinancialClassViewId: deleteSelectedFC ? '' : state.selectedFinancialClassViewId,
    selectedSettingsType: deleteSelectedFC ? '' : state.selectedSettingsType,
    selectedSettingCardViewId: deleteSelectedFC ? '' : state.selectedSettingCardViewId,
    selectedSettingCardEffectiveDate: deleteSelectedFC ? '' : state.selectedSettingCardEffectiveDate,
    selectedSettingCard: deleteSelectedFC ? undefined : state.selectedSettingCard,
    selectedGrouperCardViewId: deleteSelectedFC ? '' : state.selectedGrouperCardViewId,

    financialClasses: state.financialClasses.filter(item => item.ViewId !== payload),
    financialClassesTotalRecords: state.financialClassesTotalRecords - 1,
    financialClassesMustBeReloaded: true,
  };

  return newState;
}

const handleShowSettingsAction = (state: FinancialClassState, payload: { financialClassViewId: string, settingsType: string }): FinancialClassState => {
  const newState = {
    ...state,
    selectedFinancialClassViewId: payload.financialClassViewId,
    selectedSettingsType: payload.settingsType,
    selectedSettingCardViewId: '',
    selectedSettingCardEffectiveDate: '',
    selectedSettingCard: undefined,
    selectedGrouperCardViewId: '',
    selectedGrouperTitle: '',
    addingModeFinancialClass: false,
    addingModeSettingCard: false,
    addingModeGrouperCard: false,
  };

  return newState;
}

const handleShowSettingAction = (state: FinancialClassState, payload: {
  settingCardViewId: string, settingCardEffectiveDate: string, groupersList: GroupersChoiceListItem[], settingCard: IPCardEntity | OPCardEntity
}): FinancialClassState => {
  const newState = {
    ...state,
    selectedSettingCardViewId: payload.settingCardViewId,
    selectedSettingCardEffectiveDate: payload.settingCardEffectiveDate,
    selectedSettingCard: payload.settingCard,
    selectedGrouperCardViewId: '',
    selectedGrouperTitle: '',
    addingModeSettingCard: false,
    addingModeGrouperCard: false,
    groupersList: payload.groupersList,
  };

  return newState;
}

// add missed Pricer values
const addMissedValues = (fields: PricerCardField[], values: PricerCardValue[]) => {
  const result: PricerCardValue[] = [...values];

  // create value for each missed field
  for (let ind = 0, len = fields.length; ind < len; ind++) {
    const field = fields[ind];
    const value = values.find(val => val.PricerFieldId === field.Id);
    if (!value) {
      result.push({
        PricerFieldId: field.Id,
        Value: getPricerCardFieldDefaultValue(field.DefaultValues),
      })
    }
  }

  return result;
}

// prepare array for Pricer grid
const prepareData = (fields: PricerCardField[], values: PricerCardValue[], isSimplePricing: boolean, selectedSettingsType: string) => {
  const gridData: PricerCardRow[] = [];
  for (let ind = 0, len = fields.length; ind < len; ind++) {
    const field = fields[ind];

    const isVisible = isSimplePricing && field.SimpleVisible || !isSimplePricing && field.FullVisible;

    if (isVisible) {
      const fieldValue = values.find(val => val.PricerFieldId === field.Id);
      const value = fieldValue ? fieldValue.Value : getPricerCardFieldDefaultValue(field.DefaultValues);

      // suppose that all ddl items are present in the lists: I don't load list until cell is edited
      const error = isValidPricerCardValue(field, value);

      gridData.push({
        ...field,
        value,
        initialValue: value,
        error,
      })
    }
  }

  return gridData;
}

const handleShowPricerAction = (state: FinancialClassState, payload: {
  show: boolean, grouperCardViewId: string,
  grouperCardTitle: string, GrouperType: string, GrouperVersion: string, PricerType: string, SimplePricing: boolean,
  fields: PricerCardField[], values: PricerCardValue[], AllowCustomDrgWeights?: boolean, IsCustomDrgWeightsAvailable?: boolean,
}): FinancialClassState => {
  if (!payload.show) {
    return {
      ...state,
      selectedGrouperCardViewId: ''
    }
  }

  // services has both string and numeric field Ids in different endpoints
  const fieldsWithStringIds = payload.fields.map(field => { return {...field, Id: field.Id ? field.Id.toString() : ''} });
  const valuesWithMissed = addMissedValues(fieldsWithStringIds, payload.values);

  const newState = {
    ...state,
    pricerCardLoading: false,

    selectedGrouperCardViewId: payload.grouperCardViewId,
    selectedGrouperTitle: payload.grouperCardTitle,

    // fields metadata
    selectedGrouperFields: [...fieldsWithStringIds],
    // loaded from the services fields + all missed values
    selectedGrouperPricerValues: valuesWithMissed,
    // grid with visible in the current mode fields
    selectedGrouperCard: prepareData(fieldsWithStringIds, valuesWithMissed, payload.SimplePricing, state.selectedSettingsType),

    selectedGrouperGrouperType: payload.GrouperType,
    selectedGrouperGrouperVersion: payload.GrouperVersion,
    selectedGrouperPricerType: payload.PricerType,
    selectedGrouperSimplePricing: payload.SimplePricing,

    selectedGrouperAllowCustomDrgWeights: !!payload.AllowCustomDrgWeights,
    selectedGrouperIsCustomDrgWeightsAvailable: !!payload.IsCustomDrgWeightsAvailable,
    drgWeightsSuccessUploadMessage: '',
    drgWeightsFailedUploadMessage: '',

    addingModeSettingCard: false,
    addingModeGrouperCard: false,
  };

  return newState;
}

const handleSelectComparison = (state: FinancialClassState, payload: string): FinancialClassState => {
  const newState = {
    ...state,
    selectedSettingsComparison: payload
  };

  return newState;
}

// Settings, IP and OP Cards

const handleGetSettingsStart = (state: FinancialClassState): FinancialClassState => {
  const newState = {
    ...state,
    settingsLoading: true,
    errorText: ''
  };

  return newState;
}

const handleGetSettingsFailed = (state: FinancialClassState, error): FinancialClassState => {
  const newState = {
    ...state,
    settingsLoading: false,
    errorText: error.message
  };

  return newState;
}

const handleGetSettingsCompleted = (state: FinancialClassState,
  settings: { comparison: string, settingsType: string, settingsData: { data: CardEntity[], totalItems: number, page: number }, focusedSettingCardViewId?: string }
): FinancialClassState => {
  const ipData = settings.settingsType === SETTINGS_TYPE_IP ? settings.settingsData.data as unknown as IPCardEntity[] : [];
  const opData = settings.settingsType === SETTINGS_TYPE_OP ? settings.settingsData.data as unknown as OPCardEntity[] : [];

  const newState = {
    ...state,
    settingsLoading: false,
    errorText: '',
    ipCards: settings.settingsType === SETTINGS_TYPE_IP ? [...ipData] : state.ipCards,
    opCards: settings.settingsType === SETTINGS_TYPE_OP ? [...opData] : state.opCards,
    selectedSettingsComparison: settings.comparison,
    settingsCardsLastLoadedPage: settings.settingsData.page,
    settingsCardsTotalRecords: settings.settingsData.totalItems,
    settingsCardsMustBeReloaded: false,
    // select focused card
    selectedSettingCardViewId: settings.focusedSettingCardViewId || state.selectedSettingCardViewId,
  };

  return newState;
}

const handleGetSettingsPageStart = (state: FinancialClassState): FinancialClassState => {
  const newState = {
    ...state,
    settingsPageLoading: true,
    errorText: ''
  };

  return newState;
}

const handleGetSettingsPageFailed = (state: FinancialClassState, error): FinancialClassState => {
  const newState = {
    ...state,
    settingsPageLoading: false,
    errorText: error.message
  };

  return newState;
}

const handleGetSettingsPageCompleted = (state: FinancialClassState,
  settings: { settingsType: string, settingsData: { data: CardEntity[], totalItems: number, page: number } }
): FinancialClassState => {
  // do not add focused setting card second time
  const oldData = settings.settingsType === SETTINGS_TYPE_IP ? state.ipCards : state.opCards;
  const focusedCardLoaded = oldData.some((card) => card.ViewId === state.selectedSettingCardViewId);
  const newData = focusedCardLoaded ?
    settings.settingsData.data.filter((card) => card.ViewId !== state.selectedSettingCardViewId): settings.settingsData.data;
  const ipData = settings.settingsType === SETTINGS_TYPE_IP ? newData as unknown as IPCardEntity[] : [];
  const opData = settings.settingsType === SETTINGS_TYPE_OP ? newData as unknown as OPCardEntity[] : [];

  const newState = {
    ...state,
    settingsPageLoading: false,
    errorText: '',
    ipCards: settings.settingsType === SETTINGS_TYPE_IP ? [...state.ipCards, ...ipData] : state.ipCards,
    opCards: settings.settingsType === SETTINGS_TYPE_OP ? [...state.opCards, ...opData] : state.opCards,
    settingsCardsLastLoadedPage: settings.settingsData.page,
    settingsCardsTotalRecords: settings.settingsData.totalItems,
  };

  return newState;
}

const handleIPCardAdd = (state: FinancialClassState, payload: IPCardEntity): FinancialClassState => {
  const card = { ...payload };
  const newCards = [card, ...state.ipCards];

  const newState = {
    ...state,
    ipCards: newCards,
    addingModeSettingCard: false,
    settingsCardsTotalRecords: state.settingsCardsTotalRecords + 1,
    settingsCardsMustBeReloaded: true,
  };

  return newState;
}

const handleIPCardChange = (state: FinancialClassState, payload: { card: IPCardEntity, doNotCloseGrouperCards: boolean }): FinancialClassState => {
  const card = { ...payload.card };
  const selectedCardUpdated = card.ViewId === state.selectedSettingCardViewId;

  const newState = {
    ...state,
    ipCards: state.ipCards.map(item => item.ViewId === card.ViewId ? card : item),
    // do not close opened grouper and pricer cards after Primary changing
    selectedSettingCardViewId: !payload.doNotCloseGrouperCards && selectedCardUpdated ? '' : state.selectedSettingCardViewId,
    selectedSettingCardEffectiveDate: !payload.doNotCloseGrouperCards && selectedCardUpdated ? '' : state.selectedSettingCardEffectiveDate,
    selectedSettingCard: selectedCardUpdated ? { ...card } : state.selectedSettingCard,
    selectedGrouperCardViewId: !payload.doNotCloseGrouperCards && selectedCardUpdated ? '' : state.selectedGrouperCardViewId,
  };

  return newState;
}

const handleIPCardDelete = (state: FinancialClassState, payload: { settingCardViewId: string, financialClassViewId: string }): FinancialClassState => {
  const deleteSelectedCard = state.selectedSettingCardViewId === payload.settingCardViewId;

  const newState = {
    ...state,
    ipCards: state.ipCards.filter(item => item.ViewId !== payload.settingCardViewId),
    selectedSettingCardViewId: deleteSelectedCard ? '' : state.selectedSettingCardViewId,
    selectedSettingCardEffectiveDate: deleteSelectedCard ? '' : state.selectedSettingCardEffectiveDate,
    selectedSettingCard: deleteSelectedCard ? undefined : state.selectedSettingCard,
    selectedGrouperCardViewId: deleteSelectedCard ? '' : state.selectedGrouperCardViewId,
    settingsCardsTotalRecords: state.settingsCardsTotalRecords - 1,
    settingsCardsMustBeReloaded: true,
  };

  return newState;
}

const handleOPCardAdd = (state: FinancialClassState, payload: OPCardEntity): FinancialClassState => {
  const card = { ...payload };
  const newCards = [card, ...state.opCards];

  const newState = {
    ...state,
    opCards: newCards,
    addingModeSettingCard: false,
    settingsCardsTotalRecords: state.settingsCardsTotalRecords + 1,
    settingsCardsMustBeReloaded: true,
  };

  return newState;
}

const handleOPCardChange = (state: FinancialClassState, payload: { card: OPCardEntity, doNotCloseGrouperCards: boolean } ): FinancialClassState => {
  const card = { ...payload.card };
 
  const selectedCardUpdated = card.ViewId === state.selectedSettingCardViewId;

  const newState = {
    ...state,
    opCards: state.opCards.map(item => item.ViewId === card.ViewId ? card : item),
    // do not close opened grouper and pricer cards after Primary changing
    selectedSettingCardViewId: !payload.doNotCloseGrouperCards && selectedCardUpdated ? '' : state.selectedSettingCardViewId,
    selectedSettingCardEffectiveDate: !payload.doNotCloseGrouperCards && selectedCardUpdated ? '' : state.selectedSettingCardEffectiveDate,
    selectedSettingCard: selectedCardUpdated ? { ...card } : state.selectedSettingCard,
    selectedGrouperCardViewId: !payload.doNotCloseGrouperCards && selectedCardUpdated ? '' : state.selectedGrouperCardViewId,
  };

  return newState;
}

const handleOPCardDelete = (state: FinancialClassState, payload: { settingCardViewId: string, financialClassViewId: string }): FinancialClassState => {
  const deleteSelectedCard = state.selectedSettingCardViewId === payload.settingCardViewId;

  const newState = {
    ...state,
    opCards: state.opCards.filter(item => item.ViewId !== payload.settingCardViewId),
    selectedSettingCardViewId: deleteSelectedCard ? '' : state.selectedSettingCardViewId,
    selectedSettingCardEffectiveDate: deleteSelectedCard ? '' : state.selectedSettingCardEffectiveDate,
    selectedSettingCard: deleteSelectedCard ? undefined : state.selectedSettingCard,
    selectedGrouperCardViewId: deleteSelectedCard ? '' : state.selectedGrouperCardViewId,
    settingsCardsTotalRecords: state.settingsCardsTotalRecords - 1,
    settingsCardsMustBeReloaded: true,
  };

  return newState;
}

const handleStartAddFinancialClass = (state: FinancialClassState, mode: boolean): FinancialClassState => {
  const newState = {
    ...state,
    addingModeFinancialClass: mode,
    selectedSettingsType: '',
    selectedSettingCardViewId: '',
    selectedSettingCardEffectiveDate: '',
    selectedSettingCard: undefined,
    selectedGrouperCardViewId: '',
    selectedFinancialClassViewId: '',
  };

  return newState;
}

const handleStartAddSettingCard = (state: FinancialClassState, mode: boolean): FinancialClassState => {
  const newState = {
    ...state,
    addingModeSettingCard: mode,
    selectedSettingCardViewId: '',
    selectedSettingCardEffectiveDate: '',
    selectedSettingCard: undefined,
    selectedGrouperCardViewId: ''
  };

  return newState;
}
const handleStartAddGrouperCard = (state: FinancialClassState, mode: boolean): FinancialClassState => {
  const newState = {
    ...state,
    addingModeGrouperCard: mode,
    selectedGrouperCardViewId: ''
  };

  return newState;
}

const handleGetGrouperCardsStart = (state: FinancialClassState): FinancialClassState => {
  const newState = {
    ...state,
    grouperCardsLoading: true,
    errorText: ''
  };

  return newState;
}

const handleGetGrouperCardsFailed = (state: FinancialClassState, error): FinancialClassState => {
  const newState = {
    ...state,
    grouperCardsLoading: false,
    errorText: error.message
  };

  return newState;
}

const handleGetGrouperCardsCompleted = (state: FinancialClassState, payload: GrouperCardEntity[]): FinancialClassState => {
  const newState = {
    ...state,
    grouperCardsLoading: false,
    errorText: '',
    grouperCards: [...payload]
  };

  return newState;
}

const handleGrouperCardAdd = (state: FinancialClassState,
  payload: { addedGrouperCard: GrouperCardEntity, proposedSettingCard?: IPCardEntity | OPCardEntity }
): FinancialClassState => {
  const card = { ...payload.addedGrouperCard };

  const newCards = [card, ...state.grouperCards];

  const newState = {
    ...state,
    grouperCards: newCards,
    addingModeGrouperCard: false
  };

  return newState;
}

const handleGrouperCardChange = (state: FinancialClassState,
  payload: { changedGrouperCard: GrouperCardEntity, proposedSettingCard?: IPCardEntity | OPCardEntity }
): FinancialClassState => {
  const card = { ...payload.changedGrouperCard };

  const newCards = state.grouperCards.map((existing) => {
    return existing.ViewId === payload.changedGrouperCard.ViewId ? card : existing
  });

  const newState = {
    ...state,
    grouperCards: newCards,
  };

  return newState;
}

const handleGrouperCardDelete = (state: FinancialClassState, payload: { grouperCardViewId: string, financialClassViewId: string, proposedSettingCard?: IPCardEntity | OPCardEntity }): FinancialClassState => {
  const deleteSelectedCard = state.selectedGrouperCardViewId === payload.grouperCardViewId;

  const newCards = state.grouperCards.filter(existing => existing.ViewId !== payload.grouperCardViewId);

  const newState = {
    ...state,
    grouperCards: newCards,
    selectedGrouperCardViewId: deleteSelectedCard ? '' : state.selectedGrouperCardViewId,
  };

  return newState;
}

const handleGetPricerCardStart = (state: FinancialClassState): FinancialClassState => {
  const newState = {
    ...state,
    pricerCardLoading: true,
    errorText: ''
  };

  return newState;
}

const handleGetPricerCardFailed = (state: FinancialClassState, error): FinancialClassState => {
  const newState = {
    ...state,
    pricerCardLoading: false,
    errorText: error.message
  };

  return newState;
}

const handlePricerCardChangeValue = (state: FinancialClassState, payload: { dataItem: PricerCardRow, field: string, value: string, noChange: boolean, financialClassViewId: string, newPricerValues: PricerCardValue[], error: string }): FinancialClassState => {
  const newValues = state.selectedGrouperCard.map(item => item.Id === payload.dataItem.Id ? {
    ...item,
    value: payload.value,
    initialValue: payload.value,
    inEdit: false,
    error: payload.error,
  } : item);

  // same value, no need in the additional updating
  if (payload.noChange) {
    const newState = {
      ...state,
      selectedGrouperCard: newValues,
      drgWeightsSuccessUploadMessage: '',
      drgWeightsFailedUploadMessage: '',
    };

    return newState;
  }

  const newState = {
    ...state,
    selectedGrouperCard: newValues,
    selectedGrouperPricerValues: payload.newPricerValues,
    drgWeightsSuccessUploadMessage: '',
    drgWeightsFailedUploadMessage: '',
  };

  return newState;
}

const handlePricerCardEnterEditValue = (state: FinancialClassState, dataItem: PricerCardRow): FinancialClassState => {
  const newValues = state.selectedGrouperCard.map(item => item.Id === dataItem.Id ? {
    ...item,
    inEdit: true
  } : {
    ...item,
    inEdit: false
  });

  const newState = {
    ...state,
    selectedGrouperCard: newValues
  };

  return newState;
}

const focusPreviousRow = (_values: PricerCardRow[], dataitem: PricerCardRow, isTabKey: boolean) => {
  const values = _values;
  for (let ind = 0, len = values.length; ind < len; ind++) {
    if (values[ind].Id === dataitem.Id) {
      if (ind === 0) {
        values[isTabKey ? values.length - 1 : 0].inEdit = true;
      }

      if (ind > 0) {
        values[ind - 1].inEdit = true;
      }

      break;
    }
  }
}

const focusNextRow = (_values: PricerCardRow[], dataitem: PricerCardRow, isTabKey: boolean) => {
  const values = _values;
  for (let ind = 0, len = values.length; ind < len; ind++) {
    if (values[ind].Id === dataitem.Id) {
      if (ind === values.length - 1) {
        values[!isTabKey ? values.length - 1 : 0].inEdit = true;
      }

      if (ind < values.length - 1) {
        values[ind + 1].inEdit = true;
      }

      break;
    }
  }
}

const handlePricerCardKeydownValue = (state: FinancialClassState, payload: { dataItem: PricerCardRow, key: string, shiftKey?: boolean }): FinancialClassState => {
  const values = state.selectedGrouperCard.map(item => { return { ...item, inEdit: false } });

  switch (payload.key) {
    case Key.Tab:
    case Key.Enter: {
      if (payload.shiftKey) {
        focusPreviousRow(values, payload.dataItem, payload.key === Key.Tab);
      } else {
        focusNextRow(values, payload.dataItem, payload.key === Key.Tab);
      }

      break;
    }

    case Key.Escape:
      break;
    default:
  }

  const newState = {
    ...state,
    selectedGrouperCard: values
  };

  return newState;
}

const handleStartConcurrencyInteraction = (state: FinancialClassState): FinancialClassState => {
  return {
    ...state,
    concurrencyInteractions: state.concurrencyInteractions + 1
  }
}

const handleFinishConcurrencyInteraction = (state: FinancialClassState): FinancialClassState => {
  return {
    ...state,
    concurrencyInteractions: state.concurrencyInteractions > 0 ? state.concurrencyInteractions - 1 : 0
  }
}

const handleSetErrorItem = (state: FinancialClassState, errorItemViewId?: string): FinancialClassState => {
  return {
    ...state,
    errorItemViewId: errorItemViewId || '',
  }
}

const handleSetGrouperOptions = (state: FinancialClassState, grouperOptions: GrouperOption[]): FinancialClassState => {
  return {
    ...state,
    grouperOptions,
  }
}

const handleSetGrouperHasFile = (state: FinancialClassState, payload: { changedGrouperCardViewId: string, hasFile: boolean }): FinancialClassState => {
  const newCards = state.grouperCards.map((existing) => {
    return existing.ViewId === payload.changedGrouperCardViewId
      ? { ...existing, IsCustomDrgWeightsAvailable: payload.hasFile }
      : existing
  });

  return {
    ...state,
    grouperCards: newCards,
    selectedGrouperIsCustomDrgWeightsAvailable: payload.hasFile,
  }
}

const handleSetDrgWeightsUploadMessages = (state: FinancialClassState, payload: { drgWeightsSuccessUploadMessage: string, drgWeightsFailedUploadMessage: string }): FinancialClassState => {
  return {
    ...state,
    drgWeightsSuccessUploadMessage: payload.drgWeightsSuccessUploadMessage,
    drgWeightsFailedUploadMessage: payload.drgWeightsFailedUploadMessage,
  }
}

const handleGetDrgWeightsBegin = (state: FinancialClassState): FinancialClassState => {
  return {
    ...state,
    drgWeightsLoading: true,
    drgWeightsData: [],
    drgWeightsError: '',
    drgWeightsLoaded: false,
  }
}

const handleGetDrgWeightsEnd = (state: FinancialClassState, weights: DrgWeightItem[]): FinancialClassState => {
  return {
    ...state,
    drgWeightsLoading: false,
    drgWeightsData: [...weights],
    drgWeightsError: '',
    drgWeightsLoaded: true,
  }
}

const handleGetDrgWeightsError = (state: FinancialClassState, error: string): FinancialClassState => {
  return {
    ...state,
    drgWeightsLoading: false,
    drgWeightsData: [],
    drgWeightsError: error,
    drgWeightsLoaded: false,
  }
}
